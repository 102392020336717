<div class="d-flex flex-column align-content-stretch flex-grow-1 flex-shrink-1">
  @if (disableContainerBreakpoints || hasParentContainer) {
    <div class="flex-grow-1 flex-shrink-1 overflow-auto">
      <ng-container *ngTemplateOutlet="contentTemplate" />
    </div>
  } @else {
    <div
      class="flex-grow-1 flex-shrink-1 overflow-auto"
      siResponsiveContainer
      [breakpoints]="contentContainerBreakpoints"
    >
      <ng-container *ngTemplateOutlet="contentTemplate" />
    </div>
  }

  @if (!readonly) {
    <div class="d-flex flex-row flex-nowrap flex-grow-0 flex-shrink-0 p-4">
      <div
        class="d-flex flex-grow-1 flex-shrink-1 justify-content-end text-end overflow-hidden mx-6 my-auto"
        [class.text-success]="validFormContainerMessage"
        [class.text-danger]="invalidFormContainerMessage"
      >
        <ng-content select="[si-form-container-message]" />
      </div>

      <div class="m-auto flex-grow-0 flex-shrink-0">
        <ng-content select="[si-form-container-buttons]" />
      </div>
    </div>
  }
</div>

<ng-template #contentTemplate>
  <ng-content select="[si-form-container-content]" />
</ng-template>
