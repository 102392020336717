import { Directive, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

import { SiHeaderActionItemBase } from './si-header-action-item.base';

/**
 * Base for icon based actions.
 * @internal
 */
@Directive({ standalone: true })
export abstract class SiHeaderActionIconItemBase
  extends SiHeaderActionItemBase
  implements OnChanges, OnInit
{
  /**
   * Adds a badge to the header item.
   * If type
   * - =number, the number will be shown and automatically trimmed if \>99
   * - =true, an empty red dot will be shown
   */
  @Input() badge: number | boolean | undefined | null;

  protected badgeValue?: string;
  protected badgeDot = false;

  ngOnChanges(changes: SimpleChanges): void {
    if ('badge' in changes) {
      if (this.badge) {
        if (!this.badgeDot && !this.badgeValue) {
          this.collapsibleActions?.badgeCount.update(count => count + 1);
        }

        if (typeof this.badge === 'boolean') {
          this.badgeDot = true;
          this.badgeValue = undefined;
        } else {
          this.badgeValue = `${this.badge > 99 ? '+' : ''}${Math.min(99, Math.round(this.badge))}`;
          this.badgeDot = false;
        }
      } else {
        if (this.badgeDot || this.badgeValue) {
          this.collapsibleActions?.badgeCount.update(count => count - 1);
        }

        this.badgeDot = false;
        this.badgeValue = undefined;
      }
    }
  }

  protected get visuallyHideTitle(): boolean {
    return !this.collapsibleActions?.mobileExpanded();
  }
}
