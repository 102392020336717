import {
  animate,
  animateChild,
  group,
  query,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import { BreakpointObserver } from '@angular/cdk/layout';
import { ConnectedPosition } from '@angular/cdk/overlay';
import {
  booleanAttribute,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  inject,
  Input,
  numberAttribute,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { MenuItem, SI_UI_STATE_SERVICE } from '@simpl/element-ng/common';
import { NavbarWithDropdowns, SI_NAVBAR_WITH_DROPDOWNS } from '@simpl/element-ng/navbar-dropdown';
import { BOOTSTRAP_BREAKPOINTS } from '@simpl/element-ng/resize-observer';
import { SiSearchBarComponent } from '@simpl/element-ng/search-bar';
import { SiSkipLinkTargetDirective } from '@simpl/element-ng/skip-links';
import { SiTranslateModule } from '@simpl/element-translate-ng/translate';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { SiNavbarVerticalItemComponent } from './si-navbar-vertical-item.component';
import { SI_NAVBAR_VERTICAL } from './si-navbar-vertical.provider';

interface UIState {
  preferCollapse: boolean;
  expandedItems: Record<string, boolean>;
}

@Component({
  selector: 'si-navbar-vertical',
  templateUrl: './si-navbar-vertical.component.html',
  styleUrl: './si-navbar-vertical.component.scss',
  host: { class: 'si-layout-inner' },
  providers: [
    { provide: SI_NAVBAR_VERTICAL, useExisting: SiNavbarVerticalComponent },
    { provide: SI_NAVBAR_WITH_DROPDOWNS, useExisting: SiNavbarVerticalComponent }
  ],
  animations: [
    trigger('collapse', [
      state('expanded', style({ 'inline-size': '240px' })),
      state('collapsed', style({ 'inline-size': '*' })),
      transition('collapsed => expanded', [
        group([
          animate('0.5s ease'),
          query('.menu-body', animateChild(), { optional: true }),
          query(
            '.dropdown-caret',
            [
              style({ 'opacity': '0' }),
              animate('0.5s ease', style({ 'opacity': '*' })),
              style({ 'opacity': '1' })
            ],
            { optional: true }
          ),
          query(
            '.mobile-drawer',
            style({ 'box-shadow': 'none', background: 'var(--element-base-1)' })
          ),
          query('.mobile-drawer', [
            style({ 'inline-size': '*', 'box-shadow': '*' }),
            animate('0.5s ease', style({ 'inline-size': '240px' })),
            style({ 'inline-size': '240px' })
          ])
        ])
      ]),
      transition('expanded => collapsed', [
        query('.nav-search', style({ 'display': 'flex' }), { optional: true }),
        query('.nav-scroll', style({ 'display': 'block' })),
        group([
          animate('0.5s ease'),
          query('.menu-body', animateChild(), { optional: true }),
          query('.nav-link :not(.icon)', style({ visibility: 'hidden' }), { optional: true }),
          query(
            '.nav-link:not(.expander) + .nav-link:not(.expander)',
            style({ visibility: 'hidden' }),
            { optional: true }
          ),
          query('.section-item', style({ visibility: 'hidden' }), { optional: true }),
          query('.menu-toggle', style({ visibility: 'hidden' }), { optional: true }),
          query('.menu-body', style({ visibility: 'hidden' }), { optional: true }),
          query('.mobile-drawer', [
            style({ 'inline-size': '240px', 'box-shadow': 'none' }),
            animate('0.5s ease', style({ 'inline-size': '*' })),
            style({ 'inline-size': '*' })
          ])
        ])
      ])
    ]),
    trigger('backdrop', [
      state('show', style({ 'opacity': '1' })),
      state('hide', style({ 'opacity': '0' })),
      transition('* <=> *', [animate('0.15s linear')])
    ])
  ],
  standalone: true,
  imports: [
    SiNavbarVerticalItemComponent,
    SiSearchBarComponent,
    SiSkipLinkTargetDirective,
    SiTranslateModule
  ]
})
export class SiNavbarVerticalComponent
  implements OnChanges, OnInit, OnDestroy, NavbarWithDropdowns
{
  /**
   * Default state of navigation. It might be modified for certain screen sizes.
   *
   * @defaultValue false
   */
  @Input({ transform: booleanAttribute })
  @HostBinding('class.nav-collapsed')
  collapsed = false;

  /**
   * Toggles search bar
   *
   * @defaultValue false
   */
  @Input({ transform: booleanAttribute }) searchable = false;

  /**
   * Placeholder text for search
   *
   * @defaultValue
   * ```
   * $localize`:@@SI_NAVBAR_VERTICAL.SEARCH_PLACEHOLDER:Search ...`
   * ```
   */
  @Input() searchPlaceholder = $localize`:@@SI_NAVBAR_VERTICAL.SEARCH_PLACEHOLDER:Search ...`;

  /**
   * List of vertical navigation items
   *
   * @defaultValue []
   */
  @Input() items: MenuItem[] = [];
  @Output() readonly itemsChange = new EventEmitter<MenuItem[]>();

  /**
   * Set to `true` if there are no icons
   *
   * @defaultValue false
   */
  @HostBinding('class.nav-text-only')
  @Input({ transform: booleanAttribute })
  textOnly = false;

  /**
   * Set to false to hide the vertical navbar
   *
   * @defaultValue true
   */
  @HostBinding('class.visible')
  @Input({ transform: booleanAttribute })
  visible = true;

  /** @deprecated dropped without replacement. */
  @Input({ transform: numberAttribute }) autoCollapseDelay?: number;

  /**
   * Text for the navbar expand button. Required for a11y
   *
   * @defaultValue
   * ```
   * $localize`:@@SI_NAVBAR_VERTICAL.EXPAND:Expand`
   * ```
   */
  @Input() navbarExpandButtonText = $localize`:@@SI_NAVBAR_VERTICAL.EXPAND:Expand`;

  /**
   * Text for the navbar collapse button. Required for a11y
   *
   * @defaultValue
   * ```
   * $localize`:@@SI_NAVBAR_VERTICAL.COLLAPSE:Collapse`
   * ```
   */
  @Input() navbarCollapseButtonText = $localize`:@@SI_NAVBAR_VERTICAL.COLLAPSE:Collapse`;

  /**
   * An optional stateId to uniquely identify a component instance.
   * Required for persistence of ui state.
   */
  @Input() stateId?: string;

  /**
   * Label for the skip link to the vertical navbar
   *
   * @defaultValue
   * ```
   * $localize`:@@SI_NAVBAR_VERTICAL.SKIP_LINK.NAVIGATION_LABEL:Navigation`
   * ```
   */
  @Input()
  skipLinkNavigationLabel = $localize`:@@SI_NAVBAR_VERTICAL.SKIP_LINK.NAVIGATION_LABEL:Navigation`;

  /**
   * Label for the skip link to main content
   *
   * @defaultValue
   * ```
   * $localize`:@@SI_NAVBAR_VERTICAL.SKIP_LINK.MAIN_LABEL:Main content`
   * ```
   */
  @Input()
  skipLinkMainContentLabel = $localize`:@@SI_NAVBAR_VERTICAL.SKIP_LINK.MAIN_LABEL:Main content`;

  /**
   * Output for search bar input
   */
  @Output() readonly searchEvent = new EventEmitter<string>();

  /** @internal */
  overlayPosition: ConnectedPosition[] = [
    { originX: 'end', originY: 'top', overlayX: 'start', overlayY: 'top' }
  ];

  @ViewChild('searchBar') private searchBar!: SiSearchBarComponent;

  // Is required to prevent the navbar from running the padding animation on creation.
  @HostBinding('class.ready') protected readonly ready = true;

  protected readonly searchInputDelay = 400;

  private uiStateService = inject(SI_UI_STATE_SERVICE, { optional: true });
  private breakpointObserver = inject(BreakpointObserver);
  private cdRef = inject(ChangeDetectorRef);
  protected smallScreen = false;
  private destroyer = new Subject<void>();

  // Indicates if the user prefers a collapsed navbar. Relevant for resizing.
  private preferCollapse = false;

  constructor() {
    this.breakpointObserver
      .observe(`(max-width: ${BOOTSTRAP_BREAKPOINTS.lgMinimum}px)`)
      .pipe(takeUntil(this.destroyer))
      .subscribe(({ matches }) => {
        this.collapsed = matches || this.preferCollapse;
        this.smallScreen = matches;
        this.cdRef.markForCheck();
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.collapsed) {
      this.preferCollapse = this.collapsed;
    }
  }

  ngOnInit(): void {
    if (this.uiStateService && this.stateId) {
      this.uiStateService.load<UIState>(this.stateId).then(uiState => {
        if (uiState) {
          this.preferCollapse = uiState.preferCollapse;
          this.collapsed = this.smallScreen ? this.collapsed : this.preferCollapse;
          this.items.forEach(item => {
            if (item.id) {
              item.expanded = uiState.expandedItems[item.id];
            }
          });
          this.cdRef.markForCheck();
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.destroyer.next();
    this.destroyer.complete();
  }

  protected toggleCollapse(): void {
    if (this.collapsed) {
      this.expand();
    } else {
      this.collapse();
    }
  }

  /** Expands the vertical navbar. */
  expand(): void {
    this.collapsed = false;
    if (!this.smallScreen) {
      this.preferCollapse = this.collapsed;
      if (this.autoCollapseDelay) {
        setTimeout(() => {
          if (!this.collapsed) {
            this.toggleCollapse();
            this.cdRef.markForCheck();
          }
        }, this.autoCollapseDelay);
      }
    }
    this.saveUIState();
  }

  /** Collapses the vertical navbar. */
  collapse(): void {
    this.collapsed = true;
    if (!this.smallScreen) {
      this.preferCollapse = this.collapsed;
    }

    this.saveUIState();
  }

  protected expandForSearch(): void {
    this.expand();
    setTimeout(() => this.searchBar.focus());
  }

  protected doSearch(event: string): void {
    this.searchEvent.emit(event);
  }

  protected menuTriggered(): void {
    this.saveUIState();
    this.itemsChange.emit(this.items);
    this.collapsed = false;
  }

  protected saveUIState(): void {
    if (!this.uiStateService || !this.stateId) {
      return;
    }

    const expandedItems = this.items.reduce(
      (expandable, item) => {
        if (item.id) {
          expandable[item.id] = !!item.expanded;
        }
        return expandable;
      },
      {} as Record<string, boolean>
    );

    this.uiStateService.save<UIState>(this.stateId, {
      preferCollapse: this.preferCollapse,
      expandedItems
    });
  }

  protected itemTriggered(): void {
    if (this.smallScreen) {
      this.collapsed = true;
    }
  }
}
