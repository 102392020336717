import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SiTranslateModule } from '@simpl/element-translate-ng/translate';

@Component({
  selector: 'si-empty-state',
  templateUrl: './si-empty-state.component.html',
  styleUrl: './si-empty-state.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, SiTranslateModule]
})
export class SiEmptyStateComponent {
  /**
   * CSS class name of the desired icon.
   */
  @Input({ required: true })
  icon!: string;

  /**
   * Heading of empty state content.
   */
  @Input({ required: true })
  heading!: string;

  /**
   * Description of empty state content.
   */
  @Input() content?: string;
}
