import { NgClass } from '@angular/common';
import {
  booleanAttribute,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { SiTranslateModule } from '@simpl/element-translate-ng/translate';

import { SiComfortScoreStatusComponent } from './si-comfort-score-status.component';
import { ComfortScoreItem } from './si-comfort-score.model';

/**
 * @deprecated The {@link SiComfortScoreComponent} should no longer be used and
 * will be removed in Element v47.
 */
@Component({
  selector: 'si-comfort-score',
  templateUrl: './si-comfort-score.component.html',
  styleUrl: './si-comfort-score.component.scss',
  standalone: true,
  imports: [NgClass, SiComfortScoreStatusComponent, SiTranslateModule]
})
export class SiComfortScoreComponent implements OnChanges {
  /** @defaultValue [] */
  @Input() items: ComfortScoreItem[] = [];
  /** @defaultValue true */
  @Input({ transform: booleanAttribute }) showValues = true;
  /** @defaultValue false */
  @Input({ transform: booleanAttribute }) labelOnly = false;
  /** @defaultValue 2 */
  @Input() gridParts = 2;

  @Output() readonly selected = new EventEmitter<ComfortScoreItem>();

  protected gridPartArray: any[] = new Array(this.gridParts);

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.gridParts) {
      this.gridPartArray = new Array(this.gridParts);
    }
  }
}
