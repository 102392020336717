import { Directive, Input, OnChanges } from '@angular/core';
import { buildTrackByIdentity } from '@simpl/element-ng/common';

import { SelectGroup, SelectOptionNext } from '../si-select.types';
import { SI_SELECT_OPTIONS_STRATEGY } from './si-select-options-strategy';
import { SiSelectOptionsStrategyBase } from './si-select-options-strategy.base';

/**
 * The directive allows passing custom options.
 * Otherwise, use the {@link SiSelectSimpleOptionsDirective} directive.
 *
 * @deprecated Use {@link SiSelectSimpleOptionsDirective} instead.
 *
 * @example
 * ```html
 * <si-select [complexOptions]="['v1', 'v2', 'v3']"></si-select>
 * <si-select [complexOptions]="{ g1: ['g1.i1', 'g1.i2'], g2: ['g2.i1']}"></si-select>
 * ```
 */
// DEV-note: Do not remove this until @simpl/siemens-brand is ramped down for at least 1 year.
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'si-select[complexOptions]',
  providers: [
    { provide: SI_SELECT_OPTIONS_STRATEGY, useExisting: SiSelectComplexOptionsDirective }
  ],
  standalone: true
})
export class SiSelectComplexOptionsDirective<T>
  extends SiSelectOptionsStrategyBase<T>
  implements OnChanges
{
  /** Options to be shown in select dropdown. */
  @Input() complexOptions: T[] | Record<string, T[]> | null | undefined;

  /**
   * @deprecated Property has no effect and can be removed.
   *
   * @defaultValue
   * ```
   * buildTrackByIdentity<T>()
   * ```
   */
  @Input() trackBy = buildTrackByIdentity<T>();

  /**
   * By default, values are check on equality by reference. Override to customize the behavior.
   *
   * @defaultValue
   * ```
   * (a: T, b: T): boolean => a === b
   * ```
   */
  // override the input be to better understandable by consumer
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('optionEqualCheckFn') override optionsEqual = (a: T, b: T): boolean => a === b;

  /**
   * The valueProvider is used to extract the display text of a value.
   */
  @Input() valueProvider?: (dropdownOption: T) => string | undefined;

  /**
   * Provides Value for the display text of the dropdown group
   *
   * @defaultValue
   * ```
   * () => undefined
   * ```
   */
  @Input() groupProvider: (groupKey: string) => string | undefined = () => undefined;

  /**
   * The disabledProvider is used to display menu items as disabled.
   *
   * @defaultValue
   * ```
   * () => false
   * ```
   */
  @Input() disabledProvider: (dropdownOption: T) => boolean = () => false;

  ngOnChanges(): void {
    if (this.complexOptions instanceof Array) {
      this.allRows.set(this.convertOptionsArray(this.complexOptions));
    } else if (this.complexOptions) {
      this.allRows.set(
        Object.entries(this.complexOptions).map(
          ([key, value]) =>
            ({
              type: 'group',
              key,
              label: this.groupProvider(key) ?? key,
              options: this.convertOptionsArray(value)
            }) as SelectGroup<T>
        )
      );
    } else {
      this.allRows.set([]);
    }
    this.onFilter();
  }

  private convertOptionsArray(options: T[]): SelectOptionNext<T>[] {
    return options.map(option => ({
      type: 'option',
      value: option,
      label: (this.valueProvider ? this.valueProvider(option) : undefined) ?? option + '',
      typeaheadLabel: this.valueProvider ? this.valueProvider(option) : undefined,
      disabled: this.disabledProvider(option)
    }));
  }
}
