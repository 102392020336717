<div
  class="nav nav-tabs tab-container-header focus-inside"
  role="tablist"
  [class.is-scrollable]="scrollable"
  (siResizeObserver)="resize()"
>
  @if (scrollable) {
    <button
      type="button"
      class="tab-container-control focus-inside"
      tabindex="-1"
      aria-hidden="true"
      [hidden]="!xPos"
      (keydown.enter)="scrollStart()"
      (mousedown)="scrollStart()"
    >
      <i class="icon element-left-3 flip-rtl"></i>
    </button>
  }

  <div #tabContainer class="tab-container-buttonbar" (wheel)="mouseScroll($event)">
    <div #innerTabContainer class="tab-container-buttonbar-list focus-inside">
      @for (tab of tabPanels; track index; let index = $index) {
        <button
          #tabElement
          type="button"
          role="tab"
          class="nav-link focus-inside px-5"
          [attr.hidden]="tab.hidden || null"
          [attr.aria-controls]="tab.id"
          [id]="tab.tabId"
          [style.max-width.px]="tabButtonMaxWidth"
          [tabindex]="!tab.disabled && tab.active() && focusedTabIndex === undefined ? 0 : -1"
          [class.active]="tab.active()"
          [attr.aria-selected]="tab.active()"
          [class.disabled]="tab.disabled"
          (click)="selectTab(tab)"
          (keydown.arrowLeft)="focusPrevious()"
          (keydown.arrowRight)="focusNext()"
          (keydown.delete)="tab.closeTriggered.emit(tab)"
          (blur)="blur()"
          (focus)="focus(index)"
        >
          <span class="text-truncate">{{ tab.heading | translate }}</span>
          @if (tab.icon) {
            <si-icon [icon]="tab.icon" [alt]="tab.iconAltText" />
          }
          @if (tab.badgeContent) {
            <span class="badge" [ngClass]="'bg-' + tab.badgeColor">
              {{ tab.badgeContent | translate }}
            </span>
          }
          @if (tab.closable && !tab.disabled) {
            <i
              class="ps-4 btn btn-circle btn-xs btn-ghost element-cancel"
              aria-hidden="true"
              (click)="closeTab($event, tab)"
            ></i>
          }
        </button>
      }
    </div>
  </div>
  @if (scrollable) {
    <button
      type="button"
      class="tab-container-control is-end focus-inside"
      tabindex="-1"
      aria-hidden="true"
      [hidden]="endArrowDisabled"
      (keydown.enter)="scrollEnd()"
      (mousedown)="scrollEnd()"
    >
      <i class="icon element-right-3 flip-rtl"></i>
    </button>
  }
</div>

<ng-content />
