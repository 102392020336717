import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: "point-alarm-text",
  templateUrl: "./point-alarm-text.component.html"
})

export class PointAlarmTextComponent implements OnInit {
  @Input() public message: string;
  @Input() public translatedLabels: Map<string, string>;
  @Output() public readonly newMessageEvent = new EventEmitter<string>();

  public textForm: FormGroup<{ textSelection: FormControl<string | null> }>;
  private lastMessage: string;

  public ngOnInit(): void {
    this.textForm = new FormGroup({
      textSelection: new FormControl(
        { value: this.message, disabled: false },
        Validators.maxLength(1000)
      )
    });
    this.lastMessage = this.message;
  }

  public onTypeMessage(): void {
    const messageText = this.textForm.get("textSelection")?.value;
    if (
      messageText &&
      messageText !== this.lastMessage &&
      this.textForm.controls.textSelection.valid
    ) {
      this.newMessageEvent.emit(messageText);
      this.lastMessage = messageText;
    }
  }
}
