import { animate, query, style, transition, trigger } from '@angular/animations';
import { NgClass } from '@angular/common';
import {
  booleanAttribute,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  inject,
  Input,
  OnDestroy,
  Output,
  ViewChild
} from '@angular/core';
import { areAnimationsDisabled, BackgroundColorVariant } from '@simpl/element-ng/common';
import { SiTranslateModule } from '@simpl/element-translate-ng/translate';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { SiAccordionHCollapseService } from './si-accordion-hcollapse.service';
import { SiAccordionService } from './si-accordion.service';

let controlIdCounter = 1;

@Component({
  selector: 'si-collapsible-panel',
  templateUrl: './si-collapsible-panel.component.html',
  styleUrl: './si-collapsible-panel.component.scss',
  animations: [
    trigger('showHide', [
      transition('*=>hide', [
        style({ overflow: 'hidden' }),
        query(
          ':leave',
          [style({ blockSize: '*' }), animate('0.5s ease', style({ blockSize: '0' }))],
          { optional: true }
        )
      ]),
      transition('*=>show', [
        style({ overflow: 'hidden' }),
        query(
          ':enter',
          [style({ blockSize: '0' }), animate('0.5s ease', style({ blockSize: '*' }))],
          { optional: true }
        )
      ])
    ])
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, SiTranslateModule]
})
export class SiCollapsiblePanelComponent implements OnDestroy {
  /**
   * Heading for the collapsible panel.
   */
  @Input() heading?: string;
  /**
   * Additional CSS classes for the top element.
   *
   * @defaultValue ''
   */
  @Input() headerCssClasses = '';
  /**
   * Additional CSS classes for the collapsible content region.
   *
   * @defaultValue ''
   */
  @Input() contentBgClasses = '';
  /**
   * Additional CSS classes for the wrapping content element.
   *
   * @defaultValue ''
   */
  @Input() contentCssClasses = '';
  /**
   * Expand/collapse the panel.
   *
   * @defaultValue false
   */
  @HostBinding('class.opened') @Input({ transform: booleanAttribute }) opened = false;
  /**
   * The icon to be displayed besides the heading.
   */
  @Input() icon?: string;
  /**
   * Whether the si-collapsible-panel is disabled.
   *
   * @defaultValue false
   */
  @Input({ transform: booleanAttribute }) disabled = false;
  /** Color to use for component background */
  @HostBinding('class') @Input() colorVariant?: BackgroundColorVariant;
  /**
   * Defines the content of the optional badge. Should be a number or something like "100+".
   * if undefined or empty string, no badge is displayed
   */
  @Input() badge?: string | number;
  /**
   * Defines the background color of the badge. Default is specific to SiMPL flavour.
   */
  @Input() badgeColor?: string;

  /**
   * An event emitted when the user triggered expand/collapse and emit with the new open state.
   * The event is emitted before the animation happens.
   */
  @Output() readonly panelToggle = new EventEmitter<boolean>();

  /**
   * An event emitted when the user triggered expand/collapse.
   * The event is emitted before the animation happens.
   * @deprecated use {@link panelToggle} instead
   */
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() readonly toggle = this.panelToggle;

  @HostBinding('class.hcollapsed') protected hcollapsed = false;
  @HostBinding('class.full-height') protected fullHeight = false;
  protected controlId = '__si-collapsible-' + controlIdCounter++;
  protected headerId = this.controlId + '-header';

  private cdRef = inject(ChangeDetectorRef);
  private accordionService = inject(SiAccordionService, { optional: true });
  private accordionHCollapseService = inject(SiAccordionHCollapseService, { optional: true });
  private toggleSubscription = new Subscription();
  private enableAnimation = true;
  private readonly animationsGloballyDisabled = areAnimationsDisabled();
  private lastScrollPos = 0;

  @ViewChild('content', { static: true }) private contentRef!: ElementRef;

  constructor() {
    if (this.accordionService) {
      this.toggleSubscription.add(
        this.accordionService.fullHeight$.subscribe(resp => {
          this.fullHeight = resp;
          this.cdRef.markForCheck();
        })
      );
      this.toggleSubscription.add(
        this.accordionService.toggle$
          .pipe(filter(item => item !== this))
          .subscribe(() => this.openClose(false))
      );
    }
    if (this.accordionHCollapseService) {
      this.toggleSubscription.add(
        this.accordionHCollapseService.hcollapsed$.subscribe(state => {
          this.hcollapsed = state;
          this.cdRef.markForCheck();
        })
      );
    }
  }

  protected get showHide(): string {
    if (this.enableAnimation && !this.animationsGloballyDisabled) {
      return this.opened ? 'show' : 'hide';
    }
    return 'disabled';
  }

  protected get isHCollapsible(): boolean {
    return !!this.accordionHCollapseService;
  }

  ngOnDestroy(): void {
    this.toggleSubscription?.unsubscribe();
  }

  /**
   * Expand/collapse panel.
   * @param open - indicate the panel shall open or close
   * @param enableAnimation - with animation
   */
  openClose(open: boolean, enableAnimation = true): void {
    this.opened = open;
    this.enableAnimation = enableAnimation;
    this.cdRef.markForCheck();

    if (open) {
      setTimeout(() => {
        (this.contentRef.nativeElement as HTMLElement).scrollTop = this.lastScrollPos;
      });
    } else {
      this.lastScrollPos = (this.contentRef.nativeElement as HTMLElement).scrollTop;
    }
  }

  protected doToggle(event?: Event): void {
    if (this.disabled) {
      return;
    }

    event?.preventDefault();
    this.panelToggle.emit(!this.opened);
    this.openClose(this.hcollapsed || !this.opened);
    this.accordionService?.toggle$.next(this);
    if (this.hcollapsed) {
      this.accordionHCollapseService?.open$.next(this);
    }
  }

  protected keydown(event: KeyboardEvent): void {
    if (event.key === 'Enter' || event.key === 'Space' || event.key === ' ') {
      this.doToggle(undefined);
    }
  }
}
