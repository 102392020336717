import { BrowserObject, Command, CommandInput, CommandParameters, CommandParamType, Designation } from '@gms-flex/services';
import { isNullOrUndefined, TraceService } from '@gms-flex/services-common';
import { PropertyCommand } from '@simpl/buildings-ng';

import { TraceModules } from '../shared/trace-modules';
import { Bitstring64ParamViewModel } from './command-param-bitstring-long-vm';
import { Bitstring32ParamViewModel } from './command-param-bitstring-vm';
import { BACnetDateTimeParamViewModel } from './command-param-datetime-bacnet-vm';
import { DateTimeParamViewModel } from './command-param-datetime-vm';
import { DurationParamViewModel } from './command-param-duration-vm';
import { EnumParamViewModel } from './command-param-enum-vm';
import { LongParamViewModel } from './command-param-long-vm';
import { NumericParamViewModel } from './command-param-numeric-vm';
import { StringParamViewModel } from './command-param-string-vm';
import { CommandParamViewModel } from './command-param-vm';
import { PropertyDefinition } from './property-definition-vm';
import { ViewModelContext } from './snapin-vm.types';

// const Long: any = require('long');

/**
 * Types of internal parameters.
 */
enum InternalParameterType {
  CnsDesignation = 0
}

/**
 * Describes an internal command parameter.
 * Internal parameters are not prompted and so no VM is needed/created.  A list of internal
 * parameters descriptor are created if needed, and parameter values for these are injected into
 * the argument list when the command is executed.
 */
interface InternalParamDescriptor {
  type: InternalParameterType;
  position: number;
  id: string;
  nativeType: string;
  defaultValue?: any;
}

/**
 * Command view-model implementation.
 */
export class CommandViewModel {

  public readonly siCommand: PropertyCommand;

  protected readonly trmod: string = TraceModules.pvc;

  protected elBased: boolean;
  protected supported: boolean;
  protected active: boolean;
  protected paramList: CommandParamViewModel[];
  protected internalParamList: InternalParamDescriptor[];

  private readonly cnsDesignation: Designation;
  private readonly prioArrayWrite: boolean;
  private readonly prioArrayRelease: boolean;

  /**
   * Command identifier sent to server on execution.
   */
  public get id(): string {
    return this.cmd ? this.cmd.Id : undefined;
  }

  /**
   * Command description displayed in the UI.
   */
  public get description(): string {
    return this.cmd ? this.cmd.Descriptor : undefined;
  }

  /**
   * Indicates if this is the default command.
   */
  public get isDefault(): boolean {
    return this.cmd ? Boolean(this.cmd.IsDefault) : false;
  }

  public get isPriorityArrayWrite(): boolean {
    return this.prioArrayWrite;
  }

  public get isPriorityArrayRelease(): boolean {
    return this.prioArrayRelease;
  }

  /**
   * Indicates of command applies to individual array elements.
   */
  public get isElementBased(): boolean {
    return this.elBased;
  }

  /**
   * Command parameter list.
   */
  public get params(): readonly CommandParamViewModel[] {
    return this.paramList;
  }

  /**
   * Indicates if the command is supported.
   */
  public get isSupported(): boolean {
    return this.supported;
  }

  /**
   * Indicates if the command is enabled.
   */
  public get isEnabled(): boolean {
    return this.isSupported && this.isActive;
  }

  public get isActive(): boolean {
    return this.active;
  }

  public set isActive(flag: boolean) {
    this.active = flag;
    this.siCommand.disabled = !this.isEnabled;
  }

  /**
   * If a command is part of a group (i.e., has a defined GroupNumber), the
   * command should be hidden when disabled.
   */
  public get hideWhenDisabled(): boolean {
    return this.cmd ? this.cmd.GroupNumber !== undefined : false;
  }

  /**
   * Locale to use for formatting.
   */
  public get locale(): string {
    return this.vmContext.locale;
  }

  public get cmdRaw(): Command {
    return this.cmd;
  }

  public get groupNumber(): number {
    const val: number = this.cmd ? this.cmd.GroupNumber : Number.NaN;
    this.traceService.info(this.trmod, `Command group number = ${val}`);
    return val === undefined || Number.isNaN(val) ? 0 : val;
  }
  /**
   * Number of parameters defined for this command.
   */
  protected get paramCount(): number {
    return this.paramList ? this.paramList.length : 0;
  }

  /**
   * Compare order of parameters.
   * Return -n if a < b, +n if a > b, 0 if logically equivalent.
   */
  private static compareParamOrder(a: CommandParameters, b: CommandParameters): number {
    if (!a || isNaN(a.Order) || !b || isNaN(b.Order)) {
      return 0; // can't compare; just treat as equal so order as read from server doesn't change
    }
    return a.Order - b.Order;
  }

  /**
   * Constructor.
   */
  public constructor(
    private readonly traceService: TraceService,
    private readonly vmContext: ViewModelContext,
    private readonly oid: string,
    private readonly def: PropertyDefinition,
    private cmd: Command) {

    if (!cmd || !vmContext) {
      throw new Error('undefined argument');
    }

    this.supported = true; // may be set FALSE during parameter mapping
    this.active = false; // must be set explicitly (on command COV, usually)

    // If this command is associated with a single object, establish its designation
    // from the SNI selection context
    if (oid && vmContext.browserObjList) {
      const browserObj: BrowserObject = vmContext.browserObjList.find(bo => bo.ObjectId === oid);
      if (browserObj) {
        this.cnsDesignation = new Designation(browserObj.Designation);
      }
    }

    this.setParameters(this.cmd.Parameters);
    this.elBased = this.paramList?.some(vm => vm.inferred);

    this.prioArrayWrite = false;
    this.prioArrayRelease = false;
    if (this.def.isPriorityArray) {
      this.prioArrayWrite = (this.id === 'WritePrio');
      this.prioArrayRelease = (this.id === 'ReleasePrio');
    }

    this.siCommand = {
      action: this.id,
      id: this.id,
      title: this.description,
      parameters: this.params.map(p => p.siParam),
      disabled: !this.isEnabled
    };
  }

  /**
   * Create a CommandParameterViewModel based on a parameter description.
   */
  public createParameterViewModel(param: CommandParameters): CommandParamViewModel {
    if (!param) {
      throw new Error('null argument');
    }
    let vm: CommandParamViewModel;
    switch (param.DataType) {
      case 'BasicInt':
      case 'BasicUint':
      case 'BasicChar':
      case 'ExtendedInt':
      case 'ExtendedUint':
      case 'BasicFloat':
      case 'ExtendedReal':
      case 'ExtendedAny': // underlying type of `any` is ExtendedReal
        vm = new NumericParamViewModel(this.traceService, this.vmContext, param);
        break;

      case 'BasicInt64':
      case 'BasicUint64':
      case 'ExtendedInt64':
      case 'ExtendedUint64':
        vm = new LongParamViewModel(this.traceService, this.vmContext, param);
        break;

      case 'BasicBool':
      case 'ExtendedBool':
      case 'ExtendedEnum':
        vm = new EnumParamViewModel(this.traceService, this.vmContext, param);
        break;

      case 'BasicString':
        vm = new StringParamViewModel(this.traceService, this.vmContext, param);
        break;

      case 'BasicBit32':
      case 'ExtendedBitString':
        vm = new Bitstring32ParamViewModel(this.traceService, this.vmContext, param);
        break;

      case 'BasicBit64':
      case 'ExtendedBitString64':
        vm = new Bitstring64ParamViewModel(this.traceService, this.vmContext, param);
        break;

      case 'BasicTime':
        vm = new DateTimeParamViewModel(this.traceService, this.vmContext, param);
        break;

      case 'ExtendedDateTime':
        vm = new BACnetDateTimeParamViewModel(this.traceService, this.vmContext, param);
        break;

      case 'ExtendedDuration':
        vm = new DurationParamViewModel(this.traceService, this.vmContext, param);
        break;

      case 'BasicObjectOrPropertyId':
      case 'BasicLangText':
      case 'BasicBlob':
      case 'ExtendedApplSpecific':
      case 'ExtendedComplex':
      default:
        // Not supported!
        break;
    }

    return vm;
  }

  /**
   * Update the command VM with a provided Command object.
   */
  public updateCommand(cmd: Command): void {
    if (cmd) {
      if (cmd.Id !== this.cmd.Id) {
        throw new Error('updateCommand called with command object containing a different id');
      }
      this.cmd = cmd;
      if (cmd.Parameters) {
        cmd.Parameters.forEach(p => {
          const paramVm: CommandParamViewModel = this.paramList.find(pVm => pVm.id === p.Name);
          if (paramVm) {
            paramVm.updateParam(p);
          }
        });
      }
    }
  }

  /**
   * Align the command VM with another VM representing the same command definition but
   * on a different object.
   * This is used by an aggregate-property instance when aggregating commands of its
   * subordinate object-property instances.
   */
  public alignCommand(cmdVm: CommandViewModel): boolean {
    let isAligned = true;
    if (this.params && cmdVm.params) {

      isAligned = this.params.length === cmdVm.params.length;
      for (let idx = 0; idx < this.params.length && isAligned; ++idx) {
        const pX: CommandParamViewModel = this.paramList[idx];
        const pY: CommandParamViewModel = cmdVm.paramList.find(p => p.id === pX.id);
        if (pY) {
          isAligned = pX.alignParam(pY);
        } else {
          isAligned = false; // command parameter list mismatch!
        }
      }
    }

    return isAligned;
  }

  /**
   * Argument encoding.
   */
  public encodeArgs(args?: any[]): CommandInput[] {
    if (this.paramCount !== (args ? args.length : 0)) {
      throw new Error('incorrect number of arguments');
    }

    // Encode arguments
    const argsEncoded: CommandInput[] = [];
    for (let idx = 0; idx < this.paramCount; ++idx) {
      const paramVm: CommandParamViewModel = this.paramList[idx];
      const argValue: string = paramVm.encodeValue(args[idx]);
      if (!isNullOrUndefined(argValue)) {
        argsEncoded.push(this.createCommandInput(paramVm.id, paramVm.nativeType, argValue));
      } else {
        throw new Error(`argument ${idx} invalid`);
      }
    }

    // Inject arguments for any internal parameters
    this.injectInternalArgs(argsEncoded);

    return argsEncoded;
  }

  // Called after each command execution request to ready the command for the next time it is used
  public resetParamValues(): void {
    if (!this.paramList) {
      return;
    }
    this.paramList.forEach(p => p.resetParamValue());
  }

  /**
   * Set the list of command parameters.
   */
  private setParameters(params: CommandParameters[]): void {
    this.supported = true;
    this.paramList = [];
    this.internalParamList = [];
    if (params) {
      // Filter out potentially undefined params (unlikely) and sort by "Order" field
      const paramsSorted: CommandParameters[] = params
        .filter(p => !isNullOrUndefined(p))
        .sort((a, b) => CommandViewModel.compareParamOrder(a, b));

      // Create VM for each param
      paramsSorted.forEach((p, idx) => {
        if (p.ParameterType === CommandParamType.CnsDesignation) {
          // Internal parameter representing CNS designation of commanded data-point
          if (this.cnsDesignation) {
            const pInternal: InternalParamDescriptor = {
              type: InternalParameterType.CnsDesignation,
              position: idx,
              id: p.Name,
              nativeType: p.DataType,
              defaultValue: this.cnsDesignation.designation
            };
            this.internalParamList.push(pInternal);
          } else {
            this.traceService.warn(this.trmod,
              'Cns designation not provided with selection context.  Command will be disabled: prop=%s, cmd=%s|%s, param=%s|%s, paramType=%s',
              this.cmd.PropertyId,
              this.cmd.Id, this.cmd.Descriptor,
              p.Name, p.Descriptor,
              p.DataType);

            this.supported = false; // command not supported!
          }
        } else {
          // Normal (prompted) parameter; create a VM
          const pVm: CommandParamViewModel = this.createParameterViewModel(p);
          if (pVm) {
            this.paramList.push(pVm);
          } else {
            // Unsupported parameter type encountered!
            this.traceService.warn(this.trmod, 'Unsupported command parameter; command disabled: prop=%s, cmd=%s|%s, param=%s|%s, paramType=%s',
              this.cmd.PropertyId,
              this.cmd.Id, this.cmd.Descriptor,
              p.Name, p.Descriptor,
              p.DataType);

            this.supported = false; // command not supported!
          }
        }
      });

      if (!this.supported) {
        // Invalid or unsupported command parameters!
        this.paramList = [];
        this.internalParamList = [];
      }
    }
  }

  private injectInternalArgs(args: CommandInput[]): void {
    if (args && this.internalParamList) {
      this.internalParamList.forEach(pInternal => {
        let ci: CommandInput;
        switch (pInternal.type) {
          case InternalParameterType.CnsDesignation:
            ci = this.createCommandInput(pInternal.id, pInternal.nativeType, pInternal.defaultValue);
            break;
          default:
            throw new Error('Unrecognized internal parameter type');
        }

        args.splice(pInternal.position, 0, ci); // inject internal argument
      });
    }
  }

  /* eslint-disable */
  private createCommandInput(paramId: string, nativeType: string, argValue: string): CommandInput {
    return {
      Name: paramId,
      DataType: nativeType,
      Value: argValue,
      Comments: undefined,
      Password: undefined,
      SuperName: undefined,
      SuperPassword: undefined
    };
  }
  /* eslint-enable */
}
