/*
 * NOTICE: This component uses the same logic as the `si-list-interaction-item` directive
 * and is compatible with it.
 */

import { FocusableOption, FocusOrigin } from '@angular/cdk/a11y';
import { isPlatformBrowser, NgClass, NgTemplateOutlet } from '@angular/common';
import {
  booleanAttribute,
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  PLATFORM_ID,
  ViewChild
} from '@angular/core';
import { Direction, MenuItem } from '@simpl/element-ng/common';
import { SiDropdownToggleDirective } from '@simpl/element-ng/dropdown';
import { SiLinkDirective } from '@simpl/element-ng/link';
import {
  InteractableListDirective,
  InteractableListItem,
  InteractableListItemDirective,
  SiListInteractionService
} from '@simpl/element-ng/list-interaction';
import { SiTranslateModule } from '@simpl/element-translate-ng/translate';

/**
 * @deprecated The {@link SiMenuLegacyItemComponent} and all related symbols should no longer be used.
 * Please use {@link SiMenuModule} instead.
 * Read the {@link https://simpl.code.siemens.io/simpl-element/components/buttons-menus/menu/ | documentation} for more information.
 */
@Component({
  selector: 'si-menu-legacy-item',
  templateUrl: './si-menu-legacy-item.component.html',
  styleUrl: './si-menu-legacy-item.component.scss',
  standalone: true,
  imports: [NgClass, NgTemplateOutlet, SiLinkDirective, SiTranslateModule]
})
export class SiMenuLegacyItemComponent
  implements FocusableOption, OnDestroy, OnInit, InteractableListItemDirective
{
  private interactableListItem?: InteractableListItem;
  private isBrowser = isPlatformBrowser(inject(PLATFORM_ID));
  private _elementRef = inject(ElementRef<HTMLElement>);
  private listInteractionService = inject(SiListInteractionService);

  /**
   * Menu item to display.
   */
  @Input({ required: true })
  item!: MenuItem;

  /**
   * Specifies the badge style.
   *
   * @defaultValue ''
   */
  @Input() badgeStyle: 'inline' | 'dot' | '' = '';

  /**
   * Specifies the dropdown caret direction.
   *
   * @defaultValue ''
   */
  @Input() dropdownCaret: Direction | 'none' | '' = '';

  /**
   * Specifies an optional parameter that will be passed to the action function
   * of the item if it implements it.
   */
  @Input() actionParam?: any;

  /**
   * If item has children, do not allow it to be clicked.
   *
   * @defaultValue false
   */
  @Input({ transform: booleanAttribute }) disableParentClick = false;

  /**
   * If item does not have a link, action or children, do not allow it to be clicked.
   *
   * @defaultValue false
   */
  @Input({ transform: booleanAttribute }) disableLinklessClick = false;

  /**
   * Whether to disable the list interactability, used when only using this
   * for the styling or `MenuItem` interface. Also enables this to be manually focused.
   *
   * @defaultValue false
   */
  @Input({ transform: booleanAttribute }) disableListInteraction = false;

  /**
   * Specifies whether the menu item focus styling should be inside instead of outside.
   *
   * @defaultValue false
   */
  @Input({ transform: booleanAttribute }) focusStylingInside = false;

  /**
   * Forces the active styling
   *
   * @defaultValue false
   */
  @Input({ transform: booleanAttribute }) forceActive = false;

  /**
   * Force-show title, when iconOnly is set
   *
   * @defaultValue false
   */
  @Input({ transform: booleanAttribute }) forceTitle = false;

  /**
   * Class to use on the link
   *
   * @defaultValue 'dropdown-item'
   */
  @Input() itemClass = 'dropdown-item';

  /**
   * An optional siDropdownToggle instance to automatically open and close the dropdown on list interaction navigation
   */
  @Input() dropdownToggle?: SiDropdownToggleDirective;

  get disabled(): boolean {
    return (
      !(
        this._elementRef.nativeElement.offsetParent ||
        (this.isBrowser &&
          window.getComputedStyle(this._elementRef.nativeElement).position === 'fixed' &&
          window.getComputedStyle(this._elementRef.nativeElement).display !== 'none')
      ) ||
      this.item.disabled ||
      this.item.title === '-' ||
      this.item.isHeading ||
      ((!this.item.items || this.item.items.length === 0) &&
        !this.item.action &&
        !this.item.link &&
        !this.item.href &&
        this.disableLinklessClick)
    );
  }

  get theBadgeStyle(): string {
    return this.item.badgeStyle ?? this.badgeStyle;
  }

  get iconOnly(): boolean {
    return !!this.item.iconOnly && !this.forceTitle;
  }

  @Output() readonly activeChange = new EventEmitter<boolean>();

  // The badge character limit is restricted to three characters.
  // In case when the badge is of type `string` and the number of characters are more
  // than three, then only the first three characters will be displayed
  get badgeText(): string | number | undefined {
    const badge = this.item.badge;
    return typeof badge === 'string' && badge.length > 3
      ? badge.slice(0, 3)
      : typeof badge === 'number' && badge > 99
        ? '99+'
        : badge;
  }

  @ViewChild('menuItem') menuItem!: ElementRef;
  @ViewChild(SiLinkDirective) link!: SiLinkDirective;

  linkActiveChange(active: boolean): void {
    this.active = active;
    this.activeChange.emit(active);
  }

  /** @defaultValue false */
  active = false;

  get parentList(): InteractableListDirective | null {
    if (this.interactableListItem?.parent) {
      return this.interactableListItem.parent.directive;
    }
    return null;
  }

  get childList(): InteractableListDirective | null {
    if (
      this.interactableListItem?.child &&
      this.interactableListItem.child.trigger?.element === this.interactableListItem.element
    ) {
      return this.interactableListItem.child.directive;
    }
    return null;
  }

  get iconClass(): string | undefined {
    let iconClass = this.item.icon;

    // Use -filled icon if item is active
    // Keep the default icon in case that icon does not have a filled version
    if (
      this.item.isActive ||
      this.link?.active ||
      this.getHostElement().classList.contains('show')
    ) {
      iconClass = iconClass + ' ' + iconClass + '-filled';
    }
    return iconClass;
  }

  ngOnInit(): void {
    if (!this.disableListInteraction) {
      this.interactableListItem = this.listInteractionService.addInteractableListItem(
        this,
        this._elementRef.nativeElement
      );
    }
  }

  ngOnDestroy(): void {
    if (this.interactableListItem) {
      this.listInteractionService.removeInteractableListItem(this.interactableListItem);
    }
  }

  /** Focuses the menu item. */
  focus(origin?: FocusOrigin, options?: FocusOptions): void {
    if (this.interactableListItem?.parent) {
      this.interactableListItem.parent.directive.setActiveItem(this);
    }
    this.getItemElement().focus(options);
  }

  /** Returns the item DOM element. */
  getItemElement(): HTMLElement {
    return this.menuItem.nativeElement;
  }

  /** Returns the parent DOM element. */
  getParentElement(): HTMLElement {
    return this._elementRef.nativeElement.parentElement!;
  }

  /** Returns the host DOM element. */
  getHostElement(): HTMLElement {
    return this._elementRef.nativeElement!;
  }

  hasChildElement(element: HTMLElement): boolean {
    return this.interactableListItem
      ? this.listInteractionService.isChildElementOfItem(this.interactableListItem, element)
      : this._elementRef.nativeElement.contains(element);
  }

  getItemRole(item: MenuItem): string {
    if (item.selectionState === 'check' || item.type === 'check') {
      return 'menuitemcheckbox';
    }
    if (item.selectionState === 'radio' || item.type === 'radio') {
      return 'menuitemradio';
    }
    return 'menuitem';
  }

  getAriaChecked(item: MenuItem): boolean | null {
    if (item.selectionState === 'check' || item.selectionState === 'radio') {
      return true;
    } else if (item.type === 'check' || item.type === 'radio') {
      return false;
    }
    return null;
  }

  handleSpace(): void {
    if (this.item.type) {
      this.menuItem.nativeElement.click();
    }
  }
}
