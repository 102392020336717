import { NgModule } from '@angular/core';

import { SiCommissioningStatusComponent } from './si-commissioning-status.component';

/**
 * @deprecated The {@link SiCommissioningStatusModule} and all related symbols
 * should no longer be used and will be removed in Element v47.
 * The commissioning-status is now provided by `@simpl/buildings-ng`.
 */
@NgModule({
  imports: [SiCommissioningStatusComponent],
  exports: [SiCommissioningStatusComponent]
})
export class SiCommissioningStatusModule {}
