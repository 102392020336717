import { A11yModule } from '@angular/cdk/a11y';
import { NgClass } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  inject,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';

import { ModalRef } from './modalref';

@Component({
  selector: 'si-modal',
  templateUrl: './si-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [A11yModule, NgClass]
})
export class SiModalComponent implements OnInit, AfterViewInit, OnDestroy {
  protected modalRef = inject(ModalRef<unknown, any>);
  private changeDetectorRef = inject(ChangeDetectorRef);

  protected dialogClass = this.modalRef.dialogClass ?? '';
  protected titleId = this.modalRef.data?.ariaLabelledBy ?? '';
  protected init = false;
  protected show = false;
  protected showBackdropClass?: boolean;

  private clickStartInDialog = false;
  private origBodyOverflow?: string;
  private showTimer: any;
  private backdropTimer: any;
  private backdropGhostClickPrevention = true;

  @ViewChild('modalContainer') private modalContainerRef!: ElementRef;

  ngOnInit(): void {
    setTimeout(() => (this.backdropGhostClickPrevention = false), this.animationTime(300));
    this.init = true;
    this.showTimer = setTimeout(() => {
      this.show = true;
      this.changeDetectorRef.markForCheck();
    }, this.animationTime(150));
  }

  ngAfterViewInit(): void {
    queueMicrotask(() => this.modalRef?.shown.next(this.modalContainerRef));
  }

  ngOnDestroy(): void {
    this.hideBackdrop();
  }

  /** @internal */
  hideDialog(param?: any): void {
    clearTimeout(this.showTimer);
    if (!this.show) {
      return;
    }

    this.show = false;
    // set `detach()` in modal ref to no-op so that the animation is unaffected if called
    const detach = this.modalRef.detach;
    this.modalRef.detach = () => {};

    setTimeout(() => {
      this.hideBackdrop();
      this.changeDetectorRef.markForCheck();
      setTimeout(() => detach(), this.animationTime(150));
    }, this.animationTime(300));

    this.modalRef?.hidden.next(param);
    this.modalRef?.hidden.complete();
    this.modalRef?.message.complete();
    this.changeDetectorRef.markForCheck();
  }

  /** @internal */
  showBackdrop(): void {
    if (this.modalRef?.data.animated !== false) {
      this.showBackdropClass = false;
      this.backdropTimer = setTimeout(() => {
        this.showBackdropClass = true;
        this.changeDetectorRef.markForCheck();
      }, 16);
    } else {
      this.showBackdropClass = true;
    }
    this.origBodyOverflow = document.body.style.overflow;
    document.body.style.overflow = 'hidden';
    this.changeDetectorRef.markForCheck();
  }

  private hideBackdrop(): void {
    clearTimeout(this.backdropTimer);
    if (this.showBackdropClass !== undefined) {
      this.showBackdropClass = false;
    }
    if (this.origBodyOverflow !== undefined) {
      document.body.style.overflow = this.origBodyOverflow;
      this.origBodyOverflow = undefined;
    }
  }

  @HostListener('mousedown', ['$event'])
  protected clickStarted(event: MouseEvent): void {
    this.clickStartInDialog = event.target !== this.modalContainerRef.nativeElement;
  }

  @HostListener('mouseup', ['$event'])
  protected onClickStop(event: MouseEvent): void {
    const clickedInBackdrop =
      event.target === this.modalContainerRef.nativeElement && !this.clickStartInDialog;
    if (this.modalRef?.ignoreBackdropClick || !clickedInBackdrop) {
      this.clickStartInDialog = false;
      return;
    }

    if (!this.backdropGhostClickPrevention) {
      // Called when backdrop close is allowed and user clicks on the backdrop
      this.modalRef.messageOrHide(this.modalRef.closeValue);
    } else {
      // When in ghost click prevention mode, avoid text selection
      document.getSelection()?.removeAllRanges();
    }
  }

  @HostListener('window:keydown.esc', ['$event'])
  protected onEsc(event: KeyboardEvent): void {
    if (this.modalRef?.data.keyboard && this.modalRef?.isCurrent()) {
      event.preventDefault();
      this.modalRef.messageOrHide(this.modalRef.closeValue);
    }
  }

  private animationTime(millis: number): number {
    return this.modalRef?.data.animated !== false ? millis : 0;
  }
}
