import {
  AfterViewInit,
  booleanAttribute,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';

import { Cell, SiCalendarBodyComponent } from './si-calendar-body.component';

/**
 * Helper directive to set the initial focus to the calendar body cell.
 */
@Component({
  template: '',
  standalone: true
})
export class SiInitialFocusComponent implements AfterViewInit {
  /** The cell which has the mouse hover. */
  @Input() activeHover?: Cell;
  /** The date which is displayed as selected. */
  @Input() startDate?: Date;
  /** The date which is displayed as selected end. The value is only considered with enableRangeSelection. */
  @Input() endDate?: Date;
  /**
   * Guard to set the focus during ngAfterViewInit, we just set the focus after we first initialized the view
   *
   * @defaultValue true
   */
  @Input({ transform: booleanAttribute }) initialFocus = true;
  /** The minimum selectable date. */
  @Input() minDate?: Date;
  /** The maximum selectable date. */
  @Input() maxDate?: Date;
  /**
   * Optional input to control the minimum month the datepicker can show and the user can navigate.
   * The `minMonth` can be larger than the `minDate` This option enables the usage of multiple
   * datepickers next to each other while the more left calendar always
   * shows an earlier month the more right one.
   */
  @Input() minMonth?: Date;
  /**
   * Optional input to control the maximum month the datepicker can show and the user can navigate.
   * The `maxMonth` can be smaller than the `maxDate` This option enables the usage of multiple
   * datepickers next to each other while the more left calendar always
   * shows an earlier month the more right one.
   */
  @Input() maxMonth?: Date;
  /** Aria label for the previous button. Needed for a11y. */
  @Input() previousLabel!: string;
  /** Aria label for the next button. Needed for a11y. */
  @Input() nextLabel!: string;
  /**
   * Is range selection enabled, when enabled it shows a preview between the startDate and the focused date.
   *
   * @defaultValue false
   */
  @Input({ transform: booleanAttribute }) isRangeSelection = false;
  /**
   * Indicate whether a range preview shall be displayed.
   * When enabled a preview is visible between startDate and focusedDate.
   *
   * @defaultValue true
   */
  @Input({ transform: booleanAttribute }) previewRange = true;
  /** Required to establish a two-way binding of activeHover. The event emits on mouseover or focus changes. */
  @Output() readonly activeHoverChange = new EventEmitter<Cell>();
  /**
   * Emits when a new value is selected. In case where the value is null to
   * user aborted the selection by Escape.
   */
  @Output() readonly selectedValueChange = new EventEmitter<Date | null>();
  /** The body of calendar table */
  @ViewChild(SiCalendarBodyComponent) protected calendarBody?: SiCalendarBodyComponent;

  ngAfterViewInit(): void {
    if (this.initialFocus) {
      this.initialFocus = false;
      this.focusActiveCell();
    }
  }

  /**
   * Focus calendar cell which is marked as active cell.
   */
  focusActiveCell(): void {
    this.calendarBody!.focusActiveCell();
  }

  protected onActiveHoverChange(event: Cell): void {
    this.activeHover = event;
    this.activeHoverChange.emit(event);
  }
}
