import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import {
  SI_HEADER_DROPDOWN_OPTIONS,
  SiHeaderDropdownTriggerDirective
} from '@simpl/element-ng/header-dropdown';

/** Adds a navigation item to the header. Should be located inside `.header-navigation`. */
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'button[si-header-navigation-item], a[si-header-navigation-item]',
  standalone: true,
  template: `
    <div class="item-title">
      <ng-content />
    </div>
    @if (dropdownTrigger) {
      <div class="element-down-2 dropdown-caret ms-auto ps-0"></div>
    }
  `,
  host: {
    class: 'header-item focus-inside',
    '[class.dropdown-toggle]': '!!dropdownTrigger'
  },
  providers: [
    { provide: SI_HEADER_DROPDOWN_OPTIONS, useValue: { disableRootFocusTrapForInlineMode: true } }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SiHeaderNavigationItemComponent {
  /** @internal */
  dropdownTrigger = inject(SiHeaderDropdownTriggerDirective, {
    self: true,
    optional: true
  });
}
