import { Highlightable } from '@angular/cdk/a11y';
import {
  booleanAttribute,
  Directive,
  ElementRef,
  HostBinding,
  HostListener,
  inject,
  Input
} from '@angular/core';

import { AUTOCOMPLETE_LISTBOX } from './si-autocomplete.model';

@Directive({
  selector: '[siAutocompleteOption]',
  exportAs: 'siAutocompleteOption',
  standalone: true,
  host: {
    role: 'option'
  }
})
export class SiAutocompleteOptionDirective<T = unknown> implements Highlightable {
  private static idCounter = 0;
  private element = inject<ElementRef<HTMLElement>>(ElementRef);
  private parent = inject(AUTOCOMPLETE_LISTBOX);

  @Input()
  @HostBinding('id')
  id = `__si-autocomplete-option-${SiAutocompleteOptionDirective.idCounter++}`;
  @HostBinding('attr.aria-disabled') @Input({ transform: booleanAttribute }) disabled?: boolean;
  @Input('siAutocompleteOption') value?: T;

  @HostBinding('class.active') protected active?: boolean;

  @HostListener('click')
  protected click(): void {
    this.parent.siAutocompleteOptionSubmitted.emit(this.value);
  }

  /** @internal */
  setActiveStyles(): void {
    this.active = true;
    this.element.nativeElement.scrollIntoView({ block: 'nearest' });
  }

  /** @internal */
  setInactiveStyles(): void {
    this.active = false;
  }
}
