import { Injectable } from '@angular/core';
import { BrowserObject, Designation, DpIdentifier, ObjectNode, Page, SearchOption, SystemBrowserServiceBase, SystemBrowserSubscription,
  SystemBrowserSubscriptionKey, SystemsProxyServiceBase, TraceModules, ViewNode } from '@gms-flex/services';
import { isNullOrUndefined, TraceService } from '@gms-flex/services-common';
import { concatMap, delay, map, Observable, of, Subject, tap, throwError, zip } from 'rxjs';

import { Device } from '../../bx-services/device/device.model';
import { DeviceService } from '../../bx-services/device/device.service';
import { EquipmentType } from '../../bx-services/device/equipment-type.model';
import { EquipmentTypeService } from '../../bx-services/device/equipment-type.service';
import { Equipment } from '../../bx-services/device/equipment.model';
import { EquipmentService } from '../../bx-services/device/equipment.service';
import { FolderService, SystemFolder, trendsFolderEntityId } from '../../bx-services/folder/folder.service';
import { DisciplineEnumResponse } from '../../bx-services/location/enums-proxy.service';
import { EnumsService } from '../../bx-services/location/enums.service';
import { LocationEntityType, LocationType } from '../../bx-services/location/location-proxy.model';
import { LocationService } from '../../bx-services/location/location.service';
import { PointGroupType } from '../../bx-services/point/point-proxy.model';
import { PointService } from '../../bx-services/point/point.service';
import { CalendarService } from '../../bx-services/schedule/calendar.service';
import { ScheduleService } from '../../bx-services/schedule/schedule.service';
import { EntityType, FolderType } from '../../bx-services/shared/base.model';
import { PartitionService } from '../../bx-services/subscription/partition.service';
import { TrendProxyService } from '../../bx-services/trend/trend.proxy.service';
import { DisciplineMapperService } from '../shared/discipline-mapper.service';
import { ObjectTypeMapperService } from '../shared/object-type-mapper.service';
import { ContextService } from '../state/context.service';
import { EntityIdResolver } from './entity-id-resolver.service';
import { cBMSViewTypeBuilding, cBMSViewTypeDevices, SystemBrowserMapperBxToGmsService } from './system-browser-mapper-bx-to-gms.service';

const showGraphicFolders = false;

@Injectable()
export class SystemBrowserBxSubstituteService extends SystemBrowserServiceBase {

  private readonly dummyNotify: Subject<SystemBrowserSubscription> = new Subject<SystemBrowserSubscription>();
  private readonly selectedPartitions: string[] = [];

  public constructor(
    private readonly trace: TraceService,
    private readonly locationService: LocationService,
    private readonly partitionService: PartitionService,
    private readonly equipmentService: EquipmentService,
    private readonly equipmentTypeService: EquipmentTypeService,
    private readonly enumsService: EnumsService,
    private readonly deviceService: DeviceService,
    private readonly pointService: PointService,
    private readonly scheduleService: ScheduleService,
    private readonly calendarService: CalendarService,
    private readonly systemBrowerMapper: SystemBrowserMapperBxToGmsService,
    private readonly objectTypeMapper: ObjectTypeMapperService,
    private readonly disciplineMapper: DisciplineMapperService,
    private readonly folderService: FolderService,
    private readonly trendProxyService: TrendProxyService,
    private readonly contextService: ContextService,
    private readonly systemsService: SystemsProxyServiceBase,
    private readonly entityResolver: EntityIdResolver) {
    super();

    const savedSelectedPartitions = localStorage.getItem('selectedPartitions');
    if (!isNullOrUndefined(savedSelectedPartitions)) {
      // make partitions distinct
      const partitions = savedSelectedPartitions.split(',');
      const partMap = new Map<string, string>();
      for (const item of partitions) {
        partMap.set(item, item);
      }
      this.selectedPartitions = [];
      partMap.forEach((value, key) => this.selectedPartitions.push(value));
    }

    this.contextService.selectedPartitions$.subscribe(partitions => {
      if (!isNullOrUndefined(partitions) && partitions.length > 0) {
        this.trace.info(TraceModules.sysBrowser, `Current selected partitions: ${partitions.map(partition => partition.id).join()}`);
        this.initTypesAndDiscipline();
        // this.onPartitionSelectionChanged(partitions.map(partition => partition.id), partitions[0].relationships.ownedByCustomer.data.id);
      }
    });

    this.trace.info(TraceModules.sysBrowser, 'SystemBrowserBxSubstituteService created.');
  }

  /**
   * Gets views from the OM Api.
   * See also WSI API specification.
   *
   * @param systemId? Optional system Id. If specified, views from this system are returned only.
   * If not specified, views from all systems are returned.
   * @returns An observable with an array of {ViewNode} objects.
   *
   * @memberOf SystemBrowserService
   */
  public getViews(systemId?: string): Observable<ViewNode[]> {
    this.trace.debug(TraceModules.sysBrowser, 'SystemBrowserBxSubstituteService.getViews() called; systemId: %s', systemId);

    return this.systemsService.getSystemsExt().pipe(
      map(sro => this.systemBrowerMapper.mapSystemsToViewNodes(sro.Systems)),
      map(allViewNodes => {
        return systemId ? allViewNodes.filter(vn => vn.SystemId === systemId) : allViewNodes;
      }),
      tap(viewNodes => {
        this.trace.debug(TraceModules.sysBrowser, `SystemBrowserBxSubstituteService.getViews() returns:
        systemId: ${systemId}; No of views: ${viewNodes.length}`);
      })
    );
  }

  public getView(systemId: string, viewId: number): Observable<ViewNode> {
    this.trace.debug(TraceModules.sysBrowser, `SystemBrowserBxSubstituteService.getView() called; systemId: ${systemId}, viewId: ${viewId}`);

    return this.systemsService.getSystemsExt().pipe(
      map(sro => this.systemBrowerMapper.mapSystemsToViewNodes(sro.Systems)),
      map(allViewNodes => allViewNodes.find(vn => (vn.SystemId === systemId) && (vn.ViewId === viewId))),
      tap(viewNode => {
        this.trace.debug(TraceModules.sysBrowser, `SystemBrowserBxSubstituteService.getView() returns: view: ${viewNode.Designation}`);
      })
    );
  }

  /**
   * Gets the child nodes of the specified parent node.
   * See also WSI API specification.
   *
   * @param systemId
   * @param viewId
   * @param parentNode, the designation of the parent node.
   * @returns
   *
   * @memberOf SystemBrowserService
   */
  public getNodes(systemId: string, viewId: number, parentNode: string): Observable<BrowserObject[]> {
    const startTimeMs = performance.now();
    return this.getChildNodes(systemId, viewId, parentNode).pipe(
      tap(result => this.trace.info(TraceModules.sysBrowser, `SystemBrowserBxSubstituteService.getChildNodes() returns:
      parentNode: ${parentNode};
      time to return child nodes: ${performance.now() - startTimeMs} ms;
      number of nodes returned: ${result.length}`))
    );
  }

  public getChildNodes(systemId: string, viewId: number, parentNode: string, childNodeName?: string): Observable<BrowserObject[]> {
    if ((systemId === undefined) || (viewId === undefined) || (parentNode === undefined)) {
      return throwError(() => new Error('SystemBrowserBxSubstituteService.getChildNodes(): Invalid arguments!'));
    }
    this.trace.info(TraceModules.sysBrowser, `SystemBrowserBxSubstituteService.getChildNodes() called:
      systemId: ${systemId.toString()}; viewId: ${viewId.toString()}; parentNode: ${parentNode}; childNodeName: ${childNodeName}`);

    return this.getView(systemId, viewId).pipe(
      concatMap(viewNode => {
        if (parentNode === viewNode.Designation) {
          // Need to read the roots of the tree => these are the partition per system!
          return this.partitionService.getPartition(this.contextService.selectedCustomer.id, viewNode.SystemId).pipe(
            map(partition => [this.systemBrowerMapper.mapPartitionToTreeRoot(partition, viewNode)])
          );
        } else {
          const entityId = this.systemBrowerMapper.getEntityId(parentNode);
          if (entityId !== undefined) {
            const entityType = this.systemBrowerMapper.getEntityType(entityId);
            const parentLocation = this.systemBrowerMapper.getDccLocation(parentNode);
            const parentDesignationDisplay = this.systemBrowerMapper.getDesignationDisplay(parentNode);
            // TODO: Type enumeration vs language ?
            if ((entityType !== undefined) && (parentLocation !== undefined)) {
              if (entityType === EntityType.Customer) {
                this.trace.error(TraceModules.sysBrowser, 'SystemBrowserBxSubstituteService: Coding error, to be fixed');
                return of([]);
              } else if (entityType === EntityType.Partition) {
                return this.getLocationRoots(entityId, viewNode, parentNode, parentDesignationDisplay, parentLocation, childNodeName);
              } else {
                const partitionId = this.systemBrowerMapper.getPartitionId(entityId);
                if (entityType === EntityType.Device) {
                  if (this.systemBrowerMapper.isGatewayDevice(parentNode)) {
                    return this.getGatewayChildren(
                      viewNode, parentNode, parentDesignationDisplay, parentLocation, partitionId, entityId, entityType, childNodeName)
                  } else {
                    return this.getDeviceOrEquipmentChildren(
                      partitionId, entityId, entityType, viewNode, parentNode, parentDesignationDisplay, parentLocation, childNodeName);
                  }
                } else if (entityType === EntityType.Equipment) {
                  return this.getDeviceOrEquipmentChildren(
                    partitionId, entityId, entityType, viewNode, parentNode, parentDesignationDisplay, parentLocation, childNodeName);
                } else if (entityType === EntityType.Point) {
                  // no children
                  return of([]);
                } else if (entityType === EntityType.Schedule) {
                  // no children
                  return of([]);
                } else if (entityType === EntityType.Calendar) {
                  // no children
                  return of([]);
                } else if (entityType === EntityType.TrendViewDefinition) {
                  // no children
                  return of([]);
                } else if (entityType === EntityType.Folder) {
                  return this.getFolderChildren(
                    viewNode, parentNode, parentLocation, parentDesignationDisplay, partitionId, entityId, entityType, childNodeName);
                } else {
                  // loads location children into the tree. The loaded types depend on the view type
                  return this.getLocationChildren(viewNode, parentNode, parentDesignationDisplay, parentLocation, partitionId, entityId, childNodeName);
                }
              }
            } else {
              this.trace.error(TraceModules.sysBrowser, 'SystemBrowserBxSubstituteService: Coding error, to be fixed');
              return of([]);
            }
          } else {
            // This use case is not needed in V1.
            // Needs to be handled by resolving the parent object Id first!
            this.trace.error(TraceModules.sysBrowser, 'SystemBrowserBxSubstituteService: Unhandled use case, not needed in cBMS V1 yet!');
            return of([]);
          }
        }
      })
    );
  }

  public getPartition(entityId): Observable<string | undefined> {
    // TODO: need to check if roundtrip the server is needed!!!
    return of(this.systemBrowerMapper.getPartitionId(entityId));
  }

  /**
   * Searches for nodes.
   * For details see WSI specification.
   *
   * @param systemId
   * @param searchString
   * @param [viewId=undefined]
   * @param [searchOption=undefined]
   * @param [caseSensitive=undefined]
   * @param [groupByParent=undefined]
   * @param [size=undefined]
   * @param [page=undefined]
   * @param [disciplineFilter=undefined]
   * @param [objectTypeFilter=undefined]
   * @param [aliasFilter=undefined]
   * @returns
   *
   * @memberOf SystemBrowserService
   */
  public searchNodes(systemId: string, searchString: string, viewId: number = undefined, searchOption: SearchOption = undefined,
    caseSensitive: boolean = undefined, groupByParent: boolean = undefined, size: number = undefined, page: number = undefined,
    disciplineFilter: string = undefined, objectTypeFilter: string = undefined, alarmSuppresion: boolean = undefined,
    aliasFilter: string = undefined): Observable<Page> {

    this.trace.info(TraceModules.sysBrowser, `SystemBrowserBxSubstituteService.searchNodes() called:
      systemId: ${systemId.toString()}; searchString: ${searchString}`);

    if (searchString === 'ApplicationViewTrendFolderTvd') {
      // TODO: Hack: This is the trend viewer asking for the application trends folder. Needs to be abstracted.
      // By now, we return the 'Trends Folder below Building: Beni for some tests.
      // searchString = 'System1:65a6f1a3-58f2-4910-8f38-11589e993b9d';
      searchString = `5fe31ef1-5fc8-49f3-84cc-263f17b656fb:${trendsFolderEntityId}`;
    }

    const startTimeMs = performance.now();
    let searchObs: Observable<Page>;

    if (objectTypeFilter === undefined) {
      switch (searchOption) {
        case SearchOption.objectId:
          const dpId = new DpIdentifier(searchString);
          const entityId = dpId.objectIdWoSystem;
          const partitionId = dpId.systemName;
          searchObs = this.getViews(systemId).pipe(
            concatMap(viewNodes => {
              const search$ = viewNodes.map(vn => this.resolveObjectsInt(partitionId, entityId, vn));
              if (search$.length === 0) {
                /* eslint-disable-next-line @typescript-eslint/naming-convention */
                return of({ Page: 1, Size: 1000, Total: 0, Nodes: [] });
              }

              return zip(search$).pipe(
                map((result: Page[]) => {
                  /* eslint-disable @typescript-eslint/naming-convention */
                  if (result && result.length > 0) {
                    return result.reduce((acc, current) => {
                      return { Page: 1, Size: 1000, Total: acc.Total + current.Total, Nodes: [...acc.Nodes, ...current.Nodes] }
                    }, { Page: 1, Size: 1000, Total: 0, Nodes: [] });
                  } else {
                    return { Page: 1, Size: 1000, Total: 0, Nodes: [] }
                  }
                  /* eslint-enable @typescript-eslint/naming-convention */
                })
              );
            })
          );
          break;
        case SearchOption.designation:
          searchObs = this.getView(systemId, viewId).pipe(
            concatMap(viewNode => this.resolveDesignation(searchString, viewNode))
          );
          break;
        case SearchOption.description:
          searchObs = of(undefined);
          break;
        case SearchOption.alias:
          searchObs = of(undefined);
          break;
        default:
          searchObs = of(undefined);
          break;
      }
    } else {
      // PoC: only subtype filtering on equipment supported!!
      const filter: { [type: string]: number[] } = JSON.parse(objectTypeFilter);
      const types = Object.keys(filter);
      const subtypeIds = filter[types[0]];
      // TODO: retrieve partition from node or customer selector
      // TODO: support multiple filter selections
      const ids = subtypeIds.map(id => this.objectTypeMapper.dccSubTypeIdToEquipmentType(id)?.id);
      searchObs = this. searchEquipmentTypes(ids, systemId, viewId);
    }

    return searchObs.pipe(
      tap(result => {
        const desFound = result.Nodes[0]?.Designation;
        this.trace.info(TraceModules.sysBrowser, `SystemBrowserBxSubstituteService.searchNodes() returns:
        searchstring: ${searchString}
        first node found: ${desFound}
        time to return nodes: ${performance.now() - startTimeMs} ms;`);
      })
    );
  }

  public searchNodeMultiple(systemId: string, searchString: string[], groupByParent?: boolean): Observable<ObjectNode[]> {
    for (let idx = 0; idx < searchString.length; idx++) {
      if (searchString[idx] === 'ApplicationViewTrendFolderTvd') {
        // TODO: Hack: This is the trend viewer asking for the application trends folder. Needs to be abstracted.
        // By now, we return the child folder of 'Building': Beni for some tests.
        // searchString[idx] = 'System1:65a6f1a3-58f2-4910-8f38-11589e993b9d';
        searchString[idx] = `System1:${trendsFolderEntityId}`;
      }
    }

    // TODO: handle grouping
    const search$ = searchString.map(id => this.searchNodes(systemId, id, undefined, SearchOption.objectId));
    return zip(search$).pipe(
      map((result: Page[]) => {
        const objectNodes: ObjectNode[] = [];
        for (let idx = 0; idx < result.length; idx++) {
          /* eslint-disable-next-line @typescript-eslint/naming-convention */
          objectNodes.push({ ObjectId: searchString[idx], ErrorCode: 0, Nodes: result[idx].Nodes });
        }
        return objectNodes;
      }),
      // TODO: Workaround, without the delay, the trend snapin runs into an exception as it expects the answer not in the same event loop cycle
      // -> fix needed in trends
      delay(10)
    );
  }

  public searchViewNodeMultiple(systemId: any, viewId: any, deviceIdArr: string[]): Observable<any> {
    return throwError(() => new Error('OmSystemsProxyService.searchViewNodeMultiple(): Not Implemented!'));
  }

  /**
   * Subscribes for system browser node changes
   * Horizon does not support adding node by now.
   *
   * @param designations
   * @returns
   *
   * @memberOf SystemBrowserService
   */
  public subscribeNodeChanges(designations: string[]): Observable<SystemBrowserSubscriptionKey> {
    return throwError(() => new Error('SystemBrowserOmService.subscribeNodeChanges(): Not Implemented!'));
  }

  public nodeChangeNotification(): Observable<SystemBrowserSubscription> {
    return this.dummyNotify;
  }

  public resolvePoint(entityId: string, partitionId: string): Observable<Page> {
    this.trace.info(TraceModules.sysBrowser, `SystemBrowserBxSubstituteService.resolveEntity() called: pointId: ${entityId}; partitionId: ${partitionId}`);
    const startTimeMs = performance.now();

    if (entityId !== undefined) {
      return this.getViews().pipe(
        concatMap(viewNodes => {
          // TODO: fix partitonId check and view type
          const viewNode = viewNodes.find(vNode => (vNode.SystemName === partitionId && (vNode.ViewType === cBMSViewTypeDevices)));
          return this.findPoint(entityId, viewNode, partitionId).pipe(
            tap(result => {
              this.trace.info(TraceModules.sysBrowser, `SystemBrowserBxSubstituteService.resolvePoint() returns:
              partitionId: ${partitionId}
              entityId: ${entityId}
              node found: ${(result?.Nodes?.map(node => node.Designation)).join(' - ')}
              time to return nodes: ${performance.now() - startTimeMs} ms;`);
            })         
          );
        })
      );
    } else {
      /* eslint-disable-next-line @typescript-eslint/naming-convention */
      of({ Page: 1, Size: 1000, Total: 0, Nodes: [] });
    }
  }

  public resolveDevice(deviceId: string, partitionId: string): Observable<Page> {
    this.trace.info(TraceModules.sysBrowser, `SystemBrowserBxSubstituteService.resolveDevice() called: deviceId: ${deviceId}; partitionId: ${partitionId}`);

    if (deviceId !== undefined) {
      return this.getViews().pipe(
        concatMap(viewNodes => {
          // TODO: fix partitonId check and view type
          const viewNode = viewNodes.find(vNode => (vNode.SystemName === partitionId && (vNode.ViewType === cBMSViewTypeDevices)));
          return this.findDevice(deviceId, viewNode, partitionId);
        })
      );
    } else {
      /* eslint-disable-next-line @typescript-eslint/naming-convention */
      of({ Page: 1, Size: 1000, Total: 0, Nodes: [] });
    }
  }

  public resolveLocation(locationId: string, partitionId: string): Observable<Page> {
    this.trace.info(TraceModules.sysBrowser,
      `SystemBrowserBxSubstituteService.resolveLocation() called: locationId: ${locationId}; partitionId: ${partitionId}`);

    if (locationId !== undefined) {
      return this.getViews().pipe(
        concatMap(viewNodes => {
          // TODO: fix partitonId check and view type
          const viewNode = viewNodes.find(vNode => (vNode.SystemName === partitionId && (vNode.ViewType === cBMSViewTypeDevices)));
          return this.findLocation(locationId, viewNode, partitionId);
        })
      );
    } else {
      /* eslint-disable-next-line @typescript-eslint/naming-convention */
      of({ Page: 1, Size: 1000, Total: 0, Nodes: [] });
    }
  }

  public resolveObjects(partitionId: string, entityId: string): Observable<Page> {
    return this.getViews().pipe(
      concatMap(viewNodes => {
        const search$ = viewNodes.map(vn => this.resolveObjectsInt(partitionId, entityId, vn));
        return zip(search$).pipe(
          map((result: Page[]) => {
            /* eslint-disable @typescript-eslint/naming-convention */
            if (result && result.length > 0) {
              return result.reduce((acc, current) => {
                return { Page: 1, Size: 1000, Total: acc.Total + current.Total, Nodes: [...acc.Nodes, ...current.Nodes] }
              }, { Page: 1, Size: 1000, Total: 0, Nodes: [] });
            } else {
              return { Page: 1, Size: 1000, Total: 0, Nodes: [] }
            }
            /* eslint-enable @typescript-eslint/naming-convention */
          })
        );
      })
    );
  }

  /**
   * Deprecated
   * Needs to be properly implemented via resolve methods and multiple views!!
   * Currently only works for loaded nodes!!!
   */
  public resolveParents(partitionId: string, entityId: string): Observable<Page> {
    // TODO: properly resolve the ID.
    // Note that schedules and calendars cannot be resolved!
    const parentEntity = this.systemBrowerMapper.getParentEntityId(entityId);
    /* eslint-disable-next-line @typescript-eslint/naming-convention */
    return of({ Page: 1, Size: 1000, Total: 1, Nodes: this.systemBrowerMapper.getBrowserObjects(parentEntity) });
  }

  public getChildNodeByNameRecursive(systemId: string, viewId: number, parentNode: string, childNodeNames: string[]): Observable<BrowserObject[]> {
    if (childNodeNames.length > 0) {
      return this.getChildNodes(systemId, viewId, parentNode, childNodeNames[0]).pipe(
        concatMap(objectNodes => {
          if (childNodeNames.length > 1) {
            childNodeNames.splice(0, 1);
            return this.getChildNodeByNameRecursive(systemId, viewId, objectNodes[0].Designation, childNodeNames);
          } else {
            return of(objectNodes);
          }
        })
      );
    }
  }

  private getLocationRoots(partitionId: string,
    viewNode: ViewNode, parentNode: string, parentDesignationDisplay: string, parentLocation: string, childNodeName?: string): Observable<BrowserObject[]> {

    return this.locationService.getLocationRoots(partitionId, childNodeName, 'Id').pipe(
      map(data => this.systemBrowerMapper.mapLocationRoots(
        data.campusRoots, data.buildingRoots, viewNode, parentNode, parentDesignationDisplay, parentLocation, partitionId)));
  }

  private getLocationChildren(
    viewNode: ViewNode, parentDesignation: string, parentDesignationDisplay: string, parentLocation: string, partitionId: string,
    parentEntityId: string, childName?: string): Observable<BrowserObject[]> {

    let obs$5: Observable<SystemFolder> = of(undefined);
    if (parentDesignation === '5fe31ef1-5fc8-49f3-84cc-263f17b656fb.LogicalView:Thomas Environment.Beni') {
      // we create a single folder for the trend views. Flex Client does currentlty allow trend view only in the trend application folder (and its children)
      obs$5 = this.folderService.getRootFolder(parentEntityId, FolderType.Trend);
    }

    const entitySubType = this.systemBrowerMapper.getEntitySubType(parentEntityId);
    let obs$6: Observable<SystemFolder> = of(undefined);
    if (entitySubType === LocationEntityType.Building && viewNode.ViewType === cBMSViewTypeBuilding && showGraphicFolders) {
      // we create a single folder for the graphic views.
      // Flex Client does currently allow graphic view only in the graphic application folder (and its children).
      obs$6 = this.folderService.getRootFolder(parentEntityId, FolderType.Graphic);
    }

    let obs$1: Observable<LocationType[]> = this.locationService.getLocationChildren(partitionId, parentEntityId, childName, 'Id');
    if ((viewNode.ViewType === cBMSViewTypeDevices) && (entitySubType === LocationEntityType.Building)) {
      obs$1 = of(undefined);
    }
    let obs$2: Observable<Device[]> = of(undefined);
    let obs$3: Observable<Equipment[]> = of(undefined);
    let obs$4: Observable<EquipmentType[]> = of(undefined);
    if (viewNode.ViewType === cBMSViewTypeDevices) {
      obs$2 = this.deviceService.getDevicesOfLocation(partitionId, parentEntityId, childName, 'Id');
    }
    if (viewNode.ViewType === cBMSViewTypeBuilding) {
      obs$3 = this.equipmentService.getEquipmentsOfLocation(partitionId, parentEntityId, childName, 'Id');
      obs$4 = this.equipmentTypeService.getEquipmentTypes(partitionId);
    }
    return zip(obs$1, obs$2, obs$3, obs$4, obs$5, obs$6).pipe(
      map(result => this.systemBrowerMapper.mapLocationChildren(
        result[0], viewNode, parentDesignation, parentDesignationDisplay, parentLocation, parentEntityId, partitionId,
        result[1], result[2], result[3], (childName !== undefined), result[4], result[5]))
    );
  }

  private getFolderChildren(
    viewNode: ViewNode, parentDesignation: string, parentDesignationDisplay: string, parentLocation: string, partitionId: string, parentEntityId: string,
    entityType: string, childName?: string): Observable<BrowserObject[]> {

    // TODO support childname filtering
    const obs$1 = this.folderService.getFolders(parentEntityId);
    // TODO support reading trend views
    const obs$2 = this.trendProxyService.getTrends(parentEntityId);

    return zip(obs$1, obs$2).pipe(
      map(result => this.systemBrowerMapper.mapFolderChildren(
        result[0], result[1], viewNode, parentDesignation, parentDesignationDisplay, parentLocation, parentEntityId, partitionId))
    );
  }

  private getGatewayChildren(
    viewNode: ViewNode, parentDesignation: string, parentDesignationDisplay: string, parentLocation: string, partitionId: string,
    parentEntityId: string, entityTypeParent: PointGroupType, childName?: string): Observable<BrowserObject[]> {

    const obsDvc = this.getDevicesBehindGateway(
      partitionId, parentEntityId, viewNode, parentDesignation, parentDesignationDisplay, parentLocation, childName);
    const obsPts = this.getDeviceOrEquipmentChildren(
      partitionId, parentEntityId, entityTypeParent, viewNode, parentDesignation, parentDesignationDisplay, parentLocation, childName);
    return zip(obsDvc, obsPts).pipe(
      map(result => result[0].concat(result[1]))
    );
  }

  private initTypesAndDiscipline(): void {
    // TODO: move to types mapper?
    if (this.selectedPartitions?.length > 0) {
      const equipmentTypes$: Observable<EquipmentType[]>[] = this.selectedPartitions.map(id => this.equipmentTypeService.getEquipmentTypes(id));
      const disciplines$: Observable<DisciplineEnumResponse>[] = this.selectedPartitions.map(id => this.enumsService.getDisciplinesEnum(id));
      const eqZipped$ = zip(equipmentTypes$).pipe(
        // side effect: eagerly read all equipment types and cache them per partition before returning the partion browser nodes
        tap(results => this.objectTypeMapper.initWithTypesFromEquipmentService(results))
      );
      const enZipped$ = zip(disciplines$).pipe(
        // side effect: eagerly read all disciplines and cache them per partition before returning the partion browser nodes
        tap(results => this.disciplineMapper.initWithDisciplinesFromEnumsService(results))
      );
      zip(eqZipped$, enZipped$).subscribe();
    }
  }

  private getDeviceOrEquipmentChildren(partitionId: string, entityId: string, pgType: PointGroupType,
    viewNode: ViewNode, parentNode: string, parentDesignationDisplay: string, parentLocation: string, childNodeName?: string): Observable<BrowserObject[]> {

    const obsPts = this.getPoints(partitionId, entityId, pgType, viewNode, parentNode, parentDesignationDisplay, parentLocation, childNodeName);
    const obsSchedules = this.getSchedules(partitionId, entityId, viewNode, parentNode, parentDesignationDisplay, parentLocation, childNodeName);
    const obsCalendars = this.getCalendars(partitionId, entityId, viewNode, parentNode, parentDesignationDisplay, parentLocation, childNodeName);
    return zip(obsPts, obsSchedules, obsCalendars).pipe(
      map(result => result[0].concat(result[1]).concat(result[2]))
    );
  }

  private getPoints(partitionId: string, entityId: string, entityType: PointGroupType,
    viewNode: ViewNode, parentNode: string, parentDesignationDisplay: string, parentLocation: string, childNodeName?: string): Observable<BrowserObject[]> {

    return this.pointService.getPoints(partitionId, entityId, entityType, childNodeName, 'Id').pipe(
      tap(points => {
        this.trace.info(TraceModules.sysBrowser,
          `SystemBrowserBxSubstituteService: getPoints() retrieved no of points: ${points.length}`);
      }),
      map(points => this.systemBrowerMapper.mapPoints(points, viewNode, parentNode, parentDesignationDisplay, parentLocation, entityId, partitionId)));
  }

  private getSchedules(partitionId: string, entityId: string,
    viewNode: ViewNode, parentNode: string, parentDesignationDisplay: string, parentLocation: string, childNodeName?: string): Observable<BrowserObject[]> {

    return this.scheduleService.getSchedules(partitionId, entityId, childNodeName, 'Id').pipe(
      tap(schedules => {
        this.trace.info(TraceModules.sysBrowser,
          `SystemBrowserBxSubstituteService: getSchedules() retrieved no of schedules: ${schedules.length}`);
      }),
      map(schedules => this.systemBrowerMapper.mapSchedules(schedules, viewNode, parentNode, parentDesignationDisplay, parentLocation, entityId, partitionId)));
  }

  private getCalendars(partitionId: string, entityId: string,
    viewNode: ViewNode, parentNode: string, parentDesignationDisplay: string, parentLocation: string, childNodeName?: string): Observable<BrowserObject[]> {

    return this.calendarService.getCalendars(partitionId, entityId, childNodeName, 'Id').pipe(
      tap(calendars => {
        this.trace.info(TraceModules.sysBrowser,
          `SystemBrowserBxSubstituteService: getCalendars() retrieved no of calendars: ${calendars.length}`);
      }),
      map(calendars =>
        this.systemBrowerMapper.mapCalendars(calendars, viewNode, parentNode, parentDesignationDisplay, parentLocation, entityId, partitionId)));
  }

  private getDevicesBehindGateway(partitionId: string, entityId: string,
    viewNode: ViewNode, parentNode: string, parentDesignationDisplay: string, parentLocation: string, childNodeName?: string): Observable<BrowserObject[]> {

    return this.deviceService.getDevicesBehindGateway(partitionId, entityId, childNodeName, 'Id').pipe(
      map(childDevices =>
        this.systemBrowerMapper.mapDevicesBehindGateway(childDevices, viewNode, parentNode, parentDesignationDisplay, parentLocation, partitionId))
    );
  }

  private resolveDesignation(searchString: string, viewNode: ViewNode): Observable<Page> {
    // No wildcard support here yet
    const node = this.systemBrowerMapper.getBrowserObjectPerDesignation(searchString);
    if (node !== undefined) {
      /* eslint-disable-next-line @typescript-eslint/naming-convention */
      return of({ Page: 1, Size: 1000, Total: 1, Nodes: [node] });
    }

    const designation = new Designation(searchString);
    designation.designationParts.splice(0, 2);
    return this.getChildNodeByNameRecursive(viewNode.SystemId, viewNode.ViewId, viewNode.Designation, designation.designationParts).pipe(
      /* eslint-disable-next-line @typescript-eslint/naming-convention */
      map(nodes => nodes ? { Page: 1, Size: 1000, Total: nodes.length, Nodes: nodes } : { Page: 1, Size: 1000, Total: 0, Nodes: [] })
    );
  }

  private resolveObjectsInt(partitionId: string, entityId: string, viewNode: ViewNode): Observable<Page> {
    // No wildcard support here
    if ((!partitionId) || (!entityId)) {
      this.trace.error(TraceModules.sysBrowser,
        `SystemBrowserBxSubstituteService.resolveObjects() called with invalid parameters.`);
      return throwError(() => new Error('SystemBrowserBxSubstituteService.resolveObjects() called with invalid parameters'));
    }

    let find$: Observable<Page>[];
    const entityType = this.systemBrowerMapper.getEntityType(entityId);
    if (entityType) {
      // optimize search if due to browsing the entity has been read once
      if (entityType === EntityType.Partition) {
        find$ = [this.findPartition(entityId, viewNode)];
      } else if (entityType === EntityType.Location) {
        find$ = [this.findLocation(entityId, viewNode, partitionId)];
      } else if (entityType === EntityType.Device) {
        if (viewNode.ViewType === cBMSViewTypeDevices) {
          find$ = [this.findDevice(entityId, viewNode, partitionId)];
        } else {
          /* eslint-disable-next-line @typescript-eslint/naming-convention */
          return of({ Page: 1, Size: 1000, Total: 0, Nodes: [] });
        }
      } else if (entityType === EntityType.Equipment) {
        if (viewNode.ViewType === cBMSViewTypeBuilding) {
          find$ = [this.findEquipment(entityId, viewNode, partitionId)];
        } else {
          /* eslint-disable-next-line @typescript-eslint/naming-convention */
          return of({ Page: 1, Size: 1000, Total: 0, Nodes: [] });
        }
      } else if (entityType === EntityType.Point) {
        find$ = [this.findPoint(entityId, viewNode, partitionId)];
      } else if (entityType === EntityType.Calendar) {
        find$ = [this.findCalendar(entityId, viewNode, partitionId)];
      } else if (entityType === EntityType.Schedule) {
        find$ = [this.findSchedule(entityId, viewNode, partitionId)];
      } else if (entityType === EntityType.Folder) {
        if (viewNode.ViewType === cBMSViewTypeBuilding) {
          find$ = [this.findFolder(entityId, viewNode, partitionId)];
        } else {
          /* eslint-disable-next-line @typescript-eslint/naming-convention */
          return of({ Page: 1, Size: 1000, Total: 0, Nodes: [] });
        }
      } else {
        /* eslint-disable-next-line @typescript-eslint/naming-convention */
        return of({ Page: 1, Size: 1000, Total: 0, Nodes: [] });
      }
    } else {
      // we need to search on all APIs
      if (viewNode.ViewType === cBMSViewTypeDevices) {
        find$ = [
          this.findPartition(entityId, viewNode),
          this.findLocation(entityId, viewNode, partitionId),
          this.findDevice(entityId, viewNode, partitionId),
          this.findPoint(entityId, viewNode, partitionId),
          this.findCalendar(entityId, viewNode, partitionId),
          this.findSchedule(entityId, viewNode, partitionId)
        ];
      }
      if (viewNode.ViewType === cBMSViewTypeBuilding) {
        find$ = [
          this.findPartition(entityId, viewNode),
          this.findLocation(entityId, viewNode, partitionId),
          this.findEquipment(entityId, viewNode, partitionId),
          this.findPoint(entityId, viewNode, partitionId),
          this.findCalendar(entityId, viewNode, partitionId),
          this.findSchedule(entityId, viewNode, partitionId),
          this.findFolder(entityId, viewNode, partitionId)
        ];
      }
    }

    return zip(find$).pipe(
      map((result: Page[]) => {
        /* eslint-disable @typescript-eslint/naming-convention */
        return result.reduce((acc, value) => ({ Page: 1, Size: 1000, Total: acc.Nodes.length + value.Nodes.length, Nodes: [...acc.Nodes, ...value.Nodes] }),
          { Page: 1, Size: 1000, Total: 0, Nodes: [] });
        /* eslint-enable @typescript-eslint/naming-convention */
      })
    );
  }

  private findPartition(entityId: string, viewNode: ViewNode): Observable<Page> {
    return this.entityResolver.resolvePartition(entityId, viewNode).pipe(
      map(result => {
        if (result !== undefined) {
          /* eslint-disable-next-line @typescript-eslint/naming-convention */
          return { Page: 1, Size: 1000, Total: 1, Nodes: [result] };
        } else {
          /* eslint-disable-next-line @typescript-eslint/naming-convention */
          return { Page: 1, Size: 1000, Total: 0, Nodes: [] };
        }
      })
    );
  }

  private findLocation(entityId: string, viewNode: ViewNode, partitionId: string): Observable<Page> {
    // TODO: check and view type
    return this.entityResolver.resolveLocation(partitionId, entityId, viewNode).pipe(
      map(result => {
        if (result !== undefined) {
          /* eslint-disable-next-line @typescript-eslint/naming-convention */
          return { Page: 1, Size: 1000, Total: 1, Nodes: [result] };
        } else {
          /* eslint-disable-next-line @typescript-eslint/naming-convention */
          return { Page: 1, Size: 1000, Total: 0, Nodes: [] };
        }
      })
    );
  }

  private findEquipment(entityId: string, viewNode: ViewNode, partitionId: string): Observable<Page> {
    return this.entityResolver.resolveEquipment(partitionId, viewNode, entityId).pipe(
      map(result => {
        if (result !== undefined) {
          /* eslint-disable-next-line @typescript-eslint/naming-convention */
          return { Page: 1, Size: 1000, Total: 1, Nodes: [result] };
        } else {
          /* eslint-disable-next-line @typescript-eslint/naming-convention */
          return { Page: 1, Size: 1000, Total: 0, Nodes: [] };
        }
      })
    );
  }

  private findDevice(entityId: string, viewNode: ViewNode, partitionId?: string): Observable<Page> {
    return this.entityResolver.resolveDevice(partitionId, entityId, viewNode).pipe(
      map(result => {
        if (result !== undefined) {
          /* eslint-disable-next-line @typescript-eslint/naming-convention */
          return { Page: 1, Size: 1000, Total: 1, Nodes: [result] };
        } else {
          /* eslint-disable-next-line @typescript-eslint/naming-convention */
          return { Page: 1, Size: 1000, Total: 0, Nodes: [] };
        }
      })
    );
  }

  private findPoint(entityId: string, viewNode: ViewNode, partitionId: string): Observable<Page> {
    return this.entityResolver.resolvePoint(entityId, viewNode, partitionId).pipe(
      map(result => {
        if (result !== undefined) {
          /* eslint-disable-next-line @typescript-eslint/naming-convention */
          return { Page: 1, Size: 1000, Total: 1, Nodes: [result] };
        } else {
          /* eslint-disable-next-line @typescript-eslint/naming-convention */
          return { Page: 1, Size: 1000, Total: 0, Nodes: [] };
        }
      })
    );
  }

  private findCalendar(entityId: string, viewNode: ViewNode, partitionId: string): Observable<Page> {
    return this.entityResolver.resolveCalendar(entityId, viewNode, partitionId).pipe(
      map(result => {
        if (result !== undefined) {
          /* eslint-disable-next-line @typescript-eslint/naming-convention */
          return { Page: 1, Size: 1000, Total: 1, Nodes: [result] };
        } else {
          /* eslint-disable-next-line @typescript-eslint/naming-convention */
          return { Page: 1, Size: 1000, Total: 0, Nodes: [] };
        }
      })
    );
  }

  private findSchedule(entityId: string, viewNode: ViewNode, partitionId: string): Observable<Page> {
    return this.entityResolver.resolveSchedule(entityId, viewNode, partitionId).pipe(
      map(result => {
        if (result !== undefined) {
          /* eslint-disable-next-line @typescript-eslint/naming-convention */
          return { Page: 1, Size: 1000, Total: 1, Nodes: [result] };
        } else {
          /* eslint-disable-next-line @typescript-eslint/naming-convention */
          return { Page: 1, Size: 1000, Total: 0, Nodes: [] };
        }
      })
    );
  }

  private findFolder(entityId: string, viewNode: ViewNode, partitionId: string): Observable<Page> {
    return this.entityResolver.resolveFolder(entityId, viewNode, partitionId).pipe(
      map(result => {
        if (result !== undefined) {
          /* eslint-disable-next-line @typescript-eslint/naming-convention */
          return { Page: 1, Size: 1000, Total: 1, Nodes: [result] };
        } else {
          /* eslint-disable-next-line @typescript-eslint/naming-convention */
          return { Page: 1, Size: 1000, Total: 0, Nodes: [] };
        }
      })
    );
  }

  private searchEquipmentTypes(ids: string[], systemId: string, viewId: number): Observable<Page> {
    const search$ = ids.map(id => this.searchEquipmentType(id, systemId, viewId));
    return zip(search$).pipe(
      /* eslint-disable-next-line @typescript-eslint/naming-convention */
      map(result => ({ Page: 1, Size: 1000, Total: result.flat().length, Nodes: result.flat() }))
    );
  }

  private searchEquipmentType(id: string, systemId: string, viewId: number): Observable<BrowserObject[]> {
    return this.getView(systemId, viewId).pipe(
      concatMap(viewNode => {
        return this.equipmentService.getEquipmentByEquipmentTypeId('5fe31ef1-5fc8-49f3-84cc-263f17b656fb', id).pipe(
          concatMap(equipments => {
            if (equipments.length > 0) {
              return this.entityResolver.resolveEquipmentsToBrowserNode(equipments, viewNode, '5fe31ef1-5fc8-49f3-84cc-263f17b656fb');
            } else {
              return of([]);
            }
          })
        );
      })
    );
  }
}
