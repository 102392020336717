@if (form && fieldConfig) {
  @if (ownForm) {
    <form [formGroup]="form">
      <si-form-container [form]="form">
        <div si-form-container-content>
          <ng-container *ngTemplateOutlet="formlytmpl" />
        </div>
      </si-form-container>
    </form>
  } @else {
    <ng-container *ngTemplateOutlet="formlytmpl" />
  }

  <ng-template #formlytmpl>
    <formly-form
      [model]="model"
      [fields]="fieldConfig"
      [options]="options"
      [form]="form"
      (modelChange)="modelChange.emit($event)"
    />
  </ng-template>
}
