import {
  Component,
  EventEmitter,
  forwardRef,
  inject,
  Input,
  OnDestroy,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { clone, DateFormat, focusDialogContent, TimeFormat } from '@simpl/buildings-ng/common';
import { ModalRef, SiModalService } from '@simpl/element-ng/modal';
import { SiTranslateModule } from '@simpl/element-ng/translate';

import { getNextModalId } from '../../../helpers/modal-helpers';
import { CommandEvent, PropertyCommand } from '../../../interfaces/property';
import { SiBigNumberPropertyComponent } from '../../si-big-number-property/si-big-number-property.component';
import { SiBitstringPropertyComponent } from '../../si-bitstring-property/si-bitstring-property.component';
import { SiBlindPropertyComponent } from '../../si-blind-property/si-blind-property.component';
import { SiBooleanPropertyComponent } from '../../si-boolean-property/si-boolean-property.component';
import { SiDatePropertyComponent } from '../../si-date-property/si-date-property.component';
import { SiDatetimePropertyComponent } from '../../si-datetime-property/si-datetime-property.component';
import { SiEnumPropertyComponent } from '../../si-enum-property/si-enum-property.component';
import { SiNumberPropertyComponent } from '../../si-number-property/si-number-property.component';
import { SiObjectidPropertyComponent } from '../../si-objectid-property/si-objectid-property.component';
import { SiPropertyComponent } from '../../si-property/si-property.component';
import { SiPropertyContainerComponent } from '../../si-property-popover/si-property-container.component';
import { SiStringPropertyComponent } from '../../si-string-property/si-string-property.component';
import { SiTimePropertyComponent } from '../../si-time-property/si-time-property.component';
import { SiTimedurationPropertyComponent } from '../../si-timeduration-property/si-timeduration-property.component';

@Component({
  selector: 'si-custom-action-pane',
  templateUrl: './si-custom-action-pane.component.html',
  styleUrl: './si-custom-action-pane.component.scss',
  standalone: true,
  imports: [
    SiBlindPropertyComponent,
    SiBigNumberPropertyComponent,
    SiBitstringPropertyComponent,
    SiBooleanPropertyComponent,
    SiDatePropertyComponent,
    SiDatetimePropertyComponent,
    SiEnumPropertyComponent,
    SiNumberPropertyComponent,
    SiObjectidPropertyComponent,
    // eslint-disable-next-line @angular-eslint/no-forward-ref
    forwardRef(() => SiPropertyComponent),
    SiPropertyContainerComponent,
    SiStringPropertyComponent,
    SiTimePropertyComponent,
    SiTimedurationPropertyComponent,
    SiTranslateModule
  ]
})
export class SiCustomActionPaneComponent implements OnDestroy {
  @ViewChild('modalTemplate') protected modalTemplate!: TemplateRef<any>;

  /** @defaultValue 'dd.mm.yyyy' */
  @Input() dateFormat: DateFormat = 'dd.mm.yyyy';
  /** @defaultValue 'hh:mm:ss' */
  @Input() timeFormat: TimeFormat = 'hh:mm:ss';
  @Output() readonly command = new EventEmitter<CommandEvent>();
  @Output() readonly hidden = new EventEmitter<void>(true);

  protected propertyCommand!: PropertyCommand;
  protected modalTitleId = '';
  protected isValid = true;

  protected dialogTextCancel = $localize`:@@OBJECT_BROWSER.DIALOG_TEXT_SHORT.CANCEL:Cancel`;
  protected dialogTextOk = $localize`:@@OBJECT_BROWSER.DIALOG_TEXT_SHORT.OK:OK`;

  private modalReference?: ModalRef;
  private event?: CommandEvent;

  private modalService = inject(SiModalService);

  ngOnDestroy(): void {
    this.close();
  }

  /** @internal */
  show(command: PropertyCommand, event: CommandEvent): void {
    this.propertyCommand = command;
    this.event = event;

    const id = getNextModalId();
    this.modalTitleId = id.titleId;
    this.modalReference = this.modalService.show(this.modalTemplate, {
      class: 'object-browser modal-dialog-scrollable',
      ignoreBackdropClick: true,
      ariaLabelledBy: this.modalTitleId,
      animated: false
    });
    focusDialogContent(this.modalReference, true);
  }

  protected submit(): void {
    this.event!.parameters = clone(this.propertyCommand.parameters);
    this.command.emit(this.event);
    this.close();
  }

  protected close(): void {
    this.modalReference?.hide();
    this.modalReference = undefined;
    this.hidden.emit();
  }
}
