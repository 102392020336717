import { Injectable } from "@angular/core";
import { EventListSettingsServiceBase } from "./event-list-settings.service.base";
import { TreeItem } from "@simpl/element-ng";
import { TranslateService } from "@ngx-translate/core";
import { Observable, Subscription } from "rxjs";
import { AppContextService, isNullOrUndefined, TraceService } from "@gms-flex/services-common";
import { TraceServiceDelegate } from "../../shared/trace-service-delegate";
import { TraceModules } from "../../shared/trace-modules";
import { enumColumnType, HeaderData } from "../event-data.model";
import { Event, EventFilter, EventStates, SortingDirection, Tables, TablesServiceBase, TextEntry } from "@gms-flex/services";
import { HfwFilterPillData } from "@gms-flex/controls";

@Injectable({
  providedIn: "root"
})
export class EventListSettingsService extends EventListSettingsServiceBase {

  // #region variables
  public readonly columnsLabelList: string[] = ['eventIcon', 'cause', 'state', 'informationalText', 'creationTime',
    'timer', 'srcSource', 'belongsTo', 'srcPath', 'commands', 'suggestedAction', 'srcSystemName', 'messageText', 'inProcessBy', 'srcAlias'];

  public readonly cellSupportedData: string[] = ['eventId', 'creationTime', 'messageText', 'cause', 'state',
    'srcState', 'inProcessBy', 'suggestedAction', 'recursation', 'timer', 'informationalText', 'srcAlias', 'commands', 'srcSystemName'];

  private displayParams: any;

  private aliasFilterLabel = ''; // "Alias";
  private designationFilterLabel = ''; // "Designation";
  private sourcePropertyIdFilterLabel = ''; // "Designation";
  private nameFilterLabel = ''; // "Name";
  private descriptionFilterLabel = ''; // "Description";
  private columnNameCause = ''; //  "Event Cause",
  private columnNameCategory = ''; // "Category",
  private columnNameDateTime = ''; // "DateTime",
  private columnNameCommands = ''; // "Commands",
  private columnNameEventStatus = ''; // "Event Status",
  private columnNameInformationalText = ''; // "Informational Text",
  private columnNamePath = ''; // "Path",
  private columnNameSource = ''; // "Source",
  private columnNameBelongsTo = ''; // "Belongs to",
  private columnNameTimer = ''; // "Timer",
  private columnNameSuggestedAction = ''; // "Suggested action",
  private columnNameSrcSystemName = ''; // "System Name",
  private columnNameMessageText = ''; // "Message",
  private columnNameInProcessBy = ''; // "InProcessBy",
  private columnNameSrcAlias = ''; // "Alias",
  private hiddenEventsShowLabel = ""; // "Show";
  private eventStateUnprocessed = ""; // "Unprocessed";
  private eventStateReadyToBeReset = ""; // "Ready to be Reset";
  private eventStateReadyToBeClosed = ""; // "Ready to be Closed";
  private eventStateWaitingForCondition = ""; // "Waiting for Condition";
  private eventStateClosed = ""; // "Closed";
  private sourceStateActive = ""; // "Active";
  private sourceStateQuiet = ""; // "Quiet";

  private allowedFilterPillData: string[] = [];

  private readonly userLang: string;

  private readonly traceSvc: TraceServiceDelegate;

  private readonly subscriptions: Subscription[] = [];

  // #endregion variables

  public constructor(
    private readonly translateService: TranslateService,
    private readonly appContextService: AppContextService,
    private readonly traceService: TraceService,
    private readonly tablesService: TablesServiceBase
  ) {
    super();
    // user culture
    this.traceSvc = new TraceServiceDelegate(traceService, TraceModules.eventListSettingsService);
    this.userLang = this.translateService.getBrowserLang();

    this.subscriptions.push(this.appContextService.defaultCulture.subscribe((defaultCulture: string) => {
      if (defaultCulture !== null) {
        this.translateService.setDefaultLang(defaultCulture);
      } else {
        this.traceService.warn(TraceModules.eventListSettingsService, 'No default Culture for appContextService');
        this.translateService.setDefaultLang(this.userLang);
      }
    }));

    this.subscriptions.push(this.appContextService.userCulture.subscribe((userCulture: string) => {
      if (userCulture !== null) {
        this.translateService.use(userCulture).subscribe((res: any) => {
          this.traceService.info(TraceModules.eventListSettingsService, 'use  user Culture');
        });
      } else {
        this.traceService.warn(TraceModules.eventListSettingsService, 'No user Culture for appContextService');
      }
    }));

    this.subscriptions.push(
      this.translateService
        .get([
          // #region string list
          "EVENTS.ALIAS-FILTER-LABEL",
          "EVENTS.DESIGNATION-FILTER-LABEL",
          "EVENTS.SOURCEPROPERTYID-FILTER-LABEL",
          "EVENTS.NAME-FILTER-LABEL",
          "EVENTS.DESCRIPTION-FILTER-LABEL",
          "EVENTS.DATE-TIME-FILTER-LABEL",
          "EVENTS.TIME-EMPTY-FILTER-LABEL",
          "EVENTS.TIME-LAST-QUARTER-HOUR-FILTER-LABEL",
          "EVENTS.TIME-LAST-HALF-HOUR-FILTER-LABEL",
          "EVENTS.TIME-LAST-HOUR-FILTER-LABEL",
          "EVENTS.TIME-LAST-NIGHT-FILTER-LABEL",
          "EVENTS.TIME-YESTERDAY-FILTER-LABEL",
          "EVENTS.TIME-TODAY-FILTER-LABEL",
          "EVENTS.DISCIPLINE-FILTER-LABEL",
          "EVENTS.CATEGORY-FILTER-LABEL",
          "EVENTS.STATE-FILTER-LABEL",
          "EVENTS.SRC-STATE-FILTER-LABEL",
          "EVENTS.SRC-SYSTEM-FILTER-LABEL",
          "EVENTS.HIDDEN-EVENTS-FILTER-LABEL",
          "EVENTS.CONTENT-ACTION-FILTER-LABEL",
          "EVENTS.EVENT-STATE-UNPROCESSED",
          "EVENTS.EVENT-STATE-READY-TO-BE-RESET",
          "EVENTS.EVENT-STATE-READY-TO-BE-CLOSED",
          "EVENTS.HIDDEN-EVENTS-SHOW-LABEL",
          "EVENTS.EVENT-STATE-WAITING-FOR-CONDITION",
          "EVENTS.EVENT-STATE-CLOSED",
          "EVENTS.SOURCE-STATE-ACTIVE",
          "EVENTS.SOURCE-STATE-QUIET",
          'EVENTS.COLUMN-NAME-CAUSE',
          'EVENTS.COLUMN-NAME-DESCRIPTION',
          'EVENTS.COLUMN-NAME-NAME',
          'EVENTS.COLUMN-NAME-LOCATION',
          'EVENTS.COLUMN-NAME-DATE-TIME',
          'EVENTS.COLUMN-NAME-COMMANDS',
          'EVENTS.COLUMN-NAME-EVENT-STATUS',
          'EVENTS.COLUMN-NAME-SOURCE-STATUS',
          'EVENTS.COLUMN-CUSTOMIZE-TITLE',
          'EVENTS.COLUMN-CUSTOMIZE-DESCRIPTION',
          'EVENTS.COLUMN-NAME-CATEGORY',
          'EVENTS.COLUMN-NAME-SOURCE',
          'EVENTS.COLUMN-NAME-BELONGSTO',
          'EVENTS.COLUMN-NAME-TIMER',
          'EVENTS.COLUMN-NAME-INFORMATIONAL-TEXT',
          'EVENTS.COLUMN-NAME-PATH',
          'EVENTS.COLUMN-NAME-SUGGESTED-ACTION',
          'EVENTS.COLUMN-NAME-SYSTEM-NAME',
          'EVENTS.COLUMN-NAME-MESSAGE-TEXT',
          'EVENTS.COLUMN-NAME-IN-PROCESS-BY',
          'EVENTS.COLUMN-NAME-ALIAS'
        ])
        .subscribe(value => {
          this.aliasFilterLabel = value['EVENTS.ALIAS-FILTER-LABEL'];
          this.nameFilterLabel = value['EVENTS.NAME-FILTER-LABEL'];
          this.descriptionFilterLabel = value['EVENTS.DESCRIPTION-FILTER-LABEL'];
          this.designationFilterLabel = value['EVENTS.DESIGNATION-FILTER-LABEL'];
          this.sourcePropertyIdFilterLabel = value['EVENTS.SOURCEPROPERTYID-FILTER-LABEL'];
          this.eventStateUnprocessed = value["EVENTS.EVENT-STATE-UNPROCESSED"];
          this.eventStateReadyToBeReset =
            value["EVENTS.EVENT-STATE-READY-TO-BE-RESET"];
          this.eventStateReadyToBeClosed =
            value["EVENTS.EVENT-STATE-READY-TO-BE-CLOSED"];
          this.hiddenEventsShowLabel = value["EVENTS.HIDDEN-EVENTS-SHOW-LABEL"];
          this.eventStateWaitingForCondition =
            value["EVENTS.EVENT-STATE-WAITING-FOR-CONDITION"];
          this.eventStateClosed = value["EVENTS.EVENT-STATE-CLOSED"];
          this.sourceStateActive = value["EVENTS.SOURCE-STATE-ACTIVE"];
          this.sourceStateQuiet = value["EVENTS.SOURCE-STATE-QUIET"];
          this.columnNameCause = value['EVENTS.COLUMN-NAME-CAUSE'];
          this.columnNameCategory = value['EVENTS.COLUMN-NAME-CATEGORY'];
          this.columnNameDateTime = value['EVENTS.COLUMN-NAME-DATE-TIME'];
          this.columnNameCommands = value['EVENTS.COLUMN-NAME-COMMANDS'];
          this.columnNameEventStatus = value['EVENTS.COLUMN-NAME-EVENT-STATUS'];
          this.columnNameInformationalText = value['EVENTS.COLUMN-NAME-INFORMATIONAL-TEXT'];
          this.columnNameBelongsTo = value['EVENTS.COLUMN-NAME-BELONGSTO'];
          this.columnNameTimer = value['EVENTS.COLUMN-NAME-TIMER'];
          this.columnNamePath = value['EVENTS.COLUMN-NAME-PATH'];
          this.columnNameSource = value['EVENTS.COLUMN-NAME-SOURCE'];
          this.columnNameSuggestedAction = value['EVENTS.COLUMN-NAME-SUGGESTED-ACTION'];
          this.columnNameSrcSystemName = value['EVENTS.COLUMN-NAME-SYSTEM-NAME'];
          this.columnNameMessageText = value['EVENTS.COLUMN-NAME-MESSAGE-TEXT'];
          this.columnNameInProcessBy = value['EVENTS.COLUMN-NAME-IN-PROCESS-BY'];
          this.columnNameSrcAlias = value['EVENTS.COLUMN-NAME-ALIAS'];
        })
    );
  }

  // #region configs

  public getDisciplines(): Observable<TextEntry[]> {
    return this.tablesService.getGlobalText(Tables.Disciplines, true);
  }

  public getInvestigativeSettings(): any {
    return {
      showAckButton: true,
      showSecondaryAction: true
    }
  }

  // #endregion configs

  // #region columns
  public getAvailableColumns(displayParams: any): HeaderData[] {

    this.displayParams = displayParams;
    const hdrData: HeaderData[] = [];

    let newHeaderEntry: HeaderData = null;

    newHeaderEntry = {
      id: 'eventIcon',
      label: this.columnNameCategory,
      columnType: enumColumnType.EventIcon,
      columnVisible: this.setVisibility('eventIcon', true),
      minColWidth: 90,
      isFixedSize: true,
      widthPercentage: 10,
      allowSorting: false
    };
    hdrData.push(newHeaderEntry);

    newHeaderEntry = {
      id: 'cause',
      label: this.columnNameCause,
      columnType: enumColumnType.Text,
      columnVisible: this.setVisibility('eventIcon', true),
      minColWidth: displayParams.compactMode ? 80 : displayParams.isULC ? 400 : 230,
      isFixedSize: displayParams?.isULC ? true : false,
      widthPercentage: 10,
      allowSorting: true
    };
    hdrData.push(newHeaderEntry);

    newHeaderEntry = {
      id: 'state',
      label: this.columnNameEventStatus,
      columnType: enumColumnType.State,
      columnVisible: this.setVisibility('state', true),
      minColWidth: displayParams?.compactMode ? 28 : 120,
      isFixedSize: true,
      widthPercentage: 10,
      allowSorting: true
    };
    hdrData.push(newHeaderEntry);

    newHeaderEntry = {
      id: 'creationTime',
      label: this.columnNameDateTime,
      columnType: enumColumnType.Text,
      columnVisible: this.setVisibility('creationTime', true),
      minColWidth: displayParams?.compactMode ? 90 : 160,
      isFixedSize: true,
      widthPercentage: 10,
      allowSorting: true
    };
    hdrData.push(newHeaderEntry);

    newHeaderEntry = {
      id: 'timer',
      label: this.columnNameTimer,
      columnVisible: this.setVisibility('timer', false),
      minColWidth: 120,
      isFixedSize: true,
      widthPercentage: 10,
      allowSorting: false
    };
    hdrData.push(newHeaderEntry);

    newHeaderEntry = {
      id: 'srcSource',
      label: this.columnNameSource,
      columnType: enumColumnType.Text,
      columnVisible: this.setVisibility('srcSource', true),
      minColWidth: displayParams?.isULC ? 250 : 100,
      isFixedSize: displayParams?.isULC ? true : false,
      widthPercentage: 10,
      allowSorting: true
    };
    hdrData.push(newHeaderEntry);

    newHeaderEntry = {
      id: 'belongsTo',
      label: this.columnNameBelongsTo,
      columnType: enumColumnType.Text,
      columnVisible: this.setVisibility('belongsTo', true),
      minColWidth: 100,
      isFixedSize: false,
      widthPercentage: 10,
      allowSorting: true
    };
    hdrData.push(newHeaderEntry);

    newHeaderEntry = {
      id: 'informationalText',
      label: this.columnNameInformationalText,
      columnType: enumColumnType.ScrollableText,
      columnVisible: this.setVisibility('informationalText', true),
      minColWidth: 100,
      isFixedSize: false,
      widthPercentage: 10,
      allowSorting: true
    };
    hdrData.push(newHeaderEntry);

    newHeaderEntry = {
      id: 'srcPath',
      label: this.columnNamePath,
      columnType: enumColumnType.Text,
      columnVisible: this.setVisibility('srcPath', false),
      minColWidth: displayParams?.isULC ? 300 : 100,
      isFixedSize: false,
      widthPercentage: 10,
      allowSorting: true
    };
    hdrData.push(newHeaderEntry);

    newHeaderEntry = {
      id: 'commands',
      label: this.columnNameCommands,
      columnType: enumColumnType.Buttons,
      columnVisible: this.setVisibility('commands', false),
      minColWidth: 180,
      isFixedSize: true,
      widthPercentage: 10,
      allowSorting: false
    };
    hdrData.push(newHeaderEntry);

    newHeaderEntry = {
      id: 'suggestedAction',
      label: this.columnNameSuggestedAction,
      columnType: enumColumnType.Text,
      columnVisible: this.setVisibility('suggestedAction', false),
      minColWidth: 150,
      isFixedSize: false,
      widthPercentage: 10,
      allowSorting: false
    };
    hdrData.push(newHeaderEntry);

    newHeaderEntry = {
      id: 'srcSystemName',
      label: this.columnNameSrcSystemName,
      columnType: enumColumnType.Text,
      columnVisible: this.setVisibility('srcSystemName', false),
      minColWidth: 75,
      isFixedSize: false,
      widthPercentage: 5,
      allowSorting: true
    };
    hdrData.push(newHeaderEntry);

    newHeaderEntry = {
      id: 'messageText',
      label: this.columnNameMessageText,
      columnType: enumColumnType.Text,
      columnVisible: this.setVisibility('messageText', false),
      minColWidth: 150,
      isFixedSize: false,
      widthPercentage: 10,
      allowSorting: true
    };
    hdrData.push(newHeaderEntry);

    newHeaderEntry = {
      id: 'inProcessBy',
      label: this.columnNameInProcessBy,
      columnType: enumColumnType.Text,
      columnVisible: this.setVisibility('inProcessBy', false),
      minColWidth: 150,
      isFixedSize: false,
      widthPercentage: 10,
      allowSorting: true
    };
    hdrData.push(newHeaderEntry);

    newHeaderEntry = {
      id: 'srcAlias',
      label: this.columnNameSrcAlias,
      columnType: enumColumnType.Text,
      columnVisible: this.setVisibility('srcAlias', false),
      minColWidth: 150,
      isFixedSize: false,
      widthPercentage: 10,
      allowSorting: true
    };
    hdrData.push(newHeaderEntry);

    return hdrData;
  }
  // #endregion columns

  // #region filters

  public getStateFilter(): any {
    // Event State Tree
    let item: TreeItem;
    const stateTree: TreeItem[] = [];

    item = {
      label: this.eventStateUnprocessed,
      state: "leaf",
      customData: ["Unprocessed"]
    };
    stateTree.push(item);

    item = {
      label: this.eventStateReadyToBeReset,
      state: "leaf",
      customData: ["ReadyToBeReset"]
    };
    stateTree.push(item);

    item = {
      label: this.eventStateReadyToBeClosed,
      state: "leaf",
      customData: ["ReadyToBeClosed"]
    };
    stateTree.push(item);

    item = {
      label: this.eventStateWaitingForCondition,
      state: "leaf",
      customData: ["Acked", "WaitingOPCompletion"]
    };
    stateTree.push(item);

    item = {
      label: this.eventStateClosed,
      state: "leaf",
      customData: ["Closed"]
    };
    stateTree.push(item);

    return stateTree;
  }

  public getSourceFilter(): any {
    // Event Source State
    let item: TreeItem;
    const srcStateTree: TreeItem[] = [];

    item = {
      label: this.sourceStateActive,
      state: "leaf",
      customData: ["Active"]
    };
    srcStateTree.push(item);

    item = {
      label: this.sourceStateQuiet,
      state: "leaf",
      customData: ["Quiet"]
    };
    srcStateTree.push(item);

    return srcStateTree;
  }

  public getHiddenFilter(): any {
    // Hidden Events
    const hiddenEventsTree: TreeItem[] = [];

    const item: TreeItem = {
      label: this.hiddenEventsShowLabel,
      state: "leaf",
      customData: ["Active"]
    };
    hiddenEventsTree.push(item);

    return hiddenEventsTree;
  }

  public getFilterCriteria(eventFilter: EventFilter, pillDataArr: HfwFilterPillData[]): HfwFilterPillData[] {
    if (eventFilter == null) {
      return pillDataArr;
    }
    if (eventFilter.srcAlias !== undefined && eventFilter.srcAlias.length > 0) {
      const values: string[] = [];
      values.push(eventFilter.srcAlias);
      pillDataArr.push(new HfwFilterPillData(5, this.aliasFilterLabel, values));
    }
    if (eventFilter.srcDesignations !== undefined && eventFilter.srcDesignations.length > 0 ||
      eventFilter.srcPropertyIds !== undefined && eventFilter.srcPropertyIds.length > 0) {
      const values: string[] = [];
      values.push(eventFilter.srcDesignations ? eventFilter.srcDesignations[0] : eventFilter.srcPropertyIds[0]);
      pillDataArr.push(new HfwFilterPillData(6, eventFilter.srcDesignations ? this.designationFilterLabel : this.sourcePropertyIdFilterLabel, values));
    }
    if (eventFilter.srcDescriptor !== undefined && eventFilter.srcDescriptor.length > 0) {
      const values: string[] = [];
      values.push(eventFilter.srcDescriptor);
      pillDataArr.push(new HfwFilterPillData(7, this.descriptionFilterLabel, values));
    }
    if (eventFilter.srcName !== undefined && eventFilter.srcName.length > 0) {
      const values: string[] = [];
      values.push(eventFilter.srcName);
      pillDataArr.push(new HfwFilterPillData(8, this.nameFilterLabel, values));
    }
    return pillDataArr;
  }

  public getAllowedFilters(): any {
    if (this.allowedFilterPillData.length == 0) {
      this.allowedFilterPillData = ['Alias', 'Name', 'Description'];
    }

    return this.allowedFilterPillData;
  }

  // #endregion filters

  // #region eventsUtils

  /**
   * this method is NOT meant to be used outside the events components snapins,
   * it should be used to compare two events, and decide the order of the events according to the current ordering
   * @param ev1 the first event we want to compare
   * @param ev2 the second event we want to compare
   * @returns 1 if ev1 have an higher priority then ev2, -1 for the opposite case, 0 if there is no order difference
   */
  public compareEvents(ev1: Event, ev2: Event): number {
    if (this.sortingCriteria.length === 0) {
      return 0;
    }

    let ret = 0;

    for (const sorting of this.sortingCriteria) {
      if (sorting.sortingDir === SortingDirection.NONE) {
        continue;
      }
      switch (sorting.id) {
        case 'processing':
          if (ev1.stateId !== ev2.stateId) {
            if (ev1.stateId === EventStates.Unprocessed || ev1.stateId === EventStates.UnprocessedWithTimer) {
              ret = 1;
              break;
            }
            if (ev2.stateId === EventStates.Unprocessed || ev2.stateId === EventStates.UnprocessedWithTimer) {
              ret = -1;
              break;
            }
          }
          break;
        case 'state':
          if (ev1.statePriority > ev2.statePriority) {
            ret = (sorting.sortingDir === SortingDirection.DESCENDING) ? 1 : -1;
            break;
          }
          if (ev1.statePriority < ev2.statePriority) {
            ret = (sorting.sortingDir === SortingDirection.DESCENDING) ? -1 : 1;
            break;
          }
          break;
        case 'categoryDescriptor':
          if (ev1.categoryId > ev2.categoryId) {
            ret = (sorting.sortingDir === SortingDirection.DESCENDING) ? 1 : -1;
            break;
          }
          if (ev1.categoryId < ev2.categoryId) {
            ret = (sorting.sortingDir === SortingDirection.DESCENDING) ? -1 : 1;
            break;
          }
          break;
        case 'creationTime':
          if (ev1.originalCreationTime > ev2.originalCreationTime) {
            ret = (sorting.sortingDir === SortingDirection.DESCENDING) ? 1 : -1;
            break;
          }
          if (ev1.originalCreationTime < ev2.originalCreationTime) {
            ret = (sorting.sortingDir === SortingDirection.DESCENDING) ? -1 : 1;
            break;
          }
          break;
        case 'messageText':
          if (ev1.messageTextToDisplay > ev2.messageTextToDisplay) {
            ret = (sorting.sortingDir === SortingDirection.DESCENDING) ? 1 : -1;
            break;
          }
          if (ev1.messageTextToDisplay < ev2.messageTextToDisplay) {
            ret = (sorting.sortingDir === SortingDirection.DESCENDING) ? -1 : 1;
            break;
          }
          break;
        case 'cause':
          if (ev1.cause > ev2.cause) {
            ret = (sorting.sortingDir === SortingDirection.DESCENDING) ? 1 : -1;
            break;
          }
          if (ev1.cause < ev2.cause) {
            ret = (sorting.sortingDir === SortingDirection.DESCENDING) ? -1 : 1;
            break;
          }
          break;
        case 'srcPath':
          if (this.getEventSrcPath(ev1) > this.getEventSrcPath(ev2)) {
            ret = (sorting.sortingDir === SortingDirection.DESCENDING) ? 1 : -1;
            break;
          }
          if (this.getEventSrcPath(ev1) < this.getEventSrcPath(ev2)) {
            ret = (sorting.sortingDir === SortingDirection.DESCENDING) ? -1 : 1;
            break;
          }
          break;
        case 'srcSource':
          if (ev1.sourceFltr > ev2.sourceFltr) {
            ret = (sorting.sortingDir === SortingDirection.DESCENDING) ? 1 : -1;
            break;
          }
          if (ev1.sourceFltr < ev2.sourceFltr) {
            ret = (sorting.sortingDir === SortingDirection.DESCENDING) ? -1 : 1;
            break;
          }
          break;
        case 'belongsTo':
          if (ev1.belongsToFltr > ev2.belongsToFltr) {
            ret = (sorting.sortingDir === SortingDirection.DESCENDING) ? 1 : -1;
            break;
          }
          if (ev1.belongsToFltr < ev2.belongsToFltr) {
            ret = (sorting.sortingDir === SortingDirection.DESCENDING) ? -1 : 1;
            break;
          }
          break;
        case 'informationalText':
          if (ev1.informationalText > ev2.informationalText) {
            ret = (sorting.sortingDir === SortingDirection.DESCENDING) ? 1 : -1;
            break;
          }
          if (ev1.informationalText < ev2.informationalText) {
            ret = (sorting.sortingDir === SortingDirection.DESCENDING) ? -1 : 1;
            break;
          }
          break;
        case 'suggestedAction':
          if (ev1.suggestedAction > ev2.suggestedAction) {
            ret = (sorting.sortingDir === SortingDirection.DESCENDING) ? 1 : -1;
            break;
          }
          if (ev1.suggestedAction < ev2.suggestedAction) {
            ret = (sorting.sortingDir === SortingDirection.DESCENDING) ? -1 : 1;
            break;
          }
          break;
        case 'srcSystemName':
          if (ev1.srcSystemNameDisplay > ev2.srcSystemNameDisplay) {
            ret = (sorting.sortingDir === SortingDirection.DESCENDING) ? 1 : -1;
            break;
          }
          if (ev1.srcSystemNameDisplay < ev2.srcSystemNameDisplay) {
            ret = (sorting.sortingDir === SortingDirection.DESCENDING) ? -1 : 1;
            break;
          }
          break;
        case 'inProcessBy':
          if ((ev1.inProcessBy ?? "") > (ev2.inProcessBy ?? "")) {
            ret = (sorting.sortingDir === SortingDirection.DESCENDING) ? 1 : -1;
            break;
          }
          if ((ev1.inProcessBy ?? "") < (ev2.inProcessBy ?? "")) {
            ret = (sorting.sortingDir === SortingDirection.DESCENDING) ? -1 : 1;
            break;
          }
          if (isNullOrUndefined(ev1.inProcessBy) !== isNullOrUndefined(ev2.inProcessBy)) {
            if (isNullOrUndefined(ev1.inProcessBy)) {
              ret = sorting.sortingDir === SortingDirection.DESCENDING ? -1 : 1;
            } else {
              ret = sorting.sortingDir === SortingDirection.DESCENDING ? 1 : -1;
            }
          }
          break;
        case 'srcAlias':
          if ((ev1.srcAlias ?? "") > (ev2.srcAlias ?? "")) {
            ret = (sorting.sortingDir === SortingDirection.DESCENDING) ? 1 : -1;
            break;
          }
          if ((ev1.srcAlias ?? "") < (ev2.srcAlias ?? "")) {
            ret = (sorting.sortingDir === SortingDirection.DESCENDING) ? -1 : 1;
            break;
          }
          if (isNullOrUndefined(ev1.srcAlias) !== isNullOrUndefined(ev2.srcAlias)) {
            if (isNullOrUndefined(ev1.srcAlias)) {
              ret = sorting.sortingDir === SortingDirection.DESCENDING ? -1 : 1;
            } else {
              ret = sorting.sortingDir === SortingDirection.DESCENDING ? 1 : -1;
            }
          }
          break;
        case 'InOut':
          if (ev1.direction > ev2.direction) {
            ret = (sorting.sortingDir === SortingDirection.DESCENDING) ? 1 : -1;
          } else if (ev1.direction < ev2.direction) {
            ret = (sorting.sortingDir === SortingDirection.DESCENDING) ? -1 : 1;
          }
          break;
        default: {
          break;
        }
      }
      if (ret !== 0) {
        break;
      }
    }
    if (ret === 0 && ev1.eventId !== ev2.eventId) {
      ret = ev1.eventId > ev2.eventId ? 1 : -1;
    }
    return ret;
  }

  private setVisibility(name: string, defaultVal: boolean): boolean {
    return this.displayParams?.visibleColumns?.length > 0 ? this.displayParams.visibleColumns.includes(name) : defaultVal;
  }
  // #endregion eventsUtils
}
