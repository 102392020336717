import { Injectable } from '@angular/core';
import { BrowserObject, ViewNode } from '@gms-flex/services';
import { concatMap, map, Observable, of, throwError, zip } from 'rxjs';

import { DeviceService } from '../../bx-services/device/device.service';
import { EquipmentTypeService } from '../../bx-services/device/equipment-type.service';
import { Equipment } from '../../bx-services/device/equipment.model';
import { EquipmentService } from '../../bx-services/device/equipment.service';
import { LocationService } from '../../bx-services/location/location.service';
import { PointService } from '../../bx-services/point/point.service';
import { PartitionService } from '../../bx-services/subscription/partition.service';
import { ContextService } from '../state/context.service';
import { cBMSViewTypeBuilding, cBMSViewTypeDevices, SystemBrowserMapperBxToGmsService } from './system-browser-mapper-bx-to-gms.service';

@Injectable({
  providedIn: 'root'
})
export class EntityIdResolver {

  public constructor(
    private readonly deviceService: DeviceService,
    private readonly pointService: PointService,
    private readonly locationService: LocationService,
    private readonly equipmentService: EquipmentService,
    private readonly equipmentTypeService: EquipmentTypeService,
    private readonly partitionService: PartitionService,
    private readonly systemBrowerMapper: SystemBrowserMapperBxToGmsService,
    private readonly contextService: ContextService
  ) { }

  public resolvePoint(pointId: string, viewNode: ViewNode, partitionId: string): Observable<BrowserObject | undefined> {
    if (!partitionId) {
      return throwError(() => new Error('EntityIdResolver.resolvePoint(): Invalid arguments!'));
    }
    if (viewNode.ViewType === cBMSViewTypeBuilding) {
      // No relationship of points to equipments existing.
      // We can check the cache if the point was accidentally browsed
      const pointNodes = this.systemBrowerMapper.getBrowserObjectPoint(pointId, viewNode);
      if (pointNodes !== undefined && pointNodes.length > 0) {
        return of(pointNodes[0]);
      } else {
        return of(undefined);
      }
    }

    // Remark:
    // Currently any entity is reffered maximum one time in a view.
    // Points can exists more than one time in the building hierarchy.
    // However, resolving points is not possible due to the missing relationship of a point to its equipments.

    const resolveState = this.systemBrowerMapper.getResolvmentState(pointId, viewNode.ViewId);
    if (resolveState === 'Resolved') {
      const pointNodes = this.systemBrowerMapper.getBrowserObjectPoint(pointId, viewNode);
      if (pointNodes !== undefined && pointNodes.length > 0) {
        return of(pointNodes[0]);
      } else {
        return of(undefined);
      }
    } else {
      return this.pointService.getPointById(partitionId, pointId).pipe(
        concatMap(point => {
          if (point?.attributes.deviceId) {
            const deviceId = point.attributes.deviceId;
            return this.resolveDevice(partitionId, deviceId, viewNode).pipe(
              map(deviceNode => {
                if (deviceNode !== undefined) {
                  this.systemBrowerMapper.setResolved(pointId, viewNode.ViewId);
                  return this.systemBrowerMapper.mapPointToBrowserObject(
                    point, viewNode, deviceNode.Designation, deviceNode.DesignationDisplay, deviceNode.Location, deviceId, partitionId);
                } else {
                  return undefined;
                }
              })
            );
          } else {
            return of(undefined);
          }
        })
      );
    }
  }

  public resolveDevice(partitionId: string, deviceId: string, viewNode: ViewNode): Observable<BrowserObject> {
    if (!partitionId) {
      return throwError(() => new Error('EntityIdResolver.resolveDevice(): Invalid arguments!'));
    }
    if (viewNode.ViewType === cBMSViewTypeBuilding) {
      // Devices are not shown in the building hierarchy!
      return of(undefined);
    }

    const resolveState = this.systemBrowerMapper.getResolvmentState(deviceId, viewNode.ViewId);
    if (resolveState === 'Resolved') {
      const deviceNodes = this.systemBrowerMapper.getBrowserObjectDevice(deviceId, viewNode);
      if (deviceNodes !== undefined && deviceNodes.length > 0) {
        return of(deviceNodes[0]);
      } else {
        return of(undefined);
      }
    } else {
      return this.deviceService.getDeviceById(partitionId, deviceId).pipe(
        concatMap(device => {
          if (device !== undefined) {
            if (device.hasGateway) {
              return this.resolveDevice(partitionId, device.gatewayId, viewNode).pipe(
                map(gatewayNode => {
                  if (gatewayNode !== undefined) {
                    this.systemBrowerMapper.setResolved(deviceId, viewNode.ViewId);
                    return this.systemBrowerMapper.mapDeviceToBrowserObject(
                      device, viewNode, gatewayNode.Designation, gatewayNode.DesignationDisplay, gatewayNode.Location, device.gatewayId, partitionId);
                  } else {
                    return undefined;
                  }
                })
              );
            } else if (device.hasLocation) {
              return this.resolveLocation(partitionId, device.locationId, viewNode).pipe(
                map(locationNode => {
                  if (locationNode !== undefined) {
                    this.systemBrowerMapper.setResolved(deviceId, viewNode.ViewId);
                    return this.systemBrowerMapper.mapDeviceToBrowserObject(
                      device, viewNode, locationNode.Designation, locationNode.DesignationDisplay, locationNode.Location, device.locationId, partitionId);
                  } else {
                    return undefined;
                  }
                })
              );
            } else {
              return of(undefined);
            }
          } else {
            return of(undefined);
          }
        })
      );
    }
  }

  // private resolveGateway(partitionId: string, gatewayId: string, viewNode: ViewNode): Observable<BrowserObject> {
  //   let gatewayNode = this.systemBrowerMapper.getBrowserObject(gatewayId);
  //   if (gatewayNode != undefined) {
  //     return of(gatewayNode);
  //   } else {
  //     // read gateway device
  //     return this.deviceService.getDeviceById(partitionId, gatewayId).pipe(
  //       concatMap(gateway => {
  //         if (gateway != undefined) {
  //           const locationId = gateway.data.relationships.hasLocation.data.id;
  //           return this.resolveLocation(partitionId, locationId, viewNode).pipe(
  //             map(parentLocationNode => {
  //               return this.systemBrowerMapper.mapDeviceToBrowserObject(
  //                 gateway.data, viewNode, parentLocationNode.Designation, parentLocationNode.Location, locationId, partitionId);
  //             })
  //           );
  //         } else {
  //           return of(undefined);
  //         }
  //       })
  //     );
  //   }
  // }

  public resolveLocation(partitionId: string, locationId: string, viewNode: ViewNode): Observable<BrowserObject | undefined> {
    if (!partitionId) {
      return throwError(() => new Error('EntityIdResolver.resolveLocation(): Invalid arguments!'));
    }

    const resolveState = this.systemBrowerMapper.getResolvmentState(locationId, viewNode.ViewId);
    if (resolveState === 'Resolved') {
      const locationNodes = this.systemBrowerMapper.getBrowserObjectLocation(locationId, viewNode);
      if (locationNodes !== undefined && locationNodes.length > 0) {
        return of(locationNodes[0]);
      } else {
        return of(undefined);
      }
    } else {
      // read location
      return this.locationService.getLocationById(partitionId, locationId).pipe(
        concatMap(location => {
          if (location === undefined) {
            // location not found
            return of(undefined);
          }

          const parentLocationId = this.locationService.getParentLocationId(location);
          if (parentLocationId !== undefined) {
            // read parent location recursive upwards!!!
            return this.resolveLocation(partitionId, parentLocationId, viewNode).pipe(
              map(parentLocationNode => {
                if (parentLocationNode !== undefined) {
                  this.systemBrowerMapper.setResolved(locationId, viewNode.ViewId);
                  return this.systemBrowerMapper.mapLocationToBrowserObject(
                    location, viewNode, parentLocationNode.Designation,
                    parentLocationNode.DesignationDisplay, parentLocationNode.Location, partitionId, partitionId);
                } else {
                  return undefined;
                }
              })
            );
          } else {
            return this.resolvePartition(partitionId, viewNode).pipe(
              map(partitionNode => {
                if (partitionNode !== undefined) {
                  this.systemBrowerMapper.setResolved(locationId, viewNode.ViewId);
                  return this.systemBrowerMapper.mapLocationToBrowserObject(
                    location, viewNode, partitionNode.Designation, partitionNode.DesignationDisplay, partitionNode.Location, partitionId, partitionId);
                } else {
                  return undefined;
                }
              })
            );
          }
        })
      );
    }
  }

  public resolvePartition(partitionId: string, viewNode: ViewNode): Observable<BrowserObject | undefined> {
    if (!partitionId) {
      return throwError(() => new Error('EntityIdResolver.resolvePartition(): Invalid arguments!'));
    }

    const resolveState = this.systemBrowerMapper.getResolvmentState(partitionId, viewNode.ViewId);
    if (resolveState === 'Resolved') {
      const partitionNodes = this.systemBrowerMapper.getBrowserObjectPartition(partitionId, viewNode);
      if (partitionNodes !== undefined && partitionNodes.length > 0) {
        return of(partitionNodes[0]);
      } else {
        return of(undefined);
      }
    } else {
      return this.partitionService.getPartition(this.contextService.selectedCustomer.id, partitionId).pipe(
        map(partitionResponse => {
          if (partitionResponse !== undefined) {
            this.systemBrowerMapper.setResolved(partitionId, viewNode.ViewId);
            return this.systemBrowerMapper.mapPartitionToTreeRoot(partitionResponse, viewNode);
          } else {
            return undefined;
          }
        })
      );
    }
  }

  public resolveEquipment(partitionId: string, viewNode: ViewNode, equipmentId: string): Observable<BrowserObject | undefined> {
    if (!partitionId) {
      return throwError(() => new Error('EntityIdResolver.resolveEquipment(): Invalid arguments!'));
    }
    if (viewNode.ViewType === cBMSViewTypeDevices) {
      // Equipments are not shown in the building hierarchy!
      return of(undefined);
    }

    const resolveState = this.systemBrowerMapper.getResolvmentState(equipmentId, viewNode.ViewId);
    if (resolveState === 'Resolved') {
      const equipmentNodes = this.systemBrowerMapper.getBrowserObjectEquipment(equipmentId);
      if (equipmentNodes !== undefined && equipmentNodes.length > 0) {
        return of(equipmentNodes[0]);
      } else {
        return of(undefined);
      }
    } else {
      return this.equipmentService.getEquipmentById(partitionId, equipmentId).pipe(
        concatMap(equipment => this.resolveEquipmentToBrowserNode(equipment, viewNode, partitionId))
      );
    }
  }

  public resolveEquipmentsToBrowserNode(equipment: Equipment[], viewNode: ViewNode, partitionId: string): Observable<BrowserObject[] | undefined> {
    const resolveEqs = equipment.map(eq => this.resolveEquipmentToBrowserNode(eq, viewNode, partitionId));
    return zip(resolveEqs);
  }

  public resolveEquipmentToBrowserNode(equipment: Equipment, viewNode: ViewNode, partitionId: string): Observable<BrowserObject | undefined> {
    if (equipment !== undefined) {
      return this.resolveLocation(partitionId, equipment.locationId, viewNode).pipe(
        concatMap(locationNode => {
          if (locationNode !== undefined) {
            return this.equipmentTypeService.getEquipmentTypes(partitionId).pipe(
              map(eqTypes => {
                const equipmentType = eqTypes?.find(eqType => eqType.id === equipment.equipmentTypeId);
                this.systemBrowerMapper.setResolved(equipment.id, viewNode.ViewId);
                return this.systemBrowerMapper.mapEquipmentToBrowserObject(
                  equipment, viewNode, locationNode.Designation, locationNode.DesignationDisplay, locationNode.Location, partitionId, equipmentType)
              })
            );
          } else {
            return of(undefined);
          }
        })
      );
    } else {
      return of(undefined);
    }
  }

  public resolveCalendar(calendarId: string, viewNode: ViewNode, partitionId: string): Observable<BrowserObject | undefined> {
    if (!partitionId) {
      return throwError(() => new Error('EntityIdResolver.resolveCalendar(): Invalid arguments!'));
    }

    // No relationship of calendar to equipments/devices existing yet.
    // We can check the cache if the calendar was accidentally browsed
    const calendarNodes = this.systemBrowerMapper.getBrowserObjectCalendar(calendarId, viewNode);
    if (calendarNodes !== undefined && calendarNodes.length > 0) {
      return of(calendarNodes[0]);
    } else {
      return of(undefined);
    }
  }

  public resolveSchedule(scheduleId: string, viewNode: ViewNode, partitionId: string): Observable<BrowserObject | undefined> {
    if (!partitionId) {
      return throwError(() => new Error('EntityIdResolver.resolveSchedule(): Invalid arguments!'));
    }

    // No relationship of schedules to equipments/devices existing yet.
    // We can check the cache if the schedule was accidentally browsed
    const scheduleNodes = this.systemBrowerMapper.getBrowserObjectSchedule(scheduleId, viewNode);
    if (scheduleNodes !== undefined && scheduleNodes.length > 0) {
      return of(scheduleNodes[0]);
    } else {
      return of(undefined);
    }
  }

  public resolveFolder(folderId: string, viewNode: ViewNode, partitionId: string): Observable<BrowserObject | undefined> {
    if (!partitionId) {
      return throwError(() => new Error('EntityIdResolver.resolveFolder(): Invalid arguments!'));
    }

    // No implementataion yet, we check only the cache if the folder was accidentally browsed
    const folderNodes = this.systemBrowerMapper.getBrowserObjectFolder(folderId, viewNode);
    if (folderNodes !== undefined && folderNodes.length > 0) {
      return of(folderNodes[0]);
    } else {
      return of(undefined);
    }
  }

}
