@if (announcement) {
  <si-inline-notification
    [severity]="announcement.severity"
    [heading]="announcement.heading"
    [message]="announcement.message"
    [action]="announcement.action"
    [translationParams]="announcement.translationParams"
  />
}
<div class="landing-page">
  <div
    class="landing-page-background d-none d-md-block"
    [style.background-image]="'url(' + backgroundImageUrl + ')'"
  >
  </div>
  <div class="landing-page-content">
    <div class="d-flex justify-content-between align-items-center pb-9">
      <div class="landing-page-brand">
        @if (!logoUrl) {
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="103"
            height="20"
            viewBox="0 0 142.315 22.5"
          >
            <path
              d="M125.878 21.704v-4.378c2.53.806 4.718 1.209 6.566 1.209 2.53 0 3.795-.674 3.795-2.02 0-.49-.183-.898-.549-1.225-.43-.388-1.419-.903-2.971-1.545-2.735-1.113-4.5-2.006-5.296-2.68-1.325-1.121-1.99-2.581-1.99-4.376 0-2.235.873-3.94 2.617-5.113C129.623.525 131.744 0 134.418 0c2.143 0 4.21.27 6.198.811v4.194c-2.172-.838-4.116-1.255-5.83-1.255-2.276 0-3.415.647-3.415 1.942 0 .522.247.94.737 1.257.417.265 1.53.786 3.335 1.561 2.725 1.174 4.522 2.194 5.388 3.061.989.99 1.484 2.301 1.484 3.934 0 2.5-1.03 4.356-3.09 5.571-1.612.949-3.755 1.424-6.43 1.424-2.489 0-4.796-.266-6.917-.796m-22.913.352V.367h7.101l7.469 14.097V.367h4.349v21.69h-6.859L107.31 7.744v14.311h-4.345zm-19.73 0V.367H99v3.964h-9.705V9.2h8.45v3.581h-8.45v5.096h9.935v4.18H83.235zm-31.837 0V.367h7.913l5.434 13.607L70.378.367h7.546v21.69h-5.939V7.238L65.679 22.27h-3.781L55.76 7.239v14.817h-4.362zm-19.775 0V.367h15.78v3.964H37.7V9.2h8.45v3.581H37.7v5.096h9.919v4.18H31.623zM20.05.367h6.29v21.69h-6.29V.366zM.443 21.704v-4.378c2.52.806 4.71 1.209 6.567 1.209 2.531 0 3.796-.674 3.796-2.02 0-.49-.185-.898-.551-1.225-.419-.388-1.414-.903-2.985-1.545-2.704-1.103-4.464-1.995-5.28-2.68C.663 9.944 0 8.484 0 6.689c0-2.235.873-3.94 2.618-5.113C4.178.525 6.3 0 8.984 0c2.142 0 4.211.27 6.2.811v4.194C12.989 4.167 11.046 3.75 9.35 3.75c-2.275 0-3.412.647-3.412 1.942 0 .512.249.93.749 1.257.408.265 1.516.786 3.323 1.561 2.724 1.174 4.519 2.194 5.387 3.061.99.99 1.486 2.301 1.486 3.934 0 2.5-1.036 4.356-3.109 5.571-1.601.949-3.74 1.424-6.414 1.424-2.49 0-4.795-.266-6.918-.796"
              fill="currentColor"
            />
          </svg>
        }
        @if (logoUrl) {
          <img class="landing-page-logo" [src]="logoUrl" [alt]="heading + '\'s Background'" />
        }
      </div>
      @if (availableLanguages && availableLanguages.length > 1) {
        <div class="landing-page-language-switcher">
          <si-language-switcher
            [translationKey]="translationKey"
            [availableLanguages]="availableLanguages"
          />
        </div>
      }
    </div>
    <div class="landing-page-main">
      @if (subheading) {
        <div class="si-h2">{{ subheading }}</div>
      }
      <h1 class="si-h1-black my-2">{{ heading }}</h1>
      @if (!loginAlert) {
        <h2 class="mt-10 si-h2">{{ subtitle }}</h2>
      }
      @if (loginAlert) {
        <si-inline-notification
          class="my-6"
          [severity]="loginAlert.severity || 'danger'"
          [heading]="loginAlert.heading"
          [message]="loginAlert.message"
        />
      }
      <div class="landing-page-custom-content">
        <ng-content />
      </div>
    </div>
    <footer>
      <div>
        <div class="d-flex justify-content-start flex-row flex-wrap mt-6">
          @for (link of links; track $index) {
            <span>
              <a siLinkDefaultTarget="_blank" class="si-body-2 d-flex-inline me-6" [siLink]="link">
                {{ link.title | translate }}
              </a>
            </span>
          }
        </div>
        <div class="si-caption mt-4 mb-6 text-secondary d-flex flex-wrap">
          @if (version) {
            <span class="me-6">{{ version }}</span>
          }
          <si-copyright-notice [copyright]="copyrightDetails" />
        </div>
      </div>
    </footer>
  </div>
</div>
