@let loading = loading$ | async;
<div class="modal-header">
  @if (icon) {
    <i class="icon" [ngClass]="icon"></i>
  }
  <span class="modal-title" [id]="titleId">{{ heading | translate: translationParams }}</span>
  <button
    type="button"
    class="btn btn-circle btn-sm btn-ghost element-cancel"
    [attr.aria-label]="declineBtnName | translate"
    [disabled]="!!loading"
    (click)="modalRef.messageOrHide('decline')"
  >
  </button>
</div>
<div class="modal-body text-pre-wrap text-break">{{ message | translate: translationParams }}</div>
<div class="modal-footer">
  <si-loading-button
    buttonClass="btn btn-secondary"
    [disabled]="!!loading && loading !== 'decline'"
    [loading]="loading === 'decline'"
    (click)="modalRef.messageOrHide('decline')"
  >
    {{ declineBtnName | translate }}
  </si-loading-button>
  <si-loading-button
    buttonClass="btn btn-primary"
    [disabled]="!!loading && loading !== 'confirm'"
    [loading]="loading === 'confirm'"
    (click)="modalRef.messageOrHide('confirm')"
  >
    {{ confirmBtnName | translate }}
  </si-loading-button>
</div>
