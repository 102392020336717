<div
  [class.rounded]="!responsive"
  [class.list-header]="!responsive"
  [class.responsive]="responsive"
  [class.py-2]="responsive"
  [siAutoCollapsableList]="responsive"
>
  @if (!responsive) {
    <span class="list-title">{{ filterTitle | translate }}:</span>
  }
  @if (isEmpty) {
    <span class="list-text">{{ filterDefaultText | translate }}</span>
  }
  @for (filter of filters; track i; let i = $index) {
    <div class="pill-wrapper" siAutoCollapsableListItem>
      <si-filter-pill
        [class.dark-background]="responsive && colorVariant === 'base-0'"
        [totalPills]="filters.length"
        [filter]="filter"
        [responsive]="responsive"
        [disabled]="disabled"
        (deleteFilters)="deleteFilters($event)"
      />
    </div>
  }
  <si-filter-pill
    #overflowItem="siAutoCollapsableListOverflowItem"
    siAutoCollapsableListOverflowItem
    [class.dark-background]="responsive && colorVariant === 'base-0'"
    [responsive]="responsive"
    [disabled]="disabled"
    [filter]="{
      description: '+ ' + overflowItem.hiddenItemCount + ' filters',
      title: '',
      status: 'default',
      filterName: ''
    }"
    (deleteFilters)="deleteOverflowFilter()"
  />
  @if (allowReset && responsive && !isEmpty) {
    <button
      siAutoCollapsableListAdditionalContent
      type="button"
      class="text-nowrap btn btn-tertiary"
      [disabled]="disabled"
      (click)="onResetFilters()"
    >
      {{ resetText | translate }}
    </button>
  }
</div>
