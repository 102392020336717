import { Injectable } from '@angular/core';
import { BrowserObject, SystemInfo, TrendViewDefinition, ViewNode, ViewType, ViewTypeConverter } from '@gms-flex/services';
import { CalendarBx } from 'src/app/bx-services/schedule/calendar-proxy.model';

import { Device } from '../../bx-services/device/device.model';
import { EquipmentType } from '../../bx-services/device/equipment-type.model';
import { Equipment } from '../../bx-services/device/equipment.model';
import { SystemFolder } from '../../bx-services/folder/folder.service';
import { BuildingBx, CampusBx, LocationBx, LocationType } from '../../bx-services/location/location-proxy.model';
import { PointBx, PointSourceType } from '../../bx-services/point/point-proxy.model';
import { PointBxWithValue } from '../../bx-services/point/point.model';
import { calendarCollectionElement, scheduleCollectionElement } from '../../bx-services/schedule';
import { DeviceSubtype, EntityType } from '../../bx-services/shared/base.model';
import { Partition } from '../../bx-services/subscription/partition-proxy.model';
import { PropertyMapperBxToGmsService } from '../properties/property-mapper-bx-to-gms.service';
import { pointPropertyName } from '../properties/property-mappers/point-value-mapper.service';
import { ObjectTypeMapperService } from '../shared/object-type-mapper.service';

const cbmsViewNameDevices = 'DevicesView';
const cBmsViewDescriptorDevices = 'Devices View';
export const cBMSViewTypeDevices = ViewType.Application;

const cbmsViewNameBuilding = 'BuildingView';
const cBmsViewDescriptorBuilding = 'Building View';
export const cBMSViewTypeBuilding = ViewType.Logical;

export const unassignedDisciplineSubDiscipline = { id: 0, descriptor: 'Unassigned' };
export const unassignedTypeSubType = { id: 0, descriptor: 'Unassigned' };

export interface ParentInfo {
  objectId: string;
  designation: string;
  designationDisplay: string;
  location: string;
}

@Injectable({
  providedIn: 'root'
})
export class SystemBrowserMapperBxToGmsService {

  private readonly cache = new BrowserObjectCache();

  constructor(
    private readonly typeMapper: ObjectTypeMapperService, 
    private readonly propertyMapper: PropertyMapperBxToGmsService
  ) { }

  public mapSystemsToViewNodes(systems: SystemInfo[]): ViewNode[] {
    return systems.map(system => {
      const viewNode: ViewNode = {
        /* eslint-disable @typescript-eslint/naming-convention */
        SystemId: system.Id,
        ViewId: ViewTypeConverter.toNumber(cBMSViewTypeDevices),
        Descriptor: cBmsViewDescriptorDevices,
        Designation: `${system.Name}.${cbmsViewNameDevices}`,
        DesignationDisplay: `${system.NameDisplay}.${cbmsViewNameDevices}`,
        Name: cbmsViewNameDevices,
        SystemName: system.Name,
        SystemNameDisplay: system.NameDisplay,
        ViewType: cBMSViewTypeDevices
        /* eslint-enable @typescript-eslint/naming-convention */
      };

      const viewNodeBuilding: ViewNode = {
        /* eslint-disable @typescript-eslint/naming-convention */
        SystemId: system.Id,
        ViewId: ViewTypeConverter.toNumber(cBMSViewTypeBuilding),
        Descriptor: cBmsViewDescriptorBuilding,
        Designation: `${system.Name}.${cbmsViewNameBuilding}`,
        DesignationDisplay: `${system.NameDisplay}.${cbmsViewNameBuilding}`,
        Name: cbmsViewNameBuilding,
        SystemName: system.Name,
        SystemNameDisplay: system.NameDisplay,
        ViewType: cBMSViewTypeBuilding
        /* eslint-enable @typescript-eslint/naming-convention */
      };

      return [viewNode, viewNodeBuilding];
    }).flat();
  }

  public mapPartitionToTreeRoot(partition: Partition, viewNode: ViewNode): BrowserObject {
    const browserObject = this.createBrowserObject(
      viewNode,
      viewNode.Designation,
      viewNode.DesignationDisplay,
      `${viewNode.SystemNameDisplay}.${viewNode.Descriptor}`,
      partition.id,
      partition.attributes.name,
      partition.type,
      partition.id,
      partition.type,
      undefined,
      undefined,
      true);

    this.setMaps(browserObject, partition.id, partition.type, undefined, undefined, partition.id);
    return browserObject;
  }

  public mapLocationRoots(
    campuses: CampusBx[], buildings: BuildingBx[], viewNode: ViewNode,
    parentDesignation: string, parentDesignationDisplay: string, parentLocation: string, partitionId: string): BrowserObject[] {

    const browserObjects: BrowserObject[] = [];
    campuses.forEach(campus => {
      const browserObject = this.createBrowserObject(
        viewNode,
        parentDesignation,
        parentDesignationDisplay,
        parentLocation,
        campus.id,
        campus.attributes.label,
        campus.type,
        campus.id,
        EntityType.Location,
        campus.type);
      browserObjects.push(browserObject);
      this.setMaps(browserObject, campus.id, EntityType.Location, campus.type, partitionId, partitionId);
    });
    buildings.forEach(building => {
      const browserObject = this.createBrowserObject(
        viewNode,
        parentDesignation,
        parentDesignationDisplay,
        parentLocation,
        building.id,
        building.attributes.label,
        building.type,
        building.id,
        EntityType.Location,
        building.type);
      browserObjects.push(browserObject);
      this.setMaps(browserObject, building.id, EntityType.Location, building.type, partitionId, partitionId);
    });
    this.sortNameBasedAsc(browserObjects);
    this.cache.setChildNodesPerParentDesignation(parentDesignation, browserObjects);
    return browserObjects;
  }

  public mapLocationChildren(
    children: LocationType[],
    viewNode: ViewNode,
    parentDesignation: string,
    parentDesignationDisplay: string,
    parentLocation: string,
    parentId: string,
    partitionId: string,
    devices: Device[],
    equipments: Equipment[],
    equipmentTypes: EquipmentType[],
    filteredChildren: boolean,
    trendsFolder?: SystemFolder,
    graphicsFolder?: SystemFolder): BrowserObject[] {

    const parentLocationId = this.getEntityId(parentDesignation);

    const browserObjects: BrowserObject[] = [];
    children?.forEach(child => {
      const browserObject =
        this.mapLocationToBrowserObject(child, viewNode, parentDesignation, parentDesignationDisplay, parentLocation, parentId, partitionId);
      browserObjects.push(browserObject);
    });

    if (equipments) {
      equipments.forEach(equipment => {
        const equipmentType = equipmentTypes.find(eqType => eqType.id === equipment.equipmentTypeId);
        if (equipmentType !== undefined) {
          const browserObject =
            this.mapEquipmentToBrowserObject(equipment, viewNode, parentDesignation, parentDesignationDisplay, parentLocation, partitionId, equipmentType);
          browserObjects.push(browserObject);
        }
      });
    }

    if (devices) {
      devices.forEach(device => {
        if ((device.locationId === parentLocationId) && (device.isGateway)) {
          const browserObject =
            this.mapDeviceToBrowserObject(device, viewNode, parentDesignation, parentDesignationDisplay, parentLocation, parentId, partitionId);
          browserObjects.push(browserObject);
        }
      });
    }

    if (trendsFolder) {
      const browserObject =
        this.mapFolderToBrowserObject(trendsFolder, viewNode, parentDesignation, parentDesignationDisplay, parentLocation, parentId, partitionId);
      browserObjects.push(browserObject);
    }

    if (graphicsFolder) {
      const browserObject =
        this.mapFolderToBrowserObject(graphicsFolder, viewNode, parentDesignation, parentDesignationDisplay, parentLocation, parentId, partitionId);
      browserObjects.push(browserObject);
    }

    this.sortNameBasedAsc(browserObjects);
    if (filteredChildren === false) {
      this.cache.setChildNodesPerParentDesignation(parentDesignation, browserObjects);
    }

    return browserObjects;
  }

  public mapFolderChildren(
    children: SystemFolder[],
    trendChildren: TrendViewDefinition[],
    viewNode: ViewNode,
    parentDesignation: string,
    parentDesignationDisplay: string,
    parentLocation: string,
    parentId: string,
    partitionId: string): BrowserObject[] {

    const browserObjects: BrowserObject[] = [];

    if (children) {
      children.forEach(child => {
        const browserObject =
          this.mapFolderToBrowserObject(child, viewNode, parentDesignation, parentDesignationDisplay, parentLocation, parentId, partitionId);
        browserObjects.push(browserObject);
      });
    }

    if (trendChildren) {
      trendChildren.forEach(child => {
        const browserObject =
          this.mapTrendToBrowserObject(child, viewNode, parentDesignation, parentDesignationDisplay, parentLocation, parentId, partitionId);
        browserObjects.push(browserObject);
      });
    }

    this.sortNameBasedAsc(browserObjects);
    this.cache.setChildNodesPerParentDesignation(parentDesignation, browserObjects);
    return browserObjects;
  }

  public mapDeviceToBrowserObject(
    device: Device,
    viewNode: ViewNode,
    parentDesignation: string,
    parentDesignationDisplay: string,
    parentLocation: string,
    parentId: string,
    partitionId: string): BrowserObject {
    const browserObject = this.createBrowserObject(
      viewNode,
      parentDesignation,
      parentDesignationDisplay,
      parentLocation,
      device.id,
      device.name,
      device.description,
      device.id,
      device.type,
      device.subType,
      undefined,
      false
    );
    this.setMaps(browserObject, device.id, device.type, device.subType, parentId, partitionId);
    return browserObject;
  }

  public mapLocationToBrowserObject(
    item: LocationBx,
    viewNode: ViewNode,
    parentDesignation: string,
    parentDesignationDisplay: string,
    parentLocation: string,
    parentId: string,
    partitionId: string): BrowserObject {
    const browserObject = this.createBrowserObject(
      viewNode,
      parentDesignation,
      parentDesignationDisplay,
      parentLocation,
      item.id,
      item.attributes.label,
      item.type,
      item.id,
      EntityType.Location,
      item.type);
    this.setMaps(browserObject, item.id, EntityType.Location, item.type, parentId, partitionId);
    return browserObject;
  }

  public mapFolderToBrowserObject(
    folder: SystemFolder,
    viewNode: ViewNode,
    parentDesignation: string,
    parentDesignationDisplay: string,
    parentLocation: string,
    parentId: string,
    partitionId: string): BrowserObject {
    const browserObject = this.createBrowserObject(
      viewNode,
      parentDesignation,
      parentDesignationDisplay,
      parentLocation,
      folder.label,
      folder.label,
      EntityType.Folder,
      folder.id,
      EntityType.Folder,
      folder.folderType);
    this.setMaps(browserObject, folder.id, EntityType.Folder, folder.folderType, parentId, partitionId);
    return browserObject;
  }

  public mapTrendToBrowserObject(
    trendView: TrendViewDefinition,
    viewNode: ViewNode,
    parentDesignation: string,
    parentDesignationDisplay: string,
    parentLocation: string,
    parentId: string,
    partitionId: string): BrowserObject {
    const browserObject = this.createBrowserObject(
      viewNode,
      parentDesignation,
      parentDesignationDisplay,
      parentLocation,
      trendView.NameTvd,
      trendView.NameTvd,
      EntityType.TrendViewDefinition,
      trendView.TvdObjectId,
      EntityType.TrendViewDefinition);
    this.setMaps(browserObject, trendView.TvdObjectId, EntityType.TrendViewDefinition, undefined, parentId, partitionId);
    return browserObject;
  }

  public mapEquipmentToBrowserObject(
    equipment: Equipment,
    viewNode: ViewNode,
    parentDesignation: string,
    parentDesignationDisplay: string,
    parentLocation: string,
    partitionId: string,
    equipmentType?: EquipmentType): BrowserObject {
    // const equipmentType = equipmentTypes?.data.find(eqType => eqType.id === equipment.relationships.hasEquipmentType.data.id);
    const browserObject = this.createBrowserObject(
      viewNode,
      parentDesignation,
      parentDesignationDisplay,
      parentLocation,
      equipment.id,
      equipment.name,
      equipmentType?.name,
      equipment.id,
      equipment.type,
      equipmentType?.name,
      equipmentType?.domain,
      false
    );
    this.setMaps(browserObject, equipment.id, equipment.type, equipmentType?.name, equipment.locationId, partitionId);
    return browserObject;
  }

  public mapDevicesBehindGateway(
    children: Device[],
    viewNode: ViewNode,
    parentDesignation: string,
    parentDesignationDisplay: string,
    parentLocation: string,
    partitionId: string): BrowserObject[] {

    const browserObjects: BrowserObject[] = [];
    children.forEach(child => {
      // const nameFromDeviceInfo = children.included.find(item => item.relationships.hasDevice.data.id === child.id);
      const browserObject = this.createBrowserObject(
        viewNode,
        parentDesignation,
        parentDesignationDisplay,
        parentLocation,
        child.id,
        child.name,
        child.description,
        child.id,
        child.type);
      browserObjects.push(browserObject);
      this.setMaps(browserObject, child.id, child.type, undefined, child.gatewayId, partitionId);
    });

    this.sortNameBasedAsc(browserObjects);
    this.cache.setChildNodesPerParentDesignation(parentDesignation, browserObjects);
    return browserObjects;
  }

  public mapPoints(
    points: PointBx[],
    viewNode: ViewNode,
    parentDesignation: string,
    parentDesignationDisplay: string,
    parentLocation: string,
    parentId: string,
    partitionId: string): BrowserObject[] {

    const browserObjects: BrowserObject[] = [];
    points.forEach(point => {
      if ((point.attributes.isActive) || (point.attributes.source.type === PointSourceType.PointNB)) {
        const browserObject = this.mapPointToBrowserObject(point, viewNode, parentDesignation, parentDesignationDisplay, parentLocation, parentId, partitionId);
        browserObjects.push(browserObject);
      }
    });

    this.sortNameBasedAsc(browserObjects);
    this.cache.setChildNodesPerParentDesignation(parentDesignation, browserObjects);
    return browserObjects;
  }

  public mapSchedules(schedules: scheduleCollectionElement[], viewNode: ViewNode, 
    parentDesignation: string, parentDesignationDisplay: string, parentLocation: string, 
    parentId: string, partitionId: string): BrowserObject[] {

    const browserObjects: BrowserObject[] = [];
    schedules.forEach(schedule => {
      if (schedule.status === 'ACTIVE') {
        const browserObject = this.createBrowserObject(
          viewNode,
          parentDesignation,
          parentDesignationDisplay,
          parentLocation,
          schedule.id,
          schedule.name,
          schedule.description,
          schedule.schedule.id,
          EntityType.Schedule,
          undefined,
          undefined,
          false,
          true);
        browserObjects.push(browserObject);
        this.setMaps(browserObject, schedule.schedule.id, EntityType.Schedule, undefined, parentId, partitionId);
      }
    });

    this.sortNameBasedAsc(browserObjects);
    this.cache.setChildNodesPerParentDesignation(parentDesignation, browserObjects);
    return browserObjects;
  }

  public mapCalendars(
    calendars: calendarCollectionElement[], viewNode: ViewNode, 
    parentDesignation: string, parentDesignationDisplay: string, parentLocation: string, 
    parentId: string, partitionId: string): BrowserObject[] {

    const browserObjects: BrowserObject[] = [];
    calendars.forEach(calendar => {
      if (calendar.status === 'ACTIVE') {
        const browserObject = this.createBrowserObject(
          viewNode,
          parentDesignation,
          parentDesignationDisplay,
          parentLocation,
          calendar.id,
          calendar.name,
          calendar.description,
          calendar.calendar.id,
          EntityType.Calendar,
          undefined,
          undefined,
          false,
          true);
        browserObjects.push(browserObject);
        this.setMaps(browserObject, calendar.calendar.id, EntityType.Calendar, undefined, parentId, partitionId);
      }
    });
  
    this.sortNameBasedAsc(browserObjects);
    this.cache.setChildNodesPerParentDesignation(parentDesignation, browserObjects);
    return browserObjects;
  }

  public mapPointToBrowserObject(
    point: PointBx,
    viewNode: ViewNode,
    parentDesignation: string,
    parentDesignationDisplay: string,
    parentLocation: string,
    parentId: string,
    partitionId: string): BrowserObject {
    const browserObject = this.createBrowserObject(
      viewNode,
      parentDesignation,
      parentDesignationDisplay,
      parentLocation,
      point.id,
      point.attributes.name,
      point.attributes.description,
      point.id,
      EntityType.Point,
      point.attributes.function,
      undefined,
      false,
      true);
    this.setMaps(browserObject, point.id, EntityType.Point, point.attributes.function, parentId, partitionId);
    return browserObject;
  }

  public mapTvdToBrowserObject(
    tvd: TrendViewDefinition,
    viewNode: ViewNode,
    parentDesignation: string,
    parentDesignationDisplay: string,
    parentLocation: string,
    parentId: string,
    partitionId: string): BrowserObject {
    const browserObject = this.createBrowserObject(
      viewNode,
      parentDesignation,
      parentDesignationDisplay,
      parentLocation,
      tvd.NameTvd,
      tvd.NameTvd,
      EntityType.TrendViewDefinition,
      tvd.TvdObjectId,
      EntityType.TrendViewDefinition);
    this.setMaps(browserObject, tvd.TvdObjectId, EntityType.TrendViewDefinition, undefined, parentId, partitionId);
    return browserObject;
  }

  public isGatewayDevice(designation: string): boolean {
    const node = this.cache.getBrowserObjectPerDesignation(designation);
    return (node !== undefined) ? node.Attributes.SubTypeDescriptor === DeviceSubtype.Gateway : false;
  }

  public getEntityId(designation: string): string | undefined {
    return this.cache.getEntityId(designation);
  }

  public getParentEntityId(entityId: string): string | undefined {
    return this.cache.getParentEntityId(entityId);
  }

  public getPartitionId(entityId: string): string | undefined {
    return this.cache.getPartitionId(entityId);
  }

  public getEntityType(entityId: string): EntityType | undefined {
    return this.cache.getEntityType(entityId);
  }

  public getEntitySubType(entityId: string): string | undefined {
    return this.cache.getEntitySubType(entityId);
  }

  public getDccLocation(designation: string): string | undefined {
    const node = this.cache.getBrowserObjectPerDesignation(designation);
    return node ? node.Location : undefined;
  }

  public getDesignationDisplay(designation: string): string | undefined {
    const node = this.cache.getBrowserObjectPerDesignation(designation);
    return node ? node.DesignationDisplay : undefined;
  }

  public getBrowserObjects(entityId: string, viewNode?: ViewNode): BrowserObject[] | undefined {
    return this.cache.getBrowserObjects(entityId, viewNode);
  }

  public getBrowserObjectOfType(entityId: string, entityType: EntityType, viewNode?: ViewNode): BrowserObject[] | undefined {
    const brwObjs = this.getBrowserObjects(entityId, viewNode);
    if (brwObjs) {
      return (this.getEntityType(entityId) === entityType) ? brwObjs : undefined;
    } else {
      return undefined;
    }
  }

  public getBrowserObjectPartition(entityId: string, viewNode?: ViewNode): BrowserObject[] | undefined {
    return this.getBrowserObjectOfType(entityId, EntityType.Partition, viewNode);
  }

  public getBrowserObjectLocation(entityId: string, viewNode?: ViewNode): BrowserObject[] | undefined {
    return this.getBrowserObjectOfType(entityId, EntityType.Location, viewNode);
  }

  public getBrowserObjectPoint(entityId: string, viewNode?: ViewNode): BrowserObject[] | undefined {
    return this.getBrowserObjectOfType(entityId, EntityType.Point, viewNode);
  }

  public getBrowserObjectDevice(entityId: string, viewNode?: ViewNode): BrowserObject[] | undefined {
    return this.getBrowserObjectOfType(entityId, EntityType.Device, viewNode);
  }

  public getBrowserObjectEquipment(entityId: string, viewNode?: ViewNode): BrowserObject[] | undefined {
    return this.getBrowserObjectOfType(entityId, EntityType.Equipment, viewNode);
  }

  public getBrowserObjectCalendar(entityId: string, viewNode?: ViewNode): BrowserObject[] | undefined {
    return this.getBrowserObjectOfType(entityId, EntityType.Calendar, viewNode);
  }

  public getBrowserObjectSchedule(entityId: string, viewNode?: ViewNode): BrowserObject[] | undefined {
    return this.getBrowserObjectOfType(entityId, EntityType.Schedule, viewNode);
  }

  public getBrowserObjectFolder(entityId: string, viewNode?: ViewNode): BrowserObject[] | undefined {
    return this.getBrowserObjectOfType(entityId, EntityType.Folder, viewNode);
  }

  public getBrowserObjectPerDesignation(designation: string): BrowserObject | undefined {
    return this.cache.getBrowserObjectPerDesignation(designation);
  }

  public setResolved(entityId: string, viewId: number): void {
    this.cache.setResolved(entityId, viewId);
  }

  public getResolvmentState(entityId: string, viewId: number): EntityResolvmentState {
    return this.cache.getResolvmentState(entityId, viewId);
  }

  // public getEquipmentTypes(partitionId: string): EquipmentTypeResponse | undefined {
  //   return this.equipmentTypesPerPartition.get(partitionId);
  // }

  // public setEquipmentTypesForPartitions(partitions: string[], eqtRes: EquipmentTypeResponse[]): void {
  //   for(let idx = 0; idx < partitions.length; idx++) {
  //     this.equipmentTypesPerPartition.set(partitions[idx], eqtRes[idx]);
  //   }
  // }

  private createBrowserObject(
    viewNode: ViewNode,
    parentDesignation: string,
    parentDesignationDisplay: string,
    parentLocation: string,
    name: string,
    nameDisplay: string,
    description: string,
    objectIdWoSystemName: string,
    objectType: EntityType,
    subType?: string,
    discipline?: string,
    isRootNode: boolean = false,
    isLeaf: boolean = false
  ): BrowserObject {

    /* eslint-disable @typescript-eslint/naming-convention */
    return {
      HasChild: !isLeaf,
      ViewId: viewNode.ViewId,
      ViewType: viewNode.ViewType,
      Location: isRootNode ? `${parentLocation}:${description}` : `${parentLocation}.${description}`,
      Descriptor: description ? description : '',
      Designation: isRootNode ? `${parentDesignation}:${name}` : `${parentDesignation}.${name}`,
      DesignationDisplay: isRootNode ? `${parentDesignationDisplay}:${nameDisplay}` : `${parentDesignationDisplay}.${nameDisplay}`,
      ObjectId: `${viewNode.SystemName}:${objectIdWoSystemName}`,
      Name: name,
      NameDisplay: nameDisplay,
      SystemId: viewNode.SystemId,
      Attributes: {
        DefaultProperty: this.propertyMapper.getDefaultProperty(objectType, subType),
        ObjectId: `${viewNode.SystemName}:${objectIdWoSystemName}`,
        DisciplineDescriptor: discipline ? discipline : unassignedDisciplineSubDiscipline.descriptor,
        DisciplineId: unassignedDisciplineSubDiscipline.id, // TODO: implement mapping
        SubDisciplineDescriptor: unassignedDisciplineSubDiscipline.descriptor,
        SubDisciplineId: unassignedDisciplineSubDiscipline.id,
        TypeDescriptor: this.typeMapper.entityTypeToTypeDescriptor(objectType),
        TypeId: this.typeMapper.entityTypeToDccTypeId(objectType),
        SubTypeDescriptor: this.typeMapper.entitySubTypeToSubTypeDescriptor(objectType, subType),
        SubTypeId: subType ? this.typeMapper.entitySubTypeToDccSubTypeId(objectType, subType) : unassignedTypeSubType.id,
        ManagedType: this.typeMapper.entityTypeToManagedTypeId(objectType, subType),
        ManagedTypeName: this.typeMapper.entityTypeToManagedType(objectType, subType),
        ObjectModelName: objectType,
        Alias: '',
        FunctionName: '',
        ValidationRules: {
          Configuration: 0
        }
      }
    };
    /* eslint-enable @typescript-eslint/naming-convention */
  }

  private setMaps(
    browserObject: BrowserObject, entityId: string, entityType: EntityType, entitySubType: string, parentEntityId?: string, partitionId?: string): void {

    this.cache.setEntityId(browserObject.Designation, entityId);
    this.cache.setEntityType(entityId, entityType);
    this.cache.setEntitySubType(entityId, entitySubType);
    this.cache.setBrowserObjectPerDesignation(browserObject.Designation, browserObject);
    this.cache.setObject(entityId, browserObject);
    this.cache.setParentEntityId(entityId, parentEntityId);

    if (partitionId !== undefined) {
      this.cache.setPartitionId(entityId, partitionId);
    }
  }

  private sortNameBasedAsc(browserObjects: BrowserObject[]): void {
    browserObjects.sort((obj1, obj2) => {
      if (obj1.NameDisplay < obj2.NameDisplay) {
        return -1;
      } else if (obj1.NameDisplay > obj2.NameDisplay) {
        return 1;
      }
      return 0;
    });
  }
}

export type EntityResolvmentState = 'NotResolved' | 'Resolved';

export class BrowserObjectCache {
  private readonly browserObjectsPerEntityId: Map<string, Map<string, BrowserObject>> = new Map<string, Map<string, BrowserObject>>();
  private readonly resolveStatePerEntityIdAndView: Map<string, Map<number, EntityResolvmentState>> = new Map<string, Map<number, EntityResolvmentState>>();
  private readonly entityIdPerDesignation: Map<string, string> = new Map<string, string>();
  private readonly entityTypePerEntityId: Map<string, EntityType> = new Map<string, EntityType>();
  private readonly entitySubTypePerEntityId: Map<string, string> = new Map<string, string>();
  private readonly partitionIdPerEntityId: Map<string, string> = new Map<string, string>();
  private readonly browserObjectPerDesignation: Map<string, BrowserObject> = new Map<string, BrowserObject>();
  private readonly parentEntityIdPerEntityId: Map<string, string> = new Map<string, string>();
  private readonly childNodesPerParentDesignation: Map<string, BrowserObject[]> = new Map<string, BrowserObject[]>();

  public setObject(entityId: string, browserObject: BrowserObject): void {
    if (!this.browserObjectsPerEntityId.has(entityId)) {
      this.browserObjectsPerEntityId.set(entityId, new Map<string, BrowserObject>());
    }
    this.browserObjectsPerEntityId.get(entityId).set(browserObject.Designation, browserObject);
  }

  public setResolved(entityId: string, viewId: number): void {
    if (!this.resolveStatePerEntityIdAndView.has(entityId)) {
      this.resolveStatePerEntityIdAndView.set(entityId, new Map<number, EntityResolvmentState>());
    }
    this.resolveStatePerEntityIdAndView.get(entityId).set(viewId, 'Resolved');
  }

  public getResolvmentState(entityId: string, viewId: number): EntityResolvmentState {
    if (this.resolveStatePerEntityIdAndView.has(entityId)) {
      if (this.resolveStatePerEntityIdAndView.get(entityId).has(viewId)) {
        return this.resolveStatePerEntityIdAndView.get(entityId).get(viewId);
      } else {
        return 'NotResolved';
      }
    } else {
      return 'NotResolved';
    }
  }

  public getBrowserObjects(entityId: string, viewNode?: ViewNode): BrowserObject[] | undefined {
    if (this.browserObjectsPerEntityId.has(entityId)) {
      const nodes: BrowserObject[] = [];
      this.browserObjectsPerEntityId.get(entityId).forEach(value => nodes.push(value));
      if (viewNode) {
        return nodes.filter(node => node.ViewId === viewNode.ViewId);
      } else {
        return nodes;
      }
    } else {
      return undefined;
    }
  }

  public getEntityId(designation: string): string | undefined {
    return this.entityIdPerDesignation.get(designation);
  }

  public setEntityId(designation: string, entityId: string): void {
    this.entityIdPerDesignation.set(designation, entityId);
  }

  public getEntityType(entityId: string): EntityType | undefined {
    return this.entityTypePerEntityId.get(entityId);
  }

  public setEntityType(entityId: string, entityType: EntityType): void {
    this.entityTypePerEntityId.set(entityId, entityType);
  }

  public getEntitySubType(entityId: string): string | undefined {
    return this.entitySubTypePerEntityId.get(entityId);
  }

  public setEntitySubType(entityId: string, entitySubType: string): void {
    this.entitySubTypePerEntityId.set(entityId, entitySubType);
  }

  public getPartitionId(entityId: string): string | undefined {
    return this.partitionIdPerEntityId.get(entityId);
  }

  public setPartitionId(entityId: string, partitionId: string): void {
    this.partitionIdPerEntityId.set(entityId, partitionId);
  }

  public getBrowserObjectPerDesignation(designation: string): BrowserObject | undefined {
    return this.browserObjectPerDesignation.get(designation);
  }

  public setBrowserObjectPerDesignation(designation: string, browserObject: BrowserObject): void {
    this.browserObjectPerDesignation.set(designation, browserObject);
  }

  public getParentEntityId(entityId: string): string | undefined {
    return this.parentEntityIdPerEntityId.get(entityId);
  }

  public setParentEntityId(entityId: string, parentEntityId?: string): void {
    this.parentEntityIdPerEntityId.set(entityId, parentEntityId);
  }

  public setChildNodesPerParentDesignation(parentDesignation: string, browserObjects: BrowserObject[]): void {
    this.childNodesPerParentDesignation.set(parentDesignation, browserObjects);
  }
}
