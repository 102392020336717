<form class="form-group w-100 mb-5" [formGroup]="textForm">
  <label class="form-label" for="text"> {{ translatedLabels.get('message') }} </label>
  <textarea
    class="form-select"
    formControlName="textSelection"
    name="text"
    id="text"
    aria-label="alarm-text"
    (ngModelChange)="onTypeMessage()"
  >
  </textarea>
</form>
