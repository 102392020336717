import {
  A11yModule,
  ConfigurableFocusTrap,
  ConfigurableFocusTrapFactory,
  FocusMonitor
} from '@angular/cdk/a11y';
import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  signal,
  SimpleChanges,
  ViewChild
} from '@angular/core';

import { Cell } from './components/si-calendar-body.component';
import { isValid, nextMonth, previousMonth } from './date-time-helper';
import { SiDatepickerComponent } from './si-datepicker.component';
import { DatepickerConfig, DateRange } from './si-datepicker.model';

@Component({
  selector: 'si-datepicker-overlay',
  host: {
    class: 'mt-md-1 d-flex elevation-2 rounded-2 overflow-auto align-items-stretch'
  },
  template: `
    <si-datepicker
      #datepicker
      tabindex="-1"
      [initialFocus]="initialFocus"
      [config]="firstDatepickerConfig()"
      [class.first-datepicker]="isTwoMonthDateRange() && !isMobile"
      [class.first-datepicker-mobile]="isTwoMonthDateRange() && isMobile"
      [date]="date"
      [dateRange]="dateRange"
      [dateRangeRole]="isTwoMonthDateRange() ? 'START' : undefined"
      [time12h]="time12h"
      [timepickerLabel]="firstDatepickerConfig().startTimeLabel"
      [maxMonth]="maxMonth()"
      [(rangeType)]="rangeType"
      [(activeHover)]="activeHover"
      (dateChange)="dateChange.next($event)"
      (dateRangeChange)="onDateRangeChange($event)"
      (disabledTimeChange)="onDisableTimeChanged($event)"
      (focusedDateChange)="firstDatepickerFocusDateChange($event)"
    />
    @if (isTwoMonthDateRange()) {
      <si-datepicker
        #datepickerTwo
        class="mh-100 overflow-auto"
        tabindex="-1"
        [class.second-datepicker]="!isMobile"
        [class.second-datepicker-mobile]="isMobile"
        [hideTimeToggle]="true"
        [initialFocus]="initialFocus"
        [config]="secondDatepickerConfig()"
        [date]="date"
        [hideCalendar]="isMobile"
        [minMonth]="minMonth()"
        [dateRange]="dateRange"
        [dateRangeRole]="'END'"
        [disabledTime]="disableTime"
        [time12h]="time12h"
        [timepickerLabel]="secondDatepickerConfig().endTimeLabel"
        [(rangeType)]="rangeType"
        [(activeHover)]="activeHover"
        (dateChange)="dateChange.next($event)"
        (dateRangeChange)="onDateRangeChange($event)"
        (focusedDateChange)="secondDatepickerFocusDateChange($event)"
      />
    }
  `,
  styleUrl: './si-datepicker-overlay.component.scss',
  changeDetection: ChangeDetectionStrategy.Default,
  standalone: true,
  imports: [SiDatepickerComponent, A11yModule]
})
export class SiDatepickerOverlayComponent implements OnChanges, OnInit, AfterViewInit, OnDestroy {
  protected readonly minMonth = signal<Date>(new Date());
  protected readonly maxMonth = signal<Date>(new Date());

  @ViewChild('datepicker', { static: false }) protected datepicker?: SiDatepickerComponent;
  /**
   * {@inheritDoc SiDatepickerComponent#initialFocus}
   * @defaultValue false
   */
  @Input({ transform: booleanAttribute }) initialFocus = false;
  /**
   * {@inheritDoc SiDatepickerComponent#config}
   * @defaultValue
   * ```
   * {}
   * ```
   */
  @Input() config: DatepickerConfig = {};
  /**
   * {@inheritDoc SiDatepickerComponent#date}
   */
  @Input() date?: Date;
  /**
   * {@inheritDoc SiDatepickerComponent#dateRange}
   */
  @Input() dateRange?: DateRange;
  /**
   * {@inheritDoc SiDatepickerComponent#rangeType}
   */
  @Input() rangeType?: 'START' | 'END';
  /**
   * {@inheritDoc SiDatepickerComponent#time12h}
   */
  @Input({ transform: booleanAttribute }) time12h?: boolean;
  /**
   * {@inheritDoc SiDatepickerComponent#dateChange}
   */
  @Output() readonly dateChange = new EventEmitter<Date>();
  /**
   * {@inheritDoc SiDatepickerComponent#dateRangeChange}
   */
  @Output() readonly dateRangeChange = new EventEmitter<DateRange>();

  @Input()
  protected disableTime = false;
  /**
   * {@inheritDoc SiDatepickerComponent#disabledTimeChange}
   */
  @Output() readonly disabledTimeChange = new EventEmitter<boolean>();
  /**
   * @deprecated Property provides internal information that should not be used.
   *
   * @defaultValue false
   */
  isFocused = false;
  private readonly document = inject(DOCUMENT);
  private readonly elementRef = inject(ElementRef);
  private readonly focusMonitor = inject(FocusMonitor);
  private readonly focusTrapFactory = inject(ConfigurableFocusTrapFactory);
  private focusTrap!: ConfigurableFocusTrap;
  private previousActiveElement?: Element | HTMLElement;

  protected activeHover?: Cell;
  protected readonly isTwoMonthDateRange = signal<boolean>(false);
  protected readonly firstDatepickerConfig = signal<DatepickerConfig>({});
  protected readonly secondDatepickerConfig = signal<DatepickerConfig>({});
  /**
   * Indicate that the overlay is opened in small screen.
   * A modal dialog animation display when true and a wrapped two month calendar layout is displayed.
   *
   * @defaultValue false
   */
  @HostBinding('class.flex-wrap')
  @HostBinding('class.mobile-datepicker-overlay')
  @HostBinding('class.fade')
  @Input()
  isMobile = false;

  @HostBinding('class.show')
  protected completeAnimation = false;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.config) {
      this.isTwoMonthDateRange.set(
        !!this.config.enableDateRange &&
          (!!this.config.enableTwoMonthDateRange || !!this.config.showTime)
      );

      this.firstDatepickerConfig.set(
        !this.isTwoMonthDateRange ? this.config : { ...this.config, hideLabels: true }
      );

      this.secondDatepickerConfig.set({ ...this.config, hideLabels: true });
    }

    if (
      changes.config?.currentValue?.onlyMonthSelection &&
      this.isTwoMonthDateRange() &&
      this.dateRange?.start
    ) {
      this.minMonth.set(new Date(this.dateRange?.start.getFullYear(), 0, 1));
    }

    this.rangeType = 'START';
    if (
      isValid(changes.dateRange?.currentValue?.start) &&
      !isValid(changes.dateRange?.currentValue?.end)
    ) {
      this.rangeType = 'END';
    }
  }

  ngOnInit(): void {
    this.focusTrap = this.focusTrapFactory.create(this.elementRef.nativeElement);
    this.previousActiveElement = this.document.activeElement ?? undefined;
    if (this.isMobile) {
      setTimeout(() => (this.completeAnimation = true), 150);
    }
  }

  ngAfterViewInit(): void {
    // Monitor focus events
    this.focusMonitor
      .monitor(this.elementRef, true)
      .subscribe(origin => (this.isFocused = origin !== undefined));
  }

  ngOnDestroy(): void {
    this.focusMonitor.stopMonitoring(this.elementRef);
    this.focusTrap.destroy();
    if (this.initialFocus && this.previousActiveElement && 'focus' in this.previousActiveElement)
      this.previousActiveElement.focus();
  }

  /**
   * Focus active cell in the current datepicker view.
   */
  focusActiveCell(): void {
    this.datepicker?.focusActiveCell();
  }

  protected firstDatepickerFocusDateChange(newFocusedDate: Date): void {
    if (this.config?.onlyMonthSelection) {
      this.minMonth.set(new Date(newFocusedDate.getFullYear() + 1, 0, 1));
    } else if (newFocusedDate !== this.maxMonth()) {
      this.minMonth.set(nextMonth(newFocusedDate));
    }
  }

  protected secondDatepickerFocusDateChange(newFocusedDate: Date): void {
    if (newFocusedDate !== this.minMonth()) {
      if (this.config?.onlyMonthSelection) {
        this.maxMonth.set(new Date(newFocusedDate.getFullYear() - 1, 11, 31));
      } else {
        this.maxMonth.set(previousMonth(newFocusedDate));
      }
    }
  }

  protected onDisableTimeChanged(disableTime: boolean): void {
    this.disableTime = disableTime;
    this.disabledTimeChange.next(disableTime);
  }

  protected onDateRangeChange(dateRange: DateRange): void {
    this.dateRange = dateRange;
    this.dateRangeChange.emit(this.dateRange);
  }
}
