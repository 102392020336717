<ng-template #modalTemplate>
  <div class="modal-header">
    <h4 class="modal-title" [id]="modalTitleId">{{ propertyCommand.title || '' | translate }}</h4>
    <button type="button" class="close btn-close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body p-0">
    <si-property-container
      class="inline-table"
      [overrideMode]="'direct'"
      (validityChange)="isValid = $event"
    >
      @for (item of propertyCommand.parameters; track $index) {
        <div class="p-5 table-row d-flex align-items-center">
          <div class="w-100">
            <si-property [property]="item" [dateFormat]="dateFormat" [timeFormat]="timeFormat" />
          </div>
        </div>
      }
    </si-property-container>
  </div>
  <div class="modal-footer text-right text-end p-5">
    <div>
      <button
        type="button"
        class="btn btn-circle btn-sm btn-secondary element-cancel mr-3 me-3"
        (click)="close()"
      >
        <span class="visually-hidden">{{ dialogTextCancel | translate }}</span>
      </button>
      <button
        type="button"
        class="btn btn-circle btn-sm btn-primary element-ok"
        [disabled]="!isValid"
        (click)="submit()"
      >
        <span class="visually-hidden">{{ dialogTextOk | translate }}</span>
      </button>
    </div>
  </div>
</ng-template>
