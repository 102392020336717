import { NgClass, NgTemplateOutlet } from '@angular/common';
import {
  booleanAttribute,
  Component,
  DoCheck,
  inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { MenuItem } from '@simpl/element-ng/common';
import {
  SiHeaderDropdownComponent,
  SiHeaderDropdownItemsFactoryComponent,
  SiHeaderDropdownTriggerDirective
} from '@simpl/element-ng/header-dropdown';
import { SiLinkDirective } from '@simpl/element-ng/link';
import { SiTranslateModule } from '@simpl/element-translate-ng/translate';

import { SiNavbarPrimaryComponent } from '../si-navbar-primary/si-navbar-primary.component';

/** @deprecated Use the new `si-application-header` instead. */
@Component({
  selector: 'si-navbar-item',
  templateUrl: './si-navbar-item.component.html',
  standalone: true,
  imports: [
    SiLinkDirective,
    SiTranslateModule,
    NgClass,
    NgTemplateOutlet,
    SiHeaderDropdownComponent,
    SiHeaderDropdownItemsFactoryComponent,
    SiHeaderDropdownTriggerDirective
  ],
  host: { class: 'd-contents' }
})
export class SiNavbarItemComponent implements OnInit, DoCheck, OnDestroy {
  /**
   * MenuItem to display in the navbar.
   */
  @Input({ required: true }) item!: MenuItem;

  /**
   * Is the item a quick action displayed on the end (right in LTR) side
   */
  @Input({ transform: booleanAttribute }) quickAction?: boolean;

  @ViewChild(SiHeaderDropdownTriggerDirective)
  protected dropdownTrigger?: SiHeaderDropdownTriggerDirective;
  protected active = false;
  protected navbar = inject(SiNavbarPrimaryComponent);

  private hasBadge = false;

  ngOnInit(): void {
    this.navbar.header.closeMobileMenus.subscribe(() => this.dropdownTrigger?.close());
    this.navbar.navItemCount.update(value => value + 1);
  }

  ngDoCheck(): void {
    const newHasBadge = !!(this.item.badge ?? this.item.badgeDot);
    if (this.quickAction && this.hasBadge !== newHasBadge) {
      this.hasBadge = newHasBadge;
      if (this.hasBadge) {
        this.navbar.collapsibleActions?.badgeCount.update(value => value + 1);
      } else {
        this.navbar.collapsibleActions?.badgeCount.update(value => value - 1);
      }
    }
  }

  ngOnDestroy(): void {
    this.navbar.navItemCount.update(value => value - 1);
  }

  protected click(): void {
    if (!this.dropdownTrigger) {
      this.navbar.header.closeMobileMenus.next();
    }
  }

  protected get visuallyHideTitle(): boolean {
    return !this.navbar.collapsibleActions?.mobileExpanded();
  }
}
