import { FocusKeyManager, FocusOrigin } from '@angular/cdk/a11y';
import { isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  booleanAttribute,
  ChangeDetectorRef,
  Directive,
  ElementRef,
  HostBinding,
  HostListener,
  inject,
  Input,
  OnDestroy,
  OnInit,
  PLATFORM_ID
} from '@angular/core';
import { correctKeyRTL } from '@simpl/element-ng/common';
import { Subscription } from 'rxjs';

import {
  InteractableList,
  InteractableListDirective,
  InteractableListItemDirective,
  InteractableListOrientation,
  InteractableListPosition,
  SiListInteractionService
} from './si-list-interaction.service';

/**
 * @deprecated The {@link SiListInteractionDirective} and all related symbols should no longer be used.
 * - For creating menus, use {@link SiMenuModule} (not legacy!) instead: https://simpl.code.siemens.io/simpl-element/components/buttons-menus/menu/
 * - For creating listbox, use the {@link https://material.angular.io/cdk/listbox/overview | cdkListbox }
 * - For all other cases consider using tab-based strategy
 */
@Directive({
  selector: '[siListInteraction]',
  exportAs: 'si-list-interaction',
  standalone: true
})
export class SiListInteractionDirective
  implements AfterViewInit, OnDestroy, OnInit, InteractableListDirective
{
  private readonly isBrowser = isPlatformBrowser(inject(PLATFORM_ID));
  private _elementRef = inject(ElementRef<HTMLElement>);
  private _changeDetector = inject(ChangeDetectorRef);
  private listInteractionService = inject(SiListInteractionService);

  /**
   * Specifies whether sub menus should be displayed on the start side.
   * @defaultValue false
   * @defaultref {@link listInteractionSubListStart}
   * @deprecated Use the new Input {@link listInteractionSubListStart}
   */
  @Input() set listInteractionSubListLeft(value: boolean) {
    this.listInteractionSubListStart = value;
  }

  get listInteractionSubListLeft(): boolean {
    return this.listInteractionSubListStart;
  }

  /**
   * Specifies whether sub lists are displayed on the start side.
   *
   * @defaultValue false
   */
  @Input({ transform: booleanAttribute }) listInteractionSubListStart = false;

  get start(): boolean {
    return this.orientation === 'vert' && this.listInteractionSubListStart;
  }

  /**
   * Specifies whether the component should automatically be focused as soon as it is loaded.
   *
   * @defaultValue false
   */
  @Input({ transform: booleanAttribute }) listInteractionFocusOnLoad = false;

  /**
   * Specifies if any and which item should be focused when the component is focused,
   *
   * @defaultValue ''
   */
  @Input() listInteractionFocus: '' | 'first' | 'last' = '';

  private _focusLastFromParent = false;

  /**
   * Specifies if the last item should be focused when navigating to this list from a parent list (instead of the first).
   * @defaultValue false
   * @defaultref {@link _focusLastFromParent}
   */
  @Input() set listInteractionFocusLastFromParent(focusLastFromParent: boolean) {
    this._focusLastFromParent = focusLastFromParent;
    if (this.interactableList) {
      this.interactableList.focusLastFromParent.next(focusLastFromParent);
    }
  }

  get listInteractionFocusLastFromParent(): boolean {
    return this._focusLastFromParent;
  }

  get autofocusFromParent(): boolean {
    return this.listInteractionAutofocusFromParent;
  }

  /** @defaultValue false */
  @Input({ transform: booleanAttribute }) listInteractionAutofocusFromParent = false;

  /**
   * Specifies whether the list is a sub list and a parent item should automatically be searched or be defined.
   *
   * @defaultValue false
   */
  @Input({ transform: booleanAttribute }) listInteractionIsSubList = false;

  private _parentItem: InteractableListItemDirective | null = null;

  /**
   * Specifies a specific item that is the parent of the menu if the automatic one is incorrect.
   * @defaultValue null
   * @defaultref {@link _parentItem}
   */
  @Input() set listInteractionParentItem(item: InteractableListItemDirective | null) {
    this._parentItem = item;
    if (this.interactableList) {
      this.interactableList.triggerDefined.next(item);
    }
  }
  get listInteractionParentItem(): InteractableListItemDirective | null {
    if (!this._parentItem && this.interactableList?.trigger) {
      return this.interactableList.trigger.directive;
    }
    return this._parentItem;
  }

  get parentItem(): InteractableListItemDirective | null {
    return this.listInteractionParentItem;
  }

  private _position = 'integrated' as InteractableListPosition;

  /**
   * Specifies the position of the list relative to the parent.
   * Only relevant if this is a sub list and the parent has a different orientation.
   * @defaultref {@link _position}
   */
  @Input() set listInteractionPosition(position: InteractableListPosition) {
    this._position = position;
    if (this.interactableList) {
      this.interactableList.position.next(position);
    }
  }

  get listInteractionPosition(): InteractableListPosition {
    return this._position;
  }

  private _orientation: InteractableListOrientation = 'ste';

  /**
   * Specifies the orientation of the list, which signifies how it should be navigated.
   * The default is horizontal start to end.
   * @defaultValue 'ste'
   * @defaultref {@link _orientation}
   */
  @Input() set listInteractionOrientation(orientation: InteractableListOrientation) {
    this._orientation = orientation;
    if (this.interactableList) {
      this.interactableList.orientation.next(this._orientation);
    }
  }

  get listInteractionOrientation(): InteractableListOrientation {
    return this._orientation;
  }

  get orientation(): InteractableListOrientation {
    return this._orientation;
  }

  /**
   * Automatically refocus after pressing enter on one of the items.
   * Use when losing focus causes issues with keyboard interaction.
   *
   * @defaultValue false
   */
  @Input({ transform: booleanAttribute }) listInteractionRefocusAfterEnter = false;

  /**
   * Whether a hidden `dropdown-menu` or `si-menu` sub-menu
   * should be shown when the parent item is focused.
   *
   * @defaultValue true
   */
  @Input({ transform: booleanAttribute }) listInteractionShowSubListOnParentFocus = true;

  /**
   * Specifies whether the currently open item should be closed on click outside.
   *
   * @defaultValue true
   */
  @Input({ transform: booleanAttribute }) listInteractionCloseOnClickOut = true;

  private hadShowClassBefore = false;
  private keepOpen = false;

  private setKeepOpen(state: boolean): void {
    if (state && !this.keepOpen) {
      this.keepOpen = true;
      this.hadShowClassBefore = this._elementRef.nativeElement.classList.contains('show');
      this._elementRef.nativeElement.classList.add('show');
    } else if (!state && this.keepOpen) {
      this.keepOpen = false;
      if (!this.hadShowClassBefore) {
        this._elementRef.nativeElement.classList.remove('show');
      }
      this.hadShowClassBefore = false;
    }
  }
  /** @defaultValue '' */
  @HostBinding('attr.tabindex') tabindex = '';

  private interactableList!: InteractableList;

  get parentList(): InteractableListDirective | null {
    if (this.interactableList?.parent) {
      return this.interactableList.parent.directive;
    }
    return null;
  }

  get parentListAll(): InteractableListDirective | null {
    if (
      this.interactableList &&
      (this.interactableList.parent || this.interactableList.parentDisconnected)
    ) {
      return this.interactableList.parent
        ? this.interactableList.parent.directive
        : this.interactableList.parentDisconnected!.directive;
    }
    return null;
  }

  private connectionsCalculated = false;

  private _keyManager!: FocusKeyManager<InteractableListItemDirective>;

  private _previousChild?: { close: () => void };

  private _ignoreKeydown?: KeyboardEvent = undefined;

  private _orientationChangeSubscription!: Subscription;
  private _changeSubscription!: Subscription;
  private _tabOutSubscription!: Subscription;
  private ignoreNextChange = false;

  ngAfterViewInit(): void {
    this._keyManager = new FocusKeyManager(this.interactableList.allItemsQueryList)
      .withWrap()
      .withAllowedModifierKeys(['shiftKey']);
    this._orientationChangeSubscription = this.interactableList.orientation.subscribe(
      orientation => {
        this._orientation = orientation;
        if (orientation === 'vert') {
          this._keyManager.withHorizontalOrientation(null).withVerticalOrientation();
        } else {
          this._keyManager
            .withVerticalOrientation(false)
            .withHorizontalOrientation(orientation === 'ste' ? 'ltr' : 'rtl');
        }
      }
    );
    this._changeSubscription = this._keyManager.change.subscribe(() => {
      if (this.listInteractionShowSubListOnParentFocus && !this.ignoreNextChange) {
        this.openCloseAndFocusChildren();
        addEventListener('click', this.onClickOut);
      }
      this.ignoreNextChange = false;
    });
    this._tabOutSubscription = this._keyManager.tabOut.subscribe(() => {
      this.closePreviousChild();
      removeEventListener('click', this.onClickOut);
    });
  }

  ngOnInit(): void {
    this.interactableList = this.listInteractionService.addInteractableList(
      this,
      this._elementRef.nativeElement,
      this._parentItem,
      this._position,
      this._orientation,
      this._focusLastFromParent,
      this.listInteractionIsSubList,
      () => this.calculated()
    );
    const newTabindex = !this.listInteractionIsSubList ? '0' : '';
    if (this.tabindex !== newTabindex) {
      this.tabindex = newTabindex;
    }
  }

  ngOnDestroy(): void {
    this._orientationChangeSubscription?.unsubscribe();
    this._changeSubscription?.unsubscribe();
    this._tabOutSubscription?.unsubscribe();
    if (this.interactableList) {
      this.listInteractionService.removeInteractableList(this.interactableList);
    }
    if (this.isBrowser) {
      removeEventListener('click', this.onClickOut);
    }
  }

  calculated(): void {
    this.connectionsCalculated = true;
    const newTabindex = !this.listInteractionIsSubList || !this.parentList ? '0' : '';
    if (this.tabindex !== newTabindex) {
      this.tabindex = newTabindex;
    }
    if (this.isBrowser && document.activeElement === this.getHostElement()) {
      if (this.listInteractionFocus) {
        this.focusFirstItem('program', this.listInteractionFocus === 'last');
      }
    } else if (this.listInteractionFocusOnLoad) {
      this._elementRef.nativeElement.focus();
    }
  }

  /** Ignore the next keydown in this and all parent lists. Called from sub list. */
  ignoreKeydown(event: KeyboardEvent): void {
    if (this.parentListAll) {
      (this.parentListAll as InteractableListDirective).ignoreKeydown(event);
    }
    this._ignoreKeydown = event;
  }

  /** Handle a keyboard event from the list, delegating to the appropriate action. */
  @HostListener('keydown', ['$event'])
  handleKeydown(event: KeyboardEvent): void {
    if (this._ignoreKeydown?.key === event.key && this._ignoreKeydown.target === event.target) {
      this._ignoreKeydown = undefined;
      return;
    }

    if (this.parentListAll) {
      // If this is a sub list ignore the keydown in all parent lists (due to event bubbling).
      (this.parentListAll as InteractableListDirective).ignoreKeydown(event);
    }

    const manager = this._keyManager;

    const rtlCorrectedKey = correctKeyRTL(event.key);

    switch (rtlCorrectedKey) {
      case 'Escape':
        if (!event.altKey && !event.shiftKey && !event.ctrlKey && !event.metaKey) {
          event.preventDefault();
          this.goDownLevel();
        }
        break;
      case 'ArrowUp':
        if (this._orientation !== 'vert') {
          this.goDownLevel();
        } else {
          manager.onKeydown(event);
        }
        break;
      case 'ArrowDown':
        if (this.orientation !== 'vert') {
          this.goUpLevel();
        } else {
          manager.onKeydown(event);
        }
        break;
      case 'ArrowLeft':
        if (this._orientation === 'vert') {
          if (this.listInteractionSubListStart) {
            this.goUpLevel(false, true);
          } else {
            this.goDownLevel();
          }
        } else {
          manager.onKeydown(event);
        }
        break;
      case 'ArrowRight':
        if (this._orientation === 'vert') {
          if (this.listInteractionSubListStart) {
            this.goDownLevel(false, true);
          } else {
            this.goUpLevel();
          }
        } else {
          manager.onKeydown(event);
        }
        break;
      case 'Enter':
        if (this.listInteractionRefocusAfterEnter) {
          setTimeout(() => {
            if (
              this.isBrowser &&
              (!document.activeElement || document.activeElement === document.body)
            ) {
              this.focusActiveItem();
              this.openCloseAndFocusChildren(true);
            }
          });
        }
        break;
      default:
        manager.onKeydown(event);
    }
  }

  private onClickOut = (event: MouseEvent): void => this.clickOutHandler(event);

  private clickOutHandler(event: MouseEvent): void {
    const target = event.target as HTMLElement;
    if (
      this.listInteractionCloseOnClickOut &&
      this.interactableList &&
      !this._keyManager?.activeItem?.hasChildElement(target)
    ) {
      this.closePreviousChild();
      removeEventListener('click', this.onClickOut);
    }
  }

  private closePreviousChild(): void {
    if (this._previousChild) {
      this._previousChild.close();
      this._previousChild = undefined;
      this._changeDetector.detectChanges();
    }
  }

  private openCloseAndFocusChildren(skipOpenClose = false): void {
    if (!skipOpenClose) {
      this.closePreviousChild();
    }
    const active = this._keyManager.activeItem;
    if (active) {
      if (active.dropdownToggle) {
        active.dropdownToggle.open();
        this._previousChild = { close: () => active.dropdownToggle?.close() };
        this._changeDetector.detectChanges();
      } else {
        const activeList = this.getItemChildren(active);
        if (activeList && activeList.getHostElement() !== this.getHostElement()) {
          if (!skipOpenClose) {
            activeList.open();
            this._previousChild = activeList;
            this._changeDetector.detectChanges();
          }
          if (activeList.autofocusFromParent) {
            Promise.resolve().then(() => activeList.focusFirstItem());
          }
        }
      }
    }
  }

  open(): void {
    this.setKeepOpen(true);
  }

  close(): void {
    this.setKeepOpen(false);
  }

  setActiveItem(activeItem: InteractableListItemDirective, ignoreNextChange = false): void {
    if (
      !this._keyManager.activeItem ||
      activeItem.getHostElement() !== this._keyManager.activeItem.getHostElement()
    ) {
      this.ignoreNextChange = ignoreNextChange;
      this._keyManager.setActiveItem(activeItem);
    }
  }

  focusActiveItem(
    origin: FocusOrigin = 'program',
    reverse = false,
    nestedItem?: InteractableListItemDirective
  ): void {
    let active = this._keyManager.activeItem;
    if (!active && nestedItem && this.interactableList) {
      const foundActiveItem = this.interactableList.allItemsQueryList.find(
        listItem => nestedItem.getHostElement() === listItem.getHostElement()
      );
      if (foundActiveItem) {
        this._keyManager.setActiveItem(foundActiveItem);
        active = this._keyManager.activeItem;
      }
    }
    if (active) {
      if (active && nestedItem && nestedItem.getHostElement() === active?.getHostElement()) {
        if (reverse) {
          this._keyManager.setNextItemActive();
        } else {
          this._keyManager.setPreviousItemActive();
        }
      }
      const activeIndex = this._keyManager.activeItemIndex;
      const newActive = this._keyManager.activeItem;
      if (newActive) {
        if (
          activeIndex &&
          this.interactableList?.allItemsQueryList.get(activeIndex)?.getHostElement() !==
            newActive?.getHostElement()
        ) {
          this._keyManager.setActiveItem(newActive);
        } else {
          newActive.focus();
        }
      }
    }
  }

  /**
   * Focus the first item in the list.
   * @param origin - Action from which the focus originated. Used to set the correct styling.
   */
  focusFirstItem(origin: FocusOrigin = 'program', reverse = false, fromParent = false): void {
    const itemIndexes = Array.from(this.interactableList.allItemsQueryList)
      .map((item, index) => {
        if (
          item.parentList &&
          !item.disabled &&
          item.parentList.getHostElement() === this.getHostElement()
        ) {
          return index;
        } else {
          return -1;
        }
      })
      .filter(index => index !== -1);
    if (itemIndexes.length > 0) {
      let actualReverse = reverse;
      if (this.orientation === 'ets') {
        actualReverse = !actualReverse;
      }
      if (fromParent && this.listInteractionFocusLastFromParent) {
        actualReverse = !actualReverse;
      }
      const actualIndex = actualReverse ? itemIndexes[itemIndexes.length - 1] : itemIndexes[0];
      const activeItem = this.interactableList.allItemsQueryList.get(actualIndex)!;
      const activeItemHost = activeItem.getHostElement();
      if (
        this.interactableList.parent?.integratedNestedItems.find(
          item => item.element === activeItemHost
        )
      ) {
        this.parentList!.setActiveItem(activeItem);
      } else if (this.interactableList.integratedParentItem) {
        this.parentList!.setActiveItem(this.interactableList.integratedParentItem.directive);
      }
      if (this._keyManager.activeItem?.getHostElement() === activeItem.getHostElement()) {
        this.focusActiveItem();
        this.openCloseAndFocusChildren();
        addEventListener('click', this.onClickOut);
      } else {
        this._keyManager.setFocusOrigin(origin).setActiveItem(activeItem);
      }
    }
  }

  private getItemChildren(item: InteractableListItemDirective): InteractableListDirective | null {
    return item.childList;
  }

  private goDownLevel(reverse = false, start = false): void {
    if (this.parentList) {
      this.closePreviousChild();
      removeEventListener('click', this.onClickOut);
      const nestedOrParentItem = this.interactableList
        ? (this.interactableList.integratedParentItem
            ? this.interactableList.integratedParentItem
            : this.interactableList.items.find(
                item => item.parentIntegrationIndex !== -1 || item.nestedIntegration
              )
          )?.directive
        : undefined;
      let actualReverse = reverse;
      const parent = this.parentList as InteractableListDirective;
      if (parent.orientation === 'ets') {
        actualReverse = !actualReverse;
      }
      if (start && !parent.start) {
        actualReverse = !actualReverse;
      }
      parent.focusActiveItem('program', actualReverse, nestedOrParentItem);
    } else {
      this.goUpLevel(true);
    }
  }

  private goUpLevel(reverse = false, start = false): void {
    const active = this._keyManager.activeItem;
    if (active) {
      const activeList = this.getItemChildren(active);
      if (activeList) {
        activeList.focusFirstItem('program', reverse, true);
      } else if (this.parentList) {
        this.goDownLevel(true, start);
      }
    }
  }

  @HostListener('focusin', ['$event.target'])
  focusInEvent(target: EventTarget): void {
    if (this.connectionsCalculated) {
      if (target === this.getHostElement()) {
        if (this.listInteractionFocus) {
          this.focusFirstItem('program', this.listInteractionFocus === 'last');
        }
      } else {
        if (this.interactableList) {
          const foundItem = this.interactableList.items.find(item =>
            item.element.contains(target as HTMLElement)
          );
          if (foundItem) {
            this.setActiveItem(foundItem.directive, true);
          }
        }
        Promise.resolve().then(() => {
          if (this.tabindex !== '-1') {
            this.tabindex = '-1';
          }
        });
      }
    }
  }

  @HostListener('focusout')
  focusOutEvent(): void {
    if (this.tabindex === '-1') {
      Promise.resolve().then(
        () => (this.tabindex = !this.listInteractionIsSubList || !this.parentList ? '0' : '')
      );
    }
  }

  /** Returns the host DOM element. */
  getHostElement(): HTMLElement {
    return this._elementRef.nativeElement!;
  }
}
