import { DatePipe } from '@angular/common';
import {
  booleanAttribute,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  LOCALE_ID,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { isRTL } from '@simpl/element-ng/common';
import { SiTranslateModule } from '@simpl/element-translate-ng/translate';

import * as calendarUtils from '../date-time-helper';
import { WeekStart } from '../si-datepicker.model';
import { Cell, SiCalendarBodyComponent } from './si-calendar-body.component';
import { SiCalendarDirectionButtonComponent } from './si-calendar-direction-button.component';
import { DayCompareAdapter } from './si-compare-adapter';
import { SiInitialFocusComponent } from './si-initial-focus.component';

/**
 * Show dates of a single month as table and handles the keyboard interactions.
 * The focusedDate is handled according the keyboard interactions.
 */
@Component({
  selector: 'si-day-selection',
  templateUrl: './si-day-selection.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    DatePipe,
    SiCalendarBodyComponent,
    SiCalendarDirectionButtonComponent,
    SiTranslateModule
  ]
})
export class SiDaySelectionComponent extends SiInitialFocusComponent implements OnChanges {
  /**
   * Indicate whether the week numbers shall be hidden.
   *
   * @defaultValue false
   */
  @Input({ transform: booleanAttribute }) hideWeekNumbers = false;
  /**
   * Defines the starting day of the week. Default is `monday`.
   *
   * @defaultValue 'monday'
   */
  @Input() weekStartDay: WeekStart = 'monday';
  /** The list of translated month labels. */
  @Input() months!: string[];
  /** The active date, the cell which will receive the focus. */
  @Input() set focusedDate(v: Date) {
    const oldActiveDate = this._focusedDate;
    this._focusedDate = v;
    if (calendarUtils.isAnotherMonthOrYear(this._focusedDate, oldActiveDate)) {
      // Re-calc month view
      this.initView();
      this.activeMonthChange.emit(v);
    }
  }
  get focusedDate(): Date {
    return this._focusedDate;
  }
  /** Today button text */
  @Input() todayLabel?: string;
  /** Aria label for calendar week column */
  @Input() calenderWeekLabel?: string;
  /** Emits when the active focused date changed, typically during keyboard navigation */
  @Output() readonly focusedDateChange = new EventEmitter<Date>();
  /** Emits when the active focused date changed to another month / year, typically during keyboard navigation */
  @Output() readonly activeMonthChange = new EventEmitter<Date>();
  /** Emits when the user requests a different to show a different view */
  @Output() readonly viewChange = new EventEmitter<'year' | 'month'>();
  /** The translated list of week days. */
  protected days: string[] = [];
  /** The week numbers which are shown as row label */
  protected weekNumbers: string[] = [];
  /**
   * The current visible list of calendar days.
   * Every time the focusedDate changes to either another month or year the list will be rebuild.
   */
  protected weeks: Cell[][] = [];
  /** Compare date based on the current view */
  protected compareAdapter = new DayCompareAdapter();
  protected isTodayButtonDisabled = false;
  /** The currently focused date */
  private _focusedDate = calendarUtils.today();
  private readonly locale = inject(LOCALE_ID).toString();
  private readonly cdRef = inject(ChangeDetectorRef);

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.maxDate || changes.minDate || changes.weekStartDay) {
      this.initView();
    }
  }

  protected calendarBodyKeyDown(event: KeyboardEvent): void {
    const isRtl = isRTL();
    const oldActiveDate = this.focusedDate;
    switch (event.key) {
      case 'ArrowLeft':
        this.focusedDate = calendarUtils.addDaysInRange(
          this._focusedDate,
          isRtl ? 1 : -1,
          this.minDate,
          this.maxDate
        );
        break;
      case 'ArrowRight':
        this.focusedDate = calendarUtils.addDaysInRange(
          this._focusedDate,
          isRtl ? -1 : 1,
          this.minDate,
          this.maxDate
        );
        break;
      case 'ArrowUp':
        this.focusedDate = calendarUtils.addDaysInRange(
          this._focusedDate,
          -7,
          this.minDate,
          this.maxDate
        );
        break;
      case 'ArrowDown':
        this.focusedDate = calendarUtils.addDaysInRange(
          this._focusedDate,
          7,
          this.minDate,
          this.maxDate
        );
        break;
      case 'Home':
        this.focusedDate = calendarUtils.getWeekStartDate(this._focusedDate, this.weekStartDay);
        break;
      case 'End':
        this.focusedDate = calendarUtils.getWeekEndDate(this._focusedDate, this.weekStartDay);
        break;
      case 'PageDown':
        this.focusedDate = calendarUtils.addMonthsInRange(
          this._focusedDate,
          1,
          this.minDate,
          this.maxDate
        );
        break;
      case 'PageUp':
        this.focusedDate = calendarUtils.addMonthsInRange(
          this._focusedDate,
          -1,
          this.minDate,
          this.maxDate
        );
        break;
      case 'Enter':
      case 'Space':
      default:
        // Don't prevent default or focus active cell on keys that we don't explicitly handle.
        return;
    }
    if (!calendarUtils.isSameDate(oldActiveDate, this.focusedDate)) {
      this.focusedDateChange.emit(this.focusedDate);
      this.focusActiveCell();
    }

    // Prevent unexpected default actions such as form submission.
    event.preventDefault();
  }

  /**
   * Update month of focusedDate.
   * @param offset -1 or -1.
   */
  protected setMonthOffset(offset: number): void {
    this.focusedDate = calendarUtils.addMonthsInRange(
      this.focusedDate,
      offset,
      this.minDate,
      this.maxDate
    );
    this.focusedDateChange.emit(this.focusedDate);
  }

  /** Change the focusedDate to today */
  protected goToToday(): void {
    this.focusedDate = calendarUtils.today();
    this.focusedDateChange.emit(this.focusedDate);
    this.focusActiveCell();
  }

  /**
   * Indicate the previous button shall be disabled.
   * This happens when the focusedDate is equal or before the minDate.
   */
  protected isPreviousButtonDisabled(): boolean {
    if (!this.minDate) {
      return false;
    }
    return (
      calendarUtils.isSameMonth(this.focusedDate, this.minDate) ||
      calendarUtils.isAfterMonth(this.minDate, this.focusedDate)
    );
  }

  /**
   * Indicate the next button shall be disabled.
   * This happens when the focusedDate is equal or after the maxDate.
   */
  protected isNextButtonDisabled(): boolean {
    if (!this.maxDate) {
      return false;
    }
    return (
      calendarUtils.isSameMonth(this.focusedDate!, this.maxDate) ||
      calendarUtils.isAfterMonth(this.focusedDate, this.maxDate)
    );
  }

  protected emitSelectedValue(selected: Date): void {
    if (selected !== this.startDate || selected !== this.endDate) {
      this.selectedValueChange.emit(selected);
    }
    this.cdRef.markForCheck();
  }

  protected emitActiveDate(active: Date): void {
    this.focusedDateChange.emit(active);
  }

  protected emitViewChange(view: 'year' | 'month'): void {
    this.viewChange.emit(view);
  }

  /**
   * Initialize weekday labels.
   */
  private initWeekdays(): void {
    this.days = calendarUtils.getDayStrings(this.locale, this.weekStartDay);
  }

  /**
   * Initialize view based on the focusedDate.
   */
  private initView(): void {
    this.initWeekdays();

    // Disable today button if it is the same month
    this.isTodayButtonDisabled = calendarUtils.isSameMonth(calendarUtils.today(), this.focusedDate);

    const monthStart = calendarUtils.getFirstDateInMonth(this._focusedDate);
    const monthEnd = calendarUtils.getLastDateInMonth(this._focusedDate);
    /**
     * We start the month with the first day in the week which has the effect that dates are
     * visible which aren't in the active month.
     */
    const startDate = calendarUtils.getWeekStartDate(monthStart, this.weekStartDay);

    this.weeks = [[], [], [], [], [], []];
    let weekIndex = 0;
    this.weekNumbers = [];
    for (
      let i = 0, date = new Date(startDate);
      this.weeks[this.weeks.length - 1].length < 7;
      i++, date.setDate(date.getDate() + 1)
    ) {
      if (i > 0 && i % 7 === 0) {
        weekIndex++;
      }

      const activeMonth = calendarUtils.isSameOrBetween(date, monthStart, monthEnd);
      const isToday = calendarUtils.isSameDate(date, calendarUtils.today());
      const outOfRange = !calendarUtils.isSameOrBetween(date, this.minDate, this.maxDate);

      this.weeks.at(weekIndex)?.push({
        value: date.getDate(),
        disabled: outOfRange,
        ariaLabel: date.toDateString(),
        displayValue: date.getDate().toString(),
        isPreview: !activeMonth,
        isToday,
        valueRaw: calendarUtils.createDate(date),
        cssClasses: ['day', activeMonth ? 'si-title-1' : 'si-body-1']
      });
    }
    this.initWeekNumbers();
  }

  /**
   * Initialize calendar week numbers cells based on the weeks array.
   */
  private initWeekNumbers(): void {
    this.weeks.forEach(w => {
      const weekNumber = calendarUtils.getWeekOfYear(w[0].valueRaw, this.weekStartDay);
      this.weekNumbers.push(weekNumber.toString());
    });
  }
}
