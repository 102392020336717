<si-avatar size="small" color="0" [altText]="name" [initials]="initials" [imageUrl]="imageUrl" />
<div class="item-title" aria-hidden="true" [class.d-none]="visuallyHideTitle">
  {{ name }}
</div>
@if (badgeValue) {
  <div class="badge-text">{{ badgeValue }}</div>
}
@if (dropdownTrigger) {
  <div class="element-down-2 dropdown-caret ms-auto ps-0"></div>
}
