import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SiAvatarComponent } from '@simpl/element-ng/avatar';

import { SiHeaderActionIconItemBase } from './si-header-action-item-icon-base.directive';

/** Adds an account item to the header. Should be located inside `.header-actions`. */
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'button[si-header-account-item]',
  standalone: true,
  imports: [SiAvatarComponent],
  templateUrl: './si-header-account-item.component.html',
  host: {
    class: 'header-item focus-inside p-4',
    '[class.dropdown-toggle]': '!!dropdownTrigger'
  },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SiHeaderAccountItemComponent extends SiHeaderActionIconItemBase {
  /** Name of the account. */
  @Input({ required: true }) name!: string;
  /** Initials of the account. If not provided, they will be calculated. */
  @Input() initials?: string;
  /** URL to an image which should be shown instead of the initials. */
  @Input() imageUrl?: string;
}
