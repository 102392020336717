import { ChangeDetectionStrategy, Component } from '@angular/core';

/** The siemens logo. Should be located inside `.header-brand`. */
@Component({
  selector: 'si-header-siemens-logo, [si-header-siemens-logo]',
  standalone: true,
  templateUrl: './si-header-siemens-logo.component.html',
  host: {
    class: 'header-logo px-6 focus-inside'
  },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SiHeaderSiemensLogoComponent {}
