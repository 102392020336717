import { ValueBase } from '@simpl/buildings-types';
import { Observable, of } from 'rxjs';

import {
  AnyPropertyValueType,
  BulkCommandEvent,
  BulkProperty,
  BulkPropertyValues,
  BulkResult,
  CommandEvent,
  Property
} from '../interfaces/index';

/**
 * API to implement with a service for property loading and writing.
 * The generic type also allows `undefined`, which will display as the placeholder {@link DefaultProperty}, `undefined` will never be written.
 * If you add custom property value types, you need to display them yourself using {@link SiPropertyViewerComponent#customTemplate}.
 */
export abstract class PropertyApi<
  T extends ValueBase | undefined = AnyPropertyValueType | undefined
> {
  abstract getProperties(objectId: string): Observable<Property<T>[]>;
  abstract writeProperty(
    objectId: string,
    // `DefaultProperty` (`undefined`) will never be written, does not need to be handled by consumer.
    property: Property<Exclude<T, undefined>>
  ): Observable<void>;
  abstract executeCommand(objectId: string, command: CommandEvent<T>): Observable<void>;

  abstract getBulkProperties?(objectIds: string[]): Observable<BulkProperty[]>;

  abstract getBulkPropertyValues?(
    objectIds: string[],
    propertyId: string
  ): Observable<BulkPropertyValues<T>>;

  abstract writeBulkProperty?(
    objectIds: string[],
    propertyId: string,
    // `DefaultProperty` (`undefined`) will never be written, does not need to be handled by consumer.
    value: Exclude<T, undefined>
  ): Observable<BulkResult>;

  abstract executeBulkCommand?(
    objectIds: string[],
    command: BulkCommandEvent<T>
  ): Observable<BulkResult>;
}

export class FakePropertyApiService implements PropertyApi {
  getProperties(): Observable<never[]> {
    return of([]);
  }

  writeProperty(): Observable<void> {
    return of();
  }

  executeCommand(): Observable<void> {
    return of();
  }
}
