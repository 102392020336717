import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  computed,
  DoCheck,
  HostBinding,
  Input,
  signal
} from '@angular/core';
import { SiTranslateModule } from '@simpl/element-translate-ng/translate';

import { SiFormItemComponent } from '../si-form-item/si-form-item.component';

@Component({
  selector: 'si-form-fieldset',
  standalone: true,
  imports: [SiTranslateModule],
  templateUrl: './si-form-fieldset.component.html',
  styleUrl: '../si-form.shared.scss',
  host: {
    role: 'group',
    class: 'si-form-input'
  },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SiFormFieldsetComponent implements DoCheck {
  private static labelIdCounter = 0;

  /** The label for the entire fieldset. */
  @Input({ required: true }) label!: string;

  /** Overrides the parent label width. */
  @Input() @HostBinding('style.--si-form-label-width') labelWidth?: string;

  /** Adds a required marker to the label */
  @Input({ transform: booleanAttribute }) set required(value: boolean) {
    this.requiredInput.set(value);
  }

  protected requiredInput = signal(false);

  /**
   * Switches all child inputs to inline mode
   *
   * @defaultValue false
   */
  @Input({ transform: booleanAttribute }) inline = false;
  private formItems = signal<SiFormItemComponent[]>([]);

  /** @internal */
  readonly hasOnlyRadios = computed(() => {
    // Check if the fieldset only contains radio buttons.
    // We can safely assume that, if all items have the same control name and if there are at least 2 items.
    const items = this.formItems();
    if (items.length > 1) {
      const first = items[0];
      return items.every(item => item.ngControl?.name === first.ngControl?.name);
    }

    return false;
  });

  protected readonly errors = computed(() =>
    // All errors should be the same for radios, so we just take the first.
    this.hasOnlyRadios() ? this.formItems()[0].errors() : []
  );
  protected touched = signal(false);
  protected isRequired = computed(
    () =>
      this.requiredInput() ||
      (this.hasOnlyRadios() && this.formItems().every(item => item.required))
  );

  @HostBinding('attr.aria-labelledby')
  protected labelId = `__si-form-fieldset-label-${SiFormFieldsetComponent.labelIdCounter++}`;

  ngDoCheck(): void {
    this.touched.set(this.formItems().some(item => item.ngControl?.touched));
  }

  /** @internal */
  registerFormItem(item: SiFormItemComponent): void {
    this.formItems.update(items => [...items, item]);
  }

  /** @internal */
  unregisterFormItem(item: SiFormItemComponent): void {
    this.formItems.update(items => items.filter(i => i !== item));
  }
}
