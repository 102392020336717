@let loading = loading$ | async;
<div class="modal-header">
  @if (icon) {
    <i class="icon" [ngClass]="icon"></i>
  }
  <span class="modal-title" [id]="titleId">{{ heading | translate: translationParams }}</span>
  <button
    type="button"
    class="btn btn-circle btn-sm btn-ghost element-cancel"
    [attr.aria-label]="cancelBtnName | translate"
    [disabled]="!!loading"
    (click)="modalRef.messageOrHide('cancel')"
  >
  </button>
</div>
<div class="modal-body text-pre-wrap text-break">{{ message | translate: translationParams }}</div>
<div class="modal-footer">
  <si-loading-button
    buttonClass="btn btn-secondary"
    [disabled]="!!loading && loading !== 'cancel'"
    [loading]="loading === 'cancel'"
    (click)="modalRef.messageOrHide('cancel')"
  >
    {{ cancelBtnName | translate }}
  </si-loading-button>
  <si-loading-button
    buttonClass="btn btn-danger"
    [disabled]="!!loading && loading !== 'delete'"
    [loading]="loading === 'delete'"
    (click)="modalRef.messageOrHide('delete')"
  >
    {{ deleteBtnName | translate }}
  </si-loading-button>
</div>
