import { NgClass } from '@angular/common';
import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpEventType,
  HttpHeaders,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import {
  booleanAttribute,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  numberAttribute,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { SiIconComponent } from '@simpl/element-ng/icon';
import { SiInlineNotificationComponent } from '@simpl/element-ng/inline-notification';
import { SiProgressbarComponent } from '@simpl/element-ng/progressbar';
import { SiTranslateModule } from '@simpl/element-translate-ng/translate';
import { Observable, Subscription } from 'rxjs';
import { retry } from 'rxjs/operators';

import { SiFileDropzoneComponent } from './si-file-dropzone.component';
import { UploadFile } from './si-file-uploader.model';

/**
 * The FileUploadResult is emitted at completion of the file uploading
 * via the `uploadCompleted` emitter. On success the Http `response` from
 * the backend is provided and on failure, the `error` object is available.
 */
export interface FileUploadResult {
  file: string;
  response?: HttpResponse<unknown>;
  error?: Error;
}

export interface FileUploadConfig {
  headers: HttpHeaders | string | Record<string, string | number | (string | number)[]> | Headers;
  method: 'POST' | 'PUT' | 'PATCH';
  url: string;
  /** Form field name for the uploaded file. */
  fieldName: string;
  /**
   * Additional form fields added in the HTTP request.
   *
   * @example
   * ```json
   * { upload_user: 'Reiner Zufall', expiry_date: ' 21.12.2012' }
   * ```
   */
  additionalFields?: Record<string, string>;
  /** Specify the server response type */
  responseType?: 'arraybuffer' | 'blob' | 'json' | 'text';
  /** A function to modify the HTTP request sent on the consumer side. */
  handler?: (req: HttpRequest<unknown>) => Observable<HttpEvent<unknown>>;
  /**
   * When `true`, the file will be sent directly w/o the use of `FormData`.
   * You will also need to set the `Content-Type` header when sending binary. See: {@link headers}
   */
  sendBinary?: boolean;
}

interface ExtUploadFile extends UploadFile {
  httpErrorText?: string;
  subscription?: Subscription;
  successResponse?: HttpResponse<unknown>;
  fadeOut?: boolean;
}

@Component({
  selector: 'si-file-uploader',
  templateUrl: './si-file-uploader.component.html',
  styleUrl: './si-file-uploader.component.scss',
  standalone: true,
  imports: [
    NgClass,
    SiFileDropzoneComponent,
    SiIconComponent,
    SiInlineNotificationComponent,
    SiProgressbarComponent,
    SiTranslateModule
  ]
})
export class SiFileUploaderComponent implements OnChanges {
  /**
   * Text of the link to open the file select dialog (follows `uploadDropText`).
   *
   * @defaultValue
   * ```
   * $localize`:@@SI_FILE_UPLOADER.FILE_SELECT:click to upload`
   * ```
   */
  @Input() uploadTextFileSelect = $localize`:@@SI_FILE_UPLOADER.FILE_SELECT:click to upload`;
  /**
   * Text instructing a user to drop the files inside the dropzone.
   *
   * @defaultValue
   * ```
   * $localize`:@@SI_FILE_UPLOADER.DROP:Drop files here or`
   * ```
   */
  @Input() uploadDropText = $localize`:@@SI_FILE_UPLOADER.DROP:Drop files here or`;
  /**
   * Text to describe the maximum file size.
   *
   * @defaultValue
   * ```
   * $localize`:@@SI_FILE_UPLOADER.MAX_SIZE:Maximum upload size`
   * ```
   */
  @Input() maxFileSizeText = $localize`:@@SI_FILE_UPLOADER.MAX_SIZE:Maximum upload size`;
  /**
   * Error message shown when the maximum number of files are reached.
   *
   * @defaultValue
   * ```
   * $localize`:@@SI_FILE_UPLOADER.MAX_FILE_REACHED:Maximum number of files reached`
   * ```
   */
  @Input()
  maxFilesReachedText =
    $localize`:@@SI_FILE_UPLOADER.MAX_FILE_REACHED:Maximum number of files reached`;
  /**
   * Text for the accepted file types.
   *
   * @defaultValue
   * ```
   * $localize`:@@SI_FILE_UPLOADER.ACCEPTED_FILE_TYPES:Accepted file types`
   * ```
   */
  @Input() acceptText = $localize`:@@SI_FILE_UPLOADER.ACCEPTED_FILE_TYPES:Accepted file types`;
  /**
   * Text used inside the upload button.
   *
   * @defaultValue
   * ```
   * $localize`:@@SI_FILE_UPLOADER.UPLOAD:Upload`
   * ```
   */
  @Input() uploadButtonText = $localize`:@@SI_FILE_UPLOADER.UPLOAD:Upload`;
  /**
   * Text used inside the clear button.
   *
   * @defaultValue
   * ```
   * $localize`:@@SI_FILE_UPLOADER.CLEAR:Clear`
   * ```
   */
  @Input() clearButtonText = $localize`:@@SI_FILE_UPLOADER.CLEAR:Clear`;
  /**
   * Text shown during the file upload.
   *
   * @defaultValue
   * ```
   * $localize`:@@SI_FILE_UPLOADER.UPLOADING:Uploading`
   * ```
   */
  @Input() uploadingText = $localize`:@@SI_FILE_UPLOADER.UPLOADING:Uploading`;
  /**
   * Text shown to remove a file from the file list. Required for a11y.
   *
   * @defaultValue
   * ```
   * $localize`:@@SI_FILE_UPLOADER.REMOVE:Remove`
   * ```
   */
  @Input() removeButtonText = $localize`:@@SI_FILE_UPLOADER.REMOVE:Remove`;
  /**
   * Text of cancel button. Shown during upload. Required for a11y.
   *
   * @defaultValue
   * ```
   * $localize`:@@SI_FILE_UPLOADER.CANCEL:Cancel`
   * ```
   */
  @Input() cancelButtonText = $localize`:@@SI_FILE_UPLOADER.CANCEL:Cancel`;
  /**
   * Text shown if the upload was successful.
   *
   * @defaultValue
   * ```
   * $localize`:@@SI_FILE_UPLOADER.UPLOAD_COMPLETED:Upload completed`
   * ```
   */
  @Input() successTextTitle = $localize`:@@SI_FILE_UPLOADER.UPLOAD_COMPLETED:Upload completed`;
  /**
   * Text shown if the upload failed.
   *
   * @defaultValue
   * ```
   * $localize`:@@SI_FILE_UPLOADER.UPLOAD_FAILED:Upload failed`
   * ```
   */
  @Input() errorUploadFailed = $localize`:@@SI_FILE_UPLOADER.UPLOAD_FAILED:Upload failed`;
  /**
   * On failed upload, show the error received from the server.
   *
   * @defaultValue false
   */
  @Input({ transform: booleanAttribute }) showHttpError = false;
  /**
   * Text shown to indicate that an incorrect file type was added to file list.
   *
   * @defaultValue
   * ```
   * $localize`:@@SI_FILE_UPLOADER.ERROR_FILE_TYPE:Incorrect file type selected`
   * ```
   */
  @Input()
  errorTextFileType = $localize`:@@SI_FILE_UPLOADER.ERROR_FILE_TYPE:Incorrect file type selected`;
  /**
   * Message or translation key if file exceeds the maximum file size limit.
   *
   * @defaultValue
   * ```
   * $localize`:@@SI_FILE_UPLOADER.ERROR_FILE_SIZE_EXCEEDED:File exceeds allowed maximum size`
   * ```
   */
  @Input()
  errorTextFileMaxSize =
    $localize`:@@SI_FILE_UPLOADER.ERROR_FILE_SIZE_EXCEEDED:File exceeds allowed maximum size`;
  /**
   * Config for HTTP request to upload file.
   *
   * @defaultValue
   * ```
   * {
   *     headers: new HttpHeaders({ 'Accept': 'application/json' }),
   *     method: 'POST',
   *     url: '',
   *     fieldName: 'upload_file',
   *     responseType: 'json'
   *   }
   * ```
   */
  @Input() uploadConfig: FileUploadConfig = {
    headers: new HttpHeaders({ 'Accept': 'application/json' }),
    method: 'POST',
    url: '',
    fieldName: 'upload_file',
    responseType: 'json'
  };
  /**
   * Define which file types are suggested in file browser.
   * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#attr-accept
   */
  @Input() accept?: string;
  /**
   * Define maximal allowed file size in bytes.
   */
  @Input({ transform: numberAttribute }) maxFileSize?: number;
  /**
   * Define maximal allowed number of files.
   *
   * @defaultValue 10
   */
  @Input({ transform: numberAttribute }) maxFiles = 10;
  /**
   * Maximum number of concurrent uploads.
   *
   * @defaultValue 3
   */
  @Input({ transform: numberAttribute }) maxConcurrentUploads = 3;
  /**
   * Numbers of retries for failed uploads.
   *
   * @defaultValue 0
   */
  @Input({ transform: numberAttribute }) retries = 0;
  /**
   * Auto-upload mode - automatically start upload once files are added.
   *
   * @defaultValue false
   */
  @Input({ transform: booleanAttribute }) autoUpload = false;
  /**
   * Disable the upload button.
   *
   * @defaultValue false
   */
  @Input({ transform: booleanAttribute }) disableUpload = false;

  /**
   * Emits when a user press cancel during upload. The event provides the file details.
   */
  @Output() readonly uploadCanceled = new EventEmitter<UploadFile>();

  /**
   * Output callback event will provide you if upload is finished. If an error
   * occurred it will be emitted.
   */
  @Output() readonly uploadCompleted = new EventEmitter<FileUploadResult>(true);

  /**
   * Output which fires whenever new files are added to or removed from the uploader.
   */
  @Output() readonly filesChanges = new EventEmitter<UploadFile[]>(true);

  protected files: ExtUploadFile[] = [];
  protected pending = 0;
  protected uploading = 0;
  protected uploadEnabled = false;
  protected maxFilesReached = false;

  @ViewChild('dropZone')
  private dropZone?: SiFileDropzoneComponent;
  private cdRef = inject(ChangeDetectorRef);
  private http? = inject(HttpClient, { optional: true });

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.maxFiles || changes.disableUpload) {
      this.updateStates();
    }
  }

  protected handleFiles(files: UploadFile[]): void {
    if (!files?.length) {
      return;
    }

    // for single-file case, replace exiting file if any
    if (this.maxFiles === 1 && this.files.length) {
      this.reset(false);
    }

    let numValid = this.countValid();
    for (const file of files) {
      const duplicate = this.isDuplicate(file);
      if (duplicate) {
        // in case this is duplicated: reset if already uploaded or not handled yet
        if (duplicate.status !== 'uploading' && duplicate.status !== 'queued') {
          Object.assign(duplicate, file);
        }
        continue;
      }

      const canAdd = numValid + 1 <= this.maxFiles;
      const valid = file.status === 'added';
      if (valid && !canAdd) {
        this.maxFilesReached = true;
        break;
      } else if (valid) {
        numValid++;
      }
      this.files.push(file);
    }

    this.files.sort((a, b) => a.fileName.localeCompare(b.fileName));

    this.filesChanges.emit(this.files.slice());

    this.updateStates();

    if (this.autoUpload) {
      this.fileUpload(false);
    }
  }

  protected removeFile(index: number): void {
    if (index >= 0) {
      this.files.splice(index, 1);
      this.filesChanges.emit(this.files.slice());
      this.dropZone?.reset();
      this.updateStates();
    }
  }

  protected cancelUpload(file: ExtUploadFile): void {
    if (file.subscription) {
      file.subscription.unsubscribe();
      file.subscription = undefined;
      this.uploading--;
    }
    this.pending--;
    file.status = 'added';
    file.progress = 0;
    this.updateStates();

    const { status, fileName, size, progress } = file;
    this.uploadCanceled.emit({
      status,
      fileName,
      size,
      progress,
      file: file.file
    });
  }

  protected retryUpload(file: UploadFile): void {
    file.status = 'added';
    this.doUpload([file], true);
  }

  /**
   * Reset the state.
   */
  reset(emit = true): void {
    this.files.forEach(f => f.subscription?.unsubscribe());
    this.files = [];
    this.dropZone?.reset();
    this.updateStates();
    if (emit) {
      this.filesChanges.emit([]);
    }
  }

  /**
   * Uploads the file
   */
  fileUpload(doRetry = true): void {
    if (!this.uploadEnabled) {
      return;
    }
    this.uploadEnabled = false;
    this.doUpload(this.files, doRetry);
  }

  private doUpload(files: UploadFile[], doRetry: boolean): void {
    for (const file of files) {
      if (file.status !== 'added' && (!doRetry || file.status !== 'error')) {
        continue;
      }
      this.pending++;
      file.status = 'queued';
    }
    this.processQueue();
  }

  private processQueue(): void {
    for (let i = 0; i < this.files.length && this.uploading < this.maxConcurrentUploads; i++) {
      const file = this.files[i];
      if (file.status === 'queued') {
        this.uploading++;
        this.uploadOneFile(file);
      }
    }
  }

  private uploadOneFile(file: ExtUploadFile): void {
    let formData: FormData | undefined;
    if (!this.uploadConfig.sendBinary) {
      formData = new FormData();

      if (this.uploadConfig.additionalFields) {
        Object.keys(this.uploadConfig.additionalFields).forEach(key => {
          formData!.append(key, this.uploadConfig.additionalFields![key]);
        });
      }
      // this needs to be last for AWS
      formData.append(this.uploadConfig.fieldName, file.file, file.fileName);
    }
    const headers =
      this.uploadConfig.headers instanceof HttpHeaders
        ? this.uploadConfig.headers
        : new HttpHeaders(this.uploadConfig.headers);

    const req = new HttpRequest(
      this.uploadConfig.method,
      this.uploadConfig.url,
      formData ?? file.file,
      {
        headers,
        responseType: this.uploadConfig.responseType,
        reportProgress: true
      }
    );

    file.status = 'uploading';
    file.errorText = undefined;
    file.httpErrorText = undefined;

    const requestHandler =
      this.uploadConfig.handler ??
      (this.http ? (r: HttpRequest<unknown>) => this.http!.request(r) : undefined);
    if (!requestHandler) {
      return;
    }

    file.subscription = requestHandler(req)
      .pipe(retry(this.retries))
      .subscribe({
        next: event => this.handleUploadEvent(file, event),
        error: (error: HttpErrorResponse) => this.handleUploadError(file, error),
        complete: () => this.handleUploadComplete(file)
      });
  }

  // this is a light check for duplicate file - name and size only, not content!
  private isDuplicate(file: UploadFile): UploadFile | null {
    for (const uploadFile of this.files) {
      if (uploadFile.file.name === file.file.name && uploadFile.file.size === file.file.size) {
        return uploadFile;
      }
    }
    return null;
  }

  private handleUploadEvent(file: ExtUploadFile, httpEvent: HttpEvent<unknown>): void {
    if (httpEvent instanceof HttpResponse) {
      file.successResponse = httpEvent as HttpResponse<unknown>;
    } else if (httpEvent.type === HttpEventType.UploadProgress && httpEvent.total) {
      file.progress = Math.floor((100 * httpEvent.loaded) / httpEvent.total);
      this.cdRef.markForCheck();
    }
  }

  private handleUploadError(file: ExtUploadFile, error: HttpErrorResponse): void {
    this.uploadCompleted.emit({ file: file.fileName, error });
    file.status = 'error';
    file.errorText = this.errorUploadFailed;
    if (this.showHttpError && error.status && error.statusText) {
      file.httpErrorText = `${error.status}: ${error.statusText}`;
    }
    this.oneUploadDone(file);
  }

  private handleUploadComplete(file: ExtUploadFile): void {
    this.uploadCompleted.emit({ file: file.fileName, response: file.successResponse });
    file.status = 'success';
    file.progress = 100;
    file.successResponse = undefined;
    if (this.autoUpload) {
      this.fadeOut(file);
    }
    this.oneUploadDone(file);
  }

  private oneUploadDone(file: ExtUploadFile): void {
    file.subscription = undefined;
    this.pending--;
    this.uploading--;
    this.updateStates();
    this.processQueue();
  }

  private fadeOut(file: ExtUploadFile): void {
    setTimeout(() => {
      file.fadeOut = true;
      this.cdRef.markForCheck();
      setTimeout(() => {
        this.removeFile(this.files.indexOf(file));
        this.cdRef.markForCheck();
      }, 500);
      this.cdRef.markForCheck();
    }, 3500);
  }

  private updateStates(): void {
    this.uploadEnabled =
      !this.disableUpload &&
      !this.pending &&
      this.files.some(f => f.status === 'added' || f.status === 'error');
    if (this.maxFilesReached && this.countValid() < this.maxFiles) {
      this.maxFilesReached = false;
    }
  }

  private countValid(): number {
    return this.files.reduce<number>((acc, f) => acc + (f.status !== 'invalid' ? 1 : 0), 0);
  }
}
