import { NgClass } from '@angular/common';
import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { SiTranslateModule } from '@simpl/element-translate-ng/translate';

@Component({
  selector: 'si-formly-button',
  templateUrl: './si-formly-button.component.html',
  standalone: true,
  imports: [NgClass, SiTranslateModule]
})
export class SiFormlyButtonComponent extends FieldType {
  protected click(): void {
    const listener = this.props.clickListener;
    let args = this.props.clickArgs ?? [];
    if (args && !Array.isArray(args)) {
      args = [args];
    }
    if (!listener) {
      return;
    }

    if (typeof listener === 'string') {
      try {
        /* eslint-disable */
        const getFn = Function('formState', 'model', 'field', `return ${listener};`);
        const fn = getFn.apply(this, [this.formState, this.model, this.field]);
        if (typeof fn === 'function') {
          fn.apply(this, args);
        } else {
          console.warn(`The dyn ui button ${this.key} has no valid click listener`);
        }
      } catch (error) {
        console.warn(`Error while executing dyn ui button "${this.key}" click listener.`, error);
      }
      /* eslint-enable */
      return;
    }

    if (typeof listener === 'function') {
      try {
        listener.apply(this, args);
      } catch (error) {
        console.warn(
          `Error while executing dyn ui button "${this.key}" direct click listener.`,
          error
        );
      }
    }
  }
}
