import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  HostBinding,
  inject,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import { Subscription } from 'rxjs';

import { ResizeObserverService } from './resize-observer.service';

export interface Breakpoints {
  smMinimum: number;
  mdMinimum: number;
  lgMinimum: number;
  xlMinimum: number;
  xxlMinimum: number;
}

// keep in sync with the Bootstrap variables
export const BOOTSTRAP_BREAKPOINTS: Breakpoints = {
  smMinimum: 576,
  mdMinimum: 768,
  lgMinimum: 992,
  xlMinimum: 1200,
  xxlMinimum: 1400
};

/**
 * Directive to automatically set `si-container-*` classes so Bootstrap column classes work
 * in the context of the container instead of viewport size.
 */
@Directive({
  selector: '[siResponsiveContainer]',
  exportAs: 'siResponsiveContainer',
  standalone: true
})
export class SiResponsiveContainerDirective implements OnInit, OnDestroy {
  /** @defaultValue false */
  @HostBinding('class.si-container-xs') isXs = false;
  /** @defaultValue false */
  @HostBinding('class.si-container-sm') isSm = false;
  /** @defaultValue false */
  @HostBinding('class.si-container-md') isMd = false;
  /** @defaultValue false */
  @HostBinding('class.si-container-lg') isLg = false;
  /** @defaultValue false */
  @HostBinding('class.si-container-xl') isXl = false;
  /** @defaultValue false */
  @HostBinding('class.si-container-xxl') isXxl = false;

  /** @defaultValue 100 */
  @Input() resizeThrottle = 100;
  @Input() breakpoints?: Breakpoints;

  private subs?: Subscription;

  private element = inject(ElementRef);
  private service = inject(ResizeObserverService);
  private changeDetectorRef = inject(ChangeDetectorRef);

  ngOnInit(): void {
    this.subs = this.service
      .observe(this.element.nativeElement, this.resizeThrottle, true)
      .subscribe(event => this.setResponsiveSize(event.width, event.height));
  }

  ngOnDestroy(): void {
    this.subs?.unsubscribe();
  }

  private setResponsiveSize(width: number, height: number): void {
    if (!width && !height) {
      // element is not visible, no point in changing anything
      return;
    }
    const breakpoints = this.breakpoints ?? BOOTSTRAP_BREAKPOINTS;

    const oldXs = this.isXs;
    const oldSm = this.isSm;
    const oldMd = this.isMd;
    const oldLg = this.isLg;
    const oldXl = this.isXl;
    const oldXxl = this.isXxl;

    this.isXs = width < breakpoints.smMinimum;
    this.isSm = width >= breakpoints.smMinimum && width < breakpoints.mdMinimum;
    this.isMd = width >= breakpoints.mdMinimum && width < breakpoints.lgMinimum;
    this.isLg = width >= breakpoints.lgMinimum && width < breakpoints.xlMinimum;
    this.isXl = width >= breakpoints.xlMinimum && width < breakpoints.xxlMinimum;
    this.isXxl = width >= breakpoints.xxlMinimum;

    if (
      oldXs !== this.isXs ||
      oldSm !== this.isSm ||
      oldMd !== this.isMd ||
      oldLg !== this.isLg ||
      oldXl !== this.isXl ||
      oldXxl !== this.isXxl
    ) {
      this.changeDetectorRef.markForCheck();
    }
  }
}
