import { ConfigurableFocusTrap, ConfigurableFocusTrapFactory } from '@angular/cdk/a11y';
import {
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef
} from '@angular/core';

import {
  SI_SELECT_OPTIONS_STRATEGY,
  SiSelectOptionsStrategy
} from '../options/si-select-options-strategy';
import { SiSelectSelectionStrategy } from '../selection/si-select-selection-strategy';
import { SelectGroup, SelectOptionNext } from '../si-select.types';

@Directive({
  standalone: true,
  host: {
    class: 'dropdown-menu position-static w-100 py-4 d-flex flex-column'
  }
})
export abstract class SiSelectListBase<T> implements OnInit, OnDestroy {
  @Input({ required: true }) baseId!: string;
  @Input() optionTemplate?: TemplateRef<{ $implicit: SelectOptionNext<T> }>;
  @Input() groupTemplate?: TemplateRef<{ $implicit: SelectGroup<T> }>;
  /** @defaultValue null */
  @Input() labelledby: string | null = null;
  @Input() actionsTemplate?: TemplateRef<any>;

  @Output() readonly closeOverlay = new EventEmitter<void>();

  protected selectionStrategy = inject<SiSelectSelectionStrategy<T>>(SiSelectSelectionStrategy);
  protected selectOptions = inject<SiSelectOptionsStrategy<T>>(SI_SELECT_OPTIONS_STRATEGY);
  protected readonly focusTrapFactory = inject(ConfigurableFocusTrapFactory);
  protected readonly elementRef = inject(ElementRef);
  protected rows = this.selectOptions.rows;
  protected focusTrap!: ConfigurableFocusTrap;

  @HostBinding('class.si-multi-select')
  protected multiSelect = this.selectionStrategy.allowMultiple;

  ngOnInit(): void {
    this.focusTrap = this.focusTrapFactory.create(this.elementRef.nativeElement);
  }

  ngOnDestroy(): void {
    this.focusTrap.destroy();
  }

  @HostListener('keydown.tab')
  protected keydownTab(): void {
    // Ignore tab key if actions are displayed.
    if (!this.actionsTemplate) {
      this.closeOverlayAlways();
    }
  }

  protected closeOverlayAlways(): void {
    this.closeOverlay.emit();
  }

  protected closeOverlayIfSingle(): void {
    if (!this.selectionStrategy.allowMultiple) {
      this.closeOverlayAlways();
    }
  }
}
