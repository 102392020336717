import {
  CdkOverlayOrigin,
  ConnectedOverlayPositionChange,
  ConnectedPosition
} from '@angular/cdk/overlay';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  inject,
  Input,
  NgZone,
  OnInit,
  TemplateRef
} from '@angular/core';
import { calculateOverlayArrowPosition, OverlayArrowPosition } from '@simpl/element-ng/common';
import { SiTranslateModule, TranslatableString } from '@simpl/element-translate-ng/translate';

@Component({
  selector: 'si-tooltip',
  templateUrl: './si-tooltip.component.html',
  standalone: true,
  imports: [NgClass, NgTemplateOutlet, SiTranslateModule]
})
export class TooltipComponent implements OnInit {
  /** @defaultValue '' */
  @Input() tooltip: TranslatableString | TemplateRef<any> = '';
  // TODO: remove with v47
  @Input() anchor!: CdkOverlayOrigin;
  // TODO: remove with v47
  @Input() positions!: ConnectedPosition[];
  protected tooltipPositionClass = '';
  protected arrowPos?: OverlayArrowPosition;
  protected tooltipText: string | null = null;
  protected tooltipTemplate: TemplateRef<any> | null = null;
  /** @internal */
  id!: string;

  private zone = inject(NgZone);
  private elementRef = inject(ElementRef);
  private cdRef = inject(ChangeDetectorRef);

  ngOnInit(): void {
    if (this.tooltip instanceof TemplateRef) {
      this.tooltipTemplate = this.tooltip;
    }
    if (typeof this.tooltip === 'string') {
      this.tooltipText = this.tooltip;
    }
  }

  /** @internal */
  updateTooltipPosition(change: ConnectedOverlayPositionChange, anchor?: ElementRef): void {
    const arrowClassTooltip = `tooltip-${change.connectionPair.overlayX}-${change.connectionPair.overlayY}`;
    // need two updates as class changes affect the position
    if (arrowClassTooltip !== this.tooltipPositionClass) {
      this.zone.run(() => {
        this.tooltipPositionClass = arrowClassTooltip;
        this.cdRef.markForCheck();
      });
    }
    const arrowPos = calculateOverlayArrowPosition(change, this.elementRef, anchor);
    this.zone.run(() => {
      this.arrowPos = arrowPos;
      this.cdRef.markForCheck();
    });
  }
}
