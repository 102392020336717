<ul class="list-unstyled d-flex flex-column mb-0">
  @for (step of steps; track $index) {
    <li
      class="position-relative d-flex align-items-center timeline px-6 py-5"
      [class.text-primary]="step.state === 'running'"
    >
      @if (step.state === 'running') {
        <si-loading-spinner class="me-4" />
      }
      @if (step.icon && step.state !== 'running') {
        <si-icon class="me-4" [icon]="step.icon" />
      }
      @if (!step.icon && step.state !== 'running') {
        @switch (step.state) {
          @case ('passed') {
            <si-icon
              class="me-4"
              icon="element-circle-filled"
              color="status-success"
              stackedIcon="element-state-tick"
              stackedColor="status-critical-contrast"
            />
          }
          @case ('failed') {
            <si-icon
              class="me-4"
              icon="element-circle-filled"
              color="status-danger"
              stackedIcon="element-state-exclamation-mark"
              stackedColor="status-critical-contrast"
            />
          }
          @case ('not-started') {
            <si-icon class="me-4" icon="element-not-checked" />
          }
          @default {
            <si-icon class="me-4" icon="element-out-of-service" />
          }
        }
      }
      <div class="d-flex flex-column justify-content-center w-100 overflow-hidden">
        <div class="d-flex">
          <div class="result-description">
            <span class="si-title-2">{{
              step.description | translate: step.translationParams
            }}</span>
            @if (step.detail) {
              <div class="si-body-2 text-secondary result-detail">{{
                step.detail | translate: step.translationParams
              }}</div>
            }
            @if (step.errorMessage) {
              <div class="si-title-2 text-danger">{{
                step.errorMessage | translate: step.translationParams
              }}</div>
            }
          </div>
          @if (step.value) {
            <span class="result-value">{{ step.value }}</span>
          } @else if (stepHasValue) {
            <!-- empty placeholder to align step description with other steps-->
            <span class="result-value"></span>
          }
        </div>
      </div>
    </li>
  }
</ul>
