<div class="textarea-grow-wrap text-break">
  <textarea
    #textArea
    class="form-control overflow-hidden"
    [id]="id"
    [rows]="props.rows ?? 1"
    [cols]="props.cols ?? 1"
    [formControl]="formControl"
    [formlyAttributes]="field"
  >
  </textarea>
</div>
