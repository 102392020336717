<div class="si-toast-drawer" aria-live="polite">
  @for (toast of toasts | async; track toast) {
    <si-toast-notification
      class="position-relative"
      [toast]="toast"
      @toastTrigger
      [@.disabled]="animationsDisabled"
    />
  }
</div>
