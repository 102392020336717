import { NgClass } from '@angular/common';
import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  inject,
  Input,
  Output
} from '@angular/core';

import { SiApplicationHeaderComponent } from '../si-application-header.component';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'a[si-launchpad-app]',
  standalone: true,
  imports: [NgClass],
  templateUrl: './si-launchpad-app.component.html',
  styleUrl: './si-launchpad-app.component.scss',
  host: {
    class: 'focus-inside'
  },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SiLaunchpadAppComponent {
  /** @defaultValue false */
  @Input({ transform: booleanAttribute }) external = false;
  /** @defaultValue false */
  @Input({ transform: booleanAttribute }) @HostBinding('class.active') active = false;
  /** @defaultValue false */
  @Input({ transform: booleanAttribute }) enableFavoriteToggle = false;
  @Input({ transform: booleanAttribute }) favorite?: boolean;
  @Input({ transform: booleanAttribute }) @HostBinding('class.action') action?: boolean;
  @Input() iconUrl?: string;
  @Input() iconClass?: string;

  @Output() readonly favoriteChanged = new EventEmitter<boolean>();

  private header = inject(SiApplicationHeaderComponent);

  @HostListener('keydown.space', ['$event'])
  protected favoriteClicked(event: Event): void {
    event.stopPropagation();
    event.preventDefault();
    this.favoriteChanged.next(!this.favorite);
  }

  @HostListener('click') protected click(): void {
    this.header.closeLaunchpad();
  }
}
