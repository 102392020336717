import { booleanAttribute, Directive, HostBinding, Input } from '@angular/core';

@Directive({
  standalone: true,
  host: {
    class: 'dropdown-item d-flex focus-inside'
  }
})
export abstract class SiMenuItemBase {
  @Input() badge?: string | number;
  /**
   * @defaultValue 'secondary'
   */
  @Input() badgeColor = 'secondary';
  @Input() icon?: string;

  /** @defaultValue false */
  @Input() iconBadgeDot?: boolean | string | number = false;

  /** @defaultValue false */
  @HostBinding('class.disabled') @Input({ transform: booleanAttribute }) disabled = false;

  protected get badgeDotHasContent(): boolean {
    return typeof this.iconBadgeDot === 'string' || typeof this.iconBadgeDot === 'number';
  }
}
