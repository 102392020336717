import { CdkListbox, CdkOption } from '@angular/cdk/listbox';
import { DatePipe, NgTemplateOutlet } from '@angular/common';
import {
  booleanAttribute,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  Output,
  Pipe,
  PipeTransform,
  SimpleChanges
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SiSearchBarComponent } from '@simpl/element-ng/search-bar';
import { SiTranslateModule } from '@simpl/element-translate-ng/translate';

import { SiCalendarButtonComponent } from '../datepicker/si-calendar-button.component';
import { SiDatepickerComponent } from '../datepicker/si-datepicker.component';
import { SiDatepickerDirective } from '../datepicker/si-datepicker.directive';
import {
  DatepickerConfig,
  DatepickerInputConfig,
  DateRange
} from '../datepicker/si-datepicker.model';
import { SiDateRangeCalculationService } from './si-date-range-calculation.service';
import {
  DateRangeFilter,
  DateRangePreset,
  ONE_DAY,
  ResolvedDateRange
} from './si-date-range-filter.types';
import { SiRelativeDateComponent } from './si-relative-date.component';

@Pipe({ name: 'presetMatchFilter', pure: true, standalone: true })
export class PresetMatchFilterPipe implements PipeTransform {
  transform(value: string, term: string): boolean {
    return !term ? true : value.toLowerCase().includes(term.toLowerCase());
  }
}

@Component({
  selector: 'si-date-range-filter',
  templateUrl: './si-date-range-filter.component.html',
  styleUrl: './si-date-range-filter.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CdkOption,
    CdkListbox,
    DatePipe,
    FormsModule,
    NgTemplateOutlet,
    PresetMatchFilterPipe,
    SiCalendarButtonComponent,
    SiDatepickerComponent,
    SiDatepickerDirective,
    SiRelativeDateComponent,
    SiSearchBarComponent,
    SiTranslateModule
  ]
})
export class SiDateRangeFilterComponent implements OnChanges {
  private service = inject(SiDateRangeCalculationService);
  private cdRef = inject(ChangeDetectorRef);

  /** The filter range object */
  @Input() range!: DateRangeFilter;
  /** List of preset time ranges. When not present or empty, the preset section won't show */
  @Input() presetList?: DateRangePreset[];
  /**
   * Determines if there's a search field for the preset list
   *
   * @defaultValue true
   */
  @Input({ transform: booleanAttribute }) presetSearch = true;
  /**
   * Determines if time is selectable or only dates
   *
   * @defaultValue false
   */
  @Input({ transform: booleanAttribute }) enableTimeSelection = false;
  /**
   * Determines whether to show input fields or a date-range calendar in basic mode.
   * When time selection is enabled, this has no effect and input fields are always shown.
   *
   * @defaultValue 'calendar'
   */
  @Input() basicMode: 'input' | 'calendar' = 'calendar';
  /**
   * Reverses the order of the from/to fields
   *
   * @defaultValue false
   */
  @Input({ transform: booleanAttribute }) reverseInputFields = false;

  /**
   * Determines whether to show the 'Apply' button
   *
   * @defaultValue false
   */
  @Input({ transform: booleanAttribute }) showApplyButton = false;
  /**
   * Hides the advanced mode if input allows
   *
   * @defaultValue false
   */
  @Input({ transform: booleanAttribute }) hideAdvancedMode = false;

  /**
   * label for the "Reference point" title
   *
   * @defaultValue
   * ```
   * $localize`:@@SI_DATE_RANGE_FILTER.REF_POINT:Reference point`
   * ```
   */
  @Input() refLabel = $localize`:@@SI_DATE_RANGE_FILTER.REF_POINT:Reference point`;
  /**
   * label for the "Reference point" title
   *
   * @defaultValue
   * ```
   * $localize`:@@SI_DATE_RANGE_FILTER.FROM:From`
   * ```
   */
  @Input() fromLabel = $localize`:@@SI_DATE_RANGE_FILTER.FROM:From`;
  /**
   * label for the "Reference point" title
   *
   * @defaultValue
   * ```
   * $localize`:@@SI_DATE_RANGE_FILTER.TO:To`
   * ```
   */
  @Input() toLabel = $localize`:@@SI_DATE_RANGE_FILTER.TO:To`;
  /**
   * label for the "Range" title
   *
   * @defaultValue
   * ```
   * $localize`:@@SI_DATE_RANGE_FILTER.RANGE:Range`
   * ```
   */
  @Input() rangeLabel = $localize`:@@SI_DATE_RANGE_FILTER.RANGE:Range`;
  /**
   * label for the "Today" checkbox
   *
   * @defaultValue
   * ```
   * $localize`:@@SI_DATE_RANGE_FILTER.TODAY:Today`
   * ```
   */
  @Input() todayLabel = $localize`:@@SI_DATE_RANGE_FILTER.TODAY:Today`;
  /**
   * label for the "Now" checkbox
   *
   * @defaultValue
   * ```
   * $localize`:@@SI_DATE_RANGE_FILTER.NOW:Now`
   * ```
   */
  @Input() nowLabel = $localize`:@@SI_DATE_RANGE_FILTER.NOW:Now`;
  /**
   * label for "Date" field / radio button
   *
   * @defaultValue
   * ```
   * $localize`:@@SI_DATE_RANGE_FILTER.DATE:Date`
   * ```
   */
  @Input() dateLabel = $localize`:@@SI_DATE_RANGE_FILTER.DATE:Date`;
  /**
   * label for the "Preview" title
   *
   * @defaultValue
   * ```
   * $localize`:@@SI_DATE_RANGE_FILTER.PREVIEW:Preview`
   * ```
   */
  @Input() previewLabel = $localize`:@@SI_DATE_RANGE_FILTER.PREVIEW:Preview`;
  /**
   * Placeholder for date fields
   *
   * @defaultValue
   * ```
   * $localize`:@@SI_DATE_RANGE_FILTER.DATE_PLACEHOLDER:Select date`
   * ```
   */
  @Input() datePlaceholder = $localize`:@@SI_DATE_RANGE_FILTER.DATE_PLACEHOLDER:Select date`;
  /**
   * label for the "Before" toggle
   *
   * @defaultValue
   * ```
   * $localize`:@@SI_DATE_RANGE_FILTER.BEFORE:Before`
   * ```
   */
  @Input() beforeLabel = $localize`:@@SI_DATE_RANGE_FILTER.BEFORE:Before`;
  /**
   * label for the "After" toggle
   *
   * @defaultValue
   * ```
   * $localize`:@@SI_DATE_RANGE_FILTER.AFTER:After`
   * ```
   */
  @Input() afterLabel = $localize`:@@SI_DATE_RANGE_FILTER.AFTER:After`;
  /**
   * label for the "Within" toggle
   *
   * @defaultValue
   * ```
   * $localize`:@@SI_DATE_RANGE_FILTER.WITHIN:Within`
   * ```
   */
  @Input() withinLabel = $localize`:@@SI_DATE_RANGE_FILTER.WITHIN:Within`;
  /**
   * label for the "value" number input
   *
   * @defaultValue
   * ```
   * $localize`:@@SI_DATE_RANGE_FILTER.VALUE:Value`
   * ```
   */
  @Input() valueLabel = $localize`:@@SI_DATE_RANGE_FILTER.VALUE:Value`;
  /**
   * label for the "Unit" select
   *
   * @defaultValue
   * ```
   * $localize`:@@SI_DATE_RANGE_FILTER.UNIT:Unit`
   * ```
   */
  @Input() unitLabel = $localize`:@@SI_DATE_RANGE_FILTER.UNIT:Unit`;
  /**
   * label for the "search" input
   *
   * @defaultValue
   * ```
   * $localize`:@@SI_DATE_RANGE_FILTER.SEARCH:Search`
   * ```
   */
  @Input() searchLabel = $localize`:@@SI_DATE_RANGE_FILTER.SEARCH:Search`;
  /**
   * label for the "search" input
   *
   * @defaultValue
   * ```
   * $localize`:@@SI_DATE_RANGE_FILTER.PRESETS:Presets`
   * ```
   */
  @Input() presetLabel = $localize`:@@SI_DATE_RANGE_FILTER.PRESETS:Presets`;
  /**
   * label for the "advanced" switch
   *
   * @defaultValue
   * ```
   * $localize`:@@SI_DATE_RANGE_FILTER.ADVANCED:Advanced`
   * ```
   */
  @Input() advancedLabel = $localize`:@@SI_DATE_RANGE_FILTER.ADVANCED:Advanced`;
  /**
   * label for the "apply" switch
   *
   * @defaultValue
   * ```
   * $localize`:@@SI_DATE_RANGE_FILTER.APPLY:Apply`
   * ```
   */
  @Input() applyLabel = $localize`:@@SI_DATE_RANGE_FILTER.APPLY:Apply`;

  /** @deprecated this has no effect and will be removed */
  @Input() durationLabel?: string;

  /** Event fired when the selected range changes */
  @Output() readonly rangeChange = new EventEmitter<DateRangeFilter>();
  /** Event fired when the apply button has been clicked */
  @Output() readonly applyClicked = new EventEmitter<void>();

  /** Base configuration on how the dates should be displayed, parts of it may be overwritten internally. */
  @Input() datepickerConfig?: DatepickerInputConfig;

  protected advancedMode = false;
  protected dateRange: DateRange = { start: undefined, end: undefined };

  protected point1Now = true;
  protected point2Mode: 'duration' | 'date' = 'duration';

  protected point1date = this.getDateNow();
  protected point2date = this.getDateNow();
  protected point2offset = 0;
  protected point2range: 'before' | 'after' | 'within' = 'before';
  protected calculatedRange: ResolvedDateRange = {
    start: this.getDateNow(),
    end: this.getDateNow(),
    valid: true
  };
  protected pipeFormat = 'shortDate';
  protected datepickerConfigInternal: DatepickerConfig = {};
  protected dateRangeConfig: DatepickerConfig = { enableDateRange: true };

  protected filteredPresetList: DateRangePreset[] = [];
  protected presetFilter = '';

  protected get inputMode(): boolean {
    return this.basicMode === 'input' || this.enableTimeSelection;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.enableTimeSelection ||
      changes.datepickerConfig ||
      (changes.range && this.rangeChanged(changes.range.previousValue, changes.range.currentValue))
    ) {
      this.updateFromRange();
    }
    if (changes.presetList || changes.enableTimeSelection) {
      this.filterPresetList();
    }
    if (
      (changes.basicMode || changes.enableTimeSelection) &&
      this.inputMode &&
      !this.advancedMode
    ) {
      this.point2Mode = 'date';
      this.point2Changed();
    }
    if (changes.datepickerConfig) {
      this.dateRangeConfig = { ...(this.datepickerConfig ?? {}), enableDateRange: true };
    }
  }

  private getDateNow(): Date {
    const now = new Date();
    if (!this.enableTimeSelection) {
      this.service.removeTime(now);
    }
    return now;
  }

  private rangeChanged(oldRange: DateRangeFilter | undefined, newRange: DateRangeFilter): boolean {
    return (
      !oldRange ||
      oldRange.point1 !== newRange.point1 ||
      oldRange.point2 !== newRange.point2 ||
      oldRange.range !== newRange.range
    );
  }

  private updateFromRange(): void {
    this.point1Now = this.range.point1 === 'now';
    this.point1date = this.range.point1 === 'now' ? this.getDateNow() : this.range.point1;
    this.point2Mode = this.range.point2 instanceof Date ? 'date' : 'duration';
    this.point2date = this.range.point2 instanceof Date ? this.range.point2 : this.getDateNow();
    this.point2range = this.range.range ?? 'before';
    this.point2offset =
      this.range.point2 instanceof Date
        ? Math.round(this.point1date.getTime() - this.range.point2.getTime())
        : this.range.point2;
    this.pipeFormat = this.enableTimeSelection
      ? (this.datepickerConfig?.dateTimeFormat ?? 'short')
      : (this.datepickerConfig?.dateFormat ?? 'shortDate');
    this.datepickerConfigInternal = this.datepickerConfig = {
      ...(this.datepickerConfig ?? {}),
      showTime: this.enableTimeSelection,
      mandatoryTime: this.enableTimeSelection
    };
    if ((this.point1Now && this.basicMode !== 'input') || this.point2Mode !== 'date') {
      this.advancedMode = true;
    } else {
      this.advancedMode = false;
      this.updateDateRange();
    }
    this.updateCalculatedRange();
  }

  private resolve(range: DateRangeFilter, skipNormalization?: boolean): ResolvedDateRange {
    return this.service.resolveDateRangeFilter(range, {
      withTime: this.enableTimeSelection,
      skipNormalization
    });
  }

  protected updateDateRange(range = this.range): void {
    const calculatedRange = this.resolve(range);
    this.dateRange.start = calculatedRange.start;
    this.dateRange.end = calculatedRange.end;
  }

  protected updateOnModeChange(): void {
    if (this.advancedMode) {
      const calculatedRange = this.resolve(this.range);
      this.point2Mode = 'duration';
      this.point2range = 'after';
      this.point2offset = Math.abs(calculatedRange.end.getTime() - calculatedRange.start.getTime());
    } else {
      this.updateSimpleMode(this.range);
    }
  }

  private updateSimpleMode(newRange: DateRangeFilter): void {
    if (this.inputMode) {
      this.range.point1 = newRange.point1;
      // input mode supports `now`, so point1 needs to remain unchanged
      if (newRange.point1 === 'now') {
        this.point1Now = true;
        this.point1date = this.getDateNow();
      } else {
        this.point1Now = false;
        this.point1date = newRange.point1;
      }
      const calculatedRange = this.resolve(newRange, true);
      this.point2Mode = 'date';
      this.point2date = calculatedRange.end;
      this.range.range = undefined;
      this.range.point2 = calculatedRange.end;
      this.updateCalculatedRange();
      this.emitChange();
    } else {
      this.point1Now = false;
      this.updateDateRange(newRange);
      this.updateFromDateRange();
    }
  }

  protected updateFromDateRange(): void {
    this.point1date = this.range.point1 = this.dateRange.start ?? this.getDateNow();
    this.point2date = this.range.point2 = this.dateRange.end ?? this.getDateNow();
    this.range.range = undefined;
    this.point2Mode = 'date';
    this.point2offset = 0;
    this.updateCalculatedRange();
    this.emitChange();
  }

  protected point1Changed(): void {
    if (this.point1Now) {
      this.range.point1 = 'now';
      this.point1date = this.getDateNow();
      if (this.point2Mode !== 'date') {
        this.point2range ??= 'before';
      }
    } else {
      this.range.point1 = this.point1date ?? new Date(NaN);
    }
    this.updateCalculatedRange();
    this.emitChange();
  }

  protected point2Changed(): void {
    if (this.point2Mode === 'date') {
      if (!(this.range.point2 instanceof Date)) {
        const calculatedRange = this.resolve(this.range);
        this.point2date =
          this.point1date < calculatedRange.end ? calculatedRange.start : calculatedRange.end;
      }
      this.range.point2 = this.point2date;
      this.range.range = undefined;
    } else {
      this.range.range = this.point2range;
      if (this.range.point2 instanceof Date) {
        const calculatedRange = this.resolve(this.range);
        this.point2offset = Math.round(
          calculatedRange.end.getTime() - calculatedRange.start.getTime()
        );
      }
      this.range.point2 = this.point2offset;
    }
    this.updateCalculatedRange();
    this.emitChange();
  }

  protected selectPresetItem(item: DateRangePreset): void {
    const newRange: DateRangeFilter =
      item.type === 'custom'
        ? item.calculate(item, this.range)
        : { point1: 'now', range: 'before', point2: item.offset };
    if (this.advancedMode) {
      Object.assign(this.range, newRange);
      this.updateFromRange();
      this.emitChange();
    } else {
      this.updateSimpleMode(newRange);
    }
  }

  private updateCalculatedRange(): void {
    this.calculatedRange = this.resolve(this.range);
    this.cdRef.markForCheck();
  }

  protected filterPresetList(): void {
    const timeFilter = (item: DateRangePreset): boolean => {
      const timeOnly = item.type === 'custom' ? item.timeOnly : item.offset < ONE_DAY;
      return this.enableTimeSelection || !timeOnly;
    };

    this.filteredPresetList = (this.presetList ?? []).filter(timeFilter);
  }

  private emitChange(): void {
    this.rangeChange.emit({ ...this.range });
  }
}
