<si-property-popover
  #popover
  [property]="property"
  [modalTemplate]="modalTemplate"
  [skipClone]="true"
  [valueState]="globalState()"
  [forceReadonly]="forceReadonly"
  [displayOnly]="customToggle"
  (stateChange)="stateChange($event)"
>
  <div
    class="form-control d-flex align-items-center overflow-hidden"
    tabindex="0"
    [class.custom-toggle]="customToggle"
    (keydown.enter)="!customToggle ? popover?.open(true) : undefined"
    input
  >
    <span>
      @if (property.defaultText) {
        {{ property.defaultText }}
      } @else {
        @for (item of property.value.value; track $index) {
          <span
            class="priority-item"
            [class.is-alt-text]="item.value.value === undefined && item.value.altText"
            [class.is-set]="isPrioSet(item)"
            [attr.aria-disabled]="!isPrioSet(item)"
          >
            <span>{{ item.position }}</span>
          </span>
        }
      }
    </span>
  </div>
</si-property-popover>

<ng-template #modalTemplate>
  <div class="modal-header">
    <h4 class="modal-title" [id]="popover?.modalTitleId">{{ property.name }}</h4>
    <button
      type="button"
      class="close btn-close"
      (click)="popover?.close()"
      [attr.aria-label]="dialogTextClose | translate"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body p-0" tabindex="0">
    <si-property-container class="inline-table" [overrideMode]="property.overrideMode">
      @for (item of property.value.value; track trackByItem($index, item)) {
        <div class="p-5 table-row d-flex align-items-center">
          <span class="text-secondary badge-table">{{ item.position }}</span>
          <div class="w-100">
            <si-property
              [property]="itemValue(item)"
              [valueState]="itemState(item)"
              [dateFormat]="dateFormat"
              [timeFormat]="timeFormat"
              [forceReadonly]="readonly"
              (propertySubmit)="itemSubmit(item)"
            />
          </div>
        </div>
      }
    </si-property-container>
  </div>
  <div class="modal-footer text-right text-end p-5"></div>
</ng-template>
