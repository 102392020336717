import { NgClass, NgTemplateOutlet } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, inject, Input, OnChanges, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { SiCollapsiblePanelComponent } from '@simpl/element-ng/accordion';
import { CopyrightDetails, SiCopyrightNoticeComponent } from '@simpl/element-ng/copyright-notice';
import { Link, SiLinkDirective } from '@simpl/element-ng/link';
import { SiTranslateModule } from '@simpl/element-translate-ng/translate';

import { ApiInfo, LicenseInfo } from './si-about-data.model';

@Component({
  selector: 'si-about',
  templateUrl: './si-about.component.html',
  styleUrl: './si-about.component.scss',
  standalone: true,
  imports: [
    NgClass,
    NgTemplateOutlet,
    SiCollapsiblePanelComponent,
    SiCopyrightNoticeComponent,
    SiLinkDirective,
    SiTranslateModule
  ]
})
export class SiAboutComponent implements OnChanges, OnInit {
  private http = inject(HttpClient);
  private sanitizer = inject(DomSanitizer);
  private cdRef = inject(ChangeDetectorRef);

  /**
   * Title shown above the about information.
   */
  @Input({ required: true })
  aboutTitle!: string;
  /**
   * Define mode to display the licenses. See {@link LicenseInfo}
   */
  @Input({ required: true })
  licenseInfo!: LicenseInfo;
  /**
   * Path to the application logo. Use for image
   */
  @Input() icon?: string;
  /**
   * Name of element icon. Use as alternative to image
   */
  @Input() iconName?: string;
  /**
   * Title of this application.
   */
  @Input({ required: true })
  appName!: string;
  /**
   * Sub titles of the application. Shown as a list below the application name. (Optional)
   */
  @Input() subheading?: string[];
  /**
   * Link to `Acceptable Use Policy`.
   */
  @Input() acceptableUsePolicyLink?: Link;
  /**
   * Link to `Corporate Information`.
   */
  @Input() imprintLink?: Link;
  /**
   * Link to `Privacy Notice`.
   */
  @Input() privacyLink?: Link;
  /**
   * Link to `Cookie Notice`.
   */
  @Input() cookieNoticeLink?: Link;
  /**
   * Link to `Terms of Use`.
   */
  @Input() termsLink?: Link;
  /**
   * Additional links listed in the about section.
   *
   * @defaultValue []
   */
  @Input() links: Link[] = [];

  protected sanitizedUrl?: SafeResourceUrl;
  protected licenseApi: ApiInfo[] = [];
  /**
   * Copyright information to be displayed. Alternatively, you can use the {@link SI_COPYRIGHT_DETAILS} global inject.
   */
  @Input() copyrightDetails?: CopyrightDetails;

  ngOnChanges(): void {
    if (this.licenseInfo.iframe != null) {
      this.sanitizedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.licenseInfo.iframe);
    }
  }

  ngOnInit(): void {
    if (this.licenseInfo.api) {
      this.http.get<ApiInfo[]>(this.licenseInfo.api, { responseType: 'json' }).subscribe(data => {
        this.licenseApi = data;

        if (this.licenseApi.length === 1) {
          const apiInfo = this.licenseApi[0];
          apiInfo.isOpen = false;
          this.toggleLoadLicenseApi(apiInfo);
        }
        this.cdRef.markForCheck();
      });
    }
  }

  protected toggleLoadLicenseApi(apiInfo: ApiInfo): void {
    if (!apiInfo.isOpen && !apiInfo.files) {
      this.http.get<ApiInfo[]>(apiInfo.href, { responseType: 'json' }).subscribe(files => {
        apiInfo.files = files;
        this.cdRef.markForCheck();
      });
    }
    apiInfo.isOpen = !apiInfo.isOpen;
    this.cdRef.markForCheck();
  }

  protected toggleLoadLicenseContent(apiInfo: ApiInfo): void {
    apiInfo.isOpen = !apiInfo.isOpen;
    if (!apiInfo.content) {
      this.http.get(apiInfo.href, { responseType: 'text' }).subscribe((content: string) => {
        apiInfo.content = content;
        this.cdRef.markForCheck();
      });
    }
    this.cdRef.markForCheck();
  }
}
