import { Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FieldType, FieldTypeConfig, FormlyModule } from '@ngx-formly/core';
import { SiDateRangeComponent } from '@simpl/element-ng';

@Component({
  selector: 'si-formly-date-range',
  standalone: true,
  imports: [ReactiveFormsModule, FormlyModule, SiDateRangeComponent],
  templateUrl: './si-formly-date-range.component.html'
})
export class SiFormlyDateRangeComponent extends FieldType<FieldTypeConfig> {}
