import { MeridianDesignation } from '@simpl/buildings-ng/common';

import { Bacnet, DateParts, TimeParts, WildcardBACnet } from './enums';
import { DateValidator, KeyValuePair } from './interfaces';
import { Options } from './options';
import { SpecialChars } from './special-chars';
import { TValue } from './types';

export class DateTime implements DateValidator {
  amPm?: MeridianDesignation;
  day?: number;
  hours?: number;
  hundredths?: number;
  minutes?: number;
  month?: number;
  seconds?: number;
  year?: number;

  private readonly wildcardDay = [
    { key: WildcardBACnet.allDays, value: SpecialChars.anyDay },
    { key: WildcardBACnet.lastDayOfMonth, value: SpecialChars.lastDay },
    { key: WildcardBACnet.evenDays, value: SpecialChars.evenDay },
    { key: WildcardBACnet.oddDays, value: SpecialChars.oddDay }
  ];
  private readonly wildcardHour = [{ key: WildcardBACnet.allHours, value: SpecialChars.all }];
  private readonly wildcardHundredths = [
    { key: WildcardBACnet.allHundredths, value: SpecialChars.all }
  ];
  private readonly wildcardMinute = [{ key: WildcardBACnet.allMinutes, value: SpecialChars.all }];
  private readonly wildcardMonth = [
    { key: WildcardBACnet.allMonths, value: SpecialChars.anyMonth },
    { key: WildcardBACnet.evenMonths, value: SpecialChars.evenMonth },
    { key: WildcardBACnet.oddMonths, value: SpecialChars.oddMonth }
  ];
  private readonly wildcardSecond = [{ key: WildcardBACnet.allSeconds, value: SpecialChars.all }];
  private readonly wildcardYear = [{ key: WildcardBACnet.allYears, value: SpecialChars.anyYear }];

  private constructor(
    private _options: Options,
    value: TValue
  ) {
    const type = value ? value.type : undefined;
    switch (type) {
      case 'date':
        this.parseDate(value.value);
        break;

      case 'time':
        this.parseTime(value.value);
        break;

      case 'date-time':
      default:
        {
          const dateTime = value?.value?.split('T');
          const dateValue = dateTime && dateTime.length === 2 ? dateTime[0] : undefined;
          const timeValue = dateTime && dateTime.length === 2 ? dateTime[1] : undefined;
          this.parseDate(dateValue);
          this.parseTime(timeValue);
        }
        break;
    }
  }

  get allOptional(): boolean {
    return (
      this._options.isOptional &&
      !this._options.hasOption(Options.special | Options.wildcard) &&
      (this.day === undefined || this.day === WildcardBACnet.allDays) &&
      (this.month === undefined || this.month === WildcardBACnet.allMonths) &&
      (this.year === undefined || this.year === WildcardBACnet.allYears) &&
      (this.hours === undefined || this.hours === WildcardBACnet.allHours) &&
      (this.minutes === undefined || this.minutes === WildcardBACnet.allMinutes) &&
      (this.seconds === undefined || this.seconds === WildcardBACnet.allSeconds) &&
      (this.hundredths === undefined || this.hundredths === WildcardBACnet.allHundredths)
    );
  }

  static parse(value: TValue, options: Options): DateTime {
    return new DateTime(options, value);
  }

  setDay(value?: string): void {
    this.day = this.toNum(value, this.filterWildcards(this.wildcardDay));
  }

  setHours(value?: string, amPm?: MeridianDesignation): void {
    this.amPm = amPm ?? MeridianDesignation.AM;
    this.hours = this.toNum(value, this.filterWildcards(this.wildcardHour));
    if (amPm != null && this.hours) {
      if (this.hours === 12) {
        this.hours = this.amPm === MeridianDesignation.PM ? 12 : 0;
      } else if (this.amPm === MeridianDesignation.PM && this.hours > WildcardBACnet.allHours) {
        this.hours += 12;
      }
    }
  }

  setHundredths(value?: string): void {
    this.hundredths = this.toNum(value, this.filterWildcards(this.wildcardHundredths));
  }

  setMinutes(value?: string): void {
    this.minutes = this.toNum(value, this.filterWildcards(this.wildcardMinute));
  }

  setMonth(value?: string): void {
    this.month = this.toNum(value, this.filterWildcards(this.wildcardMonth));
  }

  setSeconds(value?: string): void {
    this.seconds = this.toNum(value, this.filterWildcards(this.wildcardSecond));
  }

  setYear(value?: string): void {
    this.year = this.toNum(value, this.filterWildcards(this.wildcardYear));
  }

  toDateStr(): string {
    return !this.allOptional
      ? `${this.toYearStr(true)}-${this.toMonthStr(true, 2)}-${this.toDayStr(true, 2)}`
      : '';
  }

  toDayStr(checkWildcard: boolean, padding?: number): string {
    const wildcards: KeyValuePair[] = checkWildcard ? this.filterWildcards(this.wildcardDay) : [];
    return this.toStr(this.day, wildcards, padding);
  }

  toHours12Str(padding?: number): string {
    let hours = this.hours;
    if (hours != null && hours >= 0) {
      this.amPm = hours >= 12 ? MeridianDesignation.PM : MeridianDesignation.AM;
      if (hours > 12) {
        hours = hours - 12;
      } else if (hours === 0) {
        hours = 12;
      }
    } else {
      this.amPm = MeridianDesignation.AM;
    }
    return this.toStr(hours, this.filterWildcards(this.wildcardHour), padding);
  }

  toHoursStr(padding?: number): string {
    return this.toStr(this.hours, this.filterWildcards(this.wildcardHour), padding);
  }

  toHundredthsStr(padding?: number): string {
    return this.toStr(this.hundredths, this.filterWildcards(this.wildcardHundredths), padding);
  }

  toMinutesStr(padding?: number): string {
    return this.toStr(this.minutes, this.filterWildcards(this.wildcardMinute), padding);
  }

  toMonthStr(checkWildcard: boolean, padding?: number): string {
    const wildcards: KeyValuePair[] = checkWildcard ? this.filterWildcards(this.wildcardMonth) : [];
    return this.toStr(this.month, wildcards, padding);
  }

  toTimeStr(): string {
    return !this.allOptional && this.hundredths != null
      ? `${this.toHoursStr(2)}:${this.toMinutesStr(2)}:${this.toSecondsStr(
          2
        )}.${this.toHundredthsStr(2)}`
      : !this.allOptional && this.seconds != null
        ? `${this.toHoursStr(2)}:${this.toMinutesStr(2)}:${this.toSecondsStr(2)}`
        : !this.allOptional && this.minutes != null
          ? `${this.toHoursStr(2)}:${this.toMinutesStr(2)}`
          : '';
  }

  toSecondsStr(padding?: number): string {
    return this.toStr(this.seconds, this.filterWildcards(this.wildcardSecond), padding);
  }

  toYearStr(checkWildcard: boolean, padding?: number): string {
    const wildcards: KeyValuePair[] = checkWildcard ? this.filterWildcards(this.wildcardYear) : [];
    return this.toStr(this.year, wildcards, padding);
  }

  toString(): string {
    return this.hasDate() && this.hasTime() && !this.allOptional
      ? `${this.toDateStr()}T${this.toTimeStr()}`
      : this.hasDate()
        ? this.toDateStr()
        : this.toTimeStr();
  }

  private filterWildcards(wildcards: KeyValuePair[]): KeyValuePair[] {
    return this._options.isSpecial || this._options.isWildcarded
      ? wildcards
      : this._options.isOptional
        ? [{ key: WildcardBACnet.all, value: SpecialChars.optional }]
        : [];
  }

  private hasDate(): boolean {
    return this.day != null && this.month != null && this.year != null;
  }

  private hasTime(): boolean {
    return this.hours != null && this.minutes != null;
  }

  private parseDate(value?: string): void {
    const parser = new RegExp(
      //     yyyy      -        mm       -        dd
      /^(\d{4}|[*{1}])-(\d{1,2}|[*{1}])-(\d{1,2}|[*{1}])/
    );
    const parts = value ? value.match(parser) : null;

    this.year =
      parts && parts.length >= 2
        ? this.toNum(parts[DateParts.yy], this.filterWildcards(this.wildcardYear))
        : undefined;
    this.month =
      parts && parts.length >= 3
        ? this.toNum(parts[DateParts.mm], this.filterWildcards(this.wildcardMonth))
        : undefined;
    this.day =
      parts && parts.length >= 4
        ? this.toNum(parts[DateParts.dd], this.filterWildcards(this.wildcardDay))
        : undefined;
  }

  private parseTime(value?: string): void {
    const parser = new RegExp(
      //         hh           :       mm       [  :       ss        [   .       hs        ] ]
      /^([01]\d|2[0-3]|[*{1}]):([0-5]\d|[*{1}])(?::([0-5]\d|[*{1}])?(?:\.([0-9]\d|[*{1}]))?)?$/
    );
    const parts = value ? value.match(parser) : null;

    this.hours =
      parts && parts.length >= 2
        ? this.toNum(parts[TimeParts.hh], this.filterWildcards(this.wildcardHour))
        : undefined;
    this.minutes =
      parts && parts.length >= 3
        ? this.toNum(parts[TimeParts.mm], this.filterWildcards(this.wildcardMinute))
        : undefined;
    this.seconds =
      parts && parts.length >= 4
        ? this.toNum(parts[TimeParts.ss], this.filterWildcards(this.wildcardSecond))
        : undefined;
    this.hundredths =
      parts && parts.length >= 5
        ? this.toNum(parts[TimeParts.hs], this.filterWildcards(this.wildcardHundredths))
        : undefined;
  }

  private toNum(value: string | undefined, wildcards: KeyValuePair[]): number | undefined {
    const wildcard = wildcards.filter(p => p.value === value).map(p => p.key);
    const num = wildcard.length === 1 ? wildcard[0] : value != null ? parseInt(value, 10) : null;

    return num != null && !isNaN(num) ? num : undefined;
  }

  private toStr(value: number | undefined, wildcards: KeyValuePair[], padding?: number): string {
    if (value == null) {
      return SpecialChars.optional;
    }
    const wildcard = wildcards.filter(p => p.key === value).map(p => p.value);

    return wildcard.length === 1
      ? wildcard[0].toString()
      : padding
        ? value.toString().padStart(padding, '0')
        : value.toString();
  }
}

export const getDayOptions = (
  recurringDayLabel: string,
  lastDayLabel: string,
  evenDaysLabel: string,
  oddDaysLabel: string
): { id: number; name: string }[] => {
  const days: { id: number; name: string }[] = [];
  days[0] = {
    id: 0,
    name: recurringDayLabel
  };
  for (let i = 1; i <= Bacnet.DAYS - 3; i++) {
    days[i] = { id: i, name: i.toString() };
  }
  days[32] = {
    id: WildcardBACnet.lastDayOfMonth,
    name: lastDayLabel
  };
  days[33] = {
    id: WildcardBACnet.evenDays,
    name: evenDaysLabel
  };
  days[34] = {
    id: WildcardBACnet.oddDays,
    name: oddDaysLabel
  };
  return days;
};

export const getMonthOptions = (
  recurringMonthLabel: string,
  oddMonthsLabel: string,
  evenMonthsLabel: string
): { id: number; name: string }[] => {
  const months: { id: number; name: string }[] = [];
  months[0] = {
    id: 0,
    name: recurringMonthLabel
  };
  for (let i = 1; i <= Bacnet.MONTHS - 2; i++) {
    months[i] = { id: i, name: i.toString() };
  }
  months[13] = {
    id: WildcardBACnet.oddMonths,
    name: oddMonthsLabel
  };
  months[14] = {
    id: WildcardBACnet.evenMonths,
    name: evenMonthsLabel
  };
  return months;
};

export const getYearOptions = (recurringYearLabel: string): { id: number; name: string }[] => {
  const years: { id: number; name: string }[] = [];
  years[0] = {
    id: 0,
    name: recurringYearLabel
  };
  for (let i = 0; i < Bacnet.YEARS; i++) {
    years[i + 1] = {
      id: Bacnet.START_YEAR + i,
      name: String(Bacnet.START_YEAR + i)
    };
  }
  return years;
};
