<ng-container
  [ngTemplateOutlet]="optionTemplate ?? defaultOptionTemplate"
  [ngTemplateOutletContext]="{ $implicit: option }"
/>

<ng-template #defaultOptionTemplate let-item siSelectOptionTemplate>
  @if (item.icon) {
    <i
      class="icon me-2"
      aria-hidden="true"
      [ngClass]="[item.icon, (!item.disabled && item.iconColor) || '']"
    ></i>
  }
  @if (item.label) {
    <span class="text-truncate">{{ item.label | translate }}</span>
  }
</ng-template>
