<div
  class="status-indication d-flex align-items-center justify-content-center"
  role="status"
  [class.has-icon]="icon"
  [class.small]="size === 'small'"
  [attr.aria-label]="theAriaLabel | translate"
>
  <div
    #bg
    class="bg"
    [class.pulse]="blinkOn"
    [class.deprecated-color]="!status"
    [class.contrast-fix]="contrastFix"
    [ngClass]="backgroundClass"
    [style.background-color]="color"
  ></div>
  @if (!status) {
    <div class="deprecated-dot" [style.background-color]="color"></div>
  }
  @if (statusIcon) {
    <si-icon
      size="status-icon"
      class="indicator"
      alt=""
      [icon]="statusIcon.icon"
      [color]="statusIcon.color"
      [stackedIcon]="statusIcon.stacked"
      [stackedColor]="statusIcon.stackedColor"
    />
  }
  @if (eventOut) {
    <i class="icon text-body event-out element-right-4 flip-rtl" aria-hidden="true"></i>
  }
  @if (icon) {
    <i class="icon position-relative text-body" aria-hidden="true" [ngClass]="icon"></i>
  }
</div>
