import { CdkMenuItemRadio, CdkMenuTrigger } from '@angular/cdk/menu';
import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';

import { SiMenuItemBase } from './si-menu-item-base.directive';

@Component({
  selector: 'si-menu-item-radio',
  templateUrl: './si-menu-item-radio.component.html',
  styleUrl: './si-menu-item.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass],
  hostDirectives: [
    {
      directive: CdkMenuItemRadio,
      inputs: ['cdkMenuItemChecked: checked', 'cdkMenuItemDisabled: disabled'],
      outputs: ['cdkMenuItemTriggered: triggered']
    },
    CdkMenuTrigger
  ]
})
export class SiMenuItemRadioComponent extends SiMenuItemBase {
  private cdkMenuItemRadio = inject(CdkMenuItemRadio);

  protected get checked(): boolean {
    return this.cdkMenuItemRadio.checked;
  }
}
