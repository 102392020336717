import { AsyncPipe, NgClass } from '@angular/common';
import { booleanAttribute, ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { SiLoadingButtonComponent } from '@simpl/element-ng/loading-spinner';
import { ModalRef } from '@simpl/element-ng/modal';
import { SiTranslateModule } from '@simpl/element-translate-ng/translate';
import { take } from 'rxjs';

import { EditDiscardDialogResult } from '../si-action-dialog.types';

@Component({
  selector: 'si-edit-discard-dialog',
  templateUrl: './si-edit-discard-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, AsyncPipe, SiTranslateModule, SiLoadingButtonComponent]
})
export class SiEditDiscardDialogComponent {
  @Input() titleId?: string;
  /** @defaultValue '' */
  @Input() heading = '';
  /**
   * @defaultValue
   * ```
   * $localize`:@@SI_EDIT_DISCARD_DIALOG.MESSAGE:Do you want to save changes to the modified element?`
   * ```
   */
  @Input()
  message =
    $localize`:@@SI_EDIT_DISCARD_DIALOG.MESSAGE:Do you want to save changes to the modified element?`;
  /**
   * @defaultValue
   * ```
   * $localize`:@@SI_EDIT_DISCARD_DIALOG.SAVE_BTN:Save`
   * ```
   */
  @Input() saveBtnName = $localize`:@@SI_EDIT_DISCARD_DIALOG.SAVE_BTN:Save`;
  /**
   * @defaultValue
   * ```
   * $localize`:@@SI_EDIT_DISCARD_DIALOG.DISCARD_BTN:Don't save`
   * ```
   */
  @Input() discardBtnName = $localize`:@@SI_EDIT_DISCARD_DIALOG.DISCARD_BTN:Don't save`;
  /**
   * @defaultValue
   * ```
   * $localize`:@@SI_EDIT_DISCARD_DIALOG.CANCEL_BTN:Cancel`
   * ```
   */
  @Input() cancelBtnName = $localize`:@@SI_EDIT_DISCARD_DIALOG.CANCEL_BTN:Cancel`;
  /** @defaultValue false */
  @Input({ transform: booleanAttribute }) disableSave = false;
  /**
   * @defaultValue
   * ```
   * $localize`:@@SI_EDIT_DISCARD_DIALOG.DISABLE_SAVE_MESSAGE:Do you want to discard the changes`
   * ```
   */
  @Input()
  disableSaveMessage =
    $localize`:@@SI_EDIT_DISCARD_DIALOG.DISABLE_SAVE_MESSAGE:Do you want to discard the changes`;
  /**
   * @defaultValue
   * ```
   * $localize`:@@SI_EDIT_DISCARD_DIALOG.DISABLE_SAVE_DISCARD_BTN:Discard`
   * ```
   */
  @Input()
  disableSaveDiscardBtnName = $localize`:@@SI_EDIT_DISCARD_DIALOG.DISABLE_SAVE_DISCARD_BTN:Discard`;
  /**
   * @defaultValue
   * ```
   * {}
   * ```
   */
  @Input() translationParams: { [key: string]: any } = {};
  /** @defaultValue '' */
  @Input() icon = '';

  protected modalRef = inject(ModalRef<SiEditDiscardDialogComponent, EditDiscardDialogResult>);
  protected loading$ = this.modalRef.message.pipe(take(1));
}
