import { NgTemplateOutlet } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import {
  getDayOptions,
  getMonthOptions,
  SiBACnetDateTimePickerComponent,
  WildcardBACnet
} from '@simpl/buildings-ng/bacnet-datetime-picker';
import { clone, DateFormat } from '@simpl/buildings-ng/common';
import { DateValue } from '@simpl/buildings-types';
import { SiDateInputDirective, SiDatepickerComponent } from '@simpl/element-ng';
import { SiTranslateService } from '@simpl/element-ng/translate';

import {
  dateString,
  dateToIsoString,
  isWeekSpecified,
  parseBacnetDateTimeFormat
} from '../../helpers/date';
import { Property, StateChange, ValueState } from '../../interfaces/property';
import { SiPropertyPopoverComponent } from '../si-property-popover/si-property-popover.component';

@Component({
  selector: 'si-date-property',
  templateUrl: './si-date-property.component.html',
  styleUrl: './si-date-property.component.scss',
  standalone: true,
  imports: [
    FormsModule,
    NgTemplateOutlet,
    SiBACnetDateTimePickerComponent,
    SiDateInputDirective,
    SiDatepickerComponent,
    SiPropertyPopoverComponent
  ]
})
export class SiDatePropertyComponent implements OnInit, AfterViewInit {
  @Input({ required: true }) property!: Property<DateValue>;
  /** @defaultValue 'none' */
  @Input() valueState: ValueState = 'none';
  @Input() format?: DateFormat;
  /** @defaultValue false */
  @Input() forceReadonly = false;
  @Output() readonly submitted = new EventEmitter<Property<DateValue>>();

  @ViewChild('form') protected form!: NgForm;
  @ViewChild('popover', { static: true }) protected popover!: SiPropertyPopoverComponent;

  protected isValid = true;
  protected formatForPicker!: string;

  protected dialogTextRecurringMonth = $localize`:@@OBJECT_BROWSER.DIALOG_TEXT_MEDIUM_SHORT.RECURRING_MONTH:Any month`;
  protected dialogTextOddMonths = $localize`:@@OBJECT_BROWSER.DIALOG_TEXT_MEDIUM_SHORT.ODD_MONTHS:Odd months`;
  protected dialogTextEvenMonths = $localize`:@@OBJECT_BROWSER.DIALOG_TEXT_MEDIUM_SHORT.EVEN_MONTHS:Even months`;
  protected dialogTextRecurringDay = $localize`:@@OBJECT_BROWSER.DIALOG_TEXT_MEDIUM_SHORT.RECURRING_DAY:Any day`;
  protected dialogTextLastDay = $localize`:@@OBJECT_BROWSER.DIALOG_TEXT_MEDIUM_SHORT.LAST_DAYS:Last day`;
  protected dialogTextEvenDays = $localize`:@@OBJECT_BROWSER.DIALOG_TEXT_MEDIUM_SHORT.EVEN_DAYS:Even days`;
  protected dialogTextOddDays = $localize`:@@OBJECT_BROWSER.DIALOG_TEXT_MEDIUM_SHORT.ODD_DAYS:Odd days`;

  protected get readonly(): true | null {
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    return this.forceReadonly || this.property.value.readonly || null;
  }

  // note this is duplicated from popover since the si-bacnet-datetime-picker expect the full DateValue
  private editValue?: DateValue;
  private weekNum!: string;
  private months: { id: number; name: string }[] = [];
  private days: { id: number; name: string }[] = [];
  private elementRef = inject(ElementRef);
  private translateService = inject(SiTranslateService);

  ngOnInit(): void {
    if (this.isBacnetDatepicker() && !this.format) {
      this.format = 'dd.mm.yyyy';
    } else {
      if (!this.format) {
        this.formatForPicker = '';
      } else {
        this.formatForPicker = this.format.replace('mm', 'MM');
      }
    }
    if (this.isBacnetDatepicker()) {
      const monthOptions = getMonthOptions(
        this.dialogTextRecurringMonth,
        this.dialogTextOddMonths,
        this.dialogTextEvenMonths
      );
      this.months.push(monthOptions[WildcardBACnet.oddMonths]);
      this.months.push(monthOptions[WildcardBACnet.evenMonths]);

      this.months.forEach(element => {
        element.name = this.translateService.translateSync(element.name);
      });

      const dayOptions = getDayOptions(
        this.dialogTextRecurringDay,
        this.dialogTextLastDay,
        this.dialogTextEvenDays,
        this.dialogTextOddDays
      );
      this.days.push(dayOptions[WildcardBACnet.oddDays]);
      this.days.push(dayOptions[WildcardBACnet.evenDays]);
      this.days.push(dayOptions[WildcardBACnet.lastDayOfMonth]);

      this.days.forEach(element => {
        element.name = this.translateService.translateSync(element.name);
      });
    }
  }

  protected get modelValue(): any {
    return this.popover.isActive ? this.editValue : this.property?.value;
  }

  private selectedDt!: Date;
  protected get selectedDate(): any {
    return this.selectedDt;
  }

  protected set selectedDate(val: Date) {
    if (val) {
      this.selectedDt = val;
      if (this.editValue) {
        this.editValue.value = dateToIsoString(val);
      }
      // When default text is specified, form status is not updated on selecting the date,
      // since the default text view is fixed. To overcome this mauanlly set the isValid flag here
      if (this.property.defaultText) {
        this.isValid = true;
      }
    }
  }

  protected isBacnetDatepicker(): boolean {
    return !!this.property.value.wildcardAllowed || !!this.property.value.specialAllowed;
  }

  protected get displayValue(): string {
    return dateString(this.modelValue.value, this.format!, clone(this.months), clone(this.days));
  }

  ngAfterViewInit(): void {
    this.form.statusChanges!.subscribe(() => {
      // setTimeout to work around Angular bug 23657
      setTimeout(() => (this.isValid = !!this.form.valid));
    });

    const propDate = this.property.value?.value;
    if (propDate) {
      const parsedDate = parseBacnetDateTimeFormat(propDate);
      if (parsedDate) {
        if (!this.isBacnetDatepicker()) {
          setTimeout(() => (this.selectedDate = new Date(parsedDate)));
        }
        this.property.value.value = parsedDate;
      }

      const date = propDate.split('T')[0];
      if (date) {
        this.weekNum = date.split(' ')[1];
      }
    }
  }

  private parseEditValue(): void {
    if (this.editValue?.value) {
      const parsedDate = parseBacnetDateTimeFormat(this.editValue.value);
      if (parsedDate) {
        this.editValue.value = parsedDate;
      }
    }
  }

  protected stateChange(state: StateChange): void {
    switch (state) {
      case 'openKeyboard':
        setTimeout(() =>
          this.elementRef.nativeElement.querySelector('si-bacnet-datetime-picker input')?.focus()
        );
        this.editValue = clone(this.property.value);
        this.parseEditValue();
        this.setSelectedDateAndValidStatus();
        break;
      case 'cancel':
      case 'open':
        this.editValue = clone(this.property.value);
        this.parseEditValue();
        this.setSelectedDateAndValidStatus();
        break;
      case 'submit':
        this.property.value.value = this.editValue!.value;
        this.editValue = undefined;
        this.appendWeek();
        this.submitted.emit(this.property);
        break;
      case 'release':
        this.property.value.value = undefined;
        this.appendWeek();
        this.submitted.emit(this.property);
        break;
    }
  }

  private appendWeek(): void {
    const propVal = this.property.value?.value;
    if (this.weekNum && propVal) {
      if (isWeekSpecified(propVal)) {
        return;
      }
      const updatedWeek = new Date(propVal).getDay();
      if (!isNaN(updatedWeek)) {
        this.weekNum = updatedWeek.toString();
      }
      this.property.value.value = this.property.value.value + ' ' + this.weekNum;
    }
  }

  private setSelectedDateAndValidStatus(): void {
    if (!this.isBacnetDatepicker()) {
      if (this.editValue?.value) {
        this.selectedDate = new Date(this.editValue.value);
      } else if (!this.property.value?.optional) {
        this.isValid = false;
      }
    }
  }
}
