import { NgClass } from '@angular/common';
import {
  booleanAttribute,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { BlinkService, EntityStatusType, STATUS_ICON, StatusIcon } from '@simpl/element-ng/common';
import { SiIconComponent } from '@simpl/element-ng/icon';
import { SiTranslateModule } from '@simpl/element-translate-ng/translate';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'si-circle-status',
  templateUrl: './si-circle-status.component.html',
  styleUrl: './si-circle-status.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, SiIconComponent, SiTranslateModule]
})
export class SiCircleStatusComponent implements OnChanges, OnDestroy {
  /**
   * The status (success, info, warning, danger) to be visualized.
   */
  @Input() status?: EntityStatusType;

  /* DO NOT REMOVE: Even though the input is marked as deprecated, the core-team decided not to remove the
   input. The possibility to have custom color is often requested by projects, so we keep it.
   however in order to discourage it's use, we keep it marked deprecated.
   */
  /**
   * A custom color (e.g. `#fefefe`) for exceptional cases.
   * @deprecated use the semantic `status` input instead.
   */
  @Input() color?: string;

  /**
   * Set a domain type icon (e.g. `element-door`) for which the status shall be shown.
   * Leave undefined for visualizing the status without an icon.
   */
  @Input() icon?: string;

  /**
   * Set the size using either regular or small only works when used together with `icon`
   *
   * @defaultValue 'regular'
   */
  @Input() size: 'regular' | 'small' = 'regular';

  /**
   * event direction is out
   *
   * @defaultValue false
   */
  @Input({ transform: booleanAttribute }) eventOut = false;

  /**
   * Whether the status should appear with a pulsing circle around the badge.
   *
   * @defaultValue false
   */
  @Input({ transform: booleanAttribute }) blink = false;

  /**
   * Blink pulse generator for synchronized blinking with other components
   */
  @Input() blinkPulse?: Observable<boolean>;

  /**
   * Aria label for icon and status combo. Needed for a11y
   */
  @Input() ariaLabel?: string;

  protected backgroundClass = '';
  protected theAriaLabel = '';
  protected statusIcon?: StatusIcon;
  protected blinkOn = false;
  protected contrastFix = false;

  private blinkSubs?: Subscription;

  @ViewChild('bg') private bg!: ElementRef;

  private blinkService = inject(BlinkService);
  private cdRef = inject(ChangeDetectorRef);

  ngOnChanges(changes: SimpleChanges): void {
    if (this.blinkService && changes.blink) {
      this.blinkSubs?.unsubscribe();

      if (this.blink) {
        const pulse = this.blinkPulse ?? this.blinkService.pulse$;
        this.blinkSubs = pulse.subscribe(onOff => {
          this.blinkOn = onOff;
          this.cdRef.markForCheck();
        });
      } else {
        this.blinkOn = false;
      }
    }
    if (changes.status) {
      this.statusIcon = this.status ? STATUS_ICON[this.status] : undefined;
      this.backgroundClass = this.statusIcon?.background ?? '';
    }
    if (changes.ariaLabel || changes.status || changes.icon) {
      this.calcAriaLabel();
    }
    if (changes.color || changes.blink) {
      queueMicrotask(() => {
        this.cdRef.markForCheck();
        this.contrastFix = !!this.color && this.blink && this.calculateContrastFix();
      });
    }
  }

  ngOnDestroy(): void {
    this.blinkSubs?.unsubscribe();
  }

  private calcAriaLabel(): void {
    if (this.ariaLabel) {
      this.theAriaLabel = this.ariaLabel;
    } else {
      const statusName = this.status && STATUS_ICON[this.status] ? this.status : 'none';
      const direction = this.eventOut ? ' out' : '';
      const iconName = this.icon?.replace('element-', '') ?? '';
      this.theAriaLabel = `${iconName}${
        this.status && this.icon ? ' in ' : ''
      }status ${statusName}${direction}`;
    }
  }

  private calculateContrastFix(): boolean {
    // see https://www.w3.org/TR/AERT/#color-contrast
    const rgb = getComputedStyle(this.bg.nativeElement)
      .backgroundColor?.match(/\d+/g)
      ?.map(v => +v);
    return !!rgb && Math.round((rgb[0] * 299 + rgb[1] * 587 + rgb[2] * 114) / 1000) <= 128;
  }
}
