import { CdkPortalOutlet, Portal, PortalModule } from '@angular/cdk/portal';
import { isPlatformBrowser } from '@angular/common';
import {
  booleanAttribute,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  PLATFORM_ID,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {
  BOOTSTRAP_BREAKPOINTS,
  Breakpoints,
  ElementDimensions,
  ResizeObserverService
} from '@simpl/element-ng/resize-observer';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { SiSidePanelService } from './si-side-panel.service';
import { SidePanelMode, SidePanelSize } from './side-panel.model';

@Component({
  selector: 'si-side-panel',
  templateUrl: './si-side-panel.component.html',
  styleUrl: './si-side-panel.component.scss',
  host: { class: 'si-layout-inner' },
  standalone: true,
  imports: [PortalModule]
})
export class SiSidePanelComponent implements OnInit, OnDestroy, OnChanges {
  /**
   * @defaultValue false
   */
  @Input({ transform: booleanAttribute }) collapsible = false;

  /*
   * Default state of navigation
   */
  @Input({ transform: booleanAttribute }) collapsed?: boolean;

  /**
   * Header of side panel
   * @deprecated Never had any effect, should be set on {@link SiSidePanelContentComponent}.
   *
   * @defaultValue ''
   */
  @Input() heading = '';

  /**
   * Mode of side panel
   * (ignored below a certain width)
   *
   * @defaultValue 'over'
   */
  @Input() mode: SidePanelMode = 'over';

  /**
   * Size of side-panel
   *
   * @defaultValue 'regular'
   */
  @Input() size: SidePanelSize = 'regular';

  /**
   * Aria label for close button. Needed for a11y
   *
   * @deprecated Never had any effect, should be set on {@link SiSidePanelContentComponent}.
   *
   * @defaultValue
   * ```
   * $localize`:@@SI_SIDE_PANEL.CLOSE:Close`
   * ```
   */
  @Input() closeButtonLabel = $localize`:@@SI_SIDE_PANEL.CLOSE:Close`;

  /**
   * Toggle icon aria-label, required for a11y
   *
   * @defaultValue
   * ```
   * $localize`:@@SI_SIDE_PANEL.TOGGLE:Toggle`
   * ```
   */
  @Input() toggleItemLabel = $localize`:@@SI_SIDE_PANEL.TOGGLE:Toggle`;

  /**
   * Specifies custom breakpoints to automatically switch mode.
   * The `smMinimum` specifies the breakpoint for the mobile view.
   * The `lgMinimum` specifies the breakpoint to allow scroll mode
   * (below automatically uses over mode).
   * The `xlMinimum` specifies the breakpoint to allow scroll mode
   * with wide size (below automatically uses over mode).
   */
  @Input() breakpoints?: Breakpoints;

  /**
   * Enable mobile drawer for small screen sizes. Should not be used in conjunction with the vertical navbar.
   *
   * @defaultValue false
   */
  @HostBinding('class.enable-mobile') @Input({ transform: booleanAttribute }) enableMobile = false;

  /**
   * Emits when the panel is closed
   */
  @Output() readonly closed = new EventEmitter<void>();

  /**
   * Emits whenever the content is resized due to opening and closing or parent resize.
   */
  @Output() readonly contentResize = new EventEmitter<ElementDimensions>();

  @HostBinding('class.collapsible') protected get isCollapsible(): boolean {
    return this.collapsible && !this.showTempContent;
  }
  @HostBinding('class.collapsible-temp') protected get isCollapsibleTemp(): boolean {
    return this.collapsible && this.showTempContent;
  }
  @HostBinding('class.rpanel-mode--scroll') protected get isScrollMode(): boolean {
    return this.mode === 'scroll';
  }
  @HostBinding('class.rpanel-mode--over') protected get isOverMode(): boolean {
    return this.mode === 'over';
  }
  @HostBinding('class.rpanel-size--regular') protected get isNormalSize(): boolean {
    return this.size === 'regular';
  }
  @HostBinding('class.rpanel-size--wide') protected get isWideSize(): boolean {
    return this.size === 'wide';
  }

  @HostBinding('class.rpanel-resize-xs') protected isXs = false;
  @HostBinding('class.rpanel-resize-sm') protected isSm = false;
  @HostBinding('class.rpanel-resize-md') protected isMd = true;
  @HostBinding('class.rpanel-resize-lg') protected isLg = false;
  @HostBinding('class.rpanel-resize-xl') protected isXl = false;

  @HostBinding('class.rpanel-collapsed') protected isCollapsed = false;
  @HostBinding('class.ready') protected ready = false;
  @HostBinding('class.rpanel-hidden') protected isHidden = false;

  @ViewChild('sidePanel', { static: true }) private panelElement!: ElementRef;
  @ViewChild('content', { static: true }) private contentElement!: ElementRef;

  @ViewChild('portalOutlet', { read: CdkPortalOutlet, static: true })
  private portalOutlet!: CdkPortalOutlet;
  @ViewChild('tmpPortalOutlet', { read: CdkPortalOutlet, static: true })
  private tmpPortalOutlet!: CdkPortalOutlet;

  protected showTempContent = false;

  /**
   * The $rpanel-transition-duration in the style is 0.5 seconds.
   * Sending the resize event after resize need to wait until resize is done.
   */
  private readonly resizeEventDelay = 500;
  private resizeEvent = new Subject<void>();
  private resizeSubs?: Subscription;
  private subscription?: Subscription;
  private openingOrClosing = false;
  private previousContentDimensions: ElementDimensions = { width: 0, height: 0 };
  private isCollapsedInternal = false; // same as the other one, except w/o timeout for animation
  private isBrowser = isPlatformBrowser(inject(PLATFORM_ID));

  private element = inject(ElementRef);
  private resizeObserver = inject(ResizeObserverService);
  private service = inject(SiSidePanelService);
  private cdRef = inject(ChangeDetectorRef);

  constructor() {
    if (this.isBrowser) {
      this.subscription = this.resizeEvent
        .asObservable()
        .pipe(debounceTime(this.resizeEventDelay))
        .subscribe(() => {
          this.openingOrClosing = false;
          this.emitResizeOutputs();
          if (this.isCollapsedInternal && !this.collapsible) {
            this.isHidden = true;
          }
          this.cdRef.markForCheck();
        });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.collapsed) {
      if (this.collapsed) {
        this.service.close();
      } else {
        this.service.open();
      }
    } else if (changes.enableMobile) {
      this.service.enableMobile = this.enableMobile;
    }
  }

  ngOnInit(): void {
    // handle initial state to avoid flicker
    const collapsed = this.collapsed ?? !this.service.isOpen();
    this.isCollapsedInternal = collapsed;
    this.isHidden = collapsed;
    this.isCollapsed = collapsed;

    this.resizeSubs = this.resizeObserver
      .observe(this.element.nativeElement, 100, true)
      .subscribe(dim => {
        this.setBreakpoints(dim.width, dim.height);
        if (!this.ready) {
          // delay because the initial sizing needs to settle
          setTimeout(() => {
            this.ready = true;
            this.cdRef.markForCheck();
          }, 100);
        }
        if (!this.openingOrClosing) {
          this.emitResizeOutputs();
        }
      });
    if (this.subscription) {
      this.subscription.add(this.service.content$.subscribe(portal => this.attachContent(portal)));
      this.subscription.add(
        this.service.tempContent$.subscribe(portal => this.attachTempContent(portal))
      );
      this.subscription.add(this.service.isOpen$.subscribe(state => this.openClose(state)));
    }
  }

  ngOnDestroy(): void {
    this.portalOutlet.detach();
    this.resizeSubs?.unsubscribe();
    this.subscription?.unsubscribe();
  }

  /**
   * Toggle whether the side panel is expanded or not.
   */
  toggleSidePanel(): void {
    if (this.collapsible) {
      this.service.toggle();
    } else {
      this.service.close();
    }
  }

  private emitResizeOutputs(): void {
    const contentDimensions = this.getContentDimensions();
    if (
      contentDimensions.width !== this.previousContentDimensions.width ||
      contentDimensions.height !== this.previousContentDimensions.height
    ) {
      this.previousContentDimensions = contentDimensions;
      this.contentResize.emit(contentDimensions);
    }
  }

  private getContentDimensions(): ElementDimensions {
    if (!this.isCollapsedInternal && this.isXs) {
      return { width: 0, height: 0 };
    }
    const rect = this.contentElement.nativeElement.getBoundingClientRect();
    return { width: rect.width, height: rect.height };
  }

  private setBreakpoints(width: number, height: number): void {
    if (!width && !height) {
      // element is not visible, no point in changing anything
      return;
    }
    const breakpoints = this.breakpoints ?? BOOTSTRAP_BREAKPOINTS;

    this.isXs = width < breakpoints.smMinimum;
    this.isSm = width >= breakpoints.smMinimum && width < breakpoints.mdMinimum;
    this.isMd = width >= breakpoints.mdMinimum && width < breakpoints.lgMinimum;
    this.isLg = width >= breakpoints.lgMinimum && width < breakpoints.xlMinimum;
    this.isXl = width >= breakpoints.xlMinimum;

    this.cdRef.markForCheck();
  }

  private sendResize(): void {
    if (this.isScrollMode || this.element.nativeElement.style.paddingRight !== '0') {
      this.openingOrClosing = true;
      this.resizeEvent.next();
    }
  }

  private attachContent(portal?: Portal<any>): void {
    this.portalOutlet.detach();
    if (portal) {
      this.portalOutlet.attach(portal);
    }
    this.cdRef.markForCheck();
  }

  private attachTempContent(portal: Portal<any> | undefined): void {
    this.tmpPortalOutlet.detach();
    if (portal) {
      this.tmpPortalOutlet.attach(portal);
      this.showTempContent = true;
      this.openClose(true, true);
    } else if (this.showTempContent) {
      this.showTempContent = false;
      this.openClose(this.service.isOpen(), true);
    }
    this.cdRef.markForCheck();
  }

  private openClose(open: boolean, regainFocus = false): void {
    if (open !== this.isCollapsedInternal) {
      this.moveFocusInside(open && regainFocus);
      return;
    }
    this.isCollapsedInternal = !open;
    if (open) {
      this.isHidden = false;
    }
    setTimeout(() => this.doOpenClose(open));
  }

  private doOpenClose(open: boolean): void {
    this.moveFocusInside(open);
    this.isCollapsed = !open;
    this.collapsed = this.isCollapsed;
    if (this.isCollapsedInternal) {
      this.closed.emit();
    }
    this.sendResize();
    this.cdRef.markForCheck();
  }

  private moveFocusInside(open: boolean): void {
    if (
      open &&
      !document.activeElement?.parentElement?.classList.contains('side-panel-collapse-toggle') &&
      !document.activeElement?.classList.contains('side-panel-collapse-toggle')
    ) {
      // moves the keyboard focus inside the panel so that the next tab is somewhere useful
      this.panelElement.nativeElement.focus();
      queueMicrotask(() => this.panelElement.nativeElement.blur());
    }
  }
}
