@if (iconUrl) {
  <img alt="" width="64" height="64" class="app-icon" [src]="iconUrl" />
} @else if (iconClass) {
  <i class="app-icon" aria-hidden="true" [ngClass]="iconClass"></i>
}
<div class="si-title-2 text-truncate mw-100">
  <ng-content select="[app-name]" />
  @if (external) {
    <i class="icon element-export icon mt-n2" aria-hidden="true"></i>
  }
</div>
<div class="si-body-2 text-truncate mw-100">
  <ng-content select="[app-systemName]" />
</div>
@if (enableFavoriteToggle) {
  <i
    aria-hidden="true"
    class="favorite-icon"
    [class.element-favorites-filled]="favorite"
    [class.element-favorites]="!favorite"
    (click)="favoriteClicked($event)"
  ></i>
}
