import { DatePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { isRTL } from '@simpl/element-ng/common';

import * as calendarUtils from '../date-time-helper';
import { Cell, SiCalendarBodyComponent } from './si-calendar-body.component';
import { SiCalendarDirectionButtonComponent } from './si-calendar-direction-button.component';
import { MonthCompareAdapter } from './si-compare-adapter';
import { SiInitialFocusComponent } from './si-initial-focus.component';

/**
 * Show months of a single year as table and handles the keyboard interactions.
 * The focus and focusedDate is handled according the keyboard interactions.
 */
@Component({
  selector: 'si-month-selection',
  templateUrl: './si-month-selection.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SiCalendarDirectionButtonComponent, SiCalendarBodyComponent, DatePipe]
})
export class SiMonthSelectionComponent extends SiInitialFocusComponent implements OnChanges {
  /**
   * The translated list of months.
   *
   * @defaultValue []
   */
  @Input() months: string[] = [];
  /** The active date, the cell which will receive the focus. */
  @Input() set focusedDate(v: Date) {
    const oldFocusedDate = this._focusedDate;
    this._focusedDate = v;
    if (calendarUtils.isAnotherYear(this._focusedDate, oldFocusedDate)) {
      // Re-calc month view
      this.initView();
      this.activeMonthChange.emit(v);
    }
  }
  get focusedDate(): Date {
    return this._focusedDate;
  }
  /** Emits when the active focused date changed, typically during keyboard navigation. */
  @Output() readonly focusedDateChange = new EventEmitter<Date>();
  /** Emits when the active focused date is changed to another month / year, typically during keyboard navigation. */
  @Output() readonly activeMonthChange = new EventEmitter<Date>();
  /** Emits when the user requests a different to show a different view. */
  @Output() readonly viewChange = new EventEmitter<'year'>();
  /** Listen Escape event to switch view back */
  @HostListener('keydown.Escape', ['$event']) triggerEsc(event: KeyboardEvent): void {
    this.selectedValueChange.emit(null);
    event.preventDefault();
    event.stopPropagation(); // Prevents the overlay from closing.
  }
  /**
   * The current visible list of calendar months.
   * Every time the focusedDate changes to another year the list will update.
   */
  protected monthCells: Cell[][] = [];
  /**
   * Is previous year button disabled based on the minDate,
   * true when the focusedDate is equal or before the minDate.
   */
  protected disablePreviousButton = false;
  /**
   * Is next year button disabled based on the maxDate,
   * true when the focusedDate is equal or after the maxDate.
   */
  protected disableNextButton = false;
  protected compareAdapter = new MonthCompareAdapter();
  /** The currently focused date */
  private _focusedDate = calendarUtils.today();
  /** Number of column before the row is wrapped */
  private readonly columnCount = 2;
  private readonly cdRef = inject(ChangeDetectorRef);

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.maxDate || changes.minDate || changes.maxMonth || changes.minMonth) {
      this.initView();
    }
  }

  protected calendarBodyKeyDown(event: KeyboardEvent): void {
    const isRtl = isRTL();
    const oldActiveDate = this.focusedDate;
    let newActiveDate: Date = this.focusedDate;
    switch (event.key) {
      case 'ArrowLeft':
        newActiveDate = calendarUtils.addMonthsInRange(
          this.focusedDate,
          isRtl ? 1 : -1,
          this.minDate,
          this.maxDate
        );
        break;
      case 'ArrowRight':
        newActiveDate = calendarUtils.addMonthsInRange(
          this.focusedDate,
          isRtl ? -1 : 1,
          this.minDate,
          this.maxDate
        );
        break;
      case 'ArrowUp':
        newActiveDate = calendarUtils.addMonthsInRange(
          this._focusedDate,
          -1 * this.columnCount,
          this.minDate,
          this.maxDate
        );
        break;
      case 'ArrowDown':
        newActiveDate = calendarUtils.addMonthsInRange(
          this._focusedDate,
          this.columnCount,
          this.minDate,
          this.maxDate
        );
        break;
      case 'Escape':
        this.selectedValueChange.emit(null);
        event.preventDefault();
        event.stopPropagation(); // Prevents the overlay from closing.
        return;
      case 'Enter':
      case 'Space':
      default:
        // Don't prevent default or focus active cell on keys that we don't explicitly handle.
        return;
    }

    this.focusedDate = newActiveDate;
    if (!this.compareAdapter.isEqual(oldActiveDate, this.focusedDate)) {
      // Synchronize focusedDate with year view
      this.focusedDateChange.emit(this.focusedDate);
      this.focusActiveCell();
    }
    // Prevent unexpected default actions such as form submission.
    event.preventDefault();
  }

  /**
   * Add offset to year and update focusedDate.
   */
  protected setYearOffset(offset: number): void {
    const newActive = calendarUtils.createDate(this.focusedDate);
    newActive.setFullYear(newActive.getFullYear() + offset);
    this.focusedDate = newActive;
    this.emitActiveDate(this.focusedDate);
  }
  /**
   * Indicate the previous button shall be disabled.
   * This happens when the focusedDate is equal or before the minDate.
   */
  protected isPreviousButtonDisabled(): boolean {
    if (!this.minDate) {
      return false;
    }
    return (
      calendarUtils.isSameYear(this.focusedDate!, this.minDate) ||
      calendarUtils.isAfterYear(this.minDate, this.focusedDate)
    );
  }

  /**
   * Indicate the next button shall be disabled.
   * This happens when the focusedDate is equal or after the maxDate.
   */
  protected isNextButtonDisabled(): boolean {
    if (!this.maxDate) {
      return false;
    }
    return (
      calendarUtils.isSameYear(this.focusedDate!, this.maxDate) ||
      calendarUtils.isAfterYear(this.focusedDate!, this.maxDate)
    );
  }

  protected emitSelectedValue(selected: Date): void {
    this.selectedValueChange.emit(selected);
    this.cdRef.markForCheck();
  }

  protected emitActiveDate(active: Date): void {
    active.setDate(this.focusedDate!.getDate());
    this.focusedDateChange.emit(active);
  }

  protected emitViewChange(): void {
    this.viewChange.emit('year');
  }

  /**
   * Initialize view based on the focusedDate.
   */
  private initView(): void {
    this.monthCells = [];
    let row: Cell[] = [];

    // The cell date object needs to be the first to prevent that we jump to the next month when
    // setting the month. For example the focusedDate is 31. setting february would result in the
    // 3. March.
    const startDate = calendarUtils.getFirstDateInYear(this.focusedDate);
    const today = calendarUtils.today();
    for (let i = 0; i <= 11; i++) {
      if (i > 0 && i % this.columnCount === 0) {
        this.monthCells.push(row);
        row = [];
      }
      const date = new Date(startDate);
      date.setMonth(i);
      const isToday = this.compareAdapter.isEqual(date, today);
      const isDisabled = !this.compareAdapter.isEqualOrBetween(date, this.minDate, this.maxDate);

      row.push({
        value: date.getDate(),
        disabled: isDisabled,
        ariaLabel: `${this.months[date.getMonth()]} ${this.focusedDate.getFullYear()}`,
        displayValue: this.months[date.getMonth()],
        isPreview: false,
        isToday,
        valueRaw: calendarUtils.createDate(date),
        cssClasses: ['month', 'si-title-1', 'text-truncate']
      });
    }
    this.monthCells.push(row);

    this.disablePreviousButton = this.isPreviousButtonDisabled();
    this.disableNextButton = this.isNextButtonDisabled();
  }
}
