import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MenuItem } from '@simpl/element-ng/common';
import { SiLinkDirective } from '@simpl/element-ng/link';
import { SiTranslateModule } from '@simpl/element-translate-ng/translate';

import { SiHeaderDropdownItemComponent } from './si-header-dropdown-item.component';
import { SiHeaderDropdownTriggerDirective } from './si-header-dropdown-trigger.directive';
import { SiHeaderDropdownComponent } from './si-header-dropdown.component';

/**
 * A factory to render multiple {@link MenuItem}.
 * Requires a wrapping {@link SiHeaderDropdownComponent}.
 *
 * @internal
 */
@Component({
  selector: 'si-header-dropdown-items-factory, si-navbar-dropdown-items-factory',
  standalone: true,
  imports: [
    SiHeaderDropdownComponent,
    SiHeaderDropdownItemComponent,
    SiTranslateModule,
    SiLinkDirective,
    SiHeaderDropdownTriggerDirective
  ],
  templateUrl: './si-header-dropdown-items-factory.component.html'
})
export class SiHeaderDropdownItemsFactoryComponent {
  @Input({ required: true }) items!: MenuItem[];
  @Output() readonly activeChange = new EventEmitter<boolean>();
}
