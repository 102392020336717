import { NgModule } from '@angular/core';

import { SiNavbarSecondaryComponent } from './si-navbar-secondary.component';

/**
 * @deprecated This module will be dropped without replacement.
 * It is recommended to rely entirely on the primary and vertical navbar.
 *
 * If this is not applicable, one can re-create the secondary navbar in a template driven manner:
 * @example
 * ```html
 * <div class="navbar bg-base-1">
 *   <div class="navbar-collapse navbar-expand">
 *     <div class="navbar-nav nav nav-tabs">
 *       <a class="nav-link" routerLink="item1" routerLinkActive="active">item 1</a>
 *       <a class="nav-link" routerLink="item2" routerLinkActive="active">item 2</a>
 *       <button class="nav-link" type="button" [siNavbarDropdownTriggerFor]="dropdown">
 *         item 3
 *         <span class="dropdown-caret element-down-2"></span>
 *       </button>
 *     </div>
 *   </div>
 * </div>
 *
 * <ng-template #dropdown>
 *   <si-header-dropdown>
 *     <a si-header-dropdown-item routerLink="item3-1">item 3.1</a>
 *     <a si-header-dropdown-item routerLink="item3-2">item 3.2</a>
 *   </si-header-dropdown>
 * </ng-template>
 * ```
 */
@NgModule({
  imports: [SiNavbarSecondaryComponent],
  exports: [SiNavbarSecondaryComponent]
})
export class SiNavbarSecondaryModule {}
