import {
  CdkOverlayOrigin,
  ConnectedOverlayPositionChange,
  ConnectedPosition
} from '@angular/cdk/overlay';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  inject,
  Input,
  NgZone,
  OnInit,
  TemplateRef
} from '@angular/core';
import { calculateOverlayArrowPosition, OverlayArrowPosition } from '@simpl/element-ng/common';

@Component({
  selector: 'si-popover',
  templateUrl: './si-popover.component.html',
  standalone: true,
  imports: [NgClass, NgTemplateOutlet]
})
export class PopoverComponent implements OnInit {
  @Input() popover!: string | TemplateRef<any>;
  /** @defaultValue '' */
  @Input() popoverTitle = '';
  // TODO: remove with v47
  @Input() anchor!: CdkOverlayOrigin;
  // TODO: remove with v47
  @Input() positions!: ConnectedPosition[];
  /** @defaultValue '' */
  @Input() containerClass = '';
  @Input() icon?: string;
  @Input() popoverContext?: unknown;

  protected positionClass = '';
  protected arrowPos?: OverlayArrowPosition;
  protected popoverTemplate: TemplateRef<any> | null = null;

  private elementRef = inject(ElementRef);
  private zone = inject(NgZone);
  private cdRef = inject(ChangeDetectorRef);

  ngOnInit(): void {
    if (this.popover instanceof TemplateRef) {
      this.popoverTemplate = this.popover;
    }
  }

  /** @internal */
  updateArrow(change: ConnectedOverlayPositionChange, anchor?: ElementRef): void {
    const positionClass = `popover-${change.connectionPair.overlayX}-${change.connectionPair.overlayY}`;
    // need two updates as class changes affect the position
    if (positionClass !== this.positionClass) {
      this.zone.run(() => {
        this.positionClass = positionClass;
        this.cdRef.markForCheck();
      });
    }
    const arrowPos = calculateOverlayArrowPosition(change, this.elementRef, anchor);
    this.zone.run(() => {
      this.arrowPos = arrowPos;
      this.cdRef.markForCheck();
    });
  }
}
