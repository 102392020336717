import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';

import { GmsImage } from '../elements/gms-image';
import { GmsElementComponent } from './gms-element.component';

@Component({
  selector: '[gms-image]',
  template: `<svg:g
                    (mouseenter)="OnMouseEnter($event)" (click)="OnMouseClick($event)"
                    (mouseout)="OnMouseLeave($event)"
                    [style.cursor]="element.IsDefaultCursorType ? 'default': 'pointer'"
                    [id]="element.Id"
                    [ngClass]="!element.IsHitTestVisible ? 'noptrevents': 'allptrevents'"
                    [attr.transform]="element.GetTransformations()"
                    [attr.filter]="element.Filter?.Url"
                    [attr.opacity]="element.Opacity" [attr.visibility]="element.GetVisible()"
                    [attr.clip-path]="element.ClipPathUrl">
                    <defs *ngIf="element.HasClipInformation">
                        <clipPath [attr.id]="element.ClipPathId">
                            <path [attr.d]="element.GetClipPathData()"/>
                        </clipPath>
                    </defs>
                    <rect
                        [ngClass]="'noptrevents'"
                        [attr.width]="element.Width" [attr.height]="element.Height"
                        [attr.fill]="element.Background" [attr.fill-opacity]="element.BackgroundOpacity" stroke-opacity="0" />
                    <svg:svg
                        [ngClass]="!element.IsHitTestVisible ? 'noptrevents': 'allptrevents'"
                        [attr.width]="element.Width" [attr.height]="element.Height" [attr.viewBox]="element.ViewBox"
                        [attr.preserveAspectRatio] = "element.PreserveAspectRatio">
                        <use [ngClass]="element.IsGrayscale ? 'grayscaleStyle': 'nograyscaleStyle'"
                             [attr.xlink:href]="element.Href"/>
                    </svg:svg>
                    <svg:rect *ngIf="element.ShowErrorBorder"
                              class="noptrevents"
                              [attr.width]="element.Width"
                              [attr.height]="element.Height"
                              fill="url(#pattern-error-comm)"
                              stroke-width="2" stroke="#5A5D60" />
                    <!--<title *ngIf="element.Tooltip !== undefined">{{element.Tooltip}}</title>-->
               </svg:g>`,
  styles: [`.noptrevents{pointer-events:none;}`,
    `.allptrevents{pointer-events:all;}`,
    `.grayscaleStyle{-webkit-filter: grayscale(100%);filter: grayscale(100%)}`,
    `.nograyscaleStyle{-webkit-filter: grayscale(0);filter: none}`
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class GmsImageComponent extends GmsElementComponent implements OnInit, OnDestroy {
  @Input() public element: GmsImage = null;
}
