import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, NgZone, OnDestroy, OnInit } from '@angular/core';

import { GmsAnimatedGif } from '../elements/gms-animated-gif';
import { GmsAdornerService } from '../services/gms-adorner.service';
import { GmsElementComponent } from './gms-element.component';

@Component({
  selector: '[gms-animated-gif]',
  template: `<svg:g
                    (mouseenter)="OnMouseEnter($event)" (click)="OnMouseClick($event)"
                    (mouseout)="OnMouseLeave($event)"
                    [id]="element.Id"
                    [ngClass]="!element.IsHitTestVisible ? 'noptrevents': 'allptrevents'"
                    [attr.transform]="element.GetTransformations()"
                    [attr.filter]="element.Filter"
                    [attr.opacity]="element.Opacity" [attr.visibility]="element.GetVisible()"
                    [attr.clip-path]="element.ClipPathUrl">
                    <defs *ngIf="element.HasClipInformation">
                        <clipPath [attr.id]="element.ClipPathId">
                            <path [attr.d]="element.GetClipPathData()"/>
                        </clipPath>
                    </defs>
                    <rect
                        [ngClass]="'noptrevents'"
                        [attr.width]="element.Width" [attr.height]="element.Height"
                        [attr.fill]="element.Background" [attr.fill-opacity]="element.BackgroundOpacity" stroke-opacity="0" />
                    <svg:svg
                        [ngClass]="!element.IsHitTestVisible ? 'noptrevents': 'allptrevents'"
                        [attr.width]="element.Width" [attr.height]="element.Height" [attr.viewBox]="element.ViewBox"
                        [attr.preserveAspectRatio] = "element.PreserveAspectRatio">
                        <svg:g *ngIf="element.FramesLoaded">
                            <svg:g *ngFor="let item of element.Frames;">
                                <svg:image class="noptrevents" [attr.width]="element.ImageWidth" [attr.height]="element.ImageHeight"
                                    [attr.xlink:href]="item.url" [attr.visibility]="item.visible" (error)="RemoveErrorFrame(item)" />
                            </svg:g>
                        </svg:g>
                    </svg:svg>
                    <!--Rect introduced: Changing frame visbility impeded selection-->
                    <rect [ngClass]="!element.IsHitTestVisible ? 'noptrevents': 'allptrevents'"
                    [attr.width]="element.Width" [attr.height]="element.Height"
                    [attr.fill]="'transparent'" [attr.fill-opacity]="0" stroke-opacity="0" />
                    <svg:rect *ngIf="element.ShowErrorBorder"
                              class="noptrevents"
                              [attr.width]="element.Width"
                              [attr.height]="element.Height"
                              fill="url(#pattern-error-comm)"
                              stroke-width="2" stroke="#5A5D60" />
               </svg:g>`,
  styles: [`.noptrevents{pointer-events:none}`,
    `.allptrevents{pointer-events:all}`],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class GmsAnimatedGifComponent extends GmsElementComponent implements OnInit, OnDestroy {
  @Input() public element: GmsAnimatedGif = null;
  public currentFrameURL: string;
  private _currentFrameIndex = 0;

  private _isRunning = false;
  private _currentRunDirection: RunDirection = RunDirection.Forward;

  constructor(changeDetector: ChangeDetectorRef, adornerService: GmsAdornerService, public ngZone: NgZone) {
    super(changeDetector, adornerService);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.element.gifLoaded.subscribe(() => {
      this.ngZone.runOutsideAngular(() => this.RunGif());
    });

    this.element.runStateChanged.subscribe(value => {
      this.ngZone.runOutsideAngular(() => this.RunGif());
    });

    this.LoadGifContent();
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this.element.gifLoaded.unsubscribe();
    this.element.runStateChanged.unsubscribe();
    this.element = undefined;
  }

  public async LoadGifContent(): Promise<void> {
    let gifElement: HTMLElement = null;
    gifElement = document.getElementById(this.element.Href.substring(1, this.element.Href.length));

    let imageElement: Node;
    for (let i = 0; i < gifElement.childNodes.length; i++) {
      const imageNode: Node = gifElement.childNodes.item(i);

      if (imageNode.nodeName === 'image') {
        imageElement = imageNode;
      }
    }

    const dataURI: string = imageElement !== null ? (imageElement as Element).attributes.getNamedItem('xlink:href').value : null;

    if (imageElement !== null) {
      this.element.SetGifContent(dataURI);
    }
  }

  public RunGif(): void {
    if (this.element === undefined) {
      return;
    }

    const newRunDirection: RunDirection = this.element.RunReverse ? RunDirection.Reverse : RunDirection.Forward;
    const directionChanged: boolean = this._currentRunDirection !== newRunDirection;

    if (this._isRunning && !directionChanged) { // If running and direction unchanged ignore.
      return;
    }

    if (this.element.RunReverse) {
      this._currentRunDirection = RunDirection.Reverse; // Sets current RunDirection
      this.RunReverse();
    } else {
      this._currentRunDirection = RunDirection.Forward; // Sets current RunDirection
      this.RunForward();
    }
  }

  public RunForward(): void {
    if (this.element === undefined) {
      return;
    }

    if (this.element.RunReverse) {
      return;
    }

    const previousIndex: number = this._currentFrameIndex === 0 ? (this.element.Frames.length > 1
      ? this.element.Frames.length - 1 : 0) : this._currentFrameIndex - 1;
    if (this.element.RunAnimation && this.element.SpeedAnimation !== 0) {
      this._isRunning = true;

      // delay field has 1/100 of a second.
      const currentFrameDelay: number = this.element.Frames[this._currentFrameIndex].delay * 10 / Math.abs(this.element.SpeedAnimation);

      // Skip 0 delay frames, for this the frame data renders empty
      if (currentFrameDelay !== 0) {
        this.ChangeVisibleImage(previousIndex, this._currentFrameIndex);
      }

      this._currentFrameIndex++;
      if (this._currentFrameIndex > (this.element.Frames.length - 1) || (this._currentFrameIndex < 0)) {
        this._currentFrameIndex = 0;
      }

      setTimeout(() => this.ngZone.runOutsideAngular(() => this.RunForward()), currentFrameDelay !== 0 ? currentFrameDelay : 30);
    } else {
      this._currentFrameIndex = 0;
      this._isRunning = false;
      this.ChangeVisibleImage(previousIndex, this._currentFrameIndex);
    }
  }

  public RunReverse(): void {
    if (this.element === undefined || !this.element.RunReverse) {
      return;
    }

    const previousIndex: number = this._currentFrameIndex === (this.element.Frames.length - 1) ? 0 : this._currentFrameIndex + 1;
    if (this.element.RunAnimation && this.element.SpeedAnimation !== 0) {
      this._isRunning = true;

      // delay field has 1/100 of a second.
      const currentFrameDelay: number = this.element.Frames[this._currentFrameIndex].delay * 10 / Math.abs(this.element.SpeedAnimation);

      // Skip 0 delay frames, for this the frame data renders empty
      if (currentFrameDelay !== 0) {
        this.ChangeVisibleImage(previousIndex, this._currentFrameIndex);
      }

      this._currentFrameIndex--;
      if (this._currentFrameIndex < 0 || this._currentFrameIndex > (this.element.Frames.length - 1)) {
        this._currentFrameIndex = this.element.Frames.length - 1;
      }

      setTimeout(() => this.ngZone.runOutsideAngular(() => this.RunReverse()), currentFrameDelay !== 0 ? currentFrameDelay : 30);
    } else {
      this._currentFrameIndex = 0;
      this._isRunning = false;
      this.ChangeVisibleImage(previousIndex, this._currentFrameIndex);
    }
  }

  public RemoveErrorFrame(frame: any): void {
    const indexToRemove: number = this.element.Frames.indexOf(frame);
    if (indexToRemove >= 0) {
      // Remove the error frame
      this.element.Frames.splice(indexToRemove, 1);

      // Reset the current frame index
      this._currentFrameIndex = 0;
    }
  }

  private ChangeVisibleImage(oldIndex: number, newIndex: number): void {

    if (this.element.GlobalDispose === 2) {
      this.element.Frames[oldIndex % this.element.Frames.length].visible = 'hidden';
      this.element.Frames[newIndex % this.element.Frames.length].visible = 'inherit';
      this.changeDetector.detectChanges();
    } else {
      // hide all frames after the new one
      const newFrameIndex: number = newIndex % this.element.Frames.length;
      let remove = false;
      for (let index: number = this.element.Frames.length - 1; index >= 0; index--) {
        const frame: any = this.element.Frames[index];

        if (index > newFrameIndex) {
          frame.visible = 'hidden';
        } else {
          frame.visible = remove ? 'hidden' : 'inherit';
          if (!remove && frame.disposal === 2) {
            remove = true;
          }
        }
      }
      this.changeDetector.detectChanges();
    }
  }
}

export enum RunDirection {
  Forward,
  Reverse
}
