import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { delay } from 'rxjs/operators';

import { OverrideMode, PropertyInfoData } from '../../interfaces/property';

@Injectable()
export class SiPropertyPopoverService {
  private subject = new Subject<any>();
  private validitySubject = new Subject<boolean>();
  private propertyInfoSubject = new Subject<PropertyInfoData>();
  private overrideMode?: OverrideMode;
  private isOpen = false;
  private invalidSet = new Set<any>();
  /**
   * Get/change container override mode.
   */
  containerOverrideMode?: OverrideMode;
  /**
   * Emits on popover open/close changes.
   *
   * @defaultValue this.subject.asObservable()
   */
  readonly stateChange$ = this.subject.asObservable();
  /**
   * Emits on validation state changes.
   *
   * @defaultValue this.validitySubject.pipe(delay(0))
   */
  readonly validityChange$ = this.validitySubject.pipe(delay(0)); // async to prevent ViewCheckError
  /**
   * Emits on property info dialog open.
   *
   * @defaultValue this.propertyInfoSubject.asObservable()
   */
  readonly propertyInfoDialog$ = this.propertyInfoSubject.asObservable();
  /**
   * Determine whether the popover can be opened.
   */
  canOpen(): boolean {
    return !this.isOpen || this.overrideMode === 'direct';
  }

  /**
   * Open the popover.
   */
  open(overrideMode: OverrideMode | undefined, caller: any): boolean {
    if (!this.canOpen()) {
      return false;
    }
    this.overrideMode = overrideMode ?? this.containerOverrideMode;
    this.isOpen = true;
    this.subject.next(caller);
    return true;
  }

  /**
   * Close the popover.
   */
  close(): void {
    this.isOpen = false;
    this.subject.next(null);
  }

  /**
   * Show the property info dialog.
   */
  showPropertyInfoDialog(selectPropertyInfo: PropertyInfoData): void {
    this.propertyInfoSubject.next(selectPropertyInfo);
  }

  /**
   * Update validation state.
   */
  setValidity(isValid: boolean, caller: any): void {
    if (isValid) {
      this.invalidSet.delete(caller);
    } else {
      this.invalidSet.add(caller);
    }
    this.validitySubject.next(this.invalidSet.size === 0);
  }
}
