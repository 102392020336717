import { NgClass } from '@angular/common';
import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input
} from '@angular/core';
import { SiTranslateModule, TranslatableString } from '@simpl/element-translate-ng/translate';

import { SiLoadingSpinnerComponent } from './si-loading-spinner.component';

@Component({
  selector: 'si-loading-button',
  templateUrl: './si-loading-button.component.html',
  styleUrl: './si-loading-button.component.scss',
  imports: [SiLoadingSpinnerComponent, NgClass, SiTranslateModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true
})
export class SiLoadingButtonComponent {
  /**
   * Whether the button is disabled.
   * @defaultValue false
   */
  @HostBinding('class.pe-none')
  @Input({ transform: booleanAttribute })
  disabled = false;
  /**
   * Whether the loading state should be displayed.
   * @defaultValue false
   */
  @Input({ transform: booleanAttribute }) loading = false;
  /**
   * Type of the button.
   * @defaultValue 'button'
   **/
  @Input() type: 'button' | 'submit' | 'reset' = 'button';
  /** aria-label for the button */
  @Input() ariaLabel?: TranslatableString;
  /** aria-labelledby for the button */
  @Input() ariaLabelledBy?: string;
  /**
   * CSS class for the button.
   * @defaultValue ''
   */
  @Input() buttonClass = '';

  protected handleClick(event: Event): void {
    if (this.disabled || this.loading) {
      event.stopPropagation();
    }
  }
}
