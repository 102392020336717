import { CdkOverlayOrigin, OverlayModule } from '@angular/cdk/overlay';
import {
  AfterContentInit,
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  HostBinding,
  inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { SI_FORM_ITEM_CONTROL, SiFormItemControl } from '@simpl/element-ng/form';
import { TranslatableString } from '@simpl/element-translate-ng/translate';

import { SiSelectComplexOptionsDirective } from './options/si-select-complex-options.directive';
import { SI_SELECT_OPTIONS_STRATEGY } from './options/si-select-options-strategy';
import { SiSelectInputComponent } from './select-input/si-select-input.component';
import { SiSelectListHasFilterComponent } from './select-list/si-select-list-has-filter.component';
import { SiSelectListComponent } from './select-list/si-select-list.component';
import { SiSelectSelectionStrategy } from './selection/si-select-selection-strategy';
import { SiSelectActionsDirective } from './si-select-actions.directive';
import { SiSelectGroupTemplateDirective } from './si-select-group-template.directive';
import { SiSelectOptionTemplateDirective } from './si-select-option-template.directive';
import { SelectGroup, SelectItem, SelectOptionNext } from './si-select.types';

@Component({
  selector: 'si-select',
  templateUrl: './si-select.component.html',
  styleUrl: './si-select.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    OverlayModule,
    SiSelectInputComponent,
    SiSelectListComponent,
    SiSelectListHasFilterComponent
  ],
  standalone: true,
  host: {
    class: 'dropdown'
  },
  providers: [{ provide: SI_FORM_ITEM_CONTROL, useExisting: SiSelectComponent }]
})
export class SiSelectComponent<T> implements AfterContentInit, SiFormItemControl, OnChanges {
  private static idCounter = 0;
  /**
   * Unique identifier.
   */
  @Input() id = `__si-select-${SiSelectComponent.idCounter++}`;
  /**
   * Aria label of the select.
   *
   * @defaultValue null
   */
  @Input() ariaLabel: string | null = null;
  /** Aria labelledby of the select. */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('labelledby') labelledbyInput?: string;
  /**
   * Placeholder for search input field.
   *
   * @defaultValue
   * ```
   * $localize`:@@SI_SELECT.SEARCH-PLACEHOLDER:Search...`
   * ```
   */
  @Input() filterPlaceholder = $localize`:@@SI_SELECT.SEARCH-PLACEHOLDER:Search...`;
  /**
   * Label if no item can be found.
   *
   * @defaultValue
   * ```
   * $localize`:@@SI_SELECT.NO-RESULTS-FOUND:No results found`
   * ```
   */
  @Input() noResultsFoundLabel = $localize`:@@SI_SELECT.NO-RESULTS-FOUND:No results found`;
  /** Placeholder text to display when no options are selected. */
  @Input() placeholder?: TranslatableString;
  /**
   * Readonly state. Similar to disabled but with higher contrast *
   *
   * @defaultValue false
   */
  @Input({ transform: booleanAttribute }) @HostBinding('class.readonly') readonly = false;

  /**
   * Emits on selection dropdown close.
   * @deprecated Use {@link openChange} instead.
   */
  @Output() readonly dropdownClose = new EventEmitter<void>();
  /** Emits when the dropdown open state changes. */
  @Output() readonly openChange = new EventEmitter<boolean>();

  @HostBinding('class.open') private _open = false;

  @ContentChild(SiSelectOptionTemplateDirective, { read: TemplateRef })
  protected optionTemplate?: TemplateRef<{ $implicit: SelectOptionNext<T> }>;

  @ContentChild(SiSelectGroupTemplateDirective, { read: TemplateRef })
  protected groupTemplate?: TemplateRef<{ $implicit: SelectGroup<T> }>;

  @ContentChild(SiSelectActionsDirective, { read: TemplateRef })
  protected actionsTemplate!: TemplateRef<any>;

  @ViewChild(CdkOverlayOrigin, { read: ElementRef, static: true })
  private trigger!: ElementRef<HTMLDivElement>;

  /** @internal */
  labelledby = this.id + '-label';

  protected rows: readonly SelectItem<T>[] = [];
  protected overlayWidth = 0;
  protected selectionStrategy = inject(SiSelectSelectionStrategy<T>);

  private backdropClicked = false;
  private selectOptions = inject(SI_SELECT_OPTIONS_STRATEGY);

  private _hasFilter = false;

  get hasFilter(): boolean {
    return this._hasFilter;
  }

  /**
   * Enables the filter input
   * @defaultValue false
   * @defaultref {@link SiSelectComponent#_hasFilter}
   */
  @Input()
  @HostBinding('class.si-select-has-filter')
  set hasFilter(value: boolean | '') {
    this._hasFilter = booleanAttribute(value);
    if (this._hasFilter) {
      this.verifyValueProvider();
    }
  }

  /** Whether the current dropdown is open. */
  get open(): boolean {
    return this._open;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.labelledbyInput || changes.id) {
      this.labelledby = this.labelledbyInput ?? this.id + '-label';
    }
  }

  ngAfterContentInit(): void {
    this.verifyValueProvider();
  }

  protected openListBox(): void {
    if (this.readonly || this.selectionStrategy.disabled) {
      return;
    }
    this.overlayWidth = this.trigger.nativeElement.getBoundingClientRect().width + 2; // 2px border
    this._open = true;
    this.openChange.emit(true);
  }

  protected overlayDetach(): void {
    this._open = false;
    if (!this.backdropClicked) {
      this.trigger.nativeElement.focus();
    } else {
      this.backdropClicked = false;
      this.selectionStrategy.onTouched();
    }
    this.dropdownClose.emit();
    this.openChange.emit(false);
  }

  protected backdropClick(): void {
    this.backdropClicked = true;
    this._open = false;
  }

  private verifyValueProvider(): void {
    if (
      this.hasFilter &&
      this.optionTemplate &&
      this.selectOptions instanceof SiSelectComplexOptionsDirective &&
      !this.selectOptions.valueProvider
    ) {
      console.error(
        'A valueProvider is required when [hasFilter]="true" and having custom option template on si-select'
      );
    }
  }
}
