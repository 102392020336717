<!-- Template to be used for every match, can be replaced using an input. -->
<ng-template #defaultItemTemplate let-match="match" siTypeaheadTemplate>
  @if (multiselect) {
    <div class="d-flex pe-4" aria-hidden="true">
      <span class="form-check-input si-form-checkbox" [class.checked]="match.itemSelected"></span>
    </div>
  }
  @if (match.iconClass) {
    <span class="icon me-2" aria-hidden="true" [ngClass]="match.iconClass"></span>
  }
  @for (segment of match.result; track $index) {
    <span [class.typeahead-match-segment-matching]="segment.isMatching">{{ segment.text }}</span>
  }
</ng-template>

<!-- Only display the component if there are any matches and set the CSS transform to properly position the typeahead -->
<ul
  #typeahead
  class="typeahead dropdown-menu"
  [siAutocompleteListboxFor]="autocompleteDirective"
  [siAutocompleteDefaultIndex]="parent.typeaheadAutoSelectIndex"
  [attr.aria-label]="parent.typeaheadAutocompleteListLabel"
  [class.d-none]="!matches().length"
  (siAutocompleteOptionSubmitted)="selectMatch($event)"
>
  <!-- Loop through every match and bind events, the mousedown prevent default is to prevent the host from losing focus on click -->
  @for (match of matches(); track index; let index = $index) {
    <li
      #typeaheadMatch
      class="dropdown-item me-4"
      [siAutocompleteOption]="match"
      [attr.aria-label]="match.text"
      [attr.aria-selected]="multiselect ? match.itemSelected : null"
      (click)="$event.stopPropagation()"
      (mousedown)="$event.preventDefault()"
    >
      <!-- Display either a template set as the input or the template above -->
      <ng-template
        [ngTemplateOutlet]="parent.typeaheadItemTemplate || defaultItemTemplate"
        [ngTemplateOutletContext]="{
          item: match.option,
          index: index,
          match: match,
          query: parent.query()
        }"
      />
    </li>
  }
</ul>
