import { Command, CommandInput, GmsSubscription, PropertyCommand, PropertyDetails,
  PropertyInfo, ValidationCommandInfo,
  ValidationResult, ValidationResultStatus, ValueDetails } from '@gms-flex/services';
import { isNullOrUndefined, TraceService } from '@gms-flex/services-common';
import { BulkProperty, BulkPropertyValues } from '@simpl/buildings-ng';
import { AnyEnumValue, BooleanValue, NumberValue } from '@simpl/element-value-types';
import { Observable, Observer, Subject, Subscription, throwError } from 'rxjs';
import { debounceTime, mergeMap } from 'rxjs/operators';

import { Common } from '../shared/common';
import { BulkCommandInvoc, CommandInvoc, CommandInvocState, SubCommandInvoc } from './command-invoc-vm';
import { CommandViewModel } from './command-vm';
import { PropertyValueType, WsiTranslator } from './data-model-helper';
import { PropertyDefinition } from './property-definition-vm';
import { ObjectPropertyEventType, ObjectPropertyInstance } from './property-instance-single-vm';
import { PropertyInstance } from './property-instance-vm';
import { AggregateValueState, PropertyCommandResult } from './property-vm.types';
import { ServiceStore, ViewModelContext } from './snapin-vm.types';

/**
 * Aggregate property (multiple object) view-model.
 */
export class AggregatePropertyInstance extends PropertyInstance {

  public siBulkPropertyDesc: BulkProperty;
  public siBulkProperty: BulkPropertyValues;

  private instanceList: ObjectPropertyInstance[];
  private instanceEventSubscriptionList: Subscription[];
  private valState: AggregateValueState;
  private valStateArr: AggregateValueState[];

  private cmdState: CommandInvocState = CommandInvocState.Initial;
  private readonly activeInvocationList: CommandInvoc[];

  private defAligned: boolean;
  private maxResolution: number;

  private activationInProgress: boolean;
  private objFilter: string;
  private readonly objFilterChangedInd: Subject<void>;

  public get isAggregate(): boolean {
    return true;
  }

  public get instances(): readonly ObjectPropertyInstance[] {
    return this.instanceList || [];
  }

  public get isActive(): boolean {
    return this.valState !== AggregateValueState.Inactive;
  }

  /**
   * String used to filter the items shown in the object-instances list.
   */
  public get objectFilter(): string {
    return this.objFilter || '';
  }

  public set objectFilter(filter: string) {
    if (this.objFilter === filter) {
      return;
    }
    this.objFilter = filter || undefined;
    this.objFilterChangedInd.next();
  }

  /**
   * Property value as a string.
   * For aggregate properties, this can only be reliably reported if the value is aligned
   * with all object-property values in the aggregation (by value and property definition).
   */
  public get value(): string {
    return this.valueState === AggregateValueState.Aligned ? this.val : undefined;
  }

  /**
   * State of the aggregate value.
   */
  public get valueState(): AggregateValueState {
    let s: AggregateValueState = this.valState;
    if (s !== AggregateValueState.Inactive && !this.defAligned) {
      // Force state to misaligned if there is some significant misalignment in one or more
      // underlying property definitions
      s = AggregateValueState.Misaligned;
    }
    return s;
  }

  /**
   * Formatted array property values.
   */
  public get valueArr(): readonly string[] {
    return this.valueState !== AggregateValueState.Inactive ? this.valArr : undefined;
  }

  /**
   * State of each aggregate array element value independently.
   */
  public get valueStateArr(): AggregateValueState[] {
    return this.valStateArr;
  }

  /**
   * Flag indicating if the aggregate value is in fault.
   * If ALL associated object-properties are in fault, then the aggregate is considered in fault.
   */
  public get valueFault(): boolean {
    return this.instances.length > 0 && this.instances.every(inst => inst.valueFault);
  }

  /**
   * Indicates if the property has been marked "absent" by the driver in a COV.
   * Indexed properties are to be hidded for multi-selections, so "absent" is always TRUE
   */
  public get isAbsent(): boolean {
    if (this.def.isIndexed) {
      return true;
    } else {
      return this.instances.length > 0 && this.instances.every(inst => inst.isAbsent);
    }
  }

  public get isHiddenByState(): boolean {
    return this.instances.length > 0 && this.instances.every(inst => inst.isHiddenByState);
  }

  /**
   * Property command state.
   */
  public get commandState(): CommandInvocState {
    return this.cmdState;
  }

  /**
   * Indicates if any commands are pending for the property.
   */
  public get isCommandPending(): boolean {
    return this.activeInvocationList.some(i => !i.isComplete);
  }

  /**
   * Constructor.
   */
  constructor(
    traceService: TraceService,
    svc: ServiceStore,
    vmContext: ViewModelContext,
    defBase: PropertyDefinition,
    cmdListBase: Command[]) {

    super(traceService, svc, vmContext, defBase, cmdListBase);

    this.siBulkPropertyDesc = {
      id: defBase.propertyId,
      name: defBase.description
    };
    this.siBulkProperty = {
      aggregate: this.siProperty,
      objectValues: []
    };
    this.valState = AggregateValueState.Inactive;
    this.activationInProgress = false;
    this.instanceEventSubscriptionList = [];

    this.cmdState = CommandInvocState.Initial;
    this.activeInvocationList = [];

    // Subscribe for changes to the object-instance list filter setting
    this.objFilterChangedInd = new Subject<void>();
    this.objFilterChangedInd
      .pipe(
        debounceTime(50))
      .subscribe(
        () => {
          this.updateFilteredInstanceList();
        });
  }

  /**
   * Activate the aggregate property.
   * Has the effect of reading all associated object-property instances, subscribing for values,
   * and reading/subscribing for commands.
   */
  public activate(): void {
    if (this.activationInProgress || this.valState !== AggregateValueState.Inactive) {
      return;
    }

    this.valState = AggregateValueState.Misaligned;

    if (isNullOrUndefined(this.instanceList)) {
      this.activationInProgress = true; // activation will require async calls

      // First time through, establish object-property list
      this.instanceList = [];
      this.siBulkProperty.objectValues = [];
      this.instanceEventSubscriptionList = [];
      this.vmContext.browserObjList.forEach(bo => {
        const inst: ObjectPropertyInstance = new ObjectPropertyInstance(
          this.traceService,
          this.svc,
          this.vmContext,
          this.defBase,
          this.cmdListBase,
          bo
        );
        this.instanceList.push(inst);
        this.siBulkProperty.objectValues.push({
          objectId: inst.objectId,
          property: inst.siProperty
        });
      });

      // Read property meta-data
      this.readProperties();
    } else {
      this.subscribeObjectPropertyEvents();
      this.subscribeValues();
      this.subscribeCommands();
    }
  }

  /**
   * Deactivate the aggregate property.
   * Unsubscribe all value and commands, but keep all definition data.
   */
  public deactivate(): void {
    this.valState = AggregateValueState.Inactive;
    this.unsubscribeObjectPropertyEvents();
    this.unsubscribeValues();
    this.unsubscribeCommands();
  }

  public resetDisplayValue(): void {
    this.traceService.debug(this.trmod, 'Reset aggregate property display value: pid=%s', this.def.propertyId);
    this.evaluateAggregateValue(); // force re-processing of aggregate-value
  }

  /**
   * Re-evaluate object display labels.
   */
  public updateObjectLabels(): void {
    if (this.instanceList) {
      this.instanceList.forEach(inst => inst.updateObjectLabels());
      this.updateFilteredInstanceList();
    }
  }

  public updateCommandList(): void {
    // Update aggregate command list based on command lists of all subordinate
    // object-property instances.
    this.cmdListBase.forEach(cmdBase => {
      const cmdId: string = cmdBase.Id;

      // Aggregate-property instance command VM
      let cmdVmAg: CommandViewModel = this.cmdVmList.find(vm => vm.id === cmdId);
      if (!cmdVmAg) {
        cmdVmAg = new CommandViewModel(this.traceService, this.vmContext, undefined, this.def, cmdBase);
        this.cmdVmList.push(cmdVmAg); // new command encountered
      }

      // Set active state of this aggregate command based on the active state
      // of the same command across all subordinate object-property instances.
      if (cmdVmAg.isSupported) {
        let active = false;
        for (let idx = 0; this.instanceList && idx < this.instanceList.length; ++idx) {
          const inst: ObjectPropertyInstance = this.instanceList[idx];
          const cmdVmInst: CommandViewModel = inst.commandListInternal.find(vm => vm.isEnabled && vm.id === cmdId);
          if (cmdVmInst) {
            if (!active) {
              cmdVmAg.updateCommand(cmdVmInst.cmdRaw);
              active = true;
            } else {
              active = cmdVmAg.alignCommand(cmdVmInst);
              if (!active) {
                // This command cannot be aligned across all object-properties; it will be disabled!
                this.traceService.warn(this.trmod,
                  'Command incompatibility found in aggregated object-property; command will be disabled: cmdId=%s, propId=%s',
                  cmdId, inst.objectAndPropertyId);
                break;
              }
            }
          }
        }
        cmdVmAg.isActive = active;
      }
    });

    this.updateSiPropertyValueCommands();
  }

  /**
   * Clear completed property command state.
   */
  public clearCompletedCommandState(clearAllInstances?: boolean): void {
    if (this.isCommandPending) {
      return; // do not reset state if command results are outstanding!
    }
    this.cmdState = CommandInvocState.Initial;
    if (this.instanceList) {
      this.instanceList.forEach(inst => inst.clearCompletedCommandState(clearAllInstances));
    }
  }

  protected executeCommandInternal(commandVm: CommandViewModel, commandArgs: CommandInput[], confirmObjectIds?: string[]): Observable<PropertyCommandResult> {
    if (!commandVm) {
      return throwError(new Error('invalid command'));
    }

    const showValidation: Observable<ValidationResult> = new Observable<ValidationResult>(observer => {
      const validationSubscription = this.svc.validationDialogService.show(validationCommandInfo).subscribe((result: ValidationResult) => {
        observer.next(result);
        observer.complete();
        validationSubscription.unsubscribe();
      });
    });

    // Validate command operation
    const propertyIds: string[] = [];
    this.instances.forEach(instance => propertyIds.push(instance.objectAndPropertyId));
    const validationCommandInfo: ValidationCommandInfo = new ValidationCommandInfo(propertyIds, commandVm.groupNumber);

    return showValidation.pipe(
      mergeMap((result: ValidationResult) => {
        if (result.Status === ValidationResultStatus.Success) {

          // Update command input with validation result
          if (commandArgs.length > 0) {
            commandArgs.forEach(commandInput => {
              commandInput.Password = result.Password;
              commandInput.SuperName = result.SuperName;
              commandInput.SuperPassword = result.SuperPassword;
              commandInput.Comments = result.Comments;
              commandInput.SessionKey = result.SessionKey;
            });
          } else {
            const commandInput: CommandInput = {
              Name: undefined,
              DataType: undefined,
              Value: undefined,
              Password: result.Password,
              SuperName: result.SuperName,
              SuperPassword: result.SuperPassword,
              SessionKey: result.SessionKey,
              Comments: result.Comments
            };

            commandArgs.push(commandInput);
          }

          // Update the command input with validation info
          return this.execute(commandVm, commandArgs, confirmObjectIds);
        } else if (result.Status === ValidationResultStatus.Cancelled) {
          return throwError(this.svc.validationDialogService.ValidationCancelled);
        } else {
          this.traceService.info(this.trmod, 'Validate command op: Status: %s, Error: %s', ValidationResultStatus[result.Status], result.Error);
          return throwError(result.Error);
        }
      }));
  }

  private execute(commandVm: CommandViewModel, commandArgs: CommandInput[], confirmObjectIds?: string[]): Observable<PropertyCommandResult> {
    return new Observable<PropertyCommandResult>(
      (o: Observer<PropertyCommandResult>) => {
        // Create sub-command invocation object for all object-properties that have this
        // command enabled; object-ids of all other object-properties (i.e., those that will NOT
        // be commanded) will be recorded in the non-command list.
        const subCommandList: SubCommandInvoc[] = [];
        const nonCommandList: string[] = [];
        this.instanceList.forEach(inst => {
          if (inst.commandListInternal.some(vm => vm.isEnabled && vm.id === commandVm.id)) {
            subCommandList.push(inst.createSubCommandInvoc(commandVm));
          } else {
            nonCommandList.push(inst.objectId);
          }
        });
        // For all instance properties NOT being commanded, send an immediate "success" status.
        // We do this to ensure the properties NOT being commanded are not left with a "in-progress" icon
        // in the UI (since the UI component sets this all on its own--assumes all instances of aggregate will be commanded!)
        // IMPROVEMENT: Consider an update to the si-property-viewer to support a "not commanded" status, which
        //  would allow the "in-progress" icon to be removed without also replacing it with a green-check.
        if (confirmObjectIds) {
          nonCommandList.forEach(oid => {
            const idx: number = confirmObjectIds.findIndex(item => item === oid);
            if (idx >= 0) {
              o.next(new PropertyCommandResult( // simulated "success" indication
                oid,
                this.def.propertyId,
                true
              ));
              confirmObjectIds.splice(idx, 1);
            }
          });
        }
        // Listen for state changes to all sub-commands to be issued through the single bulk request.
        // Report status of each on completion
        subCommandList.forEach(subinvoc => {
          subinvoc.stateChanged.subscribe(
            () => {}, // we don't care about intermediate state change indications, only the completion
            err => {}, // does not emit error
            () => {
              // Report status whether in the optional confirm set or not!
              const oid: string = this.def.objectIdFromObjectPropertyId(subinvoc.objectAndPropertyId);
              o.next(new PropertyCommandResult( // simulated "success" indication
                oid,
                this.def.propertyId,
                subinvoc.state === CommandInvocState.Success,
                subinvoc.error
              ));
              if (confirmObjectIds) {
                const idx: number = confirmObjectIds.findIndex(item => item === oid);
                if (idx >= 0) {
                  confirmObjectIds.splice(idx, 1);
                }
              }
            });
        });
        // Finally, kick-off the bulk command operation
        this.clearCompletedCommandState(true);

        const invoc: BulkCommandInvoc = new BulkCommandInvoc(subCommandList, commandVm.id, commandArgs);
        this.addActiveInvoc(invoc);

        invoc.execute(this.svc.execCommandService).subscribe(
          () => {
            this.updateCommandState(invoc);
          },
          err => {}, // does not emit error
          () => {
            this.removeActiveInvoc(invoc);
            // Before reporting the general success/failure of the bulk command, ensure that
            // any remaining objectIds in the "confirm" list are reported as errors!
            if (confirmObjectIds) {
              confirmObjectIds.forEach(oid => {
                o.next(new PropertyCommandResult( // simulated "failure" indication
                  oid,
                  this.def.propertyId,
                  false
                ));
              });
            }
            // Works a little differently than the single-instance command.
            // Here we report success by simply indicating completion of the observable;
            // Error is reported by the observable emitting an error
            if (invoc.state === CommandInvocState.Success) {
              o.complete();
            } else {
              o.error(new Error(invoc.error));
            }
          });
      });
  }

  /**
   * Property command state is an aggregate state applied to the property as a whole
   * based on execution and completion states of individual commands that may potentially
   * be executing concurrently.
   * The property command state is managed as follows:
   * - When a new command is executed, the state is set to `Executing`
   * - When a command completes with an error, the state us set to `Error`
   * - When a command completes successfully, the state is set to `Success` only if:
   *    (1) there are no outstanding commands, and
   *    (2) the current state is not `Error`
   *
   * The state can be reset to `Initial` through an explicit call to clearCommandState.
   */
  private updateCommandState(invoc: CommandInvoc): void {
    if (!invoc) {
      return;
    }
    if (invoc.isComplete) {
      if (invoc.state === CommandInvocState.Error) {
        this.cmdState = CommandInvocState.Error; // error
      } else {
        if (!this.isCommandPending && this.cmdState !== CommandInvocState.Error) {
          this.cmdState = CommandInvocState.Success; // success
        }
      }
    } else {
      this.cmdState = CommandInvocState.Executing; // in progress...
    }
  }

  /**
   * Add a new invocation object to the active list.
   */
  private addActiveInvoc(invoc: CommandInvoc): void {
    if (invoc) {
      this.activeInvocationList.push(invoc);
    }
  }

  /**
   * Remove an invocation object from the active list.
   */
  private removeActiveInvoc(invoc: CommandInvoc): void {
    const pos: number = this.activeInvocationList.findIndex(i => i === invoc);
    if (pos >= 0) {
      this.activeInvocationList.splice(pos, 1); // remove the element at index `pos`
    }
  }

  /**
   * Set the hidden-by-filter flag on all properties in both std and ext property lists.
   */
  private updateFilteredInstanceList(): void {
    if (!this.instanceList) {
      return;
    }
    const filter: string = this.objectFilter ? this.objectFilter.toLocaleLowerCase() : undefined;
    this.instanceList.forEach(inst => {
      let hidden = false;
      if (filter) {
        hidden = !inst.objectLabel.toLocaleLowerCase().includes(filter);
      }
      inst.isHiddenByFilter = hidden;
    });
  }

  /**
   * Read all properties associated with this aggregate set of objects.
   */
  private readProperties(): void {
    const pidList: string[] = this.instanceList.map(inst => inst.objectAndPropertyId);

    this.traceService.info(this.trmod, 'Read property attributes for all object-properties in aggregate');
    this.svc.propertyService.readPropertiesMulti(pidList, 3, false).subscribe(
      resp => this.readPropertiesMultiCallback(resp),
      err => {
        this.traceService.error(this.trmod, 'Read properties multi error: %s', err);
        this.activationInProgress = false;
      }
    );
  }

  /**
   * Process read property multi response.
   */
  private readPropertiesMultiCallback(resp: PropertyInfo<PropertyDetails>[]): void {
    this.traceService.info(this.trmod, 'Read properties multi response: propertySets(#dps)=%s',
      resp ? resp.length : 0);

    // Evaluate property definition information for each object-property returned.
    // For any that are significantly different from the "base" property definition, attach them
    // as local definition information directly to the object-property instance.
    if (resp) {
      this.defAligned = true;
      resp.forEach(pinfo => {
        // NOTE: This KLUDGE is needed because the WSI fills in the "ObjectId" field of the response
        //  differently depending on whether there is an error condition.  On error, the property name
        //  will be included (which we then need to remove); on success, it will not.
        let pinfoOid: string = PropertyDefinition.trimSystemName(pinfo.ObjectId);
        if (pinfo.ErrorCode !== 0) {
          const pos: number = pinfoOid.lastIndexOf('.' + this.def.propertyId);
          if (pos >= 0) {
            pinfoOid = pinfoOid.substring(0, pos);
          }
        }

        const inst: ObjectPropertyInstance =
          this.instanceList.find(i => PropertyDefinition.trimSystemName(i.objectId) === pinfoOid);
        if (inst) {
          if (pinfo.ErrorCode === 0) {
            let pd: PropertyDetails;

            // Extract property definition from response
            const isFunc: boolean = this.def.isFunctionProperty;
            if (isFunc) {
              pd = pinfo.FunctionProperties && pinfo.FunctionProperties.length > 0 ? pinfo.FunctionProperties[0] : undefined;
            } else {
              pd = pinfo.Properties && pinfo.Properties.length > 0 ? pinfo.Properties[0] : undefined;
            }

            // Align property definition for this instance with the base property def
            if (!this.alignPropertyDefinition(inst, pd, isFunc, this.def.isDefaultProperty)) {
              this.defAligned = false;
              this.traceService.info(this.trmod, 'Property definition misaligned with aggregate set: %s', inst.objectAndPropertyId);
            }
          } else {
            this.traceService.warn(this.trmod,
              'Failed to read object-property attributes (will be interpreted as no privilege): id=%s, pid=%s, errorCode=%s',
              pinfo.ObjectId, this.def.propertyId, pinfo.ErrorCode);
            inst.isAccessible = false;
          }
        }
      });
    }

    // Define the maximum resolution setting for all properties in the aggregate set
    const allRes: number[] = this.instanceList
      .map(i => i.def.resolution)
      .filter(n => !isNaN(n));
    this.maxResolution = Math.max(...allRes, 0);

    this.readCommands();
  }

  private readCommands(): void {
    const instanceList: ObjectPropertyInstance[] = this.instanceList.filter(i => i.isAccessible);
    const pidList: string[] = instanceList.map(inst => inst.objectAndPropertyId);

    this.traceService.info(this.trmod, 'Read commands for all object-properties in aggregate');
    this.svc.readCommandService.readPropertyCommands(pidList, undefined, false, undefined).subscribe(
      resp => this.readCommandsMultiCallback(instanceList, resp),
      err => {
        this.traceService.error(this.trmod, 'Read commands error: %s', err);
        this.activationInProgress = false;
      }
    );
  }

  private readCommandsMultiCallback(instanceList: ObjectPropertyInstance[], resp: PropertyCommand[]): void {
    this.traceService.info(this.trmod, 'Read commands multi response: commandSets(#dps)=%s',
      resp ? resp.length : 0);

    // Update the list of base commands (list shared by all subordinate object-property instance)
    // to represent the union of all commands read across all object instances.
    // NOTE: This operation is required because it is possible that some objects are not under the
    //  current user's "ownership" in which case it will have an empty command list.  And since
    //  we do not know the ownership status of each object, we cannot rely on just one to establish
    //  the list of base commands for all.
    //  The need for this operation will go away in DCC platform MP50 when the behavior of the command
    //  COV notifications in WSI is changed to provide all commands for a given property with an
    //  enable/disable flag for each.
    if (resp) {
      resp.forEach((propCmd, idx) => {
        if (propCmd.ErrorCode === 0) {
          if (propCmd.Commands && propCmd.Commands.length > 0) {
            propCmd.Commands.forEach(cmd => {
              if (!this.cmdListBase.find(cmdBase => cmdBase.Id === cmd.Id)) {
                this.cmdListBase.push(cmd);
              }
            });
          } else {
            // No commands returned for this object-property instance.
            // Mark it as not commandable.
            instanceList[idx].isCommandable = false;
          }
        } else {
          this.traceService.error(this.trmod, 'Failed to read object-property command: errorCode=%s', propCmd.ErrorCode);
        }
      });
    }

    this.activationInProgress = false; // completed async calls

    // Subscribe for values and commands for all object-properties (as long as the VM was not
    // deactivated while waiting for the read response)
    if (this.valState !== AggregateValueState.Inactive) {
      this.subscribeObjectPropertyEvents();
      this.subscribeValues();
      this.subscribeCommands();
    }
  }

  private alignPropertyDefinition(inst: ObjectPropertyInstance, pd: PropertyDetails, isFunc: boolean, isDefault: boolean): boolean {
    let isAligned = true;
    const localDef: PropertyDefinition = new PropertyDefinition(this.vmContext, pd, isFunc, isDefault);

    // Check engineering unit alignment
    if (inst.def.hasEngineeringUnits !== localDef.hasEngineeringUnits ||
      inst.def.engineeringUnits !== localDef.engineeringUnits) {

      inst.overridePropertyDefinition(localDef);
      isAligned = false; // cannot aggregate values for the object-property set!

      this.traceService.warn(this.trmod, 'Incompatible engineering units in aggregation: %s', inst.objectAndPropertyId);
    } else if (inst.def.valueType === PropertyValueType.DurationValue &&
      inst.def.durationValueUnits !== localDef.durationValueUnits) {

      inst.overridePropertyDefinition(localDef);
      isAligned = false; // cannot aggregate values for the object-property set!

      this.traceService.warn(this.trmod, 'Incompatible duration value units in aggregation: %s', inst.objectAndPropertyId);
    } else if (inst.def.valueType === PropertyValueType.NumericValue &&
      inst.def.resolution !== localDef.resolution) {

      inst.overridePropertyDefinition(localDef); // need to track difference; can still aggregate values
    }

    return isAligned;
  }

  private subscribeObjectPropertyEvents(): void {
    // Register local handler to be called on each object-property event (monitors cov and command activity)
    // in object-property set.
    if (this.instanceList) {
      this.instanceList.forEach(inst => {
        const s: Subscription = inst.objectPropertyEvent.subscribe(args => {
          if (args) {
            switch (args.eventType) {
              case ObjectPropertyEventType.ValueChanged:
                this.onValueChanged(args.objectPropertyInstance);
                break;
              case ObjectPropertyEventType.CommandChanged:
                this.onCommandChanged(args.objectPropertyInstance);
                break;
              case ObjectPropertyEventType.CommandExecuted:
                this.onObjectPropertyCommandExecuted(args.objectPropertyInstance);
                break;
              default:
                break;
            }
          }
        });
        this.instanceEventSubscriptionList.push(s);
      });
    }
  }

  private unsubscribeObjectPropertyEvents(): void {
    if (this.instanceEventSubscriptionList) {
      this.instanceEventSubscriptionList.forEach(s => s.unsubscribe());
      this.instanceEventSubscriptionList = [];
    }
  }

  /**
   * Subscribe for value changes for all properties associated with this aggregage set of objects.
   */
  private subscribeValues(): void {
    const propList: ObjectPropertyInstance[] = this.instanceList.filter(inst => inst.isAccessible);
    if (propList.length > 0) {
      this.traceService.info(this.trmod, 'Subscribe values: property count=%s', propList.length);

      // Subscribe for property value changes
      const valueSubscriptionArr: GmsSubscription<ValueDetails>[] =
        this.svc.valueSubscriptionService.subscribeValues(propList.map(p => p.objectAndPropertyId), this.vmContext.valueSubscriptionReg);

      // Update each object-property-instance VM with its associated value subscription
      if (valueSubscriptionArr && valueSubscriptionArr.length === propList.length) {
        propList.forEach((p, idx) => p.updateValueSubscription(valueSubscriptionArr[idx]));
      } else {
        this.traceService.error(this.trmod, 'Subscribe values: invalid response');
      }
    }
  }

  /**
   * Unsubscribe value changes for all properties associated with this aggregage set of objects.
   */
  private unsubscribeValues(): void {
    // Collect all open property subscriptions
    const valueSubscriptionList: GmsSubscription<ValueDetails>[] = [];
    if (this.instanceList) {
      this.instanceList.forEach(inst => {
        const vs: GmsSubscription<ValueDetails> = inst.updateValueSubscription(undefined);
        if (vs) {
          valueSubscriptionList.push(vs);
        }
      });
    }

    // Unsubscribe all properties
    if (valueSubscriptionList.length > 0) {
      this.traceService.info(this.trmod, 'Unsubscribe values: property count=%s', valueSubscriptionList.length);
      this.svc.valueSubscriptionService.unsubscribeValues(valueSubscriptionList, this.vmContext.valueSubscriptionReg);
    }
  }

  /**
   * Handler called when an object-property instance value changes.
   */
  private onValueChanged(opInstance: ObjectPropertyInstance): void {
    this.traceService.debug(this.trmod, 'Received value changed ind: designation=%s, pid=%s', opInstance.browserObj.Designation, this.def.propertyId);

    this.evaluateAggregateValue();

    this.traceService.debug(this.trmod, 'Updated aggregate: ag-pid=%s, si-property=%s', this.def.propertyId, Common.toStringProperty(this.siProperty));
  }

  private evaluateAggregateValue(): void {
    if (this.def.isArray) {
      this.evaluateAggregateValueArray();
    } else {
      this.evaluateAggregateValueSimple();
    }
  }

  private evaluateAggregateValueSimple(): void {
    if (!this.instanceList || this.instanceList.length === 0) {
      return;
    }

    // Compare first instance against all the rest
    let isEqual = true;
    let instBase: ObjectPropertyInstance;
    for (let i = 0; i < this.instanceList.length && isEqual; i++) {
      const inst: ObjectPropertyInstance = this.instanceList[i];
      if (!inst.isAccessible || inst.isAbsent) {
        continue; // do not evaluate inaccessible or absent properties!
      }
      if (!instBase) {
        instBase = inst; // first accessible instance is used as the base
        continue;
      }

      isEqual = (instBase.valueFault === inst.valueFault);
      if (isEqual && !instBase.valueFault) {

        const x: ValueDetails = instBase.valueRaw;
        const y: ValueDetails = inst.valueRaw;

        const xValue: string = x?.Value ? x.Value.Value : undefined;
        const yValue: string = y?.Value ? y.Value.Value : undefined;
        switch (this.def.valueType) {
          case PropertyValueType.NumericValue:
          case PropertyValueType.DurationValue:
            isEqual = Common.isEqualValueNumeric(xValue, yValue, this.maxResolution);
            break;
          case PropertyValueType.Bitstring32Value:
          case PropertyValueType.Bitstring64Value:
            isEqual = this.bitstringCompare(xValue, yValue);
            break;
          case PropertyValueType.Integer64Value:
          case PropertyValueType.UnsignedInteger64Value:
          case PropertyValueType.BACnetDateTimeValue:
          case PropertyValueType.DateTimeValue:
          case PropertyValueType.StringValue:
          case PropertyValueType.EnumeratedValue:
          default:
            isEqual = (xValue === yValue);
            break;
        }
      }
    }

    // create the value block if required
    if (!this.siProperty.value) {
      this.siProperty.value = PropertyInstance.createSiValue(this.def);
      this.updateSiPropertyValueCommands();
    }
    const defaultCmd: CommandViewModel = this.commandListInternal.find(cmd => cmd.isDefault);
    this.siProperty.value.readonly = defaultCmd ? !defaultCmd.isEnabled : true;

    this.siProperty.defaultText = undefined;
    if (isEqual && instBase) {
      // All object-properties in the aggregate set are alike
      this.valState = AggregateValueState.Aligned;
      if (instBase.valueFault) {
        this.siProperty.defaultText = '#COM';
        this.siProperty.value = undefined;
      } else {
        let inst: ObjectPropertyInstance = instBase;
        if (instBase.def.valueType === PropertyValueType.NumericValue) {
          inst = this.instanceList.find(i => i.isAccessible && i.def.resolution === this.maxResolution) || instBase;
        }
        this.val = inst.value;
        this.siProperty.value.value = inst.siProperty.value.value;
        this.siProperty.value.altText = undefined;

        // Additional special processing for certain types
        switch (inst.def.valueType) {
          case PropertyValueType.NumericValue:
            if (this.siProperty.value.type === 'number' && inst.siProperty.value.type === 'number') {
              const nv: NumberValue = this.siProperty.value as NumberValue;
              const nvBase: NumberValue = inst.siProperty.value as NumberValue;
              nv.resolution = nvBase.resolution;
            }
            break;

          case PropertyValueType.BooleanValue:
            if (this.siProperty.value.type === 'boolean' && inst.siProperty.value.type === 'boolean') {
              const bv: BooleanValue = this.siProperty.value as BooleanValue;
              const bvBase: BooleanValue = inst.siProperty.value as BooleanValue;
              bv.options = bvBase.options.slice(0);
            }
            break;

          case PropertyValueType.EnumeratedValue:
            if (this.siProperty.value.type === 'enum' && inst.siProperty.value.type === 'enum') {
              const ev: AnyEnumValue = this.siProperty.value as AnyEnumValue;
              const evBase: AnyEnumValue = inst.siProperty.value as AnyEnumValue;
              ev.options = evBase.options.slice(0);
            }
            break;

          default:
            break;
        }
      }
    } else {
      // All object-properties in the aggregate set are NOT alike
      this.valState = AggregateValueState.Misaligned;
      this.siProperty.value.value = undefined; // siProperty.altText will be used to display value
      this.siProperty.value.altText = '*';
    }
  }

  private evaluateAggregateValueArray(): void {
    if (!this.instanceList || this.instanceList.length === 0) {
      return;
    }

    this.valStateArr = [];
    this.valActiveArr = [];
    this.valArr = undefined;

    // Assume aggregate array size to be the largest of the array sizes of the associated
    // object-property instances
    let size = 0;
    for (const instance of this.instanceList) {
      if (!instance.isAccessible || instance.isAbsent) {
        continue; // do not consider inaccessible or absent properties!
      }
      size = Math.max(size, instance.sizeArray);
    }

    if (size > 0) {
      this.sizeArr = size;
    }

    // Determine whether to use a numeric (or string) value comparison on array elements
    let isNumericCompare = false;
    if (this.def.valueType === PropertyValueType.NumericValue ||
      this.def.valueType === PropertyValueType.DurationValue) {

      isNumericCompare = true;
    }

    // Set each element in aggregate value array based on the alignment/misalignment of the matching
    // elements in the associated object-property instances.
    // Elements that are marked "inactive" in all object-property instances are consider "aligned"
    // because there are no values to compare (all undefined).
    let instBase: ObjectPropertyInstance;
    let xArr: string[];

    for (const instance of this.instanceList) {
      if (!instance.isAccessible) {
        continue; // do not evaluate inaccessible properties!
      }
      if (!instBase) {
        instBase = instance; // first accessible instance is used as the base
        xArr = this.getArrayValue(instBase, isNumericCompare);
        for (let el = 0; el < size; el++) {
          this.valActiveArr.push(Boolean(instBase.valueActiveArr?.[el]));
          this.valStateArr.push(AggregateValueState.Aligned); // assume all elements aligned to start
        }
        continue;
      }

      // Compare base instance against all the rest
      const yArr: string[] = this.getArrayValue(instance, isNumericCompare);

      for (let el = 0; el < size; el++) {

        // Element active flag
        if (instance.valueActiveArr?.[el]) {
          this.valActiveArr[el] = true;
        }

        // Element value
        if (this.valStateArr[el] === AggregateValueState.Aligned) {
          let isEqual: boolean;
          if (!xArr) {
            isEqual = !yArr ? true : false;
          } else if (!yArr) {
            isEqual = false;
          } else {
            isEqual = isNumericCompare ?
              Common.isEqualValueNumeric(xArr[el], yArr[el], this.maxResolution) :
              (xArr[el] === yArr[el]);
          }

          this.valStateArr[el] = isEqual && this.defAligned ?
            AggregateValueState.Aligned : AggregateValueState.Misaligned;
        }
      }
    }

    if (instBase) {
      // Set general property alignment state (if ANY elements are misaligned, report the property
      // as generally misaligned)
      this.valState = this.valStateArr.some(s => s === AggregateValueState.Misaligned) ?
        AggregateValueState.Misaligned : AggregateValueState.Aligned;

      // Select object-property instance to copy values from
      let instCopy: ObjectPropertyInstance = instBase;
      if (instBase.def.valueType === PropertyValueType.NumericValue) {
        instCopy = this.instanceList.find(i => i.isAccessible && i.def.resolution === this.maxResolution) || instBase;
      }

      // Create the property array-value if required
      if (!this.siProperty.value) {
        if (this.def.isPriorityArray) {
          this.siProperty.value = PropertyInstance.createSiPriorityArrayValue(this.def);
        } else {
          this.siProperty.value = PropertyInstance.createSiArrayValue(this.def);
        }
        this.updateSiPropertyValueCommands();
      }

      // Call this each time through in case array size needs adjustment
      this.initializeArray(this.sizeArray, this.def.arrayItemLabels);

      // Finally, set all aggregate array values
      this.valArr = [];
      for (let el = 0; el < size; el++) {
        this.siProperty.value.value[el].value.value = undefined;
        this.siProperty.value.value[el].value.altText = undefined;
        if (this.valueStateArr[el] === AggregateValueState.Aligned) {
          this.valArr.push(instCopy.valueArr ? instCopy.valueArr[el] : undefined);
          if (instCopy.siProperty.value?.value) {
            this.siProperty.value.value[el].value.value = instCopy.siProperty.value.value[el].value.value;
          }
        } else {
          this.valArr.push(undefined);
          this.siProperty.value.value[el].value.altText = '*';
        }
      }
    } else {
      this.valState = AggregateValueState.Misaligned; // should never end up here!
    }
  }

  private getArrayValue(inst: ObjectPropertyInstance, isNumeric: boolean): string[] {
    let valArr: string[];
    if (inst) {
      if (isNumeric) {
        if (inst.valueRaw?.Value) {
          const numArr: number[] = WsiTranslator.decodeNumericArray(inst.valueRaw.Value.Value, inst.def.explicitUndefinedValue);
          if (numArr) {
            valArr = numArr.map(n => isNaN(n) ? undefined : String(n));
          }
        }
      } else {
        if (inst.valueArr) {
          valArr = inst.valueArr.slice(0);
        }
      }
    }
    return valArr;
  }

  /**
   * Subscribe for command changes for all properties in both property lists.
   */
  private subscribeCommands(): void {
    const propList: ObjectPropertyInstance[] = this.instanceList.filter(inst => inst.isAccessible);
    if (propList.length > 0) {
      this.traceService.info(this.trmod, 'Subscribe commands: property count=%s', propList.length);

      // Subscribe for property command changes
      const cmdSubscriptionArr: GmsSubscription<PropertyCommand>[] =
        this.svc.cmdSubscriptionService.subscribeCommands(propList.map(p => p.objectAndPropertyId), this.vmContext.cmdSubscriptionReg);

      // Update each property VM with its associated value subscription
      if (cmdSubscriptionArr && cmdSubscriptionArr.length === propList.length) {
        propList.forEach((p, idx) => p.updateCommandSubscription(cmdSubscriptionArr[idx]));
      } else {
        this.traceService.error(this.trmod, 'Subscribe commands: invalid response');
      }
    }
  }

  /**
   * Unsubscribe value changes for all properties associated with this aggregage set of objects.
   */
  private unsubscribeCommands(): void {
    // Collect all open property command subscriptions (single object)
    const cmdSubscriptionList: GmsSubscription<PropertyCommand>[] = [];
    if (this.instanceList) {
      this.instanceList.forEach(inst => {
        const cs: GmsSubscription<PropertyCommand> = inst.updateCommandSubscription(undefined);
        if (cs) {
          cmdSubscriptionList.push(cs);
        }
      });
    }

    // Unsubscribe all properties
    if (cmdSubscriptionList.length > 0) {
      this.traceService.info(this.trmod, 'Unsubscribe commands: property count=%s', cmdSubscriptionList.length);
      this.svc.cmdSubscriptionService.unsubscribeCommands(cmdSubscriptionList, this.vmContext.cmdSubscriptionReg);
    }
  }

  /**
   * Handler called when an object-property instance command changes.
   */
  private onCommandChanged(opInstance: ObjectPropertyInstance): void {
    this.traceService.debug(this.trmod, 'Received command changed ind: designation=%s, pid=%s', opInstance.browserObj.Designation, this.def.propertyId);

    // Reset the full command list
    this.updateCommandList();
  }

  private onObjectPropertyCommandExecuted(opInstance: ObjectPropertyInstance): void {
    this.clearCompletedCommandState(false);
  }
}
