import { A11yModule, CdkTrapFocus } from '@angular/cdk/a11y';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  inject,
  Input,
  OnDestroy,
  signal,
  ViewChild
} from '@angular/core';
import { SI_HEADER_DROPDOWN_OPTIONS } from '@simpl/element-ng/header-dropdown';
import { SiTranslateModule } from '@simpl/element-translate-ng/translate';
import { Subscription } from 'rxjs';
import { skip, takeUntil } from 'rxjs/operators';

import { SiApplicationHeaderComponent } from './si-application-header.component';

/** Container for actions that should be collapsed in mobile mode. */
@Component({
  selector: 'si-header-collapsible-actions',
  standalone: true,
  imports: [SiTranslateModule, A11yModule],
  templateUrl: './si-header-collapsible-actions.component.html',
  host: { class: 'd-contents' },
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: SI_HEADER_DROPDOWN_OPTIONS, useValue: { disableRootFocusTrapForInlineMode: true } }
  ],
  styles: '.badge-dot::after { inset-inline-end: 4px; }'
})
export class SiHeaderCollapsibleActionsComponent implements OnDestroy {
  private static idCounter = 0;

  /**
   * Accessible label of the toggle button if actions are collapsed.
   *
   * @defaultValue
   * ```
   * $localize`:@@SI_APPLICATION_HEADER.TOGGLE_ACTIONS:Toggle actions`
   * ```
   */
  @Input() mobileToggleLabel = $localize`:@@SI_APPLICATION_HEADER.TOGGLE_ACTIONS:Toggle actions`;

  /** @internal **/
  readonly mobileExpanded = signal(false);
  /** @internal **/
  readonly badgeCount = signal(0);

  protected readonly id = `__si-header-collapsible-actions-${SiHeaderCollapsibleActionsComponent.idCounter++}`;

  @ViewChild('toggle', { static: true }) private toggle!: ElementRef<HTMLDivElement>;
  @ViewChild(CdkTrapFocus, { static: true }) private focusTrap!: CdkTrapFocus;
  private header = inject(SiApplicationHeaderComponent);
  private closeMobileSub = this.header.closeMobileMenus.subscribe(() => this.closeMobile());
  private inlineChangeSubscription?: Subscription;

  ngOnDestroy(): void {
    this.closeMobileSub.unsubscribe();
    this.inlineChangeSubscription?.unsubscribe();
  }

  protected toggleMobileExpanded(): void {
    if (this.mobileExpanded()) {
      this.closeMobile();
    } else {
      this.openMobile();
    }
  }

  protected escapePressed(): void {
    this.closeMobile();
    this.toggle.nativeElement.focus();
  }

  private openMobile(): void {
    if (!this.mobileExpanded()) {
      this.header.closeMobileMenus.next();
      this.header.dropdownOpened();
      this.mobileExpanded.set(true);
      this.inlineChangeSubscription = this.header.inlineDropdown
        .pipe(skip(1), takeUntil(this.header.closeMobileMenus))
        .subscribe(() => this.header.closeMobileMenus.next());
      this.focusTrap.focusTrap.focusFirstTabbableElementWhenReady();
    }
  }

  private closeMobile(): void {
    if (this.mobileExpanded()) {
      this.header.dropdownClosed();
      this.mobileExpanded.set(false);
      this.toggle.nativeElement.focus();
    }
  }
}
