import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { EventCounter, EventCounterList } from '@gms-flex/services';
import { isNullOrUndefined, TraceService } from '@gms-flex/services-common';
import { Observable, Subject, Subscription, timer } from 'rxjs';
import { EventCategoriesBxSubstituteProxyService } from 'src/app/bx-gms-mapper/event/event-categories-bx-substitute-proxy.service';
import { ContextService } from 'src/app/bx-gms-mapper/state/context.service';
import { TraceModules } from 'src/app/core/shared/trace-modules';

import { EventCategoryBx, EventSummaryResponse } from './events-proxy.model';
import { EventsProxyService } from './events-proxy.service';

const pollRateEvents = 30000;
const delayEventReadingAtStartup = 100;

@Injectable({
  providedIn: 'root'
})

export class EventsSummaryService {
  /* Observable for event category summary */
  private readonly _eventSummarySubject$ = new Subject<EventCounterList>();
  private readonly _eventsSitesSummarySubject$ = new Subject<EventSummaryResponse[]>();

  /* Event summary counters */
  private emergency = 0;
  private fault = 0;
  private lifeSafety = 0;
  private security = 0;
  private supervisory = 0;
  private trouble = 0;
  private hight = 0;
  private medium = 0;
  private low = 0;
  private status = 0;
  private none = 0;
    
  /* Selected partition ids */
  private selectedPartitionsIds: string[];
  
  /* Polling management subscription */
  private timerSubscription: Subscription;

  public constructor(
    private readonly traceService: TraceService,
    private readonly eventCategoryProxyService: EventCategoriesBxSubstituteProxyService,
    private readonly eventsProxy: EventsProxyService,
    private readonly contextService: ContextService) {

    this.traceService.info(TraceModules.bxServicesSummary, 'EventSummaryService created.');
    this.subscribeSelectedPartitions();
  }

  /**
   * this getter return an {@link EventCounterList} observable,
   * the values are the sum of the events of all active sites,
   * it contains the total number of open alarms and a counter for each category
   */
  public get getEventSummary(): Observable<EventCounterList> {
    return this._eventSummarySubject$.asObservable();
  }

  /**
   * this getter return an array of {@link EventSummaryResponse} observable,
   * each value contains the siteId information and the counters of that site,
   * it contains a counter of total open alarms and a counter for each category
   */
  public get getSitesEventSummary(): Observable<EventSummaryResponse[]> {
    return this._eventsSitesSummarySubject$.asObservable();
  }

  /* Subscribe for selected partition changes and start timer subscription for event category summary */
  private subscribeSelectedPartitions(): void {    
    this.contextService.selectedPartitions$.subscribe(partitions => {
      if (!isNullOrUndefined(this.contextService.selectedPartitions$)) {
        this.selectedPartitionsIds = partitions.map(p => p.id);
        /* First time we want to start timer after 100 ms; the other times the delay will be each 30000 ms */
        this.startTimerForSubscription(delayEventReadingAtStartup);
      } else {
        this.selectedPartitionsIds = []; 
      }
    });
  }

  /** Fired every time that total event counter changed (for all selected partitions)  */
  private onEventSummaryChanged(summaryAllPartitions: EventSummaryResponse[]): void {
    this.traceService.debug(TraceModules.bxServicesSummary, 
      `onEventSummaryChanged() received at: ${formatDate(new Date(), 'dd-MM-yyyy hh:mm:ss', 'en-US')}`);         
    
    this.clearEventSummaryCounters();
    summaryAllPartitions.forEach(summaryPartition => { // for each partition
      this.updateEventSummaryCounters(summaryPartition);
    }); 

    this.traceSummaries();
    const eventCounters: EventCounterList = this.addCounters();
    this._eventSummarySubject$.next(eventCounters);
    this._eventsSitesSummarySubject$.next(summaryAllPartitions);
  }

  /* Construct and return new EventCounterList object */
  /* eslint-disable-next-line @typescript-eslint/naming-convention */
  private addCounters(): EventCounterList {
    /* eslint-disable-next-line @typescript-eslint/naming-convention */
    const eventCounters = { EventCategoryCounters: [], TotalCounters: 0, TotalUnprocessedCounters: 0 };

    eventCounters.EventCategoryCounters.push(this.addCounter(EventCategoryBx.Emergency, this.emergency)); 
    eventCounters.EventCategoryCounters.push(this.addCounter(EventCategoryBx.LifeSafety, this.lifeSafety));
    eventCounters.EventCategoryCounters.push(this.addCounter(EventCategoryBx.Security, this.security));
    eventCounters.EventCategoryCounters.push(this.addCounter(EventCategoryBx.Supervisory, this.supervisory));
    eventCounters.EventCategoryCounters.push(this.addCounter(EventCategoryBx.Trouble, this.trouble));
    eventCounters.EventCategoryCounters.push(this.addCounter(EventCategoryBx.High, this.hight));
    eventCounters.EventCategoryCounters.push(this.addCounter(EventCategoryBx.Medium, this.medium));
    eventCounters.EventCategoryCounters.push(this.addCounter(EventCategoryBx.Low, this.low));
    eventCounters.EventCategoryCounters.push(this.addCounter(EventCategoryBx.Fault, this.fault));
    eventCounters.EventCategoryCounters.push(this.addCounter(EventCategoryBx.Status, this.status));
    eventCounters.EventCategoryCounters.push(this.addCounter(EventCategoryBx.Unknown, this.none));

    return eventCounters;
  }

  /* Construct and return a new EventCounter object for given event category and given total counter */
  /* eslint-disable-next-line @typescript-eslint/naming-convention */
  private addCounter(category: EventCategoryBx, categoryTotalCount: number): EventCounter {
    /* eslint-disable @typescript-eslint/naming-convention */
    return {
      CategoryId: this.eventCategoryProxyService.getCategory(category).CategoryId,
      CategoryDescriptor: this.eventCategoryProxyService.getCategory(category).CategoryName,
      TotalCount: categoryTotalCount,
      TotalSubsequentGrouping: categoryTotalCount,
      UnprocessedCount: categoryTotalCount,
      UnprocessedSubsequentGrouping: categoryTotalCount
    };
    /* eslint-enable @typescript-eslint/naming-convention */
  }

  /* Update event summary counters for given partition */
  private updateEventSummaryCounters(summaryPartition: EventSummaryResponse): void {
    this.emergency += summaryPartition[EventCategoryBx.Emergency]?.count ?? 0;  
    this.lifeSafety += summaryPartition[EventCategoryBx.LifeSafety]?.count ?? 0;  
    this.security += summaryPartition[EventCategoryBx.Security]?.count ?? 0;  
    this.supervisory += summaryPartition[EventCategoryBx.Supervisory]?.count ?? 0;   
    this.trouble += summaryPartition[EventCategoryBx.Trouble]?.count ?? 0;  
    this.hight += summaryPartition[EventCategoryBx.High]?.count ?? 0;  
    this.medium += summaryPartition[EventCategoryBx.Medium]?.count ?? 0;  
    this.low += summaryPartition[EventCategoryBx.Low]?.count ?? 0;  
    this.fault += summaryPartition[EventCategoryBx.Fault]?.count ?? 0;    
    this.status += summaryPartition[EventCategoryBx.Status]?.count ?? 0;   
    this.none += summaryPartition[EventCategoryBx.Unknown]?.count ?? 0;  
  }

  /* Clean event summary counters */
  private clearEventSummaryCounters(): void {
    this.emergency = 0;
    this.lifeSafety = 0;
    this.security = 0;
    this.supervisory = 0;
    this.trouble = 0;
    this.hight = 0;
    this.medium = 0;
    this.low = 0;
    this.fault = 0;
    this.status = 0;
    this.none = 0;
  }

  /* Trace for debug scope */
  private traceSummaries(): void {
    this.traceService.debug(TraceModules.bxServicesSummary, EventCategoryBx.Emergency + ': ' + this.emergency);
    this.traceService.debug(TraceModules.bxServicesSummary, EventCategoryBx.LifeSafety + ': ' + this.lifeSafety);
    this.traceService.debug(TraceModules.bxServicesSummary, EventCategoryBx.Security + ': ' + this.security);
    this.traceService.debug(TraceModules.bxServicesSummary, EventCategoryBx.Supervisory + ': ' + this.supervisory);
    this.traceService.debug(TraceModules.bxServicesSummary, EventCategoryBx.Trouble + ': ' + this.trouble);
    this.traceService.debug(TraceModules.bxServicesSummary, EventCategoryBx.High + ': ' + this.hight);
    this.traceService.debug(TraceModules.bxServicesSummary, EventCategoryBx.Medium + ': ' + this.medium);
    this.traceService.debug(TraceModules.bxServicesSummary, EventCategoryBx.Low + ': ' + this.low);
    this.traceService.debug(TraceModules.bxServicesSummary, EventCategoryBx.Fault + ': ' + this.fault);
    this.traceService.debug(TraceModules.bxServicesSummary, EventCategoryBx.Status + ': ' + this.status);
    this.traceService.debug(TraceModules.bxServicesSummary, EventCategoryBx.Unknown + ': ' + this.none);
  }

  /* Reset timer subscription */
  private stopTimerForSubscription(): void {
    this.timerSubscription?.unsubscribe();
    this.timerSubscription = undefined;
  }

  /* Start timer subscription */
  private startTimerForSubscription(delay: number): void {
    this.stopTimerForSubscription();
    this.timerSubscription = timer(delay).subscribe(count => this.onTimerSubscription());
  }

  /* Subscribe to 'getEventSummary' response */
  private onTimerSubscription(): void {
    if (this.selectedPartitionsIds.length > 0) {
      this.eventsProxy.getEventSummary(this.selectedPartitionsIds).subscribe(response => {
        this.onEventSummaryChanged(response);
        this.startTimerForSubscription(pollRateEvents);
      })
    }
  }
}
