import { Directive, inject, Input, TemplateRef } from '@angular/core';

import { TreeItem } from './si-tree-view.model';

@Directive({
  selector: '[siTreeViewItemTemplate]',
  standalone: true
})
export class SiTreeViewItemTemplateDirective {
  @Input('siTreeViewItemTemplate') name!: string;
  /** @internal */
  template = inject(TemplateRef<any>);

  static ngTemplateContextGuard(
    dir: SiTreeViewItemTemplateDirective,
    ctx: any
  ): ctx is { $implicit: TreeItem } {
    return true;
  }
}
