import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CopyrightDetails, SiCopyrightNoticeComponent } from '@simpl/element-ng/copyright-notice';
import { SiInlineNotificationComponent } from '@simpl/element-ng/inline-notification';
import { IsoLanguageValue, SiLanguageSwitcherComponent } from '@simpl/element-ng/language-switcher';
import { Link, SiLinkDirective } from '@simpl/element-ng/link';
import { SiTranslateModule } from '@simpl/element-translate-ng/translate';

import { AlertConfig } from './alert-config.model';

@Component({
  selector: 'si-landing-page',
  templateUrl: './si-landing-page.component.html',
  styleUrl: './si-landing-page.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    SiCopyrightNoticeComponent,
    SiLanguageSwitcherComponent,
    SiLinkDirective,
    SiInlineNotificationComponent,
    SiTranslateModule
  ]
})
export class SiLandingPageComponent {
  /**
   * Heading of the application.
   */
  @Input({ required: true })
  heading!: string;
  /**
   * Secondary heading of the application.
   */
  @Input() subheading?: string;
  /**
   * Short description of the application.
   */
  @Input({ required: true })
  subtitle!: string;
  /**
   * List of links (e.g. Corporate information)
   *
   * @defaultValue []
   */
  @Input() links: Link[] = [];

  /**
   * URL to custom background image
   *
   * @defaultValue './assets/images/landing-page-digitalcity.webp'
   */
  @Input() backgroundImageUrl = './assets/images/landing-page-digitalcity.webp';

  /**
   * URL to custom brand image
   */
  @Input() logoUrl?: string;

  /**
   * Input of si-language-switcher: Key for translation.
   * If your languages are already translated, you may display them without any
   * manipulation by passing in an empty string.
   *
   * @defaultValue 'LANGUAGE'
   */
  @Input() translationKey = 'LANGUAGE';
  /**
   * Input of si-language-switcher: List of all available languages in this
   * application.
   */
  @Input() availableLanguages?: string[] | IsoLanguageValue[];

  /**
   *
   * Config to enable/disable general alerts on landing page
   */
  @Input() announcement?: AlertConfig;

  /**
   *
   * Config to enable/disable login related error/alerts
   */
  @Input() loginAlert?: AlertConfig;

  /**
   * Version of the application
   */
  @Input() version?: string;

  /**
   * Copyright information to be displayed. Alternatively, you can use the {@link SI_COPYRIGHT_DETAILS} global inject.
   */
  @Input() copyrightDetails?: CopyrightDetails;
}
