@if (show) {
  <div class="step flex-column px-5 py-2 my-5 elevation-1 card" [class.step-active]="active">
    <div class="d-flex flex-row justify-content-between">
      <div
        class="d-flex flex-row flex-fill align-items-center me-5"
        role="button"
        [class.status-active]="active"
        [class.disabled]="disabled"
        [attr.tabindex]="disabled ? null : 0"
        [attr.aria-disabled]="disabled"
        (keydown.enter)="stepClicked()"
        (click)="stepClicked()"
      >
        <div class="step-icon me-4">
          @switch (state) {
            @case ('checked') {
              <si-icon icon="element-checked-filled" />
            }
            @case ('not-needed') {
              <si-icon icon="element-disable" />
            }
            @case ('failed') {
              <si-icon icon="element-issue" />
            }
            @case ('failed-checked') {
              <si-icon
                icon="element-circle-filled"
                stackedIcon="element-state-exclamation-mark"
                stackedColor="status-danger-contrast"
                [color]="active ? 'status-danger' : ''"
              />
            }
            @case ('not-supported') {
              <si-icon icon="element-out-of-service" />
            }
            @case ('not-checked') {
              <si-icon [icon]="active ? 'element-radio-checked' : 'element-not-checked'" />
            }
            @default {
              <si-icon [icon]="active ? 'element-radio-checked' : 'element-not-checked'" />
            }
          }
        </div>
        <div class="si-title-2 step-title" [class.text-muted]="disabled">{{ heading }}</div>
      </div>
      <!-- Actions -->
      <div class="d-flex flex-row ms-auto align-items-center">
        @if (primaryAction) {
          <button
            type="button"
            class="btn me-6 step-primary-action"
            [class.btn-primary]="active && !disabled"
            [class.btn-secondary]="!active || disabled"
            [disabled]="disabled || primaryAction.disabled"
            (click)="actionCallback(primaryAction.type)"
          >
            @if (primaryAction.icon) {
              <i class="icon" [ngClass]="primaryAction.icon"></i>
            }
            {{ primaryAction.title }}
          </button>
        }
        @if (secondaryActionsArr?.length) {
          <button
            type="button"
            class="btn btn-tertiary btn-menu icon element-options-vertical px-4 py-2"
            [class.element-options-vertical]="!menuOpen"
            [class.element-cancel]="menuOpen"
            [attr.aria-label]="toggleLabel | translate"
            [attr.title]="toggleLabel | translate"
            [disabled]="disabled"
            [cdkMenuTriggerFor]="contextMenu"
            (cdkMenuOpened)="menuOpen = true"
            (cdkMenuClosed)="menuOpen = false"
          >
          </button>
        }
      </div>
    </div>
    <!-- Input content -->
    @if (active) {
      <div class="step-content pt-7">
        <ng-content />
      </div>
    }
  </div>
}

<ng-template #contextMenu>
  <si-menu-factory [items]="secondaryActionsArr" />
</ng-template>
