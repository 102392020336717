import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { clone } from '@simpl/buildings-ng/common';
import { SiTimeDurationComponent } from '@simpl/buildings-ng/time-duration';
import { TimeDurationValue } from '@simpl/buildings-types';

import { durationString } from '../../helpers/time';
import { Property, StateChange, ValueState } from '../../interfaces/property';
import { SiPropertyPopoverComponent } from '../si-property-popover/si-property-popover.component';

@Component({
  selector: 'si-timeduration-property',
  templateUrl: './si-timeduration-property.component.html',
  styleUrl: './si-timeduration-property.component.scss',
  standalone: true,
  imports: [FormsModule, SiPropertyPopoverComponent, SiTimeDurationComponent]
})
export class SiTimedurationPropertyComponent implements AfterViewInit {
  @Input({ required: true }) property!: Property<TimeDurationValue>;
  /** @defaultValue 'none' */
  @Input() valueState: ValueState = 'none';
  /** @defaultValue false */
  @Input() forceReadonly = false;
  @Output() readonly submitted = new EventEmitter<Property<TimeDurationValue>>();

  @ViewChild('form') protected form!: NgForm;
  @ViewChild('popover', { static: true }) protected popover!: SiPropertyPopoverComponent;

  protected isValid = true;

  protected get readonly(): true | null {
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    return this.forceReadonly || this.property.value.readonly || null;
  }

  // note this is duplicated from popover since the si-time-duration expect the full TimeDurationValue
  private editValue?: TimeDurationValue;

  private elementRef = inject(ElementRef);

  protected get modelValue(): any {
    return this.popover.isActive ? this.editValue : this.property?.value;
  }

  ngAfterViewInit(): void {
    this.form.statusChanges!.subscribe(() => {
      // setTimeout to work around Angular bug 23657
      setTimeout(() => (this.isValid = !!this.form.valid));
    });
  }

  protected get displayValue(): string {
    return durationString(
      this.modelValue.value,
      this.property.value.unit,
      this.property.value.format
    );
  }

  protected stateChange(state: StateChange): void {
    switch (state) {
      case 'openKeyboard':
        setTimeout(() =>
          this.elementRef.nativeElement.querySelector('si-time-duration input')?.focus()
        );
        this.editValue = clone(this.property.value);
        break;
      case 'open':
        this.editValue = clone(this.property.value);
        break;
      case 'submit':
        this.property.value.value = this.editValue!.value;
        this.editValue = undefined;
        this.submitted.emit(this.property);
        break;
      case 'release':
        this.property.value.value = undefined;
        this.submitted.emit(this.property);
        break;
    }
  }
}
