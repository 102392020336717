import { Injectable } from '@angular/core';
import { TraceService } from '@gms-flex/services-common';
import { map, Observable, of, share, tap, zip } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { TraceModules } from 'src/app/core/shared/trace-modules';

import { EntityTypeHasAssets, FilterType } from '../shared/base.model';
import { useCache, useMulticast } from '../shared/http-utility.service';
import { BuildingBx,
  BuildingPartBx,
  CampusBx,
  CampusPartBx,
  FloorAreaBx,
  FloorBx,
  GeoLocationCoordinates,
  GeoLocationType,
  Geometries,
  LocationBx,
  LocationType,
  MultiFloorAreaBx,
  OutsideBx,
  RoomBx,
  RoomSegmentBx } from './location-proxy.model';
import { LocationProxyService } from './location-proxy.service';

@Injectable({
  providedIn: 'root'
})
export class LocationService {
  private readonly campusPerPartition: Map<string, CampusBx[]> = new Map<string, CampusBx[]>();
  private readonly buildingPerPartition: Map<string, BuildingBx[]> = new Map<string, BuildingBx[]>();
  private readonly locationPerPartitionAndParent: Map<string, Map<string, LocationType[]>> = new Map<string, Map<string, LocationType[]>>();
  private readonly locationPerPartitionAndId: Map<string, Map<string, LocationType>> = new Map<string, Map<string, LocationType>>();
  private readonly campusPerPartitionAndName: Map<string, Map<string, CampusBx[]>> = new Map<string, Map<string, CampusBx[]>>();
  private readonly buildingPerPartitionAndName: Map<string, Map<string, BuildingBx[]>> = new Map<string, Map<string, BuildingBx[]>>();
  private readonly locationPerPartitionAndParentAndName: Map<string, Map<string, Map<string, LocationType[]>>> =
    new Map<string, Map<string, Map<string, LocationType[]>>>();

  private readonly multiCastLocObs: Map<string, Observable<LocationType>> = new Map<string, Observable<LocationType>>();

  public constructor(
    private readonly traceService: TraceService,
    private readonly locationProxy: LocationProxyService) {

    this.traceService.info(TraceModules.bxServicesLocations, 'LocationService created.');
  }

  public getParentLocationId(location: LocationType): string {
    if ((location as CampusPartBx).relationships.isCampusPartOf !== undefined) {
      return (location as CampusPartBx).relationships.isCampusPartOf.data.id;
    } else if ((location as BuildingBx).relationships.isBuildingOf !== undefined) {
      return (location as BuildingBx).relationships.isBuildingOf.data.id;
    } else if ((location as BuildingPartBx).relationships.isBuildingPartOf !== undefined) {
      return (location as BuildingPartBx).relationships.isBuildingPartOf.data.id;
    } else if ((location as FloorBx).relationships.isFloorOf !== undefined) {
      return (location as FloorBx).relationships.isFloorOf.data.id;
    } else if ((location as FloorAreaBx).relationships.isFloorAreaOf !== undefined) {
      return (location as FloorAreaBx).relationships.isFloorAreaOf.data.id;
    } else if ((location as MultiFloorAreaBx).relationships.isMultifloorAreaOf !== undefined) {
      return (location as MultiFloorAreaBx).relationships.isMultifloorAreaOf.data.id;
    } else if ((location as RoomBx).relationships.isRoomOf !== undefined) {
      return (location as RoomBx).relationships.isRoomOf.data.id;
    } else if ((location as RoomSegmentBx).relationships.isRoomSegmentOf !== undefined) {
      return (location as RoomSegmentBx).relationships.isRoomSegmentOf.data.id;
    } else if ((location as OutsideBx).relationships.isOutsideOf !== undefined) {
      return (location as OutsideBx).relationships.isOutsideOf.data.id;
    } else {
      return undefined;
    }
  }

  /**
   * Returns the root nodes of a partition. Root nodes have no parent location. An optional filter (location name or Id) can be set.
   * Root nodes are of type Campus of Building.
   */
  public getLocationRoots(partitionId: string, filter?: string, filterType?: FilterType): Observable<{ campusRoots: CampusBx[]; buildingRoots: BuildingBx[] }> {
    const campus$ = this.getLocationCampus(partitionId, filter, filterType);
    const building$ = this.getLocationBuilding(partitionId, filter, filterType);
    return zip(campus$, building$).pipe(
      map((results: [CampusBx[], BuildingBx[]]) => {
        // if the building has no parent (it is not contained by a campus nor a campuspart) => this is a root node
        const bldgRoots = results[1].filter(bldg => (bldg.relationships.isBuildingOf == null));
        return { campusRoots: results[0], buildingRoots: bldgRoots };
      }));
  }

  /**
   * Reads the location from the backend (or the cache)
   */
  public getLocationById(partitionId: string, locationId: string): Observable<LocationType | undefined> {
    if ((useCache) && (this.locationPerPartitionAndId.has(partitionId) && (this.locationPerPartitionAndId.get(partitionId).has(locationId)))) {
      return of(this.locationPerPartitionAndId.get(partitionId).get(locationId));
    } else {
      // using multicast to support the concurrent alarm source resolvement more efficient.
      // problem statement: finding/resolving the same object (e.g the device of alarms of the device) causes many parallel calls which do the same.
      // cache cannot be maintained.
      if ((useMulticast) && (this.multiCastLocObs.has(locationId))) {
        return this.multiCastLocObs.get(locationId);
      }
      const locObs: Observable<LocationType> = this.locationProxy.getLocationById(partitionId, locationId).pipe(
        tap(result => {
          this.replaceSeparatorsSingle(result);
          this.updateLocationCachePerPartitionAndId(partitionId, [result]);
        }),
        catchError(error => {
          this.traceService.warn(TraceModules.bxServicesLocations, `Location not found: ${locationId}, error=${error}`);
          if (this.locationPerPartitionAndId.has(partitionId) === false) {
            this.locationPerPartitionAndId.set(partitionId, new Map<string, LocationType>());
          }
          this.locationPerPartitionAndId.get(partitionId).set(locationId, undefined);
          return of(undefined);
        })
      );
      if (useMulticast) {
        const multicastObs = locObs.pipe(share());
        this.multiCastLocObs.set(locationId, multicastObs);
        return multicastObs;
      } else {
        return locObs;
      }
    }
  }

  /**
   * Returns the children of the parentLocationId.
   *
   * Parent-Child relationsips are as follows:
   * Parent: CampusBx => Children: CampusPartBx | BuildingBx | OutsideBx
   * Parent: CampusPartBx => Children: BuildingBx | OutsideBx
   * Parent: BuildingBx => Children: BuildingPartBx | FloorBx | OutsideBx
   * Parent: BuildingPartBx => Children: FloorBx | OutsideBx
   * Parent: FloorBx => Children: FloorAreaBx | MultiFloorAreaBx | RoomBx
   * Parent: FloorAreaBx => Children: RoomBx[]
   * Parent: MultiFloorAreaBx => Children: RoomBx[]
   * Parent: RoomBx => Children: RoomSegmentBx[]
   * Parent: RoomSegmentBx => Children: none
   * Parent: OutsideBx => Children: none
   */
  public getLocationChildren(partitionId: string, parentLocationId: string, filter?: string, filterType?: FilterType): Observable<LocationType[]> {
    return this.getLocationChildrenOfParent(partitionId, parentLocationId, filter, filterType);
  }

  /**
   * Returns all campuses of the partition. An optional filter (location name or Id) can be set.
   * If name is specified, only the campuses with that name are returned.
   */
  public getLocationCampus(partitionId: string, filter?: string, filterType?: FilterType): Observable<CampusBx[]> {
    if ((useCache) && (this.campusPerPartition.has(partitionId))) {
      const campuses = this.campusPerPartition.get(partitionId);
      const filtered = this.filterLocations(campuses, filter, filterType);
      this.traceService.debug(TraceModules.bxServicesLocations, `LocationService.getLocationCampus() returned: no of campus: ${filtered.length} from cache
        partitionId=${partitionId}, filter=${filter}`);
      return of(filtered as CampusBx[]);
    } else {
      if ((filter === undefined) || (filter && filterType === 'Id')) {
        // Note: Even in case of filtering based on Id, all campus are fetched.
        // Reason: Avoid 'Not found' errors and it is likely that all campuses need to be fetched anyway.
        // This decision might be reconsidered in case of many buildings. (and the location by Id would need to be used.)
        return this.locationProxy.getLocationCampus(partitionId).pipe(
          tap(result => {
            this.replaceSeparators(result);
            this.campusPerPartition.set(partitionId, result);
            this.updateLocationCachePerPartitionAndId(partitionId, result);
            this.traceService.debug(TraceModules.bxServicesLocations, `LocationService.getLocationCampus() returned: no of campus ${result.length} from backend
              partitionId=${partitionId}, filter=${filter}`);
          }),
          map(result => {
            if (filter) {
              return this.filterLocations(result, filter, filterType) as CampusBx[];
            } else {
              return result;
            }
          })
        );
      } else {
        // Note: In case there are lots of buildings per partition (e.g. 1000), it is better to do filtering on server!
        return this.getLocationCampusByName(partitionId, filter);
      }
    }
  }

  /**
   * Returns all buildings of the partition. An optional filter (location name or Id) can be set.
   * If name is specified, only the buildings with that name are returned.
   */
  public getLocationBuilding(partitionId: string, filter?: string, filterType?: FilterType): Observable<BuildingBx[]> {
    if ((useCache) && (this.buildingPerPartition.has(partitionId))) {
      const buildings = this.buildingPerPartition.get(partitionId);
      const filtered = this.filterLocations(buildings, filter, filterType);
      this.traceService.debug(TraceModules.bxServicesLocations, `LocationService.getLocationBuilding() returned: no of buildings: ${filtered.length} from cache
        partitionId=${partitionId}, filter=${filter}`);
      return of(filtered as BuildingBx[]);
    } else {
      if ((filter === undefined) || (filter && filterType === 'Id')) {
        // Note: Even in case of filtering based on Id, all campus are fetched.
        // Reason: Avoid 'Not found' errors and it is likely that all campuses need to be fetched anyway.
        // This decision needs to be reconsidered in case of many buildings and timely bad behaviour. (and the location by Id would need to be used.)
        return this.locationProxy.getLocationBuilding(partitionId).pipe(
          tap(result => {
            this.replaceSeparators(result);
            this.buildingPerPartition.set(partitionId, result);
            this.updateLocationCachePerPartitionAndId(partitionId, result);
            this.traceService.debug(TraceModules.bxServicesLocations,
              `LocationService.getLocationBuilding() returned: no of buildings ${result.length} from backend
              partitionId=${partitionId}, buildingName=${filter}`);
          }),
          map(result => {
            if (filter) {
              return this.filterLocations(result, filter, filterType) as BuildingBx[];
            } else {
              return result;
            }
          })
        );
      } else {
        // Note: In case there are lots of buildings per partition (e.g. 1000), it is better to do filtering on server!
        return this.getLocationBuildingByName(partitionId, filter);
      }
    }
  }

  public getLocationHasAssetsIds(partitionId: string, locationId: string, entityType: EntityTypeHasAssets): Observable<string[]> {
    return this.getLocationById(partitionId, locationId).pipe(
      map(result => this.getDeviceIds(result, entityType))
    );
  }

  public getGeometriesInfo(coordinates: GeoLocationCoordinates, type: GeoLocationType): Geometries[] {
    const geometries: Geometries[] = [
      {
        coordinates: [coordinates[0] ?? 0, coordinates[1] ?? 0],
        type
      }
    ];

    return geometries;
  }

  private getDeviceIds(location: LocationType, entityType: EntityTypeHasAssets): string[] {
    if (location.relationships.hasAssets) {
      return location.relationships.hasAssets.data.filter(item => item.type === entityType).map(item => item.id);
    } else {
      return [];
    }
  }

  private getLocationCampusByName(partitionId: string, name: string): Observable<CampusBx[]> {
    if ((useCache) && (this.campusPerPartitionAndName.has(partitionId) && (this.campusPerPartitionAndName.get(partitionId).has(name)))) {
      const campuses = this.campusPerPartitionAndName.get(partitionId).get(name);
      this.traceService.debug(TraceModules.bxServicesLocations, `LocationService.getLocationCampusByName() returned: no of campus: ${campuses.length} from cache
        partitionId=${partitionId}, campusName=${name}`);
      return of(campuses);
    } else {
      return this.locationProxy.getLocationCampusByName(partitionId, this.unreplaceSeparators(name)).pipe(
        tap(result => {
          this.replaceSeparators(result);
          if (this.campusPerPartitionAndName.has(partitionId) === false) {
            this.campusPerPartitionAndName.set(partitionId, new Map<string, CampusBx[]>());
          }
          this.campusPerPartitionAndName.get(partitionId).set(name, result);
          this.updateLocationCachePerPartitionAndId(partitionId, result);

          this.traceService.debug(TraceModules.bxServicesLocations,
            `LocationService.getLocationCampusByName() returned: no of campus: ${result.length} from backend
            partitionId=${partitionId}, campusName=${name}`);
        })
      );
    }
  }

  private getLocationBuildingByName(partitionId: string, name: string): Observable<BuildingBx[]> {
    if ((useCache) && (this.buildingPerPartitionAndName.has(partitionId) && (this.buildingPerPartitionAndName.get(partitionId).has(name)))) {
      const buildings = this.buildingPerPartitionAndName.get(partitionId).get(name);
      this.traceService.debug(TraceModules.bxServicesLocations,
        `LocationService.getLocationBuildingByName() returned: no of buildings: ${buildings.length} from cache
        partitionId=${partitionId}, buildingName=${name}`);
      return of(buildings);
    } else {
      return this.locationProxy.getLocationBuildingByName(partitionId, this.unreplaceSeparators(name)).pipe(
        tap(result => {
          this.replaceSeparators(result);
          if (this.buildingPerPartitionAndName.has(partitionId) === false) {
            this.buildingPerPartitionAndName.set(partitionId, new Map<string, BuildingBx[]>());
          }
          this.buildingPerPartitionAndName.get(partitionId).set(name, result);
          this.updateLocationCachePerPartitionAndId(partitionId, result);
          this.traceService.debug(TraceModules.bxServicesLocations,
            `LocationService.getLocationBuildingByName() returned: no of buildings: ${result.length} from backend
            partitionId=${partitionId}, campusName=${name}`);
        })
      );
    }
  }

  private getLocationChildrenOfParent(partitionId: string, parentId: string, filter?: string, filterType?: FilterType): Observable<LocationType[]> {
    if ((useCache) && (this.locationPerPartitionAndParent.has(partitionId) && (this.locationPerPartitionAndParent.get(partitionId).has(parentId)))) {
      const children = this.locationPerPartitionAndParent.get(partitionId).get(parentId);
      const filtered = this.filterLocations(children, filter, filterType);
      this.traceService.debug(TraceModules.bxServicesLocations,
        `LocationService.getLocationChildrenOfParent() returned: no of chilldren: ${filtered.length} from cache
        partitionId=${partitionId}, filter=${filter}`);
      return of(filtered as LocationType[]);
    } else {
      if ((filter === undefined) || (filter && filterType === 'Id')) {
        // Note: Even in case of filtering based on Id, all campus are fetched.
        // Reason: Avoid 'Not found' errors and it is likely that all campuses need to be fetched anyway.
        // This decision needs to be reconsidered in case of many buildings and timely bad behaviour. (and the location by Id would need to be used.)
        return this.locationProxy.getLocationChildrenOfParent(partitionId, parentId).pipe(
          tap(result => {
            this.replaceSeparators(result);
            if (this.locationPerPartitionAndParent.has(partitionId) === false) {
              this.locationPerPartitionAndParent.set(partitionId, new Map<string, LocationType[]>());
            }
            this.locationPerPartitionAndParent.get(partitionId).set(parentId, result);
            this.updateLocationCachePerPartitionAndId(partitionId, result);
            this.traceService.debug(TraceModules.bxServicesLocations,
              `LocationService.getLocationChildrenOfParent() returned: no of chilldren: ${result.length} from backend
              partitionId=${partitionId}, filter=${filter}`);
          }),
          map(result => {
            if (filter) {
              return this.filterLocations(result, filter, filterType) as LocationType[];
            } else {
              return result;
            }
          })
        );
      } else {
        return this.getLocationChildrenOfParentByName(partitionId, parentId, filter);
      }
    }
  }

  private getLocationChildrenOfParentByName(partitionId: string, parentId: string, childName: string): Observable<LocationType[]> {
    if ((useCache) && (this.locationPerPartitionAndParentAndName.has(partitionId) &&
    (this.locationPerPartitionAndParentAndName.get(partitionId).has(parentId)) &&
    (this.locationPerPartitionAndParentAndName.get(partitionId).get(parentId).has(childName)))) {
      const children = this.locationPerPartitionAndParentAndName.get(partitionId).get(parentId).get(childName);
      this.traceService.debug(TraceModules.bxServicesLocations,
        `LocationService.getLocationChildrenOfParentByName() returned: no of children: ${children.length} from cache
        partitionId=${partitionId}, buildingName=${childName}`);
      return of(children);
    } else {
      return this.locationProxy.getLocationChildrenOfParentByName(partitionId, parentId, this.unreplaceSeparators(childName)).pipe(
        tap(result => {
          this.replaceSeparators(result);
          if (this.locationPerPartitionAndParentAndName.has(partitionId) === false) {
            this.locationPerPartitionAndParentAndName.set(partitionId, new Map<string, Map<string, LocationType[]>>());
          }
          if (this.locationPerPartitionAndParentAndName.get(partitionId).has(parentId) === false) {
            this.locationPerPartitionAndParentAndName.get(partitionId).set(parentId, new Map<string, LocationType[]>());
          }
          this.locationPerPartitionAndParentAndName.get(partitionId).get(parentId).set(childName, result);
          this.updateLocationCachePerPartitionAndId(partitionId, result);
          this.traceService.debug(TraceModules.bxServicesLocations,
            `LocationService.getLocationChildrenOfParentByName() returned: no of children: ${result.length} from backend
            partitionId=${partitionId}, campusName=${childName}`);
        })
      );
    }
  }

  private filterLocations(locations: LocationBx[], filter?: string, filterType?: FilterType): LocationBx[] {
    if (filter) {
      if (filterType === 'Name') {
        return this.findLocationByName(locations, filter);
      } else {
        return this.findLocationById(locations, filter);
      }
    } else {
      return locations;
    }
  }

  private findLocationByName(locations: LocationBx[], name: string): LocationBx[] {
    return locations.filter(loc => loc.attributes.label === name);
  }

  private findLocationById(locations: LocationBx[], id: string): LocationBx[] {
    return locations.filter(loc => loc.id === id);
  }

  private updateLocationCachePerPartitionAndId(partitionId: string, locations: LocationType[]): void {
    if (this.locationPerPartitionAndId.has(partitionId) === false) {
      this.locationPerPartitionAndId.set(partitionId, new Map<string, LocationType>());
    }
    locations.forEach(location => this.locationPerPartitionAndId.get(partitionId).set(location.id, location));
  }

  private replaceSeparators(locationDefs: LocationBx[]): void {
    // TODO: remove workaround
    // We need to do proper escaping also on UI and Designation classes
    locationDefs.forEach(locDef => {
      this.replaceSeparatorsSingle(locDef);
    });
  }

  private replaceSeparatorsSingle(locationDef: LocationBx): void {
    // TODO: remove workaround
    // We need to do proper escaping also on UI and Designation classes
    locationDef.attributes.label = locationDef.attributes.label.replaceAll('.', '\'\'');
  }

  private unreplaceSeparators(name: string): string {
    // TODO: remove workaround, it is not mature!!
    // We need to do proper escaping also on UI and Designation classes
    return name.replaceAll('\'\'', '.');
  }
}
