/*
 * NOTICE: This component uses the same logic as the `si-list-interaction` directive
 * and is compatible with it.
 */

import { FocusKeyManager, FocusOrigin } from '@angular/cdk/a11y';
import { isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  booleanAttribute,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  inject,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  PLATFORM_ID,
  SimpleChanges
} from '@angular/core';
import { correctKeyRTL, MenuItem, responsivelyCheckDirection } from '@simpl/element-ng/common';
import {
  InteractableList,
  InteractableListDirective,
  InteractableListItemDirective,
  InteractableListOrientation,
  InteractableListPosition,
  SiListInteractionService
} from '@simpl/element-ng/list-interaction';
import { Subscription } from 'rxjs';

import { SiMenuLegacyItemComponent } from './si-menu-legacy-item/si-menu-legacy-item.component';

/**
 * @deprecated The {@link SiMenuLegacyComponent} and all related symbols should no longer be used.
 * Please use {@link SiMenuModule} instead.
 * Read the {@link https://simpl.code.siemens.io/simpl-element/components/buttons-menus/menu/ | documentation} for more information.
 */
@Component({
  selector: 'si-menu-legacy',
  templateUrl: './si-menu-legacy.component.html',
  styleUrl: './si-menu-legacy.component.scss',
  host: {
    class: 'dropdown-menu'
  },
  standalone: true,
  imports: [SiMenuLegacyItemComponent]
})
export class SiMenuLegacyComponent
  implements AfterViewInit, OnDestroy, OnInit, OnChanges, InteractableListDirective
{
  /**
   * Array of menu items to display.
   *
   * @defaultValue []
   */
  @Input({ required: true })
  items: MenuItem[] = [];

  /**
   * Specifies whether sub menus should be displayed on the start side.
   * @defaultValue false
   * @defaultref {@link dropstart}
   * @deprecated Use the new Input {@link dropstart}
   */
  @Input() set dropleft(value: boolean) {
    this.dropstart = value;
  }

  get dropleft(): boolean {
    return this.dropstart;
  }

  /**
   * Specifies whether sub menus should be displayed on the start side.
   *
   * @defaultValue false
   */
  @Input({ transform: booleanAttribute }) dropstart = false;

  get start(): boolean {
    return this.actualDropstart;
  }

  /**
   * Specifies whether sub menus should be displayed on the start side.
   * @defaultValue true
   * @defaultref {@link responsiveDropstart}
   * @deprecated Use the new Input {@link responsiveDropstart}
   */
  @Input() set responsiveDropleft(value: boolean) {
    this.responsiveDropstart = value;
  }

  get responsiveDropleft(): boolean {
    return this.responsiveDropstart;
  }

  /**
   * If `true`, the sub-menus will automatically open on the other side, if there is not enough space on the preferred one.
   *
   * @defaultValue true
   */
  @Input({ transform: booleanAttribute }) responsiveDropstart = true;

  /**
   * If `true`, the sub-menus will automatically open upwards, if there is not enough space below.
   *
   * @defaultValue true
   */
  @Input({ transform: booleanAttribute }) responsivelyDropUpwards = true;

  /**
   * Specifies an optional parameter that will be passed to the action function
   * of items that implement it.
   */
  @Input() actionParam?: any;

  /**
   * Specifies whether the component should automatically be focused as soon as it is loaded.
   *
   * @defaultValue false
   */
  @Input({ transform: booleanAttribute }) focusOnLoad = false;

  /**
   * Specifies if any and which item should be focused when the component is focused.
   *
   * @defaultValue ''
   */
  @Input() focus: '' | 'first' | 'last' = '';

  private _focusLastFromParent = false;

  /**
   * Specifies if the last item should be focused when navigating to this menu from a parent list (instead of the first).
   * @defaultValue false
   * @defaultref {@link _focusLastFromParent}
   */
  @Input() set focusLastFromParent(focusLastFromParent: boolean) {
    this._focusLastFromParent = focusLastFromParent;
    if (this.interactableList) {
      this.interactableList.focusLastFromParent.next(focusLastFromParent);
    }
  }

  get focusLastFromParent(): boolean {
    return this._focusLastFromParent;
  }

  /**
   * Specifies whether the menu is a sub list and a parent item should automatically be searched or be defined.
   *
   * @defaultValue false
   */
  @Input({ transform: booleanAttribute }) isSubList = false;

  private _parentItem: InteractableListItemDirective | null = null;

  /**
   * Specifies a specific item that is the parent of the menu if the automatic one is incorrect.
   * @defaultValue null
   * @defaultref {@link _parentItem}
   */
  @Input() set parentItem(item: InteractableListItemDirective | null) {
    this._parentItem = item;
    if (this.interactableList) {
      this.interactableList.triggerDefined.next(item);
    }
  }
  get parentItem(): InteractableListItemDirective | null {
    if (!this._parentItem && this.interactableList?.trigger) {
      return this.interactableList.trigger.directive;
    }
    return this._parentItem;
  }

  /**
   * Automatically refocus after pressing enter on one of the items.
   * Use when losing focus causes issues with keyboard interaction.
   *
   * @defaultValue true
   */
  @Input({ transform: booleanAttribute }) refocusAfterEnter = true;

  /**
   * @defaultValue 'vert'
   */
  orientation: InteractableListOrientation = 'vert';

  private _position = 'integrated' as InteractableListPosition;

  /**
   * Specifies the position of the list relative to the parent.
   * Only relevant if this is a sub list and the parent has a different orientation.
   * @defaultref {@link _position}
   */
  @Input() set position(position: InteractableListPosition) {
    this._position = position;
    if (this.interactableList) {
      this.interactableList.position.next(position);
    }
  }

  get position(): InteractableListPosition {
    return this._position;
  }

  /** @defaultValue false */
  @Input({ transform: booleanAttribute }) autofocusFromParent = false;

  /**
   * Specifies whether the menu should use relative positioning and be open by default.
   * Used when not combined with a dropdown.
   *
   * @defaultValue false
   */
  @HostBinding('class.dropdown-menu-relative')
  @Input({ transform: booleanAttribute })
  relativePositioning = false;

  /**
   * Force-show title, when iconOnly is set
   *
   * @defaultValue false
   */
  @Input({ transform: booleanAttribute }) forceTitle = false;

  @Output() readonly activeChange = new EventEmitter<boolean>();

  /** @defaultValue false */
  @HostBinding('class.show') keepOpen = false;
  /** @defaultValue '' */
  @HostBinding('attr.tabindex') tabindex = '';

  private interactableList!: InteractableList;

  linkActiveChange(active: boolean): void {
    const newActive =
      active ||
      this.interactableList.items.some(
        item => (item.directive as SiMenuLegacyItemComponent).active
      ) ||
      this.interactableList.nested.some(list => (list.directive as SiMenuLegacyComponent).active) ||
      false;
    if (newActive !== this.active) {
      this.active = newActive;
      this.activeChange.emit(this.active);
    }
  }

  /** @defaultValue false */
  active = false;

  get parentList(): InteractableListDirective | null {
    if (this.interactableList?.parent) {
      return this.interactableList.parent.directive;
    }
    return null;
  }

  get parentListAll(): InteractableListDirective | null {
    if (
      this.interactableList &&
      (this.interactableList.parent || this.interactableList.parentDisconnected)
    ) {
      return this.interactableList.parent
        ? this.interactableList.parent.directive
        : this.interactableList.parentDisconnected!.directive;
    }
    return null;
  }

  private isBrowser = isPlatformBrowser(inject(PLATFORM_ID));
  private connectionsCalculated = false;
  private ignoreNextChange = false;

  private _keyManager!: FocusKeyManager<InteractableListItemDirective>;

  private _previousChild?: InteractableListDirective;
  private _ignoreKeydown?: KeyboardEvent = undefined;

  private _changeSubscription!: Subscription;
  private _tabOutSubscription!: Subscription;

  /** @defaultValue false */
  childrenHidden = false;
  private listenersSet = false;
  private observer: IntersectionObserver | undefined;
  private entries: {
    element: HTMLElement;
    itemElement: HTMLElement;
    item: MenuItem;
    isVisible: boolean;
  }[] = [];

  private _elementRef = inject(ElementRef<HTMLElement>);
  private _changeDetector = inject(ChangeDetectorRef);
  private listInteractionService = inject(SiListInteractionService);
  private zone = inject(NgZone);

  /**
   * Indicate whether the child menu open to left or right.
   *
   * @defaultValue false
   */
  actualDropstart = false;

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.items ||
      changes.dropstart ||
      changes.responsiveDropstart ||
      changes.responsivelyDropUpwards
    ) {
      this.actualDropstart = this.dropstart;
      this.observer?.disconnect();
      this.observer = undefined;
      this.childrenHidden = false;
      setTimeout(() => {
        this.entries = [];

        if (
          (!this.responsiveDropstart && !this.responsivelyDropUpwards) ||
          !this.hasAnyChildren() ||
          !this.isBrowser
        ) {
          return;
        }

        const menu = this.getHostElement();

        if (!menu?.firstElementChild) {
          return;
        }
        const children = Array.from(menu.firstElementChild.children);
        if (!children) {
          return;
        }
        this.items.forEach((item, index) => {
          const found = item.items?.length
            ? children[index]?.querySelector('SI-MENU-LEGACY')
            : undefined;
          if (found) {
            const foundItem = children[index]?.querySelector('SI-MENU-LEGACY-ITEM');
            if (foundItem) {
              this.entries.push({
                element: found as HTMLElement,
                itemElement: foundItem as HTMLElement,
                item,
                isVisible: false
              });
            }
          }
        });
        if (!this.entries.length) {
          return;
        }
        this.childrenHidden = true;
        this.observer = new IntersectionObserver(visibilities => {
          let didChanges = false;
          visibilities.forEach(visibility => {
            const entry = this.entries.find(item => item.element === visibility.target);
            if (entry) {
              if (visibility.isIntersecting) {
                if (!entry.isVisible) {
                  if (entry.itemElement !== this._keyManager.activeItem?.getHostElement()) {
                    this.closePreviousChild();
                  }
                  entry.isVisible = true;
                  didChanges = true;
                  if (this.responsivelyDropUpwards) {
                    this.responsivelyCheckIfUpwards(entry.element, entry.itemElement, entry.item);
                  }
                }
              } else if (entry.isVisible) {
                entry.isVisible = false;
                didChanges = true;
              }
            }
          });
          if (didChanges && (this.responsiveDropstart || this.responsivelyDropUpwards)) {
            const visibleEntries = this.entries
              .filter(item => item.isVisible)
              .map(item => item.element);
            if (visibleEntries.length) {
              if (this.responsiveDropstart) {
                this.responsivelyChangeDirection(visibleEntries);
              }
              if (this.childrenHidden) {
                this.childrenHidden = false;
              }
              if (!this.listenersSet && this.isBrowser) {
                this.zone.runOutsideAngular(() => {
                  // Add event listener to window as capturing to handle tested element scrolling
                  addEventListener('scroll', this.onParentScrollOrResize, true);
                  // here we _actually_ want window.resize, nothing else since this is about a fixed position
                  addEventListener('resize', this.onParentScrollOrResize);
                });
                this.listenersSet = true;
              }
            } else if (!this.childrenHidden) {
              this.childrenHidden = true;
            }
          }
        });

        this.entries.forEach(entry => {
          this.observer!.observe(entry.element);
        });
      });
    }
  }

  ngOnInit(): void {
    this.interactableList = this.listInteractionService.addInteractableList(
      this,
      this._elementRef.nativeElement,
      this._parentItem,
      this._position,
      'vert',
      this.focusLastFromParent,
      this.isSubList,
      () => this.calculated()
    );
    this.interactableList.orientation.next('vert');
    const newTabindex = !this.isSubList ? '0' : '';
    if (this.tabindex !== newTabindex) {
      this.tabindex = newTabindex;
    }
  }

  ngAfterViewInit(): void {
    this._keyManager = new FocusKeyManager(this.interactableList.allItemsQueryList).withWrap();
    this._changeSubscription = this._keyManager.change.subscribe(() => {
      if (!this.ignoreNextChange) {
        this.openAndCloseChildren();
        addEventListener('click', this.onClickOut);
      }
      this.ignoreNextChange = false;
    });
    this._tabOutSubscription = this._keyManager.tabOut.subscribe(() => {
      this.closePreviousChild();
      removeEventListener('click', this.onClickOut);
    });
  }

  ngOnDestroy(): void {
    this._changeSubscription?.unsubscribe();
    this._tabOutSubscription?.unsubscribe();
    if (this.interactableList) {
      this.listInteractionService.removeInteractableList(this.interactableList);
    }
    if (this.isBrowser) {
      removeEventListener('click', this.onClickOut);
      this.zone.runOutsideAngular(() => {
        removeEventListener('scroll', this.onParentScrollOrResize, true);
        removeEventListener('resize', this.onParentScrollOrResize);
      });
    }
  }

  calculated(): void {
    this.connectionsCalculated = true;
    const newTabindex = !this.isSubList || !this.parentList ? '0' : '';
    if (this.tabindex !== newTabindex) {
      this.tabindex = newTabindex;
    }
    if (this.isBrowser && document.activeElement === this.getHostElement()) {
      if (this.focus) {
        this.focusFirstItem('program', this.focus === 'last');
      }
    } else if (this.focusOnLoad) {
      this._elementRef.nativeElement.focus();
    }
  }

  /** Ignore the next keydown in this and all parent menus. Called from sub menu. */
  ignoreKeydown(event: KeyboardEvent): void {
    if (this.parentListAll) {
      (this.parentListAll as InteractableListDirective).ignoreKeydown(event);
    }
    this._ignoreKeydown = event;
  }

  /** Handle a keyboard event from the menu, delegating to the appropriate action. */
  @HostListener('keydown', ['$event'])
  handleKeydown(event: KeyboardEvent): void {
    if (this._ignoreKeydown?.key === event.key && this._ignoreKeydown.target === event.target) {
      this._ignoreKeydown = undefined;
    } else {
      if (this.parentListAll) {
        // If this is a sub menu ignore the keydown in all parent menus (due to event bubbling).
        this.parentListAll.ignoreKeydown(event);
      }
      const key = event.key;
      const manager = this._keyManager;

      const rtlCorrectedKey = correctKeyRTL(key);

      switch (rtlCorrectedKey) {
        case 'Escape':
          if (!event.altKey && !event.shiftKey && !event.ctrlKey && !event.metaKey) {
            event.preventDefault();
            this.goDownLevel();
          }
          break;
        case 'ArrowLeft':
          if (this.actualDropstart) {
            this.goUpLevel(false, true);
          } else {
            this.goDownLevel();
          }
          break;
        case 'ArrowRight':
          if (this.actualDropstart) {
            this.goDownLevel(false, true);
          } else {
            this.goUpLevel();
          }
          break;
        case 'Enter':
          if (this.refocusAfterEnter && this.isBrowser) {
            setTimeout(() => {
              if (!document.activeElement || document.activeElement === document.body) {
                this.focusActiveItem();
              }
            });
          }
          break;
        default:
          manager.onKeydown(event);
      }
    }
  }

  hasChildren(item: MenuItem): boolean {
    return !!item.items?.length && item.items.some(child => child.title && child.title !== '-');
  }

  private closePreviousChild(): void {
    if (this._previousChild) {
      this._previousChild.close();
      this._previousChild = undefined;
      this._changeDetector.detectChanges();
    }
  }

  private openAndCloseChildren(): void {
    this.closePreviousChild();
    const active = this._keyManager.activeItem;
    if (active) {
      const activeList = active.childList;
      if (activeList && activeList.getHostElement() !== this.getHostElement()) {
        activeList.open();
        this._previousChild = activeList;
        this._changeDetector.detectChanges();
      }
    }
  }

  open(): void {
    this.keepOpen = true;
  }

  close(): void {
    this.keepOpen = false;
  }

  setActiveItem(activeItem: InteractableListItemDirective, ignoreNextChange = false): void {
    if (
      !this._keyManager.activeItem ||
      activeItem.getHostElement() !== this._keyManager.activeItem.getHostElement()
    ) {
      this.ignoreNextChange = ignoreNextChange;
      this._keyManager.setActiveItem(activeItem);
    }
  }

  focusActiveItem(origin: FocusOrigin = 'program'): void {
    this._keyManager.activeItem?.focus();
  }

  /**
   * Focus the first item in the menu.
   * @param origin - Action from which the focus originated. Used to set the correct styling.
   */
  focusFirstItem(origin: FocusOrigin = 'program', reverse = false, fromParent = false): void {
    const itemIndexes = Array.from(this.interactableList.allItemsQueryList)
      .map((item, index) => {
        if (
          item.parentList &&
          !item.disabled &&
          item.parentList.getHostElement() === this.getHostElement()
        ) {
          return index;
        } else {
          return -1;
        }
      })
      .filter(index => index !== -1);
    if (itemIndexes.length > 0) {
      let actualReverse = reverse;
      if (fromParent && this.focusLastFromParent) {
        actualReverse = !actualReverse;
      }
      const actualIndex = actualReverse ? itemIndexes[itemIndexes.length - 1] : itemIndexes[0];
      const activeItem = this.interactableList.allItemsQueryList.get(actualIndex)!;
      if (this._keyManager.activeItemIndex === actualIndex) {
        this.focusActiveItem();
        this.openAndCloseChildren();
        addEventListener('click', this.onClickOut);
      } else {
        this._keyManager.setFocusOrigin(origin).setActiveItem(activeItem);
      }
      const activeItemHost = activeItem.getHostElement();
      if (
        this.interactableList.parent?.integratedNestedItems.find(
          item => item.element === activeItemHost
        )
      ) {
        this.parentList!.setActiveItem(activeItem);
      } else if (this.interactableList.integratedParentItem) {
        this.parentList!.setActiveItem(this.interactableList.integratedParentItem.directive);
      }
    }
  }

  private hasAnyChildren(): boolean {
    return this.items.some(item => item.items?.length);
  }

  private levelOfChildren(
    counter = -1,
    items: MenuItem[] = this.entries.filter(entry => entry.isVisible).map(entry => entry.item)
  ): number {
    return Math.max(
      0,
      ...items.map(subitem => {
        if (!subitem.disabled && subitem.items?.length) {
          return this.levelOfChildren(counter + 1, subitem.items);
        } else {
          return counter + 1;
        }
      })
    );
  }

  private onParentScrollOrResize = (): void => {
    if (this.responsiveDropstart || this.responsivelyDropUpwards) {
      const visibleEntries = this.entries.filter(item => item.isVisible);
      if (visibleEntries.length) {
        setTimeout(() => {
          this.zone.run(() => {
            if (this.responsiveDropstart) {
              this.responsivelyChangeDirection(visibleEntries.map(item => item.element));
            }
            if (this.responsivelyDropUpwards) {
              visibleEntries.forEach(entry => {
                this.responsivelyCheckIfUpwards(entry.element, entry.itemElement, entry.item);
              });
            }
          });
        });
      }
    }
  };

  responsivelyChangeDirection(elements: HTMLElement[]): void {
    const menu = this.getHostElement();
    const levelOfChildren = this.levelOfChildren();

    const { responsiveDirection } = responsivelyCheckDirection({
      isScrolling: false,
      currentDirection: this.dropstart ? 'start' : 'end',
      contentElements: elements,
      hostElement: menu,
      placement: '',
      placementReferenceElement: menu,
      align: 'center',
      responsiveDirectionToPlacement: false,
      closeOnPlacementReferenceScrollOut: false,
      closeOnContentScrollOut: false,
      minSpaceThresholdFactor: levelOfChildren > 1 ? levelOfChildren - 1 : undefined
    });

    const newDropstart = responsiveDirection === 'start';

    if (this.actualDropstart !== newDropstart) {
      this.actualDropstart = newDropstart;
    }
    this._changeDetector.markForCheck();
  }

  private responsivelyCheckIfUpwards(
    element: HTMLElement,
    itemElement: HTMLElement,
    item: MenuItem
  ): void {
    const { responsiveDirection } = responsivelyCheckDirection({
      isScrolling: false,
      currentDirection: item.dropUpwards ? 'up' : 'down',
      contentElements: [element],
      hostElement: itemElement as HTMLElement,
      placement: 'top',
      placementReferenceElement: itemElement,
      align: 'center',
      responsiveDirectionToPlacement: false,
      closeOnPlacementReferenceScrollOut: false,
      closeOnContentScrollOut: false,
      placementReverse: 'bottom',
      rtl: false
    });

    const newDropUpwards = responsiveDirection === 'up';

    if (item.dropUpwards !== newDropUpwards) {
      item.dropUpwards = newDropUpwards;
      const recursivelyApply = (subitem: MenuItem): void => {
        if (subitem.items?.length) {
          subitem.items?.forEach(subsubitem => {
            subsubitem.dropUpwards = newDropUpwards;
            if (!subsubitem.disabled) {
              recursivelyApply(subsubitem);
            }
          });
        }
      };
      recursivelyApply(item);
    }
  }

  private goDownLevel(reverse = false, start = false): void {
    if (this.parentList) {
      this.closePreviousChild();
      const nestedOrParentItem = this.interactableList
        ? (this.interactableList.integratedParentItem
            ? this.interactableList.integratedParentItem
            : this.interactableList.items.find(
                item => item.parentIntegrationIndex !== -1 || item.nestedIntegration
              )
          )?.directive
        : undefined;
      let actualReverse = reverse;
      const parent = this.parentList as InteractableListDirective;
      if (parent.orientation === 'ets') {
        actualReverse = !actualReverse;
      }
      if (start && !parent.start) {
        actualReverse = !actualReverse;
      }
      parent.focusActiveItem('program', actualReverse, nestedOrParentItem);
    } else {
      this.goUpLevel(true);
    }
  }

  private goUpLevel(reverse = false, start = false): void {
    const active = this._keyManager.activeItem;
    if (active) {
      const activeList = active.childList;
      if (activeList) {
        activeList.focusFirstItem('program', reverse, true);
      } else if (this.parentList) {
        this.goDownLevel(true, start);
      }
    }
  }

  private onClickOut = (event: MouseEvent): void => this.clickOutHandler(event);

  private clickOutHandler(event: MouseEvent): void {
    const target = event.target as HTMLElement;
    if (this.interactableList && !this._keyManager?.activeItem?.hasChildElement(target)) {
      this.closePreviousChild();
      removeEventListener('click', this.onClickOut);
    }
  }

  @HostListener('focusin', ['$event.target'])
  focusInEvent(target: EventTarget): void {
    if (this.connectionsCalculated) {
      if (target === this.getHostElement()) {
        if (this.focus) {
          this.focusFirstItem('program', this.focus === 'last');
        }
      } else {
        if (this.interactableList) {
          const foundItem = this.interactableList.items.find(item =>
            item.element.contains(target as HTMLElement)
          );
          if (foundItem) {
            this.setActiveItem(foundItem.directive);
          }
        }
        if (this.tabindex !== '-1') {
          this.tabindex = '-1';
        }
      }
    }
  }

  @HostListener('focusout')
  focusOutEvent(): void {
    if (this.tabindex === '-1') {
      Promise.resolve().then(
        () => (this.tabindex = !this.isSubList || !this.parentList ? '0' : '')
      );
    }
  }

  /** Returns the host DOM element. */
  getHostElement(): HTMLElement {
    return this._elementRef.nativeElement!;
  }
}
