<div
  class="form-group mb-6 position-relative focus-none"
  tabindex="-1"
  [siPopover]="aboutPopOver"
  placement="bottom"
  triggers=""
  container="body"
  containerClass="si-property-info-popover mt-0"
  [outsideClick]="true"
  #popExpander="si-popover"
>
  <div class="label-container">
    @if (property?.name) {
      <span class="form-label">{{ property.name }}</span>
    }
    @if (isActive && valueInfo) {
      <span class="value-info">{{ valueInfo }}</span>
    }

    @if (property?.actions) {
      <si-content-action-bar
        [hidden]="isActive"
        [viewType]="viewType"
        [primaryActions]="primaryActions"
        [secondaryActions]="property.actions"
        [actionParam]="property"
      />
    }
  </div>

  <div
    #valueContent
    class="value-content"
    [class.has-buttons]="overrideMode !== 'direct'"
    [class.active]="isActive"
    (keydown.escape)="escape($event)"
  >
    <div class="value-content-inner">
      <div class="position-relative" (click)="openClick($event)">
        <ng-content select="[input]" />
        <i class="value-status-icon" [ngClass]="valueIcon"></i>
      </div>

      @if (isActive) {
        <div class="px-5 value-body">
          <ng-content select="[body]" />
          @if (overrideMode !== 'direct') {
            <div class="text-right text-end d-flex justify-content-end pb-5">
              @if (
                property?.overridden &&
                (overrideMode === 'normal' ||
                  overrideMode === 'warning' ||
                  overrideMode === 'danger')
              ) {
                <button
                  type="button"
                  class="btn btn-circle btn-sm me-auto release-button"
                  [ngClass]="{
                    'btn-secondary': overrideMode === 'normal',
                    'btn-warning': overrideMode === 'warning',
                    'btn-danger': overrideMode === 'danger',
                    'element-manual': overrideMode === 'normal',
                    'element-manual-filled': overrideMode === 'warning' || overrideMode === 'danger'
                  }"
                  (click)="release()"
                >
                  <span class="visually-hidden">{{ dialogTextRelease | translate }}</span>
                </button>
              }
              <button
                type="button"
                class="btn btn-circle btn-sm btn-secondary element-cancel mr-3 me-3"
                (click)="cancel()"
              >
                <span class="visually-hidden">{{ dialogTextCancel | translate }}</span>
              </button>
              <button
                type="button"
                [ngClass]="{
                  'btn-warning': overrideMode === 'warning',
                  'btn-danger': overrideMode === 'danger',
                  'btn-primary': overrideMode !== 'warning' && overrideMode !== 'danger'
                }"
                class="btn btn-circle btn-sm element-ok"
                [disabled]="submitDisabled"
                (click)="submit()"
              >
                <span class="visually-hidden">{{ dialogTextOk | translate }}</span>
              </button>
            </div>
          }
        </div>
      }
    </div>
  </div>
</div>

<ng-template #aboutPopOver>
  <ng-container #aboutPropertyTemplate cdkPortalOutlet (siClickOutside)="closePopups()" />
</ng-template>

<ng-template #dangerModalTemplate>
  <div class="modal-header">
    <h4 class="modal-title" [id]="dangerModalTitleId">
      {{ dialogTextDangerTitle | translate }}
    </h4>
    <button
      type="button"
      class="close btn-close"
      (click)="closeDanger()"
      [attr.aria-label]="dialogTextCancel | translate"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{ dialogTextDangerTextSafetyFunctions | translate }}</p>
    <p>{{ dialogTextDangerTextProceed | translate }}</p>
  </div>
  <div class="modal-footer text-right text-end p-5">
    <button type="button" class="btn btn-secondary mr-5 me-5" (click)="closeDanger()">
      {{ dialogTextCancel | translate }}
    </button>
    <button type="button" class="btn btn-danger" (click)="submitDanger()">
      {{ dialogTextOk | translate }}
    </button>
  </div>
</ng-template>
