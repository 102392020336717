import { AsyncPipe } from '@angular/common';
import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  inject,
  Input,
  Output,
  TemplateRef
} from '@angular/core';
import { SiAutoCollapsableListModule } from '@simpl/element-ng/auto-collapsable-list';
import { SiTranslateModule, TranslatableString } from '@simpl/element-translate-ng/translate';

import {
  SI_SELECT_OPTIONS_STRATEGY,
  SiSelectOptionsStrategy
} from '../options/si-select-options-strategy';
import { SiSelectOptionComponent } from '../select-option/si-select-option.component';
import { SiSelectSelectionStrategy } from '../selection/si-select-selection-strategy';
import { SelectOptionNext } from '../si-select.types';

@Component({
  selector: 'si-select-input',
  standalone: true,
  imports: [SiAutoCollapsableListModule, SiSelectOptionComponent, SiTranslateModule, AsyncPipe],
  templateUrl: './si-select-input.component.html',
  styleUrl: './si-select-input.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    role: 'combobox',
    class: 'select focus-none dropdown-toggle d-flex align-items-center ps-4',
    'aria-autocomplete': 'none',
    'aria-haspopup': 'listbox'
  }
})
export class SiSelectInputComponent<T> {
  @Input({ required: true }) baseId!: string;
  /**
   * Aria labelledby of the select.
   *
   * @defaultValue null
   */
  @Input() labelledby: string | null = null;
  /**
   * Aria label of the select.
   *
   * @defaultValue null
   */
  @Input() ariaLabel: string | null = null;
  /** @defaultValue false */
  @HostBinding('class.active')
  @HostBinding('attr.aria-expanded')
  @Input({ transform: booleanAttribute })
  open = false;
  @Input() placeholder?: TranslatableString;
  @Input({ required: true }) @HostBinding('attr.aria-controls') controls!: string;
  @Input() optionTemplate?: TemplateRef<{ $implicit: SelectOptionNext<T> }>;

  /** @defaultValue false */
  @Input({ transform: booleanAttribute }) @HostBinding('attr.aria-readonly') readonly = false;

  @Output() readonly openListbox = new EventEmitter<void>();
  protected selectionStrategy = inject<SiSelectSelectionStrategy<T>>(SiSelectSelectionStrategy<T>);
  private selectOptions = inject<SiSelectOptionsStrategy<T>>(SI_SELECT_OPTIONS_STRATEGY);
  protected selectedRows = this.selectOptions.selectedRows;

  @HostListener('blur')
  protected blur(): void {
    if (!this.open) {
      this.selectionStrategy.onTouched();
    }
  }

  @HostBinding('tabindex')
  protected get tabindex(): string {
    return this.selectionStrategy.disabled ? '-1' : '0';
  }

  @HostBinding('attr.aria-labelledby')
  protected get hostLabelledby(): string {
    return `${this.baseId}-aria-label ${this.labelledby}`;
  }

  @HostBinding('attr.aria-disabled')
  @HostBinding('class.disabled')
  protected get disabled(): boolean {
    return this.selectionStrategy.disabled;
  }

  @HostListener('click')
  @HostListener('keydown.arrowDown', ['$event'])
  @HostListener('keydown.alt.arrowDown', ['$event'])
  @HostListener('keydown.arrowUp', ['$event'])
  @HostListener('keydown.enter')
  @HostListener('keydown.space')
  protected click(event?: Event): void {
    event?.preventDefault();
    this.openListbox.emit();
  }
}
