import { NgModule } from '@angular/core';
import { SiAccordionModule } from '@simpl/element-ng/accordion';
import { SiBreadcrumbModule } from '@simpl/element-ng/breadcrumb';
import { SiBreadcrumbRouterModule } from '@simpl/element-ng/breadcrumb-router';
import { SiCardModule } from '@simpl/element-ng/card';
import { SiContentActionBarModule } from '@simpl/element-ng/content-action-bar';
import { SiCopyrightNoticeModule } from '@simpl/element-ng/copyright-notice';
import { SiDropdownModule } from '@simpl/element-ng/dropdown';
import { SiFormModule } from '@simpl/element-ng/form';
import { SiIconModule } from '@simpl/element-ng/icon';
import { SiInlineNotificationModule } from '@simpl/element-ng/inline-notification';
import { SiLanguageSwitcherModule } from '@simpl/element-ng/language-switcher';
import { SiLinkModule } from '@simpl/element-ng/link';
import { SiListInteractionModule } from '@simpl/element-ng/list-interaction';
import { SiLoadingSpinnerModule } from '@simpl/element-ng/loading-spinner';
import { SiLocalizationModule } from '@simpl/element-ng/localization';
import { SiMenuLegacyModule } from '@simpl/element-ng/menu-legacy';
import { SiModalModule } from '@simpl/element-ng/modal';
import { SiNavbarModule } from '@simpl/element-ng/navbar';
import { SiNavbarSecondaryModule } from '@simpl/element-ng/navbar-secondary';
import { SiNavbarVerticalModule } from '@simpl/element-ng/navbar-vertical';
import { SiNumberInputModule } from '@simpl/element-ng/number-input';
import { SiPopoverModule } from '@simpl/element-ng/popover';
import { SiProgressbarModule } from '@simpl/element-ng/progressbar';
import { SiResizeObserverModule } from '@simpl/element-ng/resize-observer';
import { SiSearchBarModule } from '@simpl/element-ng/search-bar';
import { SiSelectModule } from '@simpl/element-ng/select';
import { SiSplitModule } from '@simpl/element-ng/split';
import { SiStatusBarModule } from '@simpl/element-ng/status-bar';
import { SiTabsModule } from '@simpl/element-ng/tabs';
import { SiTypeaheadModule } from '@simpl/element-ng/typeahead';

import { SiAboutModule } from './about/index';
import { SiActionDialogModule } from './action-modal/index';
import { SiCircleStatusModule } from './circle-status/index';
import { SiColumnSelectionModalModule } from './column-selection-dialog/index';
import { SiComfortScoreModule } from './comfort-score/index';
import { SiCommissioningStatusModule } from './commissioning-status/index';
import { SiConnectionStrengthModule } from './connection-strength/index';
import { SiDateRangeFilterModule } from './date-range-filter/index';
import { SiDatepickerModule } from './datepicker/index';
import { SiElectrontitlebarModule } from './electron-titlebar/index';
import { SiEmptyStateModule } from './empty-state/index';
import { SiFileUploaderModule } from './file-uploader/index';
import { SiFilterBarModule } from './filter-bar/index';
import { SiFilteredSearchModule } from './filtered-search/index';
import { SiFooterModule } from './footer/index';
import { SiIconStatusModule } from './icon-status/index';
import { SiLandingPageModule } from './landing-page/index';
import { SiMainDetailContainerModule } from './main-detail-container/index';
import { SiPaginationModule } from './pagination/index';
import { SiPasswordStrengthModule } from './password-strength/index';
import { SiPasswordToggleModule } from './password-toggle/index';
import { SiPillsInputModule } from './pills-input/index';
import { SiPromptDialogButtonsModule } from './prompt-dialog-buttons/index';
import { SiResultDetailsListModule } from './result-details-list/index';
import { SiSidePanelModule } from './side-panel/index';
import { SiSliderModule } from './slider/index';
import { SiSortBarModule } from './sort-bar/index';
import { SiThresholdModule } from './threshold/index';
import { SiTooltipModule } from './tooltip/index';
import { SiTreeViewModule } from './tree-view/index';
import { SiUnauthorizedPageModule } from './unauthorized-page/index';
import { SiWizardModule } from './wizard/index';

@NgModule({
  // Since this a library, which does not have any pages, imports are not needed
  exports: [
    SiAboutModule,
    SiAccordionModule,
    SiActionDialogModule,
    SiBreadcrumbModule,
    SiBreadcrumbRouterModule,
    SiCardModule,
    SiCircleStatusModule,
    SiColumnSelectionModalModule,
    SiComfortScoreModule,
    SiCommissioningStatusModule,
    SiConnectionStrengthModule,
    SiContentActionBarModule,
    SiCopyrightNoticeModule,
    SiDatepickerModule,
    SiDateRangeFilterModule,
    SiDropdownModule,
    SiElectrontitlebarModule,
    SiEmptyStateModule,
    SiFileUploaderModule,
    SiFilterBarModule,
    SiFilteredSearchModule,
    SiFooterModule,
    SiFormModule,
    SiIconModule,
    SiIconStatusModule,
    SiInlineNotificationModule,
    SiLandingPageModule,
    SiLanguageSwitcherModule,
    SiLinkModule,
    SiListInteractionModule,
    SiLoadingSpinnerModule,
    SiLocalizationModule,
    SiMainDetailContainerModule,
    SiMenuLegacyModule,
    SiModalModule,
    SiNavbarModule,
    SiNavbarSecondaryModule,
    SiNavbarVerticalModule,
    SiNumberInputModule,
    SiPaginationModule,
    SiPasswordStrengthModule,
    SiPasswordToggleModule,
    SiPillsInputModule,
    SiPopoverModule,
    SiProgressbarModule,
    SiPromptDialogButtonsModule,
    SiResizeObserverModule,
    SiResultDetailsListModule,
    SiSearchBarModule,
    SiSelectModule,
    SiSidePanelModule,
    SiSliderModule,
    SiSortBarModule,
    SiSplitModule,
    SiStatusBarModule,
    SiTabsModule,
    SiThresholdModule,
    SiTooltipModule,
    SiTreeViewModule,
    SiTypeaheadModule,
    SiUnauthorizedPageModule,
    SiWizardModule
  ]
})
export class SimplElementNgModule {}
