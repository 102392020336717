<si-property-popover
  #popover
  [property]="property"
  [valueState]="valueState"
  [modalTemplate]="modalTemplate"
  [forceReadonly]="forceReadonly"
  [displayOnly]="customToggle"
  (stateChange)="stateChange($event)"
>
  <div
    class="form-control d-flex align-items-center"
    tabindex="0"
    [class.custom-toggle]="customToggle"
    (keydown.enter)="!customToggle ? popover?.open(true) : undefined"
    input
  >
    <span>
      @if (!property.defaultText && property.value.value !== undefined) {
        {{ displayValue }}
      } @else {
        {{ property.defaultText }}
      }
      <ng-template #defaultText>{{ property.defaultText }}</ng-template>
    </span>
  </div>
</si-property-popover>

<ng-template #modalTemplate>
  <div class="modal-header">
    <h4 class="modal-title" [id]="popover?.modalTitleId">{{ property.name }}</h4>
    <button
      type="button"
      class="close btn-close"
      (click)="popover?.close()"
      [attr.aria-label]="dialogTextClose | translate"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body p-0" tabindex="0">
    @if (editValue) {
      <si-property-container class="inline-table" [overrideMode]="'direct'">
        @for (item of editValue; track $index) {
          <div
            class="p-5 table-row d-flex align-items-center"
            [class.is-array]="property.value.collectionType === 'array'"
          >
            <span class="text-secondary badge-table">{{ item.position }}</span>
            <div class="w-100">
              <si-property
                [property]="item"
                [dateFormat]="dateFormat"
                [timeFormat]="timeFormat"
                [forceReadonly]="readonly"
              />
            </div>
          </div>
        }
      </si-property-container>
    }
  </div>
  <div class="modal-footer text-right text-end p-5">
    @if (!readonly && popover?.overrideMode !== 'direct') {
      <div class="d-flex justify-content-end">
        @if (
          property.overridden &&
          (property.overrideMode === 'normal' ||
            property.overrideMode === 'warning' ||
            property.overrideMode === 'danger')
        ) {
          <button
            type="button"
            class="btn btn-circle btn-sm me-auto release-button"
            [ngClass]="{
              'btn-secondary': property.overrideMode === 'normal',
              'btn-warning': property.overrideMode === 'warning',
              'btn-danger': property.overrideMode === 'danger',
              'element-manual': property.overrideMode === 'normal',
              'element-manual-filled':
                property.overrideMode === 'warning' || property.overrideMode === 'danger'
            }"
            (click)="popover?.release()"
          >
            <span class="visually-hidden">{{ dialogTextRelease | translate }}</span>
          </button>
        }
        <button
          type="button"
          class="btn btn-circle btn-sm btn-secondary element-cancel mr-3 me-3"
          (click)="popover?.cancel()"
        >
          <span class="visually-hidden">{{ dialogTextCancel | translate }}</span>
        </button>
        <button
          type="button"
          [ngClass]="{
            'btn-warning': property.overrideMode === 'warning',
            'btn-danger': property.overrideMode === 'danger',
            'btn-primary': property.overrideMode !== 'warning' && property.overrideMode !== 'danger'
          }"
          class="btn btn-circle btn-sm element-ok"
          (click)="popover?.submit()"
        >
          <span class="visually-hidden">{{ dialogTextOk | translate }}</span>
        </button>
      </div>
    }
  </div>
</ng-template>
