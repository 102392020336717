import { Component, Input } from '@angular/core';
import { Link, SiLinkDirective } from '@simpl/element-ng/link';
import { SiTranslateModule } from '@simpl/element-translate-ng/translate';

@Component({
  selector: 'si-footer',
  templateUrl: './si-footer.component.html',
  styleUrl: './si-footer.component.scss',
  standalone: true,
  imports: [SiLinkDirective, SiTranslateModule]
})
export class SiFooterComponent {
  /**
   * Copyright of your application.
   */
  @Input({ required: true })
  copyright!: string;
  /**
   * List of additional links.
   */
  @Input() links?: Link[];
}
