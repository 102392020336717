<div class="item-wrapper">
  @if (icon && !badgeDotHasContent) {
    <span class="icon" aria-hidden="true" [ngClass]="icon" [class.badge-dot]="iconBadgeDot"></span>
  } @else if (icon && badgeDotHasContent) {
    <span class="icon badge-dot" aria-hidden="true" [ngClass]="icon"></span>
    <span class="badge-text">{{ iconBadgeDot }}</span>
  }
  <span class="item-title">
    <ng-content />
  </span>
  <div class="item-end ms-2 d-flex me-n3 gap-1">
    @if (badge) {
      <span class="mx-0 badge" [ngClass]="'bg-' + badgeColor">{{ badge }}</span>
    }
    @if (checked) {
      <div class="element-ok icon" aria-hidden="true"></div>
    }
  </div>
</div>
