import { NgTemplateOutlet } from '@angular/common';
import {
  AfterContentChecked,
  AfterViewInit,
  booleanAttribute,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  HostBinding,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  signal,
  SimpleChanges
} from '@angular/core';
import {
  FormControl,
  NgControl,
  RequiredValidator,
  ValidationErrors,
  ValidatorFn
} from '@angular/forms';
import { SiTranslateModule } from '@simpl/element-translate-ng/translate';

import { SiFormFieldsetComponent } from '../form-fieldset/si-form-fieldset.component';
import { SiFormContainerComponent } from '../si-form-container/si-form-container.component';
import { SI_FORM_ITEM_CONTROL, SiFormItemControl } from '../si-form-item.control';
import {
  SI_FORM_VALIDATION_ERROR_MAPPER,
  SiFormValidationErrorMapper
} from '../si-form-validation-error-mapper.provider';
import { SiFormFieldNativeControl } from './si-form-field-native.control';

/** @internal */
export interface SiFormError {
  message: string;
  params: any;
}

@Component({
  selector: 'si-form-item',
  styleUrl: '../si-form.shared.scss',
  templateUrl: './si-form-item.component.html',
  standalone: true,
  imports: [SiTranslateModule, NgTemplateOutlet],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SiFormItemComponent
  implements AfterViewInit, AfterContentChecked, OnChanges, OnInit, OnDestroy
{
  /** @deprecated property has longer an effect. SiFormItem detects IDs automatically  */
  @Input() inputId?: string;

  /**
   * The label to be displayed in the form item.
   * It will be translated if a translation key is available.
   */
  @Input() label?: string | null;

  /**
   * A custom width value to be applied to the label.
   * A value of 0 is allowed as well to visually hide away the label area.
   *
   * Numbers will be converted to pixels.
   * Using numbers is discouraged and might be dropped.
   *
   * @example labelWidth="100px"
   */
  @Input() labelWidth?: string | number;

  /**
   * @deprecated This input has no effect and can be removed.
   *
   * @defaultValue false
   */
  @Input({ transform: booleanAttribute }) readonly = false;

  /**
   * Disables the automatic error printing. Error printing will be enabled by default in v46.
   *
   * @defaultValue false
   */
  @Input({ transform: booleanAttribute }) disableErrorPrinting = false;

  @Input() formErrorMapper?: SiFormValidationErrorMapper;

  /**
   * Defines that this form item is required for the overall form to be valid.
   *
   * @defaultValue false
   */
  @HostBinding('class.required') @Input({ transform: booleanAttribute }) required = false;

  @ContentChild(SI_FORM_ITEM_CONTROL, { descendants: true })
  protected fieldControl?: SiFormItemControl;
  /** @internal */
  @ContentChild(NgControl, { descendants: true }) ngControl?: NgControl;
  @ContentChild(NgControl, { read: ElementRef, descendants: true })
  protected controlElementRef?: ElementRef<HTMLElement>;
  @ContentChild(RequiredValidator, { descendants: true })
  protected requiredValidator?: RequiredValidator;

  /** @internal */
  errors = signal<SiFormError[]>([]);

  protected fieldset = inject(SiFormFieldsetComponent, { optional: true });
  protected container = inject(SiFormContainerComponent, { optional: true });

  private applicationFormErrorMapper = inject(SI_FORM_VALIDATION_ERROR_MAPPER);
  private requiredTestControl = new FormControl('');
  private validator?: ValidatorFn | null;
  private previousErrors?: ValidationErrors | null;

  private changeDetectorRef = inject(ChangeDetectorRef);

  @HostBinding('style.--si-form-label-width')
  protected get labelWidthCssVar(): string | undefined {
    if (typeof this.labelWidth === 'number') {
      return `${this.labelWidth}px`;
    }

    return this.labelWidth;
  }

  protected get printErrors(): boolean {
    return !this.disableErrorPrinting && !(this.container?.disableErrorPrinting ?? false);
  }

  @HostBinding('class.form-check')
  protected get isCheckItem(): boolean {
    return this.fieldControl?.isFormCheck ?? false;
  }

  @HostBinding('class.form-check-inline') protected get isInline(): boolean {
    return this.fieldset?.inline ?? false;
  }

  @HostBinding('class.si-form-input') protected get isFormInput(): boolean {
    return !this.fieldset;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.formErrorMapper) {
      this.updateValidationMessages();
    }
  }

  ngOnInit(): void {
    this.fieldset?.registerFormItem(this);
  }

  ngAfterContentChecked(): void {
    this.updateRequiredState();
    this.updateValidationMessages();
  }

  ngAfterViewInit(): void {
    queueMicrotask(() => {
      this.changeDetectorRef.markForCheck();
      this.fieldControl ??= SiFormFieldNativeControl.createForElementRef(this.controlElementRef);
    });
  }

  ngOnDestroy(): void {
    this.fieldset?.unregisterFormItem(this);
  }

  private updateRequiredState(): void {
    if (this.requiredValidator) {
      // the easy case: required validator is applied in the template: <input required>
      const newRequired = booleanAttribute(this.requiredValidator.required);
      if (this.required !== newRequired) {
        this.required = newRequired;
        this.changeDetectorRef.markForCheck();
      }
    } else {
      // No required validator is applied via template, it could still be applied in the code.
      // If validators are applied via code, the validator object will change of controls.
      // So we only need to check for required if the validator object was changed.
      const validator = this.ngControl?.control?.validator;

      if (this.validator !== validator) {
        this.validator = validator;
        this.requiredTestControl.setValidators(this.validator ?? null);
        this.requiredTestControl.updateValueAndValidity();
        this.required = this.requiredTestControl.errors?.required ?? false;
        this.changeDetectorRef.markForCheck();
      }
    }
  }

  private updateValidationMessages(): void {
    const currentErrors = this.ngControl?.errors;

    if (this.printErrors && this.previousErrors !== currentErrors) {
      this.changeDetectorRef.markForCheck();
      this.previousErrors = currentErrors;
      if (this.previousErrors) {
        const containerMapper = this.container?.formErrorMapper;
        /*
         * Converts the angular error object (like: {required: true, minLength: {actualLength: 1, requiredLength: 3}})
         * to SiFormError[].
         * Therefore, the error key is look up in the error mappers.
         * If it can be resolved, the error will be printed using the resolved text.
         */
        this.errors.set(
          Object.entries(this.previousErrors)
            .map(([key, params]) => ({
              message:
                // lookup value in component specific map
                this.formErrorMapper?.[key] ??
                // Lookup value in the si-form-container mapper (optional).
                // This mapper currently includes the applicationFormErrorMapper.
                // The first look-up uses the full name: `controlName.errorKey`, the second one only the error key.
                containerMapper?.[`${this.ngControl?.name}.${key}`] ??
                containerMapper?.[key] ??
                // form-item can be used standalone, so we need to check to global mapper again
                this.applicationFormErrorMapper?.[`${this.ngControl?.name}.${key}`] ??
                this.applicationFormErrorMapper?.[key],
              params
            }))
            .filter(error => !!error.message) as SiFormError[]
        );
      } else {
        this.errors.set([]);
      }
    }
  }
}
