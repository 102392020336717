<div class="position-relative" [class.disabled]="disabled" [class.focus]="inFocus">
  @if (showIcon) {
    <i class="search-bar-icon icon element-search text-secondary" aria-hidden="true"></i>
  }
  <input
    #inputRef
    type="text"
    class="form-control"
    [placeholder]="placeholder"
    [class.dark-background]="colorVariant === 'base-0'"
    [class.ps-9]="showIcon"
    [class.is-invalid]="isInvalid"
    [class.icon-end]="value"
    [attr.tabindex]="tabbable ? '' : '-1'"
    [readonly]="readonly"
    (focus)="inFocus = true"
    (blur)="inFocus = false"
    (input)="input($event)"
  />
  @if (value) {
    <button
      type="button"
      aria-label="clear"
      class="btn btn-circle btn-xs btn-ghost cancel-button element-cancel"
      [disabled]="disabled || readonly"
      (click)="resetForm($event)"
      (focusin)="onCancelFocus($event)"
    ></button>
  }
</div>
