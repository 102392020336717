import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { SiteSelectionServiceBase } from './site-selection.service.base';
/**
 * Implementation for site selection handling.
 *
 * @export
 * @class SiteSelectionService
 */
@Injectable({
  providedIn: 'root'
})
export class SiteSelectionService extends SiteSelectionServiceBase {

  private readonly _selectedSite: BehaviorSubject<{ objectId: string | undefined; singleSiteActive: boolean | undefined }>
    = new BehaviorSubject<{ objectId: string | undefined; singleSiteActive: boolean | undefined }>({
      objectId: undefined,
      singleSiteActive: undefined
    });

  public setSite(objectId: string | undefined, singleSiteActive: boolean | undefined): void {
    this._selectedSite.next({ objectId, singleSiteActive });
  }

  public get selectedSite(): Observable<{ objectId: string | undefined, singleSiteActive: boolean | undefined }> {
    return this._selectedSite.asObservable();
  }
} 
