import { Directive, ElementRef, EventEmitter, HostListener, inject, Output } from '@angular/core';

@Directive({
  selector: '[siClickOutside]',
  standalone: true
})
export class SiClickOutsideDirective {
  private elementRef = inject(ElementRef);

  @Output() readonly siClickOutside = new EventEmitter<MouseEvent>();

  @HostListener('document:click', ['$event', '$event.target'])
  protected onClick(event: MouseEvent, targetElement: HTMLElement): void {
    if (!targetElement) {
      return;
    }
    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.siClickOutside.emit(event);
    }
  }
}
