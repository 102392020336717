import { Command, CommandInput } from '@gms-flex/services';
import { isNullOrUndefined, TraceService } from '@gms-flex/services-common';
import { parseLong } from '@gms-flex/snapin-common';
import { AnyCommandingProperty, AnyPropertyValueType, Property } from '@simpl/buildings-ng';
import { AnyBitStringValue, AnyEnumValue, AnyPriorityArrayValue, AnyValueType, BigNumberValue, BooleanValue,
  DateTimeValue, DateValue, NumberValue, PriorityArrayItem, RecordCollectionValue, StringValue,
  TimeDurationValue, TimeValue, ValueBase } from '@simpl/element-value-types';
import * as Long from 'long';
import { Observable, throwError } from 'rxjs';

import { Common } from '../shared/common';
import { TraceModules } from '../shared/trace-modules';
import { CommandInvocState } from './command-invoc-vm';
import { EnumParamViewModel } from './command-param-enum-vm';
import { LongParamViewModel } from './command-param-long-vm';
import { NumericParamViewModel } from './command-param-numeric-vm';
import { CommandParamViewModel } from './command-param-vm';
import { CommandViewModel } from './command-vm';
import { DateTimeType } from './command-vm.types';
import { PropertyValueType } from './data-model-helper';
import { PropertyDefinition } from './property-definition-vm';
import { PropertyCommandResult } from './property-vm.types';
import { ServiceStore, ViewModelContext } from './snapin-vm.types';

/**
 * Property instance view-model.
 */
export abstract class PropertyInstance {

  public isHiddenByFilter: boolean;

  protected readonly trmod: string = TraceModules.pvc;

  protected val: string;
  protected valArr: string[];
  protected valActiveArr: boolean[];
  protected sizeArr: number;

  protected defLocal: PropertyDefinition;

  protected cmdVmList: CommandViewModel[];

  public siProperty: AnyCommandingProperty;

  /**
   * Locale to use for formatting.
   */
  public get locale(): string {
    return this.vmContext.locale;
  }

  /**
   * Array size.
   * This is a property of the instance (not definition, as you might expect) due to the
   * fact that array size cannot be reliably determined until a COV is received.  Until
   * that time, we use the less-reliable default-size in the property definition, which is
   * determined by the size of the element-label array.
   */
  public get sizeArray(): number {
    let sz: number = this.def.sizeArrayDefault;
    if (this.def.isArray && !isNaN(this.sizeArr) && this.sizeArr > 0) {
      sz = this.sizeArr;
    }
    return sz;
  }

  /**
   * Bitstring offset.
   * Appears here instead of property definition just to be in harmony with `size` property.
   */
  // public get offset(): number {
  //   return this.def.offset;
  // }

  /**
   * Formatted property value (non-array and non-bitstring property types).
   */
  public abstract get value(): string;

  /**
   * Formatted array property values.
   */
  public abstract get valueArr(): readonly string[];

  /**
   * Flag for each array element indicating if the element is `active`.
   */
  public get valueActiveArr(): readonly boolean[] {
    return this.valActiveArr;
  }

  /**
   * Flag indicating if the value is in fault.
   */
  public abstract get valueFault(): boolean;

  /**
   * Flag indicating of the value is hidden due to its current state.
   */
  public abstract get isHiddenByState(): boolean;

  /**
   * Indicates if the property has been marked "absent" by the driver in a COV.
   */
  public abstract get isAbsent(): boolean;

  /**
   * Command list.
   */
  public get commandList(): readonly CommandViewModel[] {
    return this.cmdVmList.filter(vm => vm.isEnabled || !vm.hideWhenDisabled);
  }

  /**
   * Command list internal.
   * NOT available to the view through the VM interface.  Intended for use by
   * aggregate-property only!
   */
  public get commandListInternal(): readonly CommandViewModel[] {
    return this.cmdVmList || [];
  }

  /**
   * Property command state.
   */
  public abstract get commandState(): CommandInvocState;

  /**
   * Indicates if any commands are pending for the property.
   */
  public abstract get isCommandPending(): boolean;

  /**
   * Flag indicating if property is an aggregate.
   */
  public abstract get isAggregate(): boolean;

  /**
   * Reference to property definition.
   */
  public get def(): PropertyDefinition {
    return this.defLocal || this.defBase; // Base definition can be overridden in object-property instance!
  }

  public static createSiValue(def: PropertyDefinition): AnyPropertyValueType {
    const siValue: AnyPropertyValueType = {
      readonly: true, // made writable if/when default command is established
      optional: false,
      // property-type specific; set in switch below
      type: undefined,
      kind: undefined,
      // set on COV
      value: undefined,
      altText: undefined
    };

    switch (def.valueType) {
      case PropertyValueType.BooleanValue: {
        const siBooleanValue: BooleanValue = siValue as BooleanValue;
        siBooleanValue.type = 'boolean';
        siBooleanValue.kind = undefined;
        // Default text values; will be overridden as we receive COVs or default command definiton
        siBooleanValue.options = [
          { value: false, text: 'False' },
          { value: true, text: 'True' }
        ];
      }
        break;

      case PropertyValueType.EnumeratedValue: {
        const siEnumValue: AnyEnumValue = siValue as AnyEnumValue;
        siEnumValue.type = 'enum';
        siEnumValue.kind = undefined;
        siEnumValue.optionsRef = undefined;
        // Set through default command definiton, if applicable
        siEnumValue.options = [];
      }
        break;

      case PropertyValueType.NumericValue: {
        const siNumberValue: NumberValue = siValue as NumberValue;
        siNumberValue.type = 'number';
        siNumberValue.kind = undefined;
        siNumberValue.unit = def.engineeringUnits;
        if (def.isNumericFloat) {
          const res: number = !isNaN(def.resolution) ? Common.limit(def.resolution, 0, 0, 100, true) : 2;
          siNumberValue.resolution = 1 / Math.pow(10, res);
          siNumberValue.decimalsAllowed = res > 0;
        } else {
          siNumberValue.decimalsAllowed = false;
          siNumberValue.resolution = 1;
        }
        siNumberValue.typeMin = Common.numericTypeMin(def.nativeType);
        siNumberValue.typeMax = Common.numericTypeMax(def.nativeType);
        // Set through default command definiton, if applicable
        siNumberValue.min = undefined;
        siNumberValue.max = undefined;
      }
        break;

      case PropertyValueType.Integer64Value:
      case PropertyValueType.UnsignedInteger64Value: {
        const siBigNumberValue: BigNumberValue = siValue as BigNumberValue;
        siBigNumberValue.type = 'big-number';
        siBigNumberValue.kind = undefined;
        siBigNumberValue.unit = def.engineeringUnits;
        siBigNumberValue.decimalsAllowed = false; // used for int/uint only
        siBigNumberValue.resolution = '1';
        siBigNumberValue.typeMin = Common.longTypeMin(def.nativeType)?.toString();
        siBigNumberValue.typeMax = Common.longTypeMax(def.nativeType)?.toString();
        // Set through default command definiton, if applicable
        siBigNumberValue.min = undefined;
        siBigNumberValue.max = undefined;
      }
        break;

      case PropertyValueType.DateTimeValue: {
        const siDateTimeValue: DateTimeValue = siValue as DateTimeValue;
        siDateTimeValue.type = 'date-time';
        siDateTimeValue.kind = undefined;
        siDateTimeValue.wildcardAllowed = false;
        siDateTimeValue.specialAllowed = false;
      }
        break;

      case PropertyValueType.BACnetDateTimeValue : {
        switch (def.bnDateTimeDetail) {
          case DateTimeType.DateOnly: {
            const siDateValue: DateValue = siValue as DateValue;
            siDateValue.type = 'date';
            siDateValue.kind = undefined;
            siDateValue.wildcardAllowed = def.isAllowWildcards;
            siDateValue.specialAllowed = def.isAllowDayOfWeek;
          }
            break;

          case DateTimeType.TimeOnly: {
            const siTimeValue: TimeValue = siValue as TimeValue;
            siTimeValue.type = 'time';
            siTimeValue.kind = undefined;
            siTimeValue.wildcardAllowed = def.isAllowWildcards;
          }
            break;

          case DateTimeType.DateAndTime:
          default: {
            const siDateTimeValue: DateTimeValue = siValue as DateTimeValue;
            siDateTimeValue.type = 'date-time';
            siDateTimeValue.kind = undefined;
            siDateTimeValue.wildcardAllowed = def.isAllowWildcards;
            siDateTimeValue.specialAllowed = def.isAllowDayOfWeek;
          }
            break;
        }
      }
        break;

      case PropertyValueType.DurationValue: {
        const siTimeDurationValue: TimeDurationValue = siValue as TimeDurationValue;
        siTimeDurationValue.type = 'time-duration';
        siTimeDurationValue.kind = undefined;
        siTimeDurationValue.unit = PropertyDefinition.mapTimeDurationUnit(def.durationValueUnits);
        siTimeDurationValue.format = PropertyDefinition.mapTimeDurationFormat(def.durationDisplayFormat);
        siTimeDurationValue.resolution = 1;
        siTimeDurationValue.typeMin = Common.uint32Min;
        siTimeDurationValue.typeMax = Common.uint32Max;
        // Set through default command definiton, if applicable
        siTimeDurationValue.min = undefined;
        siTimeDurationValue.max = undefined;
      }
        break;

      case PropertyValueType.Bitstring32Value:
      case PropertyValueType.Bitstring64Value: {
        const siBitStringValue: AnyBitStringValue = siValue as AnyBitStringValue;
        siBitStringValue.type = 'bitstring';
        siBitStringValue.kind = undefined;
        siBitStringValue.options = [];

        for (let bitnum = 0; bitnum < def.sizeBitstring; bitnum++) {
          const label: string = def.bitstringItemLabels.length >= bitnum + 1 ? def.bitstringItemLabels[bitnum] : '';

          siBitStringValue.options.push({ value: bitnum, text: label, allowed: true });
        }
      }
        break;

      case PropertyValueType.StringValue:
      default: {
        const siStringValue: StringValue = siValue as StringValue;
        siStringValue.type = 'string';
        siStringValue.kind = undefined;
        siStringValue.pattern = undefined;
        siStringValue.placeholder = undefined;
        // Set through default command definiton, if applicable
        siStringValue.minLength = undefined;
        siStringValue.maxLength = undefined;
        // Override default as strings can be zero length
        siStringValue.optional = true;
      }
        break;
    }

    return siValue;
  }

  public static createSiPriorityArrayValue(def: PropertyDefinition): AnyPriorityArrayValue {
    const siPriorityArrayValue: AnyPriorityArrayValue = {
      readonly: true,
      optional: true,
      type: 'collection',
      kind: 'priority-array',
      collectionType: 'record',
      // For non-prio arrays, we create item-values on COV to be certain of array size
      itemCount: def.sizeArrayDefault,
      minLength: def.sizeArrayDefault,
      maxLength: def.sizeArrayDefault,
      value: []
    };
    for (let prio = 1; prio <= def.sizeArrayDefault; prio++) {
      siPriorityArrayValue.value.push({
        position: prio,
        name: def.arrayItemLabels[prio - 1],
        value: PropertyInstance.createSiValue(def)
      });
    }
    return siPriorityArrayValue;
  }

  public static createSiArrayValue(def: PropertyDefinition): RecordCollectionValue<ValueBase> {
    const siArrayValue: RecordCollectionValue<ValueBase> = {
      readonly: true,
      optional: true,
      type: 'collection',
      kind: undefined,
      collectionType: 'record',
      // Array size and item values will be established on the fist COV
      itemCount: undefined,
      minLength: undefined,
      maxLength: undefined,
      value: []
    };
    return siArrayValue;
  }

  /**
   * Constructor.
   */
  constructor(
    protected traceService: TraceService,
    protected svc: ServiceStore,
    protected vmContext: ViewModelContext,
    protected readonly defBase: PropertyDefinition,
    protected readonly cmdListBase: Command[]) {

    if (!defBase) {
      throw new Error('undefined argument');
    }

    this.cmdVmList = [];

    // Add SiMPL property instance
    this.siProperty = this.createSiPropertyInstance();
    this.siProperty.actions = undefined;
  }

  public abstract resetDisplayValue(): void;

  /**
   * Re-evaluate object display labels.
   */
  public abstract updateObjectLabels(): void;

  /**
   * Create the command list by merging the base commands (from command read request) and
   * active commands (derived from command change indications)
   */
  public abstract updateCommandList(): void;

  public executeDefaultCommand(arg: ValueBase, confirmObjectIds?: string[]): Observable<PropertyCommandResult> {
    let cmdVm: CommandViewModel;
    let cmdArgs: ValueBase[];
    if (!this.def.isPriorityArray) {
      // Normal default command processing
      cmdVm = this.cmdVmList.find(cmd => cmd.isDefault);
      cmdArgs = [arg];
    } else {
      // Special case for priority-array properties:
      // Default command is interpretted as either "write" or "release" depending on the content of the provided
      // argument.  For priority-array properties, the argument will be of type PriorityArrayValue.  It will contain
      // exactly one PriorityArrayItem represting the element (slot) of the priority-array being commanded.  If the
      // value of this item is `undefined`, the command will be interpretted as "release"; otherwise, "write".
      if (arg && arg.kind === 'priority-array') {
        const item: PriorityArrayItem<ValueBase> = arg.value ? arg.value[0] : undefined;
        if (item) {
          cmdArgs = [];
          cmdArgs.push({
            type: 'enum',
            value: item.position // priority-array element index
          });
          if (item.value?.value !== undefined) {
            cmdArgs.push(item.value); // priority-array element value
            cmdVm = this.cmdVmList.find(cmd => cmd.isPriorityArrayWrite);
          } else {
            cmdVm = this.cmdVmList.find(cmd => cmd.isPriorityArrayRelease);
          }
        }
      }
    }
    if (!cmdArgs) {
      return throwError(new Error('invalid argument'));
    }
    if (!cmdVm) {
      return throwError(new Error('no default command'));
    }
    return this.executeCommand(cmdVm.id, cmdArgs, confirmObjectIds);
  }

  public executeCommand(commandId: string, args?: ValueBase[], confirmObjectIds?: string[]): Observable<PropertyCommandResult> {
    const cmdVm: CommandViewModel = this.cmdVmList.find(cmd => cmd.id === commandId);
    if (!cmdVm) {
      return throwError(new Error('invalid command id'));
    }
    if (!cmdVm.isSupported) {
      return throwError(new Error('command not supported'));
    }
    let commandArgs: CommandInput[];
    try {
      if (args) {
        commandArgs = cmdVm.encodeArgs(args);
      }
    } catch (e) {
      return throwError(e);
    }
    cmdVm.resetParamValues();
    return this.executeCommandInternal(cmdVm, commandArgs, confirmObjectIds);
  }

  protected abstract executeCommandInternal(commandVm: CommandViewModel, commandArgs: CommandInput[],
    confirmObjectIds?: string[]): Observable<PropertyCommandResult>;

  /**
   * Compare two bitsring values (provided as numbers in string form) according
   * to the size and offset characteristics of this bitstring property.
   */
  protected bitstringCompare(val1: string, val2: string): boolean {
    let bs1: Long = parseLong(val1, true) || Long.UZERO;
    let bs2: Long = parseLong(val2, true) || Long.UZERO;
    // Mask representing significant bits in this bitstring
    // For example,
    //  size=4, offset=3  ==>  mask=0000000001111000
    let lmask: Long = Long.UZERO;
    for (let i = 0; i < this.def.sizeBitstring; ++i) {
      lmask = lmask.shiftLeft(1).or(Long.UONE);
    }
    lmask = lmask.shiftLeft(this.def.offsetBitstring);
    // Compare only significant bits
    bs1 = bs1.and(lmask);
    bs2 = bs2.and(lmask);
    return bs1.equals(bs2);
  }

  protected updateSiPropertyValueCommands(): void {
    if (!this.siProperty?.value) {
      return;
    }

    let prioArrayHasDefaultCommands = false;

    if (!this.def.isPriorityArray) {
      const cmdVm: CommandViewModel = this.cmdVmList.find(x => x.isDefault);

      if (cmdVm) {
        if (cmdVm.isEnabled) {
          const param: CommandParamViewModel = cmdVm.params ? cmdVm.params[0] : undefined;

          this.updateSiPropertyValueLimits(this.siProperty.value, param);
        } else {
          this.siProperty.value.readonly = true;
        }
      }
    } else {
      // Special case for priority-array (write and release commands):
      // If the property is a priority-array and its command-list contains BOTH write and release
      // commands, set the property array-item values as writable

      // if (this.cmdVmList.some(cmd => cmd.isPriorityArrayWrite) &&
      //   this.cmdVmList.some(cmd => cmd.isPriorityArrayRelease)) {
      //   prioArrayHasDefaultCommands = true;

      //   const siVal: AnyPriorityArrayValue = this.siProperty.value as AnyPriorityArrayValue;
      //   siVal.readonly = false;

      //   const cmdVm: CommandViewModel = this.commandList.find(x => x.isPriorityArrayWrite);

      //   if (cmdVm && cmdVm.isEnabled) {
      //     const param: CommandParamViewModel = cmdVm.params ? cmdVm.params[0] : undefined;

      //     siVal.value.forEach(item => this.updateSiPropertyValueLimits(this.siProperty.value, param));
      //   }

      if (this.cmdVmList.some(cmd => cmd.isPriorityArrayWrite) &&
      this.cmdVmList.some(cmd => cmd.isPriorityArrayRelease)) {
        prioArrayHasDefaultCommands = true;

        const siVal: AnyPriorityArrayValue = this.siProperty.value as AnyPriorityArrayValue;
        siVal.readonly = false;
        siVal.value.forEach(item => item.value.readonly = false);

        // find the priority array write command
        const cmdVm: CommandViewModel = this.cmdVmList.find(cmd => cmd.isPriorityArrayWrite);

        if (cmdVm?.isEnabled) {
          const param: CommandParamViewModel = cmdVm.params.find(x => x.inferred === false); // find value parameter

          if (param) {
            siVal.value.forEach(item => this.updateSiPropertyValueLimits(item.value as AnyPropertyValueType, param));
          }
        }
      }
    }

    // update siProperty Action list
    this.siProperty.actions = this.cmdVmList
      .filter(vm => !vm.isDefault)
      .filter(vm => !(prioArrayHasDefaultCommands && (vm.isPriorityArrayWrite || vm.isPriorityArrayRelease)))
      .filter(vm => vm.isEnabled || !vm.hideWhenDisabled)
      .map(vm => vm.siCommand);

    if (this.siProperty.actions.length === 0) {
      this.siProperty.actions = undefined;
    }
  }

  protected initializeArray(arraySize: number, labels: readonly string[]): void {
    const anyArray: Property<RecordCollectionValue<AnyValueType>> = this.siProperty as Property<RecordCollectionValue<AnyValueType>>;

    // Initialize array if the array elements hasn't been set
    if ((isNullOrUndefined(anyArray.value.value.length)) || (anyArray.value.value.length !== arraySize)) {
      anyArray.value.value = [];
      for (let idx = 1; idx <= arraySize; idx++) {
        anyArray.value.value.push({
          name: labels && idx <= labels.length ? labels[idx - 1] : '',
          position: idx,
          value: PropertyInstance.createSiValue(this.def)
        });
      }
      anyArray.value.itemCount = arraySize;
      anyArray.value.minLength = arraySize;
      anyArray.value.maxLength = arraySize;
    }
  }

  private createSiPropertyInstance(): AnyCommandingProperty {
    const siProperty: AnyCommandingProperty = {
      id: this.def.propertyId,
      name: this.def.description,
      actions: undefined,
      defaultText: '',
      value: undefined
    };
    return siProperty;
  }

  private updateSiPropertyValueLimits(propertyValue: AnyPropertyValueType, param: CommandParamViewModel): void {
    if (param) {
      propertyValue.readonly = false;

      switch (propertyValue.type) {
        case 'number': {
          const nv: NumberValue = propertyValue as NumberValue;
          const npvm: NumericParamViewModel = param as NumericParamViewModel; // assume that the parameter is numeric
          nv.min = npvm.minValue;
          nv.max = npvm.maxValue;
          break;
        }

        case 'big-number': {
          const bnv: BigNumberValue = propertyValue as BigNumberValue;
          const lpvm: LongParamViewModel = param as LongParamViewModel; // assume that the parameter is long
          bnv.min = lpvm.minValue.toString();
          bnv.max = lpvm.maxValue.toString();
          break;
        }

        case 'boolean': {
          const bv: BooleanValue = propertyValue as BooleanValue;
          const epvm: EnumParamViewModel = param as EnumParamViewModel;
          bv.options = []; // remove all current options
          for (let aidx = 0; aidx < epvm.enumTextArr.length; aidx++) { // add command parameter options
            bv.options.push({
              text: epvm.enumTextArr[aidx],
              value: (epvm.enumValueArr[aidx] ? true : false)
            });
          }
          if (bv.options.length === 0) { // make writable only if we have no options
            bv.readonly = true;
          }
          break;
        }

        case 'enum': {
          const ev: AnyEnumValue = propertyValue as AnyEnumValue;
          const epvm: EnumParamViewModel = param as EnumParamViewModel;
          ev.options = []; // remove all current options
          for (let aidx = 0; aidx < epvm.enumTextArr.length; aidx++) { // add command parameter options
            ev.options.push({
              text: epvm.enumTextArr[aidx],
              value: epvm.enumValueArr[aidx]
            });
            if (epvm.enumTextArr[aidx] === ev.value) { // convert the current string value to the enumerated value
              ev.value = epvm.enumValueArr[aidx];
            }
          }
          if (ev.options.length === 0) { // make writable only if we have no options
            ev.readonly = true;
          }
          break;
        }

        case 'bitstring':
        case 'date-time':
        case 'string':
        case 'time-duration':
        default: {
          break;
        }
      }
    }
  }

}
