import { CommandParameters } from '@gms-flex/services';
import { TraceService } from '@gms-flex/services-common';
import { AnyPropertyValueType } from '@simpl/buildings-ng';
import { AnyValueType, TimeDurationValue } from '@simpl/element-value-types';

import { Common } from '../shared/common';
import { CommandParamViewModel } from './command-param-vm';
import { CommandParamType } from './command-vm.types';
import { DurationDisplayFormat, DurationUnits, WsiTranslator } from './data-model-helper';
import { PropertyDefinition } from './property-definition-vm';
import { ViewModelContext } from './snapin-vm.types';

/**
 * String parameter view-model implementation.
 */
export class DurationParamViewModel extends CommandParamViewModel {

  private min: number;
  private max: number;
  private durUnits: DurationUnits;
  private durFormat: DurationDisplayFormat;
  private defaultVal: number;

  /**
   * Minimum allowed value.
   */
  public get minValue(): number {
    return this.min;
  }

  /**
   * Maximum allowed value.
   */
  public get maxValue(): number {
    return this.max;
  }

  /**
   * Default parameter value.
   */
  public get defaultValue(): number {
    return this.defaultVal;
  }

  /**
   * Parameter data type.
   */
  public get dataType(): CommandParamType {
    return CommandParamType.Duration;
  }

  /**
   * Duration display format.
   * NOTE: Not yet sure if this needs to be public...
   */
  protected get durationDisplayFormat(): DurationDisplayFormat {
    return this.durFormat;
  }

  /**
   * Units of a duration property value.
   * NOTE: Not yet sure if this needs to be public...
   */
  protected get durationValueUnits(): DurationUnits {
    return this.durUnits;
  }

  /**
   * Constructor.
   */
  public constructor(
    traceService: TraceService,
    vmContext: ViewModelContext,
    param: CommandParameters) {

    super(traceService, vmContext, param);

    // Initialize the si-param value
    this.siParam.value = {
      type: 'time-duration',
      readonly: false,
      optional: false,
      value: undefined,
      resolution: 1
    } as TimeDurationValue;

    this.updateParamAttributes();
    this.resetParamValue();
  }

  /**
   * Decode the provided object as a number and encoded it as a string that can be passed
   * to the WSI as a command parameter value.
   *
   * If the provided object cannot at first be converted to a number, undefined will be returned
   * to indicate `invalid` argument.
   */

  public encodeValue(valObj: AnyValueType | AnyPropertyValueType): string {
    let valEnc: string;
    if (valObj && valObj.type === 'time-duration') {
      valEnc = WsiTranslator.encodeNumeric(valObj.value);
    }
    return valEnc;
  }

  public resetParamValue(): void {
    const siVal: TimeDurationValue = this.siParam.value as TimeDurationValue;
    siVal.value = !isNaN(this.defaultVal) ? this.defaultVal : undefined;
  }

  /**
   * Align this parameter with the provided param.
   */
  public alignParam(p: CommandParamViewModel): boolean {
    if (!p || p.dataType !== CommandParamType.Duration) {
      return false; // undefined param or type mismatch!
    }
    const param: DurationParamViewModel = p as DurationParamViewModel;

    // Cannot align if min/max value range is the same!
    if (!Common.isEqualNumber(this.minValue, param.minValue) ||
      !Common.isEqualNumber(this.maxValue, param.maxValue)) {
      return false;
    }

    // If default value of alignment param does not match, clear the local default value
    if (this.defaultValue !== param.defaultValue) {
      this.defaultVal = undefined;
    }

    return true;
  }

  protected updateParamAttributes(): void {
    const p: CommandParameters = this.param;
    if (!p) {
      return;
    }

    const uint32Max = 4294967295;

    this.durFormat = WsiTranslator.toDurationDisplayFormat(this.param.DurationDisplayFormat);
    this.durUnits = WsiTranslator.toDurationUnits(this.param.DurationValueUnits);

    this.max = Common.limit(parseInt(p.Max, 10), 0, 0, uint32Max, true);
    this.min = Common.limit(parseInt(p.Min, 10), 0, 0, this.max, false);

    this.defaultVal = Common.limit(parseInt(p.DefaultValue, 10), 0, this.min, this.max, false);

    const siVal: TimeDurationValue = this.siParam.value as TimeDurationValue;
    siVal.min = this.min;
    siVal.max = this.max;
    siVal.unit = PropertyDefinition.mapTimeDurationUnit(this.durUnits);
    siVal.format = PropertyDefinition.mapTimeDurationFormat(this.durFormat);
    siVal.typeMin = 0;
    siVal.typeMax = uint32Max;
    this.resetParamValue();
  }
}
