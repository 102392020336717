import { CommandParameters, EnumerationItem } from '@gms-flex/services';
import { TraceService } from '@gms-flex/services-common';
import { AnyPropertyValueType } from '@simpl/buildings-ng';
import { AnyBitStringValue, AnyValueType } from '@simpl/element-value-types';
import * as Long from 'long';

import { CommandParamViewModel } from './command-param-vm';
import { CommandParamType } from './command-vm.types';
import { WsiTranslator } from './data-model-helper';
import { ViewModelContext } from './snapin-vm.types';

export class Bitstring64ParamViewModel extends CommandParamViewModel {

  private defaultValArr: boolean[];
  private defaultVal: string;
  private readonly size: number;
  private readonly offset: number;

  public get defaultValue(): boolean[] {
    return this.defaultValArr;
  }

  public get dataType(): CommandParamType {
    return CommandParamType.Bitstring64;
  }

  public constructor(
    traceService: TraceService,
    vmContext: ViewModelContext,
    param: CommandParameters) {

    super(traceService, vmContext, param);

    let min = Number(param.Min); // low-bit
    if (isNaN(min)) {
      min = 0;
    }
    let max = Number(param.Max); // high-bit
    if (isNaN(max)) {
      max = 63;
    }
    this.size = (max - min) + 1;
    this.offset = min;

    // Initialize the si-param value
    this.siParam.value = {
      type: 'bitstring',
      readonly: false,
      optional: false,
      options: [],
      value: undefined
    } as AnyBitStringValue;
    // Set bit labels (in cases where labels are not provided, default label will be an empty string)
    for (let bitpos = 0; bitpos < this.size; bitpos++) {
      const bitLabel: EnumerationItem = param.EnumerationTexts.find(item => item.Value === bitpos);
      this.siParam.value.options.push({
        value: bitpos,
        text: bitLabel ? bitLabel.Descriptor : '',
        allowed: true
      });
    }

    this.updateParamAttributes();
  }

  public encodeValue(valObj: AnyValueType | AnyPropertyValueType): string {
    let valEnc: string;
    if (valObj && valObj.type === 'bitstring') {
      let val: Long = Long.UZERO;
      let invalid = false;
      if (Array.isArray(valObj.value)) {
        valObj.value.forEach(bitpos => {
          if (typeof bitpos === 'number') {
            val = WsiTranslator.setBitValue64(val, this.offset, bitpos, true);
          } else {
            invalid = true; // non-numeric value in array!
          }
        });
        if (!invalid) {
          valEnc = WsiTranslator.encodeBitstring64(val);
        }
      }
    }
    return valEnc;
  }

  public resetParamValue(): void {
    const siVal: AnyBitStringValue = this.siParam.value as AnyBitStringValue;
    siVal.value = [];
    if (this.defaultValArr) {
      for (let bitpos = 0; bitpos < this.size; bitpos++) {
        if (this.defaultValArr[bitpos]) {
          siVal.value.push(bitpos);
        }
      }
    }
  }

  public alignParam(p: CommandParamViewModel): boolean {
    if (!p || p.dataType !== CommandParamType.Bitstring64) {
      return false; // undefined param or type mismatch!
    }
    const param: Bitstring64ParamViewModel = p as Bitstring64ParamViewModel;

    // If default value of alignment param does not match, clear the local default value
    if (this.defaultVal !== param.defaultVal) {
      this.defaultVal = undefined;
      this.defaultValArr = undefined;
    }
    return true;
  }

  protected updateParamAttributes(): void {
    const p: CommandParameters = this.param;
    if (!p) {
      return;
    }
    this.defaultVal = undefined;
    this.defaultValArr = undefined;
    if (p.DefaultValue) {
      this.defaultVal = p.DefaultValue;
      this.defaultValArr = WsiTranslator.decodeBitstring(p.DefaultValue, this.size, this.offset);
    }
    this.resetParamValue();
  }
}
