import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TraceService } from '@gms-flex/services-common';

import { GmsXps } from '../elements/gms-xps';
import { GmsAdornerService } from '../services/gms-adorner.service';
import { GmsElementComponent } from './gms-element.component';

@Component({
  selector: '[gms-xps]',
  template: `<svg:g
                    [style.cursor]="element.IsDefaultCursorType ? 'default': 'pointer'"
                    (mouseenter)="OnMouseEnter($event)"
                    (click)="OnMouseClick($event)" (mouseleave)="OnMouseLeave($event)"
                    [id]="element.Id"
                    [ngClass]="!element.IsHitTestVisible ? 'noptrevents': 'allptrevents'"
                    [attr.transform]="element.GetTransformations()"
                    [attr.filter]="element.Filter?.Url"
                    [attr.opacity]="element.Opacity" [attr.visibility]="element.GetVisible()"
                    [attr.clip-path]="element.ClipPathUrl">
                    <defs *ngIf="element.HasClipInformation">
                        <clipPath [attr.id]="element.ClipPathId">
                            <path [attr.d]="element.GetClipPathData()"/>
                        </clipPath>
                    </defs>
                    <rect
                        *ngIf="element.Background !== 'transparent'"
                        [ngClass]="'noptrevents'"
                        [attr.width]="element.Width" [attr.height]="element.Height"
                        [attr.fill]="element.Background"
                        [attr.fill-opacity]="element.BackgroundOpacity" stroke-opacity="0" />
                    <svg:svg  [ngClass]="element.IsGrayscale ? 'grayscaleStyle': 'nograyscaleStyle'"
                        [attr.width]="element.Width" [attr.height]="element.Height" [attr.viewBox]="element.ViewBox">
                        <g #Container stroke-opacity="1"/>
                    </svg:svg>
                    <svg:rect *ngIf="element.ShowErrorBorder"
                              class="noptrevents"
                              [attr.width]="element.Width"
                              [attr.height]="element.Height"
                              fill="url(#pattern-error-comm)"
                              stroke-width="2" stroke="#5A5D60" />
                   <!--<title *ngIf="element.Tooltip !== undefined">{{element.Tooltip}}</title>-->
               </svg:g>`,
  styles: [`.noptrevents{pointer-events:none;}`,
    `.allptrevents{pointer-events:all;}`,
    `.grayscaleStyle{-webkit-filter: grayscale(100%);filter: grayscale(100%)}`,
    `.nograyscaleStyle{-webkit-filter: grayscale(0);filter: none}`
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class GmsXpsComponent extends GmsElementComponent implements OnInit, OnDestroy {
  @Input() public element: GmsXps = null;
  @ViewChild('Container', { static: true }) public contentContainer: ElementRef;

  constructor(changeDetector: ChangeDetectorRef, adornerService: GmsAdornerService, private readonly traceService: TraceService) {
    super(changeDetector, adornerService);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.AddContent();
  }

  private async AddContent(): Promise<void> {
    if (this.element.Content !== undefined) {
      this.contentContainer.nativeElement.appendChild(this.element.Content.cloneNode(true));
    } else {
      this.traceService.error(this.traceModule, 'XPS Content load error: Content not available');
    }
  }
}
