import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { GmsAdorner } from '../elements/gms-adorner';
import { GmsPath } from '../elements/gms-path';
import { GmsAdornerService } from '../services/gms-adorner.service';
import { Guid } from '../utilities/guid';
import { GmsElementComponent } from './gms-element.component';

@Component({
  selector: '[gms-path]',
  template: `<svg:g [id]="element.Id"
                    (mouseenter)="OnMouseEnter($event)" (click)="OnMouseClick($event)"
                    (mouseout)="OnMouseLeave($event)"
                    [ngClass]="!element.IsHitTestVisible ? 'noptrevents': 'allptrevents'"
                    [style.cursor]="element.IsDefaultCursorType ? 'default': 'pointer'"
                    [attr.transform]="element.GetTransformations()"
                    [attr.filter]="element.Filter?.Url"
                    [attr.opacity]="element.Opacity" [attr.visibility]="element.GetVisible()"
                    [attr.clip-path]="element.ClipPathUrl">
                     <defs *ngIf="element.HasClipInformation">
                        <clipPath [attr.id]="element.ClipPathId">
                            <path [attr.d]="element.GetClipPathData()"/>
                        </clipPath>
                    </defs>
                    <svg:rect *ngIf="element.Background !== 'transparent'"
                              [ngClass]="element.IsHitTestVisible ? 'allptrevents': 'noptrevents'"
                              [style.cursor]="element.IsDefaultCursorType ? 'default': 'pointer'"
                              [attr.width]="element.Width"
                              [attr.height]="element.Height"
                              [attr.fill]="element.Background" [attr.fill-opacity]="element.BackgroundOpacity" stroke-opacity="0" />
                    <svg:path
                            [ngClass]="!element.IsHitTestVisible ? 'noptrevents': 'allptrevents'"
                            [attr.transform]="element.ResizeScaleTransformation()"
                            [attr.d]="element.Data"
                            [attr.fill]="element.Fill"
                            [attr.fill-opacity]="element.FillOpacity"
                            [attr.stroke]="element.Stroke"
                            [attr.stroke-dasharray]="element.StrokeDashArray"
                            [attr.stroke-opacity]="element.StrokeOpacity"
                            [attr.stroke-width]="element.StrokeWidth"
                            [attr.stroke-linecap]="element.StrokeLineCap"
                            [attr.stroke-linejoin]="element.StrokeLineJoin"
                            fill-rule="evenodd" />
                    <svg:rect *ngIf="element.ShowErrorBorder"
                              class="noptrevents"
                              [attr.width]="element.Width"
                              [attr.height]="element.Height"
                              fill="url(#pattern-error-comm)"
                              stroke-width="2" stroke="#5A5D60" />
                    <g *ngIf="element.HasArrows" fill-rule="evenodd" stroke-opacity="0" stroke-linejoin="round"
                       [attr.fill]="element.Stroke" fill-opacity="element.StrokeOpacity">
                        <svg:path
                            *ngIf="element.StartArrow !== undefined"
                            [attr.d]="element.StartArrow" />
                        <svg:path
                            *ngIf="element.EndArrow !== undefined"
                            [attr.d]="element.EndArrow" />
                    </g>
                    <!--<title *ngIf="element.Tooltip !== undefined">{{element.Tooltip}}</title>-->
               </svg:g>`,
  styles: [`.noptrevents{pointer-events:none}`,
    `.allptrevents{pointer-events:all}`],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GmsPathComponent extends GmsElementComponent implements OnInit, OnDestroy {
  @Input() public element: GmsPath = null;

  public get Offset(): string {
    const position: string = 'translate(' + this.element.Width / 2 + ',' + this.element.Height / 2 + ')';
    return position;
  }
}
