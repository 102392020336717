import { Injectable } from '@angular/core';
import { SummaryBarSnapInServiceBase } from './summary-bar-snapin.service.base';
import { Observable, of } from 'rxjs';

/**
 * SummaryBar SnapIn service.
 * Provides the methods to get if summary bar functionalities are visible.
 *
 * @export
 * @class SummaryBarSnapInService
 * @extends {SummaryBarSnapInServiceBase}
 */

@Injectable({
  providedIn: "root"
})
export class SummaryBarSnapInService implements SummaryBarSnapInServiceBase {

  public buzzerVisible(): boolean {
    return true;
  }

  public disableFrameSwitch(): Observable<boolean> {
    return of(false);
  }

  public showTotalEvNumberOnly(): boolean {
    return false;
  }

  /**
   * this method is purposely not implemented in flex implementation
   * @param category
   */
  public setSelectedCategory(categoryId: number | undefined): void {
    return;
  }

  /**
   * this method is purposely not implemented in flex implementation
   */
  public get getSelectedCategory(): Observable<number | undefined> {
    return undefined;
  }
}
