/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HfwControlsModule } from '@gms-flex/controls';
import { HFW_TRANSLATION_FILE_TOKEN, TraceService } from '@gms-flex/services-common';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
  SiCircleStatusModule,
  SiContentActionBarModule,
  SiNavbarModule,
  SiNavbarVerticalModule,
  SiToastNotificationService
} from '@simpl/element-ng';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';

import { LayoutModalComponent } from './layout-modal/layout-modal.component';
import { LayoutSettingsComponent } from './layout-settings/layout-settings.component';
import { NavBarPrimaryComponent } from './navbar-primary/navbar-primary.component';
import { NotificationComponent, SanitizeHtmlPipe } from './notification/notification.component';
import { UserRolesComponent } from './user-roles/user-roles.component';

export const createTranslateLoader = (http: HttpClient): TranslateHttpLoader => new TranslateHttpLoader(http, './@gms-flex/navigation-bar/i18n/', '.json');

@NgModule({ declarations: [LayoutModalComponent, LayoutSettingsComponent, NavBarPrimaryComponent, NotificationComponent, SanitizeHtmlPipe, UserRolesComponent],
  exports: [NavBarPrimaryComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA], imports: [BsDropdownModule, CommonModule,
    ButtonsModule.forRoot(), FormsModule, HfwControlsModule, ModalModule,
    PortalModule, SiCircleStatusModule, SiContentActionBarModule,
    SiNavbarModule, SiNavbarVerticalModule,
    TranslateModule.forChild({
      loader: { provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient, TraceService] },
      isolate: true
    })], providers: [SiToastNotificationService,
    { provide: HFW_TRANSLATION_FILE_TOKEN, useValue: './@gms-flex/navigation-bar/i18n/', multi: true }, provideHttpClient(withInterceptorsFromDi())] })

export class GmsNavbarModule {
  public static forRoot(): ModuleWithProviders<GmsNavbarModule> {
    return {
      ngModule: GmsNavbarModule
    };
  }
}
