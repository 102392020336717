import { inject, Injectable } from '@angular/core';
import { ValueBase } from '@simpl/buildings-types';
import { EMPTY, Observable } from 'rxjs';

import { PropertyApi } from '../api/property.api';
import {
  BulkCommandEvent,
  BulkProperty,
  BulkPropertyValues,
  BulkResult,
  CommandEvent,
  Property
} from '../interfaces/property';

@Injectable()
export class SiPropertyService {
  private propertyApi = inject(PropertyApi);

  /**
   * Read properties from the server.
   */
  getProperties(objectId: string): Observable<Property[]> {
    return this.propertyApi.getProperties(objectId);
  }

  /**
   * Write property to the server.
   */
  writeProperty(objectId: string, property: Property): Observable<void> {
    return this.propertyApi.writeProperty(objectId, property);
  }

  /**
   * Dispatch a command to the server.
   */
  executeCommand(objectId: string, command: CommandEvent): Observable<void> {
    return this.propertyApi.executeCommand(objectId, command);
  }

  /**
   * Read bulk properties from the server.
   */
  getBulkProperties(objectIds: string[]): Observable<BulkProperty[]> {
    if (!this.propertyApi.getBulkProperties) {
      return EMPTY;
    }
    return this.propertyApi.getBulkProperties(objectIds);
  }

  /**
   * Read bulk property values from the server.
   */
  getBulkPropertyValues(objectIds: string[], propertyId: string): Observable<BulkPropertyValues> {
    if (!this.propertyApi.getBulkPropertyValues) {
      return EMPTY;
    }
    return this.propertyApi.getBulkPropertyValues(objectIds, propertyId);
  }

  /**
   * Write bulk properties to the server.
   */
  writeBulkProperty(
    objectIds: string[],
    propertyId: string,
    value: ValueBase
  ): Observable<BulkResult> {
    if (!this.propertyApi.writeBulkProperty) {
      return EMPTY;
    }
    return this.propertyApi.writeBulkProperty(objectIds, propertyId, value);
  }

  /**
   * Dispatch a bulk command to the server.
   */
  executeBulkCommand(objectIds: string[], command: BulkCommandEvent): Observable<BulkResult> {
    if (!this.propertyApi.executeBulkCommand) {
      return EMPTY;
    }
    return this.propertyApi.executeBulkCommand(objectIds, command);
  }
}
