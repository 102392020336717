import { AfterViewChecked, ChangeDetectionStrategy, Component, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core';

import { GmsGroup } from '../elements/gms-group';
import { GmsElementType } from '../types/gms-element-types';
import { GmsAnimatedGifComponent } from './gms-animated-gif.component';
import { GmsElementComponent } from './gms-element.component';
import { GmsEllipseComponent } from './gms-ellipse.component';
import { GmsImageComponent } from './gms-image.component';
import { GmsLineComponent } from './gms-line.component';
import { GmsNumericComponent } from './gms-numeric.component';
import { GmsPathComponent } from './gms-path.component';
import { GmsPipeComponent } from './gms-pipe.component';
import { GmsPolygonComponent } from './gms-polygon.component';
import { GmsRectangleComponent } from './gms-rectangle.component';
import { GmsSelectorComponent } from './gms-selector.component';
import { GmsSpinnerComponent } from './gms-spinner.component';
import { GmsTextComponent } from './gms-text.component';
import { GmsXpsComponent } from './gms-xps.component';

@Component({
  selector: '[gms-group]',
  template: `
        <svg:g #groupElement
               [ngClass]="!element.IsHitTestVisible ? 'noptrevents': 'allptrevents'"
               [style.cursor]="element.IsDefaultCursorType ? 'default': 'pointer'"
               [attr.visibility]="element.GetVisible()"
               [attr.opacity]="element.Opacity"
               [attr.transform]="element.GetTransformations()" [attr.id]="element.Id"
               [attr.clip-path]="element.ClipPathUrl"
               [attr.filter]="element.Filter?.Url">
            <defs *ngIf="element.HasClipInformation">
                <clipPath [attr.id]="element.ClipPathId">
                    <path [attr.d]="element.GetClipPathData()"/>
                </clipPath>
            </defs>
            <rect
                    (mouseenter)="OnMouseEnter($event)" (click)="OnMouseClick($event)"
                    (mouseout)="OnMouseLeave($event)"
                    [id]="element.Id"
                    [ngClass]="!element.IsHitTestVisible ? 'noptrevents': 'allptrevents'"
                    [attr.width]="element.Width"
                    [attr.height]="element.Height"
                    [attr.fill]="element.Background" [attr.fill-opacity]="element.BackgroundOpacity" stroke-opacity="0"
            />
            <svg:g
                    [ngClass]="!element.IsHitTestVisible ? 'noptrevents': 'allptrevents'"
                    *ngFor="let item of element.children;">
                <!-- List all possible components that can be in a group -->
                <ng-container *ngIf="item.Type === elementType.SymbolInstance">
                   <ng-container *ngTemplateOutlet="item.SymbolTemplate; context: {model: item}"/>
                </ng-container>
                <ng-container *ngIf="item.Type === elementType.Group">
                   <ng-container *ngTemplateOutlet="item.GroupTemplate; context: {model: item}"/>
                </ng-container>
                <svg:g *ngIf="item.Type === elementType.Ellipse" gms-ellipse [element]="item"/>
                <svg:g *ngIf="item.Type === elementType.Rectangle" gms-rectangle [element]="item"/>
                <svg:g *ngIf="item.Type === elementType.Path" gms-path [element]="item"/>
                <svg:g *ngIf="item.Type === elementType.Line" gms-line [element]="item"/>
                <svg:g *ngIf="item.Type === elementType.Polygon" gms-polygon [element]="item"/>
                <svg:g *ngIf="item.Type === elementType.Text" gms-text [element]="item"/>
                <svg:g *ngIf="item.Type === elementType.Image" gms-image [element]="item"/>
                <svg:g *ngIf="item.Type === elementType.Xps" gms-xps [element]="item"/>
                <svg:g *ngIf="item.Type === elementType.AnimatedGif" gms-animated-gif [element]="item"/>
                <ng-container *ngIf="item.Type === elementType.Replication">
                   <ng-container *ngTemplateOutlet="item.ReplicationTemplate; context: {model: item.Replication}"/>
                </ng-container>
                <svg:g *ngIf="item.Type === elementType.CommandControl && item.ControlType === commandControlType.Numeric"
                       gms-numeric [element]="item"/>
                <svg:g *ngIf="item.Type === elementType.CommandControl && item.ControlType === commandControlType.Selector"
                       gms-selector [element]="item"/>
                <svg:g *ngIf="item.Type === elementType.CommandControl && item.ControlType === commandControlType.Spinner"
                       gms-spinner [element]="item"/>
                <svg:g *ngIf="item.Type === elementType.CommandControl && item.ControlType === commandControlType.Slider"
                       gms-slider [element]="item"/>
                <svg:g *ngIf="item.Type === elementType.CommandControl && item.ControlType === commandControlType.Rotator"
                       gms-rotator [element]="item"/>
                <svg:g *ngIf="item.Type === elementType.CommandControl && item.ControlType === commandControlType.String"
                       gms-string-command [element]="item"/>
                <svg:g *ngIf="item.Type === elementType.Pipe" gms-pipe [element]="item"/>
            </svg:g>
            <svg:rect *ngIf="element.ShowErrorBorder"
                      class="noptrevents"
                      [attr.width]="element.Width"
                      [attr.height]="element.Height"
                      fill="url(#pattern-error-comm)"
                      stroke-width="2" stroke="#5A5D60" />
        </svg:g>`,
  styles: [`.noptrevents{pointer-events:none}`,
    `.allptrevents{pointer-events:all}`],
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [GmsRectangleComponent, GmsEllipseComponent, GmsPathComponent, GmsLineComponent, GmsPolygonComponent
    , GmsTextComponent, GmsImageComponent, GmsXpsComponent, GmsAnimatedGifComponent,
    GmsSpinnerComponent, GmsNumericComponent, GmsSelectorComponent, GmsPipeComponent]
})

export class GmsGroupComponent extends GmsElementComponent implements OnDestroy, AfterViewChecked {
  @Input() public element: GmsGroup = null;
  @ViewChild('groupElement', { static: false }) public groupElement: ElementRef;
  public elementType: any = GmsElementType; // Store a reference to the enum, so that we can compare in the template

  public ngAfterViewChecked(): void {
    if (this.element.IsCommandGroupEnabled && this.element.buttons === undefined) {
      const controlGroup: any = (this.element as any);
      controlGroup.AddCommandButtons();
    }
  }
}
