import { Injectable } from "@angular/core";
import { PropertyDetails, SubscriptionGmsVal, ValueDetails } from "@gms-flex/services";
import { isNullOrUndefined } from "@siemens/ngx-datatable";

import { DccDataType } from "../point-type.service";
import { GlobalTextGroups, TextGroupBxService } from "../text-group-bx.service";
import { PropertyMapper } from "./property-mapper";

export const deviceConnectionPropertyName = 'DeviceConnection';

@Injectable({
  providedIn: 'root'
})
export class DeviceConnectionMapperService extends PropertyMapper {

  constructor(private readonly textGroupBxService: TextGroupBxService) {
    super();
  }

  public createDeviceConnection(order: number): PropertyDetails {
    const propDetails: PropertyDetails = {
      /* eslint-disable @typescript-eslint/naming-convention */
      PropertyName: deviceConnectionPropertyName,
      Descriptor: 'Connection',
      DisplayType: 0,
      IsArray: false,
      Order: order,
      Resolution: undefined,
      Type: DccDataType.ExtendedEnum,
      Value: undefined,
      Min: '0',
      Max: '2',
      UnitDescriptor: undefined,
      UnitId: undefined,
      Usage: 3, // Show only in basic and extended properties (Bit0: Show in basic properties, Bit1: Show in extended properties)
      DisplayOffNormalOnly: false,
      NormalValue: '1',
      TextTable: GlobalTextGroups.TextGroupIdConnectionState,
      PropertyType: 0 // Type = 0,Indexed = 1,Functions = 2
      /* eslint-enable @typescript-eslint/naming-convention */
    };
    return propDetails;
  }

  public createDeviceConnectionValue(subGms: SubscriptionGmsVal, connected?: boolean): ValueDetails | undefined {
    // TODO: finalize quality bits

    const displayValue = this.createDeviceConnectionDisplayValue(connected);
    let rawValue = '#NaN';
    if (!isNullOrUndefined(connected)) {
      rawValue = connected ? '1' : '0';
    }

    return {
      /* eslint-disable @typescript-eslint/naming-convention */
      DataType: DccDataType.BasicUint,
      ErrorCode: subGms.errorCode,
      SubscriptionKey: subGms.key,
      IsArray: false,
      Value: {
        // the bit positions which are set (e.g. value=6 => bit0=false, bit1=true, bit2=true, bit3=false)
        Value: rawValue,
        DisplayValue: displayValue ?? rawValue,   
        Timestamp: '',
        QualityGood: true,
        Quality: '0',
        IsPropertyAbsent: false
      }
      /* eslint-enable @typescript-eslint/naming-convention */
    };
  }

  private createDeviceConnectionDisplayValue(connected: boolean | undefined): string | undefined {
    const txgConnection = this.textGroupBxService.getGlobalTextGroup(GlobalTextGroups.TextGroupIdConnectionState);
    if (isNullOrUndefined(connected)) {
      return txgConnection.enum['2']?.label;
    } else {
      return txgConnection.enum[connected ? '1' : '0']?.label;
    }
  }
}
