import { HttpParams } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SiTranslateModule } from '@simpl/element-translate-ng/translate';

export interface SortCriteria {
  name: string;
  key: number | string;
}

@Component({
  selector: 'si-sort-bar',
  templateUrl: './si-sort-bar.component.html',
  styleUrl: './si-sort-bar.component.scss',
  standalone: true,
  imports: [SiTranslateModule]
})
export class SiSortBarComponent implements OnInit {
  /**
   * Custom sort title.
   *
   * @defaultValue
   * ```
   * $localize`:@@SI_SORT_BAR.TITLE:Sort by`
   * ```
   */
  @Input() sortTitle = $localize`:@@SI_SORT_BAR.TITLE:Sort by`;
  /**
   * List of sort criteria.
   */
  @Input({ required: true })
  sortCriteria!: SortCriteria[];
  /**
   * `key` which sortCriteria is active by default.
   */
  @Input() defaultSortCriteria!: SortCriteria['key'];

  /**
   * Output callback event will provide you with a HttpParams object if active
   * sortCriteria change.
   */
  @Output() readonly sortChange: EventEmitter<HttpParams> = new EventEmitter();

  protected activeSortCriteria = '';
  protected sortIsDescending = false;

  ngOnInit(): void {
    if (this.defaultSortCriteria) {
      this.setActive(this.defaultSortCriteria);
    }
  }

  protected setActive(key: any): void {
    if (this.activeSortCriteria === key) {
      this.sortIsDescending = !this.sortIsDescending;
    }

    this.activeSortCriteria = key;

    const searchParams = new HttpParams()
      .set('sort', this.activeSortCriteria)
      .set('order', this.sortIsDescending ? 'desc' : 'asc');

    this.sortChange.emit(searchParams);
  }
}
