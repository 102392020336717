import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NumberValue } from '@simpl/buildings-types';
import { SiSliderComponent } from '@simpl/element-ng';

import { SiNumberPipe } from './si-number.pipe';
import { SiNumberValidatorDirective } from '../../directives/number-validator.directive';
import { Property, StateChange, ValueState } from '../../interfaces/property';
import { SiPropertyPopoverComponent } from '../si-property-popover/si-property-popover.component';

const MAX_ANALOG_RANGE_STEPS = 2000;

@Component({
  selector: 'si-number-property',
  templateUrl: './si-number-property.component.html',
  styleUrl: './si-number-property.component.scss',
  standalone: true,
  imports: [
    FormsModule,
    SiNumberPipe,
    SiNumberValidatorDirective,
    SiPropertyPopoverComponent,
    SiSliderComponent
  ]
})
export class SiNumberPropertyComponent {
  @Input({ required: true }) property!: Property<NumberValue>;
  /** @defaultValue 'none' */
  @Input() valueState: ValueState = 'none';
  /** @defaultValue false */
  @Input() allowValuesOutOfRange = false;
  /** @defaultValue false */
  @Input() forceReadonly = false;
  @Output() readonly submitted = new EventEmitter<Property<NumberValue>>();

  @ViewChild('inputBox') protected inputBox!: ElementRef<HTMLInputElement>;
  @ViewChild('popover', { static: true }) protected popover!: SiPropertyPopoverComponent;

  protected smallRange = false;
  protected valueInfo = '';

  protected get readonly(): true | null {
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    return this.forceReadonly || this.property.value.readonly || null;
  }

  protected stateChange(state: StateChange): void {
    switch (state) {
      case 'openKeyboard':
      case 'open':
        this.determineRange();
        this.adjustValueForFloatPrecision();
        setTimeout(() => {
          this.inputBox.nativeElement.focus();
          if (state === 'openKeyboard') {
            this.inputBox.nativeElement.select();
          }
        }, 0);
        break;
      case 'submit':
        this.submitted.emit(this.property);
        break;
      case 'release':
        this.property.value.value = undefined;
        this.submitted.emit(this.property);
        break;
    }
  }

  protected onKeyDown(event: KeyboardEvent): void {
    if (this.property.value.decimalsAllowed === false && (event.key === '.' || event.key === ',')) {
      event.preventDefault();
    }
  }

  private determineRange(): void {
    this.smallRange =
      this.property.value.min == null || this.property.value.max == null
        ? false
        : Math.pow(this.property.value.resolution ?? 1, -1) *
            (this.property.value.max - this.property.value.min) <=
          MAX_ANALOG_RANGE_STEPS;
    const minValue =
      this.property.value.min != null && this.property.value.min > Number.MIN_SAFE_INTEGER
        ? this.property.value.min
        : undefined;
    const maxValue =
      this.property.value.max != null && this.property.value.max < Number.MAX_SAFE_INTEGER
        ? this.property.value.max
        : undefined;
    this.valueInfo =
      minValue !== undefined || maxValue !== undefined
        ? `${minValue ?? ''}..${maxValue ?? ''}`
        : '';

    if (this.property.value.unit) {
      this.valueInfo += ' ' + this.property.value.unit;
    }
  }

  // work around for float math problems. This "rounds" the value to the resolution when opening
  // the popup so that the `<input type="number">` shows the same thing as the label
  private adjustValueForFloatPrecision(): void {
    if (this.popover.editValue !== undefined && this.property.value.resolution !== undefined) {
      const value = this.popover.editValue as number;
      const r = 1 / this.property.value.resolution; // yes, this is intentional!
      this.popover.modelValue = Math.round(value * r) / r;
    }
  }
}
