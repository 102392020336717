<form #form="ngForm">
  <si-property-popover
    #popover
    [property]="property"
    [submitDisabled]="!isValid"
    [valueState]="valueState"
    [forceReadonly]="forceReadonly"
    (stateChange)="stateChange($event)"
  >
    <ng-container
      [ngTemplateOutlet]="
        property.defaultText || !modelValue.value
          ? defaultAltText
          : isBacnetDatepicker()
            ? displayVal
            : siDatepicker
      "
      input
    />

    <ng-template #defaultAltText>
      <div
        class="form-control d-flex align-items-center"
        [attr.readonly]="readonly"
        [attr.tabindex]="readonly ? '' : '0'"
        (keydown.enter)="popover.open(true)"
      >
        <span>
          {{ property.defaultText || property.value.altText }}
        </span>
      </div>
    </ng-template>
    <ng-template #displayVal>
      <div
        class="form-control d-flex align-items-center"
        [attr.readonly]="readonly"
        [attr.tabindex]="readonly ? '' : '0'"
        (keydown.enter)="popover.open(true)"
      >
        <span>
          {{ displayValue }}
        </span>
      </div>
    </ng-template>
    <ng-template #siDatepicker>
      <input
        #inputBox
        name="main"
        type="text"
        class="form-control"
        [attr.readonly]="readonly"
        [attr.tabindex]="readonly ? '' : '0'"
        (keydown.enter)="popover.open(true)"
        [(ngModel)]="selectedDate"
        [attr.aria-label]="property.name"
        siDateInput
        [siDatepickerConfig]="{ showTime: false, dateFormat: formatForPicker }"
      />
    </ng-template>

    @if (property.value.wildcardAllowed || property.value.specialAllowed) {
      <si-bacnet-datetime-picker
        [value]="modelValue"
        [dateFormat]="format!"
        (submitEnter)="popover.submitEnter()"
        body
      />
    } @else {
      <div class="d-flex justify-content-center" body>
        <si-datepicker class="border-0" [(date)]="selectedDate" />
      </div>
    }
  </si-property-popover>
</form>
