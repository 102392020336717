import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FieldType, FieldTypeConfig, FormlyModule } from '@ngx-formly/core';
import { SiNumberInputComponent } from '@simpl/element-ng';

@Component({
  selector: 'si-formly-number',
  templateUrl: './si-formly-number.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ReactiveFormsModule, FormlyModule, SiNumberInputComponent]
})
export class SiFormlyNumberComponent extends FieldType<FieldTypeConfig> {}
