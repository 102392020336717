import { Injectable } from '@angular/core';

export interface FontStyleOverride {
  fontStyle?: string;
  fontVariant?: string;
  fontWeight?: string;
  fontSize?: string;
  fontFamily?: string;
}

@Injectable({
  providedIn: 'root'
})
export class TextMeasureService {
  private measureCanvas?: CanvasRenderingContext2D;
  private defaultFont?: string;
  /** Measure text width in pixel. */
  measureText(text: string, fontRef?: HTMLElement | string, overrides?: FontStyleOverride): number {
    this.ensureCanvas();
    this.setFontStyle(fontRef, overrides);
    return Math.ceil(this.measureCanvas!.measureText(text).width);
  }
  /** Get font styles for `font` CSS short-hand property. */
  getFontStyle(element: HTMLElement, overrides?: FontStyleOverride): string {
    const style = getComputedStyle(element);
    const { fontStyle, fontVariant, fontWeight, fontSize, fontFamily } = { ...style, ...overrides };
    // this is necessary since Firefox returns style.font as ""
    return `${fontStyle} ${fontVariant} ${fontWeight} ${fontSize} ${fontFamily}`
      .replace(/ +/g, ' ')
      .trim();
  }

  private ensureCanvas(): void {
    if (this.measureCanvas) {
      return;
    }
    const canvas = document.createElement('canvas') as HTMLCanvasElement;
    this.measureCanvas = canvas.getContext('2d') as CanvasRenderingContext2D;
  }

  private setFontStyle(fontRef?: HTMLElement | string, overrides?: FontStyleOverride): void {
    if (typeof fontRef === 'string') {
      this.measureCanvas!.font = fontRef;
      return;
    }
    if (fontRef || overrides) {
      this.measureCanvas!.font = this.getFontStyle(fontRef ?? document.body, overrides);
      return;
    }
    if (!this.defaultFont) {
      this.defaultFont = this.getFontStyle(document.body);
    }
    this.measureCanvas!.font = this.defaultFont;
  }
}
