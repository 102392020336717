import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BrowserObject } from '@gms-flex/services';
import { Subscription } from 'rxjs';

import { GmsAdorner } from '../elements/gms-adorner';
import { GmsElement } from '../elements/gms-element';
import { GmsRectangle } from '../elements/gms-rectangle';
import { GmsAdornerService } from '../services/gms-adorner.service';
import { Guid } from '../utilities/guid';
import { GmsElementComponent } from './gms-element.component';

@Component({
  selector: '[gms-rectangle]',
  template: `<svg:g
                    (mouseenter)="OnMouseEnter($event)" (click)="OnMouseClick($event)"
                    (mouseleave)="OnMouseLeave($event)"
                    [ngClass]="!element.IsHitTestVisible ? 'noptrevents': 'allptrevents'"
                    [id]="element.Id"
                    [attr.transform]="element.GetTransformations()" [attr.clip-path]="element.ClipPathUrl"
                    [attr.filter]="element.Filter?.Url"
                    [attr.opacity]="element.Opacity" [attr.visibility]="element.GetVisible()">
                    <defs *ngIf="element.HasClipInformation">
                        <clipPath [attr.id]="element.ClipPathId">
                            <path [attr.d]="element.GetClipPathData()"/>
                        </clipPath>
                    </defs>
                    <svg:rect
                            [ngClass]="'noptrevents'"
                              *ngIf="element.Background !== 'transparent'"
                              [attr.width]="element.Width"
                              [attr.height]="element.Height"
                              [attr.fill]="element.Background" [attr.fill-opacity]="element.BackgroundOpacity" stroke-opacity="0" />
                    <svg:rect
                            [ngClass]="!element.IsHitTestVisible ? 'noptrevents': 'allptrevents'"
                              [style.cursor]="element.IsDefaultCursorType ? 'default': 'pointer'"
                              [attr.id]="element.Id"
                              [attr.x] = "offsetX"
                              [attr.y] = "offsetY"
                              [attr.width]="offsetWidth"
                              [attr.height]="offsetHeight"
                              [attr.fill]="element.Fill"
                              [attr.fill-opacity]="element.FillOpacity"
                              [attr.stroke]="element.Stroke"
                              [attr.stroke-width]="element.StrokeWidth" [attr.transform]="Offset"
                              [attr.stroke-opacity]="element.StrokeOpacity"
                              [attr.stroke-dasharray]="element.StrokeDashArray"
                              [attr.rx]="element.RadiusX"
                              [attr.ry]="element.RadiusY" />
                   <svg:rect *ngIf="element.ShowErrorBorder"
                              class="noptrevents"
                              [attr.width]="element.Width"
                              [attr.height]="element.Height"
                              fill="url(#pattern-error-comm)"
                              stroke-width="2" stroke="#5A5D60" />
                   <!--<title *ngIf="element.Tooltip !== undefined">{{element.Tooltip}}</title>-->
               </svg:g>`,
  styles: [`.noptrevents{pointer-events:none}`,
    `.allptrevents{pointer-events:all;cursor: pointer;}`],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class GmsRectangleComponent extends GmsElementComponent implements OnInit, OnDestroy {
  @Input() public element: GmsRectangle = null;

  public get Offset(): string {
    return '';
  }

  // offset properties for correcting svg vs wpf - stroke painting difference
  // Understanding for future: Minimum value for Height should be atleast more
  // than twice of strokewidth to be a rectangle visually.
  public get offsetX(): number {
    return this.element.StrokeWidth / 2;
  }

  public get offsetY(): number {
    return this.element.StrokeWidth / 2;
  }

  public get offsetWidth(): number {
    const offsetWidth: number = this.element.Width - this.element.StrokeWidth;
    return offsetWidth > 0 ? offsetWidth : 0;
  }

  public get offsetHeight(): number {
    const offsetWidth: number = this.element.Height - this.element.StrokeWidth;
    return offsetWidth > 0 ? offsetWidth : this.element.StrokeWidth;
  }
}
