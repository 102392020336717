import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { takeUntil } from 'rxjs/operators';

import { GmsCommandControlString } from '../elements/gms-commandcontrol-string';
import { GmsCommandResult } from '../processor/command-view-model/gms-command-vm';
import { ControlEditorMode } from '../processor/command-view-model/gms-parameter-vm.base';
import { GmsElementComponent } from './gms-element.component';

@Component({
  selector: '[gms-string-command]',
  template: `<svg:g
            #groupElement
            [ngClass]="'allptrevents'"
            [attr.transform]="element.GetTransformations()"
            [attr.filter]="element.Filter"
            [attr.visibility]="element.GetVisible()" [id]="element.Id"
            [attr.clip-path]="element.ClipPathUrl"
            (mouseenter)="OnMouseEnter($event)"
            >
            <defs *ngIf="element.HasClipInformation">
                        <clipPath [attr.id]="element.ClipPathId">
                            <path [attr.d]="element.GetClipPathData()"/>
                        </clipPath>
            </defs>
           <svg:foreignObject class="allptrevents"
                [attr.width]="element.Width"
                [attr.height]="element.Height">
                <xhtml:div>
                    <xhtml:input  [ngClass]="'textInputStyle'"  type="text" #textInput
                        [style.min-width]="element.Width"
                        [style.max-width]="element.Width"
                        [style.min-height]="element.Height"
                        [style.max-height]="element.Height"
                        [attr.opacity]="element.Opacity"
                        [attr.minlength]="element.MinimumLength"
                        [attr.maxlength]="element.MaximumLength"
                        [(ngModel)]="element.FormattedValue"
                        [attr.disabled]="element.GetCommandEnabled() ? null : true"
                        [attr.contenteditable] = "element.GetCommandEnabled() ? true : false"
                        [attr.readonly] = "element.IsReadOnly || !element.GetCommandEnabled() ? true : null "
                        (focusout)="onLostFocus()"
                        (click)="OnMouseClick($event)"
                        (keydown)="handleInput($event)"
                        (keyup.esc)="handleCancel()"
                        [style.font-size.px]="element.GetFontSize()"
                        [style.font-family]="element.GetFontFamily()"
                        [style.border-color]="element.GetCommandBorder()"
                        [style.background-color]="element.GetBackgroundColor()"
                        [style.color]="element.StrokeColor" />
                </xhtml:div>
            </svg:foreignObject>
            <svg:rect *ngIf="element.ShowErrorBorder"
                              [attr.width]="element.Width"
                              [attr.height]="element.Height"
                              fill="url(#pattern-error-comm)"
                              stroke-width="2" stroke="#5A5D60" />
        </svg:g>`,
  styles: [
    `.allptrevents{pointer-events:all; outline: none}`,
    `.textInputStyle {cursor: pointer; outline: none; padding: 2px; margin-right: 7px; border: 1px solid; }`
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GmsStringCommandComponent extends GmsElementComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input()
  public element: GmsCommandControlString = null;
  @ViewChildren('textInput')
  public textInput: QueryList<any>;

  public handleInput(event: KeyboardEvent): void {
    if (this.element === null || (this.element.ParameterVM === null)) {
      return;
    }

    // event.keyCode == 13 Handling "Go" Button
    if ((event.keyCode === 13 ||

            event.code === 'Enter' || event.code === 'NumpadEnter')) {
      this.handleExecute();
    } else if (event.code === 'Tab') {
      return;
    } else if (!this.element.CommandVM.isModified) {
      this.element.CommandVM.isCommandExecuteDone = false;
      this.element.CommandVM.isModified = true;
      this.element.setControlEditorMode(ControlEditorMode.Edit);
      this.element.IsFocused = true;
    }
  }

  public handleCancel(): void {
    // Escape key, Cancel Button, etc
    this.element.ParameterVM.FormattedValue = this.element.ParameterVM.Value;
    this.element.CommandVM.isModified = false;
    this.textInput.first.nativeElement.blur();
    this.element.IsFocused = false;
  }

  public OnMouseClick(event: MouseEvent): void {
    if (this.element === null || this.element.IsControlInEditMode || !this.element.GetCommandEnabled()) {
      return;
    }

    if (this.element.IsInViewMode) {
      this.element.setControlEditorMode(ControlEditorMode.Highlight);
      this.textInput.first.nativeElement.select();
    } else if (this.element.IsInHighlightMode) {
      this.element.setControlEditorMode(ControlEditorMode.Edit);
      this.textInput.first.nativeElement.select();
      this.element.IsFocused = true;
    }
  }

  public onLostFocus(): void {
    if (this.element !== null) {
      this.element.IsFocused = false;
      if (this.element.IsInHighlightMode || !this.element.CommandVM.isModified) {
        this.element.setControlEditorMode(ControlEditorMode.View);
      }
    }
  }

  public ngAfterViewInit(): void {
    if (this.element !== undefined && this.element.buttons !== undefined) {
      this.element.buttons.confirmSubscription.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
        this.handleExecute();
      });
      this.element.buttons.cancelSubscription.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
        this.handleCancel();
      });
    }
  }

  public ngOnDestroy(): void {
    if (this.element !== undefined && this.element.buttons !== undefined) {
      this.element.buttons.Destroy();
    }
  }
  public handleExecute(): void {
    if (this.CanExecuteCommand()) {
      if (this.element.CommandVM.IsInputValid(this.element.locale)) {
        this.element.CommandVM.isCommandExecuteDone = false;
        this.element.CommandVM.isCommandExecuteCancel = false;
        this.element.subExecuteStatus = this.element.ExecuteCommand().subscribe(
          (res: GmsCommandResult) => {
            if (res.Success) {
              this.UpdateStatus(true);
            } else {
              this.UpdateStatus(false, res.Error);
            }
          },
          error => {
            this.UpdateStatus(false, error.message);
          }
        );
      } else {
        this.showToastNotification('warning', 'Command Execute failed: Invalid input',
          this.element.CommandVM.ErrorDescription);
      }
    } else {
      this.handleCancel();
    }
  }

  private CanExecuteCommand(): boolean {
    return this.element.CanExecuteCommand;
  }
  protected UpdateStatus(success: boolean, error: string = undefined): void {
    this.textInput.first.nativeElement.blur();
    this.element.UpdateCommandExecuteStatus(success, error);
  }
}
