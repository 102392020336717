import { Tables, TextEntry } from "@gms-flex/services/wsi-proxy-api/tables/data.model";
import { HeaderData } from "../event-data.model";
import { Observable } from "rxjs";
import { HfwFilterPillData } from "@gms-flex/controls";
import { CnsLabel, CnsLabelEn, Event, EventFilter, EventSortingField } from "@gms-flex/services";

export abstract class EventListSettingsServiceBase {
  public sortingCriteria: EventSortingField[] = [];
  public currCnsLabel: CnsLabel = null;

  public readonly columnsLabelList: string[];
  public readonly cellSupportedData: string[];
  public abstract getStateFilter(): any;
  public abstract getSourceFilter(): any;
  public abstract getHiddenFilter(): any;
  public abstract getInvestigativeSettings(): any;
  public abstract getAvailableColumns(displayParams: any): HeaderData[];
  public abstract getDisciplines(): Observable<TextEntry[]>;
  public abstract getFilterCriteria(eventFilter: EventFilter, pillDataArr: HfwFilterPillData[]): HfwFilterPillData[];
  public abstract getAllowedFilters(): any;
  public abstract compareEvents(ev1: Event, ev2: Event): number;

  /**
   * this method is NOT meant to be used outside the events components snapins,
   * it is used to add sortingCriterias to an internal array
   */
  public buildSortingCriteriaArray(): void {
    this.sortingCriteria.push(new EventSortingField('state', 0));
    this.sortingCriteria.push(new EventSortingField('categoryDescriptor', 0));
    this.sortingCriteria.push(new EventSortingField('creationTime', 0));
    this.sortingCriteria.push(new EventSortingField('messageText', 0));
    this.sortingCriteria.push(new EventSortingField('cause', 0));
    this.sortingCriteria.push(new EventSortingField('srcPath', 0));
    this.sortingCriteria.push(new EventSortingField('srcSource', 0));
    this.sortingCriteria.push(new EventSortingField('belongsTo', 0));
    this.sortingCriteria.push(new EventSortingField('informationalText', 0));
    this.sortingCriteria.push(new EventSortingField('suggestedAction', 0));
    this.sortingCriteria.push(new EventSortingField('inProcessBy', 0));
    this.sortingCriteria.push(new EventSortingField('srcAlias', 0));
    this.sortingCriteria.push(new EventSortingField('buildingName', 0));
  }

  /**
   * this method is NOT meant to be used outside the events components snapins,
   * it's used to get the SrcPath of an Event
   *
   * @param eventObj the event we want to get the SrcPath for
   * @returns a string of the srcPath according to the current CnsLabel (es: Description or NameAndAlias)
   */
  public getEventSrcPath(eventObj: Event): string {
    let srcPath: string = eventObj.srcLocation;
    if (this.currCnsLabel != null) {
      switch (this.currCnsLabel.cnsLabel) {
        case CnsLabelEn.Description:
        case CnsLabelEn.DescriptionAndAlias:
        case CnsLabelEn.DescriptionAndName:
          srcPath = eventObj.srcLocation;
          break;
        case CnsLabelEn.Name:
        case CnsLabelEn.NameAndAlias:
        case CnsLabelEn.NameAndDescription:
          srcPath = eventObj.srcDesignationDisplay;
          break;
        default:
          break;
      }
    }
    return srcPath;
  }
}
