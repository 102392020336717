import {
  booleanAttribute,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BackgroundColorVariant } from '@simpl/element-ng/common';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'si-search-bar',
  templateUrl: './si-search-bar.component.html',
  styleUrl: './si-search-bar.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: SiSearchBarComponent,
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: []
})
export class SiSearchBarComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @ViewChild('inputRef', { static: true }) private inputRef!: ElementRef<HTMLInputElement>;
  private _value = '';
  private cdRef = inject(ChangeDetectorRef);
  private debouncer = new Subject<string>();

  /**
   * Time unit change of search input takes effect.
   *
   * @defaultValue 400
   */
  @Input() debounceTime = 400;
  /**
   * Prohibited characters restricting search.
   */
  @Input() prohibitedCharacters?: string;
  /**
   * Define search input placeholder.
   *
   * @defaultValue ''
   */
  @Input() placeholder = '';
  /**
   * Display search icon before search input.
   */
  @Input({ transform: booleanAttribute }) showIcon?: boolean;
  /**
   * Whether the search is tabbable or not.
   *
   * @defaultValue true
   */
  @Input({ transform: booleanAttribute }) tabbable = true;

  /**
   * Define search input content.
   */
  get value(): string {
    return this._value;
  }

  /**
   * @defaultValue ''
   * @defaultref {@link _value}
   */
  @Input()
  set value(value: string) {
    if (value !== this._value) {
      this.writeValue(value);
      this.onChange(this._value);
      this.onTouch();
      this.searchChange.emit(this._value);
    }
  }

  /**
   * Disable component
   */
  get disabled(): boolean {
    return this.inputRef.nativeElement.disabled;
  }

  @Input({ transform: booleanAttribute })
  set disabled(value: boolean) {
    this.setDisabledState(value);
  }

  /** @defaultValue false */
  @Input({ transform: booleanAttribute })
  @HostBinding('class.readonly')
  readonly = false;
  /**
   * Color to use for component background
   *
   * @defaultValue 'base-1'
   */
  @Input() colorVariant: BackgroundColorVariant = 'base-1';
  /**
   * Output callback event will provide you with search term if search input changes.
   */
  @Output() readonly searchChange: EventEmitter<string> = new EventEmitter();

  protected isInvalid = false;
  protected inFocus = false;

  protected onChange = (val: any): void => {};
  protected onTouch = (): void => {};

  /** @internal */
  writeValue(value: string): void {
    this._value = value ?? '';
    this.inputRef.nativeElement.value = this._value;
    this.cdRef.markForCheck();
  }

  /** @internal */
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  /** @internal */
  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  /** @internal */
  setDisabledState(isDisabled: boolean): void {
    this.inputRef.nativeElement.disabled = isDisabled;
    this.cdRef.markForCheck();
  }

  ngOnInit(): void {
    this.debouncer.pipe(debounceTime(this.debounceTime)).subscribe(value => (this.value = value));
  }

  ngOnDestroy(): void {
    this.debouncer.complete();
  }

  protected resetForm(event: Event): void {
    event.stopPropagation();
    this.value = '';
  }

  private isProhibitedCharactersUsed(searchString: string | null): boolean {
    if (!this.prohibitedCharacters || !searchString) {
      return false;
    }

    for (const prohibitedCharacter of this.prohibitedCharacters) {
      if (searchString.includes(prohibitedCharacter)) {
        return true;
      }
    }

    return false;
  }

  /** @internal */
  @HostListener('focus')
  focus(): void {
    this.inputRef.nativeElement.focus();
  }

  protected onCancelFocus(event: Event): void {
    event.stopPropagation();
  }

  protected input(event: Event): void {
    const value = (event.target as HTMLInputElement).value;
    if (!this.isProhibitedCharactersUsed(value)) {
      this.debouncer.next(value);
    }
  }
}
