import { Injectable } from '@angular/core';
import { SystemInfo } from '@gms-flex/services';

import { Partition } from '../../bx-services/subscription/partition-proxy.model';

@Injectable({
  providedIn: 'root'
})
export class SystemsMapperBxToGmsService {

  public mapPartitions(partitions: Partition[]): SystemInfo[] {
    return partitions.map(partition => {
      /* eslint-disable @typescript-eslint/naming-convention */
      const systemInfo: SystemInfo = {
        Name: partition.id,
        NameDisplay: partition.attributes.name,
        Id: partition.id,
        Description: partition.attributes.description,
        IsOnline: true
      };
      /* eslint-enable @typescript-eslint/naming-convention */
      return systemInfo;
    });
  }
}
