import { Injectable } from '@angular/core';
import { FilesServiceBase } from '@gms-flex/services';
import { TraceService } from '@gms-flex/services-common';
import { BehaviorSubject, Observable, Observer, Subscription } from 'rxjs';

import { LibraryImage } from '../processor/image/gms-library-image';
import { TextGroupEntryHelper } from '../processor/textgroup/gms-text-group-helper';
import { GmsSystemsService } from './gms-systems.service';

@Injectable()
export class LibraryImageService {

  private readonly traceModule: string = 'gmsSnapins_LibraryImageService';

  private readonly _subscriptionsReadLibraryImage: Map<string, Subscription> = new Map<string, Subscription>();

  private readonly globalLibraryImages: Map<string, LibraryImage> = new Map<string, LibraryImage>();

  public constructor(private readonly traceService: TraceService,
    private readonly filesService: FilesServiceBase,
    private readonly gmsSystemsService: GmsSystemsService) {

    this.traceService.info(this.traceModule, 'Graphics Library Image service created.');
  }

  public unsubscribe(): void {
    this._subscriptionsReadLibraryImage.forEach((subscription: Subscription) => {
      if (subscription != null) {
        subscription.unsubscribe();
      }
    });
    this._subscriptionsReadLibraryImage.clear();
  }

  public getLibraryImageSource(imagePath: string): LibraryImage {
    let libraryImage: LibraryImage = this.globalLibraryImages.get(imagePath);
    if (libraryImage === undefined) {
      libraryImage = new LibraryImage(imagePath);
      this.globalLibraryImages.set(imagePath, libraryImage);
    }
    return libraryImage;
  }

  public deleteLibraryImageSource(imagePath: string): void {
    if (this.globalLibraryImages.has(imagePath)) {
      this.globalLibraryImages.delete(imagePath);
    }
  }

  public readLibraryImage(libraryImage: LibraryImage, selectedObjectSystemId: string): void {

    this.traceService.info(this.traceModule, 'readLibraryImage called for ' + libraryImage.ImagePath);
    const subscription: Subscription = this.filesService.getFile(selectedObjectSystemId, libraryImage.ImagePath)
      .subscribe(blob => this.onReadLibraryImage(libraryImage, blob),
        error => this.onReadLibraryImageError(libraryImage, error));
    if (subscription !== undefined) {
      this._subscriptionsReadLibraryImage.set(libraryImage.ImagePath, subscription);
    }
  }

  private UnsubscribeReadLibraryImage(imagePath: string): void {
    const subscription: Subscription = this._subscriptionsReadLibraryImage.get(imagePath);
    if (subscription != null) {
      subscription.unsubscribe();
      this._subscriptionsReadLibraryImage.delete(imagePath);
    }
  }

  private onReadLibraryImage(libraryImage: LibraryImage, theBlob: Blob): void {
    this.UnsubscribeReadLibraryImage(libraryImage.ImagePath);
    const reader: FileReader = new FileReader();
    reader.addEventListener('loadend', () => {
      libraryImage.isResolved = true;
      const result: string = reader.result as string;
      libraryImage.IconSource = result;
    }, false);

    // start the reading process...
    reader.readAsDataURL(theBlob);

    this.traceService.info(this.traceModule, 'onReadLibraryImage(): image  %s', theBlob);
  }

  private onReadLibraryImageError(libraryImage: LibraryImage, error: Error): void {
    this.UnsubscribeReadLibraryImage(libraryImage.ImagePath);
    libraryImage.isResolved = false;
    libraryImage.IconSource = '';
    this.traceService.error(this.traceModule, 'onReadLibraryImageError(): error: %s, image path: %s', error.message, libraryImage.ImagePath);
  }
}
