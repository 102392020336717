import { NgClass } from '@angular/common';
import {
  booleanAttribute,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { ExtendedStatusType, STATUS_ICON, StatusIcon } from '@simpl/element-ng/common';
import { SiIconComponent } from '@simpl/element-ng/icon';
import { SiTranslateModule } from '@simpl/element-translate-ng/translate';

@Component({
  selector: 'si-status-bar-item',
  templateUrl: './si-status-bar-item.component.html',
  styleUrl: './si-status-bar-item.component.scss',
  standalone: true,
  imports: [NgClass, SiIconComponent, SiTranslateModule]
})
export class SiStatusBarItemComponent implements OnChanges {
  @Input() status?: ExtendedStatusType;
  @Input() statusAriaLabel!: string;
  @Input() value!: string | number;
  @Input() heading!: string;
  @Input() color?: string;
  /** @defaultValue false */
  @Input({ transform: booleanAttribute }) blink = false;
  /** @defaultValue false */
  @Input({ transform: booleanAttribute }) valueOnly? = false;
  /** @defaultValue false */
  @HostBinding('class.clickable') @Input({ transform: booleanAttribute }) clickable = false;

  @ViewChild('bg') private bg!: ElementRef;

  protected contrastFix = false;
  protected statusIcon?: StatusIcon;
  protected background = '';

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.color || changes.blink) {
      queueMicrotask(
        () => (this.contrastFix = !!this.color && this.blink && this.calculateContrastFix())
      );
    }
    if (changes.status) {
      this.statusIcon = this.status ? STATUS_ICON[this.status] : undefined;
    }
    if (changes.status || changes.blink) {
      this.background =
        this.blink && this.status !== 'success' ? (this.statusIcon?.background ?? '') : '';
    }
  }

  private calculateContrastFix(): boolean {
    // see https://www.w3.org/TR/AERT/#color-contrast
    const rgb = getComputedStyle(this.bg.nativeElement)
      .backgroundColor?.match(/\d+/g)
      ?.map(v => +v);
    return !!rgb && Math.round((rgb[0] * 299 + rgb[1] * 587 + rgb[2] * 114) / 1000) <= 128;
  }
}
