import { AsyncPipe, NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { SiLoadingButtonComponent } from '@simpl/element-ng/loading-spinner';
import { ModalRef } from '@simpl/element-ng/modal';
import { SiTranslateModule } from '@simpl/element-translate-ng/translate';
import { take } from 'rxjs';

import { AlertDialogResult } from '../si-action-dialog.types';

@Component({
  selector: 'si-alert-dialog',
  templateUrl: './si-alert-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, AsyncPipe, SiTranslateModule, SiLoadingButtonComponent]
})
export class SiAlertDialogComponent {
  @Input() titleId?: string;
  /** @defaultValue '' */
  @Input() heading = '';
  /** @defaultValue '' */
  @Input() message = '';
  /**
   * @defaultValue
   * ```
   * $localize`:@@SI_ALERT_DIALOG.OK:OK`
   * ```
   */
  @Input() confirmBtnName = $localize`:@@SI_ALERT_DIALOG.OK:OK`;
  /**
   * @defaultValue
   * ```
   * {}
   * ```
   */
  @Input() translationParams: { [key: string]: any } = {};
  /** @defaultValue '' */
  @Input() icon = '';

  protected modalRef = inject(ModalRef<SiAlertDialogComponent, AlertDialogResult>);
  protected loading$ = this.modalRef.message.pipe(take(1));
}
