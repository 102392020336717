<button
  #toggle
  type="button"
  class="header-toggle focus-inside"
  [class.show]="mobileExpanded()"
  [attr.aria-expanded]="mobileExpanded()"
  [attr.aria-label]="mobileToggleLabel | translate"
  [attr.aria-controls]="id + '-content'"
  (click)="toggleMobileExpanded()"
>
  <span class="element-options-vertical" aria-hidden="true">
    @if (badgeCount()) {
      <span class="badge-dot"></span>
    }
  </span>
</button>
<div
  class="header-collapsible-actions"
  [id]="id + '-content'"
  [class.show]="mobileExpanded()"
  [cdkTrapFocus]="mobileExpanded()"
  (keydown.escape)="escapePressed()"
>
  <ng-content />
</div>
