import { ElementRef, Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import {
  CheckboxClickEventArgs,
  ClickEventArgs,
  FolderStateEventArgs,
  TreeItem
} from './si-tree-view.model';
import { ROOT_LEVEL } from './si-tree-view.utils';

/**
 * Supports communication between the TreeViewComponent and the TreeViewItemComponent and the services.
 * Stores tree specific settings.
 * One instance per TreeViewComponent exists.
 */
@Injectable()
export class SiTreeViewService {
  /** Emits when a tree item is clicked. */
  clickEvent: Subject<ClickEventArgs> = new Subject<ClickEventArgs>();
  /** Emits when a folder item is clicked. */
  folderClickEvent: Subject<FolderStateEventArgs> = new Subject<FolderStateEventArgs>();
  /** Emits when checkbox is clicked. */
  checkboxClickEvent: Subject<CheckboxClickEventArgs> = new Subject<CheckboxClickEventArgs>();
  /** Emits when on tree item expansion to load child items. */
  loadChildrenEvent: Subject<TreeItem> = new Subject<TreeItem>();
  /** Emits when the consumer want a node to be scrolled into view. */
  scrollIntoViewEvent: Subject<ElementRef> = new Subject<ElementRef>();
  /** Emits when the parent shall become focused. */
  focusParentEvent: Subject<TreeItem> = new Subject<TreeItem>();
  /** Emits when the child shall become focused. */
  focusFirstChildEvent: Subject<TreeItem> = new Subject<TreeItem>();
  /**
   * Sets if the checkbox state of a tree item is inherited to its children/parent.
   *
   * @defaultValue true
   */
  inheritChecked = true;
  /**
   * Shows or hides context menu button.
   *
   * @defaultValue true
   */
  enableContextMenuButton = true;
  /**
   * Sets if children are deleted upon collapsing tree items.
   *
   * @defaultValue false
   */
  deleteChildrenOnCollapse = false;
  /**
   * Allows / disabled selecting of tree items by clicking on them.
   *
   * @defaultValue false
   */
  enableSelection = false;
  /**
   * Shows or hides additional information below the label.
   *
   * @defaultValue false
   */
  enableDataField1 = false;
  /**
   * Shows or hides additional information below the label.
   *
   * @defaultValue false
   */
  enableDataField2 = false;
  /**
   * Shows or hides state pipe.
   *
   * @defaultValue true
   */
  enableStateIndicator = true;
  /**
   * Show tree item icon.
   *
   * @defaultValue true
   */
  enableIcon = true;
  /**
   * All tree items are visualized with check input.
   *
   * @defaultValue false
   */
  enableCheckbox = false;
  /**
   * All tree items are visualized with radio input.
   *
   * @defaultValue false
   */
  enableOptionbox = false;
  /**
   * Automatically expend tree item on click.
   *
   * @defaultValue false
   */
  expandOnClick = false;
  /**
   * Visualized tree as flat list.
   *
   * @defaultValue false
   */
  flatTree = false;
  /**
   * Single selection mode.
   *
   * @defaultValue false
   */
  singleSelectMode = false;
  /**
   * Child padding in pixel (px).
   *
   * @defaultValue 14
   */
  childrenIndentation = 14;
  /**
   * Visualize tree as grouped list, the child padding will have no effect.
   *
   * @defaultValue false
   */
  groupedList = false;
  /**
   * Virtualize tree items, this option should be used if you have many items.
   *
   * @defaultValue true
   */
  isVirtualized = true;
  /**
   * Whether the folder state shall be displayed tree item start or end.
   *
   * @defaultValue true
   */
  folderStateStart = true;
  /**
   * Disable the filled icon usage on selected tree items.
   *
   * @defaultValue false
   */
  disableFilledIcons = false;
  /** @internal */
  triggerMarkForCheck: Subject<void> = new Subject<void>();
  /** @internal */
  scroll$!: Observable<Event>;
  /** Indicate whether item is a group item. */
  isGroupedItem(item: TreeItem): boolean {
    return (!item.parent || item.parent.level === ROOT_LEVEL) && this.groupedList;
  }
}
