/* eslint-disable @angular-eslint/no-conflicting-lifecycle */
import { LocationStrategy } from '@angular/common';
import {
  booleanAttribute,
  ChangeDetectorRef,
  Directive,
  DoCheck,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  inject,
  InjectionToken,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  Renderer2
} from '@angular/core';
import {
  ActivatedRoute,
  IsActiveMatchOptions,
  NavigationEnd,
  NavigationExtras,
  Router,
  UrlTree
} from '@angular/router';
import { SiTranslateService } from '@simpl/element-translate-ng/translate';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { AriaCurrentType } from './aria-current.model';
import { Link } from './link.model';
import { SiLinkActionService } from './si-link-action.service';

export const SI_LINK_DEFAULT_NAVIGATION_EXTRA = new InjectionToken<NavigationExtras>(
  'SI_LINK_DEFAULT_NAVIGATION_EXTRA'
);

@Directive({
  selector: '[siLink]',
  exportAs: 'siLink',
  standalone: true
})
export class SiLinkDirective implements DoCheck, OnChanges, OnDestroy {
  @Input() siLink?: Link;
  @Input() siLinkDefaultTarget?: string;
  @Input() actionParam?: any;
  @Input() activeClass?: string;
  /** @defaultValue false */
  @Input({ transform: booleanAttribute }) exactMatch = false;
  /**
   * Type for `aria-current` to set if routerLink is active.
   */
  @Input() ariaCurrent?: AriaCurrentType;

  @Output() readonly activeChange = new EventEmitter<boolean>();

  // @HostBinding('attr.href') is used rather than @HostBinding() because
  // it removes the href attribute when it becomes `null`.
  /** @defaultValue null */
  @HostBinding('attr.href') href: string | null = null;
  @HostBinding('attr.target') target?: string;
  @HostBinding('attr.title') title?: string;
  @HostBinding('attr.aria-current')
  get isAriaCurrent(): string | null {
    return this.active ? (this.ariaCurrent ?? 'true') : null;
  }

  /** @defaultValue false */
  active = false;

  private readonly destroyer = new Subject<void>();
  private urlTree!: UrlTree;
  private navigationExtras?: NavigationExtras;
  /** Href of this item without leading hash and query params. Used to check if route is active */
  private compareHref!: string;

  private router = inject(Router, { optional: true });
  private activatedRoute = inject(ActivatedRoute, { optional: true });
  private locationStrategy = inject(LocationStrategy, { optional: true });
  private translateService = inject(SiTranslateService);
  private actionService = inject(SiLinkActionService, { optional: true });
  private element = inject(ElementRef);
  private renderer = inject(Renderer2);
  private cdRef = inject(ChangeDetectorRef);
  private defaultNavigationExtra = inject(SI_LINK_DEFAULT_NAVIGATION_EXTRA, { optional: true });

  ngOnDestroy(): void {
    this.destroyer.next();
    this.destroyer.complete();
  }

  ngOnChanges(): void {
    if (!this.siLink) {
      this.href = null;
      return;
    }
    this.destroyer.next();

    if (this.siLink.tooltip) {
      this.translateService
        .translateAsync(this.siLink.tooltip)
        .pipe(takeUntil(this.destroyer))
        .subscribe(text => {
          this.title = text;
          this.cdRef.markForCheck();
        });
    } else {
      this.title = this.siLink.tooltip;
    }
    if (this.siLink.action) {
      this.href = '';
    } else if (this.siLink.link) {
      this.subscribeRouter();
    } else if (this.siLink.href) {
      this.href = this.siLink.href;
      this.target = this.siLink.target ?? this.siLinkDefaultTarget;
    } else {
      // In case the siLink has no link, href or action,
      // we remove the href to avoid the mouse pointer.
      this.href = null;
    }

    this.updateActive();
  }

  ngDoCheck(): void {
    // this deep-checks if isActive has changed. It then updates the internal state and emits the event
    // to be symmetric with the router-link case. queueMicroTask avoids "Expression has changed after it was checked" errors
    if (
      this.siLink &&
      !this.siLink.link &&
      this.siLink.isActive !== undefined &&
      this.active !== this.siLink.isActive
    ) {
      this.active = this.siLink.isActive;
      queueMicrotask(() => {
        this.activeChange.emit(this.active);
        this.updateActiveClass();
      });
    }
  }

  private subscribeRouter(): void {
    if (!this.router || !this.activatedRoute) {
      return;
    }
    this.router.events
      .pipe(
        takeUntil(this.destroyer),
        filter(e => e instanceof NavigationEnd)
      )
      .subscribe(() => this.updateActive());

    this.activatedRoute.queryParams.pipe(takeUntil(this.destroyer)).subscribe(() => {
      this.updateUrlTree();
      this.updateActive();
      this.cdRef.detectChanges();
    });
  }

  private updateUrlTree(): void {
    if (!this.router || !this.siLink || !this.locationStrategy) {
      return;
    }
    const commands = Array.isArray(this.siLink.link) ? this.siLink.link : [this.siLink.link];
    this.navigationExtras = {
      relativeTo: this.activatedRoute,
      preserveFragment: true,
      queryParamsHandling: 'merge',
      ...this.defaultNavigationExtra,
      ...this.siLink.navigationExtras
    };
    this.urlTree = this.router.createUrlTree(commands, this.navigationExtras);
    this.href = this.locationStrategy.prepareExternalUrl(this.router.serializeUrl(this.urlTree));
    // Remove leading hash and query params
    this.compareHref = this.href.replace(/^(#)/, '').replace(/\?.*/, '');
  }

  private updateActive(): void {
    const matchOptions: IsActiveMatchOptions = this.exactMatch
      ? { paths: 'exact', queryParams: 'exact', fragment: 'ignored', matrixParams: 'ignored' }
      : { paths: 'subset', queryParams: 'subset', fragment: 'ignored', matrixParams: 'ignored' };

    // Promise is used to work around Angular view check error
    queueMicrotask(() => {
      let active = false;
      if (this.siLink) {
        if (this.siLink.isActive) {
          active = true;
        } else if (this.siLink.link && this.router) {
          // Compare with a string so we can ignore query params
          active = this.router.isActive(this.compareHref, matchOptions);
        }
      }

      if (active !== this.active) {
        this.active = active;
        this.activeChange.emit(active);
      }

      this.updateActiveClass();
    });
  }

  private updateActiveClass(): void {
    if (this.activeClass) {
      if (this.active) {
        this.renderer.addClass(this.element.nativeElement, this.activeClass);
      } else {
        this.renderer.removeClass(this.element.nativeElement, this.activeClass);
      }
    }
  }

  @HostListener('click', ['$event'])
  onClick(event: any): void {
    if (this.siLink?.action) {
      event.preventDefault();

      if (typeof this.siLink.action === 'string') {
        this.actionService?.emit(this.siLink, this.actionParam);
      } else if (this.actionParam === undefined) {
        this.siLink.action();
      } else {
        this.siLink.action(this.actionParam);
      }
      this.updateActive();
      return;
    }

    // ignore regular links, allow user to open links in new tab or window
    if (
      !this.siLink ||
      this.siLink.href ||
      event.button > 0 ||
      event.ctrlKey ||
      event.metaKey ||
      event.shiftKey
    ) {
      return;
    }

    event.preventDefault();
    // We have links without any action, href or link. For example grouping navbar items that hold a
    // dropdown with links. That is why we need to check if the link property is set.
    if (this.siLink.link && this.router) {
      this.router.navigateByUrl(this.urlTree, this.navigationExtras);
    }
  }
}
