import { Observable } from 'rxjs';

/**
 * Base class for the site selection service.
 * Provides the functionality to set selected site.
 *
 * @export
 * @abstract
 * @class SiteSelectionServiceBase
 */

export abstract class SiteSelectionServiceBase {
  /**
   * Set Selected Site
   *
   * @abstract
   * @param {string | undefined} objectId ObjectId of the selected site in the form of System:ObjectId
   * @param {boolean | undefined} singleSiteActive Active side mode information
   * @returns void
   *
   * @memberOf SiteSelectionServiceBase
   */
  public abstract setSite(objectId: string | undefined, singleSiteActive: boolean | undefined): void;

  /**
   * Observable of the selected site.
   * When subscribed, an object with the last selected site and info for active site mode is returned.
   *
   * @abstract
   * @type {Observable<{ objectId: string | undefined, singleSiteActive: boolean | undefined }>}
   */
  public abstract get selectedSite(): Observable<{ objectId: string | undefined, singleSiteActive: boolean | undefined }>;
}
