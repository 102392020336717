import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SiLinkModule } from '@simpl/element-ng/link';
import { SiTranslateModule } from '@simpl/element-translate-ng/translate';

import { SiMenuLegacyItemComponent } from './si-menu-legacy-item/si-menu-legacy-item.component';
import { SiMenuLegacyComponent } from './si-menu-legacy.component';

/**
 * @deprecated The {@link SiMenuLegacyModule} and all related symbols should no longer be used.
 * Please use {@link SiMenuModule} instead.
 * Read the {@link https://simpl.code.siemens.io/simpl-element/components/buttons-menus/menu/ | documentation} for more information.
 */
@NgModule({
  imports: [
    CommonModule,
    SiLinkModule,
    SiMenuLegacyComponent,
    SiMenuLegacyItemComponent,
    SiTranslateModule
  ],
  exports: [SiMenuLegacyComponent, SiMenuLegacyItemComponent]
})
export class SiMenuLegacyModule {}
