/**
 * Base class for the summary-bar service.
 * Provides the methods to get if summary bar functionalities are visible.
 *
 * @export
 * @abstract
 * @class SummaryBarSnapInServiceBase
 */
import { Observable } from "rxjs";

export abstract class SummaryBarSnapInServiceBase {

   /**
   * Get the visibility of buzzer.
   *s
   * @abstracts
   * @returns { boolean}
   *
   * @memberOf SummaryBarSnapInServiceBase
   */
   public abstract buzzerVisible(): boolean ;
   public abstract disableFrameSwitch(): Observable<boolean> ;
   public abstract showTotalEvNumberOnly(): boolean ;
   public abstract setSelectedCategory(categoryId: number | undefined): void;
   public abstract get getSelectedCategory(): Observable<number | undefined>;
}
