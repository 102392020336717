import { Injectable } from "@angular/core";
import { SiqudtUnitConversionPipe } from "@building-x/common-ui-ng";
import { PropertyDetails, SubscriptionGmsVal, ValueDetails } from "@gms-flex/services";
import { isNullOrUndefined } from "@siemens/ngx-datatable";
import { PointAttributes, PointValue } from "src/app/bx-services/point/point-proxy.model";

import { formatNumericString } from "../../../core/shared/string-utils";
import { PointTypeService } from "../point-type.service";
import { TextGroupBxService } from "../text-group-bx.service";
import { PropertyMapper } from "./property-mapper";

export const pointPropertyName = 'PresentValue';

@Injectable({
  providedIn: 'root'
})

export class PointValueMapperService extends PropertyMapper {

  constructor(
    private readonly textGroupBxService: TextGroupBxService, 
    private readonly pointTypeService: PointTypeService,
    private readonly qudtPipe: SiqudtUnitConversionPipe) {
    super();
  }

  public createPointProperty(pointId: string, pointAttributes: PointAttributes, order: number): PropertyDetails {
    const txtGroup = this.textGroupBxService.createTextGroup(pointId, pointAttributes);
    
    const dccUnit = this.getDccUnit(this.qudtPipe, pointAttributes?.unit);

    const propDetails: PropertyDetails = {
      /* eslint-disable @typescript-eslint/naming-convention */
      PropertyName: pointPropertyName,
      Descriptor: 'Value',
      DisplayType: 0,
      IsArray: false,
      Order: order,
      Resolution: this.roundByPrecision(pointAttributes),
      Type: this.pointTypeService.getDccType(pointAttributes),
      Value: undefined,
      Min: formatNumericString(pointAttributes?.minimum),
      Max: formatNumericString(pointAttributes?.maximum),
      UnitDescriptor: dccUnit?.unit,
      UnitId: dccUnit?.id,
      Usage: 3, // Show only in basic and extended properties (Bit0: Show in basic properties, Bit1: Show in extended properties)
      TextTable: txtGroup ? txtGroup.id : undefined,
      PropertyType: 0 // Type = 0,Indexed = 1,Functions = 2
      /* eslint-enable @typescript-eslint/naming-convention */
    };
    
    return propDetails
  }

  public createValue(subGms: SubscriptionGmsVal, pointId: string, pointValue: PointValue, pointAttributes: PointAttributes): ValueDetails {
    // TODO: finalize quality bits
    let displayValue: string | undefined = '#NaN';
    if (!isNullOrUndefined(pointValue)) {
      if (this.showDisplayValue(pointValue.qualityOfValue)) {
        displayValue = this.textGroupBxService.resolveRawValue(pointId, pointValue.value);
      }
      if (displayValue === undefined) {
        displayValue = pointValue.value;
      }

      // TODO: properly display quality state!
      if (pointValue.qualityOfValue === 2) {
        displayValue = '#COM';
      }
      if (pointValue.qualityOfValue === 3) {
        displayValue = '#ENG';
      }

    }

    return {
      /* eslint-disable @typescript-eslint/naming-convention */
      DataType: this.pointTypeService.getDccType(pointAttributes),
      ErrorCode: subGms.errorCode,
      SubscriptionKey: subGms.key,
      IsArray: false,
      Value: {
        Value: (!isNullOrUndefined(pointValue)) ? pointValue.value : '',
        DisplayValue: displayValue,
        Timestamp: pointValue?.createdAt,
        QualityGood: this.showDisplayValue(pointValue?.qualityOfValue),
        // QualityGood: pointValue?.qualityOfValue ? (pointValue.qualityOfValue === 0) : true,
        Quality: '0' // TODO: check the usage
      }
      /* eslint-enable @typescript-eslint/naming-convention */
    };
  }
}
