import { NgClass } from '@angular/common';
import {
  booleanAttribute,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostAttributeToken,
  HostBinding,
  HostListener,
  inject,
  Input,
  NgZone,
  OnChanges,
  Output,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { correctKeyRTL, isRTL, listenGlobal } from '@simpl/element-ng/common';
import { SI_FORM_ITEM_CONTROL, SiFormItemControl } from '@simpl/element-ng/form';
import { SiTranslateModule } from '@simpl/element-translate-ng/translate';
import { Subscription, timer } from 'rxjs';

@Component({
  selector: 'si-slider',
  templateUrl: './si-slider.component.html',
  styleUrl: './si-slider.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: SiSliderComponent,
      multi: true
    },
    {
      provide: SI_FORM_ITEM_CONTROL,
      useExisting: SiSliderComponent
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, SiTranslateModule],
  host: {
    role: 'group'
  }
})
export class SiSliderComponent implements OnChanges, ControlValueAccessor, SiFormItemControl {
  private static idCounter = 0;

  @ViewChild('handle', { static: true }) private handleRef!: ElementRef;

  @Input() id = `__si-slider-${SiSliderComponent.idCounter++}`;

  /**
   * Current value of slider.
   */
  @Input() value?: number;
  /**
   * Minimum of slider range.
   *
   * @defaultValue 0
   */
  @Input() min = 0;
  /**
   * Maximum of slider range.
   *
   * @defaultValue 100
   */
  @Input() max = 100;
  /**
   * Label to describe minimum of slider range.
   *
   * @defaultValue ''
   */
  @Input() minLabel = '';
  /**
   * Label to describe maximum of slider range.
   *
   * @defaultValue ''
   */
  @Input() maxLabel = '';
  /**
   * Interval to step through the slider.
   *
   * @defaultValue 1
   */
  @Input() step = 1;
  /**
   * Disables option to change slider value.
   *
   * @defaultValue false
   */
  @Input({ transform: booleanAttribute }) disabled = false;
  /**
   * Icon to use as the slider thumb.
   */
  @Input() thumbIcon?: string;
  /**
   * Text for aria-label of increment. Needed for a11y.
   *
   * @defaultValue
   * ```
   * $localize`:@@SI_SLIDER.INCREMENT:Increment`
   * ```
   */
  @Input() incrementLabel = $localize`:@@SI_SLIDER.INCREMENT:Increment`;
  /**
   * Text for aria-label of decrement. Needed for a11y.
   *
   * @defaultValue
   * ```
   * $localize`:@@SI_SLIDER.DECREMENT:Decrement`
   * ```
   */
  @Input() decrementLabel = $localize`:@@SI_SLIDER.DECREMENT:Decrement`;
  /**
   * Text for aria-label of slider. Needed for a11y
   *
   * @defaultValue
   * ```
   * $localize`:@@SI_SLIDER.LABEL:Value`
   * ```
   */
  @Input() sliderLabel = $localize`:@@SI_SLIDER.LABEL:Value`;

  @HostBinding('attr.aria-labelledby') labelledby =
    inject(new HostAttributeToken('aria-labelledby'), {
      optional: true
    }) ?? `${this.id}-label`;

  /**
   * Output callback that triggers when the slider value changes.
   */
  @Output() readonly valueChange = new EventEmitter();

  @HostBinding('class.disabled') protected get isDisabled(): boolean {
    return this.disabled || this.min === this.max;
  }

  protected indicatorPos = 50;
  protected isDragging = false;

  private autoUpdate$ = timer(400, 80); // 250
  private autoUpdateSubs?: Subscription;
  private rtl = false;

  private unlistenDragEvents: (() => void)[] = [];

  private onTouchedCallback: () => void = () => {};
  private onChangeCallback: (val: any) => void = () => {};

  private zone = inject(NgZone);
  private changeDetectorRef = inject(ChangeDetectorRef);

  private incrementValue(): void {
    this.value = this.normalizeValue(this.value! + this.step);
    this.valueChanged();
  }

  private decrementValue(): void {
    this.value = this.normalizeValue(this.value! - this.step);
    this.valueChanged();
  }

  private roundToStepPrecision(value: number): number {
    const factor = 1 / this.step;
    if (factor > 1) {
      return Math.round(value * factor) / factor;
    }
    return Math.round(value / this.step) * this.step;
  }

  private normalizeValue(value: number): number {
    return Math.min(Math.max(this.roundToStepPrecision(value), this.min), this.max);
  }

  private updateIndicatorPosition(): void {
    const range = this.max - this.min;
    if (range === 0) {
      this.indicatorPos = 50;
      return;
    }
    const indicatorPos = ((this.value! - this.min) * 100) / range;
    this.indicatorPos = Math.max(Math.min(indicatorPos, 100), 0);
  }

  private handleTouchMove(event: TouchEvent): void {
    if (event.cancelable) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.handleDragMove(event.touches[0]);
  }

  private handleMouseMove(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.handleDragMove(event);
  }

  private handleDragMove(event: MouseEvent | Touch): void {
    const pointerPosX = event.clientX;
    const handleRect = this.handleRef.nativeElement.getBoundingClientRect();
    const handleWrapperWidth = this.handleRef.nativeElement.parentElement.clientWidth;

    const direction = this.rtl ? -1 : 1;
    const pointerPosDelta =
      Math.round(pointerPosX - (handleRect.x + handleRect.width / 2)) * direction;
    const valueDelta = (pointerPosDelta / handleWrapperWidth) * (this.max - this.min);

    const newValue = this.normalizeValue(this.value! + valueDelta);

    if (
      (pointerPosDelta > 0 && newValue > this.value!) ||
      (pointerPosDelta < 0 && newValue < this.value!)
    ) {
      // the zone is required to work around a problem on native device where CD doesn't trigger
      this.zone.run(() => {
        this.changeDetectorRef.markForCheck();
        this.value = newValue;
        this.valueChanged();
      });
    }
    window.getSelection()?.removeAllRanges();
  }

  private handleDragEnd(): void {
    this.isDragging = false;

    this.unlistenDragEvents.forEach(handler => handler());
    this.unlistenDragEvents.length = 0;
  }

  private handleUndefinedValue(): void {
    this.value ??= this.roundToStepPrecision((this.min + this.max) / 2);
  }

  ngOnChanges(): void {
    this.handleUndefinedValue();
    this.updateIndicatorPosition();
  }

  @HostListener('pointerdown', ['$event'])
  @HostListener('mousedown', ['$event'])
  @HostListener('touchstart', ['$event'])
  protected handlePointerDown(event: Event): void {
    event.stopPropagation();
  }

  protected autoUpdateKeydown(event: KeyboardEvent): void {
    const rtlCorrectedKey = correctKeyRTL(event.key);

    if (rtlCorrectedKey === 'ArrowLeft') {
      this.autoUpdateStart(event, false);
    } else if (rtlCorrectedKey === 'ArrowRight') {
      this.autoUpdateStart(event, true);
    }
  }

  protected autoUpdateStart(event: Event, isIncrement: boolean): void {
    event.preventDefault();

    const trigger = isIncrement ? () => this.incrementValue() : () => this.decrementValue();

    this.autoUpdateSubs?.unsubscribe();
    this.autoUpdateSubs = this.autoUpdate$.subscribe(trigger);
    trigger();
  }

  protected autoUpdateStop(): void {
    if (this.autoUpdateSubs) {
      this.autoUpdateSubs.unsubscribe();
      this.autoUpdateSubs = undefined;
    }
  }

  protected handleMouseDown(event: MouseEvent): void {
    this.unlistenDragEvents.push(
      listenGlobal('mousemove', moveEvent => this.handleMouseMove(moveEvent))
    );
    this.unlistenDragEvents.push(listenGlobal('mouseup', () => this.handleDragEnd()));

    this.isDragging = true;

    this.rtl = isRTL();
    this.handleMouseMove(event);
  }

  protected handleTouchStart(event: TouchEvent): void {
    if (event.touches.length !== 1) {
      return;
    }

    this.unlistenDragEvents.push(listenGlobal('touchmove', e => this.handleTouchMove(e), true));
    this.unlistenDragEvents.push(listenGlobal('touchend', () => this.handleDragEnd()));

    this.isDragging = true;

    this.rtl = isRTL();
    this.handleTouchMove(event);
  }

  private valueChanged(): void {
    this.updateIndicatorPosition();
    this.onTouchedCallback();
    this.onChangeCallback(this.value);
    this.valueChange.emit(this.value);
  }

  /** @internal */
  writeValue(val: any): void {
    this.value = val;
    this.handleUndefinedValue();
    this.updateIndicatorPosition();
    this.changeDetectorRef.markForCheck();
  }

  /** @internal */
  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  /** @internal */
  registerOnTouched(fn: () => void): void {
    this.onTouchedCallback = fn;
  }

  /** @internal */
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.changeDetectorRef.markForCheck();
  }
}
