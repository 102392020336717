import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { SiLinkModule } from '@simpl/element-ng/link';
import { SiTranslateModule } from '@simpl/element-translate-ng/translate';

import { SiApplicationHeaderComponent } from '../si-application-header.component';
import { SiLaunchpadAppComponent } from './si-launchpad-app.component';
import { App, AppCategory } from './si-launchpad.model';

export interface FavoriteChangeEvent {
  app: App;
  favorite: boolean;
}

@Component({
  selector: 'si-launchpad-factory',
  standalone: true,
  imports: [A11yModule, CommonModule, SiLinkModule, SiTranslateModule, SiLaunchpadAppComponent],
  templateUrl: './si-launchpad-factory.component.html',
  styleUrl: './si-launchpad-factory.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SiLaunchpadFactoryComponent implements OnChanges {
  /**
   * Text to close the launchpad. Needed for a11y.
   *
   * @defaultValue
   * ```
   * $localize`:@@SI_LAUNCHPAD.CLOSE:Close launchpad`
   * ```
   */
  @Input() closeText = $localize`:@@SI_LAUNCHPAD.CLOSE:Close launchpad`;

  /**
   * Title of the launchpad.
   *
   * @defaultValue
   * ```
   * $localize`:@@SI_LAUNCHPAD.TITLE:Launchpad`
   * ```
   */
  @Input() titleText = $localize`:@@SI_LAUNCHPAD.TITLE:Launchpad`;

  /**
   * Subtitle of the launchpad.
   *
   * @defaultValue
   * ```
   * $localize`:@@SI_LAUNCHPAD.SUBTITLE:Access all your apps`
   * ```
   */
  @Input() subtitleText = $localize`:@@SI_LAUNCHPAD.SUBTITLE:Access all your apps`;

  /** All app items shown in the launchpad. */
  @Input({ required: true }) apps!: App[] | AppCategory[];

  /**
   * Allow the user to select favorite apps which will then be displayed at the top.
   *
   * @defaultValue false
   */
  @Input({ transform: booleanAttribute }) enableFavorites = false;

  /**
   * Title of the favorite apps section.
   *
   * @defaultValue
   * ```
   * $localize`:@@SI_LAUNCHPAD.FAVORITE_APPS:Favorite apps`
   * ```
   */
  @Input() favoriteAppsText = $localize`:@@SI_LAUNCHPAD.FAVORITE_APPS:Favorite apps`;

  /**
   * Title of the show more apps button.
   *
   * @defaultValue
   * ```
   * $localize`:@@SI_LAUNCHPAD.SHOW_MORE:Show more`
   * ```
   */
  @Input() showMoreAppsText = $localize`:@@SI_LAUNCHPAD.SHOW_MORE:Show more`;

  /**
   * Title of the show less apps button.
   *
   * @defaultValue
   * ```
   * $localize`:@@SI_LAUNCHPAD.SHOW_LESS:Show less`
   * ```
   */
  @Input() showLessAppsText = $localize`:@@SI_LAUNCHPAD.SHOW_LESS:Show less`;

  @Output() readonly favoriteChange = new EventEmitter<FavoriteChangeEvent>();

  protected showAllApps = false;
  protected categories!: AppCategory[];
  protected hasFavorites = false;

  private header = inject(SiApplicationHeaderComponent);

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.apps) {
      this.calcAppItems();
    }
  }

  protected closeLaunchpad(): void {
    this.header.closeLaunchpad();
  }

  private calcAppItems(): void {
    this.hasFavorites = false;
    if (this.isCategories(this.apps)) {
      this.categories = [...this.apps];
    } else {
      this.categories = [{ name: '', apps: [...this.apps] }];
    }

    if (this.enableFavorites) {
      const favorites = this.apps
        .flatMap(app => ('apps' in app ? app.apps : (app as App)))
        .filter(app => app.favorite);
      this.hasFavorites = favorites.length > 0;
      if (this.hasFavorites) {
        this.categories.splice(0, 0, {
          name: this.favoriteAppsText,
          apps: favorites
        });
      }
    }
  }

  protected toggleFavorite(app: App, favorite: boolean): void {
    this.favoriteChange.emit({ app, favorite });
  }

  protected escape(): void {
    this.closeLaunchpad();
  }

  protected isCategories(items: App[] | AppCategory[]): items is AppCategory[] {
    return items.some(item => 'apps' in item);
  }
}
