export abstract class TrendEvalutionServiceBase {
  
  /**
     *
     * @abstract This method checks if managed type is NEW_TREND
     * @param {string} managedType to check if it is one NEW_TREND
     * @param {TrendAction} TrendAction requested by the user
     * @returns {Observable<Boolean>}  Return true if managedType is NEW_TREND
     *
     * @memberOf TrendEvalutionServiceBase
     */
  public abstract doCheckIfManagedTypeIsNewTrend(managedType: string): boolean;

  /**
     *
     * @abstract This method checks if managed type is TRENDVIEWDEFINITION
     * @param {string} managedType to check if it is one of the TRENDVIEWDEFINITION
     * @param {TrendAction} TrendAction requested by the user
     * @returns {Observable<Boolean>}  Return true if managedType is one the TRENDVIEWDEFINITION
     *
     * @memberOf TrendEvalutionServiceBase
     */
  public abstract doCheckIfManagedTypeIsTrendViewDefination(managedType: string): boolean;
   
  /**
     *
     * @abstract this API decides whether to show trend tiles or not
     * @param {string} managedType for which you want to show trend tiles
     * @returns {Observable<Boolean>}  Return true if you want to show trend tiles for specified managedType
     *
     * @memberOf TrendEvalutionServiceBase
     */
  public abstract doShowTrendTile(managedType: string): boolean;

  /**
     *
     * @abstract function to decide whether to show snapin content actions or not
     * @param {string} managedType for which you want to show snapin content actions
     * @returns {Observable<Boolean>} Return true if you want to show snapin content actions for specified manageType
     *
     * @memberOf TrendEvalutionServiceBase
     */
  public abstract doShowSnapinContentAction(managedType: string): boolean;

  /**
     *
     * @abstract Function decides whether to allow trend modification for specified ManagedType
     * @param {string} managedType, for which we want to check if this is supported Managed type or not to add point
     * @returns {Observable<Boolean>} Return true if specified managed type is supported to Add
     *
     * @memberOf TrendEvalutionServiceBase
     */
  public abstract doAllowOnlineTrendModification(managedType: string): boolean;

  /**
     *
     * @abstract this API checks if ManagedType is OfflineTrendLog
     * @param {string} managedType to check if it is Offline TrendLog
     * @returns {Observable<Boolean>} Return true if managedType is Offline Trendlog
     *
     * @memberOf TrendEvalutionServiceBase
     */
  public abstract doCheckIfManagedTypeIsOfflineTrendLog(managedType: string): boolean;

  /**
     *
     * @abstract This API checks if ManagedType is Online or offline Trendlog
     * @param {string} managedType to check if it is Online or offline Trendlog
     * @returns {Observable<Boolean>} Return true if amanagedType is online or offline trendlog
     *
     * @memberOf TrendEvalutionServiceBase
     */
  public abstract doCheckIfManagedTypeIsOfflineOrOnlineTrendLog(managedType: string): boolean;

  /**
     *
     * @abstract This API returns delay timeout to set while send the response from the setSettings callback
     * @returns {number} Return timeout delay in milliseconds
     *
     * @memberOf TrendEvalutionServiceBase
     */
  public abstract getGenSettingsTimeout(): number;

  /**
     * 
     * @abstract Function decides whether to remove unnecessary content action 
     * @returns {Observable<Boolean>} Return true to show required buttons and content actions
     * @memberOf TrendEvalutionServiceBase
     */
  public abstract doShowButtonsAndContentAction(): boolean;
  
  /**
     * 
     * @abstract Function decides whether to show or remove unsaved dailog poup
     * @returns {Observable<Boolean>} Return true to show unsaved dailog poup after edit
     * @memberOf TrendEvalutionServiceBase
     */
  public abstract doShowUnsavedDialogPopup(): boolean;
}
