import { CommonModule } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { HfwControlsModule } from '@gms-flex/controls';
import { HFW_TRANSLATION_FILE_TOKEN } from '@gms-flex/services-common';
import { TranslateModule } from '@ngx-translate/core';
/* service import */
import { SiAccordionModule, SiContentActionBarModule, SiEmptyStateModule, SiMenuLegacyModule,
  SiResizeObserverModule, SiSidePanelModule, SiTabsModule } from '@simpl/element-ng';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';

import { IHfwMessage } from './common/interfaces/ihfwmessage';
import { ISnapInActions } from './common/interfaces/isnapinactions.service';
import { ISnapInConfig } from './common/interfaces/isnapinconfig';
/* component import */
import { IStateService } from './common/interfaces/istate.service';
import { ErrorDialogComponent } from './core/dialogs/error-dialog/error-dialog.component';
import { MinimumSizeDialogComponent } from './core/dialogs/minimum-size-dialog/minimum-size-dialog.component';
import { FrameComponent } from './core/frame/frame.component';
import { LayoutComponent } from './core/layout/layout.component';
import { MobileNavigationDirective } from './core/mobile/mobile-directive/mobile-navigation.directive';
import { MobileViewComponent } from './core/mobile/mobile-view/mobile-view.component';
import { PageNotFoundComponent } from './core/page/page-not-found.component';
import { PageComponent } from './core/page/page.component';
import { PaneHeaderComponent } from './core/pane-header/pane-header.component';
import { PaneTabComponent } from './core/pane-tab/pane-tab.component';
import { PaneTabSelectedComponent } from './core/pane-tab/pane-tabselected.component';
import { PaneComponent } from './core/pane/pane.component';
import { RouterOutletComponent } from './core/shared/routing/router-outlet.component';
import { SnapinHostComponent } from './core/snapin-host/snapin-host.component';
import { SnapinActionsService } from './core/snapinactions/snapinactions.service';
import { SnapInConfigService } from './core/snapinconfig/snapinconfig.service';
import { SnapinMessageBroker } from './core/snapinmessagebroker/snapinmessagebroker.service';
import { SplitterHostComponent } from './core/splitterhost/splitterhost.component';
import { IState } from './core/state/istate';
import { ReuseStrategyService } from './core/state/reuse-strategy.service';
import { StateService } from './core/state/state.service';
import { HfwTabComponent } from './core/tabs/hfw-tab/hfw-tab.component';
import { HfwTabsetComponent } from './core/tabs/hfw-tabset/hfw-tabset.component';
import { ViewComponent } from './core/view';
@NgModule({ declarations: [
  ErrorDialogComponent,
  FrameComponent,
  HfwTabComponent,
  HfwTabsetComponent,
  LayoutComponent,
  MinimumSizeDialogComponent,
  MobileNavigationDirective,
  MobileViewComponent,
  PageComponent,
  PageNotFoundComponent,
  PaneComponent,
  PaneHeaderComponent,
  PaneTabComponent,
  PaneTabSelectedComponent,
  RouterOutletComponent,
  SnapinHostComponent,
  SplitterHostComponent,
  ViewComponent
],
exports: [
  ErrorDialogComponent,
  FrameComponent,
  HfwTabComponent,
  HfwTabsetComponent,
  LayoutComponent,
  MobileNavigationDirective,
  MobileViewComponent,
  PageComponent,
  PageNotFoundComponent,
  PaneComponent,
  PaneHeaderComponent,
  RouterOutletComponent,
  SnapinHostComponent,
  SplitterHostComponent
],
schemas: [CUSTOM_ELEMENTS_SCHEMA], imports: [CommonModule,
  FormsModule,
  HfwControlsModule,
  ModalModule.forRoot(),
  RouterModule,
  SiAccordionModule,
  SiContentActionBarModule,
  SiEmptyStateModule,
  SiMenuLegacyModule,
  SiResizeObserverModule,
  SiSidePanelModule,
  SiTabsModule,
  TabsModule,
  TranslateModule], providers: [
  { provide: HFW_TRANSLATION_FILE_TOKEN, useValue: './@gms-flex/core/i18n/', multi: true },
  { provide: IHfwMessage, useClass: SnapinMessageBroker },
  { provide: ISnapInConfig, useClass: SnapInConfigService },
  { provide: ISnapInActions, useClass: SnapinActionsService },
  { provide: RouteReuseStrategy, useClass: ReuseStrategyService },
  { provide: IStateService, useExisting: StateService },
  { provide: IState, useExisting: StateService },
  provideHttpClient(withInterceptorsFromDi())
] })

export class HfwCoreModule {}
