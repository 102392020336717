import {
  AfterContentChecked, AfterViewInit, ChangeDetectorRef,
  Component, ElementRef, EventEmitter, HostBinding, Input,
  OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { animationFrameScheduler, Observable, Subject, Subscription } from 'rxjs';
import {
  BOOTSTRAP_BREAKPOINTS, Criterion, CriterionValue, DatepickerInputConfig, MenuItem, ResizeObserverService,
  SearchCriteria, SiFilteredSearchComponent, SiToastNotificationService, SplitOrientation
} from '@simpl/element-ng';
import {
  BrowserObject,
  Filter,
  GmsMessageData,
  GmsSelectionType,
  HistLogColumnDescription,
  HistoryLogKind,
  LogViewerServiceBase,
  LogViewResult,
  RelativeTimeUnitEnum,
  RowDetailsDescription,
  SystemBrowserServiceBase,
  TimeRangeFilter,
  TimeRangeSelectionEnum
} from '@gms-flex/services';
import { FullSnapInId, IHfwMessage, IStorageService, MobileNavigationService, ParamsSendMessage, SnapInBase, UnsavedDataReason } from '@gms-flex/core';
import { AppContextService, SettingsServiceBase, TraceService } from '@gms-flex/services-common';

import { HistoryLogService } from './services/history-log.service';
import { TraceModules } from './shared/trace-modules';
import { LogViewerTableComponent } from './log-viewer-table/log-viewer-table.component';
import {
  ActivityOriginalEnumValues,
  ColumnSettings,
  CustomDialog,
  ILogViewerObj,
  MasterDetailContainerSettings,
  PaneControls, SelectionDetail, WarningMessageContent
} from './services/history-log-view.model';
import { LogViewerRowDetailsComponent } from './log-viewer-row-details/log-viewer-row-details.component';
import { id } from '@siemens/ngx-datatable';
import { isBuffer } from 'util';
import { debounceTime } from 'rxjs/operators';
import { EventsCommonServiceBase } from '../events/services/events-common.service.base';

interface SearchFilterData extends CriterionValue {
  datepickerConfig?: DatepickerInputConfig,
}

enum SourceNames {
  SourceDesignation = 'DefaultViewDesignation',
  SourceLocation = 'DefaultViewLocation'
}

@Component({
  selector: 'gms-log-viewer',
  templateUrl: './log-viewer.component.html',
  styleUrl: './log-viewer.component.scss',
  providers: [HistoryLogService]
})
export class LogViewerComponent implements OnInit, OnChanges, OnDestroy, AfterContentChecked, AfterViewInit {
  @ViewChild(LogViewerTableComponent) public logViewertable!: LogViewerTableComponent;
  @ViewChild(LogViewerRowDetailsComponent) public logViewerDetails!: LogViewerRowDetailsComponent;
  @ViewChild('logViewerTable') public logViewerTableElement!: LogViewerTableComponent;
  @ViewChild('logViewer', { static: true, read: ElementRef }) public logViewerElement!: ElementRef;
  @ViewChild('siMasterDetailContainer', { static: true, read: ElementRef }) public siMasterDetailContainer!: ElementRef;
  @ViewChild('rowDetailsPane', { static: true, read: ElementRef }) public rowDetailsPane!: ElementRef;
  @ViewChild(SiFilteredSearchComponent) public siFilteredSearchComponent!: SiFilteredSearchComponent;
  @HostBinding('class.hfw-flex-container-column') public guardFrame = true;
  @HostBinding('class.hfw-flex-item-grow') public guardGrow = true;
  @HostBinding('class.snapin-container-overflow-auto') public guardOverflow = true;
  @HostBinding('class.rounded-bottom') public roundedBorder = true;
  @Input()
  public fromSnapin = false;
  @Input()
  public fullId?: FullSnapInId;
  @Input()
  public systemId?: string;
  @Input()
  public managedTypeName?: string;
  @Input()
  public objectId?: string;
  @Input()
  public logViewerChangeDetect: number;
  @Input()
  public readonly storageService: IStorageService;
  @Input()
  public objectDesignationRightPane?: string;
  @Input()
  public objectLocationRightPane?: string;
  @Input()
  public objectIdRightPane?: string;
  @Input()
  public isHistoryExpanded: boolean;
  @Input()
  public searchPlaceHolder: string;
  @Input()
  public receivedViewId: number;
  @Output()
  public readonly secondaryRowSelection: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  public readonly sendSelectionEvent: EventEmitter<SelectionDetail> = new EventEmitter<SelectionDetail>();
  @Output()
  public readonly dataLength: EventEmitter<number> = new EventEmitter<number>();
  @Output()
  public readonly isLoadingDataEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  public readonly isDetailActive: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  public readonly paneControlsOp: EventEmitter<PaneControls> = new EventEmitter<PaneControls>();
  @Output()
  public readonly criteriaLocLogViewer: EventEmitter<Criterion[]> = new EventEmitter<Criterion[]>();
  // ---------------------------------------------Master detail container-------------------------------------------
  public detailsActive = false; // this is the default
  public largeLayoutBreakpoint = BOOTSTRAP_BREAKPOINTS.mdMinimum; // this is the default
  public truncateHeading = true;
  public resizableParts = true;
  public containerMaxWidth!: number | null;
  public orientation: SplitOrientation = 'horizontal';
  public expanded = false;
  public rowData!: LogViewResult;
  public columnDecriptionMap!: Map<string, HistLogColumnDescription> | null;
  public searchCriteriaSelectable: Criterion[] = [];
  public isToShowWarningMessage = false;
  public viewSize? = 0;
  public warning = '';
  public warningMsg = '';
  public warningMsg1 = '';
  public warningMsg2 = '';
  public searchLabel = '';
  public items = '';
  public noMatch = '';
  public errorFetchingLVD = '';
  public currentNodeInternalName = '';
  public appliedFilterCriteria: SearchCriteria = {
    criteria: [],
    value: ''
  };
  public selectedCriteriaOptions: SearchCriteria = {
    criteria: [],
    value: ''
  };
  public selectionEventDetail: SelectionDetail = {
    internalName: '',
    ruleName: ''
  };
  public split = false;
  public mobileView = false;
  public userLocalizationCulture = '';
  public userLang = '';
  public noDataDetailPane = false;
  public controlsChangedToSmallDevice = false;
  public fromSystemRightPannel = false;
  public snapInObjectId = '';
  // Activity Icons
  public actionResultBadges: ILogViewerObj = {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    0: 'bg-danger', 1: 'bg-success', 2: 'bg-warning', 3: 'bg-danger', 4: 'bg-info',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    5: 'bg-danger', 6: 'bg-danger', 7: 'bg-danger', 8: 'bg-default'
  };

  public filterActions?: MenuItem[] = [];
  public columnsActions?: MenuItem[] = [];
  public snapShotId = '';
  public scrollSubject = new Subject<boolean>();
  public settings!: MasterDetailContainerSettings;
  public masterContainerWidth = 32;
  private firstLoad = true;
  private subActivityEnumValues!: Subscription | null;
  private readonly activityOriginalEnumValues!: ActivityOriginalEnumValues;
  // --------------------------------------------- log view members ------------------------------------------------

  // Store service to persist e.g. scroll bar position
  private snapinTitle!: string | undefined;
  // Used to format real values
  private readonly subscriptions: Subscription[] = [];
  private subLogEnumValues!: Subscription | null;
  private scrollSubjectSubscriptions!: Subscription;
  private readonly translateService: TranslateService;
  private filtersLoaded: Map<string, any> = new Map<string, any>();
  private activityEnums: Map<string, ActivityOriginalEnumValues> = new Map<string, ActivityOriginalEnumValues>();
  // private sourceInformationLabel = '';
  // -------------------------------------------------- c'tor -------------------------------------------------------

  constructor(
    private readonly mobileNavigationService: MobileNavigationService,
    private readonly messageBroker: IHfwMessage,
    private readonly settingsService: SettingsServiceBase,
    activatedRoute: ActivatedRoute,
    private readonly appContextService: AppContextService,
    eventCommonService: EventsCommonServiceBase,
    private readonly traceService: TraceService,
    private readonly logViewerService: LogViewerServiceBase,
    private readonly resizeObserverService: ResizeObserverService,
    private readonly historyLogService: HistoryLogService,
    private readonly systemBrowserService: SystemBrowserServiceBase,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly toastNotificationService: SiToastNotificationService
  ) {
    this.activityOriginalEnumValues = { enum: [], tag: [] };
    this.translateService = eventCommonService.commonTranslateService;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (!!this.objectDesignationRightPane && !!this.objectLocationRightPane && !this.fromSnapin) {
      this.detailsActive = false;
    }

    if (changes?.logViewerChangeDetect?.currentValue || changes?.logViewerChangeDetect?.currentValue === 0) {
      if (this.logViewertable) {
        this.logViewertable.tableChangeDetect = changes?.logViewerChangeDetect?.currentValue;
      }
    }
  }

  // --------------------------------------------------- ngOnInit() -------------------------------------------------

  public ngOnInit(): void {
    if (this.fromSnapin) {
      this.getTranslations();
      // Initialize translation service and user localization culture
      /*  this.subscriptions.push(this.appContextService.userCulture.subscribe((userCulture: string) => {
        if (userCulture != null) {
          this.translateService.use(userCulture).subscribe((_req: any) => {
            this.traceService.info(TraceModules.logViewer, `Use  user culture: ${userCulture}`);
            this.getTranslations();
            this.logViewerDetails?.processData();
          },
          (err: any) => {
            this.subscriptions.push(this.appContextService.defaultCulture.subscribe((defaultCulture: string) => {
              if (defaultCulture != null) {
                this.translateService.setDefaultLang(defaultCulture);
              } else {
                this.traceService.warn('No default Culture for appContextService');
                this.translateService.setDefaultLang(this.translateService.getBrowserLang()!);
              }
              this.getTranslations();
              this.logViewerDetails?.processData();
            }));
          });
        } else {
          this.traceService.warn(TraceModules.logViewer, 'No user Culture for appContextService');
        }
      })); */

      this.initUserLocalizationCulture();
      // Init message broker service
      this.subscriptions.push(this.messageBroker.getMessage(this.fullId).subscribe(
        (message: GmsMessageData) => {
          // If user selects "Navigate to Log Viewer" option in History section from right Panel,
          // message will recieve custom data.
          // Custom data have details of selected object/node.
          const sourceLocationLabel = this.translateService.instant('FILTER-COLUMNS.SOURCE-LOCATION');
          if (message.customData) {
            this.fromSystemRightPannel = true;
            // Commenting label and name for Source information.
            const criteria = {
              // label: this.sourceInformationLabel,
              // name: 'Description'
              label: sourceLocationLabel,
              name: SourceNames.SourceLocation,
              options: [],
              value: message.customData[0]?.value || '' // Handle potential undefined or null values
            };

            const messageBody = {
              selectedCriteriaOptions: {
                criteria: [criteria],
                value: ''
              },
              appliedFilterCriteria: {
                criteria: [criteria],
                value: ''
              }
            };

            message.customData = undefined;
            // A location filter (source information) of the object/node from
            // where the navigation took place
            // will be automatically applied (in the filtered search)
            // And the filtered results will be displayed.
            this.storageService.setState(this.fullId, messageBody);
            this.retainLogViewerState();
          }
          if (!!message) {
            this.process(message);
          }
        },
        error => {
          this.traceService.error(TraceModules.logViewer, error);
        })
      );
      // Get history event and activity logs
      this.traceService.debug(TraceModules.logViewer, `ngOnInit() end`);
      this.rowDetailsPane.nativeElement.addEventListener('scroll', this.onScroll.bind(this), true);
      this.scrollSubjectSubscriptions = this.scrollSubject.pipe(debounceTime(100)).subscribe(data => {
        this.saveScrollPosition(data);
      });
      this.userLang = this.translateService.getBrowserLang()!;
    }
  }

  public ngAfterContentChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  // --------------------------------------------ngAfterViewInit()-------------------------------------------------------
  public ngAfterViewInit(): void {
    this.subscribeContainerWidthChanges();
    this.retainLogViewerState();
  }

  // -------------------------------------------- ngOnDestroy() -----------------------------------------------------

  public ngOnDestroy(): void {
    this.traceService.debug(TraceModules.logViewer, `ngOnDestroy() called`);

    // Unsubscribe i18n text subscriptions
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription?.unsubscribe();
    });
    if (this.fromSnapin) {
      this.scrollSubjectSubscriptions?.unsubscribe();
      this.saveCurrentState();
      this.rowDetailsPane.nativeElement.removeEventListener('scroll', this.onScroll, true);
    }
  }

  public saveCurrentState(): void {
    // Persist scroll offset Y
    let storageData: any = this.storageService.getState(this.fullId);
    if (!storageData) {
      storageData = this.logViewertable?.logViewerRetainState || {};
    }
    // storageData.scrollOffsetY = this.logViewertable.table.element.getElementsByTagName('datatable-body')[0].scrollTop || 0;
    this.historyLogService.logViewDatahideShowVeryDetailPane.subscribe(isCollapse => {
      storageData.hideShowVeryDetailPane = isCollapse;
    });
    const scrollTop = this.rowDetailsPane.nativeElement?.scrollTop;
    const scrollLeft = this.rowDetailsPane.nativeElement?.scrollLeft;
    if (scrollTop > 0 && scrollLeft > 0) {
      storageData.detailPaneScrollPosition = scrollTop;
      storageData.detailPaneScrollLeft = scrollLeft;
    }
    this.storageService.setState(this.fullId, storageData);
  }

  public setfilterData(criteria: Criterion[]): void {
    this.searchCriteriaSelectable = criteria;
    const retainedData = this.storageService?.getState(this.fullId);
    if (this.managedTypeName === 'LogViewDefinition' && this.logViewertable && this.objectId && !retainedData) {
      // Log view deifintion is loading for the first time
      this.logViewertable.firstLVDLoad = true;
      this.logViewertable.lvdActivityType.set('Activity', '');
      this.logViewertable.lvdActivityType.set('ActivityGroup', '');
      this.applyLogViewDefinitionFilter();
    }
  }

  public showHideWarningMessageHandler(warningMessageContent: WarningMessageContent): void {
    this.isToShowWarningMessage = warningMessageContent.isToShowWarningMessage;
    this.viewSize = warningMessageContent.viewSize;
    // adding this async as we reading localization texts for warning message is async.
    setTimeout(() => {
      this.translateService.get('Log_Viewer.LOG-VIEWER-WARNING-MSG.DETAILED-MSG', { viewSize: this.viewSize }).subscribe((res: string) => {
        this.warningMsg = res;
      });
    });
  }

  // this is called from simpl control when user clicks on apply filter button
  public onSearchAppliedFilterChanged(appliedFilterCriteria: SearchCriteria): void {
    this.appliedFilterCriteria = appliedFilterCriteria;
    this.logViewertable?.onSearchAppliedFilterChanged(appliedFilterCriteria, this.activityEnums);
  }

  // this function is called when user checks/unchecks options from filter
  public onSearchFilterChange(selectedCriteriaOptions: SearchCriteria): void {
    this.selectedCriteriaOptions = selectedCriteriaOptions;
    if (this.activityEnums?.size === 0 && this.logViewertable?.activityEnums) {
      this.activityEnums = this.logViewertable?.activityEnums;
    }
    this.logViewertable?.onSearchFilterChange(selectedCriteriaOptions);
  }
  // this function gets the log view definition filters from the backend
  public applyLogViewDefinitionFilter(): void {
    this.logViewerService.getLogViewDefinition(this.systemId, this.objectId.split(':')[1]).subscribe({
      next: response => {
        if (!Object.prototype.hasOwnProperty.call(response, 'ErrorInfo')) {
          const logViewDefinitionData = response.LogViewDefinationInfo;
          // this search object is passed to the SiFilter search
          const searchObject = {
            "criteria": this.mapFilterList(logViewDefinitionData.ConditionFilter, logViewDefinitionData.TimeRangeFilter),
            "value": ""
          };
          if (searchObject.criteria?.length > 0) {
            this.onSearchFilterChange(searchObject);
            this.onSearchAppliedFilterChanged(searchObject);
          }
        } else {
          this.traceService.error(TraceModules.logViewer, response[0].ErrorInfo);
          this.toastNotificationService.queueToastNotification('danger', 'Error', response[0].ErrorInfo);
        }
      },
      error: err => {
        this.traceService.error(TraceModules.logViewer, err);
        this.toastNotificationService.queueToastNotification('danger', 'Error', this.errorFetchingLVD);
      }
    });
  }
  // this function maps the filter object from the backend to what SiFilter search expects
  public mapFilterList(conditionFilters: Filter[], timeRangeFilters: TimeRangeFilter): SearchFilterData[] {
    const newFilterList: SearchFilterData[] = [];
    const activityLabel = this.searchCriteriaSelectable.find(filterObj => filterObj.name === 'Activity').label;
    const activityGroupLabel = this.searchCriteriaSelectable.find(filterObj => filterObj.name === 'ActivityGroup').label;
    const sourceLocDesgnNames = Object.values(SourceNames) as string[];
    // This section will add condition filters to the new filter list.
    if (Array.isArray(conditionFilters) && conditionFilters?.length > 0) {
      conditionFilters.forEach(filterItem => {
        // Ignore filter if it's value is null
        const filterValue = Array.isArray(filterItem.Value) ? filterItem.Value[0] : filterItem.Value;
        if (filterValue === null) {
          return;
        }
        const filterDetails = this.searchCriteriaSelectable.find(filterObj => filterObj.name === filterItem.Name);
        // On the flex we only support = operator for all filter's other than Date/Time. In Date/Time we also support >= & <=.
        if (filterDetails && filterItem.Operator === "=") {
          newFilterList.push({ name: filterDetails.name, 
            label: filterDetails.label,
            // If it's a Source Location/Designation filter, take 0th Value else take the whole.
            value: sourceLocDesgnNames.includes(filterDetails.name) ? filterItem.Value[0] : filterItem.Value 
          });
          // In standard client we don't have Activity. So we need to handle it differntly here. Activity is combination of Action & Event State
          // Check if filter is Action/AlertState and add as Activity if true.
        } else if (filterItem.Name === 'Action' || filterItem.Name === 'AlertState') {
          newFilterList.push({ name: 'Activity', label: activityLabel, value: filterItem.Value });
          this.logViewertable.lvdActivityType.set('Activity', filterItem.Name);
          // In standard client we don't have ActivityGroup. So we need to handle it differntly. ActivityGroup is combination of Record & Log Type.
          // Check if filter is Record/Log Type and add as ActivityGroup if true.
        } else if (filterItem.Name === 'RecordType' || filterItem.Name === 'LogType') {
          const filterValues = filterItem.Name === 'RecordType' ? filterItem.Value : filterItem.Value?.map(val => `${val} Activity`);
          newFilterList.push({ name: 'ActivityGroup', label: activityGroupLabel, value: filterValues });
          this.logViewertable.lvdActivityType.set('ActivityGroup', filterItem.Name);
        }
      });
    }
    const addTimeFilter = (operator, value, dateValue, datepickerConfig = {}): void => {
      newFilterList.push({
        operator,
        name: 'Time',
        label: this.searchCriteriaSelectable.find(filterObj => filterObj.name === 'Time').label,
        value,
        dateValue,
        datepickerConfig
      });
    };
    // This section will add time range filters to the new filter list.
    // Only Absolute, Exact and Relative type of time range filters are supported in flex. Undefined and Null are not supported.
    switch (timeRangeFilters.TimeRangeSelectionType) {
      case TimeRangeSelectionEnum.Absolute:
        // In case of Absolute we will add two  differnt Date/Time filter in flex.
        addTimeFilter("≥", timeRangeFilters.Absolute.From, timeRangeFilters.Absolute.From);
        addTimeFilter("≤", timeRangeFilters.Absolute.To, timeRangeFilters.Absolute.To);
        break;
      case TimeRangeSelectionEnum.Exact:
        // In case of Exact a single Date/Time filter with =, >= , <= operaters will be applied.
        const dateTimeAllowedOperators = ["=", ">=", "<="];
        if (dateTimeAllowedOperators.includes(timeRangeFilters.Absolute.Operator)) {
          const operator = timeRangeFilters.Absolute.Operator;
          const mappedOperator = operator === "=" ? operator : (operator === ">=" ? "≥" : "≤");
          const value = timeRangeFilters.Absolute.From.split('T')[0];
          addTimeFilter(mappedOperator, value, timeRangeFilters.Absolute.From, { disabledTime: true });
        }
        break;
      case TimeRangeSelectionEnum.Relative:
        // In case of Relative we will add two differnt Date/Time filter with relative start and end date.
        const now = new Date();
        const offsetUnit = timeRangeFilters.Relative.Unit;
        const startUnitOffset = timeRangeFilters.Relative.Current ? (1 - offsetUnit) : -offsetUnit;
        const endUnitOffset = timeRangeFilters.Relative.Current ? 1 : 0;
        const option = timeRangeFilters.Relative.Option;
        const startDate = this.findAdjustedDate(now, startUnitOffset, option).toISOString();
        const endDate = this.findAdjustedDate(now, endUnitOffset, option).toISOString();
        addTimeFilter("≥", startDate, startDate);
        addTimeFilter("≤", endDate, endDate);
      default:
    }
    return newFilterList;
  }
  // This function calculates the relative date
  public findAdjustedDate(now: Date, offset: number, option: number): Date {
    let adjustedDate: Date;
    switch (option) {
      case RelativeTimeUnitEnum.Minutes:
        adjustedDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes(), 0);
        adjustedDate.setMinutes(now.getMinutes() + offset);
        break;
      case RelativeTimeUnitEnum.Hours:
        adjustedDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), 0, 0);
        adjustedDate.setHours(now.getHours() + offset);
        break;
      case RelativeTimeUnitEnum.Days:
        adjustedDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0);
        adjustedDate.setDate(now.getDate() + offset);
        break;
      case RelativeTimeUnitEnum.Weeks:
        const refDayOfWeek = now.getDay();
        const firstDayOfWeek = 0; // Assuming Sunday as the first day of the week
        adjustedDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0);
        if (refDayOfWeek >= firstDayOfWeek) {
          adjustedDate.setDate(now.getDate() - (refDayOfWeek - firstDayOfWeek) + (7 * offset));
        } else {
          adjustedDate.setDate(now.getDate() - 7 + (firstDayOfWeek - refDayOfWeek) + (7 * offset));
        }
        break;
      case RelativeTimeUnitEnum.Months:
        adjustedDate = new Date(now.getFullYear(), now.getMonth(), 1, 0, 0, 0);
        adjustedDate.setMonth(now.getMonth() + offset);
        break;
      case RelativeTimeUnitEnum.Years:
        adjustedDate = new Date(now.getFullYear(), 0, 1, 0, 0, 0);
        adjustedDate.setFullYear(now.getFullYear() + offset);
      default:
    }
    return adjustedDate;
  }

  // ------------------------------------------- process request ------------------------------------------------------
  public process(message: GmsMessageData): void {
    this.snapInObjectId = message?.data[0]?.ObjectId;
    // In case of invalid message condition , just return
    if (!message?.data?.length) {
      return;
    }
    const browserObject: BrowserObject = message?.data[0];
    if (this.systemId && this.systemId !== browserObject.SystemId) {
      this.logViewertable.prevSystemId = this.systemId;
    } else {
      const prevSystemId = this.logViewertable && (this.logViewertable.prevSystemId = null);
    }
    this.systemId = browserObject.SystemId;
    // Log Viewer: Flex Client loses connection after applying filter
    // on every time log viewer node selection from system browser, we should load enumerations for filtering
    // as enumeration values can be different for different systems in distributed environmenet
    this.filtersLoaded = new Map<string, any>();

    if (!!this.logViewertable && !!this.logViewerDetails) {
      if (!this.fromSystemRightPannel) {
        this.logViewertable.nodeReselection = true;
        this.siFilteredSearchComponent.deleteAllCriteria(new MouseEvent('click'));
      } else {
        this.logViewertable.nodeReselection = false;
        this.fromSystemRightPannel = false;
      }
      this.historyLogService.logViewRowDetails.next(null);
      this.logViewertable?.resetTable();
      this.logViewerDetails?.resetData();
    }
  }

  /**
   * This method is used to track the scroll position of detail pane
   */
  public onScroll(event: Event): void {
    this.scrollSubject.next(true);
  }

  public saveScrollPosition(event: boolean): void {
    let storageData: any = this.storageService.getState(this.fullId);
    if (!storageData) {
      storageData = this.logViewertable?.logViewerRetainState || {};
    }
    storageData.detailPaneScrollPosition = this.rowDetailsPane.nativeElement?.scrollTop;
    storageData.detailPaneScrollLeft = this.rowDetailsPane.nativeElement?.scrollLeft;
    this.logViewertable.logViewerRetainState.detailPaneScrollPosition = storageData.detailPaneScrollPosition;
    this.logViewertable.logViewerRetainState.detailPaneScrollLeft = storageData.detailPaneScrollLeft;
    this.storageService.setState(this.fullId, storageData);
  }
  /**
   * This method is used to retain scroll bar position when secondary pane is closed
   */
  public retainScrollBarsInSecondaryInstanceClosed(): void {
    const retainedData = this.storageService.getState(this.fullId);
    if (!!retainedData) {
      // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
      this.setScrollPositionForDetailPane(this.logViewertable?.logViewerRetainState?.detailPaneScrollPosition || 0);
      // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
      this.setScrollPositionLeftForDetailPane(this.logViewertable?.logViewerRetainState?.detailPaneScrollLeft || 0);
    }
  }
  /**
   * This method is used to inject the retained applied filter to the child component
   * logviewertable filterCriteria value
   */
  public retainLogViewerState(): void {
    const retainedData = this.storageService?.getState(this.fullId);
    if (!!retainedData) {
      if (retainedData?.selectedCriteriaOptions) {
        this.selectedCriteriaOptions = retainedData?.selectedCriteriaOptions;
        this.logViewerTableElement.selectedCriteriaOptions = this.selectedCriteriaOptions;
      }
      if (this.logViewertable) {
        this.logViewertable.logViewerRetainState = {};
        this.logViewertable.logViewerRetainState = retainedData;
      }
      this.snapShotId = retainedData?.snapShotId;
      this.historyLogService.logViewDatahideShowVeryDetailPane.next(retainedData?.hideShowVeryDetailPane);
      this.historyLogService.detailPaneIsLoaded.subscribe(isDetailPaneLoaded => {
        if (isDetailPaneLoaded) {
          // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
          this.setScrollPositionForDetailPane(this.logViewertable?.logViewerRetainState?.detailPaneScrollPosition || 0);
          // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
          this.setScrollPositionLeftForDetailPane(this.logViewertable?.logViewerRetainState?.detailPaneScrollLeft || 0);
        }
      });
    }
  }
  /**
   * This method is used to set the scroll bar position Y for retained state of detail pane
   *  The scroll bar position Y = 0 means top position.
   */
  public setScrollPositionForDetailPane(scrollOffsetY: number): void {
    if (scrollOffsetY > 0) {
      setTimeout(() => {
        this.rowDetailsPane.nativeElement.scrollTop = scrollOffsetY;
        if (this.snapShotId !== this.logViewertable.logViewerRetainState?.snapShotId) {
          this.logViewertable.logViewerRetainState.detailPaneScrollPosition = 0;
        }
      });
    }
  }

  public setScrollPositionLeftForDetailPane(scrollOffsetY: number): void {
    if (scrollOffsetY > 0) {
      setTimeout(() => {
        this.rowDetailsPane.nativeElement.scrollLeft = scrollOffsetY;
        if (this.snapShotId !== this.logViewertable.logViewerRetainState?.snapShotId) {
          this.logViewertable.logViewerRetainState.detailPaneScrollLeft = 0;
        }
      });
    }
  }
  /**
   * This method is used to set the scroll bar position Y. The scroll bar position Y = 0 means top position.
   */
  public setScrollBarPositionY(scrollOffsetY: number): void {

    // Schedule scroll position to be restored after attach and just prior to view rendering
    if (scrollOffsetY > 0) {
      animationFrameScheduler.schedule(() => {
        // this.logViewertable.table.element.getElementsByTagName('datatable-body')[0].scrollTop = scrollOffsetY;
      });
    }
  }

  /**
   * This method is used to provide the enum values for a particular category name (i.e. column name). The filtered
   * search control bar loads them lazy from the CC backend, then when needed to be shown to the user.
   */
  public lazyValueProvider = (categoryName: string, typed: string): Observable<string[] | null> => {
    if (this.logViewertable.firstLVDLoad) { // If LVD is loading for the first time, don't fetch enum values.
      return;
    }
    const subject = new Subject<string[] | null>();
    let filteredValues: string[] | null;
    if (!this.subLogEnumValues) {
      // Log Viewer: Flex Client loses connection after applying filter
      // if user is filtering, we dont need to load enumerations everytime.
      // We are keeping them in map and if it is already loaded then dont load same enumeration again, just reuse it
      if (!this.filtersLoaded.has(categoryName)) {
        this.subLogEnumValues = this.logViewerService.getHistoryLogEnumValues(this.systemId, HistoryLogKind.ActivityFeed, categoryName).subscribe(
          data => {
            this.getActivityTypes(categoryName);
            // Insert enum values in search criterion
            const enumValues = data?.EnumValues;
            const enumVals = Object.assign([], data?.EnumValues ?? []);
            if (categoryName.includes('Activity')) {
              this.activityOriginalEnumValues.enum = Object.assign([], data?.EnumValues ?? []);
              this.activityOriginalEnumValues.tag = [];
            }
            filteredValues = enumValues ? enumValues : null as string[] | null;
            filteredValues?.sort();
            this.filtersLoaded.set(categoryName, enumVals);
            subject.next(filteredValues);
            this.subLogEnumValues!.unsubscribe();
            this.subLogEnumValues = null;
          },
          error => {
            this.traceService.error(TraceModules.logViewer, `lazyValueProvider() returned Error = ${JSON.stringify(error)}}`);
          }
        );
      } else {
        // Log Viewer: Flex Client loses connection after applying filter
        // if user is filtering, we dont need to load enumerations everytime.
        // We are keeping them in map and if it is already loaded then dont load same enumeration again, just reuse it
        const arrayList = Object.assign([], this.filtersLoaded.get(categoryName));
        if (categoryName.includes('Activity')) {
          this.activityOriginalEnumValues.enum = Object.assign([], arrayList);
          const categoryNameOnly = categoryName === 'Activity' ? 'ActivityTagOnlyForFlex' : 'ActivityGroupTagOnlyForFlex';
          if (this.filtersLoaded.has(categoryNameOnly)) {
            const arrayListOnly = this.filtersLoaded.get(categoryNameOnly);
            this.activityOriginalEnumValues.tag = Object.assign([], arrayListOnly ?? []);
          }
          if (!this.activityEnums?.has(categoryName)) {
            this.activityEnums?.set(categoryName, Object.assign([], this.activityOriginalEnumValues));
          }
        }
        // adding setTimeout as subject.next(arryList); requires async call and hence adding this setTimeout
        setTimeout(() => {
          arrayList?.sort();
          subject.next(arrayList);
        }, 100);
      }
    }
    return subject.asObservable();
  };

  public getActivityTypes(categoryName: string): void {
    // this needs to be tested for different different languages
    if (!this.subActivityEnumValues && (categoryName === 'Activity' || categoryName === 'ActivityGroup')) {
      const categoryNameOnly = categoryName === 'Activity' ? 'ActivityTagOnlyForFlex' : 'ActivityGroupTagOnlyForFlex';
      this.subActivityEnumValues = this.logViewerService.getHistoryLogEnumValues(this.systemId, HistoryLogKind.ActivityFeed, categoryNameOnly).subscribe(
        data => {
          // Insert enum values in search criterion
          this.activityOriginalEnumValues.tag = Object.assign([], data?.EnumValues ?? []);
          this.filtersLoaded.set(categoryNameOnly, this.activityOriginalEnumValues.tag);
          this.activityEnums.set(categoryName, Object.assign([], this.activityOriginalEnumValues));
          this.subActivityEnumValues!.unsubscribe();
          this.subActivityEnumValues = null;
        },
        error => {
          this.traceService.error(TraceModules.logViewer, `lazyValueProvider() Activity or ActivityGroup returned Error = ${JSON.stringify(error)}}`);
        }
      );
    }
  }

  public onCustomDialogue(custmDialg: CustomDialog): void {
    this.filterActions = custmDialg?.primaryActions;
    this.columnsActions = custmDialg?.secondaryActions;
    if (this.filterActions.length === 0) {
      this.mobileView = true;
    } else {
      this.mobileView = false
    }
  }

  // Will pass the snapIn Objectid to show the log viewer details in the right panel
  public showLogViewerDetails(showDetails: string): void {
    if (showDetails === 'LogViewerDetails') {
      this.secondaryRowSelection.next(this.snapInObjectId);
    } else {
      this.secondaryRowSelection.next(showDetails);
    }
  }

  public sendSelectionDetails(ruleName: string): void {
    this.selectionEventDetail.internalName = this.currentNodeInternalName;
    this.selectionEventDetail.ruleName = ruleName;
    this.sendSelectionEvent.next(this.selectionEventDetail);
  }

  public onResize(settings: MasterDetailContainerSettings): void {
    if (settings) {
      const str = JSON.stringify(settings);
      this.logViewerService.putSettings(
        'LogViewerSettings',
        `'${str}'`).subscribe();
      this.retainScrollBarsInSecondaryInstanceClosed();
    }
  }

  public onSplitterPositionChange(masterContainerWidthChange: number): void {
    if (!this.firstLoad) {
      this.settings.masterDataContinerSize = masterContainerWidthChange;
      const str = JSON.stringify(this.settings);
      this.logViewerService.putSettings(
        'LogViewerSettings',
        `'${str}'`).subscribe();
    }
  }

  public setSplitterPosition(settings: MasterDetailContainerSettings): void {
    if (settings) {
      this.firstLoad = false;
      this.settings = settings;
      this.masterContainerWidth = typeof (settings.masterDataContinerSize!) === 'string' ? 50 : (settings.masterDataContinerSize ?? 50);
    }
  }

  public criteriaLoc(criteria: Criterion[]): void {
    this.criteriaLocLogViewer.emit(criteria);
  }

  public logTableDataLength(length: number): void {
    this.dataLength.next(length);
  }

  public historyDataFetched(flag: boolean): void {
    this.isLoadingDataEvent.next(flag);
  }

  public detailsActiveChange(event): void {
    if (!this.fromSnapin) {
      this.isDetailActive.next(event);
    }
  }

  public paneControls(event: PaneControls): void {
    this.paneControlsOp.next(event);
  }
  public userLocale(event): void {
    this.userLocalizationCulture = event;
  }

  public noData(value: boolean): void {
    this.noDataDetailPane = value;
    this.changeDetectorRef.detectChanges();
  }
  private subscribeContainerWidthChanges(): void {
    if (!(this.siMasterDetailContainer?.nativeElement)) {
      this.traceService.warn('Unable to locate si-tree-view element in DOM for width monitoring');
      return;
    }

    // Subscribe for size changes on this host element
    this.subscriptions.push(this.resizeObserverService.observe(this.siMasterDetailContainer.nativeElement, 100, true, true)
      .subscribe(dim => { this.containerMaxWidth = (dim?.width) ? dim?.width : null; })
    );

    // Subscribe for size changes on this host element
    this.subscriptions.push(this.resizeObserverService.observe(this.rowDetailsPane.nativeElement, 100, true, true)
      .subscribe(dim => {
        if (dim?.width < 400) {
          this.split = true;
          this.historyLogService.splitDetailControls.next(true);
        } else {
          this.split = false;
          this.historyLogService.splitDetailControls.next(false);

        }
      })
    );
    // Do check if any row is selected
    this.subscriptions.push(this.historyLogService.logViewRowDetails.subscribe((rowData: RowDetailsDescription | null) => {
      this.detailsActive = rowData ? true : false;
      if (rowData && this.containerMaxWidth != null) {
        this.currentNodeInternalName = rowData?.logViewResult?.HiddenInternalName;
        this.showLogViewerDetails(rowData?.logViewResult?.HiddenInternalName);
      }
    }));
  }

  // ---------------------------------------------------------------------------------------------------------------

  // ------------------------------------------ Private Methods -----------------------------------------------------

  /**
   * Init user localization culture of logged in user (i.e. corresponds to the browser culture).
   * Use this culture to format values like real values.
   */
  private initUserLocalizationCulture(): void {
    this.subscriptions.push(this.appContextService.userLocalizationCulture.subscribe((userLocCulture: string) => {
      if ((userLocCulture !== null) && (userLocCulture.length > 0)) {
        this.userLocalizationCulture = userLocCulture;
      } else {
        this.traceService.warn(TraceModules.logViewer,
          `No user localization culture set on appContextService! Use the culture set by the browser: ${this.translateService.getBrowserLang()}`);
        this.userLocalizationCulture = this.translateService.getBrowserLang()!;
      }
    }));
  }

  private getTranslations(): void {
    this.subscriptions.push(this.translateService.get([
      'Log_Viewer.SNAPIN-TITLE',
      'Log_Viewer.WARNING',
      'Log_Viewer.FILTER-PLACEHOLDER',
      'Log_Viewer.SEARCH_LABEL',
      // 'FILTER-COLUMNS.SOURCE-INFORMATION',
      'Log_Viewer.FILTERED_ITEMS',
      'Log_Viewer.NO_MATCHING_CRITERIA',
      'Log_Viewer.LVD_ERROR_MESSAGES.UNABLE_FETCH_LVD'
    ]).subscribe(values => {
      this.snapinTitle = values['Log_Viewer.SNAPIN-TITLE'];
      // eslint-disable-next-line @typescript-eslint/dot-notation
      this.searchPlaceHolder = values['Log_Viewer.FILTER-PLACEHOLDER'];
      this.searchLabel = values['Log_Viewer.SEARCH_LABEL'];
      // this.sourceInformationLabel = values['FILTER-COLUMNS.SOURCE-INFORMATION'];
      this.items = values['Log_Viewer.FILTERED_ITEMS'];
      this.noMatch = values['Log_Viewer.NO_MATCHING_CRITERIA'];
      this.errorFetchingLVD = values['Log_Viewer.LVD_ERROR_MESSAGES.UNABLE_FETCH_LVD']
    })
    );
  }
}
