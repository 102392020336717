import { getLocaleFirstDayOfWeek, WeekDay } from '@angular/common';
import {
  AfterViewInit,
  booleanAttribute,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  LOCALE_ID,
  OnChanges,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SiTranslateModule } from '@simpl/element-translate-ng/translate';

import { Cell } from './components/si-calendar-body.component';
import { SiDaySelectionComponent } from './components/si-day-selection.component';
import { SiMonthSelectionComponent } from './components/si-month-selection.component';
import { SiYearSelectionComponent } from './components/si-year-selection.component';
import * as dt from './date-time-helper';
import { getLocaleMonthNames, isValid } from './date-time-helper';
import { DatepickerConfig, DateRange, WeekStart } from './si-datepicker.model';
import { SiTimepickerComponent } from './si-timepicker.component';

let idCounter = 1;

/**
 * Choose which view shall be shown.
 * @internal
 */
type ViewType = 'week' | 'month' | 'year' | undefined;

export type RangeType = 'START' | 'END' | undefined;

@Component({
  selector: 'si-datepicker',
  templateUrl: './si-datepicker.component.html',
  styleUrl: './si-datepicker.component.scss',
  standalone: true,
  imports: [
    SiYearSelectionComponent,
    SiMonthSelectionComponent,
    SiDaySelectionComponent,
    SiTimepickerComponent,
    FormsModule,
    SiTranslateModule
  ]
})
export class SiDatepickerComponent implements AfterViewInit, OnInit, OnChanges {
  /**
   * The date which is currently focused
   * Compare to the selected date or range the calendar requires to have one element to focus.
   */
  @Input() focusedDate?: Date;
  /** Emits when the active focused date changed, typically during keyboard navigation */
  @Output() readonly focusedDateChange = new EventEmitter<Date>();
  /**
   * The selected date of the datepicker. Use for
   * initialization and for bidirectional binding.
   */
  @Input() date?: Date;
  /**
   * The selected date range of the datepicker. Use for
   * initialization and for bidirectional binding.
   */
  @Input() dateRange?: DateRange;
  /** @internal */
  @Input() dateRangeRole: RangeType;
  /**
   * Set initial focus to calendar body.
   *
   * @defaultValue false
   */
  @Input({ transform: booleanAttribute }) initialFocus = false;
  /**
   * Emits the new value of `date` whenever its value changes.
   */
  @Output() readonly dateChange = new EventEmitter<Date>();
  /**
   * Emits the new value of `dateRange` whenever its value changes.
   */
  @Output() readonly dateRangeChange = new EventEmitter<DateRange>();
  /**
   * Disabled the optional visible time picker.
   *
   * @defaultValue false
   */
  @Input() disabledTime = false;
  /**
   * Emits an event to notify about disabling the time from the datepicker.
   * When time is disable, we construct a pure date object in UTC 00:00:00 time.
   */
  @Output() readonly disabledTimeChange = new EventEmitter<boolean>();
  /**
   * Object to configure the datepicker.
   *
   * @defaultValue
   * ```
   * {}
   * ```
   */
  @Input() config: DatepickerConfig = {};
  /**
   * Aria label for the previous button. Needed for a11y.
   *
   * @defaultValue
   * ```
   * $localize`:@@SI_DATEPICKER.PREVIOUS:Previous`
   * ```
   */
  @Input() previousLabel = $localize`:@@SI_DATEPICKER.PREVIOUS:Previous`;
  /**
   * Aria label for the next button. Needed for a11y.
   *
   * @defaultValue
   * ```
   * $localize`:@@SI_DATEPICKER.NEXT:Next`
   * ```
   */
  @Input() nextLabel = $localize`:@@SI_DATEPICKER.NEXT:Next`;
  /**
   * Aria label for week number column
   *
   * @defaultValue
   * ```
   * $localize`:@@SI_DATEPICKER.CALENDAR_WEEK_LABEL:Calendar week`
   * ```
   */
  @Input() calenderWeekLabel = $localize`:@@SI_DATEPICKER.CALENDAR_WEEK_LABEL:Calendar week`;
  /**
   * Enable/disable 12H mode in timepicker. Defaults to locale
   */
  @Input({ transform: booleanAttribute }) time12h?: boolean;
  /**
   * Use this to force date range operation to select either start date or end date.
   *
   * @defaultValue 'START'
   */
  @Input() rangeType: RangeType = 'START';
  @Output() readonly rangeTypeChange = new EventEmitter<RangeType>();

  /**
   * Optional input to control the minimum month the datepicker can show and the user can navigate.
   * The `minMonth` can be larger than the `minDate` This option enables the usage of multiple
   * datepickers next to each other while the more left calendar always
   * shows a earlier month the the more right one.
   * @internal
   */
  @Input() minMonth?: Date;
  /**
   * Optional input to control the maximum month the datepicker can show and the user can navigate.
   * The `maxMonth` can be smaller than the `maxDate` This option enables the usage of multiple
   * datepickers next to each other while the more left calendar always
   * shows a earlier month the the more right one.
   * @internal
   */
  @Input() maxMonth?: Date;
  /**
   * Option to hide the time switch.
   *
   * @defaultValue false
   */
  @Input() hideTimeToggle = false;
  /** @internal */
  @Input() hideCalendar = false;
  /**
   * Optional timepicker label.
   */
  @Input() timepickerLabel?: string;

  protected get startDate(): Date | undefined {
    return this.config.enableDateRange ? this.dateRange?.start : this.date;
  }

  protected get endDate(): Date | undefined {
    return this.config.enableDateRange ? this.dateRange?.end : undefined;
  }
  /**
   * Returns the date object if not range selection is enabled. Otherwise, if
   * the date range role is 'END', the date range end date is returned. If
   * date range role is not 'END', the date range start date is returned.
   */
  private getRelevantDate(): Date | undefined {
    return !this.config.enableDateRange
      ? this.date
      : this.dateRangeRole === 'END'
        ? this.dateRange?.end
        : this.dateRange?.start;
  }

  private readonly defaultDisabledTimeText = $localize`:@@SI_DATEPICKER.DISABLED_TIME_TEXT:Ignore time`;
  private readonly defaultEnableTimeText = $localize`:@@SI_DATEPICKER.ENABLED_TIME_TEXT:Consider time`;

  protected get includeTimeLabel(): string {
    return this.disabledTime
      ? (this.config.disabledTimeText ?? this.defaultDisabledTimeText)
      : (this.config.enabledTimeText ?? this.defaultEnableTimeText);
  }

  protected get weekStartDay(): WeekStart {
    return this.config.weekStartDay ?? this.localeWeekStart;
  }

  protected get hideWeekNumbers(): boolean {
    return this.config.hideWeekNumbers ?? false;
  }

  /**
   * The active view
   */
  protected view: ViewType = 'week';
  protected months: string[] = [];
  protected switchId = `__si-datepicker-switch-id-${idCounter++}`;
  protected timepickerId = `__si-datepicker-timepicker-id-${idCounter++}`;
  protected actualFocusedDate: Date;
  /**
   * Configuration which view shall be shown after year selection,
   * when onlyMonthSelection is enabled the month view is shown otherwise the week view.
   */
  protected yearViewSwitchTo: 'month' | 'week' = 'week';
  protected monthViewSwitchTo: 'month' | 'week' = 'week';

  private readonly locale = inject(LOCALE_ID).toString();
  private readonly cdRef = inject(ChangeDetectorRef);
  private readonly localeWeekStart: WeekStart;
  /**
   * Date object to track and change the time. Keeping time and date
   * in separate objects to not change the date when flipping time.
   * After change, a new date object is created with an adapted time.
   */
  protected time?: Date;
  /**
   * Used to hold the last time when setting the time to disabled.
   * Value will be reset on enabling the time again.
   */
  private previousTime?: Date;

  @ViewChild('timePicker') private timePicker!: SiTimepickerComponent;
  /** Reference to the current day selection component. Shown when view === 'week' */
  @ViewChild(SiDaySelectionComponent) private daySelection?: SiDaySelectionComponent;
  /** Reference to the current month selection component. Shown when view === 'month' */
  @ViewChild(SiMonthSelectionComponent) private monthSelection?: SiMonthSelectionComponent;
  /** Reference to the current year selection component. Shown when view === 'year' */
  @ViewChild(SiYearSelectionComponent) private yearSelection?: SiYearSelectionComponent;

  /**
   * The cell which which has the mouse hover.
   * @internal
   */
  @Input() activeHover?: Cell;
  /**
   * Required to establish a two way binding of activeHover. The event emits on mouseover or focus changes.
   * @internal
   */
  @Output() readonly activeHoverChange = new EventEmitter<Cell>();

  constructor() {
    this.initCalendarLabels();
    this.actualFocusedDate = dt.today();

    const weekStart = getLocaleFirstDayOfWeek(this.locale);
    this.localeWeekStart =
      weekStart === WeekDay.Sunday
        ? 'sunday'
        : weekStart === WeekDay.Saturday
          ? 'saturday'
          : 'monday';
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.date && !this.config.enableDateRange) {
      if (this.date && !dt.isValid(this.date)) {
        this.date = undefined;
      }
      if (this.date) {
        if (changes.date.isFirstChange()) {
          this.previousTime = new Date(this.date);
          this.time = this.date;
        }
        if (this.time?.getTime() !== this.date?.getTime()) {
          this.time = this.date;
        }
        this.changeFocusedDate(this.date);
      }
    }

    if (changes.config?.currentValue?.disabledTime) {
      this.disabledTime = changes.config?.currentValue?.disabledTime;
      this.onDisabledTimeChanged();
    }

    if (changes.config?.firstChange) {
      if (this.config.onlyMonthSelection) {
        this.yearViewSwitchTo = 'month';
        this.monthViewSwitchTo = 'month';
        this.switchView('month');
      }
    }

    // Date-range input field has changed
    if (changes.dateRange) {
      // Ensure the dateRange object only contain valid start/end dates
      if (this.dateRange) {
        if (!isValid(this.dateRange?.start)) {
          this.dateRange!.start = undefined;
        }
        if (!isValid(this.dateRange?.end)) {
          this.dateRange!.end = undefined;
        }
      }

      // Only one calendar is used when no dateRangeRole is available.
      if (!this.dateRangeRole) {
        const previous: DateRange | undefined = changes.dateRange.previousValue;
        if (this.dateRange?.end && !dt.isSameDate(this.dateRange!.end!, previous?.end)) {
          this.actualFocusedDate = this.dateRange!.end!;
        }
        if (this.dateRange?.start && !dt.isSameDate(this.dateRange!.start!, previous?.start)) {
          this.actualFocusedDate = this.dateRange!.start!;
        }
      } else {
        // Date range selection with two calendars
        const newDate =
          this.dateRangeRole === 'START' ? this.dateRange?.start : this.dateRange?.end;

        if (newDate && changes.dateRange.isFirstChange()) {
          this.previousTime = new Date(newDate);
          this.time = newDate;
        }
      }
    }

    if (changes.focusedDate && this.focusedDate) {
      this.actualFocusedDate = this.focusedDate;
    }

    if (
      changes.minMonth?.currentValue &&
      dt.isAfter(changes.minMonth.currentValue, this.actualFocusedDate)
    ) {
      this.changeFocusedDate(changes.minMonth.currentValue);
    }
    if (
      changes.maxMonth?.currentValue &&
      dt.isAfter(this.actualFocusedDate, changes.maxMonth.currentValue)
    ) {
      this.changeFocusedDate(changes.maxMonth.currentValue);
    }
  }

  ngOnInit(): void {
    if (
      this.config.enableDateRange &&
      this.rangeType === 'END' &&
      this.dateRange?.end &&
      !this.dateRangeRole
    ) {
      // The user chose to trigger the datepicker from the date-range end, in this
      // case we
      this.actualFocusedDate = this.dateRange!.end;
    } else if (this.config.enableDateRange && this.dateRangeRole === 'START') {
      const maxMonth = this.config?.onlyMonthSelection ? this.maxMonth : this.config.maxDate;
      this.actualFocusedDate = dt.getDateSameOrBetween(
        this.dateRange?.start ? this.dateRange!.start : (this.focusedDate ?? dt.today()),
        this.config.minDate,
        maxMonth
      );
    } else if (this.config.enableDateRange && this.dateRangeRole === 'END') {
      const minMonth = this.config?.onlyMonthSelection ? this.minMonth : this.config.minDate;
      this.actualFocusedDate = dt.getDateSameOrBetween(
        this.dateRange?.end ? this.dateRange!.end : (this.focusedDate ?? dt.today()),
        minMonth,
        this.config.maxDate
      );
    } else {
      this.actualFocusedDate = dt.getDateSameOrBetween(
        isValid(this.startDate) ? this.startDate : (this.focusedDate ?? dt.today()),
        this.config.minDate,
        this.config.maxDate
      );
    }
    this.changeFocusedDate(this.actualFocusedDate);
  }

  ngAfterViewInit(): void {
    // After the view is created the first time we want that the children components set
    // the focus to the calendarBody. Means when we select a date in month-selection,
    // the day selection shall focus automatically the day in calendarBody.
    setTimeout(() => (this.initialFocus = true));
  }

  /** Initialize day and month labels */
  private initCalendarLabels(): void {
    this.months = getLocaleMonthNames(this.locale.toString());
  }

  /**
   * Validates and sets a new date to the this.date model object of this component
   * and fires the related events. The model object shall not be updated elsewhere
   * with a new date object. Shall only be called on simple date selection and not
   * on date range selection.
   *
   * @param newDate - The new date to be set.
   */
  private setDate(newDate: Date): void {
    const dateWithoutTime = dt.getDateWithoutTime(newDate);
    const validForMinDate = !(
      this.config?.minDate && dateWithoutTime < dt.getDateWithoutTime(this.config.minDate)
    );
    const validForMaxDate = !(
      this.config?.maxDate && dateWithoutTime > dt.getDateWithoutTime(this.config.maxDate)
    );
    if (this.date !== newDate && validForMinDate && validForMaxDate) {
      const previousValue = this.date;
      this.date = newDate;
      // eslint-disable-next-line @angular-eslint/no-lifecycle-call
      this.ngOnChanges({
        date: new SimpleChange(previousValue, this.date, previousValue === undefined)
      });
      this.dateChange.next(this.date);
    } else if (!validForMinDate || !validForMaxDate) {
      // eslint-disable-next-line @angular-eslint/no-lifecycle-call
      this.ngOnChanges({ date: new SimpleChange(undefined, this.date, true) });
    }
    if (
      this.config.enableTimeValidation &&
      this.timePicker &&
      (this.config.minDate || this.config.maxDate)
    ) {
      this.validateTime(newDate);
    }

    this.cdRef.markForCheck();
  }

  /**
   * Validates and sets the new date range to the dateRange model
   * object.
   * @param newDateRange - The new range to be set.
   * @returns True if the new range is valid and set. Otherwise false.
   */
  private setDateRange(newDateRange: DateRange): boolean {
    if (newDateRange.start) {
      const isValidRange = dt.isSameOrBetween(
        newDateRange.start,
        this.config?.minDate,
        this.config?.maxDate
      );
      if (!isValidRange) {
        return false;
      }
    }
    if (newDateRange.end) {
      const isValidRange = dt.isSameOrBetween(
        newDateRange.end,
        this.config?.minDate,
        this.config?.maxDate
      );
      if (!isValidRange) {
        return false;
      }
    }

    this.dateRange = newDateRange;
    this.dateRangeChange.next(this.dateRange);
    return true;
  }

  protected timeSelected(newTime: Date): void {
    if (!newTime) {
      return;
    }

    // Break event cycle
    if (this.time?.getTime() === newTime.getTime()) {
      this.validateTime(newTime);
      return;
    }

    this.previousTime = this.time;
    this.time = newTime;

    const oldDate = this.getRelevantDate() ?? new Date();
    let newDate: Date;
    if (this.disabledTime) {
      // if time is disabled, ensure that 00:00:00 is displayed in any timezone
      newDate = dt.createDate(oldDate);
      this.time = newDate;
    } else {
      newDate = dt.createDate(
        oldDate,
        this.time.getHours(),
        this.time.getMinutes(),
        this.time.getSeconds(),
        this.time.getMilliseconds()
      );
    }
    if (!this.config.enableDateRange) {
      this.setDate(newDate);
    } else {
      const newDateRange =
        this.dateRangeRole === 'START'
          ? { start: newDate, end: this.dateRange?.end }
          : { start: this.dateRange?.start, end: newDate };
      this.setDateRange(newDateRange);
    }
  }

  protected toggleDisabledTime(): void {
    this.disabledTime = !this.disabledTime;
    this.config.disabledTime = this.disabledTime;
    this.onDisabledTimeChanged();
  }

  private onDisabledTimeChanged(): void {
    if (!this.config.enableDateRange) {
      if (this.disabledTime) {
        const date = this.date ?? new Date();
        const newTime = new Date(
          Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0)
        );
        this.timeSelected(newTime);
      } else if (this.previousTime) {
        this.timeSelected(this.previousTime);
      } else {
        this.timeSelected(new Date());
      }
    }
    this.disabledTimeChange.next(this.disabledTime);
  }

  private validateTime(date: Date): void {
    // wait for a cycle to initialize timepicker
    setTimeout(() => {
      if (
        !this.disabledTime &&
        ((this.config.minDate && date < this.config.minDate) ||
          (this.config.maxDate && date > this.config.maxDate))
      ) {
        this.timePicker.invalidHours = this.timePicker.invalidMinutes = true;
        this.timePicker.invalidSeconds = this.timePicker.invalidMilliseconds = true;
      } else {
        this.timePicker.invalidHours = this.timePicker.invalidMinutes = false;
        this.timePicker.invalidSeconds = this.timePicker.invalidMilliseconds = false;
      }
      this.cdRef.markForCheck();
    });
  }

  /**
   * Get the current shown view.
   */
  protected getActiveView():
    | SiDaySelectionComponent
    | SiMonthSelectionComponent
    | SiYearSelectionComponent
    | undefined {
    switch (this.view) {
      case 'month':
        return this.monthSelection;
      case 'year':
        return this.yearSelection;
      default:
        return this.daySelection;
    }
  }

  protected switchView(newView: ViewType): void {
    this.view = newView;
    this.cdRef.markForCheck();
  }

  /**
   * Handle selection in the day view.
   * @param selection - selected date.
   */
  protected selectionChange(selection: Date): void {
    const newDate = dt.createDate(
      selection,
      this.time?.getHours(),
      this.time?.getMinutes(),
      this.time?.getSeconds(),
      this.time?.getMilliseconds()
    );
    if (this.config.enableDateRange) {
      let newDateRange: DateRange =
        !this.rangeType || this.rangeType === 'START'
          ? { start: newDate, end: undefined }
          : { start: this.dateRange?.start, end: newDate };
      let newRangeType: RangeType = !this.rangeType || this.rangeType === 'START' ? 'START' : 'END';

      // The user selected a date before the current range start. Now the clicked day
      // is used as new start and the end is cleared
      if (newDateRange.start && newDateRange.end && newDateRange.end < newDateRange.start) {
        newDateRange = { start: newDateRange.end, end: undefined };
        newRangeType = 'START'; // Switch back to start so that the next selection is end
      }

      // Reset end range when we started start
      if (newRangeType === 'START' && newDateRange.end) {
        newDateRange.end = undefined;
      }

      const rangeValid = this.setDateRange(newDateRange);

      if (rangeValid) {
        // Toggle rangeType every time the user uses the datepicker to change the range
        this.rangeType = newRangeType === 'START' ? 'END' : 'START';
        this.rangeTypeChange.emit(this.rangeType);
      }
    } else {
      this.changeFocusedDate(newDate);
      this.setDate(newDate);
    }
  }

  /**
   * Handle month/year changes
   * @param selection - the selected month or null of cancelled.
   */
  protected activeMonthChange(selection: Date | null): void {
    if (selection) {
      this.changeFocusedDate(dt.changeDay(selection, this.actualFocusedDate!.getDate()));
      if (this.config.onlyMonthSelection) {
        if (this.config.enableDateRange) {
          this.selectionChange(selection);
        } else {
          this.setDate(selection);
        }
      }
      this.cdRef.markForCheck();
    }
    this.switchView(this.monthViewSwitchTo);
  }

  /**
   * Handle year changes
   * @param selection - the selected year or null of cancelled.
   */
  protected activeYearChange(selection: Date | null): void {
    if (selection) {
      selection.setMonth(this.actualFocusedDate!.getMonth());
      this.changeFocusedDate(dt.changeDay(selection, this.actualFocusedDate!.getDate()));
    }
    this.switchView(this.yearViewSwitchTo);
  }

  /**
   * Focus the active cell in view.
   * The function is required to transfer the focus from input to the active date cell.
   */
  focusActiveCell(): void {
    this.getActiveView()?.focusActiveCell();
  }

  protected onActiveHoverChange(event: Cell): void {
    this.activeHover = event;
    this.activeHoverChange.emit(event);
  }

  private changeFocusedDate(date: Date): void {
    this.actualFocusedDate = this.focusedDate = date;
    this.focusedDateChange.emit(this.actualFocusedDate);
  }
}
