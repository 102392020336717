import { AfterViewInit, booleanAttribute, Directive, HostBinding, Input } from '@angular/core';

import { SiAutoCollapsableListMeasurable } from './si-auto-collapsable-list-measurable.class';

@Directive({
  selector: '[siAutoCollapsableListItem]',
  exportAs: 'siAutoCollapsableListItem',
  standalone: true
})
export class SiAutoCollapsableListItemDirective
  extends SiAutoCollapsableListMeasurable
  implements AfterViewInit
{
  /**
   * Hide this item even if enough space is available.
   * When calculating the overall available size, this item is still considered when forceHide=true
   *
   * @defaultValue false
   */
  @Input({ transform: booleanAttribute }) forceHide = false;

  /**
   * True if enough space is available for this item.
   */
  canBeVisible?: boolean;

  @HostBinding('style.visibility')
  protected get visibility(): string {
    return this.isVisible ? 'visible' : 'hidden';
  }

  @HostBinding('style.position')
  protected get position(): string {
    return this.isVisible ? '' : 'absolute';
  }

  // Ensure hidden items are behind the visible ones. Otherwise, the visible ones are not clickable
  @HostBinding('style.z-index')
  protected get zIndex(): string {
    return this.isVisible ? '' : '-1';
  }

  /**
   * True if this item is actually visible to the user
   */
  get isVisible(): boolean {
    return !!this.canBeVisible && !this.forceHide;
  }

  override ngAfterViewInit(): void {
    if (getComputedStyle(this.elementRef.nativeElement).display === 'inline') {
      console.error('siAutoCollapsibleListItem does not work on items with display="inline"');
    }
    super.ngAfterViewInit();
  }
}
