import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { SiTranslateModule, TranslatableString } from '@simpl/element-translate-ng/translate';

@Component({
  selector: 'si-icon',
  templateUrl: './si-icon.component.html',
  styles: ':host, span { line-height: 1; }',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, SiTranslateModule]
})
export class SiIconComponent implements OnChanges {
  /** Icon token, see {@link https://simpl.code.siemens.io/simpl/icons/element} */
  @Input() icon?: string;
  /** Color class, see {@link https://simpl.code.siemens.io/simpl-element/fundamentals/typography/#color-variants-classes} */
  @Input() color?: string;
  /** Icon token, see {@link https://simpl.code.siemens.io/simpl-element/fundamentals/icons/} */
  @Input() stackedIcon?: string;
  /** Color class, see {@link https://simpl.code.siemens.io/simpl-element/fundamentals/icons/} */
  @Input() stackedColor?: string;
  /** Alternative name or translation key for icon. Used for A11y. */
  @Input() alt?: TranslatableString;
  /**
   * Text-size class for icon size, see {@link https://simpl.code.siemens.io/simpl-element/fundamentals/typography/#type-styles-classes}
   *
   * @defaultValue 'icon'
   */
  @Input() size = 'icon';

  protected altText = '';

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.icon || changes.alt) {
      this.altText = this.alt ?? this.icon?.replace('element-', '').split('-').join(' ') ?? '';
    }
  }
}
