import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

@Component({
  selector: 'si-input-pill',
  standalone: true,
  imports: [],
  templateUrl: './si-input-pill.component.html',
  host: {
    class: 'pill pe-0'
  }
})
export class SiInputPillComponent {
  @Output() readonly deletePill = new EventEmitter<void>();

  /** @defaultValue false */
  @Input() @HostBinding('class.pe-4') hideClose = false;
}
