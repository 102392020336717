import { EntityBase } from '../shared/base.model';

export enum EventStateBx {
  Active = 'active',
  ActiveAcknowledged = 'activeAcknowledged',
  Inactive = 'inactive',
  InactiveAcknowledged = 'inactiveAcknowledged',
  Closed = 'closed'
}

export const enum EventCategoryBx {
  Emergency = 'Emergency',
  LifeSafety = 'Life Safety',
  Security = 'Security',
  High = 'High',
  Medium = 'Medium',
  Low = 'Low',
  Fault = 'Fault',
  Trouble = 'Trouble',
  Supervisory = 'Supervisory',
  Status = 'Status',
  Unknown = 'Unknown'
}

export interface EventBx {
  category: EventCategoryBx;
  deviceId: string; // id of the gateway
  eventId: string;
  eventType: string;
  alarmType: string;
  isActive: boolean; // source state, equals to: state.StartsWith("active"))
  isOpen: boolean; // equals to: state!="closed"
  message?: string;
  producedBy: EntityBase; // device of the source point
  raisedAt: string; // UTC datetime in ISO 8601
  siteId: string;
  source: EntityBase;
  sourceValue?: string;
  state: EventStateBx; // "active", "activeAcknowledged", "inactive", "inactiveAcknowledged", "closed"
  stateDetail?: string;
  syncId?: string;
  syncOrigin?: string;
  uniqueTimestamp: string;
  updatedAt: string; // UTC datetime in ISO 8601
}

export interface EventResponse {
  events: EventBx[];
}

export interface EventResponsePaged {
  events: EventBx[];
  lastEvaluatedKey: string;
}

export interface Summary {
  count: number;
  updatedAt: string;
}

export type EventSummary = {
  [key in EventCategoryBx]: Summary;
}

export interface EventSummaryResponse extends EventSummary {
  siteId: string;
  noOfOpenAlarms: number;
  updatedAt: string;
}
