import { ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { SiBACnetDateTimePickerModule } from '@simpl/buildings-ng/bacnet-datetime-picker';
import { SiDatapointModule } from '@simpl/buildings-ng/datapoint';
import { SiTimeDurationModule } from '@simpl/buildings-ng/time-duration';

import { FakePropertyApiService, PropertyApi } from './api/index';
import {
  SiBigNumberPropertyModule,
  SiBitstringPropertyModule,
  SiBlindPropertyModule,
  SiBooleanPropertyModule,
  SiCollectionPropertyModule,
  SiDatePropertyModule,
  SiDatetimePropertyModule,
  SiDefaultPropertyModule,
  SiEnumPropertyModule,
  SiNumberPropertyModule,
  SiObjectidPropertyModule,
  SiPriorityArrayPropertyModule,
  SiPropertyPopoverModule,
  SiPropertyViewerModule,
  SiStringPropertyModule,
  SiTimedurationPropertyModule,
  SiTimePropertyModule
} from './components/index';
import { SiPropertyService } from './services';

@NgModule({
  exports: [
    SiBACnetDateTimePickerModule,
    SiBigNumberPropertyModule,
    SiBitstringPropertyModule,
    SiBlindPropertyModule,
    SiBooleanPropertyModule,
    SiCollectionPropertyModule,
    SiDatapointModule,
    SiDatePropertyModule,
    SiDatetimePropertyModule,
    SiDefaultPropertyModule,
    SiEnumPropertyModule,
    SiNumberPropertyModule,
    SiObjectidPropertyModule,
    SiPriorityArrayPropertyModule,
    SiPropertyPopoverModule,
    SiPropertyViewerModule,
    SiStringPropertyModule,
    SiTimeDurationModule,
    SiTimedurationPropertyModule,
    SiTimePropertyModule
  ]
})
export class SimplPropertyViewerNgModule {
  static forRoot(
    config: { propertyApi?: Provider } = {}
  ): ModuleWithProviders<SimplPropertyViewerNgModule> {
    return {
      ngModule: SimplPropertyViewerNgModule,
      providers: [
        config.propertyApi ?? { provide: PropertyApi, useClass: FakePropertyApiService },
        { provide: SiPropertyService }
      ]
    };
  }
}
