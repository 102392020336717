<si-number-input
  class="form-control"
  [inputId]="id"
  [formControl]="formControl"
  [formlyAttributes]="field"
  [min]="props.min"
  [max]="props.max"
  [placeholder]="props.placeholder"
  [disabled]="props.disabled ?? false"
  [readonly]="props.readonly"
  [showButtons]="props.showButtons ?? true"
  [step]="props.numberStep ?? 1"
  [unit]="props.unit"
/>
