import { Component, inject, Input, OnInit } from '@angular/core';
import { SiTranslateModule, SiTranslateService } from '@simpl/element-translate-ng/translate';

import { IsoLanguageValue } from './iso-language-value';

@Component({
  selector: 'si-language-switcher',
  templateUrl: './si-language-switcher.component.html',
  styleUrl: './si-language-switcher.component.scss',
  standalone: true,
  imports: [SiTranslateModule]
})
export class SiLanguageSwitcherComponent implements OnInit {
  /**
   * Key for translation.
   * If the key is set to an empty string, the language of the underlaying tanslation framework will not be switched.
   *
   * @defaultValue 'LANGUAGE'
   */
  @Input() translationKey = 'LANGUAGE';
  /**
   * Text for aria label for the language selector. Needed for a11y
   *
   * @defaultValue
   * ```
   * $localize`:@@SI_LANGUAGE_SWITCHER.LABEL:Language switcher`
   * ```
   */
  @Input()
  languageSwitcherLabel = $localize`:@@SI_LANGUAGE_SWITCHER.LABEL:Language switcher`;

  /**
   * List of all available languages in this application.
   */
  @Input()
  set availableLanguages(languages: string[] | IsoLanguageValue[]) {
    if (typeof languages[0] !== 'object') {
      languages = (languages as string[]).map(languageValue => ({
        value: languageValue,
        name: this.translationKey
          ? `${this.translationKey}.${languageValue.toUpperCase()}`
          : languageValue
      }));
    }
    this.availableIsoLanguages = languages as IsoLanguageValue[];
  }

  get availableLanguages(): IsoLanguageValue[] {
    return this.availableIsoLanguages;
  }

  protected availableIsoLanguages!: IsoLanguageValue[];
  protected translate = inject(SiTranslateService);

  ngOnInit(): void {
    this.availableLanguages ??= this.translate.availableLanguages;
  }

  protected switchLanguage(target: EventTarget | null): void {
    const language = (target as HTMLSelectElement)?.value;
    if (!language || !this.translationKey || this.translationKey.length === 0) {
      return;
    }
    this.translate.setCurrentLanguage(language);
  }
}
