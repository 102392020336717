import { Injectable } from '@angular/core';
import { ConnectionState, EventCounter, EventCounterList, EventCounterProxyServiceBase, TraceModules } from '@gms-flex/services';
import { TraceService } from '@gms-flex/services-common';
import { asapScheduler, Observable, scheduled, Subject, throwError } from 'rxjs';
import { EventsSummaryService } from 'src/app/bx-services/alarm/events-summary.service';

@Injectable()
export class EventCounterBxSubstituteProxyService extends EventCounterProxyServiceBase {

  private readonly _notifyConnectionState: Subject<ConnectionState> = new Subject<ConnectionState>();
  private readonly _eventCounters: Subject<EventCounterList> = new Subject<EventCounterList>();
  private subscriberCount = 0;

  public constructor(
    private readonly traceService: TraceService,
    private readonly eventSummaryService: EventsSummaryService) {
    super();

    asapScheduler.schedule(() => {
      // No real connection state is delivered. There is no constant streaming channel.
      this._notifyConnectionState.next(ConnectionState.Disconnected);
      this._notifyConnectionState.next(ConnectionState.Connecting);
      this._notifyConnectionState.next(ConnectionState.Connected);
    }, 0);

    this.traceService.info(TraceModules.events, 'EventCounterBxSubstituteProxyService created.');
  }

  public notifyConnectionState(): Observable<ConnectionState> {
    return this._notifyConnectionState.asObservable();
  }

  /**
   * Gets all event counters for all categories of the system.
   *
   */
  public getEventCountersAll(): Observable<EventCounterList> {
    this.traceService.info(TraceModules.events, 'getEventCountersAll() called.');

    return throwError(() => new Error('EventCounterBxSubstituteProxyService.getEventCountersAll(): Not Implemented!'));

  }

  /**
   * Gets the event counters for the specified category Id
   *
   */
  public getEventCounters(categoryId: number): Observable<EventCounter> {
    this.traceService.info(TraceModules.events, 'getEventCounters() called. categoryId: %s', categoryId);

    return throwError(() => new Error('EventCounterBxSubstituteProxyService.getEventCountersAll(): Not Implemented!'));
  }

  /**
   * Subscribes for all event counters of the system.
   *
   */
  public subscribeEventCounters(hiddenEvents = false): Observable<boolean> {
    this.traceService.info(TraceModules.events, 'EventCounterBxSubstituteProxyService.subscribeEventCounters() called.');
    this.traceService.info(TraceModules.eventCounterTiming, 'EventCounterBxSubstituteProxyService.subscribeEventCounters() called.');

    if (this.subscriptionActive === false) {
      // we use the EventSummaryService to notify the counters upon event summary changes:
      this.eventSummaryService.getEventSummary.subscribe(data => {
        this._eventCounters.next(data);
      })
    }
    this.subscriberCount++;
    return scheduled([true], asapScheduler);
  }

  /**
   * Event for the event counter notifications.
   *
   */
  public eventCountersNotification(): Observable<EventCounterList> {
    return this._eventCounters.asObservable();
  }

  public unSubscribeEventCounters(): Observable<boolean> {
    this.traceService.info(TraceModules.events, 'EventCounterBxSubstituteProxyService.unSubscribeEventCounters() called');

    if (this.subscriptionActive) {
      this.subscriberCount--;
    }
    return scheduled([true], asapScheduler);
  }

  private get subscriptionActive(): boolean {
    return (this.subscriberCount > 0);
  }
}
