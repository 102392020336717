import { NgModule } from '@angular/core';

import { SiComfortScoreStatusComponent } from './si-comfort-score-status.component';
import { SiComfortScoreComponent } from './si-comfort-score.component';

/**
 * @deprecated The {@link SiComfortScoreModule} and all related symbols should no
 * longer be used and will be removed in Element v47.
 */
@NgModule({
  exports: [SiComfortScoreComponent, SiComfortScoreStatusComponent],
  imports: [SiComfortScoreComponent, SiComfortScoreStatusComponent]
})
export class SiComfortScoreModule {}
