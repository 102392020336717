import { NgClass, NgTemplateOutlet } from '@angular/common';
import {
  AfterContentInit,
  booleanAttribute,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  HostBinding,
  inject,
  Input,
  OnDestroy,
  Output,
  QueryList,
  signal,
  ViewChild
} from '@angular/core';
import { WebComponentContentChildren } from '@simpl/element-ng/common';
import { SiResizeObserverDirective } from '@simpl/element-ng/resize-observer';
import { SiTranslateModule } from '@simpl/element-translate-ng/translate';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { SiWizardStepComponent } from './si-wizard-step.component';

interface StepItem {
  index: number;
  step: SiWizardStepComponent;
}

@Component({
  selector: 'si-wizard',
  templateUrl: './si-wizard.component.html',
  styleUrl: './si-wizard.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, SiResizeObserverDirective, SiTranslateModule, NgTemplateOutlet],
  host: {
    class: 'py-6 px-8 d-flex flex-column'
  }
})
export class SiWizardComponent implements AfterContentInit, OnDestroy {
  @WebComponentContentChildren(SiWizardStepComponent)
  @ContentChildren(SiWizardStepComponent)
  private wizardSteps!: QueryList<SiWizardStepComponent>;

  @ViewChild('containerSteps') protected containerSteps?: ElementRef<HTMLDivElement>;

  /**
   * Description of back button.
   *
   * @defaultValue
   * ```
   * $localize`:@@SI_WIZARD.BACK:Back`
   * ```
   */
  @Input() backText = $localize`:@@SI_WIZARD.BACK:Back`;
  /**
   * Description of next button.
   *
   * @defaultValue
   * ```
   * $localize`:@@SI_WIZARD.NEXT:Next`
   * ```
   */
  @Input() nextText = $localize`:@@SI_WIZARD.NEXT:Next`;

  /** @deprecated Use {@link hideNavigation} instead. */
  @Input({ transform: booleanAttribute }) set hasNavigation(value: boolean) {
    this.hideNavigation = !value;
  }

  /**
   * Hide the navigation buttons previous/next.
   *
   * @defaultValue false
   */
  @Input({ transform: booleanAttribute }) hideNavigation = false;
  /**
   * Description of save button.
   *
   * @defaultValue
   * ```
   * $localize`:@@SI_WIZARD.SAVE:Save`
   * ```
   */
  @Input() saveText = $localize`:@@SI_WIZARD.SAVE:Save`;
  /**
   * Hide the save button.
   *
   * @defaultValue false
   */
  @Input({ transform: booleanAttribute }) hideSave = false;
  /**
   * Text shown if you complete the wizard.
   *
   * @defaultValue
   * ```
   * $localize`:@@SI_WIZARD.COMPLETED:Wizard completed!`
   * ```
   */
  @Input() completionText = $localize`:@@SI_WIZARD.COMPLETED:Wizard completed!`;
  /**
   * Description of cancel button.
   *
   * @defaultValue
   * ```
   * $localize`:@@SI_WIZARD.CANCEL:Cancel`
   * ```
   */
  @Input() cancelText = $localize`:@@SI_WIZARD.CANCEL:Cancel`;
  /**
   * Show the cancel button
   *
   * @defaultValue false
   */
  @Input({ transform: booleanAttribute }) hasCancel = false;
  /**
   * Display a predefined page by the end of the wizard.
   *
   * @defaultValue false
   */
  @Input({ transform: booleanAttribute }) enableCompletionPage = false;
  /**
   * Define how many milliseconds the completion page is visible.
   *
   * @defaultValue 3000
   */
  @Input() completionPageVisibleTime = 3000;
  /**
   * Class name of icon shown for current and upcoming steps.
   *
   * @defaultValue 'element-not-checked'
   */
  @Input() stepIcon = 'element-not-checked';
  /**
   * Class name of icon shown for the active step.
   *
   * @defaultValue 'element-radio-checked'
   */
  @Input() stepActiveIcon = 'element-radio-checked';
  /**
   * Class name of icon shown when a step was completed.
   *
   * @defaultValue 'element-checked-filled'
   */
  @Input() stepCompletedIcon = 'element-checked-filled';
  /**
   * Class name of icon shown when a step had an error.
   *
   * @defaultValue 'element-warning-filled'
   */
  @Input() stepFailedIcon = 'element-warning-filled';
  /**
   * Set the orientation of the wizard to vertical.
   *
   * @defaultValue false
   */
  @Input({ transform: booleanAttribute }) @HostBinding('class.vertical') verticalLayout = false;
  /**
   * Set false to show navigation buttons in footer instead of inline.
   *
   * @defaultValue true
   */
  @Input({ transform: booleanAttribute }) inlineNavigation = true;
  /**
   * Use number representation for steps.
   *
   * @defaultValue false
   */
  @Input({ transform: booleanAttribute }) showStepNumbers = false;
  /** Set the wizard step container min size in vertical layout. */
  @HostBinding('style.--wizard-vertical-min-size')
  @Input()
  verticalMinSize?: string;
  /** Set the wizard step container max size in vertical layout. */
  @HostBinding('style.--wizard-vertical-max-size')
  @Input()
  verticalMaxSize?: string;

  /** Callback function triggered after the wizard has been completed. */
  @Output() readonly completionAction = new EventEmitter();

  /** Callback function triggered if the wizard has been canceled. */
  @Output() readonly wizardCancel = new EventEmitter();

  /**
   * Callback function triggered if the wizard has been canceled.
   * @deprecated use {@link wizardCancel} instead
   */
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() readonly cancel = this.wizardCancel;

  /** Get the current step wizard step index. */
  get index(): number {
    return this._index;
  }

  /** Get number of wizard steps. */
  get stepCount(): number {
    return this.steps.length;
  }

  /** Get current visible wizard step. */
  get currentStep(): SiWizardStepComponent | undefined {
    return this._currentStep;
  }

  protected steps: SiWizardStepComponent[] = [];
  protected visibleSteps = 0;
  protected showCompletionPage = false;
  /** The list of visible steps. */
  protected readonly activeSteps = signal<StepItem[]>([]);

  private _index = 0;
  private _currentStep?: SiWizardStepComponent;
  private destroyer = new Subject<void>();
  private cdRef = inject(ChangeDetectorRef);

  ngAfterContentInit(): void {
    this.updateSteps();
    this._currentStep = this.steps[0];
    this.cdRef.markForCheck();
    queueMicrotask(() => {
      if (this.steps.length > 0) {
        this.steps[0].isActive = true;
        this.cdRef.markForCheck();
      }
    });

    this.wizardSteps.changes.pipe(takeUntil(this.destroyer)).subscribe(() => {
      this.updateSteps();
      if (!this.wizardSteps.some(step => step === this.currentStep)) {
        this._currentStep = this.wizardSteps.first;
        this._index = 0;
      } else {
        this._index = this.currentStep ? this.steps.indexOf(this.currentStep) : 0;
      }
      this.cdRef.markForCheck();
      queueMicrotask(() => {
        if (this.currentStep) {
          this.activate(this.currentStep);
        }
        this.calculateNumberOfVisibleSteps();
        this.cdRef.markForCheck();
      });
    });
  }

  ngOnDestroy(): void {
    this.destroyer.next();
    this.destroyer.complete();
  }

  /** @internal */
  notifyChildrenUpdated(): void {
    this.cdRef.markForCheck();
  }

  protected canActivate(stepIndex: number): boolean {
    if (stepIndex < 0) {
      return false;
    }
    // Can always activate previous steps
    if (stepIndex < this.index) {
      return true;
    }
    // We are already in the step. Nothing to activate.
    if (stepIndex === this.index) {
      return false;
    }
    // Fast-forward: check all steps if they are valid
    for (let i = this.index; i < stepIndex; i++) {
      const theStep = this.steps[i];
      if (!theStep.isValid) {
        return false;
      }
    }
    return true;
  }

  protected activateStep(event: Event, stepIndex: number): void {
    event.preventDefault();
    if (this.canActivate(stepIndex)) {
      if (stepIndex > this.index) {
        this.next(stepIndex - this.index);
      }
      if (stepIndex < this.index) {
        this.back(this.index - stepIndex);
      }
    }
  }

  protected getStateClass(stepIndex: number): string {
    if (stepIndex === this.index) {
      return 'active';
    }
    if (!this.canActivate(stepIndex)) {
      return 'disabled';
    }
    if (stepIndex < this.index) {
      return 'completed';
    }
    return '';
  }

  protected getAriaDisabled(stepIndex: number): string {
    if (!this.canActivate(stepIndex)) {
      return 'true';
    }
    return 'false';
  }

  protected getAriaCurrent(stepIndex: number): string {
    if (stepIndex === this.index) {
      return 'step';
    }
    return 'false';
  }

  /**
   * Go to the next wizard step.
   * @param delta - optional number of steps to move forward.
   */
  next(delta: number = 1): void {
    if (this.index === this.steps.length) {
      return;
    }
    const stepIndex = this.index + delta;
    const nextStep = this.steps[stepIndex];
    if (this.canActivate(stepIndex)) {
      this.currentStep?.next.emit();
      if (this.currentStep?.isNextNavigable) {
        this.activate(nextStep);
      }
    }
    this.cdRef.markForCheck();
  }

  /**
   * Go to the previous wizard step.
   * @param delta - optional number of steps to move backwards.
   */
  back(delta: number = 1): void {
    if (this.index === 0) {
      return;
    }
    this.currentStep?.back.emit();
    this.activate(this.steps[this.index - delta]);
    this.cdRef.markForCheck();
  }

  /** Triggers the save action to complete the wizard. */
  save(): void {
    this.currentStep?.save.emit();

    if (this.enableCompletionPage && this.completionPageVisibleTime > 0) {
      this.showCompletionPage = true;
      setTimeout(() => {
        this.showCompletionPage = false;
        this.completionAction.emit();
        this.cdRef.markForCheck();
      }, this.completionPageVisibleTime);
    } else {
      this.completionAction.emit();
    }
  }

  protected getState(step: SiWizardStepComponent, stepIndex: number): string {
    if (step.failed === true) {
      return this.stepFailedIcon;
    }
    const txtStyle = step.isActive ? this.stepActiveIcon : this.stepIcon;
    return stepIndex >= this.index ? txtStyle : this.stepCompletedIcon;
  }

  private activate(step: SiWizardStepComponent): void {
    if (this.currentStep) {
      this.currentStep.isActive = false;
    }

    step.isActive = true;
    this._currentStep = step;
    this._index = this.steps.indexOf(step);
    this.updateVisibleSteps();
  }

  protected calculateNumberOfVisibleSteps(): void {
    if (!this.containerSteps) {
      return;
    }
    if (this.verticalLayout) {
      const computedStyle = getComputedStyle(this.containerSteps.nativeElement);
      const clientHeight =
        this.containerSteps.nativeElement.clientHeight -
        parseInt(computedStyle.paddingBlockStart) -
        parseInt(computedStyle.paddingBlockEnd);
      this.visibleSteps = Math.max(Math.floor(clientHeight / 48), 1);
    } else {
      const clientWidth = this.containerSteps.nativeElement.clientWidth;
      this.visibleSteps = Math.max(Math.floor(clientWidth / 150), 1);
    }
    this.updateVisibleSteps();
  }

  private updateSteps(): void {
    this.steps = this.wizardSteps.toArray();
    this.steps.forEach(step => step.registerParent(this));
    this.updateVisibleSteps();
  }

  private updateVisibleSteps(): void {
    const create = (index: number): StepItem => ({ index, step: this.steps[index] });
    if (this.steps.length === 0) {
      this.activeSteps.set([]);
    } else if (this.visibleSteps <= 1) {
      this.activeSteps.set([create(this.index)]);
    } else if (this.stepCount <= this.visibleSteps) {
      this.activeSteps.set(this.steps.map((_, i) => create(i)));
    } else {
      const steps = [this.index];
      for (
        let i = 1, left = this.index - 1, right = this.index + 1;
        i < this.visibleSteps;
        right++, left--
      ) {
        // Iterate in both directions to check current step is in visible range.
        if (right < this.stepCount) {
          steps.push(right);
          i++;
        }
        if (left >= 0 && i < this.visibleSteps) {
          steps.push(left);
          i++;
        }
      }

      this.activeSteps.set(steps.sort((l, r) => l - r).map(i => create(i)));
    }
  }
}
