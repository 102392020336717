import { AfterContentInit, Component, ElementRef, HostBinding, OnDestroy, OnInit, Self, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IHfwMessage, SnapInBase } from '@gms-flex/core';
import { GmsMessageData } from '@gms-flex/services';
import { AppContextService, TraceService } from '@gms-flex/services-common';
import { TranslateService } from '@ngx-translate/core';
import { DateFormat, PropertyApi, SiPropertyConfig, SiPropertyService, TimeFormat } from '@simpl/buildings-ng';
import { ResizeObserverService, SiToastNotificationService } from '@simpl/element-ng';
import { animationFrameScheduler, Subscription } from 'rxjs';
import { concatMap, take } from 'rxjs/operators';

import { PropertyApiService } from '../services/property-api.service';
import { PropertySnapInService } from '../services/property-snapin.service';
import { TraceModules } from '../shared/trace-modules';
import { PropertyInstance } from '../view-model/property-instance-vm';
import { PropertySnapInViewModel } from '../view-model/snapin-vm';
import { ContextState } from '../view-model/snapin-vm.types';

@Component({
  selector: 'gms-property-snapin',
  templateUrl: './property-snapin.component.html',
  styleUrl: './property-snapin.component.scss',
  providers: [
    SiPropertyConfig,
    SiPropertyService,
    { provide: PropertyApi, useClass: PropertyApiService }
  ]
})
export class PropertySnapInComponent extends SnapInBase implements OnInit, OnDestroy, AfterContentInit {

  // @HostBinding("class.hfw-flex-container-column") public guardFrame: boolean = true;
  @HostBinding('class.hfw-flex-item-grow') public guardGrow = true;
  @HostBinding('class.snapin-container') public guardSnapIn = true;

  public snapInVm: PropertySnapInViewModel;
  public paneWidth: number;
  public propertyInst: PropertyInstance;

  public placeholderFilter: string;
  public labelAdvancedSwitch: string;
  public labelBasicSwitch: string;
  public saveErrorTitle: string;
  public commandErrorTitle: string;
  public defaultCommandErrorMesg: string;
  public noPropertiesText: string;
  public differentPropertyTypesText: string;
  public isAboutOpen: boolean;
  public isMemoOpen: boolean;
  public isMemoEmpty: boolean;
  public isLoading: boolean;
  public isLoadingSpinnerEnabled: boolean;
  public fullSnapinID = this.fullId;

  @ViewChild('propContainer', { static: false, read: ElementRef })
  private readonly propContainer: ElementRef;

  private snapInId: string;
  private readonly propertyApiService: PropertyApiService;
  // private propertyStorageService: PropertyStorageService;
  private messageSubscription: Subscription;
  // private preselectService: PropertyPreselectService;
  private readonly subscriptions: Subscription[] = [];
  private resizeSubscription: Subscription;

  public get isMemoEnabled(): boolean {
    return this.snapInVm?.contextState === ContextState.SingleObject;
  }

  public get filterPattern(): string {
    if (!this.snapInVm) {
      return undefined;
    }
    return this.snapInVm.detailProperty ? this.snapInVm.detailProperty.objectFilter : this.snapInVm.propertyFilter;
  }

  public get switchLabel(): string {
    let lab = '';
    if (this.snapInVm) {
      lab = this.snapInVm.showPropertyListExt ? this.labelAdvancedSwitch : this.labelBasicSwitch;
    }
    return lab;
  }

  public get isPropertyListEmpty(): boolean {
    if (!(this.snapInVm && this.snapInVm.contextState !== ContextState.Empty)) {
      return false;
    }
    const propListLen: number = this.snapInVm.siPropertyList?.length || 0;
    const bulkListLen: number = this.snapInVm.siBulkPropertyList?.length || 0;
    return this.snapInVm.contextState === ContextState.SingleObject ? propListLen === 0 : bulkListLen === 0;
  }

  public get propertyListEmptyText(): string {
    return this.snapInVm.contextState === ContextState.MultiObjectDifferent ? this.differentPropertyTypesText : this.noPropertiesText;
  }

  public constructor(
    private readonly hostElement: ElementRef,
    private readonly traceService: TraceService,
    messageBroker: IHfwMessage,
    activatedRoute: ActivatedRoute,
    private readonly translateService: TranslateService,
    private readonly appContextService: AppContextService,
    private readonly toastNotificationService: SiToastNotificationService,
    private readonly resizeObserverService: ResizeObserverService,
    private readonly propertySnapInService: PropertySnapInService,
    @Self() private readonly siPropertyConfig: SiPropertyConfig,
    @Self() propertyApi: PropertyApi) {

    super(messageBroker, activatedRoute);

    // Get the browser locale
    const browserLocale = this.translateService.getBrowserLang();

    // Set language used to display snap-in resources (from user's GMS account)
    this.appContextService.defaultCulture
      .pipe(
        take(1))
      .subscribe(
        defaultCulture => {
          const lang: string = defaultCulture || browserLocale || 'en';
          this.translateService.setDefaultLang(lang);
        });
    this.appContextService.userCulture
      .pipe(
        concatMap(userCulture => this.translateService.use(userCulture)),
        take(1))
      .subscribe();

    // The PropertyApiService is provided to the SiPropertyService injected into the si-property-viewercomponent.
    // It must be initialized with the SNI view-model during local component initialization in order to process
    // get-property and property-command requests from the child si-property-viewer component.
    this.propertyApiService = propertyApi as PropertyApiService;

    // The SiPropertyConfig service is shared with the si-property-viewer component and holds component configuration information.
    this.siPropertyConfig.update({
      dateFormat: browserLocale === 'en-US' ? DateFormat.DF_MMDDYYYY_FSLASH : DateFormat.DF_DDMMYYYY_FSLASH,
      timeFormat: TimeFormat.TWELVE });
  }

  public ngOnInit(): void {

    this.snapInId = this.fullId.fullId();
    this.snapInVm = this.propertySnapInService.registerViewModel(this.snapInId);

    // Initialize the propertyApiService to process si-property-viewer component requests
    this.propertyApiService.initialize(this.traceService, this.snapInVm);
    this.propertyApiService.commandError
      .subscribe(errMesg => {
        this.toastNotificationService.queueToastNotification('danger', this.commandErrorTitle, errMesg || this.defaultCommandErrorMesg);
      });

    // Set locale for formatting numeric and date-time values (from browser setting)
    this.snapInVm.setLocale(this.translateService.getBrowserLang());

    // this.preselectService = <PropertyPreselectService><any>this.messageBroker.getPreselectionService(this.fullId);
    // this.propertyStorageService = (this.messageBroker.getStorageService(this.fullId) as PropertyStorageService);

    this.translateService.get('FILTER-PLACEHOLDER').subscribe(s => this.placeholderFilter = s);
    this.translateService.get('ADVANCED-SWITCH-LABEL').subscribe(s => this.labelAdvancedSwitch = s);
    this.translateService.get('BASIC-SWITCH-LABEL').subscribe(s => this.labelBasicSwitch = s);
    this.translateService.get('SAVE-ERROR-TITLE').subscribe(s => this.saveErrorTitle = s);
    this.translateService.get('COMMAND-ERROR-TITLE').subscribe(s => this.commandErrorTitle = s);
    this.translateService.get('COMMAND-ERROR-MESSAGE-DEFAULT').subscribe(s => this.defaultCommandErrorMesg = s);
    this.translateService.get('NO-PROPERTIES').subscribe(s => this.noPropertiesText = s);
    this.translateService.get('DIFFERENT-PROPERTY-TYPES').subscribe(s => this.differentPropertyTypesText = s);

    this.messageSubscription = this.messageBroker.getMessage(this.fullId).subscribe(
      (m => {
        if (m != null) {
          this.traceService.debug(TraceModules.pvc, 'message arrived.', m);
          this.receiveMessage(m);
        }
      })
    );

    this.snapInVm.loading.subscribe(loading => {
      this.isLoading = loading;
      if (this.isLoading) {
        // Delay showing the spinner until we have been waiting a short period of time.
        // This avoids the spinner "blinking" quickly in/out of view on every selection no
        // matter how quickly the new context loads.
        setTimeout(() => this.isLoadingSpinnerEnabled = this.isLoading, 800);
      } else {
        this.isLoadingSpinnerEnabled = false;
      }
    });

    this.traceService.debug(TraceModules.pvc, 'Component initialized.');
  }

  public ngAfterContentInit(): void {
    this.paneWidth = 0;
    if (this.hostElement?.nativeElement) {
      this.paneWidth = this.hostElement.nativeElement.clientWidth || 0;
    }
    this.subscribeContainerWidthChanges();
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => { if (subscription != null) { subscription.unsubscribe(); } });
    if (this.resizeSubscription) {
      this.resizeSubscription.unsubscribe();
      this.resizeSubscription = undefined;
    }

    if (this.messageSubscription !== undefined) {
      this.messageSubscription.unsubscribe();
      this.messageSubscription = undefined;
    }

    if (this.snapInVm) {
      // Note: do not unregister the VM or the snap-in component will lose all context when it is re-created.
      this.snapInVm.deactivate(); // will result in value subscriptions being closed
      this.snapInVm = undefined;
    }

    if (this.traceService) {
      this.traceService.debug(TraceModules.pvc, 'Component destroyed.');
    }
  }

  public onBeforeAttach(): void {
    super.onBeforeAttach();
    this.restoreScrollOnAttach(this.propContainer);
    this.subscribeContainerWidthChanges();
  }

  public receiveMessage(messageBody: GmsMessageData): boolean {
    // 0 = explicit selection type of None. Used when alarms are reset for example.
    if (messageBody && messageBody.selectionType === 0) {
      this.snapInVm.setContext(undefined);
      return;
    }
    if (messageBody?.data?.length > 0) {
      this.snapInVm.setContext(messageBody.data);
    }
  }

  public togglePropertyList(): void {
    if (this.snapInVm) {
      this.snapInVm.showPropertyListExt = !this.snapInVm.showPropertyListExt;
    }
  }

  public onOpenStateChanged(open: boolean): void {
    setTimeout(() => this.isMemoOpen = !!open, 0);
  }

  public onMemoSaveError(errMesg: string): void {
    this.toastNotificationService.queueToastNotification('danger', this.saveErrorTitle, errMesg);
  }

  public onFilterChange(filter: string): void {
    if (this.snapInVm.detailProperty) {
      this.snapInVm.detailProperty.objectFilter = filter;
    } else {
      this.snapInVm.propertyFilter = filter;
    }
  }

  private restoreScrollOnAttach(el: ElementRef): void {
    if (!el || !el.nativeElement) {
      return;
    }
    // Get vertical scroll position just prior to it being reset by the attach operation
    const top: number = el.nativeElement.scrollTop || 0;
    if (top > 0) {
      // Schedule scroll position to be restored after attach and just prior to view rendering
      animationFrameScheduler.schedule(() => {
        el.nativeElement.scrollTop = top;
      });
    }
  }

  private subscribeContainerWidthChanges(): void {
    if (this.hostElement?.nativeElement) {
      // Detach any previously established subscriptions on this element
      if (this.resizeSubscription) {
        this.resizeSubscription.unsubscribe();
        this.resizeSubscription = undefined;
      }
      // Subscribe for size changes on this host element
      this.resizeSubscription = this.resizeObserverService.observe(this.hostElement.nativeElement, 100, true, true)
        .subscribe(dim => {
          if (dim && !isNaN(dim.width)) {
            this.paneWidth = dim.width;
          }
        });
    }
  }

}
