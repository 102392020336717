import { NgClass, NgTemplateOutlet } from '@angular/common';
import {
  booleanAttribute,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {
  BOOTSTRAP_BREAKPOINTS,
  ElementDimensions,
  ResizeObserverService
} from '@simpl/element-ng/resize-observer';
import { SiSplitComponent, SiSplitPartComponent } from '@simpl/element-ng/split';
import { SiTranslateModule } from '@simpl/element-translate-ng/translate';
import { Subscription } from 'rxjs';

@Component({
  selector: 'si-main-detail-container',
  templateUrl: './si-main-detail-container.component.html',
  styleUrl: './si-main-detail-container.component.scss',
  host: { class: 'si-layout-inner' },
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, NgTemplateOutlet, SiSplitComponent, SiSplitPartComponent, SiTranslateModule]
})
export class SiMainDetailContainerComponent implements OnInit, OnChanges, OnDestroy {
  private animationDuration = 500;
  private resizeSubs?: Subscription;
  private elementRef = inject(ElementRef);
  private resizeObserver = inject(ResizeObserverService);
  private changeDetectorRef = inject(ChangeDetectorRef);

  /**
   * A numeric value defining the minimum width in px, which the container needs
   * to be displayed in its large layout. Whenever smaller than
   * this threshold, the small layout will be used. Default is
   * value is BOOTSTRAP_BREAKPOINTS.mdMinimum.
   */
  @Input() largeLayoutBreakpoint = BOOTSTRAP_BREAKPOINTS.mdMinimum;

  /**
   * Whether the main-detail layout component has a large size or not,
   * `true` if the container´s width matches or exceeds the `largeLayoutBreakpoint`.
   */
  hasLargeSize!: boolean;

  /**
   * Emits whether the components size is large enough to display
   * main and details views next to each other or not.
   */
  @Output() readonly hasLargeSizeChange = new EventEmitter<boolean>();

  /**
   * Whether the details are currently active or not, mostly relevant in the
   * responsive scenario when the viewport only shows either the main or the detail.
   *
   * @defaultValue false
   */
  @Input({ transform: booleanAttribute }) detailsActive = false;

  /**
   * Emits the new value of `detailsActive` whenever its value changes.
   */
  @Output() readonly detailsActiveChange = new EventEmitter<boolean>();

  /**
   * The heading of the main-detail layout component, usually a page heading.
   *
   * @defaultValue ''
   */
  @Input() heading = '';

  /**
   * Whether the heading should be truncated (single line) or not.
   * Default value is `false`.
   *
   * @defaultValue false
   */
  @Input({ transform: booleanAttribute }) truncateHeading = false;

  /**
   * The heading of the detail area.
   *
   * @defaultValue ''
   */
  @Input() detailsHeading = '';

  /**
   * Whether the main and detail parts should be resizable by a splitter or not.
   * This is only supported in the 'large' scenario (when `hasLargeSize` is `true`).
   * Default value is `false`.
   *
   * @defaultValue false
   */
  @Input({ transform: booleanAttribute }) resizableParts = false;

  /**
   * You can hide the back button in the mobile view by setting true. Required
   * in add, edit workflows on mobile sizes. During add or edit, the back button
   * should be hidden. Default value is `false`.
   *
   * @defaultValue false
   */
  @Input({ transform: booleanAttribute }) hideBackButton = false;

  /**
   * Details back button text. Required for a11y.
   *
   * @defaultValue
   * ```
   * $localize`:@@SI_MAIN_DETAIL_CONTAINER.BACK:Back`
   * ```
   */
  @Input() detailsBackButtonText = $localize`:@@SI_MAIN_DETAIL_CONTAINER.BACK:Back`;

  /**
   * CSS class(es) applied to the outermost container. Per default, Bootstrap classes
   * to handle responsive paddings are applied: `px-6 pt-6 px-md-9`.
   *
   * @defaultValue 'px-6 pt-6 px-md-9'
   */
  @Input() containerClass = 'px-6 pt-6 px-md-9';

  /**
   * CSS class(es) applied to the main container. In combination with `containerClass`,
   * this allows for settings individual padding and margin values on the individual containers.
   *
   * @defaultValue 'pb-6 pb-md-9'
   */
  @Input() mainContainerClass = 'pb-6 pb-md-9';

  /**
   * CSS class(es) applied to the detail container. In combination with `containerClass`,
   * this allows for settings individual padding and margin values on the individual containers.
   * Default value: `pb-7 pb-md-9`
   *
   * @defaultValue 'pb-6 pb-md-9'
   */
  @Input() detailContainerClass = 'pb-6 pb-md-9';

  /**
   * The percentage width of the main container from the overall component width.
   * Can be a number or `'default'`, which is 32% when {@link resizableParts} is active, otherwise 50%.
   *
   * @defaultValue 'default'
   */
  @Input() mainContainerWidth: number | 'default' = 'default';
  /**
   * Emits on split changes the new percentage width of the main container from
   * the overall container width.
   */
  @Output() readonly mainContainerWidthChange = new EventEmitter<number>();
  /**
   * Sets the minimal width of the main container in pixel.
   *
   * @defaultValue 300
   */
  @Input() minMainSize = 300;
  /**
   * Sets the minimal width of the detail container in pixel.
   *
   * @defaultValue 300
   */
  @Input() minDetailSize = 300;
  /**
   * An optional stateId to uniquely identify a component instance.
   * Required for persistence of ui state.
   */
  @Input() stateId?: string;

  /**
   * The attribute is set to true when the detail area is not visible to ensure that the user
   * can't tab to details area when it is hidden.
   */
  protected preventFocusDetails = false;

  private get actualMainContainerWidth(): number {
    return this.mainContainerWidth === 'default'
      ? this.resizableParts
        ? 32
        : 50
      : this.mainContainerWidth;
  }

  protected splitSizes: [number, number] = [
    this.actualMainContainerWidth,
    100 - this.actualMainContainerWidth
  ];
  // The max size to limit the main container in the static flex layout (if less than 50%), otherwise not set.
  protected maxMainSize: string = this.getMaxSize(0);
  // The max size to limit the detail container in the static flex layout (if less than 50%), otherwise not set.
  protected maxDetailSize: string = this.getMaxSize(1);

  protected get mainStateId(): string | undefined {
    return this.stateId ? `${this.stateId}-main` : undefined;
  }

  protected get detailStateId(): string | undefined {
    return this.stateId ? `${this.stateId}-detail` : undefined;
  }

  @HostBinding('class.animate') protected animate = false;

  @HostBinding('style.opacity') protected opacity = '0';

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.detailsActive) {
      this.updateDetailsFocusable();
      this.doAnimation(changes.detailsActive.currentValue);
    }
    if (changes.mainContainerWidth || changes.resizableParts) {
      this.splitSizes = [this.actualMainContainerWidth, 100 - this.actualMainContainerWidth];
      this.maxMainSize = this.getMaxSize(0);
      this.maxDetailSize = this.getMaxSize(1);
    }
  }

  ngOnInit(): void {
    this.resizeSubs = this.resizeObserver
      .observe(this.elementRef.nativeElement, 100, true)
      .subscribe(dimensions => this.determineLayout(dimensions));
  }

  ngOnDestroy(): void {
    this.resizeSubs?.unsubscribe();
  }

  protected onSplitSizesChange(sizes: number[]): void {
    this.mainContainerWidth = sizes[0];
    this.mainContainerWidthChange.emit(this.actualMainContainerWidth);
  }

  protected detailsBackClicked(): void {
    this.detailsActive = false;
    this.doAnimation(false);
  }

  /**
   * Get the max size to limit in the static flex layout (if less than 50%), otherwise not set
   */
  private getMaxSize(part: 0 | 1): string {
    return this.resizableParts ||
      this.mainContainerWidth === 'default' ||
      !this.hasLargeSize ||
      this.splitSizes[part] > 50
      ? ''
      : this.splitSizes[part] + '%';
  }

  private determineLayout(dimensions: ElementDimensions): void {
    const newHasLargeSize = dimensions.width >= this.largeLayoutBreakpoint;
    if (this.hasLargeSize !== newHasLargeSize) {
      this.hasLargeSize = newHasLargeSize;
      this.maxMainSize = this.getMaxSize(0);
      this.maxDetailSize = this.getMaxSize(0);
      this.updateDetailsFocusable();
      this.hasLargeSizeChange.emit(this.hasLargeSize);
      this.changeDetectorRef.markForCheck();
    }
    if (this.opacity === '0') {
      this.opacity = '';
      this.changeDetectorRef.markForCheck();
    }
  }

  private doAnimation(detailsActive: boolean): void {
    this.animate = true;
    setTimeout(() => {
      this.animate = false;
      this.changeDetectorRef.markForCheck();
    }, this.animationDuration);
    this.detailsActiveChange.emit(detailsActive);
  }

  private updateDetailsFocusable(): void {
    this.preventFocusDetails = !this.hasLargeSize && !this.detailsActive;
  }
}
