import { Component } from '@angular/core';
import { FieldType, FormlyModule } from '@ngx-formly/core';
import { SiTabComponent, SiTabsetComponent } from '@simpl/element-ng/tabs';

@Component({
  selector: 'si-formly-object-tabset',
  templateUrl: './si-formly-object-tabset.component.html',
  standalone: true,
  imports: [SiTabsetComponent, SiTabComponent, FormlyModule]
})
export class SiFormlyObjectTabsetComponent extends FieldType {
  protected tabIndexChange(selectedTab: number): void {
    if (this.options?.formState) {
      this.options.formState.selectedTabIndex = selectedTab;
    }
  }
}
