import {
  booleanAttribute,
  Component,
  EventEmitter,
  HostBinding,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  TemplateRef
} from '@angular/core';
import { clone, DateFormat, TimeFormat } from '@simpl/buildings-ng/common';
import { SiResponsiveContainerDirective } from '@simpl/element-ng';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

import {
  BulkPropertyState,
  SiBulkPropertyListComponent
} from './internal/si-bulk-property-list.component';
import {
  BulkProperty,
  BulkPropertyValues,
  CommandEvent,
  Property,
  ValueState
} from '../../interfaces/property';
import { SiPropertyConfig } from '../../services/si-property-config.service';
import { SiPropertyService } from '../../services/si-property.service';
import { PropertyDisplayStyle, PropertyTemplateContext } from '../si-property/si-property.model';
import { SiPropertyListComponent } from '../si-property-list/si-property-list.component';

@Component({
  selector: 'si-property-viewer',
  templateUrl: './si-property-viewer.component.html',
  styleUrl: './si-property-viewer.component.scss',
  standalone: true,
  imports: [SiBulkPropertyListComponent, SiPropertyListComponent, SiResponsiveContainerDirective],
  providers: [SiPropertyService]
})
export class SiPropertyViewerComponent implements OnDestroy, OnChanges {
  private subscription?: Subscription;
  private bulkValSubs?: Subscription;
  private singleObjectId?: string;

  private propertyService = inject(SiPropertyService);
  private configService = inject(SiPropertyConfig);

  @Input({ required: true }) objectId!: string | string[];
  @Input() filter?: string;
  /**
   * Way the properties should be displayed.
   * Nested collections are currently only supported in `"table"` view.
   *
   * @defaultValue 'list'
   */
  @Input() displayStyle: PropertyDisplayStyle = 'list';
  /**
   * Custom template for supporting custom property value types in {@link PropertyApi}.
   * This template will be used if it is not part of the built-in supported types: {@link AnyPropertyValueType} and `undefined` ({@link DefaultProperty}).
   * You may use the {@link SiPropertyComponent} to display sub properties.
   */
  @Input() customTemplate?: TemplateRef<PropertyTemplateContext>;

  /**
   * Hides the value state indicator next to the property value.
   * It will no longer be updated when a write or command begins or succeeds/fails.
   * This is especially useful if the values are just written to a database during
   * engineering where they take immediate effect and no runtime issues arise.
   *
   * @defaultValue false
   */
  @Input({ transform: booleanAttribute }) hideValueState = false;

  @Output() readonly filtered = new EventEmitter<number>(true);

  @HostBinding('class.property-viewer-table')
  protected get tableClass(): boolean {
    return this.displayStyle === 'table';
  }

  protected dateFormat: DateFormat = this.configService.get().dateFormat!;
  protected timeFormat: TimeFormat = this.configService.get().timeFormat!;
  protected allowValuesOutOfRange = !!this.configService.get().allowValuesOutOfRange;
  /** @internal */
  properties: Property[] = [];
  private originalPropertyValues: Record<string, Property['value']['value']> = {};
  protected valueState: ValueState[] = [];

  protected bulkMode: 'no' | 'select' | 'edit' = 'no';
  protected bulkProperties: BulkProperty[] = [];
  protected bulkObjectIds?: string[];
  protected bulkPropertyValues?: BulkPropertyValues;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.objectId && this.objectId) {
      this.bulkMode = Array.isArray(this.objectId) && this.objectId.length > 1 ? 'select' : 'no';
      if (this.bulkMode === 'no') {
        this.subscribeService();
      } else {
        this.subscribeBulkService();
      }
    }
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    this.bulkValSubs?.unsubscribe();
  }

  private subscribeService(): void {
    this.singleObjectId = this.objectId as string;
    this.bulkObjectIds = undefined;
    this.bulkProperties = [];

    this.subscription?.unsubscribe();
    this.subscription = this.propertyService
      .getProperties(this.singleObjectId!)
      .subscribe(properties => {
        this.properties = properties;
        this.originalPropertyValues = {};
        this.properties.forEach(prop => {
          if (prop.id && prop.value) {
            this.originalPropertyValues[prop.id] =
              typeof prop.value.value !== 'object' ? prop.value.value : clone(prop.value.value);
          }
        });
        this.valueState = new Array(properties.length).fill('none');
      });
  }

  private subscribeBulkService(): void {
    this.singleObjectId = undefined;
    this.bulkObjectIds = this.objectId as string[];
    this.properties = [];

    this.subscription?.unsubscribe();
    this.subscription = this.propertyService
      .getBulkProperties(this.bulkObjectIds!)
      .subscribe(properties => (this.bulkProperties = properties));
  }

  private setState(property: Property, state: ValueState): void {
    if (!this.properties || this.hideValueState) return;

    // Try to find object by reference or by ID.
    let index = this.properties.indexOf(property);
    if (index === -1 && property.id !== undefined) {
      index = this.properties.findIndex(p => p.id === property.id);
    }

    // Update state if the property is found.
    if (index >= 0) {
      this.valueState[index] = state;
    }
  }

  private getObjectId(prop: Property): string | undefined {
    if (this.bulkMode === 'no') {
      return this.singleObjectId;
    }

    const originalProp: Property = (prop as any)._original ?? prop;
    const objectProp = this.bulkPropertyValues!.objectValues.find(p => p.property === originalProp);
    return objectProp?.objectId;
  }

  /**
   * Checks if the property values are the same as the last update, only works when `id` is set.
   */
  private comparePropertyValues(property: Property): boolean {
    if (
      property.id &&
      (((typeof this.originalPropertyValues[property.id] !== 'object' ||
        typeof property.value?.value !== 'object') &&
        this.originalPropertyValues[property.id] === property.value?.value) ||
        JSON.stringify(this.originalPropertyValues[property.id]) ===
          JSON.stringify(property.value?.value))
    ) {
      return true;
    }
    return false;
  }

  protected submit(property: Property): void {
    const objectId = this.getObjectId(property);
    if (objectId === undefined) {
      return;
    }

    const originalProp: Property = (property as any)._original ?? property;

    if (this.comparePropertyValues(property)) {
      return;
    }

    this.setState(originalProp, 'loading');
    this.propertyService
      .writeProperty(objectId, property)
      .pipe(first())
      .subscribe({
        next: () => this.setState(originalProp, 'passed'),
        error: () => this.setState(originalProp, 'failed')
      });
  }

  protected command(event: CommandEvent): void {
    const objectId = this.getObjectId(event.property);
    if (objectId === undefined) {
      return;
    }

    this.setState(event.property, 'loading');
    this.propertyService
      .executeCommand(objectId, event)
      .pipe(first())
      .subscribe({
        next: () => this.setState(event.property, 'passed'),
        error: () => this.setState(event.property, 'failed')
      });
  }

  protected bulkPropertySelected(prop: BulkProperty): void {
    this.bulkMode = 'edit';
    this.bulkValSubs?.unsubscribe();
    this.bulkPropertyValues = undefined;
    this.properties = [];
    this.valueState = new Array(this.bulkObjectIds!.length).fill('none');
    this.bulkValSubs = this.propertyService
      .getBulkPropertyValues(this.bulkObjectIds!, prop.id)
      .subscribe(bulkValues => {
        this.bulkPropertyValues = bulkValues;
        this.properties = bulkValues.objectValues.map(p => p.property);
      });
  }

  protected bulkPropertyBack(): void {
    this.bulkPropertyValues = undefined;
    this.bulkMode = 'select';
    this.bulkValSubs?.unsubscribe();
  }

  /** @internal */
  setBulkPropertyState(state: BulkPropertyState): void {
    const bulkPropVal = this.bulkPropertyValues?.objectValues.find(
      p => p.objectId === state.objectId
    );
    if (bulkPropVal) {
      this.setState(bulkPropVal.property, state.state);
    }
  }
}
