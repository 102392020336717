import { Injectable } from '@angular/core';
import { GmsManagedTypes, TextEntry } from '@gms-flex/services';
import { LocationEntityType } from 'src/app/bx-services/location/location-proxy.model';
import { PointType } from 'src/app/bx-services/point/point-proxy.model';

import { EquipmentType } from '../../bx-services/device/equipment-type.model';
import { DeviceSubtype, EntityType, EquipmentTypeEn, FolderType } from '../../bx-services/shared/base.model';
import { unassignedTypeSubType } from '../system-browser/system-browser-mapper-bx-to-gms.service';

@Injectable({
  providedIn: 'root'
})
export class ObjectTypeMapperService {

  private readonly entityTypeToDccId: Map<EntityType, number> = new Map<EntityType, number>();
  private readonly entitySubTypeNameToDccIdPerEntityType: Map<EntityType, Map<string, number>> = new Map<EntityType, Map<string, number>>();
  private readonly subTypeDccIddToEquipmentType: Map<number, EquipmentType> = new Map<number, EquipmentType>();
  private readonly equipmentTypeIdToEquipmentType: Map<string, EquipmentType> = new Map<string, EquipmentType>();
  private readonly typesNested: TextEntry[] = [];
  private equipmentTypeNextId = 0;
  private textEntryEquipment: TextEntry;
  private readonly equipmentTypesWithIconDefined: Map<string, number> = new Map<string, number>();

  constructor() {
    this.initTypeCustomer();
    this.initTypePartition();
    this.initTypeLocation();
    this.initTypeDevice();
    this.initTypeFolder();
    this.initTypeTvd();
    this.initTypeSchedule();
    this.initTypeCalendar();
    this.initTypePoint();
    this.initTypeEquipment();
    this.sortTextEntries(this.typesNested);
  }

  public initWithTypesFromEquipmentService(equipmentTypes: EquipmentType[][]): void {
    const entitySubTypeNameToDccId = this.entitySubTypeNameToDccIdPerEntityType.get(EntityType.Equipment);
    equipmentTypes.forEach(equipments => {
      equipments.forEach(equipment => {
        if (!entitySubTypeNameToDccId.has(equipment.name)) {
          // only add when not yet in the map
          if (this.equipmentTypesWithIconDefined.has(equipment.name)) {
            // add equipment type with icon
            entitySubTypeNameToDccId.set(equipment.name, this.equipmentTypesWithIconDefined.get(equipment.name));
            this.subTypeDccIddToEquipmentType.set(this.equipmentTypesWithIconDefined.get(equipment.name), equipment);
            this.equipmentTypeIdToEquipmentType.set(equipment.id, equipment);
            this.textEntryEquipment.subText.push(new TextEntry(this.equipmentTypesWithIconDefined.get(equipment.name), equipment.name));
          } else {
            // add equipment type without icon
            entitySubTypeNameToDccId.set(equipment.name, this.equipmentTypeNextId);
            this.subTypeDccIddToEquipmentType.set(this.equipmentTypeNextId, equipment);
            this.equipmentTypeIdToEquipmentType.set(equipment.id, equipment);
            this.textEntryEquipment.subText.push(new TextEntry(this.equipmentTypeNextId++, equipment.name));
          }
        } else {
          // only update the equipment type id
          const dccId = entitySubTypeNameToDccId.get(equipment.name);
          this.subTypeDccIddToEquipmentType.set(dccId, equipment);
          this.equipmentTypeIdToEquipmentType.set(equipment.id, equipment);
        }
      });
    });

    this.sortTextEntries(this.textEntryEquipment.subText);
  }

  public dccSubTypeIdToEquipmentType(id: number): EquipmentType | undefined {
    return this.subTypeDccIddToEquipmentType.get(id);
  }

  public equipmentTypeIdForEquipmentType(id: string): EquipmentType | undefined {
    return this.equipmentTypeIdToEquipmentType.get(id);
  }

  public entityTypeToDccTypeId(entityType: EntityType): number {
    return this.entityTypeToDccId.get(entityType);
  }

  public entityTypeToTypeDescriptor(entityType: EntityType): string {
    // TODO: localize
    return entityType;
  }

  public entityTypeToManagedTypeId(type: EntityType, subtype: string): number {
    // TODO: clarify the mapping concept of managed types!!!!

    if ((type === EntityType.Folder) && (subtype === EntityType.TrendViewDefinition)) {
      return GmsManagedTypes.TRENDVIEWDEFINITION.id;
    } else if ((type === EntityType.Folder) && (subtype === FolderType.Graphic)) {
      return GmsManagedTypes.PROJECT_GRAPHIC_ROOT_FOLDER.id;
    } else {
      return this.entityTypeToDccId.get(type);
    }
  }

  public entityTypeToManagedType(type: string, subtype: string): string {
    // TODO: clarify the mapping concept of managed types!!!!

    if ((type === EntityType.Folder) && (subtype === FolderType.Graphic)) {
      return GmsManagedTypes.PROJECT_GRAPHIC_ROOT_FOLDER.name;
    } else {
      return type;
    }
  }

  public entitySubTypeToDccSubTypeId(entityType: EntityType, entitySubType: string): number | undefined {
    if (entitySubType && entityType && this.entitySubTypeNameToDccIdPerEntityType.has(entityType)) {
      return this.entitySubTypeNameToDccIdPerEntityType.get(entityType).get(entitySubType) ?? unassignedTypeSubType.id;
    } else {
      return unassignedTypeSubType.id;
    }
  }

  public entitySubTypeToSubTypeDescriptor(_entityType: EntityType, entitySubType: string): string {
    // TODO: localize
    return entitySubType ? entitySubType : unassignedTypeSubType.descriptor;
  }

  public getEntityType(typeId: number): string | undefined {
    let keyFound;
    this.entityTypeToDccId.forEach((value, key) => {
      if (value === typeId) {
        keyFound = key;
      }
    });
    return keyFound;
  }

  public getEntitySubType(typeId: number, subTypeId: number): string | undefined {
    let keyFound: EntityType;
    let subTypeMap: Map<string, number>;
    this.entityTypeToDccId.forEach((value, key) => {
      if (value === typeId) {
        keyFound = key;
      }
    });
    if (keyFound) {
      subTypeMap = this.entitySubTypeNameToDccIdPerEntityType.get(keyFound);
    }

    let keySubTypeFound: string;
    subTypeMap?.forEach((value, key) => {
      if (value === subTypeId) {
        keySubTypeFound = key;
      }
    });
    return keySubTypeFound;
  }

  public checkIfPoint(typeId: number): boolean {
    return this.getEntityType(typeId) === EntityType.Point;
  }

  public checkIfGateway(typeId: number, subTypeId: number): boolean {
    return this.getEntitySubType(typeId, subTypeId) === DeviceSubtype.Gateway;
  }

  public get textEntriesNested(): TextEntry[] {
    return this.typesNested;
  }

  private initTypeCustomer(): void {
    this.entityTypeToDccId.set(EntityType.Customer, 10000);
    this.typesNested.push(new TextEntry(this.entityTypeToDccId.get(EntityType.Customer), EntityType.Customer, []));
  }

  private initTypePartition(): void {
    this.entityTypeToDccId.set(EntityType.Partition, 10010);
    this.typesNested.push(new TextEntry(this.entityTypeToDccId.get(EntityType.Partition), EntityType.Partition, []));
  }

  private initTypeLocation(): void {
    this.entityTypeToDccId.set(EntityType.Location, 10020);
    const textEntry = new TextEntry(this.entityTypeToDccId.get(EntityType.Location), EntityType.Location, []);

    this.entitySubTypeNameToDccIdPerEntityType.set(EntityType.Location, new Map<string, number>());
    const entitySubTypeNameToDccId = this.entitySubTypeNameToDccIdPerEntityType.get(EntityType.Location);

    entitySubTypeNameToDccId.set(LocationEntityType.Campus, 10021);
    textEntry.subText.push(new TextEntry(entitySubTypeNameToDccId.get(LocationEntityType.Campus), LocationEntityType.Campus, []));
    entitySubTypeNameToDccId.set(LocationEntityType.CampusPart, 10022);
    textEntry.subText.push(new TextEntry(entitySubTypeNameToDccId.get(LocationEntityType.CampusPart), LocationEntityType.CampusPart, []));
    entitySubTypeNameToDccId.set(LocationEntityType.Building, 10023);
    textEntry.subText.push(new TextEntry(entitySubTypeNameToDccId.get(LocationEntityType.Building), LocationEntityType.Building, []));
    entitySubTypeNameToDccId.set(LocationEntityType.BuildingPart, 10024);
    textEntry.subText.push(new TextEntry(entitySubTypeNameToDccId.get(LocationEntityType.BuildingPart), LocationEntityType.BuildingPart, []));
    entitySubTypeNameToDccId.set(LocationEntityType.Floor, 10025);
    textEntry.subText.push(new TextEntry(entitySubTypeNameToDccId.get(LocationEntityType.Floor), LocationEntityType.Floor, []));
    entitySubTypeNameToDccId.set(LocationEntityType.FloorArea, 10026);
    textEntry.subText.push(new TextEntry(entitySubTypeNameToDccId.get(LocationEntityType.FloorArea), LocationEntityType.FloorArea, []));
    entitySubTypeNameToDccId.set(LocationEntityType.MultifloorArea, 10027);
    textEntry.subText.push(new TextEntry(entitySubTypeNameToDccId.get(LocationEntityType.MultifloorArea), LocationEntityType.MultifloorArea, []));
    entitySubTypeNameToDccId.set(LocationEntityType.Room, 10028);
    textEntry.subText.push(new TextEntry(entitySubTypeNameToDccId.get(LocationEntityType.Room), LocationEntityType.Room, []));
    entitySubTypeNameToDccId.set(LocationEntityType.RoomSegment, 10029);
    textEntry.subText.push(new TextEntry(entitySubTypeNameToDccId.get(LocationEntityType.RoomSegment), LocationEntityType.RoomSegment, []));
    entitySubTypeNameToDccId.set(LocationEntityType.Outside, 10030);
    textEntry.subText.push(new TextEntry(entitySubTypeNameToDccId.get(LocationEntityType.Outside), LocationEntityType.Outside, []));
    this.typesNested.push(textEntry);
    this.sortTextEntries(textEntry.subText);
  }

  private initTypeDevice(): void {
    this.entityTypeToDccId.set(EntityType.Device, 10120);
    const textEntry = new TextEntry(this.entityTypeToDccId.get(EntityType.Device), EntityType.Device, []);

    this.entitySubTypeNameToDccIdPerEntityType.set(EntityType.Device, new Map<string, number>());
    const entitySubTypeNameToDccId = this.entitySubTypeNameToDccIdPerEntityType.get(EntityType.Device);

    entitySubTypeNameToDccId.set(DeviceSubtype.Gateway, 10121);
    textEntry.subText.push(new TextEntry(entitySubTypeNameToDccId.get(DeviceSubtype.Gateway), DeviceSubtype.Gateway));
    this.typesNested.push(textEntry);
  }

  private initTypeFolder(): void {
    this.entityTypeToDccId.set(EntityType.Folder, 10130);
    this.typesNested.push(new TextEntry(this.entityTypeToDccId.get(EntityType.Folder), EntityType.Folder, []));

  }

  private initTypeTvd(): void {
    this.entityTypeToDccId.set(EntityType.TrendViewDefinition, 10131);
    this.typesNested.push(new TextEntry(this.entityTypeToDccId.get(EntityType.TrendViewDefinition), EntityType.TrendViewDefinition, []));
  }

  private initTypeSchedule(): void {
    this.entityTypeToDccId.set(EntityType.Schedule, 10132);
    this.typesNested.push(new TextEntry(this.entityTypeToDccId.get(EntityType.Schedule), EntityType.Schedule, []));
  }

  private initTypeCalendar(): void {
    this.entityTypeToDccId.set(EntityType.Calendar, 10133);
    this.typesNested.push(new TextEntry(this.entityTypeToDccId.get(EntityType.Calendar), EntityType.Calendar, []));
  }

  private initTypePoint(): void {
    this.entityTypeToDccId.set(EntityType.Point, 10200);
    const textEntry = new TextEntry(this.entityTypeToDccId.get(EntityType.Point), EntityType.Point, []);

    this.entitySubTypeNameToDccIdPerEntityType.set(EntityType.Point, new Map<string, number>());
    const entitySubTypeNameToDccId = this.entitySubTypeNameToDccIdPerEntityType.get(EntityType.Point);

    entitySubTypeNameToDccId.set(PointType.Sensor, 10201);
    textEntry.subText.push(new TextEntry(entitySubTypeNameToDccId.get(PointType.Sensor), PointType.Sensor));
    entitySubTypeNameToDccId.set(PointType.Cmd, 10202);
    textEntry.subText.push(new TextEntry(entitySubTypeNameToDccId.get(PointType.Cmd), PointType.Cmd));
    entitySubTypeNameToDccId.set(PointType.SP, 10203);
    textEntry.subText.push(new TextEntry(entitySubTypeNameToDccId.get(PointType.SP), PointType.SP));
    this.typesNested.push(textEntry);
    this.sortTextEntries(textEntry.subText);
  }

  private initTypeEquipment(): void {
    this.equipmentTypesWithIconDefined.set(EquipmentTypeEn.Fan, 10301);
    this.equipmentTypesWithIconDefined.set(EquipmentTypeEn.HeatPump, 10302);
    this.equipmentTypesWithIconDefined.set(EquipmentTypeEn.Meter, 10303);
    this.equipmentTypesWithIconDefined.set(EquipmentTypeEn.AirHandlingUnit, 10304);
    this.equipmentTypesWithIconDefined.set(EquipmentTypeEn.AirHandlingUnitVAV, 10305);
    this.equipmentTypesWithIconDefined.set(EquipmentTypeEn.AirHandlingUnitCAV, 10306);
    this.equipmentTypesWithIconDefined.set(EquipmentTypeEn.AirHandlingUnitSingleZone, 10307);
    this.equipmentTypesWithIconDefined.set(EquipmentTypeEn.AirHandlingUnitMultiZone, 10308);
    this.equipmentTypesWithIconDefined.set(EquipmentTypeEn.Valve, 10309);

    this.entityTypeToDccId.set(EntityType.Equipment, 10300);
    this.textEntryEquipment = new TextEntry(this.entityTypeToDccId.get(EntityType.Equipment), EntityType.Equipment, []);
    this.entitySubTypeNameToDccIdPerEntityType.set(EntityType.Equipment, new Map<string, number>());

    this.equipmentTypeNextId = 10310;
    this.typesNested.push(this.textEntryEquipment);
    this.sortTextEntries(this.textEntryEquipment.subText);
  }

  private sortTextEntries(textEntries: TextEntry[]): void {
    textEntries.sort((obj1, obj2) => {
      if (obj1.text < obj2.text) {
        return -1;
      } else if (obj1.text > obj2.text) {
        return 1;
      }
      return 0;
    });
  }
}
