import { Injectable } from '@angular/core';
import { BrowserObject, DpIdentifier, PropertyCommand, ReadCommandServiceBase, SystemBrowserServiceBase, TraceModules } from '@gms-flex/services';
import { TraceService } from '@gms-flex/services-common';
import { concatMap, map, Observable, of, throwError, zip } from 'rxjs';

import { PointService } from '../../bx-services/point/point.service';
import { ObjectTypeMapperService } from '../shared/object-type-mapper.service';
import { SystemBrowserBxSubstituteService } from '../system-browser/system-browser-bx-substitute.service';
import { CommandMapperBxToGmsService } from './command-mapper-bx-to-gms.service';

@Injectable()
export class ReadCommandBxSubstituteService extends ReadCommandServiceBase {

  public constructor(private readonly traceService: TraceService,
    private readonly systemBrowser: SystemBrowserServiceBase,
    private readonly objectTypeMapper: ObjectTypeMapperService,
    private readonly pointService: PointService,
    private readonly commandMapper: CommandMapperBxToGmsService) {
    super();
    this.traceService.info(TraceModules.command, 'ReadCommandBxSubstituteService created.');
  }

  /**
   * Reads the list of available commands for a single propertyId.
   */
  public readPropertyCommand(
    propertyId: string,
    commandId?: string,
    enabledCommandsOnly = false,
    clientType?: string): Observable<PropertyCommand> {
    if (propertyId === undefined) {
      return throwError(() => new Error(`ReadCommandBxSubstituteService.readPropertyCommand(): Invalid arguments, propertyId: ${propertyId}`));
    }
    if (enabledCommandsOnly) {
      this.traceService.warn(TraceModules.command,
        `ReadCommandBxSubstituteService.readPropertyCommand() called with an invalid option:
          enabledCommandOnly: ${enabledCommandsOnly}; propertyId: ${propertyId}`);
    }
    this.traceService.debug(TraceModules.command, `ReadCommandBxSubstituteService.readPropertyCommand() called; propertyId: ${propertyId}`);

    /* eslint-disable @typescript-eslint/naming-convention */
    const command: PropertyCommand = {
      PropertyId: propertyId,
      ErrorCode: 0,
      SubscriptionKey: null,
      Commands: []
    };
    /* eslint-enable @typescript-eslint/naming-convention */
    return of(command);
  }

  /**
   * Reads the list of available commands for multiple propertyIds.
   */
  public readPropertyCommands(
    propertyIds: string[],
    commandId?: string,
    enabledCommandsOnly = false,
    clientType?: string,
    booleansAsNumericText?: boolean): Observable<PropertyCommand[]> {
    if (propertyIds === undefined) {
      return throwError(() => new Error(`ReadCommandBxSubstituteService.readPropertyCommands(): Invalid arguments, propertyId: ${propertyIds}`));
    }
    if (enabledCommandsOnly) {
      this.traceService.warn(TraceModules.command,
        `ReadCommandBxSubstituteService.readPropertyCommands() called with an invalid option:
          enabledCommandOnly: ${enabledCommandsOnly}; propertyIds: ${propertyIds.toString()}`);
    }
    this.traceService.debug(TraceModules.command, `ReadCommandBxSubstituteService.readPropertyCommands() called; propertyIds: ${propertyIds.toString()}`);

    const commands$ = propertyIds.map(propertyId => this.createCommand(propertyId));
    return zip(commands$);
  }

  private createCommand(objectOrPropertyId: string): Observable<PropertyCommand> {
    const dpId = new DpIdentifier(objectOrPropertyId);
    return (this.systemBrowser as SystemBrowserBxSubstituteService).resolveObjects(dpId.systemName, dpId.objectIdWoSystem).pipe(
      concatMap(page => {
        if (page.Total === 0) {
          const propCmd = this.commandMapper.createPropertyCommandEmpty(objectOrPropertyId, undefined);
          return of(propCmd);
        }
        if (this.isPoint2(page.Nodes[0])) {
          return this.pointService.getPointById(dpId.systemName, dpId.objectIdWoSystem).pipe(
            map(pointBx => {
              return this.commandMapper.createPropertyCommand(objectOrPropertyId, pointBx.attributes);
            })
          );
        } else {
          const propCmd = this.commandMapper.createPropertyCommandEmpty(objectOrPropertyId, undefined);
          return of(propCmd);
        }
      })

    );
  }

  private isPoint2(browserObject: BrowserObject): boolean {
    return this.objectTypeMapper.checkIfPoint(browserObject.Attributes.TypeId);
  }
}
