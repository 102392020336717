import { ModalRef } from '@simpl/element-ng';

const elementNotHidden = (el: HTMLElement): boolean =>
  !el.hasAttribute('disabled') && !el.getAttribute('aria-hidden') && !!el.offsetWidth;

const focusable = (el?: HTMLElement): boolean => {
  if (!el) {
    return false;
  }
  const ti = el.getAttribute('tabindex');
  return !!ti && ti !== '-1' && elementNotHidden(el);
};

export const getFocusableElements = (element: HTMLElement): HTMLElement[] => {
  return Array.from(
    element.querySelectorAll(
      'a[href], button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])'
    ) as NodeListOf<HTMLElement>
  ).filter(elementNotHidden);
};

/**
 * Focus a relevant element inside the given element.
 * @param element - The element to start searching from (if undefined nothing will happen).
 */
export const focusFocusable = (
  element?: HTMLElement,
  options?: { includeSelf?: boolean; preferFormElements?: boolean }
): HTMLElement | undefined => {
  if (!element) {
    return undefined;
  }
  const includeSelf = options?.includeSelf ?? false;
  const preferFormElements = options?.preferFormElements ?? true;

  let focusableElement: HTMLElement | undefined;
  if (includeSelf && focusable(element)) {
    focusableElement = element;
  }

  if (!focusableElement) {
    const focusableElements = getFocusableElements(element);
    focusableElement = focusableElements[0];
    if (focusableElements.length > 1 && preferFormElements) {
      const firstFocusableFormElement = focusableElements.find(searchElement =>
        ['INPUT', 'TEXTAREA', 'SELECT'].includes(searchElement.tagName)
      );
      if (firstFocusableFormElement) {
        focusableElement = firstFocusableFormElement;
      }
    }
  }

  focusableElement?.focus();
  return focusableElement;
};

export const focusDialogContent = (modalRef: ModalRef, byKeyboard: boolean): void => {
  modalRef.shown.subscribe(element => {
    const modalBody = (element.nativeElement as HTMLElement).querySelector<HTMLElement>(
      '.modal-body'
    )!;
    const firstElem = focusFocusable(modalBody, { includeSelf: !byKeyboard });
    if (!byKeyboard) {
      firstElem?.blur();
    }
  });
};
