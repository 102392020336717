import { FocusMonitor } from '@angular/cdk/a11y';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  DoCheck,
  ElementRef,
  HostBinding,
  inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { SiTranslateModule } from '@simpl/element-translate-ng/translate';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { SiDatepickerOverlayDirective } from './si-datepicker-overlay.directive';
import { SiDatepickerDirective } from './si-datepicker.directive';

/**
 * Calendar toggle button used in combination with a datepicker directive.
 *
 * @example
 * ```
 * <si-calendar-button class="w-100">
 *   <input
 *     class="form-control"
 *     type="text"
 *     siDatepicker
 *     [siDatepickerConfig]="config"
 *   />
 * </si-calendar-button>
 * ```
 */
@Component({
  selector: 'si-calendar-button',
  template: `<ng-content />
    <button
      #calendarButton
      name="open-calendar"
      type="button"
      class="btn btn-circle btn-tertiary btn-xs element-calendar position-absolute end-0 top-0 me-2 mt-2"
      [attr.aria-label]="ariaLabel | translate"
      [disabled]="disabled"
      (click)="show()"
    >
    </button>`,
  styles: ':host {--si-action-icon-offset: 24px;}',
  host: {
    class: 'd-inline-block position-relative form-control-wrapper'
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SiTranslateModule]
})
export class SiCalendarButtonComponent implements OnInit, AfterContentInit, OnDestroy, DoCheck {
  /**
   * Aria label for the calendar toggle button.
   *
   * @defaultValue
   * ```
   * $localize`:@@SI_DATEPICKER.CALENDAR_TOGGLE_BUTTON:Open calendar`
   * ```
   */
  @Input()
  ariaLabel = $localize`:@@SI_DATEPICKER.CALENDAR_TOGGLE_BUTTON:Open calendar`;

  @ViewChild('calendarButton', { static: true }) protected button!: ElementRef<HTMLButtonElement>;
  /** Datepicker input directive instance used to watch for state changes and required to open the calendar. */
  @ContentChild(SiDatepickerDirective, { static: true })
  protected datepicker?: SiDatepickerDirective;

  @ContentChild(SiDatepickerOverlayDirective, { static: true })
  protected datepickerOverlay!: SiDatepickerOverlayDirective;

  @ContentChild(NgControl, { static: true }) private ngControl?: NgControl;
  protected disabled = false;

  private cdRef = inject(ChangeDetectorRef);
  private focusMonitor = inject(FocusMonitor);
  private elementRef = inject<ElementRef<HTMLElement>>(ElementRef);
  private destroyer = new Subject<void>();

  // Add classes here to enable error messages in si-form-item
  @HostBinding('class.ng-invalid')
  @HostBinding('class.ng-touched')
  private showValidationMessages?: boolean;

  ngOnInit(): void {
    // Monitor input state changes and update the button accordingly
    this.datepicker?.stateChange
      .pipe(takeUntil(this.destroyer))
      .subscribe(() => this.updateState());
    this.focusMonitor
      .monitor(this.elementRef, true)
      .pipe(takeUntil(this.destroyer))
      .subscribe(origin => {
        setTimeout(() => {
          if (origin === null && !this.datepickerOverlay.isShown()) {
            this.datepicker?.touch();
          }
        });
      });
  }

  ngDoCheck(): void {
    const showValidationMessages =
      this.ngControl?.control?.touched && this.ngControl?.control?.invalid;
    if (showValidationMessages !== this.showValidationMessages) {
      this.showValidationMessages = showValidationMessages;
      this.cdRef.markForCheck();
    }
  }

  ngAfterContentInit(): void {
    this.datepicker?.useExternalTrigger(this.button);
    this.updateState();
  }

  ngOnDestroy(): void {
    this.destroyer.next();
    this.destroyer.complete();
  }

  protected show(): void {
    this.datepicker?.show(true);
  }

  private updateState(): void {
    this.disabled = !!this.datepicker?.disabled || !!this.datepicker?.readonly;
    this.cdRef.markForCheck();
  }
}
