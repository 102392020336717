import { formatDate } from '@angular/common';
import {
  booleanAttribute,
  ChangeDetectorRef,
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  inject,
  Input,
  LOCALE_ID,
  OnChanges,
  Output,
  Renderer2,
  SimpleChanges
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators
} from '@angular/forms';
import { SI_FORM_ITEM_CONTROL, SiFormItemControl } from '@simpl/element-ng/form';

import { compareDate, getMaxDate, getMinDate, isValid, parseDate } from './date-time-helper';
import { DatepickerInputConfig, DateRange, getDatepickerFormat } from './si-datepicker.model';

/**
 * Base directive for date input fields.
 */
@Directive({
  selector: '[siDateInput]',
  exportAs: 'siDateInput',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: SiDateInputDirective,
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: SiDateInputDirective,
      multi: true
    },
    {
      provide: SI_FORM_ITEM_CONTROL,
      useExisting: SiDateInputDirective
    }
  ],
  standalone: true
})
export class SiDateInputDirective
  implements ControlValueAccessor, OnChanges, Validator, SiFormItemControl
{
  private static idCounter = 0;

  @HostBinding('id') @Input() readonly id = `si-date-input-${SiDateInputDirective.idCounter++}`;

  /**
   * Configuration object for the datepicker.
   *
   * @defaultValue
   * ```
   * {}
   * ```
   */
  @Input() siDatepickerConfig?: DatepickerInputConfig = {};
  @Input() dateRange?: DateRange;
  /**
   * Indicate whether the input represent the start or the end of a range.
   */
  @Input() rangeType?: 'START' | 'END';
  /**
   * @deprecated Property has no effect and will be removed without a replacement.
   *
   * @defaultValue 200
   */
  @Input() dateInputDebounceTime = 200;
  /**
   * Emits an event to notify about disabling the time from the datepicker.
   * When time is disable, we construct a pure date object in UTC 00:00:00 time.
   */
  @Output() readonly siDatepickerDisabledTime = new EventEmitter<boolean>();
  /**
   * Emits an event on state changes e.g. readonly, disable, ... .
   */
  @Output() readonly stateChange = new EventEmitter<void>();

  private _disabled = false;
  get disabled(): boolean {
    return this._disabled;
  }
  /**
   * Whether the date range input is disabled.
   * @defaultValue false
   * @defaultref {@link _disabled}
   */
  @Input()
  set disabled(value: boolean) {
    this.setDisabledState(value !== false);
  }

  /**
   * Whether the date range input is readonly.
   * @defaultValue false
   * @defaultref {@link _readonly}
   */
  @Input({ transform: booleanAttribute })
  set readonly(value: boolean) {
    this._readonly = value;
    this.stateChange.next();
  }
  get readonly(): boolean {
    return this._readonly;
  }

  @HostBinding('attr.readonly') protected get readOnlyState(): boolean | null {
    return this._readonly ? true : null;
  }
  @HostBinding('class.readonly') private _readonly = false;

  /** @internal */
  validatorOnChange = (): void => {};
  /**
   * Date form input validator function, validating text format, min and max value.
   */
  protected validator = Validators.compose([
    () => this.formatValidator(),
    () => this.minValidator(),
    () => this.maxValidator()
  ])!;
  protected date?: Date;
  /**
   * Emits a new `date` value on input field value changes.
   */
  protected readonly dateChange = new EventEmitter<Date | undefined>();
  protected onTouched: () => void = () => {};
  protected onModelChange: (value: any) => void = () => {};
  protected readonly cdRef = inject(ChangeDetectorRef);
  protected readonly locale = inject(LOCALE_ID).toString();
  private readonly elementRef = inject(ElementRef);
  private readonly renderer = inject(Renderer2);
  private format = '';

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.siDatepickerConfig && !changes.siDatepickerConfig.currentValue) {
      this.siDatepickerConfig = {};
    }
    if (changes.siDatepickerConfig) {
      // reflect possible change is date/time format
      const format = this.format;
      this.format = '';
      this.getFormat();
      const formatChanged = format !== this.format;
      if (this.date && formatChanged) {
        this.updateNativeValue();
      }
    }
  }

  validate(c: AbstractControl): ValidationErrors | null {
    return this.validator(c);
  }

  registerOnChange(fn: any): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  registerOnValidatorChange(fn: () => void): void {
    this.validatorOnChange = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this._disabled = isDisabled;
    this.renderer.setProperty(this.elementRef.nativeElement, 'disabled', isDisabled);
    this.stateChange.next();
    this.cdRef.markForCheck();
  }

  writeValue(value?: Date | string): void {
    // remove date when user input is empty
    let emptyString = false;
    // Flag to define invalid string
    let invalidDate = false;
    if (typeof value === 'string') {
      emptyString = value.trim().length === 0;
      value = parseDate(value, this.getFormat(), this.locale);
      invalidDate = !value;
    }

    this.date = value;
    this.dateChange.next(this.date);

    // We should not change the content of the input field when the user typed
    // a wrong input. Otherwise the typed content changes and the user cannot
    // correct the content.
    if (!invalidDate || emptyString) {
      this.updateNativeValue();
    }
    this.cdRef.markForCheck();
  }

  private updateNativeValue(): void {
    let dtStr = '';
    if (isValid(this.date)) {
      dtStr = formatDate(this.date, this.getFormat(), this.locale);
    }
    this.renderer.setProperty(this.elementRef.nativeElement, 'value', dtStr);
  }

  /**
   * Handles `input` events on the input element.
   * @param value - current input value.
   */
  @HostListener('input', ['$event.target.value'])
  protected onInput(value: string): void {
    const parsedDate = parseDate(value, this.getFormat(), this.locale);

    // Is same date
    const hasChanged = !(parsedDate === this.date);
    if (hasChanged) {
      this.date = parsedDate;
      this.onModelChange(this.date);
      this.dateChange.next(this.date);
    }
  }

  @HostListener('blur', ['$event']) protected onBlur(event: FocusEvent): void {
    this.onTouched();
  }

  private getFormat(): string {
    if (!this.format) {
      this.format = getDatepickerFormat(this.locale, this.siDatepickerConfig);
    }
    return this.format;
  }

  /**
   * Callback when the datepicker changes his value.
   * @param date - updated date
   */
  protected onDateChanged(date: Date): void {
    // update input element
    this.writeValue(date);
    // update the Forms ngModel
    this.onModelChange(this.date);
    this.cdRef.markForCheck();
  }

  /**
   * Datepicker consider time / ignore time changed.
   * @param disabledTime - disable time
   * @internal
   */
  onDisabledTime(disabledTime: boolean): void {
    this.format = '';
    this.siDatepickerConfig!.disabledTime = disabledTime;
    // Ensure the time format will be removed
    this.onDateChanged(this.date!);
    this.siDatepickerDisabledTime.next(disabledTime);
  }

  /** The form control validator for date format */
  private formatValidator(): ValidationErrors | null {
    const invalidFormat = this.date && isNaN(this.date.getTime());
    return invalidFormat ? { dateFormat: { format: this.getFormat() } } : null;
  }

  /** The form control validator for the min date. */
  private minValidator(): ValidationErrors | null {
    const controlValue = this.date;
    const min = getMinDate(this.siDatepickerConfig?.minDate);
    const withTime = this.siDatepickerConfig?.showTime;

    return !min ||
      !isValid(controlValue) ||
      (withTime ? controlValue >= min : compareDate(controlValue, min) >= 0)
      ? null
      : { minDate: { min, actual: controlValue } };
  }

  /** The form control validator for the min date. */
  private maxValidator(): ValidationErrors | null {
    const controlValue = this.date;
    const max = getMaxDate(this.siDatepickerConfig?.maxDate);
    const withTime = this.siDatepickerConfig?.showTime;

    return !max ||
      !isValid(controlValue) ||
      (withTime ? controlValue <= max : compareDate(controlValue, max) <= 0)
      ? null
      : { maxDate: { max, actual: controlValue } };
  }
}
