import { Directive, Input, OnChanges } from '@angular/core';

import { SelectItem, SelectOption } from '../si-select.types';
import { SI_SELECT_OPTIONS_STRATEGY } from './si-select-options-strategy';
import { SiSelectOptionsStrategyBase } from './si-select-options-strategy.base';

/**
 * This directive allows passing {@link SelectItem} to the {@link SiSelectComponent}.
 *
 * @example
 * ```html
 * <si-select [options]="[{ type: 'option', value: 'one', label: 'One' }, { type: 'option', value: 'two', label: 'Two' }]"></si-select>
 * <si-select [options]="[{ type: 'group', label: 'Group', options [{ type: 'option', value: 1, label: 'One' }, { type: 'option', value: 2, label: 'Two' }] }]"></si-select>
 * ```
 */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'si-select[options]',
  providers: [{ provide: SI_SELECT_OPTIONS_STRATEGY, useExisting: SiSelectSimpleOptionsDirective }],
  standalone: true
})
export class SiSelectSimpleOptionsDirective<T = string>
  extends SiSelectOptionsStrategyBase<T>
  implements OnChanges
{
  /** Options to be shown in select dropdown */
  @Input() options: (SelectOption | SelectItem<T>)[] | undefined | null;

  /**
   * By default, values are check on referential equality. Provide a function to customize the behavior.
   *
   * @defaultValue
   * ```
   * (a: T, b: T): boolean => a === b
   * ```
   */
  // override the input be to better understandable by consumer
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('optionEqualCheckFn') override optionsEqual = (a: T, b: T): boolean => a === b;

  ngOnChanges(): void {
    if (this.options) {
      this.allRows.set(
        this.options?.map(option =>
          option.type
            ? option
            : {
                type: 'option',
                value: option.id as T,
                label: option.title,
                iconColor: option.color,
                icon: option.icon,
                disabled: option.disabled
              }
        )
      );
    } else {
      this.allRows.set([]);
    }

    this.onFilter();
  }
}
