import {
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {
  ControlContainer,
  FormControl,
  FormRecord,
  FormsModule,
  ReactiveFormsModule,
  ValidationErrors,
  Validators
} from '@angular/forms';
import { TimeDurationFormat, TimeDurationUnit, TimeDurationValue } from '@simpl/buildings-types';
import { Subscription } from 'rxjs';

@Component({
  selector: 'si-time-duration',
  templateUrl: './si-time-duration.component.html',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule]
})
export class SiTimeDurationComponent implements OnInit, OnChanges, OnDestroy {
  /**
   * Settings of time duration. See {@link TimeDurationValue}
   */
  @Input({ required: true }) value!: TimeDurationValue;

  @Output() readonly submitEnter = new EventEmitter<void>(true);

  protected form!: FormRecord<FormControl<string | null>>;
  protected values: number[] = [];
  protected inputs: string[] = [];

  private subscription!: Subscription;
  private unitsToMillisecondsMap = {
    'ms': 1,
    'hs': 10,
    'ts': 100,
    's': 1000,
    'min': 60000,
    'h': 3600000,
    'd': 86400000
  };

  private formatsToMillisecondsMap = {
    'ms': 1,
    'hs': 10,
    'ts': 100,
    'ss': 1000,
    'mm': 60000,
    'hh': 3600000,
    'dd': 86400000
  };

  private elementRef = inject(ElementRef);
  private controlContainer = inject(ControlContainer, { optional: true });

  ngOnChanges(): void {
    this.init();
  }

  ngOnInit(): void {
    this.init();
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private init(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.form = (this.controlContainer?.control as typeof this.form) ?? new FormRecord({});
    if (!this.value) {
      return;
    }

    this.value.readonly = this.value.readonly ?? false;
    this.inputs = this.getInputs(this.value.format ?? '');
    this.values =
      this.value.value !== undefined ? this.getValues(this.value.value, this.value.unit ?? '') : [];
    for (let index = 0; index < this.inputs.length; index++) {
      this.form.addControl(this.inputs[index], new FormControl('', [Validators.pattern(/^\d*$/)]));
      const formEle = this.form.get(this.inputs[index]);
      if (formEle) {
        formEle.setValue(this.values[index]);
      }
    }

    // second pass because we need to construct all form controls first
    for (let index = 0; index < this.inputs.length; index++) {
      const formControl = this.form.controls[this.inputs[index]];
      formControl.addValidators([() => this.validateRequired(index)]);
    }

    this.subscription = this.form.valueChanges.subscribe(() => {
      this.value.value = this.updateValue();
      this.revalidate();
      if (
        (this.value.max !== undefined && this.value.value > this.value.max) ||
        (this.value.min !== undefined && this.value.value < this.value.min)
      ) {
        this.form.setErrors({ 'invalid': true });
      }
    });
  }

  private validateRequired(index: number): ValidationErrors | null {
    // required:
    // - when the last index
    // - when a value in front is set
    let required = index === this.inputs.length - 1;
    for (let i = 0; !required && i < index; i++) {
      const control = this.form.controls[this.inputs[i]];
      required = control.value != null;
    }
    if (required) {
      const control = this.form.controls[this.inputs[index]];
      return Validators.required(control);
    }
    return null;
  }

  private revalidate(): void {
    for (const input of this.inputs) {
      const control = this.form.controls[input];
      control.updateValueAndValidity({ emitEvent: false });
    }
  }

  protected getInputs(requiredFormat: TimeDurationFormat): string[] {
    return requiredFormat.split(/[:.]+/).filter(f => (this.formatsToMillisecondsMap as any)[f]);
  }

  protected getValues(value: number, defaultUnit: TimeDurationUnit): number[] {
    let valueToConvert = value * (this.unitsToMillisecondsMap as any)[defaultUnit];
    const ret: number[] = [];
    for (const input of this.inputs) {
      const conversion = valueToConvert / (this.formatsToMillisecondsMap as any)[input];
      valueToConvert = valueToConvert % (this.formatsToMillisecondsMap as any)[input];
      ret.push(Math.floor(conversion));
    }
    return ret;
  }

  /**
   * Get the current time duration value based on configured unit.
   */
  updateValue(): number {
    const sumOfInputValues = this.inputs.reduce((currentTotal: number, input) => {
      const formInput = this.form.get(input);
      if (formInput) {
        return (
          currentTotal +
          parseInt(formInput.value ?? 0, 10) * (this.formatsToMillisecondsMap as any)[input]
        );
      }
      return currentTotal;
    }, 0);
    return sumOfInputValues / (this.unitsToMillisecondsMap as any)[this.value.unit ?? ''];
  }

  protected trackByItem(index: number, item: string): string {
    return item;
  }

  protected onEnter(index: number): void {
    if (index + 1 === this.inputs.length) {
      this.submitEnter.emit();
    } else {
      this.elementRef.nativeElement
        .querySelectorAll('input')
        ?.item(index + 1)
        ?.focus();
    }
  }
}
