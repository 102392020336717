import { Injectable } from '@angular/core';
import { TraceService } from '@gms-flex/services-common';
import { Observable, ReplaySubject, Subscription, timer } from 'rxjs';
import { TraceModules } from 'src/app/core/shared/trace-modules';

import { ConnectivityFeatureAttributes } from './device-proxy.model';
import { DeviceProxyService } from './device-proxy.service';

const pollRateEvents = 30000;
const delayConnectionReading = 100;

@Injectable({
  providedIn: 'root'
})
export class DeviceConnectionService {

  private readonly subscriptionsPerDevice: Map<string, ReplaySubject<boolean | undefined>> = new Map<string, ReplaySubject<boolean | undefined>>;

  /* Polling management subscription */
  private timerSubscription: Subscription;

  public constructor(
    private readonly traceService: TraceService,
    private readonly deviceProxy: DeviceProxyService) {

    this.traceService.info(TraceModules.bxServicesSummary, 'DeviceConnectionService created.');
  }

  /**
   * Subscribes for the connection state of the desired device.
   * The returned observable notifies on the respective state.
   * The consumer needs to subscribe on the returned observable immediately.
   * If the consumer does no more need notifications, it shall unsubscribe from the returned observable!
   * This service does regulary clean up returned observables which do not have subscribers anymore!
   */
  public subscribeConnectionState(partitionId: string, deviceId: string): Observable<boolean | undefined> {
    const dvcKey = this.getDeviceKey(partitionId, deviceId);
    if (!this.subscriptionsPerDevice.has(dvcKey)) {
      this.subscriptionsPerDevice.set(dvcKey, new ReplaySubject<boolean>(1));
      this.startTimerForSubscription(delayConnectionReading);
    }
    return this.subscriptionsPerDevice.get(dvcKey);
  }

  private getDeviceKey(partitionId: string, deviceId: string): string {
    return `${partitionId}.${deviceId}`;
  }

  private splitDeviceKey(deviceKey: string): string[] {
    return deviceKey.split('.');
  }

  /* Reset timer subscription */
  private stopTimerForSubscription(): void {
    this.timerSubscription?.unsubscribe();
    this.timerSubscription = undefined;
  }

  /* Start timer subscription */
  private startTimerForSubscription(delay: number): void {
    this.stopTimerForSubscription();
    this.timerSubscription = timer(delay).subscribe(count => this.onTimerSubscription(delay === pollRateEvents));
  }

  private onTimerSubscription(cleanSubscriptions: boolean): void {
    if (cleanSubscriptions) {
      this.cleanUpSubscriptions();
    }

    if (this.subscriptionsPerDevice.size > 0) {
      this.subscriptionsPerDevice.forEach((subj, key) => {
        const ids = this.splitDeviceKey(key);
        this.deviceProxy.getDeviceFeatureById(ids[0], ids[1]).subscribe(dvcFeatures => {
          const connectInfo = dvcFeatures.find(item => item.type === 'Connectivity');
          if (connectInfo?.attributes) {
            subj.next((connectInfo.attributes as ConnectivityFeatureAttributes).connected);
          } else {
            subj.next(undefined);
          }
        });
      });
      this.startTimerForSubscription(pollRateEvents);
    }
  }

  private cleanUpSubscriptions(): void {
    const keys: string[] = [];
    this.subscriptionsPerDevice.forEach((subj, key) => {
      if (!subj.observed) {
        keys.push(key);
      }
    });
    keys.forEach(val => this.subscriptionsPerDevice.delete(val));
  }
}
