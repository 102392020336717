import {
  booleanAttribute,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { SI_FORM_ITEM_CONTROL, SiFormItemControl } from '@simpl/element-ng/form';
import { Subscription, timer } from 'rxjs';

@Component({
  selector: 'si-number-input',
  templateUrl: './si-number-input.component.html',
  styleUrl: './si-number-input.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: SiNumberInputComponent,
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: SiNumberInputComponent,
      multi: true
    },
    {
      provide: SI_FORM_ITEM_CONTROL,
      useExisting: SiNumberInputComponent
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true
})
export class SiNumberInputComponent
  implements OnChanges, ControlValueAccessor, Validator, SiFormItemControl
{
  private static idCounter = 0;
  private static formatValidator: ValidatorFn = control => {
    if (isNaN(control.value)) {
      return { numberFormat: true };
    }
    return null;
  };

  /** The min. value for HTML input */
  @Input() min?: number;
  /** The max. value for HTML input */
  @Input() max?: number;
  /**
   * The step size for HTML input
   *
   * @defaultValue 1
   */
  @Input() step: number | 'any' = 1;
  /** The value */
  @Input() value?: number;
  /** Optional unit label */
  @Input() unit?: string;
  /**
   * Show increment/decrement buttons?
   *
   * @defaultValue true
   */
  @Input({ transform: booleanAttribute }) @HostBinding('class.show-step-buttons') showButtons =
    true;
  /** The aria-label passed to the input */
  @Input('aria-label') ariaLabel?: string;
  /** ID that is set on the input, e.g. for `<label for="...">` */
  @Input() inputId = `__si-number-input-${SiNumberInputComponent.idCounter++}`;

  get id(): string {
    return this.inputId;
  }

  /** @defaultValue false */
  @Input({ transform: booleanAttribute }) @HostBinding('class.disabled') disabled = false;
  /** @defaultValue false */
  @Input({ transform: booleanAttribute }) @HostBinding('class.readonly') readonly = false;

  /**
   * The placeholder for input field.
   */
  @Input() placeholder?: string;

  @Output() readonly valueChange = new EventEmitter<number>();

  @ViewChild('inputElement', { static: true })
  protected inputElement!: ElementRef<HTMLInputElement>;

  protected canInc = true;
  protected canDec = true;
  protected onTouched: () => void = () => {};
  protected onChange: (val: any) => void = () => {};
  protected validator: ValidatorFn | null = SiNumberInputComponent.formatValidator;

  private internalValue?: number;
  private autoUpdate$ = timer(400, 80);
  private autoUpdateSubs?: Subscription;
  private changeDetectorRef = inject(ChangeDetectorRef);

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value) {
      this.writeValueToInput(this.value);
    }
    if (changes.min || changes.max) {
      this.validator = Validators.compose([
        this.min != null ? Validators.min(this.min) : null,
        this.max != null ? Validators.max(this.max) : null,
        SiNumberInputComponent.formatValidator
      ])!;
    }
    this.updateStepButtons();
  }

  /** @internal */
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  /** @internal */
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  /** @internal */
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.changeDetectorRef.markForCheck();
  }

  /** @internal */
  writeValue(value: number | undefined): void {
    this.writeValueToInput(value);
    this.updateStepButtons();
    this.changeDetectorRef.markForCheck();
  }

  /** @internal */
  validate(control: AbstractControl): ValidationErrors | null {
    return this.validator ? this.validator(control) : null;
  }

  protected modelChanged(): void {
    const value = this.inputElement.nativeElement.valueAsNumber;
    this.internalValue = value;
    this.updateStepButtons();
    this.onChange(value);
    this.valueChange.emit(value);
  }

  protected autoUpdateStart(event: Event, isIncrement: boolean): void {
    const mouseButton = (event as MouseEvent).button;
    if (mouseButton) {
      return;
    }

    this.onTouched();
    event.preventDefault();
    const trigger = isIncrement ? () => this.increment() : () => this.decrement();
    this.autoUpdateSubs?.unsubscribe();
    this.autoUpdateSubs = this.autoUpdate$.subscribe(trigger);
    trigger();
  }

  protected autoUpdateStop(): void {
    this.autoUpdateSubs?.unsubscribe();
    this.autoUpdateSubs = undefined;
  }

  private updateStepButtons(): void {
    const step = typeof this.step === 'number' ? this.step : 1;
    this.canInc =
      this.max == null || this.internalValue == null || this.internalValue + step <= this.max;
    this.canDec =
      this.min == null || this.internalValue == null || this.internalValue - step >= this.min;
    if (!this.canInc || !this.canDec) {
      this.autoUpdateStop();
    }
  }

  private decrement(): void {
    this.inputElement.nativeElement.stepDown();
    this.modelChanged();
  }

  private increment(): void {
    this.inputElement.nativeElement.stepUp();
    this.modelChanged();
  }

  private writeValueToInput(value: number | undefined): void {
    this.inputElement.nativeElement.value = value == null ? '' : value.toString();
    this.internalValue = value;
  }
}
