import { NgClass } from '@angular/common';
import { Component, inject, OnDestroy } from '@angular/core';
import { SiTranslateModule } from '@simpl/element-translate-ng/translate';

import { SiApplicationHeaderComponent } from './si-application-header.component';

@Component({
  selector: 'si-header-navigation',
  standalone: true,
  template: `
    @if (header.launchpad) {
      <button
        class="header-item focus-inside"
        type="button"
        [ngClass]="'d-' + header.expandBreakpoint + '-none'"
        (click)="header.openLaunchpad()"
      >
        <span class="element-thumbnails icon pe-5"></span>
        {{ header.launchpadLabel | translate }}
      </button>
    }
    <ng-content />
  `,
  imports: [NgClass, SiTranslateModule],
  host: { class: 'header-navigation', role: 'navigation' }
})
export class SiHeaderNavigationComponent implements OnDestroy {
  protected header = inject(SiApplicationHeaderComponent);

  constructor() {
    this.header.hasNavigation.set(true);
  }

  ngOnDestroy(): void {
    this.header.hasNavigation.set(false);
  }
}
