import { NgClass, NgTemplateOutlet } from '@angular/common';
import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { DateFormat, TimeFormat } from '@simpl/buildings-ng/common';
import { AnyCollectionValue } from '@simpl/buildings-types';
import { SiTranslateModule } from '@simpl/element-ng/translate';

import { resetValueCollection } from '../../helpers/property';
import { Property, StateChange, ValueState } from '../../interfaces/property';
import { SiPropertyComponent } from '../si-property/si-property.component';
import { SiPropertyContainerComponent } from '../si-property-popover/si-property-container.component';
import { SiPropertyPopoverComponent } from '../si-property-popover/si-property-popover.component';

@Component({
  selector: 'si-collection-property',
  templateUrl: './si-collection-property.component.html',
  styleUrl: './si-collection-property.component.scss',
  standalone: true,
  imports: [
    NgClass,
    NgTemplateOutlet,
    // eslint-disable-next-line @angular-eslint/no-forward-ref
    forwardRef(() => SiPropertyComponent),
    SiPropertyContainerComponent,
    SiPropertyPopoverComponent,
    SiTranslateModule
  ]
})
export class SiCollectionPropertyComponent {
  /** @defaultValue 'dd.mm.yyyy' */
  @Input() dateFormat: DateFormat = 'dd.mm.yyyy';
  /** @defaultValue 'hh:mm:ss' */
  @Input() timeFormat: TimeFormat = 'hh:mm:ss';
  @Input({ required: true }) property!: Property<AnyCollectionValue>;
  /** @defaultValue 'none' */
  @Input() valueState: ValueState = 'none';
  /** @defaultValue false */
  @Input() forceReadonly = false;
  /** @defaultValue false */
  @Input() customToggle = false;
  @Output() readonly submitted = new EventEmitter<Property<AnyCollectionValue>>();

  @ViewChild('popover', { read: SiPropertyPopoverComponent })
  protected popover?: SiPropertyPopoverComponent;
  @ViewChild('modalTemplate') protected modalTemplate!: TemplateRef<any>;
  protected editValue: any[] = []; // any because it's not a Property but used as one with additional fields

  protected dialogTextClose = $localize`:@@OBJECT_BROWSER.DIALOG_TEXT_SHORT.CLOSE:Close`;
  protected dialogTextRelease = $localize`:@@OBJECT_BROWSER.DIALOG_TEXT_SHORT.RELEASE:Release`;
  protected dialogTextCancel = $localize`:@@OBJECT_BROWSER.DIALOG_TEXT_SHORT.CANCEL:Cancel`;
  protected dialogTextOk = $localize`:@@OBJECT_BROWSER.DIALOG_TEXT_SHORT.OK:OK`;

  private formatNumber(length: number): string {
    if (this.property.value.collectionType === 'record') {
      return `<${length}>`;
    } else {
      return `[${length}]`;
    }
  }

  protected get displayValue(): string {
    return this.formatNumber(
      this.property.value.itemCount ?? this.property.value.value?.length ?? 0
    );
  }

  protected get readonly(): boolean {
    return (this.forceReadonly || this.property.value.readonly) ?? false;
  }

  protected stateChange(state: StateChange): void {
    switch (state) {
      case 'open':
      case 'openKeyboard':
        this.editValue = this.popover?.editValue;
        break;
      case 'submit':
        this.submitted.emit(this.property);
        break;
      case 'release':
        resetValueCollection(this.property);
        this.submitted.emit(this.property);
        break;
    }
  }
}
