import { CommonModule } from '@angular/common';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HfwControlsModule, SearchConfig, TilesViewModule } from '@gms-flex/controls';
import { SystemBrowserService, ValueService } from '@gms-flex/services';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
  SiActionDialogService,
  SiCardModule,
  SiEmptyStateModule, SiLoadingSpinnerModule, SiMenuModule,
  SiPopoverModule,
  SiSearchBarModule,
  SiToastNotificationService
} from '@simpl/element-ng';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

import { ValidationDialogModule } from '../../validation-dialog/validation-dialog.module';
import { EventsModule } from './../../events/events.module';
import { NumericValueDirective } from './directives/numeric-value.directive';
import { SearchViewComponent } from './search/search-view.component';
import { GmsAdornerService } from './services/gms-adorner.service';
import { GmsAnimationTimerService } from './services/gms-animation-timer.service';
import { GmsBrowserObjectService } from './services/gms-browser-object.service';
import { ElementBrushService } from './services/gms-brush.service';
import { GmsButtonsService } from './services/gms-buttons.service';
import { GmsCommandService } from './services/gms-command-service';
import { DataPointService } from './services/gms-datapoint2.service';
import { LibraryImageService } from './services/gms-library-image-service';
import { GmsObjectSelectionService } from './services/gms-object-selection.service';
import { PropertyImageService } from './services/gms-property-image.service';
import { GmsReplicationService } from './services/gms-replication.service';
import { GraphicStateStorageService } from './services/gms-storage.service';
import { GmsSystemsService } from './services/gms-systems.service';
import { TextGroupService } from './services/gms-text-group-service';
import { GraphicsSnapinService } from './services/graphics-snapin.service';
import { PerformanceRef, TimerService, WindowPerformance } from './services/timer-service';
import { GraphicsCommonComponent } from './snapin/graphics-common.component';
import { GmsAdornerComponent } from './views/gms-adorner.component';
import { GmsAlarmComponent } from './views/gms-alarm.component';
import { GmsAlarmsContainerComponent } from './views/gms-alarms-container.component';
import { GmsAnimatedGifComponent } from './views/gms-animated-gif.component';
import { GmsButtonsComponent } from './views/gms-buttons.component';
import { GmsElementComponent } from './views/gms-element.component';
import { GmsEllipseComponent } from './views/gms-ellipse.component';
import { GmsGraphicComponent } from './views/gms-graphic.component';
import { GmsGroupComponent } from './views/gms-group.component';
import { GmsImageComponent } from './views/gms-image.component';
import { GmsLayerComponent } from './views/gms-layer.component';
import { GmsLineComponent } from './views/gms-line.component';
import { GmsNumericComponent } from './views/gms-numeric.component';
import { GmsPathComponent } from './views/gms-path.component';
import { GmsPipeComponent } from './views/gms-pipe.component';
import { GmsPolygonComponent } from './views/gms-polygon.component';
import { GmsRectangleComponent } from './views/gms-rectangle.component';
import { GmsReplicationComponent } from './views/gms-replication.component';
import { GmsRotatorComponent } from './views/gms-rotator-component';
import { GmsSelectorComponent } from './views/gms-selector.component';
import { GmsSliderComponent } from './views/gms-slider-component';
import { GmsSpinnerComponent } from './views/gms-spinner.component';
import { GmsStringCommandComponent } from './views/gms-string-command.component';
import { GmsSymbolInstanceComponent } from './views/gms-symbol-instance.component';
import { GmsTextComponent } from './views/gms-text.component';
import { GmsXpsComponent } from './views/gms-xps.component';
import { GraphicViewComponent } from './views/graphic-view.component';
import { GmsMenuDepthsComponent } from './views/menu-views/gms-menu-depths.component';
import { GmsMenuDisciplinesComponent } from './views/menu-views/gms-menu-disciplines.component';
import { GmsMenuLayersComponent } from './views/menu-views/gms-menu-layers.component';
import { GmsMenuComponent } from './views/menu-views/gms-menu.component';
import { GmsToolbarComponent } from './views/menu-views/gms-toolbar.component';
import { GmsTooltipComponent } from './views/tooltip/gms-tooltip.component';

export const createTranslateLoader = (http: HttpClient): TranslateHttpLoader => new TranslateHttpLoader(http, './@gms-flex/graphics-viewer/i18n/', '.json');

@NgModule({ declarations: [
  GmsAdornerComponent,
  GmsAlarmComponent,
  GmsAlarmsContainerComponent,
  GmsAnimatedGifComponent,
  GmsButtonsComponent,
  GmsElementComponent,
  GmsEllipseComponent,
  GmsGraphicComponent,
  GmsGroupComponent,
  GmsImageComponent,
  GmsLayerComponent,
  GmsLineComponent,
  GmsMenuComponent,
  GmsMenuDepthsComponent,
  GmsMenuDisciplinesComponent,
  GmsMenuLayersComponent,
  GmsNumericComponent,
  GmsPathComponent,
  GmsPipeComponent,
  GmsPolygonComponent,
  GmsRectangleComponent,
  GmsReplicationComponent,
  GmsRotatorComponent,
  GmsSelectorComponent,
  GmsSliderComponent,
  GmsSpinnerComponent,
  GmsStringCommandComponent,
  GmsSymbolInstanceComponent,
  GmsTextComponent,
  GmsToolbarComponent,
  GmsTooltipComponent,
  GmsXpsComponent,
  GraphicsCommonComponent,
  GraphicViewComponent,
  NumericValueDirective,
  SearchViewComponent
],
exports: [GraphicsCommonComponent, GraphicViewComponent],
schemas: [CUSTOM_ELEMENTS_SCHEMA], imports: [BsDropdownModule,
  CommonModule,
  TranslateModule.forChild({
    loader: {
      provide: TranslateLoader,
      useFactory: (createTranslateLoader),
      deps: [HttpClient]
    },
    isolate: true
  }),
  EventsModule,
  FormsModule,
  HfwControlsModule,
  PopoverModule,
  SiCardModule,
  SiEmptyStateModule,
  SiLoadingSpinnerModule,
  SiMenuModule,
  SiSearchBarModule,
  TilesViewModule,
  TooltipModule,
  ValidationDialogModule], providers: [
  DataPointService,
  ElementBrushService,
  GmsAdornerService,
  GmsAnimationTimerService,
  GmsBrowserObjectService,
  GmsButtonsService,
  GmsCommandService,
  GmsObjectSelectionService,
  GmsReplicationService,
  GmsSystemsService,
  GraphicsSnapinService,
  GraphicStateStorageService,
  LibraryImageService,
  PropertyImageService,
  { provide: PerformanceRef, useClass: WindowPerformance },
  SearchConfig,
  SiActionDialogService,
  SiToastNotificationService,
  SystemBrowserService,
  TextGroupService,
  TimerService,
  ValueService,
  provideHttpClient(withInterceptorsFromDi())
] })

export class GmsGraphicsCommonModule {
}
