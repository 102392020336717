import { Directive, Input, OnChanges } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { SI_FORM_ITEM_CONTROL, SiFormItemControl } from '@simpl/element-ng/form';

@Directive({
  selector: '[siFormlyFormFieldProvider]',
  standalone: true,
  providers: [{ provide: SI_FORM_ITEM_CONTROL, useExisting: SiFormlyFormFieldProviderDirective }]
})
export class SiFormlyFormFieldProviderDirective implements SiFormItemControl, OnChanges {
  @Input({ required: true }) field!: FormlyFieldConfig;

  id?: string;
  isFormCheck?: boolean;
  labelledby?: string | null;

  ngOnChanges(): void {
    this.id = this.field.id;
    this.isFormCheck = this.field.type === 'checkbox' || this.field.type === 'boolean';
    if (this.field.props?.useAriaLabel) {
      this.labelledby = this.field.id + '-label';
    }
  }
}
