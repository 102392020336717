import { NgModule } from '@angular/core';

import { SiStepperStepComponent } from './si-stepper-step.component';
import { SiStepperComponent } from './si-stepper.component';

/**
 * @deprecated The {@link SiStepperModule} and all related symbols should no
 * longer be used and will be removed in Element v47. The stepper is now
 * provided by `@simpl/buildings-ng`.
 */
@NgModule({
  exports: [SiStepperComponent, SiStepperStepComponent],
  imports: [SiStepperComponent, SiStepperStepComponent]
})
export class SiStepperModule {}
