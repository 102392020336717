import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  Output,
  signal
} from '@angular/core';
import { TranslatableString } from '@simpl/element-translate-ng/translate';

import { SiTabsetComponent } from '../si-tabset/index';

@Component({
  selector: 'si-tab',
  template: '<ng-content />',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    role: 'tabpanel'
  },
  standalone: true
})
export class SiTabComponent implements OnChanges {
  private static idCounter = 0;
  /** Title of the tab item. */
  @Input() heading?: string;
  /** Icon of the tab item. */
  @Input() icon?: string;
  /** Alternative name or translation key for icon. Used for A11y. */
  @Input() iconAltText?: TranslatableString;
  /** Additional status badge content. */
  @Input() badgeContent?: string;
  /** Background color of the additional status badge. */
  @Input() badgeColor?: string;
  /**
   * Disables the tab.
   *
   * @defaultValue false
   */
  @Input({ transform: booleanAttribute }) disabled = false;
  /**
   * Close the current tab.
   *
   * @defaultValue false
   */
  @Input({ transform: booleanAttribute }) closable? = false;
  /** Event emitter to notify when a tab is closed. */
  @Output() readonly closeTriggered = new EventEmitter<SiTabComponent>();
  private _hidden = false;
  get hidden(): boolean {
    return this._hidden;
  }
  /** Hides the tab item completely if set.
   *
   * @deprecated Use *ngIf instead
   */
  @Input()
  set hidden(val: boolean) {
    // WebComponent has a conflict when used same property name as Input and HTML native attribute binding.
    // We ignore binding if it is coming as attribute string.
    if (typeof val === 'string') {
      return;
    }
    this._hidden = val;
  }

  /** @internal */
  @HostBinding('id') id = `__si-tab-panel-${SiTabComponent.idCounter++}`;
  /** @internal */
  @HostBinding('attr.aria-labelledby') tabId = `__si-tab-${SiTabComponent.idCounter}`;

  @HostBinding('attr.hidden')
  protected get isHidden(): boolean | null {
    return this.hidden || !this.active() ? true : null;
  }

  private parent?: SiTabsetComponent;

  /** @internal */
  active = signal(false);

  /** @internal */
  registerParent(parent: SiTabsetComponent): void {
    this.parent = parent;
  }

  ngOnChanges(): void {
    this.parent?.notifyChildrenChanged();
  }
}
