import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { SiIconComponent } from '@simpl/element-ng/icon';
import { SiLoadingSpinnerComponent } from '@simpl/element-ng/loading-spinner';
import { SiTranslateModule } from '@simpl/element-translate-ng/translate';

import { ResultDetailStep } from './si-result-details-list.datamodel';

@Component({
  selector: 'si-result-details-list',
  templateUrl: './si-result-details-list.component.html',
  styleUrl: './si-result-details-list.component.scss',
  standalone: true,
  imports: [SiLoadingSpinnerComponent, SiIconComponent, SiTranslateModule]
})
export class SiResultDetailsListComponent implements OnChanges {
  /**
   * Array of steps to show in the list.
   *
   * @defaultValue []
   */
  @Input() steps: ResultDetailStep[] = [];

  protected stepHasValue = false;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.steps) {
      this.stepHasValue = this.steps.some(item => !!item.value);
    }
  }
}
