import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  inject,
  Input,
  numberAttribute,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { EntityStatusType, STATUS_ICON, StatusIcon } from '@simpl/element-ng/common';
import { SiIconModule } from '@simpl/element-ng/icon';
import { TranslatableString } from '@simpl/element-translate-ng/translate';

import { SiAvatarBackgroundColorDirective } from './si-avatar-background-color.directive';

export type AvatarSize = 'tiny' | 'xsmall' | 'small' | 'regular' | 'large' | 'xlarge';

@Component({
  selector: 'si-avatar',
  templateUrl: './si-avatar.component.html',
  styleUrl: './si-avatar.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass, SiIconModule],
  standalone: true,
  hostDirectives: [
    {
      directive: SiAvatarBackgroundColorDirective,
      inputs: ['color', 'autoColor']
    }
  ]
})
export class SiAvatarComponent implements OnChanges {
  /**
   * Size of the component.
   *
   * @defaultValue 'regular'
   */
  @Input() @HostBinding('class') size: AvatarSize = 'regular';
  /** Image src URL when using an image. */
  @Input() imageUrl?: string;
  /** Icon name when using an icon. */
  @Input() icon?: string;
  /**
   * Initials to be displayed as default avatar if no `icon` or `imageUrl` are provided.
   * If also no initials are provided, they will be automatically calculated from the `altText`.
   * The value will be used to calculate the background color when `autoColor` is true.
   */
  @Input() initials?: string;
  /**
   * The desired color index from $element-data-* color tokens. This can be set to any kind of
   * positive integer that is then mapped to a color index.
   * A better way to set a pseudo-random color is to set * {@link autoColor} to `true`.
   */
  @Input({ transform: numberAttribute }) color?: number;
  /** The `alt` text for image, `title` for other modes. */
  @Input({ required: true }) altText!: string;
  /**
   * The status (success, info, warning, caution, danger, critical, pending, progress) to be
   * visualized.
   */
  @Input() status?: EntityStatusType;
  /**
   * aria-label for status
   */
  @Input() statusAriaLabel?: TranslatableString;

  protected statusIcon?: StatusIcon;
  protected displayInitials?: string;
  private readonly autoBackgroundColorDirective = inject(SiAvatarBackgroundColorDirective);

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.status) {
      this.statusIcon = this.status ? STATUS_ICON[this.status] : undefined;
    }

    if (changes.initials || changes.altText) {
      this.setInitials();
      this.autoBackgroundColorDirective.calculateColorFromInitials(this.displayInitials);
    }
  }

  private setInitials(): void {
    if (this.initials) {
      this.displayInitials = this.initials;
    } else {
      const parts = this.altText.split(' ');
      let first = parts.shift() ?? '';
      if (first) {
        first = first[0].toLocaleUpperCase();
      }
      let last = parts.pop() ?? '';
      if (last) {
        last = last[0].toLocaleUpperCase();
      }
      this.displayInitials = first + last;
    }
  }
}
