import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges
} from '@angular/core';
import { DateFormat, TimeFormat } from '@simpl/buildings-ng/common';
import { SiEmptyStateComponent } from '@simpl/element-ng';
import { SiTranslateModule } from '@simpl/element-ng/translate';
import { Subscription } from 'rxjs';

import { mergeProperties } from '../../../helpers/property';
import {
  BulkCommandEvent,
  BulkProperty,
  BulkPropertyValues,
  BulkResult,
  CommandEvent,
  Property,
  ValueState
} from '../../../interfaces/index';
import { SiPropertyService } from '../../../services/index';
import { SiPropertyListComponent } from '../../si-property-list/si-property-list.component';

export interface BulkPropertyState {
  objectId: string;
  propertyId?: string;
  state: ValueState;
}

@Component({
  selector: 'si-bulk-property-list',
  templateUrl: './si-bulk-property-list.component.html',
  styleUrl: './si-bulk-property-list.component.scss',
  standalone: true,
  imports: [SiEmptyStateComponent, SiPropertyListComponent, SiTranslateModule]
})
export class SiBulkPropertyListComponent implements OnChanges, OnDestroy {
  /** @defaultValue false */
  @Input() editMode = false;
  @Input() bulkPropertyValues?: BulkPropertyValues;
  @Input() bulkProperties!: BulkProperty[];
  @Input() filter?: string;

  /** @defaultValue 'dd.mm.yyyy' */
  @Input() dateFormat: DateFormat = 'dd.mm.yyyy';
  /** @defaultValue 'hh:mm:ss' */
  @Input() timeFormat: TimeFormat = 'hh:mm:ss';
  /** @defaultValue false */
  @Input() allowValuesOutOfRange = false;

  @Output() readonly selectProperty = new EventEmitter<BulkProperty>();
  @Output() readonly bulkPropertyState = new EventEmitter<BulkPropertyState>();
  @Output() readonly back = new EventEmitter<void>();
  @Output() readonly filtered = new EventEmitter<number>();

  /** @defaultValue [] */
  combinedProperties: Property[] = [];
  /** @defaultValue [] */
  combinedValueState: ValueState[] = [];
  /** @defaultValue [] */
  filteredBulkProps: BulkProperty[] = [];

  protected dialogTextBack = $localize`:@@OBJECT_BROWSER.DIALOG_TEXT_SHORT.BACK:Back`;
  protected dialogTextClickToOperate = $localize`:@@OBJECT_BROWSER.DIALOG_TEXT_LONG.CLICK_TO_OPERATE:Click to operate`;
  protected dialogTextFilterNoResults = $localize`:@@OBJECT_BROWSER.DIALOG_TEXT_LONG.FILTER_NO_RESULTS:No matching objects found`;

  private selectedProp?: BulkProperty;
  private subscription?: Subscription;
  private numFail = 0;

  private propertyService = inject(SiPropertyService);

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.bulkPropertyValues && this.bulkPropertyValues) {
      this.combinedProperties = [this.bulkPropertyValues.aggregate];
    } else {
      // this is to show a placeholder while loading to avoid a big layout shift
      this.combinedProperties = [
        {
          name: ' ',
          defaultText: ' '
        } as Property
      ];
    }
    if (changes.bulkProperties || changes.filter) {
      this.filterProps();
    }
    if (changes.editMode) {
      this.setState('none');
    }
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  propertySelected(prop: BulkProperty): void {
    this.selectedProp = prop;
    this.selectProperty.emit(prop);
  }

  bulkSubmit(bulkProperty: Property): void {
    if (!this.bulkPropertyValues) {
      return;
    }

    this.setState('loading');
    this.setBulkState('loading');
    this.bulkPropertyValues.objectValues.forEach(p => mergeProperties(bulkProperty, p.property));

    this.subscription?.unsubscribe();
    this.subscription = this.propertyService
      .writeBulkProperty(this.getObjectIds(), this.selectedProp!.id, bulkProperty.value)
      .subscribe({
        next: res => this.handleBulkResult(res),
        error: () => this.handleBulkFailed(),
        complete: () => this.handleBulkResultComplete()
      });
  }

  bulkCommand(bulkCommand: CommandEvent): void {
    if (!this.bulkPropertyValues) {
      return;
    }

    const command: BulkCommandEvent = {
      propertyId: this.selectedProp!.id,
      command: bulkCommand.command,
      parameters: bulkCommand.parameters
    };

    this.setState('loading');
    this.setBulkState('loading');

    this.subscription?.unsubscribe();
    this.subscription = this.propertyService
      .executeBulkCommand(this.getObjectIds(), command)
      .subscribe({
        next: res => this.handleBulkResult(res),
        error: () => this.handleBulkFailed(),
        complete: () => this.handleBulkResultComplete()
      });
  }

  private setState(state: ValueState): void {
    this.combinedValueState = [state];
  }

  private getObjectIds(): string[] {
    return this.bulkPropertyValues!.objectValues.map(p => p.objectId);
  }

  private setBulkState(state: ValueState): void {
    this.bulkPropertyValues?.objectValues.forEach(p => {
      this.bulkPropertyState.emit({
        objectId: p.objectId,
        propertyId: p.property.id,
        state
      });
    });
    this.numFail = 0;
  }

  private handleBulkResult(result: BulkResult): void {
    this.numFail += result.success ? 0 : 1;
    this.bulkPropertyState.emit({
      objectId: result.objectId,
      propertyId: result.propertyId,
      state: result.success ? 'passed' : 'failed'
    });
  }

  private handleBulkFailed(): void {
    this.subscription?.unsubscribe();
    this.subscription = undefined;
    this.setState('failed');
    this.setBulkState('failed');
  }

  private handleBulkResultComplete(): void {
    this.subscription = undefined;
    const state: ValueState =
      this.numFail === 0
        ? 'passed'
        : this.numFail === this.bulkPropertyValues?.objectValues.length
          ? 'failed'
          : 'partial';
    this.setState(state);
  }

  private filterProps(): void {
    const filterLower = this.filter?.toLocaleLowerCase();
    this.filteredBulkProps = filterLower
      ? this.bulkProperties.filter(prop => prop.name.toLocaleLowerCase().includes(filterLower))
      : this.bulkProperties;
    this.filtered.emit(this.filteredBulkProps?.length ?? 0);
  }
}
