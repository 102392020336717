import { NgClass } from '@angular/common';
import { Component, HostBinding, Input } from '@angular/core';
import { SiTranslateModule } from '@simpl/element-translate-ng/translate';

import { ComfortScoreItem } from './si-comfort-score.model';

/**
 * @deprecated The {@link SiComfortScoreStatusComponent} should no longer be used and
 * will be removed in Element v47.
 */
@Component({
  selector: 'si-comfort-score-status',
  templateUrl: './si-comfort-score-status.component.html',
  styleUrl: './si-comfort-score-status.component.scss',
  standalone: true,
  imports: [NgClass, SiTranslateModule]
})
export class SiComfortScoreStatusComponent {
  @Input({ required: true }) item!: ComfortScoreItem;
  /**
   * @defaultValue 'medium'
   */
  @Input() size: 'small' | 'medium' | 'large' = 'medium';

  @HostBinding('class') protected get sizeClass(): string {
    return 'size-' + this.size;
  }
}
