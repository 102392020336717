import {
  Directive,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { Subscription } from 'rxjs';

import { ElementDimensions, ResizeObserverService } from './resize-observer.service';

/**
 * Directive to emit events on element size change. Use like this:
 *   `<div (siResizeObserver)="handleResize($event)">`
 * When the size of the element changes, an event in the format
 *   `{ width: number, height: number }`
 * will be emitted. Also an initial event will be emitted on init.
 *
 * By default, events are throttled and to an event every 100ms. To change
 * this, add `[resizeThrottle]="200"` on the same element. Input in milliseconds.
 */
@Directive({
  selector: '[siResizeObserver]',
  standalone: true
})
export class SiResizeObserverDirective implements OnInit, OnDestroy {
  /** @defaultValue 100 */
  @Input() resizeThrottle = 100;
  @Output() readonly siResizeObserver = new EventEmitter<ElementDimensions>();

  private subs?: Subscription;
  private element = inject(ElementRef);
  private service = inject(ResizeObserverService);

  ngOnInit(): void {
    this.subs = this.service
      .observe(this.element.nativeElement, this.resizeThrottle, true)
      .subscribe(this.siResizeObserver);
  }

  ngOnDestroy(): void {
    this.subs?.unsubscribe();
  }
}
