<div class="icon" aria-hidden="true" [ngClass]="icon">
  @if (badgeDot) {
    <span class="badge-dot"></span>
  }
</div>
@if (badgeValue) {
  <div class="badge-text">{{ badgeValue }}</div>
}
<ng-content select="si-avatar" />
<div class="item-title" [class.visually-hidden]="visuallyHideTitle">
  <ng-content />
</div>
@if (dropdownTrigger) {
  <div class="element-down-2 dropdown-caret ms-auto ps-0" aria-hidden="true"></div>
}
