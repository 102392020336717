<button
  type="button"
  class="btn"
  [id]="id"
  [ngClass]="{
    'btn-secondary': !props.btnType || props.btnType === 'secondary',
    'btn-primary': props.btnType === 'primary',
    'btn-tertiary': props.btnType === 'tertiary',
    'btn-warning': props.btnType === 'warning',
    'btn-danger': props.btnType === 'danger'
  }"
  [disabled]="props.disabled || props.readonly"
  [hidden]="props.hidden"
  (click)="click()"
  >{{ props.label | translate }}</button
>
