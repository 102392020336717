import { NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, EventEmitter, HostBinding, Input, Output, TemplateRef } from '@angular/core';
import { DateFormat, TimeFormat } from '@simpl/buildings-ng/common';

import { PropertyDisplayStyle, PropertyTemplateContext } from './si-property.model';
import { DefaultProperty, Property, ValueState } from '../../interfaces/property';
import { SiBigNumberPropertyComponent } from '../si-big-number-property/si-big-number-property.component';
import { SiBitstringPropertyComponent } from '../si-bitstring-property/si-bitstring-property.component';
import { SiBlindPropertyComponent } from '../si-blind-property/si-blind-property.component';
import { SiBooleanPropertyComponent } from '../si-boolean-property/si-boolean-property.component';
import { SiCollectionPropertyComponent } from '../si-collection-property/si-collection-property.component';
import { SiDatePropertyComponent } from '../si-date-property/si-date-property.component';
import { SiDatetimePropertyComponent } from '../si-datetime-property/si-datetime-property.component';
import { SiDefaultPropertyComponent } from '../si-default-property/si-default-property.component';
import { SiEnumPropertyComponent } from '../si-enum-property/si-enum-property.component';
import { SiNumberPropertyComponent } from '../si-number-property/si-number-property.component';
import { SiObjectidPropertyComponent } from '../si-objectid-property/si-objectid-property.component';
import { SiPriorityArrayPropertyComponent } from '../si-priority-array-property/si-priority-array-property.component';
import { SiStringPropertyComponent } from '../si-string-property/si-string-property.component';
import { SiTimePropertyComponent } from '../si-time-property/si-time-property.component';
import { SiTimedurationPropertyComponent } from '../si-timeduration-property/si-timeduration-property.component';

@Component({
  selector: 'si-property',
  templateUrl: './si-property.component.html',
  styles: ':host { display: block; }',
  standalone: true,
  imports: [
    NgClass,
    NgTemplateOutlet,
    SiBigNumberPropertyComponent,
    SiBitstringPropertyComponent,
    SiBlindPropertyComponent,
    SiBooleanPropertyComponent,
    SiCollectionPropertyComponent,
    SiDatePropertyComponent,
    SiDatetimePropertyComponent,
    SiDefaultPropertyComponent,
    SiEnumPropertyComponent,
    SiNumberPropertyComponent,
    SiObjectidPropertyComponent,
    SiPriorityArrayPropertyComponent,
    SiStringPropertyComponent,
    SiTimePropertyComponent,
    SiTimedurationPropertyComponent
  ],
  styleUrl: './si-property.component.scss'
})
export class SiPropertyComponent {
  @Input({ required: true }) property!: Property | DefaultProperty;
  /** @defaultValue 'none' */
  @Input() valueState: ValueState = 'none';
  /** @defaultValue false */
  @Input() allowValuesOutOfRange = false;
  /** @defaultValue '' */
  @Input() colClass = '';
  /** @defaultValue 'list' */
  @Input() displayStyle: PropertyDisplayStyle = 'list';
  /** @defaultValue 'dd.mm.yyyy' */
  @Input() dateFormat: DateFormat = 'dd.mm.yyyy';
  /** @defaultValue 'hh:mm:ss' */
  @Input() timeFormat: TimeFormat = 'hh:mm:ss';
  /** @defaultValue false */
  @Input() forceReadonly = false;
  @Input() customTemplate?: TemplateRef<PropertyTemplateContext>;
  @Output() readonly propertySubmit = new EventEmitter<Property>();

  private readonly tablePropertyClass = 'table-cell-value';

  protected getSubmit = (event: Property): void => {
    this.submit(event);
  };

  @HostBinding('class')
  protected get hostClass(): string {
    return this.displayStyle === 'list' ? this.colClass : '';
  }

  @HostBinding('class.readonly')
  protected get hostReadonlyClass(): boolean {
    return !!this.property.value?.readonly || this.forceReadonly;
  }

  protected get propertyClass(): string {
    return this.displayStyle === 'list' ? '' : this.tablePropertyClass;
  }

  protected submit(event: Property): void {
    this.propertySubmit.emit(event);
  }

  protected displayChildren(): boolean {
    return (
      this.displayStyle === 'table' &&
      'treeStatus' in this.property &&
      this.property.treeStatus !== 'disabled'
    );
  }

  protected childrenExpanded(): boolean {
    return 'treeStatus' in this.property && this.property.treeStatus === 'expanded';
  }
}
