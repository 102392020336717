import { LiveAnnouncer } from '@angular/cdk/a11y';
import {
  CDK_DRAG_CONFIG,
  CdkDrag,
  CdkDragDrop,
  CdkDragHandle,
  CdkDropList,
  moveItemInArray
} from '@angular/cdk/drag-drop';
import { CdkListbox, CdkOption, ListboxValueChangeEvent } from '@angular/cdk/listbox';
import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  inject,
  Input,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { ModalRef } from '@simpl/element-ng/modal';
import { SiTranslateModule, SiTranslateService } from '@simpl/element-translate-ng/translate';
import { first } from 'rxjs/operators';

import { Column, ColumnSelectionDialogResult } from './si-column-selection-dialog.types';

const dragConfig = {
  dragStartThreshold: 0,
  pointerDirectionChangeThreshold: 5,
  zIndex: 10000
};

@Component({
  selector: 'si-column-selection-dialog',
  templateUrl: './si-column-selection-dialog.component.html',
  styleUrl: './si-column-selection-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CdkDrag, CdkDragHandle, CdkDropList, CdkListbox, CdkOption, SiTranslateModule],
  providers: [{ provide: CDK_DRAG_CONFIG, useValue: dragConfig }]
})
export class SiColumnSelectionDialogComponent implements OnInit {
  @Input() titleId?: string;
  @Input() heading!: string;
  @Input() bodyTitle!: string;
  /**
   * @defaultValue
   * ```
   * $localize`:@@SI_COLUMN_SELECTION_DIALOG.SUBMIT:Apply`
   * ```
   */
  @Input() submitBtnName = $localize`:@@SI_COLUMN_SELECTION_DIALOG.SUBMIT:Apply`;
  /**
   * @defaultValue
   * ```
   * $localize`:@@SI_COLUMN_SELECTION_DIALOG.CANCEL:Cancel`
   * ```
   */
  @Input() cancelBtnName = $localize`:@@SI_COLUMN_SELECTION_DIALOG.CANCEL:Cancel`;
  /**
   * @defaultValue
   * ```
   * $localize`:@@SI_COLUMN_SELECTION_DIALOG.RESTORE_TO_DEFAULT:Restore to default`
   * ```
   */
  @Input()
  restoreToDefaultBtnName =
    $localize`:@@SI_COLUMN_SELECTION_DIALOG.RESTORE_TO_DEFAULT:Restore to default`;
  /**
   * @defaultValue
   * ```
   * $localize`:@@SI_COLUMN_SELECTION_DIALOG.HIDDEN:Hidden`
   * ```
   */
  @Input() hiddenText = $localize`:@@SI_COLUMN_SELECTION_DIALOG.HIDDEN:Hidden`;
  /**
   * @defaultValue
   * ```
   * $localize`:@@SI_COLUMN_SELECTION_DIALOG.VISIBLE:Visible`
   * ```
   */
  @Input() visibleText = $localize`:@@SI_COLUMN_SELECTION_DIALOG.VISIBLE:Visible`;
  /** @defaultValue false */
  @Input({ transform: booleanAttribute }) restoreEnabled = false;
  @Input() columns!: Column[];
  /**
   * @defaultValue
   * ```
   * {}
   * ```
   */
  @Input() translationParams: Record<string, unknown> = {};

  /**
   * @defaultValue
   * ```
   * $localize`:@@SI_COLUMN_SELECTION_DIALOG.LIST_ARIA_LABEL:List of possible columns. Items can be moved using Alt+ArrowUp or Alt+ArrowDown`
   * ```
   */
  @Input()
  listAriaLabel =
    $localize`:@@SI_COLUMN_SELECTION_DIALOG.LIST_ARIA_LABEL:List of possible columns. Items can be moved using Alt+ArrowUp or Alt+ArrowDown`;
  /**
   * @defaultValue
   * ```
   * $localize`:@@SI_COLUMN_SELECTION_DIALOG.ITEM_MOVED:Item is now at position {{targetPosition}}`
   * ```
   */
  @Input()
  a11yItemMovedMessage =
    $localize`:@@SI_COLUMN_SELECTION_DIALOG.ITEM_MOVED:Item is now at position {{targetPosition}}`;
  /**
   * @defaultValue
   * ```
   * $localize`:@@SI_COLUMN_SELECTION_DIALOG.ITEM_NOT_MOVED:Item was not moved`
   * ```
   */
  @Input()
  a11yItemNotMovedMessage =
    $localize`:@@SI_COLUMN_SELECTION_DIALOG.ITEM_NOT_MOVED:Item was not moved`;
  /** @defaultValue true */
  @Input({ transform: booleanAttribute }) columnVisibilityConfigurable = true;

  @ViewChildren(CdkOption) private listOptions!: QueryList<CdkOption>;

  @ViewChild('modalBody', { static: true }) private modalBodyElement!: ElementRef<HTMLDivElement>;

  private tempHeaderData: Column[] = [];

  protected modalRef = inject(
    ModalRef<SiColumnSelectionDialogComponent, ColumnSelectionDialogResult>
  );

  protected visibleIds: string[] = [];

  private liveAnnouncer = inject(LiveAnnouncer);
  private translateService = inject(SiTranslateService);

  ngOnInit(): void {
    this.setupColumnData();
  }

  /** @internal */
  get backupColumns(): Column[] {
    return this.tempHeaderData;
  }

  protected submitColumnSelection(): void {
    this.modalRef.hide({ type: 'ok', columns: this.columns });
  }

  protected cancelColumnSelection(): void {
    this.columns.length = 0;
    this.tempHeaderData.forEach(element => this.columns.push(element));
    this.modalRef.hide({ type: 'cancel', columns: this.columns });
  }

  protected drop(event: CdkDragDrop<string[]>): void {
    if (this.columns[event.currentIndex].draggable) {
      moveItemInArray(this.columns, event.previousIndex, event.currentIndex);
      this.modalRef.hidden.next({ type: 'instant', columns: this.columns });
    }
  }

  protected restoreToDefault(): void {
    this.modalRef.hidden.next({
      type: 'restoreDefault',
      columns: this.columns,
      updateColumns: columns => {
        this.columns = columns;
        this.setupColumnData();
      }
    });
  }

  protected changeColumnVisibilityFromListbox({ value }: ListboxValueChangeEvent<string>): void {
    for (const column of this.columns) {
      column.visible = value.includes(column.id);
    }
    this.modalRef.hidden.next({ type: 'instant', columns: this.columns });
  }

  protected moveDown(index: number, event: Event): void {
    if (this.columns[index].draggable) {
      let targetIndex = index + 1;
      while (this.columns[targetIndex] && !this.columns[targetIndex].draggable) {
        targetIndex++;
      }

      if (targetIndex !== index && this.columns[targetIndex]?.draggable) {
        event.preventDefault();
        moveItemInArray(this.columns, index, targetIndex);

        // When moving the first partially visible item down,
        // the browser tries to keep its position stable within the viewport by automatically scrolling down.
        // This behavior is not wanted here, so we restore the previous scroll after moving the item
        // TODO: check if this could be solved easier
        if (
          this.listOptions.get(index)!.element.getBoundingClientRect().top <=
          this.modalBodyElement.nativeElement.getBoundingClientRect().top
        ) {
          const previousScrollTop = this.modalBodyElement.nativeElement.scrollTop;
          setTimeout(() => (this.modalBodyElement.nativeElement.scrollTop = previousScrollTop));
        }

        // When moving the last visible element down, the scroll position is not adopted. So its scroll out of view.
        // We correct this manually by scrolling it back into view
        const targetElement = this.listOptions.get(targetIndex)!.element;
        if (
          targetElement.getBoundingClientRect().bottom >
          this.modalBodyElement.nativeElement.getBoundingClientRect().bottom
        ) {
          targetElement.scrollIntoView({ block: 'end' });
        }

        this.announceSuccessfulMove(targetIndex);
      } else {
        this.announceNotSuccessfulMove();
      }
    }
  }

  protected moveUp(index: number, event: Event): void {
    if (this.columns[index].draggable) {
      let targetIndex = index - 1;
      while (this.columns[targetIndex] && !this.columns[targetIndex].draggable) {
        targetIndex--;
      }

      if (targetIndex !== index && this.columns[targetIndex]?.draggable) {
        event.preventDefault();
        moveItemInArray(this.columns, index, targetIndex);
        // it seems like this is only necessary for move up. Don't know why
        setTimeout(() => this.listOptions.get(targetIndex)!.focus());
        this.announceSuccessfulMove(targetIndex);
      } else {
        this.announceNotSuccessfulMove();
      }
    }
  }

  private setupColumnData(): void {
    this.tempHeaderData = this.columns.map(x => Object.assign({}, x));
    this.visibleIds = this.columns.filter(column => column.visible).map(column => column.id);
  }

  private announceSuccessfulMove(index: number): void {
    this.announceMove(this.a11yItemMovedMessage, {
      ...this.translationParams,
      targetPosition: index + 1
    });
  }

  private announceNotSuccessfulMove(): void {
    this.announceMove(this.a11yItemNotMovedMessage, this.translationParams);
  }

  private announceMove(message?: string, translationParams?: Record<string, unknown>): void {
    if (message) {
      this.translateService
        .translateAsync(message, translationParams)
        .pipe(first())
        .subscribe(translatedMessage => this.liveAnnouncer.announce(translatedMessage));
    }
  }
}
