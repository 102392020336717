import {
  AfterViewInit,
  booleanAttribute,
  ComponentRef,
  Directive,
  ElementRef,
  HostListener,
  inject,
  Input,
  OnDestroy
} from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SI_FORM_ITEM_CONTROL } from '@simpl/element-ng/form';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { SiDateInputDirective } from './si-date-input.directive';
import { SiDatepickerOverlayComponent } from './si-datepicker-overlay.component';
import { SiDatepickerOverlayDirective } from './si-datepicker-overlay.directive';
import { getDatepickerFormat } from './si-datepicker.model';

@Directive({
  selector: '[siDatepicker]',
  exportAs: 'siDatepicker',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: SiDatepickerDirective,
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: SiDatepickerDirective,
      multi: true
    },
    {
      provide: SI_FORM_ITEM_CONTROL,
      useExisting: SiDatepickerDirective
    }
  ],
  hostDirectives: [
    {
      directive: SiDatepickerOverlayDirective,

      outputs: ['siDatepickerClose']
    }
  ],
  standalone: true
})
export class SiDatepickerDirective
  extends SiDateInputDirective
  implements AfterViewInit, OnDestroy
{
  /**
   * Automatically close overlay on date selection.
   * Do not use this behavior with config showTime = true, because it
   * will close the overlay when the user change one of the time units.
   *
   * @defaultValue false
   */
  @Input({ transform: booleanAttribute }) autoClose = false;
  /**
   * @deprecated Property has no effect and will be removed without replacement.
   *
   * @defaultValue inject(ElementRef)
   */
  @Input() triggeringInput: ElementRef = inject(ElementRef);

  /**
   * During focus on close the datepicker will not show since we recover the focus on element.
   * The focus on close is only relevant when the directive is configured without a calendar button.
   */
  private overlaySubscriptions?: Subscription[];
  private externalTrigger?: ElementRef<HTMLElement>;
  private readonly overlayToggle = inject(SiDatepickerOverlayDirective);
  private readonly destroyer$ = new Subject<void>();

  ngAfterViewInit(): void {
    // Update datepicker with new date value
    this.dateChange
      .pipe(takeUntil(this.destroyer$))
      .subscribe(date => this.overlayToggle.setInputs({ date }));
  }

  ngOnDestroy(): void {
    this.destroyer$.next();
    this.destroyer$.complete();
  }

  /** @internal */
  touch(): void {
    this.onTouched();
  }

  /**
   * On click shall show datepicker.
   */
  @HostListener('click', ['$event'])
  protected onClick(): void {
    if (!this.externalTrigger) {
      this.show();
    }
  }

  /**
   * Focus out shall close the datepicker except we are moving the focus to the datepicker.
   * @param event - focus out event with the related target
   */
  protected override onBlur(event: FocusEvent): void {
    const target = event.relatedTarget as HTMLElement;
    if (!this.externalTrigger && !this.overlayToggle.contains(target)) {
      this.overlayToggle.closeOverlay();
      this.onTouched();
    }
  }

  @HostListener('keydown.tab')
  protected onTab(): void {
    if (this.overlayToggle.isShown()) {
      this.overlayToggle.closeOverlay();
    }
  }

  /**
   * @internal
   */
  public show(initialFocus = false): void {
    if (this.disabled || this.readonly || this.overlayToggle.isShown()) {
      return;
    }

    this.subscribeDateChanges(
      this.overlayToggle.showOverlay(initialFocus, {
        config: this.siDatepickerConfig,
        date: this.date,
        time12h: this.getTime12h()
      })
    );
  }

  /**
   * @internal
   */
  public useExternalTrigger(element: ElementRef<HTMLElement>): void {
    this.externalTrigger = element;
  }

  @HostListener('focus')
  protected focusChange(): void {
    if (!this.externalTrigger) {
      this.show();
    }
  }

  private getTime12h(): boolean | undefined {
    const dateFormat = getDatepickerFormat(this.locale, this.siDatepickerConfig, true);
    return dateFormat?.includes('a');
  }

  private subscribeDateChanges(overlay?: ComponentRef<SiDatepickerOverlayComponent>): void {
    this.overlaySubscriptions?.forEach(s => s.unsubscribe());

    overlay?.instance.dateChange
      .pipe(takeUntil(this.destroyer$))
      .subscribe(d => this.onDateChanged(d));
    overlay?.instance.disabledTimeChange
      .pipe(takeUntil(this.destroyer$))
      .subscribe(d => this.onDisabledTime(d));
  }

  /**
   * Callback when the datepicker changes his value.
   * @param date - updated date
   */
  protected override onDateChanged(date: Date): void {
    super.onDateChanged(date);
    if (this.autoClose) {
      // a tick later so the event won't end on the wrong element
      setTimeout(() => this.overlayToggle.closeAfterSelection());
    }
  }
}
