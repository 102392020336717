import { CdkMenuTrigger } from '@angular/cdk/menu';
import { NgClass } from '@angular/common';
import {
  booleanAttribute,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { MenuItem } from '@simpl/element-ng';
import { SiIconComponent } from '@simpl/element-ng/icon';
import { SiMenuFactoryComponent } from '@simpl/element-ng/menu';
import { SiTranslateModule } from '@simpl/element-translate-ng/translate';

import { SiStepperComponent } from './si-stepper.component';
import { StepAction, StepActionEvent, StepState } from './si-stepper.model';

/**
 * @deprecated The {@link SiStepperStepComponent} should no longer be used and
 * will be removed in Element v47. The stepper is now provided by `@simpl/buildings-ng`.
 */
@Component({
  selector: 'si-stepper-step',
  templateUrl: './si-stepper-step.component.html',
  styleUrl: './si-stepper-step.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CdkMenuTrigger, NgClass, SiIconComponent, SiMenuFactoryComponent, SiTranslateModule]
})
export class SiStepperStepComponent implements OnChanges {
  protected show = true;
  protected active = false;
  protected menuOpen = false;

  /** Step title */
  @Input({ required: true })
  heading!: string;
  /** Step state */
  @Input() state!: StepState;

  /**
   * Disables action buttons
   *
   * @defaultValue false
   */
  @Input({ transform: booleanAttribute }) disabled = false;

  private cdRef = inject(ChangeDetectorRef);
  private parent?: SiStepperComponent;

  /**
   * Only required steps shown when mandatory switch is on
   *
   * @defaultValue false
   */
  @Input({ transform: booleanAttribute }) required = false;

  /** Primary action visible as button */
  @Input() primaryAction?: StepAction;
  /** Actions visible within ellipsis menu */
  @Input() secondaryActions?: StepAction[];
  /**
   * Toggle item label a11y
   *
   * @defaultValue
   * ```
   * $localize`:@@SI_STEPPER.TOGGLE_MENU:Toggle step menu`
   * ```
   */
  @Input() toggleLabel = $localize`:@@SI_STEPPER.TOGGLE_MENU:Toggle step menu`;

  protected secondaryActionsArr?: MenuItem[];

  /** Returns step action to the consumer */
  @Output() readonly stepAction = new EventEmitter<StepActionEvent>();

  /** Outputs whenever this step becomes active or the active state is removed again */
  @Output() readonly activeStateChange = new EventEmitter<boolean>();

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.secondaryActions || changes.disabled) {
      this.secondaryActionsArr = this.secondaryActions?.map((a: StepAction) => this.mapAction(a));
    }
  }

  /** @internal */
  updateActive(active: boolean): void {
    if (this.active !== active) {
      this.active = active;
      this.activeStateChange.emit(this.active);
      this.cdRef.markForCheck();
    }
  }

  /** @internal */
  updateShow(show: boolean): void {
    this.show = show;
    this.cdRef.markForCheck();
  }

  /**
   * Callback action of a step.
   * @param type - Use action type to set behaviour on specific action
   */
  protected actionCallback(type: string): void {
    this.stepAction.emit({ type });
  }

  /**
   * Converts step action into MenuItem for content action bar usage
   * @param action - Step action object
   * @returns MenuItem compatible with content action bar
   */
  private mapAction(action: StepAction): MenuItem {
    return {
      title: action.title,
      action: () => this.actionCallback(action.type),
      icon: action.icon,
      disabled: this.disabled || action.disabled
    };
  }

  /** @internal */
  registerParent(siStepperComponent: SiStepperComponent): void {
    this.parent = siStepperComponent;
  }

  protected stepClicked(): void {
    this.parent?.updateActiveStep(this);
  }
}
