import { Inject, Injectable } from "@angular/core";
import { HfwFilterPillData } from "@gms-flex/controls";
import { Event, EventFilter, EventStates, SortingDirection, TextEntry } from "@gms-flex/services";
import { AppContextService, isNullOrUndefined, TraceService } from "@gms-flex/services-common";
import { enumColumnType, EventListSettingsServiceBase, HeaderData } from "@gms-flex/snapin-common";
import { TranslateService } from "@ngx-translate/core";
import { TreeItem } from "@simpl/element-ng";
import { Observable, of, Subscription } from "rxjs";
import { TraceModules } from "src/app/core/shared/trace-modules";

@Injectable()
export class EventListSettingsBxSubstituteService extends EventListSettingsServiceBase {

  // #region variables

  // those label are used to process default settings
  public readonly columnsLabelList: string[] = ['eventIcon', 'cause', 'state', 'informationalText', 'creationTime',
    'timer', 'srcSource', 'belongsTo', 'srcPath', 'suggestedAction', 'messageText',
    'inProcessBy', 'srcAlias', 'buildingName'];
  
  public readonly cellSupportedData: string[] = ['eventId', 'creationTime', 'messageText', 'cause', 'state',
    'srcState', 'inProcessBy', 'suggestedAction', 'recursation', 'timer', 'informationalText', 'srcAlias', 'buildingName'];
    
  private displayParams: any;
  // Free text filter pill label
  private searchTextFilterLabel = ''; // "Search text"

  // Popover filter pill labels
  private designationFilterLabel; // Designation
  private sourcePropertyIdFilterLabel; // Designation

  // column name variables
  private columnNameCause = ''; //  "Event Cause"
  private columnNameCategory = ''; // "Category"
  private columnNameDateTime = ''; // "DateTime"
  private columnNameEventStatus = ''; // "Event Status"
  private columnNamePath = ''; // "Path"
  private columnNameSource = ''; // "Source"
  private columnNameBelongsTo = ''; // "Belongs to"
  private columnNameBuildingName = ''; // "Building Name"

  // event state filter variables
  private eventStateUnprocessed = ""; // "Unprocessed";
  private eventStateClosed = ""; // "Closed";

  // source state filter variables
  private sourceStateActive = ""; // "Active";
  private sourceStateQuiet = ""; // "Quiet";

  private readonly userLang: string;

  private readonly subscriptions: Subscription[] = [];

  // #endregion variables

  public constructor(
    @Inject(TranslateService) private readonly translateService: TranslateService,
    private readonly appContextService: AppContextService,
    private readonly traceService: TraceService
  ) {
    super();
    // user culture
    this.userLang = this.translateService.getBrowserLang() ?? this.translateService.defaultLang;

    this.subscriptions.push(
      this.appContextService.defaultCulture.subscribe(
        (defaultCulture: string) => {
          if (defaultCulture !== null) {
            this.translateService.setDefaultLang(defaultCulture);
          } else {
            this.traceService.warn(
              TraceModules.eventListSettings,
              "No default Culture for appContextService"
            );
            this.translateService.setDefaultLang(this.userLang);
          }
        }
      )
    );

    this.subscriptions.push(
      this.appContextService.userCulture.subscribe((userCulture: string) => {
        if (userCulture !== null) {
          this.translateService.use(userCulture).subscribe((res: any) => {
            this.traceService.info(
              TraceModules.eventListSettings,
              "use  user Culture"
            );
          });
        } else {
          this.traceService.warn(
            TraceModules.eventListSettings,
            "No user Culture for appContextService"
          );
        }
      })
    );

    this.subscriptions.push(
      this.translateService
        .get([
          // #region string list
          "EVENTS.DESIGNATION-FILTER-LABEL",
          /* eslint-disable @typescript-eslint/naming-convention */
          "EVENTS.SOURCEPROPERTYID-FILTER-LABEL",
          /* eslint-disable @typescript-eslint/naming-convention */
          "EVENTS.SEARCH-TEXT-FILTER-LABEL",
          "EVENTS.DATE-TIME-FILTER-LABEL",
          "EVENTS.TIME-EMPTY-FILTER-LABEL",
          "EVENTS.TIME-LAST-QUARTER-HOUR-FILTER-LABEL",
          "EVENTS.TIME-LAST-HALF-HOUR-FILTER-LABEL",
          "EVENTS.TIME-LAST-HOUR-FILTER-LABEL",
          "EVENTS.TIME-LAST-NIGHT-FILTER-LABEL",
          "EVENTS.TIME-YESTERDAY-FILTER-LABEL",
          "EVENTS.TIME-TODAY-FILTER-LABEL",
          "EVENTS.DISCIPLINE-FILTER-LABEL",
          "EVENTS.CATEGORY-FILTER-LABEL",
          "EVENTS.STATE-FILTER-LABEL",
          "EVENTS.SRC-STATE-FILTER-LABEL",
          "EVENTS.SRC-SYSTEM-FILTER-LABEL",
          "EVENTS.HIDDEN-EVENTS-FILTER-LABEL",
          "EVENTS.CONTENT-ACTION-FILTER-LABEL",
          "EVENTS.EVENT-STATE-UNPROCESSED",
          "EVENTS.HIDDEN-EVENTS-SHOW-LABEL",
          "EVENTS.EVENT-STATE-CLOSED",
          "EVENTS.SOURCE-STATE-ACTIVE",
          "EVENTS.SOURCE-STATE-QUIET",
          'EVENTS.COLUMN-NAME-CAUSE',
          'EVENTS.COLUMN-NAME-DESCRIPTION',
          'EVENTS.COLUMN-NAME-NAME',
          'EVENTS.COLUMN-NAME-LOCATION',
          'EVENTS.COLUMN-NAME-DATE-TIME',
          'EVENTS.COLUMN-NAME-EVENT-STATUS',
          'EVENTS.COLUMN-NAME-SOURCE-STATUS',
          'EVENTS.COLUMN-CUSTOMIZE-TITLE',
          'EVENTS.COLUMN-CUSTOMIZE-DESCRIPTION',
          'EVENTS.COLUMN-NAME-CATEGORY',
          'EVENTS.COLUMN-NAME-SOURCE',
          'EVENTS.COLUMN-NAME-BELONGSTO',
          'EVENTS.COLUMN-NAME-PATH',
          'EVENTS.COLUMN-NAME-BUILDING-NAME'
        ])
        .subscribe(value => {
          this.designationFilterLabel = value['EVENTS.DESIGNATION-FILTER-LABEL'];
          this.sourcePropertyIdFilterLabel = value["EVENTS.SOURCEPROPERTYID-FILTER-LABEL"];
          this.searchTextFilterLabel = value['EVENTS.SEARCH-TEXT-FILTER-LABEL'];
          this.eventStateUnprocessed = value["EVENTS.EVENT-STATE-UNPROCESSED"];
          this.eventStateClosed = value["EVENTS.EVENT-STATE-CLOSED"];
          this.sourceStateActive = value["EVENTS.SOURCE-STATE-ACTIVE"];
          this.sourceStateQuiet = value["EVENTS.SOURCE-STATE-QUIET"];
          this.columnNameCause = value['EVENTS.COLUMN-NAME-CAUSE'];
          this.columnNameCategory = value['EVENTS.COLUMN-NAME-CATEGORY'];
          this.columnNameDateTime = value['EVENTS.COLUMN-NAME-DATE-TIME'];
          this.columnNameEventStatus = value['EVENTS.COLUMN-NAME-EVENT-STATUS'];
          this.columnNameBelongsTo = value['EVENTS.COLUMN-NAME-BELONGSTO'];
          this.columnNamePath = value['EVENTS.COLUMN-NAME-PATH'];
          this.columnNameSource = value['EVENTS.COLUMN-NAME-SOURCE'];
          this.columnNameBuildingName = value['EVENTS.COLUMN-NAME-BUILDING-NAME'];
        })
    );

    this.traceService.info(TraceModules.eventListSettings, 'EventListSettingsBxSubstituteService created.');
  }

  // #region configs

  public getDisciplines(): Observable<TextEntry[]> {
    return of([]);
  }

  public getInvestigativeSettings(): any {
    return {
      showAckButton: false,
      showSecondaryAction: false
    }
  }

  // #endregion configs

  // #region columns

  public getAvailableColumns(displayParams: any): HeaderData[] {

    this.displayParams = displayParams;
    const hdrData: HeaderData[] = [];

    let newHeaderEntry: HeaderData = {
      id: 'eventIcon',
      label: this.columnNameCategory,
      columnType: enumColumnType.EventIcon,
      columnVisible: this.setVisibility('eventIcon', true),
      minColWidth: 90,
      isFixedSize: true,
      widthPercentage: 10,
      allowSorting: false
    };
    hdrData.push(newHeaderEntry);

    newHeaderEntry = {
      id: 'cause',
      label: this.columnNameCause,
      columnType: enumColumnType.Text,
      columnVisible: this.setVisibility('eventIcon', true),
      minColWidth: displayParams.compactMode ? 80 : 230,
      isFixedSize: displayParams?.isULC ? true : false,
      widthPercentage: 10,
      allowSorting: true
    };
    hdrData.push(newHeaderEntry);

    newHeaderEntry = {
      id: 'state',
      label: this.columnNameEventStatus,
      columnType: enumColumnType.State,
      columnVisible: this.setVisibility('state', true),
      minColWidth: displayParams?.compactMode ? 28 : 120,
      isFixedSize: true,
      widthPercentage: 10,
      allowSorting: true
    };
    hdrData.push(newHeaderEntry);

    newHeaderEntry = {
      id: 'creationTime',
      label: this.columnNameDateTime,
      columnType: enumColumnType.Text,
      columnVisible: this.setVisibility('creationTime', true),
      minColWidth: displayParams?.compactMode ? 90 : 160,
      isFixedSize: true,
      widthPercentage: 10,
      allowSorting: true
    };
    hdrData.push(newHeaderEntry);
    
    newHeaderEntry = {
      id: 'buildingName',
      label: this.columnNameBuildingName,
      columnType: enumColumnType.Text,
      columnVisible: this.setVisibility('buildingName', false),
      minColWidth: displayParams?.compactMode ? 90 : 160,
      isFixedSize: false,
      widthPercentage: 10,
      allowSorting: true
    };
    hdrData.push(newHeaderEntry);

    newHeaderEntry = {
      id: 'srcSource',
      label: this.columnNameSource,
      columnType: enumColumnType.Text,
      columnVisible: this.setVisibility('srcSource', true),
      minColWidth: displayParams?.isULC ? 250 : 100,
      isFixedSize: displayParams?.isULC ? true : false,
      widthPercentage: 10,
      allowSorting: true
    };
    hdrData.push(newHeaderEntry);

    newHeaderEntry = {
      id: 'belongsTo',
      label: this.columnNameBelongsTo,
      columnType: enumColumnType.Text,
      columnVisible: this.setVisibility('belongsTo', true),
      minColWidth: 100,
      isFixedSize: false,
      widthPercentage: 10,
      allowSorting: true
    };
    hdrData.push(newHeaderEntry);

    newHeaderEntry = {
      id: 'srcPath',
      label: this.columnNamePath,
      columnType: enumColumnType.Text,
      columnVisible: this.setVisibility('srcPath', false),
      minColWidth: displayParams?.isULC ? 300 : 100,
      isFixedSize: false,
      widthPercentage: 10,
      allowSorting: true
    };
    hdrData.push(newHeaderEntry);

    return hdrData;
  }
  // #endregion columns

  // #region filters

  public getStateFilter(): any {
    // Event State Tree
    let item: TreeItem;
    const stateTree: TreeItem[] = [];

    item = {
      label: this.eventStateUnprocessed,
      state: "leaf",
      customData: ["Unprocessed"]
    };
    stateTree.push(item);

    item = {
      label: this.eventStateClosed,
      state: "leaf",
      customData: ["Closed"]
    };
    stateTree.push(item);

    return stateTree;
  }

  public getSourceFilter(): any {
    // Event Source State
    let item: TreeItem;
    const srcStateTree: TreeItem[] = [];

    item = {
      label: this.sourceStateActive,
      state: "leaf",
      customData: ["Active"]
    };
    srcStateTree.push(item);

    item = {
      label: this.sourceStateQuiet,
      state: "leaf",
      customData: ["Quiet"]
    };
    srcStateTree.push(item);

    return srcStateTree;
  }

  public getHiddenFilter(): any {
    // Hidden Events
    const hiddenEventsTree: TreeItem[] = [];

    return hiddenEventsTree;
  }

  public getFilterCriteria(eventFilter: EventFilter, pillDataArr: HfwFilterPillData[]): HfwFilterPillData[] {
    if (eventFilter == null) {
      return pillDataArr;
    }

    // Popover filter
    if (eventFilter.srcDesignations !== undefined && eventFilter.srcDesignations.length > 0 ||
      eventFilter.srcPropertyIds !== undefined && eventFilter.srcPropertyIds.length > 0) {
      const values: string[] = [];
      values.push(eventFilter.srcDesignations ? eventFilter.srcDesignations[0] : eventFilter.srcPropertyIds[0]);
      pillDataArr.push(new HfwFilterPillData(6, eventFilter.srcDesignations ? this.designationFilterLabel : this.sourcePropertyIdFilterLabel, values));
    }
    
    // Free text filter
    if (eventFilter.searchText !== undefined && eventFilter.searchText.length > 0) {
      const values: string[] = [];
      values.push(eventFilter.searchText);
      pillDataArr.push(new HfwFilterPillData(12, this.searchTextFilterLabel, values));
    }
    return pillDataArr;
  }

  public getAllowedFilters(): any {
    const allowedFilterPillData: string[] = [];
    return allowedFilterPillData;
  }

  // #endregion filters

  // #region eventsUtils
  
  /**
   * this method is NOT meant to be used outside the events components snapins,
   * it should be used to compare two events, and decide the order of the events according to the current ordering
   * @param ev1 the first event we want to compare
   * @param ev2 the second event we want to compare
   * @returns 1 if ev1 have an higher priority then ev2, -1 for the opposite case, 0 if there is no order difference
   */
  public compareEvents(ev1: Event, ev2: Event): number {
    if (this.sortingCriteria.length === 0) {
      return 0;
    }

    let ret = 0;

    for (const sorting of this.sortingCriteria) {
      if (sorting.sortingDir === SortingDirection.NONE) {
        continue;
      }
      switch (sorting.id) {
        case 'processing':
          if (ev1.stateId !== ev2.stateId) {
            if (ev1.stateId === EventStates.Unprocessed || ev1.stateId === EventStates.UnprocessedWithTimer) {
              ret = 1;
              break;
            }
            if (ev2.stateId === EventStates.Unprocessed || ev2.stateId === EventStates.UnprocessedWithTimer) {
              ret = -1;
              break;
            }
          }
          break;
        case 'state':
          if (ev1.statePriority > ev2.statePriority) {
            ret = (sorting.sortingDir === SortingDirection.DESCENDING) ? 1 : -1;
            break;
          }
          if (ev1.srcState < ev2.srcState) {
            ret = (sorting.sortingDir === SortingDirection.DESCENDING) ? -1 : 1;
            break;
          }
          break;
        case 'categoryDescriptor':
          if (ev1.categoryId > ev2.categoryId) {
            ret = (sorting.sortingDir === SortingDirection.DESCENDING) ? 1 : -1;
            break;
          }
          if (ev1.categoryId < ev2.categoryId) {
            ret = (sorting.sortingDir === SortingDirection.DESCENDING) ? -1 : 1;
            break;
          }
          break;
        case 'creationTime':
          if (ev1.originalCreationTime > ev2.originalCreationTime) {
            ret = (sorting.sortingDir === SortingDirection.DESCENDING) ? 1 : -1;
            break;
          }
          if (ev1.originalCreationTime < ev2.originalCreationTime) {
            ret = (sorting.sortingDir === SortingDirection.DESCENDING) ? -1 : 1;
            break;
          }
          break;
        case 'messageText':
          if (ev1.messageTextToDisplay > ev2.messageTextToDisplay) {
            ret = (sorting.sortingDir === SortingDirection.DESCENDING) ? 1 : -1;
            break;
          }
          if (ev1.messageTextToDisplay < ev2.messageTextToDisplay) {
            ret = (sorting.sortingDir === SortingDirection.DESCENDING) ? -1 : 1;
            break;
          }
          break;
        case 'cause':
          if (ev1.cause > ev2.cause) {
            ret = (sorting.sortingDir === SortingDirection.DESCENDING) ? 1 : -1;
            break;
          }
          if (ev1.cause < ev2.cause) {
            ret = (sorting.sortingDir === SortingDirection.DESCENDING) ? -1 : 1;
            break;
          }
          break;
        case 'srcPath':
          if (this.getEventSrcPath(ev1) > this.getEventSrcPath(ev2)) {
            ret = (sorting.sortingDir === SortingDirection.DESCENDING) ? 1 : -1;
            break;
          }
          if (this.getEventSrcPath(ev1) < this.getEventSrcPath(ev2)) {
            ret = (sorting.sortingDir === SortingDirection.DESCENDING) ? -1 : 1;
            break;
          }
          break;
        case 'srcSource':
          if (ev1.sourceFltr > ev2.sourceFltr) {
            ret = (sorting.sortingDir === SortingDirection.DESCENDING) ? 1 : -1;
            break;
          }
          if (ev1.sourceFltr < ev2.sourceFltr) {
            ret = (sorting.sortingDir === SortingDirection.DESCENDING) ? -1 : 1;
            break;
          }
          break;
        case 'belongsTo':
          if (ev1.belongsToFltr > ev2.belongsToFltr) {
            ret = (sorting.sortingDir === SortingDirection.DESCENDING) ? 1 : -1;
            break;
          }
          if (ev1.belongsToFltr < ev2.belongsToFltr) {
            ret = (sorting.sortingDir === SortingDirection.DESCENDING) ? -1 : 1;
            break;
          }
          break;
        case 'informationalText':
          if (ev1.informationalText > ev2.informationalText) {
            ret = (sorting.sortingDir === SortingDirection.DESCENDING) ? 1 : -1;
            break;
          }
          if (ev1.informationalText < ev2.informationalText) {
            ret = (sorting.sortingDir === SortingDirection.DESCENDING) ? -1 : 1;
            break;
          }
          break;
        case 'suggestedAction':
          if (ev1.suggestedAction > ev2.suggestedAction) {
            ret = (sorting.sortingDir === SortingDirection.DESCENDING) ? 1 : -1;
            break;
          }
          if (ev1.suggestedAction < ev2.suggestedAction) {
            ret = (sorting.sortingDir === SortingDirection.DESCENDING) ? -1 : 1;
            break;
          }
          break;
        case 'inProcessBy':
          if ((ev1.inProcessBy ?? "") > (ev2.inProcessBy ?? "")) {
            ret = (sorting.sortingDir === SortingDirection.DESCENDING) ? 1 : -1;
            break;
          }
          if ((ev1.inProcessBy ?? "") < (ev2.inProcessBy ?? "")) {
            ret = (sorting.sortingDir === SortingDirection.DESCENDING) ? -1 : 1;
            break;
          }
          if (isNullOrUndefined(ev1.inProcessBy) !== isNullOrUndefined(ev2.inProcessBy)) {
            if (isNullOrUndefined(ev1.inProcessBy)) {
              ret = sorting.sortingDir === SortingDirection.DESCENDING ? -1 : 1;
            } else {
              ret = sorting.sortingDir === SortingDirection.DESCENDING ? 1 : -1;
            }
          }
          break;
        case 'srcAlias':
          if ((ev1.srcAlias ?? "") > (ev2.srcAlias ?? "")) {
            ret = (sorting.sortingDir === SortingDirection.DESCENDING) ? 1 : -1;
            break;
          }
          if ((ev1.srcAlias ?? "") < (ev2.srcAlias ?? "")) {
            ret = (sorting.sortingDir === SortingDirection.DESCENDING) ? -1 : 1;
            break;
          }
          if (isNullOrUndefined(ev1.srcAlias) !== isNullOrUndefined(ev2.srcAlias)) {
            if (isNullOrUndefined(ev1.srcAlias)) {
              ret = sorting.sortingDir === SortingDirection.DESCENDING ? -1 : 1;
            } else {
              ret = sorting.sortingDir === SortingDirection.DESCENDING ? 1 : -1;
            }
          }
          break;
        case 'buildingName': {
          if (ev1.BuildingName ?? "" > ev2.BuildingName ?? "") {
            ret = (sorting.sortingDir === SortingDirection.DESCENDING) ? 1 : -1;
            break;
          }
          if (ev1.BuildingName ?? "" < ev2.BuildingName ?? "") {
            ret = (sorting.sortingDir === SortingDirection.DESCENDING) ? -1 : 1;
            break;
          }
        }
          break;
        case 'InOut': {
          if (ev1.direction > ev2.direction) {
            ret = (sorting.sortingDir === SortingDirection.DESCENDING) ? 1 : -1;
          } else if (ev1.direction < ev2.direction) {
            ret = (sorting.sortingDir === SortingDirection.DESCENDING) ? -1 : 1;
          }
          break;
        }
        default: {
          break;
        }
      }
      if (ret !== 0) {
        break;
      }
    }
    if (ret === 0 && ev1.eventId !== ev2.eventId) {
      ret = ev1.eventId > ev2.eventId ? 1 : -1;
    }
    return ret;
  }

  private setVisibility(name: string, defaultVal: boolean): boolean {
    return this.displayParams?.visibleColumns?.length > 0 ? this.displayParams.visibleColumns.includes(name) : defaultVal;
  }
  // #endregion eventsUtils
}
