import { Directive } from '@angular/core';

import { EnumeratedBreadcrumbItem } from './breadcrumb-item.model';

@Directive({ standalone: true, selector: '[siBreadcrumbItemTemplate]' })
export class SiBreadcrumbItemTemplateDirective {
  static ngTemplateContextGuard(
    directive: SiBreadcrumbItemTemplateDirective,
    context: unknown
  ): context is { item: EnumeratedBreadcrumbItem; title?: string } {
    return true;
  }
}
