import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
  booleanAttribute,
  ComponentRef,
  Directive,
  ElementRef,
  HostBinding,
  HostListener,
  inject,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef
} from '@angular/core';
import { getOverlay, getPositionStrategy, positions } from '@simpl/element-ng/common';
import { TranslatableString } from '@simpl/element-translate-ng/translate';

import { TooltipComponent } from './si-tooltip.component';

@Directive({
  selector: '[siTooltip]',
  standalone: true
})
export class SiTooltipDirective implements OnInit, OnDestroy {
  private static idCounter = 0;

  /**
   * The tooltip text to be displayed
   *
   * @defaultValue ''
   */
  @Input() siTooltip: TranslatableString | TemplateRef<any> = '';

  private _placement!: keyof typeof positions;

  /**
   * The placement of the tooltip. One of 'top', 'start', end', 'bottom'
   */
  @Input() set placement(value: keyof typeof positions) {
    this._placement = value;
  }

  get placement(): keyof typeof positions {
    return this._placement;
  }

  /**
   * The trigger event on which the tooltip shall be displayed
   */
  @Input() triggers!: '' | 'focus';

  /**
   * Allows the tooltip to be disabled
   *
   * @defaultValue false
   */
  @Input({ transform: booleanAttribute }) isDisabled = false;

  @HostBinding('attr.aria-describedby') protected describedBy =
    `__tooltip_${SiTooltipDirective.idCounter++}`;

  private overlayref?: OverlayRef;
  private overlay = inject(Overlay);
  private elementRef = inject(ElementRef);

  ngOnInit(): void {
    if (!this.placement || !positions[this.placement]) {
      this.placement = 'auto';
    }
  }

  ngOnDestroy(): void {
    this.overlayref?.dispose();
  }

  private showTooltip(): void {
    if (this.isDisabled || !this.siTooltip) {
      return;
    }
    if (!this.overlayref?.hasAttached()) {
      this.overlayref = getOverlay(this.elementRef, this.overlay, false, this.placement);
      this.overlayref.addPanelClass('pe-none');
    }

    if (this.overlayref.hasAttached()) {
      return;
    }
    const toolTipPortal = new ComponentPortal(TooltipComponent);
    const tooltipRef: ComponentRef<TooltipComponent> = this.overlayref.attach(toolTipPortal);

    tooltipRef.instance.tooltip = this.siTooltip;
    tooltipRef.instance.id = this.describedBy;

    const positionStrategy = getPositionStrategy(this.overlayref);
    positionStrategy?.positionChanges.subscribe(change =>
      tooltipRef.instance.updateTooltipPosition(change, this.elementRef)
    );

    tooltipRef.changeDetectorRef.detectChanges();
  }

  @HostListener('focus')
  protected focusIn(): void {
    this.showTooltip();
  }

  @HostListener('mouseenter')
  protected show(): void {
    if (this.triggers === 'focus') {
      return;
    }
    this.showTooltip();
  }

  @HostListener('touchstart')
  @HostListener('focusout')
  protected hide(): void {
    this.overlayref?.detach();
  }

  @HostListener('mouseleave')
  protected mouseOut(): void {
    if (this.triggers === 'focus') {
      return;
    }
    this.hide();
  }
}
