import { NgModule } from '@angular/core';

import { SiDropdownMenuDirective } from './si-dropdown-menu.directive';
import { SiDropdownToggleDirective } from './si-dropdown-toggle.directive';
import { SiDropdownDirective } from './si-dropdown.directive';

/**
 * @deprecated The {@link SiDropdownModule} and its related directives should no longer be used.
 * - For creating menus, use {@link SiMenuModule} instead: https://simpl.code.siemens.io/simpl-element/components/buttons-menus/menu/
 * - For creating plain overlays, use the {@link https://material.angular.io/cdk/overlay/overview | CDK Overlay}: https://simpl.code.siemens.io/simpl-element/components/buttons-menus/dropdowns/
 */
@NgModule({
  imports: [SiDropdownDirective, SiDropdownMenuDirective, SiDropdownToggleDirective],
  exports: [SiDropdownDirective, SiDropdownMenuDirective, SiDropdownToggleDirective]
})
export class SiDropdownModule {}
