import { CommandParameters } from '@gms-flex/services';
import { isNullOrUndefined, TraceService } from '@gms-flex/services-common';
import { AnyPropertyValueType } from '@simpl/buildings-ng';
import { AnyValueType, NumberValue } from '@simpl/element-value-types';

import { Common } from '../shared/common';
import { CommandParamViewModel } from './command-param-vm';
import { CommandParamType, NumericType } from './command-vm.types';
import { WsiTranslator } from './data-model-helper';
import { ViewModelContext } from './snapin-vm.types';

/**
 * Numeric parameter view-model implementation.
 */
export class NumericParamViewModel extends CommandParamViewModel {

  private min: number;
  private max: number;
  private res: number;
  private defaultVal: number;
  private type: NumericType;

  /**
   * Minimum allowed value.
   */
  public get minValue(): number {
    return this.min;
  }

  /**
   * Maximum allowed value.
   */
  public get maxValue(): number {
    return this.max;
  }

  /**
   * Resolution.
   */
  public get resolution(): number {
    return this.res;
  }

  /**
   * Default parameter value.
   */
  // public get defaultValue(): number {
  //   return this.defaultVal;
  // }

  /**
   * Type of numeric value.
   */
  // public get numericType(): NumericType {
  //   return this.type;
  // }

  /**
   * Parameter data type.
   */
  public get dataType(): CommandParamType {
    return CommandParamType.Numeric;
  }

  private get engUnits(): string {
    return this.param.UnitDescriptor;
  }

  /**
   * Constructor.
   */
  public constructor(
    traceService: TraceService,
    vmContext: ViewModelContext,
    param: CommandParameters) {

    super(traceService, vmContext, param);

    // Initialize the si-param value
    this.siParam.value = {
      type: 'number',
      readonly: false,
      optional: false,
      value: undefined
    } as NumberValue;

    this.updateParamAttributes();
    this.resetParamValue();
  }

  /**
   * Decode the provided object as a number and encoded it as a string that can be passed
   * to the WSI as a command parameter value.
   *
   * If the provided object cannot at first be converted to a number, undefined will be returned
   * to indicate `invalid` argument.
   */
  public encodeValue(valObj: AnyValueType | AnyPropertyValueType): string {
    let valEnc: string;
    if (valObj && valObj.type === 'number') {
      valEnc = WsiTranslator.encodeNumeric(valObj.value);
    }
    return valEnc;
  }

  public resetParamValue(): void {
    const siVal: NumberValue = this.siParam.value as NumberValue;
    siVal.value = !isNaN(this.defaultVal) ? this.defaultVal : undefined;
  }

  /**
   * Align this parameter with the provided param.
   */
  public alignParam(p: CommandParamViewModel): boolean {
    if (!p || p.dataType !== CommandParamType.Numeric) {
      return false; // undefined param or type mismatch!
    }
    const param: NumericParamViewModel = p as NumericParamViewModel;

    // Cannot align if engineering units are not the same!
    if (this.engUnits !== param.engUnits) {
      return false;
    }
    // Cannot align if min/max value range is not the same!
    if (!Common.isEqualNumber(this.minValue, param.minValue) ||
      !Common.isEqualNumber(this.maxValue, param.maxValue)) {
      return false;
    }

    // Set resolution to max of current value and alignment value (float params only!)
    if (this.type === NumericType.Float && !isNaN(param.resolution)) {
      this.res = Math.max(this.res, param.resolution);
    }

    // If default value of alignment param does not match, clear the local default value
    if (!Common.isEqualNumber(this.defaultVal, param.defaultVal)) {
      this.defaultVal = undefined;
    }

    return true;
  }

  protected updateParamAttributes(): void {
    const p: CommandParameters = this.param;
    if (!p) {
      return;
    }

    const int32Min = -(2147483648);
    const int32Max = 2147483647;
    const uint32Min = 0;
    const uint32Max = 4294967295;
    const charMin = 0;
    const charMax = 255;

    // Set numeric-type and min/max values
    switch (this.nativeType) {
      case 'BasicInt':
      case 'ExtendedInt':
        this.type = NumericType.Integer;
        this.res = 0;
        this.max = Common.limit(parseInt(p.Max, 10), 0, int32Min, int32Max, true);
        this.min = Common.limit(parseInt(p.Min, 10), 0, int32Min, this.max, false);
        break;

      case 'BasicUint':
      case 'ExtendedUint':
        this.type = NumericType.Integer;
        this.res = 0;
        this.max = Common.limit(parseInt(p.Max, 10), 0, uint32Min, uint32Max, true);
        this.min = Common.limit(parseInt(p.Min, 10), 0, uint32Min, this.max, false);
        break;

      case 'BasicChar':
        this.type = NumericType.Integer;
        this.res = 0;
        this.max = Common.limit(parseInt(p.Max, 10), 0, charMin, charMax, true);
        this.min = Common.limit(parseInt(p.Min, 10), 0, charMin, this.max, false);
        break;

      case 'BasicBit32':
      case 'ExtendedBitString':
        // In the case where the numeric param vm is used to represent a bitstring, treat the param
        // as a 32-bit uint.  Parameters Min/Max will indicate the size of the bitstring, which can be
        // used to calculate max uint value.
        this.type = NumericType.Integer;
        this.res = 0;
        const loBit: number = Common.limit(parseInt(p.Min, 10), 0, 0, 31, false);
        const hiBit: number = Common.limit(parseInt(p.Max, 10), 0, loBit, 31, true);
        const size: number = Math.max(0, hiBit - loBit + 1);
        this.max = Math.pow(2, size) - 1;
        this.min = 0;
        break;

      default:
        this.type = NumericType.Float;
        this.res = !isNaN(p.Resolution) ? Common.limit(p.Resolution, 0, 0, 100, true) : 2;
        this.max = Common.limit(parseFloat(p.Max), this.res, Number.NEGATIVE_INFINITY, Number.POSITIVE_INFINITY, true);
        this.min = Common.limit(parseFloat(p.Min), this.res, Number.NEGATIVE_INFINITY, this.max, false);
        break;
    }

    // Set default value
    this.defaultVal = undefined;
    if (!isNullOrUndefined(p.DefaultValue)) {
      switch (this.type) {
        case NumericType.Integer:
          this.defaultVal = Common.limit(parseInt(p.DefaultValue, 10), 0, this.min, this.max, false);
          break;
        case NumericType.Float:
        default:
          this.defaultVal = Common.limit(parseFloat(p.DefaultValue), this.res, this.min, this.max, false);
          break;
      }
    }

    const siVal: NumberValue = this.siParam.value as NumberValue;
    siVal.decimalsAllowed = (this.type === NumericType.Float);
    siVal.resolution = 1 / Math.pow(10, this.res);
    siVal.min = this.min;
    siVal.max = this.max;
    siVal.unit = this.param.UnitDescriptor;
    this.resetParamValue();
  }

}
