import { FocusOrigin } from '@angular/cdk/a11y';
import { Observable, Subject } from 'rxjs';

import {
  CriterionDefinition,
  OptionCriterion,
  OptionType,
  SearchCriteria
} from './si-filtered-search.model';

export interface TypeaheadOptionCriterion extends OptionCriterion {
  selected?: boolean;
  translatedLabel: string;
}

export interface InternalCriterionDefinition extends CriterionDefinition {
  label: string;
  inputType: 'text' | 'number';
  step: '1' | 'any';
  translatedLabel: string;
}

export interface InternalCriterionValue {
  // TODO: remove with element v47
  /**
   * If the provided label diverges from the definition label, this property will hold the diverged value.
   * @deprecated CriterionValues should not override the label from the definition.
   */
  label?: string;
  /** Raw textual presentation of the current value. */
  value: string | string[];
  /** Should contain the translated value. Its content is only guaranteed to be correct if the criterion is being edited. */
  valueLabel?: string;
  operator?: string;
  dateValue?: Date;
  optionValue?: OptionCriterion[];
  disabledTime?: boolean;
  /** Not available for date and date-time. Fired whenever the input text changes. */
  inputChange?: Subject<string>;
  /** Only available if multi select is enabled. Fired whenever the selection changes. */
  selectionChange?: Subject<string[]>;
  /** Filtered, translated and lazy loaded list of options that should be shown for this criterion. */
  options?: Observable<TypeaheadOptionCriterion[]>;
  config: InternalCriterionDefinition;
  /** Tracks the current focus state. */
  focusState?: FocusOrigin;
}

export interface InternalSearchCriteria {
  internalCriteria: InternalCriterionValue[];
  value: string;
  /** Last emitted external model. */
  searchCriteria?: SearchCriteria;
}

/** Convert options to option criterions */
export const toOptionCriteria = (values?: OptionType[]): OptionCriterion[] =>
  values?.map(v =>
    typeof v === 'string'
      ? { value: v }
      : { label: v.label, value: v.value, iconClass: v.iconClass }
  ) ?? [];

/*
 * Update selected state the matching is based on value since plain
 * string options will automatically fill the value attribute with the
 * actual string value.
 */
export const selectOptions = (options: TypeaheadOptionCriterion[], toSelect: string[]): void =>
  options.forEach(val => (val.selected = toSelect.includes(val.value)));

/**
 * Difference by name, create an array that contains those elements of criteria's a that are not in criteria's b.
 * This operation is also sometimes called minus (-).
 */
export const differenceByName = (
  a: InternalCriterionDefinition[],
  b: InternalCriterionValue[]
): InternalCriterionDefinition[] =>
  a.filter(x => b.filter(y => y.config.name === x.name).length === 0);

/** Convert criteria to internal model criteria */
export const toInternalCriteria = (crit: CriterionDefinition): InternalCriterionDefinition => {
  return {
    ...crit,
    label: crit.label ?? crit.name,
    options: crit.options ?? [],
    inputType:
      crit.validationType === 'integer' || crit.validationType === 'float' ? 'number' : 'text',
    step: crit.validationType === 'integer' ? '1' : 'any',
    translatedLabel: crit.label ?? crit.name
  };
};
