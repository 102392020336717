import { Injectable } from '@angular/core';
import { BrowserObject, CnsHelperService, Designation, GmsManagedTypes, GraphicsService, ObjectNode, SystemBrowserServiceBase, SystemInfo, ViewNode } from '@gms-flex/services';
import { TraceService } from '@gms-flex/services-common';
import { forkJoin, Observable, of as observableOf, throwError } from 'rxjs';
import { catchError, filter, map, mergeMap, take, tap } from 'rxjs/operators';

import { TraceChannel } from '../common/trace-channel';
import { Datapoint } from '../processor/datapoint/gms-datapoint';
import { GraphicsDatapointHelper } from '../processor/datapoint/gms-datapoint-helper';
import { DataPointService } from './gms-datapoint2.service';
import { GmsSystemsService } from './gms-systems.service';

/**
 * Process selections of datapoints by designation string.
 */
@Injectable()
export class GmsBrowserObjectService {
  private readonly traceModule: string = TraceChannel.Services;
  private readonly browserObjectCache: Map<string, BrowserObject[]>;
  private primarySelection: BrowserObject = undefined;

  public constructor(
    private readonly systemBrowserService: SystemBrowserServiceBase,
    private readonly traceService: TraceService,
    private readonly gmsSystemsService: GmsSystemsService,
    private readonly datapointService: DataPointService,
    private readonly cnsHelperService: CnsHelperService,
    private readonly graphicService: GraphicsService) {
    this.browserObjectCache = new Map<string, BrowserObject[]>();
  }

  public SetPrimarySelection(primarySelection: BrowserObject): void {
    if (primarySelection === undefined) {
      this.traceService.warn(this.traceModule, `Primary selection is undefined`);
      return;
    }

    this.primarySelection = primarySelection;
  }

  /**
   * Resolve multiple BrowserObjects simultaneously
   * @param designationStrings
   * @returns observable array of resolved BrowserObjects
   */
  public collectBrowserObjects(designationStrings: string[]): Observable<BrowserObject[]> {
    const browserObjects: Observable<BrowserObject>[] = [];
    if (designationStrings.length === 0) {
      return observableOf([]);
    }

    // acquire browser objects for each selection
    designationStrings.forEach((ds: string) => {
      browserObjects.push(
        this.getObject(ds)
          .pipe(
            take(1),
            catchError((error: any) => throwError(error))
          ));
    });

    // return after all BrowserObjects are resolved
    return forkJoin(browserObjects);
  }

  /**
   * Resolve multiple child BrowserObjects simultaneously
   * @param designationStrings
   * @returns observable array of resolved BrowserObjects
   */
  public collectChildBrowserObjects(designationStrings: string[]): Observable<BrowserObject[]> {
    const browserObjects: Observable<BrowserObject>[] = [];
    if (designationStrings.length === 0) {
      return observableOf([]);
    }

    // acquire browser objects for each selection
    designationStrings.forEach((ds: string) => {
      browserObjects.push(
        this.getChildObject(ds)
          .pipe(
            take(1),
            catchError((error: any) => throwError(error))
          ));
    });

    // return after all BrowserObjects are resolved
    return forkJoin(browserObjects);
  }

  /**
   * Retrieve and return a BrowserObject from a designation string
   * @param designationString
   * @returns Observable<BrowserObject>
   */
  public getObject(designationString: string): Observable<BrowserObject> {

    // normalize semi-colons at the end of designation strings
    if (!designationString.includes(';')) {
      designationString = designationString + ';';
    }
    designationString = GraphicsDatapointHelper.RemoveLeadingSemicolons(designationString);

    // 1. get system number
    // Create and validate Designation from designationString
    const designation: Designation = new Designation(designationString);
    if (designation.systemName === undefined) {
      return observableOf(undefined);
    }

    // get datapoint referred to by designationString
    const datapoint: Datapoint = this.datapointService.GetByDesignation(designationString);

    if (datapoint === undefined) {
      this.traceService.warn(this.traceModule, `datapoint not resolved : ${designationString}`);
      return observableOf(undefined);
    }

    if (datapoint.Id === undefined) {
      this.traceService.warn(this.traceModule, `object ID not available for designation string: ${designationString}`);
      return observableOf(undefined);
    }

    // Designation to match the BrowserObject
    const designationToMatch: string = datapoint.Designation.endsWith(';') ? datapoint.Designation.slice(0, -1) : datapoint.Designation;
    if (this.browserObjectCache.has(datapoint.Id)) {
      const browserObjects: BrowserObject[] = this.browserObjectCache.get(datapoint.Id);
      return observableOf(this.getCurrentViewBrowserObject(browserObjects, designationToMatch));
    }

    // Resolve system Id from system name.
    return this.getSystemIdFromSystemName(designation.systemName).pipe(
      // Handle missing system id
      map(systemNumber => {
        if (systemNumber === undefined) {
          throw new Error(`no system number found for designation string: ${designationString}`);
        } else {
          return systemNumber;
        }
      }),
      // Search for BrowserObject
      mergeMap(systemNumber => this.searchNodes(systemNumber, datapoint.Id, designationToMatch)),
      catchError((e: any) => {
        this.traceService.warn(this.traceModule, `: ${e}`);
        return observableOf(undefined);
      })
    );
  }

  /**
   * For an object designation, get the first child object's BrowserObject which has graphics.
   * @param designationString
   * @returns Observable<BrowserObject>
   */
  public getChildObject(designationString: string): Observable<BrowserObject> {

    // normalize semi-colons at the end of designation strings
    if (!designationString.includes(';')) {
      designationString = designationString + ';';
    }
    designationString = GraphicsDatapointHelper.RemoveLeadingSemicolons(designationString);

    // Create and validate Designation from designationString
    const designation: Designation = new Designation(designationString);
    if (designation.systemName === undefined) {
      return observableOf(undefined);
    }

    // get datapoint referred to by designationString
    const datapoint: Datapoint = this.datapointService.GetByDesignation(designationString);

    if (datapoint === undefined) {
      this.traceService.warn(this.traceModule, `datapoint not resolved : ${designationString}`);
      return observableOf(undefined);
    }

    if (datapoint.Id === undefined) {
      this.traceService.warn(this.traceModule, `object ID not available for designation string: ${designationString}`);
      return observableOf(undefined);
    }

    const designationToMatch = designationString.endsWith(';') ? designationString.slice(0, -1) : designationString;
    const isGraphicDp: boolean = datapoint.ManagedTypeName === GmsManagedTypes.GRAPHIC.name
        || datapoint.ManagedTypeName === GmsManagedTypes.GRAPHIC_PAGE.name;
    const downwardNavigation = isGraphicDp === true ? false : true;

    return this.getSystemIdFromSystemName(designation.systemName).pipe(
      // Handle missing system id
      map(systemNumber => {
        if (systemNumber === undefined) {
          throw new Error(`no system number found for designation string: ${designationString}`);
        } else {
          return systemNumber;
        }
      }),
      mergeMap(systemNumber => {
        if (this.browserObjectCache.has(datapoint.Id)) {
          const browserObjects: BrowserObject[] = this.browserObjectCache.get(datapoint.Id);

          // Get matching browser object only from the current view
          const parentBO = this.getCurrentViewBrowserObject(browserObjects, designationToMatch, downwardNavigation);

          if (isGraphicDp === true) {
            return observableOf(parentBO);
          }

          if (parentBO !== undefined) {
            return this.graphicService.getChildWithGraphics(parentBO.Designation);
          }
        }

        // Get matching browser object only from the current view
        return this.searchNodes(systemNumber, datapoint.Id, designationToMatch, downwardNavigation).pipe(
          // Search for BrowserObject
          mergeMap((parentBO: BrowserObject) => {
            if (parentBO === undefined) {
              this.traceService.warn(this.traceModule, `parent dp browser object not found for designation string: ${designationToMatch}`);
              return observableOf(undefined);
            }

            if (isGraphicDp === true) {
              return observableOf(parentBO);
            }

            return this.graphicService.getChildWithGraphics(parentBO.Designation);
          }));
      }),
      catchError((e: any) => {
        this.traceService.warn(this.traceModule, `: ${e}`);
        return observableOf(undefined);
      })
    );
  }

  /**
   * Gets the appropriate BrowserObject
   * @param browserObjects from all the views for objectId
   * @param designation used to match the cns node
   * @param downwardNavigation -If downward navigation - ignore getting browser object from other views using view pecking order
   * returns the BrowserObject from the currently selected view in the system browser
   * or from priorized view order - User defined, Logical, Physical, Application, Management.
   */
  private getCurrentViewBrowserObject(browserObjects: BrowserObject[], designation: string, downwardNavigation: boolean = false): BrowserObject {

    let currentViewBrowserObj: BrowserObject;
    const currentViewBrowserObjects: BrowserObject[] = browserObjects.filter(browserObject => this
      .cnsHelperService.activeViewValue.containsObject(browserObject));

    // If parent dp exists multiple times in the current view
    if (downwardNavigation && currentViewBrowserObjects.length > 1) {
      // sort alphabetically
      currentViewBrowserObjects.sort((a, b) => a.Designation < b.Designation ? -1 : 1);

      // primary selection is the parent dp
      currentViewBrowserObj = this.primarySelection !== undefined ? currentViewBrowserObjects
        .find(browserObject => browserObject.Designation === this.primarySelection.Designation) : undefined;

      // primary selection is a parent of the parent dp
      if (currentViewBrowserObj === undefined) {
        currentViewBrowserObj = this.primarySelection !== undefined ? currentViewBrowserObjects
          .find(browserObject => browserObject.Designation.includes(this.primarySelection.Designation + '.')) : undefined;
      }

      if (currentViewBrowserObj !== undefined) {
        return currentViewBrowserObj;
      } else {
        return currentViewBrowserObjects[0];
      }
    }

    // If the object exists multiple times in the current view - match the designation
    currentViewBrowserObj = browserObjects.find(browserObject => this
      .cnsHelperService.activeViewValue.containsObject(browserObject) && browserObject.Designation === designation);

    // Get the current view browser object.
    if (currentViewBrowserObj === undefined) {
      currentViewBrowserObj = browserObjects.find(browserObject => this
        .cnsHelperService.activeViewValue.containsObject(browserObject));
    }

    if (currentViewBrowserObj !== undefined || downwardNavigation) {
      return currentViewBrowserObj;
    } else {
      // Has to be the first one based on the view pecking order.
      browserObjects.sort(CnsHelperService.compareBrowserObjects);
      return browserObjects[0];
    }
  }

  /**
   * Resolve system ID from designation string
   *
   * Wrapper for parseSystemIdFromSystemName
   * @param systemName
   * @returns system ID or error if not exists
   */
  private getSystemIdFromSystemName(systemName: string): Observable<string | undefined> {

    // SystemInfo[] representing all wsi systems: req'd by parseSystemIdFromSystemName
    const allSystemsObs: Observable<SystemInfo[]> = this.getAllSystems();

    return allSystemsObs.pipe(
      mergeMap((systemInfos: SystemInfo[]) => {
        const systemId: string = parseSystemIdFromSystemName(systemInfos, systemName);
        if (systemId === undefined) {
          return throwError(`System name: ${systemName} not found`);
        } else {
          return observableOf(systemId);
        }
      }),
      catchError((err: any) => throwError(err)));
  }

  /**
   * Get all wsi Systems
   */
  private getAllSystems(): Observable<SystemInfo[]> {
    this.gmsSystemsService.getSystems();
    return this.gmsSystemsService.isResolved.pipe(filter(val => val === true)).pipe(
      mergeMap((resolved: boolean) => observableOf(this.gmsSystemsService.allSystemInfos)));
  }

  /**
   * Resolve BrowserObject from objectId
   * Update the BrowserObject(s) cache on resolution.
   * @param systemId Id of wsi system
   * @param objectId search query used by wsi to resolve a unique BrowserObject
   * @param designation used to match the cns node
   * @param downwardNavigation - If downward navigation - ignore getting browser object from other views using view pecking order
   * returns the BrowserObject from the currently selected view in the system browser
   * or from priorized view order - User defined, Logical, Physical, Application, Management.
   */
  private searchNodes(systemId: string, objectId: string, designation: string, downwardNavigation: boolean = false): Observable<BrowserObject> {
    return this.systemBrowserService.searchNodeMultiple(systemId, [objectId], true)
      .pipe(map((objectNodes: ObjectNode[]) => {
        if (objectNodes.length === 0) {
          return undefined; // no matching cns nodes
        }

        const objectNode: ObjectNode = objectNodes[0];
        if (objectNode === undefined && objectNode.Nodes.length === 0) {
          return undefined; // no matching cns nodes
        }

        // set the cache
        this.browserObjectCache.set(objectId, objectNode.Nodes);

        return this.getCurrentViewBrowserObject(objectNode.Nodes, designation, downwardNavigation);
      }),
      catchError((err: any) => {
        this.traceService.warn(this.traceModule, `${err}`);
        return throwError(err);
      }));
  }
}

/**
 * Return System Id of SystemInfo where Name equals nameString
 * @param systemInfos
 * @param nameString
 */
export const parseSystemIdFromSystemName = (systemInfos: SystemInfo[], nameString: string): string | undefined => {
  const match: SystemInfo = systemInfos.find(sysInfo => sysInfo.Name === nameString);
  return match ? match.Id : undefined;
};
