import { AfterViewInit, Directive, ElementRef, inject } from '@angular/core';
import { ResizeObserverService } from '@simpl/element-ng/resize-observer';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map, shareReplay } from 'rxjs/operators';

@Directive({ standalone: true })
export class SiAutoCollapsableListMeasurable implements AfterViewInit {
  /** Emits on element width changes. */
  size$!: Observable<number>;

  protected elementRef = inject(ElementRef<HTMLElement>);
  private resizeObserverService = inject(ResizeObserverService);

  ngAfterViewInit(): void {
    this.size$ = this.resizeObserverService
      .observe(this.elementRef.nativeElement, 0, true, true)
      .pipe(
        map(size => size.width),
        distinctUntilChanged(),
        shareReplay(1)
      );
  }
}
