import { NgTemplateOutlet } from '@angular/common';
import { Component, EventEmitter, forwardRef, Input, Output, ViewChild } from '@angular/core';
import { clone, DateFormat, TimeFormat } from '@simpl/buildings-ng/common';
import { AnyPriorityArrayValue, PriorityArrayItem, ValueBase } from '@simpl/buildings-types';
import { SiTranslateModule } from '@simpl/element-ng/translate';

import { isValueSet } from '../../helpers/property';
import { OverrideMode, Property, StateChange, ValueState } from '../../interfaces/property';
import { SiPropertyComponent } from '../si-property/si-property.component';
import { SiPropertyContainerComponent } from '../si-property-popover/si-property-container.component';
import { SiPropertyPopoverComponent } from '../si-property-popover/si-property-popover.component';

@Component({
  selector: 'si-priority-array-property',
  templateUrl: './si-priority-array-property.component.html',
  styleUrl: './si-priority-array-property.component.scss',
  standalone: true,
  imports: [
    NgTemplateOutlet,
    // eslint-disable-next-line @angular-eslint/no-forward-ref
    forwardRef(() => SiPropertyComponent),
    SiPropertyContainerComponent,
    SiPropertyPopoverComponent,
    SiTranslateModule
  ]
})
export class SiPriorityArrayPropertyComponent {
  /** @defaultValue 'dd.mm.yyyy' */
  @Input() dateFormat: DateFormat = 'dd.mm.yyyy';
  /** @defaultValue 'hh:mm:ss' */
  @Input() timeFormat: TimeFormat = 'hh:mm:ss';
  @Input({ required: true }) property!: Property<AnyPriorityArrayValue>;
  /** @defaultValue 'none' */
  @Input() valueState?: ValueState | { [key: number]: ValueState } = 'none';
  /** @defaultValue false */
  @Input() forceReadonly = false;
  /** @defaultValue false */
  @Input() customToggle = false;
  @Output() readonly submitted = new EventEmitter<Property<AnyPriorityArrayValue>>();

  // Internally used in templates
  @ViewChild('popover', { read: SiPropertyPopoverComponent })
  protected popover?: SiPropertyPopoverComponent;

  protected get readonly(): boolean {
    return (this.forceReadonly || this.property.value.readonly) ?? false;
  }

  protected dialogTextClose = $localize`:@@OBJECT_BROWSER.DIALOG_TEXT_SHORT.CLOSE:Close`;

  protected trackByItem = (index: number, item: any): any => item;
  protected isPrioSet = (item: PriorityArrayItem<ValueBase>): boolean =>
    isValueSet(item as Property);

  private positionToPriority(priority?: number, isItemValueSet?: boolean): OverrideMode {
    if (this.property.overrideMode === 'direct') {
      // If a value is set, return "normal" so that values can be cleared
      return isItemValueSet ? 'normal' : 'direct';
    }
    if (priority && priority <= 3) {
      return 'danger';
    } else if (priority && priority > 3 && priority < 9) {
      return 'warning';
    } else {
      return 'normal';
    }
  }

  // Property<any> because Ivy compiler messes up the template type checking...
  protected itemValue(item: PriorityArrayItem<ValueBase>): Property<any> {
    item.value.optional = true;
    const isItemValueSet = isValueSet(item as Property);
    return {
      ...item,
      id: String(item.position),
      overridden: isItemValueSet || !!item.value.altText,
      overrideMode: this.positionToPriority(item.position, isItemValueSet)
    };
  }

  protected itemState(item: PriorityArrayItem<ValueBase>): ValueState {
    if (typeof this.valueState === 'object') {
      return item.position && this.valueState ? this.valueState[item.position] || 'none' : 'none';
    } else {
      return 'none';
    }
  }

  protected itemSubmit(item: PriorityArrayItem<ValueBase>): void {
    if (item.value.altText) {
      item.value.altText = undefined;
    }
    const cloned = clone(this.property);
    cloned.value.value = [item];
    (cloned as any)._original = this.property;
    this.submitted.emit(cloned);
  }

  protected globalState(): ValueState {
    return typeof this.valueState === 'object' ? 'none' : (this.valueState as ValueState);
  }

  protected stateChange(_state: StateChange): void {}
}
