import { animate, style, transition, trigger } from '@angular/animations';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import {
  booleanAttribute,
  Component,
  EventEmitter,
  HostBinding,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MenuItem } from '@simpl/element-ng/common';
import { SiLinkDirective } from '@simpl/element-ng/link';
import { SiMenuFactoryComponent } from '@simpl/element-ng/menu';
import {
  SiNavbarDropdownComponent,
  SiNavbarDropdownItemsFactoryComponent,
  SiNavbarDropdownTriggerDirective
} from '@simpl/element-ng/navbar-dropdown';
import { SiTranslateModule } from '@simpl/element-translate-ng/translate';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { SI_NAVBAR_VERTICAL } from './si-navbar-vertical.provider';

@Component({
  selector: 'si-navbar-vertical-item',
  templateUrl: './si-navbar-vertical-item.component.html',
  styleUrl: './si-navbar-vertical-item.component.scss',
  animations: [
    // Prevents initial animation. See: https://stackoverflow.com/a/50791299
    trigger('host', [transition(':enter', [])]),
    trigger('collapse', [
      transition(':enter', [
        style({ 'block-size': '0' }),
        animate('0.5s ease', style({ 'block-size': '*' }))
      ]),
      transition(':leave', [
        style({ 'block-size': '*' }),
        animate('0.5s ease', style({ 'block-size': '0' }))
      ])
    ])
  ],
  standalone: true,
  imports: [
    NgClass,
    NgTemplateOutlet,
    SiLinkDirective,
    SiMenuFactoryComponent,
    SiTranslateModule,
    SiNavbarDropdownComponent,
    SiNavbarDropdownItemsFactoryComponent,
    SiNavbarDropdownTriggerDirective
  ],
  host: {
    '[@host]': ''
  }
})
export class SiNavbarVerticalItemComponent implements OnInit, OnDestroy {
  private static idCounter = 0;

  protected id = `si-navbar-vertical-item-${SiNavbarVerticalItemComponent.idCounter++}`;

  @Input() item!: MenuItem;
  /** @defaultValue false */
  @HostBinding('class.text-only') @Input({ transform: booleanAttribute }) textOnly = false;
  @Input() collapseButtonText!: string;
  @Input() expandButtonText!: string;

  @Output() readonly menuTrigger = new EventEmitter<void>();
  @Output() readonly itemTrigger = new EventEmitter<void>();

  @ViewChild('itemLink', { static: false, read: SiLinkDirective })
  private itemLink!: SiLinkDirective;

  protected itemActive = false;
  protected flyoutItems: MenuItem[] = [];
  protected parent = inject(SI_NAVBAR_VERTICAL);

  get isLink(): boolean {
    return !!this.item.action || !!this.item.link || !!this.item.href;
  }

  private router = inject(Router);
  private activatedRoute = inject(ActivatedRoute);
  private destroyer = new Subject<void>();

  ngOnInit(): void {
    this.router.events
      .pipe(
        filter(e => e instanceof NavigationEnd),
        takeUntil(this.destroyer)
      )
      .subscribe(() => this.updateItemActive());

    this.updateItemActive();

    if (this.itemActive) {
      this.item.expanded = true;
    }
  }

  ngOnDestroy(): void {
    this.destroyer.next();
    this.destroyer.complete();
  }

  private updateItemActive(): void {
    this.itemActive = [this.item, ...(this.item.items ?? [])].some(
      item =>
        item.isActive ||
        (item.link &&
          this.router.isActive(
            this.router.createUrlTree(Array.isArray(item.link!) ? item.link : [item.link], {
              relativeTo: this.activatedRoute
            }),
            {
              queryParams: 'ignored',
              matrixParams: 'ignored',
              paths: 'subset',
              fragment: 'ignored'
            }
          ))
    );
  }

  protected triggerMenu(): void {
    this.item.expanded = !this.item.expanded;
    this.menuTrigger.emit();
  }

  protected triggerItemOrMenu(event: Event): void {
    this.updateItems();
    if (!this.parent.collapsed && this.isLink) {
      this.itemLink.onClick(event);
      this.itemTrigger.emit();
    } else if (!this.parent.collapsed) {
      this.triggerMenu();
    }
  }

  protected updateItems(): void {
    this.flyoutItems = [{ ...this.item, items: undefined }, ...(this.item.items ?? [])];
  }
}
