export class SpecialChars {
  /** @defaultValue '*' */
  static readonly all = '*';
  /** @defaultValue '' */
  static readonly optional = '';

  static readonly anyDay = SpecialChars.all;
  /** @defaultValue 34 */
  static readonly evenDay = 34;
  /** @defaultValue 33 */
  static readonly oddDay = 33;
  /** @defaultValue 32 */
  static readonly lastDay = 32;

  static readonly anyMonth = SpecialChars.all;
  /** @defaultValue 14 */
  static readonly evenMonth = 14;
  /** @defaultValue 13 */
  static readonly oddMonth = 13;

  static readonly anyYear = SpecialChars.all;

  /** @defaultValue
   * ```
   * [
   *   SpecialChars.anyDay,
   *   SpecialChars.lastDay,
   *   SpecialChars.evenDay,
   *   SpecialChars.oddDay
   * ]
   * ```
   */
  static readonly day = [
    SpecialChars.anyDay,
    SpecialChars.lastDay,
    SpecialChars.evenDay,
    SpecialChars.oddDay
  ];
  /** @defaultValue [SpecialChars.all] */
  static readonly hours = [SpecialChars.all];
  /** @defaultValue [SpecialChars.all] */
  static readonly hundredths = [SpecialChars.all];
  /** @defaultValue [SpecialChars.all] */
  static readonly minutes = [SpecialChars.all];
  /** @defaultValue [SpecialChars.anyMonth, SpecialChars.evenMonth, SpecialChars.oddMonth] */
  static readonly month = [SpecialChars.anyMonth, SpecialChars.evenMonth, SpecialChars.oddMonth];
  /** @defaultValue [SpecialChars.all] */
  static readonly seconds = [SpecialChars.all];
  /** @defaultValue [SpecialChars.anyYear] */
  static readonly year = [SpecialChars.anyYear];
}
