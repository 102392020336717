/* eslint-disable @typescript-eslint/naming-convention */
export type TimeFormat =
  | 'hh:mm:ss'
  | 'hh:mm:ss tt'
  | 'hh:mm'
  | 'hh:mm tt'
  | 'hh:mm:ss.hs'
  | 'hh:mm:ss.hs tt'
  | 'hh:mm.hs'
  | 'hh:mm.hs tt';

export const TimeFormat = {
  TWENTY_FOUR: 'hh:mm:ss' as TimeFormat,
  TWELVE: 'hh:mm:ss tt' as TimeFormat
};

export type DateFormat =
  | 'dd.mm.yyyy'
  | 'dd/mm/yyyy'
  | 'dd-mm-yyyy'
  | 'yyyy.mm.dd'
  | 'yyyy/mm/dd'
  | 'yyyy-mm-dd'
  | 'mm-dd-yyyy'
  | 'mm/dd/yyyy';

export const DateFormat = {
  DF_DDMMYYYY_DOT: 'dd.mm.yyyy' as DateFormat,
  DF_DDMMYYYY_FSLASH: 'dd/mm/yyyy' as DateFormat,
  DF_DDMMYYYY_DASH: 'dd-mm-yyyy' as DateFormat,
  DF_YYYYMMDD_DOT: 'yyyy.mm.dd' as DateFormat,
  DF_YYYYMMDD_FSLASH: 'yyyy/mm/dd' as DateFormat,
  DF_YYYYMMDD_DASH: 'yyyy-mm-dd' as DateFormat,
  DF_MMDDYYYY_DASH: 'mm-dd-yyyy' as DateFormat,
  DF_MMDDYYYY_FSLASH: 'mm/dd/yyyy' as DateFormat
};
/* eslint-enable @typescript-eslint/naming-convention */

export enum MeridianDesignation {
  AM,
  PM
}
