import { CdkPortal } from '@angular/cdk/portal';
import { PlatformLocation } from '@angular/common';
import {
  AfterViewInit,
  Component, EventEmitter, HostBinding, Inject, Input, NgZone, OnDestroy, OnInit, Output, Renderer2, ViewChild
} from '@angular/core';
import { TimeoutDialogService, TraceSettingsComponent } from '@gms-flex/controls';
import { FrameStore, FullPaneId, FullSnapInId, HfwFrame, IHfwMessage, ISnapInConfig, MobileNavigationService, StateService } from '@gms-flex/core';
import {
  EventCounterServiceBase, EventService, EventSoundServiceBase,
  MultiMonitorServiceBase, ObjectMessageType, ResoundCategory, StatusBarState, UserRole, UserRolesService
} from '@gms-flex/services';
import {
  AppContextService, AppSettings, AppSettingsService, AuthenticationServiceBase, isNullOrUndefined, Language, LanguageServiceBase,
  NotifConfiguration, NotificationServiceBase, ProductInfo, ProductService, SessionCookieStorage, SettingsServiceBase, TraceService
} from '@gms-flex/services-common';
import { EventsCommonServiceBase } from '@gms-flex/snapin-common';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem, NavbarItem, SiSidePanelService, SiToastNotificationService, ThemeType } from '@simpl/element-ng';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { fromEvent, merge, Observable, of, Subject, Subscription } from 'rxjs';
import { debounceTime, throttleTime } from 'rxjs/operators';

import { NotificationManagerService } from '../notification/notification-manager.service';
import { ExtendedNavbarItem } from '../shared/extended-navbar-item.model';
import { InactivityTimeout } from '../shared/inactivity';
import { Initials } from '../shared/initials';
import { TraceModules } from '../shared/trace-modules';
import { settingsMode, UtilityPanelsEn } from '../shared/utility-panel-model';

const icon = 'element-notification';
const style = 'navbar-brand';
const styleAppTitle = 'navbar-app-title-inner';
const summaryBar = 'summary-bar';
const eventList = 'event-list';
@Component({
  selector: 'gms-navbar-primary',
  templateUrl: './navbar-primary.component.html',
  styleUrl: '../gms-navbar.scss'
})

export class NavBarPrimaryComponent implements AfterViewInit, OnInit, OnDestroy {

  private static readonly companySelectionFrameId = 'company-selection-frame-id';

  @HostBinding('class.navbar-blocked') public guard = true;

  @Output() public readonly utilityPanelChanged: EventEmitter<UtilityPanelsEn> = new EventEmitter<UtilityPanelsEn>();

  @Input() public hfwFrames: HfwFrame[];

  @Input() public primaryItems: NavbarItem[];

  @Input() public additionalItems?: NavbarItem[];

  @Input() public isMainManager?: boolean = true;

  @Input() public notificationSupported?: boolean = true;

  @Input() public partitionSelectionSupported?: boolean = false;

  @Input() public appSwitcherSupported?: boolean = false;

  @Input() public appSwitcherData?: any;

  public avatarDropdownOpened = false;

  public extendedNavbarItems: NavbarItem[] = [];
  public userDropDown: NavbarItem[] = [];
  public rightItems: ExtendedNavbarItem[] = [
    { title: 'USER.MOCKUSERNAME', items: this.userDropDown }
  ];

  /* Mobile Screen related properties */
  public isMobile: boolean | undefined;
  public sysBrowActive = false;

  public helpItems: NavbarItem[] = [];

  public userItem: any;

  public appTitle: string;

  public layoutLabelTitle: string;

  public messageItemTitle: string;

  public helpTitle: string;

  public logoUrl: string;

  public homeLink: string;

  /* System Configuration properties */

  public webHelp: string;

  public settings: AppSettings;

  public showTermsAndConditions: boolean;

  public modalRef: BsModalRef;
  public modalRefShotDown: BsModalRef;

  public showMobileNav: boolean;

  public enableNotifications = true;

  public enableCollapsing = true;
  public statusBarStateEnum: any = StatusBarState;

  public runsInElectron = true;

  public layoutSettingsItem: MenuItem = { title: 'Layout', icon: 'element-layout-vertical', action: () => this.showHideLayout() };

  public notificationItem: any = NavBarPrimaryComponent.createNewNotificationItem('Messages', 0, () => this.showHideNotification());
  // public helpAction: MenuItem = { title: 'Help', icon: 'element-help', action: () => this.showNotification() };

  public isULInactivityTimeout = false;

  @ViewChild('notificationPanel', { read: CdkPortal }) public notificationPanel!: CdkPortal;
  @ViewChild('layoutPanel', { read: CdkPortal }) public layoutPanel!: CdkPortal;
  @ViewChild('userRolesPanel', { read: CdkPortal }) public userRolesPanel!: CdkPortal;

  private userRoles: UserRole[];

  // cBMS related
  private readonly customerSelectorSubject = new Subject<void>();

  // eslint-disable-next-line @typescript-eslint/member-ordering
  public readonly customerSelector$ = this.customerSelectorSubject.asObservable();

  private readonly subscriptions: Subscription[] = [];
  private languageCode = 'en-US';
  private _soundSub: any;
  private _configuration: NotifConfiguration;
  private _enableCategories: string[] = [];
  private _resoundData: ResoundCategory[] | undefined;

  private readonly inactivityTimeout: InactivityTimeout;

  /* System Configuration*/
  private corporateInformation: any;

  private mousedownListenerFn: () => void;
  private clickListenerFn: () => void;

  public static createNewNotificationItem(title: string, count: number, notifAction: any, previousItem?: MenuItem): any {
    if (previousItem) {
      return { ...previousItem, badge: count };
    } else {
      return {
        title: title,
        icon,
        badgeColor: 'danger',
        badge: count,
        action: notifAction
      };
    }
  }

  /**
   * Constructor
   * @param {ProductService} productService
   * @param {TraceService} traceService
   * @param {EventCounterServiceBase} eventCounterService
   * @param {EventService} eventService
   * @param {AppContextService} appContextService
   * @param {TranslateService} translateService
   * @param {AppSettingsService} appSettingsService
   * @param {SettingsServiceBase} settingsService
   * @param {PlatformLocation} platformLocation
   * @param {BsModalService} modalService
   * @param {NotificationServiceBase} notificationService
   * @param {StateService} stateService
   * @param {MessageBroker} messageBroker
   * @param {LanguageServiceBase} languageService
   */
  public constructor(
    private readonly snapinConfig: ISnapInConfig,
    private readonly settingsService: SettingsServiceBase,
    private readonly productService: ProductService,
    private readonly traceService: TraceService,
    private readonly eventCounterService: EventCounterServiceBase,
    private readonly eventService: EventService,
    private readonly eventSoundService: EventSoundServiceBase,
    private readonly appContextService: AppContextService,
    private readonly translateService: TranslateService,
    private readonly appSettingsService: AppSettingsService,
    private readonly platformLocation: PlatformLocation,
    private readonly modalService: BsModalService,
    private readonly notificationService: NotificationServiceBase,
    private readonly stateService: StateService,
    private readonly authenticationService: AuthenticationServiceBase,
    private readonly timeoutDialogService: TimeoutDialogService,
    private readonly sidePanelService: SiSidePanelService,
    private readonly ngZone: NgZone,
    private readonly renderer2: Renderer2,
    private readonly notificationManagerService: NotificationManagerService,
    private readonly userRolesService: UserRolesService,
    private readonly toastNotificationService: SiToastNotificationService,
    private readonly multiMonitorService: MultiMonitorServiceBase,
    private readonly languageService: LanguageServiceBase,
    private readonly eventsCommonService: EventsCommonServiceBase,
    @Inject(MobileNavigationService) private readonly mobileNavigationService: MobileNavigationService,
    @Inject(IHfwMessage) private readonly messageBroker: IHfwMessage
  ) {
    if (!this.authenticationService.defaultUserLoggedIn) {
      this.inactivityTimeout = new InactivityTimeout(
        this.traceService,
        this.authenticationService,
        this.timeoutDialogService,
        this.multiMonitorService,
        this.messageBroker);
    }
  }

  public ngOnInit(): void {

    if (isNullOrUndefined((window as any).electron)) {
      this.runsInElectron = false;
    }
    this.eventService.addConsumer();

    // load summary bar EL resoundData config
    this.getHldlConfigs();

    // listener for outside Angular
    this.setListenerForMouseMoveKeyTouch();

    // start timeout for inactivity
    if (!this.authenticationService.defaultUserLoggedIn) {
      this.inactivityTimeout.manageInactivityTimeout(this.isULInactivityTimeout);
    }

    if (this.isMainManager) {
      this.clickListenerFn = this.renderer2.listen('document', 'click', event => {
        const targetElement: HTMLElement = event.target as HTMLElement;
        if (!isNullOrUndefined(targetElement) &&
          !isNullOrUndefined(targetElement.className) &&
          !isNullOrUndefined(targetElement.className.includes) &&
          !targetElement.className.includes(icon) &&
          !targetElement.className.includes('badge-text') &&
          !targetElement.children[0]?.className.includes(icon)) {
          this.notifVisibility('close');
        }
      });
      this.mousedownListenerFn = this.renderer2.listen('document', 'mousedown', event => {
        const targetElement: HTMLElement = event.target as HTMLElement;
        if (!isNullOrUndefined(targetElement) &&
          !isNullOrUndefined(targetElement.className) &&
          !isNullOrUndefined(targetElement.children[0]?.className) &&
          !isNullOrUndefined(targetElement.className.includes) &&
          (targetElement.className.includes(icon) ||
            targetElement.className.includes('badge-text') ||
            targetElement.children[0]?.className.includes(icon))) {
          this.notifVisibility();
        }
        if (!this.authenticationService.defaultUserLoggedIn) {
          this.inactivityTimeout.refreshUserState();
        }
      });
    }

    this.onInitProduct();
    this.onInitSubcription();
    this.onInitContextAndToast();

    if (this.settings != null) {
      if (this.settings.traceEnabled === true) {
        this.userDropDown.push(
          { title: 'USER.TRACE-SETTINGS', action: this.traceSettingsAction });
      }
    }

    this.subscriptions.push(this.appContextService.userCulture.subscribe((userCulture: string) => {
      if (userCulture != null) {
        this.translateService.use(userCulture).subscribe((res: any) => {
          this.traceService.info(TraceModules.navigationBar, 'use  user Culture');
          this.languageCode = userCulture;
        },
        (err: any) => {
          this.subscriptions.push(this.appContextService.defaultCulture.subscribe((defaultCulture: string) => {
            if (defaultCulture != null) {
              this.translateService.setDefaultLang(defaultCulture);
            } else {
              this.traceService.warn(TraceModules.navigationBar, 'No default Culture for appContextService');
              this.translateService.setDefaultLang(this.translateService.getBrowserLang());
            }
            this.languageCode = this.translateService.getDefaultLang();
          }));
        });
      } else {
        this.traceService.warn(TraceModules.navigationBar, 'No user Culture for appContextService');
      }
    }));

    this.appContextService.userDescriptor.subscribe(descriptor => {
      if (descriptor != null && descriptor !== '') {
        this.rightItems[0].title = this.appContextService.userDescriptorValue;
      } else {
        this.rightItems[0].title = this.appContextService.userNameValue;
      }
      this.rightItems[0].avatar = Initials.getInitials(this.rightItems[0].title, 2);
      this.userItem = { title: this.rightItems[0].title };

    });

    if (this.isMainManager) {
      this.subscriptions.push(this.notificationService.subscribeConfigurations().subscribe(res => {
        this._configuration = res.get('newEvents');

        this.onInitCategoryAndSound();
      }));

      this.subscriptions.push(this.notificationManagerService.getNotificationCount().pipe(
        debounceTime(100)
      ).subscribe(count =>
        this.updateNotificationCount(count)));
    }

    this.userRolesService.subscribeUserRoles();
    this.subscriptions.push(this.userRolesService.userRolesNotification().subscribe(() => this.updateUserRoles(true)));

    if (sessionStorage.getItem(SessionCookieStorage.UserRoles) == SessionCookieStorage.True) {
      this.toastNotificationService.queueToastNotification('info', 'ROLES.TOAST-TITLE', '');
      sessionStorage.removeItem(SessionCookieStorage.UserRoles);
    }

    // set brand cursor to "pointer";
    this.setBrandCursor();

    // Mobile Only Visibility
    this.isMobile = this.mobileNavigationService.mobileOnlyVisibilityLast;

    this.subscriptions.push(this.mobileNavigationService.mobileOnlyVisibility$.subscribe((mobileOnlyVisibility: boolean) => {
      this.isMobile = mobileOnlyVisibility;
    }));

    // Subscribe If system-browser is active
    this.subscriptions.push(this.mobileNavigationService.sysBrowActive$.subscribe((sysBrowActive: boolean) => {
      this.sysBrowActive = sysBrowActive;
    }));

    this.getModeSettings().subscribe({
      next: mode => {
        if (!isNullOrUndefined(mode)) {
          this.appContextService.setThemeType(mode as ThemeType);
          if (this.multiMonitorService.runsInElectron) {
            this.multiMonitorService.synchronizeUiState({ sendToItself: false, state: { themeType: mode } });
          }
        }
      },
      error: error => {
        this.appContextService.setThemeType('auto');
        this.traceService.warn(TraceModules.layoutSettings, 'Not able to Applied save Mode due to : %s', error);
        if (this.multiMonitorService.runsInElectron) {
          this.multiMonitorService.synchronizeUiState({ sendToItself: false, state: { themeType: 'auto' } });
        }
      }
    });
  }

  public ngAfterViewInit(): void {
    if (this.layoutPanel) {
      this.layoutPanel.context = { utilityId: UtilityPanelsEn[UtilityPanelsEn.GmsSnapinsLayoutSettings] };
    }
    if (this.notificationPanel) {
      this.notificationPanel.context = { utilityId: UtilityPanelsEn[UtilityPanelsEn.GmsSnapinsNotifications] };
    }
    if (this.userRolesPanel) {
      this.userRolesPanel.context = { utilityId: UtilityPanelsEn[UtilityPanelsEn.GmsSnapinsUserRoles] };
    }
  }

  public ngOnDestroy(): void {
    if (this.clickListenerFn) {
      this.clickListenerFn();
      this.clickListenerFn = undefined;
    }
    if (this.mousedownListenerFn) {
      this.mousedownListenerFn();
      this.mousedownListenerFn = undefined;
    }
    this.eventService.removeConsumer();
    this.eventCounterService.unSubscribeEventCounters();
    this.subscriptions.forEach((subscription: Subscription) => { if (subscription != null) { subscription.unsubscribe(); } });
    this.traceService.info(TraceModules.navigationBar, 'Component destroyed.');
  }

  public onHidden(): void {
    this.avatarDropdownOpened = false;
  }

  public onShown(): void {
    this.avatarDropdownOpened = true;
  }

  /**
   * Switches the current frame to the specified frame.
   * @param frame - The identifier for the frame to switch to.
   * Subscribes to the frame switch operation and handles two possible outcomes:
   * - If the frame matches the company selection frame, the customer selector is triggered.
   * - Otherwise, it updates the destination frame and logs the completion result.
   */
  public switchFrame(frame: string): void {
    this.messageBroker.switchToNextFrame(frame).subscribe((frameChanged: boolean) => {
      if (frame === NavBarPrimaryComponent.companySelectionFrameId) {
        this.triggerCustomerSelector();
        this.traceService.info(TraceModules.navigationBar, 'Company Selection right panel triggered.');
      } else {
        this.eventsCommonService.destinationFrame = frame;
        this.traceService.info(TraceModules.navigationBar, 'switchToNextFrame completed. Result: %s', frameChanged);
      }
    });
  }

  /** Log out and notify SnapIns
   *
   */
  public logoutAction: () => void = () => {
    if (this.multiMonitorService.runsInElectron) {
      // In electron
      this.multiMonitorService.sendObjectToMainManager({
        type: ObjectMessageType.Logout,
        data: {}
      });
    } else {
      // In web client
      this.messageBroker.logout();
    }
  };

  /** Configure User Roles
   *
   */
  public userRolesAction: () => void = () => {
    // const modalOptions: ModalOptions = { ignoreBackdropClick: true, keyboard: true, animated: true };
    // this.modalService.show(UserRolesComponent, modalOptions);
    this.utilityPanelChanged.emit(UtilityPanelsEn.GmsSnapinsUserRoles);
    this.sidePanelService.showTemporaryContent(this.userRolesPanel).subscribe(() => this.utilityPanelChanged.emit(null));
  };

  public logoClick(event: any): void {

    const target: any = (event.target as HTMLElement);
    switch (target.tagName) {
      case 'A':
        if (target.className.includes(style)) {
          if (this.preventNavigationDueToAssistedTreatment()) {
            return;
          }

          this.startUpNodeNavigation().subscribe((res: boolean) => {
            this.traceService.info(TraceModules.navigationBar, `startNavigateDefaultUrl: ${res}`);
          });
        }
        break;
      case 'path': {
        const firstParentNode: any = (target.parentNode as HTMLElement);
        if (firstParentNode.tagName === 'svg' &&
          (firstParentNode.parentNode as HTMLElement).className.includes(style)) {

          if (this.preventNavigationDueToAssistedTreatment()) {
            return;
          }

          this.startUpNodeNavigation().subscribe((res: boolean) => {
            this.traceService.info(TraceModules.navigationBar, `startNavigateDefaultUrl: ${res}`);
          });
        }
        break;
      }
      // Case for the appTitle click
      case 'DIV': {
        if (target.className.includes(styleAppTitle)) {
          if (this.sysBrowActive && this.isMobile) {
            this.mobileNavigationService.setSysBrowActive(false);
          }
          if (this.preventNavigationDueToAssistedTreatment()) {
            return;
          }
          this.startUpNodeNavigation().subscribe((res: boolean) => {
            this.traceService.info(TraceModules.navigationBar, `startNavigateDefaultUrl: ${res}`);
          });
        }
        break;
      }
      // Case for Logo click
      case 'svg': {
        const parentNode: any = (target.parentNode as HTMLElement);
        if (parentNode.className.includes(style)) {
          if (this.preventNavigationDueToAssistedTreatment()) {
            return;
          }
          this.startUpNodeNavigation().subscribe((res: boolean) => {
            this.traceService.info(TraceModules.navigationBar, `startNavigateDefaultUrl: ${res}`);
          });
        }
        break;
      }
      default:
        break;
    }
  }

  public traceSettingsAction: () => void = () => {
    this.modalRef = this.modalService.show(TraceSettingsComponent, { ignoreBackdropClick: true });
  };

  public openHelpAction: () => void = () => {
    const pathName: string = this.platformLocation.pathname.substring(0, (this.platformLocation.pathname.indexOf('main/page/')));
    const basUrl: string = (this.platformLocation as any)._location.origin + pathName;
    this.languageService.getUserLanguage().subscribe((languageCode: Language) => {
      const helpURL: string = basUrl +
        'webhelp/' + languageCode.Code + '/index.html';
      window.open(helpURL, '_blank');
    });
  };

  public notifVisibility(close?: string): void {
    if (close === 'close') {
      this.notificationManagerService.visible = false;
    } else {
      this.notificationManagerService.visible = !this.notificationManagerService.visible;
    }
  }

  public showUtility(utility: UtilityPanelsEn): void {
    switch (utility) {
      case UtilityPanelsEn.GmsSnapinsLayoutSettings: {
        this.showHideLayout();
        break;
      }
      case UtilityPanelsEn.GmsSnapinsNotifications: {
        this.showHideNotification();
        break;
      }
      case UtilityPanelsEn.GmsSnapinsUserRoles: {
        this.userRolesAction();
        break;
      }
      default: {
        break;
      }
    }
  }

  /**
   * Read SummaryBar and EventList configuration to retrive Resound setting and isULC for UL
   */
  private getHldlConfigs(): void {
    // Read summary-bar hldl settings
    const fullSnapInID = new FullSnapInId(summaryBar, 'sb');
    const fullPaneInID = new FullPaneId(summaryBar, 'sb-pane');
    const eventListHldlConfig = this.snapinConfig.getSnapInHldlConfig(fullSnapInID, fullPaneInID);

    if (eventListHldlConfig != undefined) {
      this._resoundData = eventListHldlConfig.ResoundData!;
    }

    const fullSnapInId = new FullSnapInId(eventList, 'el');
    const fullPaneId = new FullPaneId(eventList, 'el-pane');

    const eventListHldlFullConfig = this.snapinConfig.getSnapInHldlConfig(fullSnapInId, fullPaneId);

    if (eventListHldlFullConfig != undefined) {
      this.isULInactivityTimeout = eventListHldlFullConfig.isULInactivityTimeout;
    }
  }
  private showHideLayout(): void {
    if (this.hideLayout()) {
      return;
    }
    this.layoutSettingsItem = { ...this.layoutSettingsItem, isActive: true };
    this.utilityPanelChanged.emit(UtilityPanelsEn.GmsSnapinsLayoutSettings);
    this.sidePanelService.showTemporaryContent(this.layoutPanel).subscribe(() => this.hideLayout());
  }

  private hideLayout(): boolean {
    if (this.layoutSettingsItem.isActive) {
      this.layoutSettingsItem = { ...this.layoutSettingsItem, isActive: false };
      this.sidePanelService.hideTemporaryContent();
      this.utilityPanelChanged.emit(null);
      return true;
    }
    return false;
  }

  private readonly showHideNotification: () => void = () => {
    if (this.hideNotification()) {
      return;
    }
    this.notificationItem = { ...this.notificationItem, isActive: true };
    this.utilityPanelChanged.emit(UtilityPanelsEn.GmsSnapinsNotifications);
    this.sidePanelService.showTemporaryContent(this.notificationPanel).subscribe(() => this.hideNotification());
  };

  private hideNotification(): boolean {
    if (this.notificationItem.isActive) {
      this.notificationItem = { ...this.notificationItem, isActive: false };
      this.sidePanelService.hideTemporaryContent();
      this.utilityPanelChanged.emit(null);
      return true;
    }
    return false;
  }

  private setListenerForMouseMoveKeyTouch(): void {
    if (!this.authenticationService.defaultUserLoggedIn) {
      this.ngZone.runOutsideAngular(() => {
        const activeAction$ = merge(
          fromEvent<Event>(document, 'mousemove'),
          fromEvent<Event>(document, 'keypress'),
          fromEvent<Event>(document, 'touchstart')
        );
        activeAction$.pipe(throttleTime(1000, undefined, { leading: true, trailing: true })).subscribe(
          () => this.inactivityTimeout.refreshUserState());
      });
    }
  }

  private startUpNodeNavigation(): Observable<boolean> {
    this.traceService.info(TraceModules.navigationBar, 'startUpNodeNavigation(): begin navigation to startup node');
    const frame: FrameStore = this.stateService.currentState.getFrameStoreViaId('system-manager');
    frame.hasBeenNavigatedOnce = false;
    if (!isNullOrUndefined(frame)) {
      return this.stateService.startNavigateDefaultUrl(this.messageBroker);
    } else {
      return of(false);
    }
  }

  private preventNavigationDueToAssistedTreatment(): boolean {
    if (this.eventsCommonService.isInAssistedMode) {
      this.eventsCommonService.destinationFrame = 'system-manager'
      this.switchFrame(this.eventsCommonService.destinationFrame);
      return true;
    }
    return false;
  }

  private initFrameList(): void {
    // fill dropdown menus
    if (this.hfwFrames) {
      const aboutFrame: HfwFrame = this.hfwFrames.find(f => f.id === 'about-frame-id');
      const accountFrame: HfwFrame = this.hfwFrames.find(f => f.id === 'account-frame-id');
      const notifFrame: HfwFrame = this.hfwFrames.find(f => f.id === 'notifconfig-frame-id');

      if (aboutFrame !== undefined) {
        this.initHelpMenuItems(aboutFrame);
      }
      if (this.partitionSelectionSupported) {
        this.userDropDown.push({
          title: 'NAVBAR.' + 'SELECT-COMPANY', icon: 'element-project',
          action: this.switchFrame.bind(this, NavBarPrimaryComponent.companySelectionFrameId),
          navigationExtras: { queryParamsHandling: 'preserve' }
        });
      }
      if (accountFrame !== undefined) {
        this.userDropDown.push({
          // icon: accountFrame ? "element-account" : accountFrame.iconClass
          title: 'NAVBAR.' + accountFrame.id, icon: accountFrame.iconClass,
          action: this.switchFrame.bind(this, accountFrame.id),
          navigationExtras: { queryParamsHandling: 'preserve' }
        });
      }
      if (this.notificationSupported && notifFrame !== undefined) {
        this.userDropDown.push({
          title: 'NAVBAR.' + notifFrame.id, icon: notifFrame.iconClass,
          action: this.switchFrame.bind(this, notifFrame.id),
          navigationExtras: { queryParamsHandling: 'preserve' }
        });
      }
    }
    if (this.isMainManager) {
      if (this.userRoles.length > 0) {
        this.userDropDown.push(
          { title: 'USER.ROLES', action: () => this.userRolesAction() }
        );
      }
      this.userDropDown.push(
        { title: '-' }
      );
      const logoffLabel = this.authenticationService.defaultUserLoggedIn ? 'USER.LOGIN' : 'USER.LOGOUT';
      this.userDropDown.push(
        { title: logoffLabel, icon: 'element-logout', action: () => this.logoutAction() }
      );
    }
  }

  private initHelpMenuItems(aboutFrame: HfwFrame): void {
    if (this.helpItems) {
      this.helpItems.push({ title: 'HELP', action: this.openHelpAction, icon: 'element-help' });
      this.helpItems.push({ title: '-' });
      this.helpItems.push({
        title: 'NAVBAR.' + aboutFrame.id,
        icon: 'element-info',
        action: this.switchFrame.bind(this, aboutFrame.id),
        navigationExtras: { queryParamsHandling: 'preserve' },
        isActive: false
      });

      const currentLang: string = this.languageCode;
      const currcorporate: any = this.corporateInformation.filter(item => item.language === currentLang);

      if (!isNullOrUndefined(currcorporate) && currcorporate.length > 0) {
        if (!isNullOrUndefined(currcorporate[0].url) && currcorporate[0].url.length > 0) {
          this.helpItems.push({ title: 'NAVBAR.CORPORATE-INFORMATION', href: currcorporate[0].url, target: '_blank' });
        }
      }
    }
  }

  private updateNotificationCount(count: number): void {
    this.notificationItem = NavBarPrimaryComponent.createNewNotificationItem(this.messageItemTitle, count,
      () => this.showHideNotification(), this.notificationItem);
  }

  private triggerCustomerSelector(): void {
    this.customerSelectorSubject.next();
  }

  private updateUserRoles(roles: any): void {
    sessionStorage.setItem(SessionCookieStorage.UserRoles, SessionCookieStorage.True);
    // if there's an assisted treatment open, close it cleanly
    if (this.eventsCommonService.isInAssistedMode === true) {
      this.eventService.eventCommand([this.eventsCommonService.treatedEvent], 'suspend', 'assistedtreatment');
      this.messageBroker.changeMode({ id: 'default', relatedValue: null }, 'event-list').subscribe((modeChanged: boolean) => {
        this.eventsCommonService.autoAssistedEvents.next([]);
        this.eventsCommonService.treatedEvent = undefined;
        this.eventsCommonService.isInAssistedMode = false;
        location.reload();
      });
    } else {
      location.reload();
    }
  }

  private onInitCategoryAndSound(): void {
    if (this._configuration?.getCustomData()[0] && !this._soundSub) {
      // eslint-disable-next-line prefer-const
      let disableCategories: string[] = [];
      this._enableCategories = [];
      this._configuration.getCustomData().forEach(d => {
        if ((d.override === true && d.data[2].value === false) || (d.override === false && this._configuration.getSound() === false)) {
          disableCategories.push(d.id);
        }
        if ((d.override === false && this._configuration.getSound() === true) || (d.override === true && d.data[2].value === true)) {
          this._enableCategories.push(d.id);
        }
      });
      this.onInitSound(disableCategories);
    }
  }

  private onInitSound(disableCateg: string[]): void {
    if (!this._soundSub) {
      this._soundSub = true;
      this.eventSoundService.unSubscribeEventSound().subscribe(r => {
        this.subscriptions.push(this.eventSoundService.subscribeEventSound(disableCateg, this._resoundData).subscribe(s => {
          this._soundSub = false;
        },
        error => {
          this.eventSoundService.unSubscribeEventSound();
          this._soundSub = false;
        }));
      });
    }
  }

  private onInitProduct(): void {
    this.productService.getProductSettings().subscribe((product: ProductInfo) => {
      this.appTitle = product.brandProductName;
      this.logoUrl = product.navigationBarLogo;
      this.corporateInformation = product.corporateLink;
      this.userRolesService.getUserRoles().toPromise().then(res => {
        if (res != null) {
          this.userRoles = res;
          this.initFrameList();
        }
      });
    });
    this.settings = this.appSettingsService.getAppSettingsValue();
    this.webHelp = document.baseURI + 'webhelp';
  }

  private onInitSubcription(): void {
    this.subscriptions.push(this.appContextService.defaultCulture.subscribe((defaultCulture: string) => {
      if (defaultCulture != null) {
        this.translateService.setDefaultLang(defaultCulture);
      } else {
        this.traceService.warn(TraceModules.navigationBar, 'No default Culture for appContextService');
        this.translateService.setDefaultLang(this.translateService.getBrowserLang());
      }
      this.languageCode = this.translateService.getDefaultLang();
    }));

    this.subscriptions.push(this.appContextService.userCulture.subscribe((userCulture: string) => {
      if (userCulture != null) {
        this.languageCode = userCulture;
        this.translateService.use(userCulture).subscribe((res: any) => {
          this.traceService.info(TraceModules.navigationBar, 'use user Culture');
        });
      } else {
        this.traceService.warn(TraceModules.navigationBar, 'No user Culture for appContextService');
      }

    }));

    this.subscriptions.push(this.translateService.get([
      'HELP',
      'LAYOUT.LABEL',
      'NAVBAR.notifconfig-frame-id'
    ]).subscribe(values => this.onTranslateStrings(values)));
  }

  private onTranslateStrings(strings: string[]): void {
    // eslint-disable-next-line @typescript-eslint/dot-notation
    this.helpTitle = strings['HELP'];
    this.layoutLabelTitle = strings['LAYOUT.LABEL'];
    this.messageItemTitle = strings['NAVBAR.notifconfig-frame-id'];

    this.notificationItem = NavBarPrimaryComponent.createNewNotificationItem(this.messageItemTitle, 0, (): void => this.showHideNotification());
    this.layoutSettingsItem = { title: this.layoutLabelTitle, icon: 'element-layout-vertical', action: (): void => this.showHideLayout() };
  }

  private onInitContextAndToast(): void {
    this.appContextService.userDescriptor.subscribe(descriptor => {
      if (descriptor != null && descriptor !== '') {
        this.rightItems[0].title = this.appContextService.userDescriptorValue;
      } else {
        this.rightItems[0].title = this.appContextService.userNameValue;
      }
      this.rightItems[0].avatar = Initials.getInitials(this.rightItems[0].title, 2);
      this.userItem = { title: this.rightItems[0].title };

    });

    this.userRolesService.subscribeUserRoles();
    this.subscriptions.push(this.userRolesService.userRolesNotification().subscribe(() => this.updateUserRoles(true)));

    if (sessionStorage.getItem(SessionCookieStorage.UserRoles) == SessionCookieStorage.True) {
      this.toastNotificationService.queueToastNotification('info', 'ROLES.TOAST-TITLE', '');
      sessionStorage.removeItem(SessionCookieStorage.UserRoles);
    }
  }

  /** Set Logo  cursor  to pointer
   *
   */
  private setBrandCursor(): void {
    const listBrand = document.getElementsByClassName('navbar-brand') as HTMLCollectionOf<HTMLElement>;
    if (listBrand) {
      Array.from(listBrand).forEach(elem => {
        elem.style.cursor = 'pointer';
      });
    }
  }

  private getModeSettings(): Observable<string> {

    return this.settingsService.getSettings(settingsMode);
  }
}
