import { Component, HostBinding, Input } from '@angular/core';
import { MenuItem } from '@simpl/element-ng/common';
import {
  ContentActionBarMainItem,
  SiContentActionBarComponent,
  ViewType
} from '@simpl/element-ng/content-action-bar';
import { MenuItemNext } from '@simpl/element-ng/menu';
import { SiTranslateModule, TranslatableString } from '@simpl/element-translate-ng/translate';

@Component({
  selector: 'si-card',
  templateUrl: './si-card.component.html',
  styleUrl: './si-card.component.scss',
  host: {
    class: 'card'
  },
  standalone: true,
  imports: [SiContentActionBarComponent, SiTranslateModule]
})
export class SiCardComponent {
  // Needed for inheritance.
  @HostBinding('class.elevation-1') protected get classElevation(): boolean {
    return true;
  }
  /**
   * Card header text.
   */
  @Input() heading?: TranslatableString;
  /**
   * Input list of primary action items. Supports up to **4** actions and omits additional ones.
   *
   * @defaultValue []
   */
  @Input() primaryActions: (MenuItem | ContentActionBarMainItem)[] = [];
  /**
   * Input list of secondary action items.
   *
   * @defaultValue []
   */
  @Input() secondaryActions: (MenuItem | MenuItemNext)[] = [];
  /**
   * A param that will be passed to the `action` in the primary/secondary actions.
   * This allows to re-use the same primary/secondary action arrays across rows
   * in a table.
   */
  @Input() actionParam?: any;
  /**
   * The view type of the content action bar of the card. Default is `collapsible`
   * for dashboards. However, in some cases you might need to change to `expanded`
   * or `mobile`.
   *
   * @defaultValue 'collapsible'
   */
  @Input() actionBarViewType: ViewType = 'collapsible';
  /**
   * Optional setting of html title attribute for the content action bar.
   * Helpful for a11y when only one action is configured in expand mode.
   *
   * @defaultValue ''
   */
  @Input() actionBarTitle: TranslatableString = '';
  /**
   * Image source for the card.
   */
  @Input() imgSrc?: string;
  /**
   * Alt text for a provided image.
   */
  @Input() imgAlt?: string;
  /**
   * Defines if an image is placed on top or start (left) of the card.
   *
   * @defaultValue 'vertical'
   */
  @Input() imgDir?: 'horizontal' | 'vertical' = 'vertical';
  /**
   * Sets the image [object-fit](https://developer.mozilla.org/en-US/docs/Web/CSS/object-fit) CSS property,
   * Sets the image [object-fit](https://developer.mozilla.org/en-US/docs/Web/CSS/object-fit) CSS property.
   *
   * @defaultValue 'scale-down'
   */
  @HostBinding('style.--si-card-img-object-fit')
  @Input()
  imgObjectFit?: 'contain' | 'cover' | 'fill' | 'none' | 'scale-down' = 'scale-down';
  /**
   * Sets the image [object-position](https://developer.mozilla.org/en-US/docs/Web/CSS/object-position) CSS property.
   */
  @HostBinding('style.--si-card-img-object-position')
  @Input()
  imgObjectPosition?: string;
  /**
   * In case the card uses an image and horizontal direction is used we set flex row direction.
   */
  @HostBinding('class.card-horizontal') protected get classCardHorizontal(): boolean {
    return !!this.imgSrc && this.imgDir === 'horizontal';
  }
  /**
   * Returns `true` when primary or secondary actions are set.
   */
  get displayContentActionBar(): boolean {
    return this.primaryActions?.length > 0 || this.secondaryActions?.length > 0;
  }
}
