import { Cipher } from 'crypto';

import { Inject, Injectable } from '@angular/core';
import { IHfwMessage } from '@gms-flex/core';
import { LicenseServiceBase, MultiMonitorServiceBase, SuppressedObjectsServiceBase } from '@gms-flex/services';
import { Action, CustomData, CustomSetting, NotifConfiguration, Notification, NotificationServiceBase, NotificationState } from '@gms-flex/services-common';
import { TranslateService } from '@ngx-translate/core';
import { SiToast, SiToastNotificationService } from '@simpl/element-ng';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable } from 'rxjs';

import { NotificationMouseService } from './notification-mouse.service';
import { GroupProperties } from './notification.component';

export interface NotificationAndSenders {
  notifications: NotificationPublic[];
  senders: Map<string, GroupProperties>;
}

export class NotificationPublic {
  public id!: number;
  public actions!: Action[];
  public icon = '';
  public iconBg = '#FFFFF';
  public title!: string;
  public text!: string;
  public toastTitle!: string;
  public toastText!: string;
  public autoCancel = false;
  public sender!: string;
  public customData: any = undefined;
  public overwrite = false;
  public timestamp: Date = undefined;
  public state: NotificationState = NotificationState.Active;
}

@Injectable({
  providedIn: 'root'
})
export class NotificationManagerService {

  public toastNotifications: Map<string, SiToast> = new Map<string, SiToast>();
  public visible = false;

  private readonly count: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private readonly notificationsAndSenders: BehaviorSubject<NotificationAndSenders> =
    new BehaviorSubject<NotificationAndSenders>({ notifications: [], senders: new Map<string, GroupProperties>() });
  private readonly configurations: BehaviorSubject<Map<string, NotifConfiguration>> =
    new BehaviorSubject<Map<string, NotifConfiguration>>(new Map<string, NotifConfiguration>());

  private readonly _sound: HTMLAudioElement = new Audio();
  private soundLocked = true;

  private resubscribeSuppressed = false;
  private suppressedToast = false;
  private resubscribeLicense = false;
  private _licenseText = '';
  private lastToastSender: string;
  private licenseText = '';

  private passwordReminderSubtitleLabel = '';
  private passwordReminderTitleLabel = '';
  private passwordReminderDescriptionLabel = '';
  private passwordChangeTitleLabel = '';
  private passwordExpiredSubTitleLabel = '';
  private suppressedConfig: NotifConfiguration;
  private passwordReminderConfig: NotifConfiguration;

  private suppressedNotificationDescriptionLabel = '';
  private suppressedNotificationSingleLabel = '';
  private suppressedNotificationPluralLabel = '';

  private passwordReminderCounter = -1;
  private readonly maxReminderCounter = 30;

  constructor(private readonly notificationService: NotificationServiceBase,
    private readonly suppressedObjectsService: SuppressedObjectsServiceBase,
    private readonly toastNotification: SiToastNotificationService,
    private readonly licenseService: LicenseServiceBase,
    private readonly mouseService: NotificationMouseService,
    private readonly translateService: TranslateService,
    private readonly cookieService: CookieService,
    private readonly multiMonitorService: MultiMonitorServiceBase,
    // @Inject(IHfwMessage)
    private readonly messageBroker: IHfwMessage) {

    // load notification sound
    this._sound.src = '@gms-flex/services/assets/notification.wav';
    this._sound.load();
    this.mouseService.onClick$.subscribe((e: Event) => {
      if (this.soundLocked === true && this._sound) {
        this._sound.muted = true;
        this._sound.play();
        this.soundLocked = false;
      }
    });

    this.getTranslations();

    this.startCookieTimer();

    this.registerNotifConfig();

    this.subscribeNotifications();

    this.subscribeConfigurations();

    this.licenseService.subscribeLicense();
  }

  public getNotificationCount(): Observable<number> {
    return this.count.asObservable();
  }

  public getNotificationsAndSenders(): Observable<NotificationAndSenders> {
    return this.notificationsAndSenders.asObservable();
  }

  public getConfigurations(): Observable<Map<string, NotifConfiguration>> {
    return this.configurations.asObservable();
  }

  // ask if I get it right, this is something needed to be done at startup
  private registerNotifConfig(): void {
    this.suppressedConfig = new NotifConfiguration(this.suppressedNotificationDescriptionLabel)
      .setIcon('element-alarm-suppression-on')
      .setShow(true)
      .setSound(true)
      .setToast('none')
      .setPersistent(true)
      .setGroupaction(false);
    this.notificationService.register('suppressedObjects', this.suppressedConfig);

    this.passwordReminderConfig = new NotifConfiguration(this.passwordReminderDescriptionLabel)
      .setIcon('element-user')
      .setShow(true)
      .setSound(true)
      .setToast('alert')
      .setGroupaction(false);
    this.notificationService.register('passwordReminder', this.passwordReminderConfig);
  }

  private subscribeConfigurations(): void {
    // this._configurations = this.notificationService.getConfigurations();
    this.notificationService.subscribeConfigurations().subscribe((configurations: Map<string, NotifConfiguration>) => {
      if (configurations) {
        if (configurations.get('suppressedObjects')) {
          if (configurations.get('suppressedObjects').getShow() === false) {
            this.resubscribeSuppressed = true;
            this.suppressedToast = false;
          }
          if (configurations.get('suppressedObjects').getShow() === true && this.resubscribeSuppressed === true) {
            this.resubscribeSuppressed = false;
            this.suppressedObjectsService.unSubscribeSuppressedObjects().subscribe(r => {
              this.suppressedObjectsService.subscribeSuppressedObjects();
            });
          }
        }

        if (configurations.get('license')) {
          if (configurations.get('license').getShow() === false) {
            this.resubscribeLicense = true;
          }
          if (configurations.get('license').getShow() === true && this.resubscribeLicense === true) {
            this.resubscribeLicense = false;
            this.licenseService.unSubscribeLicense().subscribe(r => {
              this.licenseService.subscribeLicense();
            });
          }
        }

        this.configurations.next(configurations);
      }
    });
  }

  private checkNoDuplication(res: Notification, notifs: NotificationPublic[]): NotificationPublic[] {
    // Whenever we receive a negative ID, it means the notification is about a "toNormal".
    // Therefore, we need to multiply by -1 to let the system match the id with the initial "offNormal" notification.
    // If we do not do this, the effect on the notification center si that you display 2 notifications: 1 for offNormal and 1 for normal.
    if (res.id < 0) {
      res.id *= -1;
    }

    // ensure that notifications are not duplicated
    const toDelete = notifs.findIndex(x => x.sender === res.getSender() && x.id === res.id);
    if (toDelete !== -1) {
      notifs.splice(toDelete, 1);
    }
    return notifs;
  }

  private playSoundForLicense(res: Notification, configs: Map<string, NotifConfiguration>): void {
    // play sound
    if ((!this.multiMonitorService.runsInElectron || this.multiMonitorService.isMainManager()) &&
      (configs.get(res.getSender()).getSound() === true || this.checkCategorySound(res))
      && configs.get(res.getSender()).getCustomSound() === false) {
      // license sound plays only if the license changes
      if (res.getSender() === 'license' && res.getTitle() !== this._licenseText) {
        this._sound.muted = false;
        this._sound.play();
        this._licenseText = res.getTitle();
      } else if (res.getSender() !== 'license') {
        this._sound.muted = false;
        this._sound.play();
      }
    }
  }

  private playSoundForLicenseAndRemoveToast(res: Notification, configs: Map<string, NotifConfiguration>): void {
    // play sound
    this.playSoundForLicense(res, configs);

    // play sound for Back to Normal events
    if ((!this.multiMonitorService.runsInElectron || this.multiMonitorService.isMainManager()) &&
      res.getSender() === 'newEvents' && res.id < 0 && configs.get(res.getSender()).getCustomSettings()[0].value === true) {
      this._sound.muted = false;
      this._sound.play();
    }
    this.removeLastToast(res, configs);
  }

  private setSenders(res: Notification, senders: Map<string, GroupProperties>, notifs: NotificationPublic[]): Map<string, GroupProperties> {
    const count = Array.from(notifs.values()).length;
    this.setNotificationCount(count);
    if (!senders.has(res.getSender())) {
      senders.set(res.getSender(), { sender: res.getSender(), grouped: false, count: 1 });
    } else {
      senders = this.setSenderIfNotOverwrite(res, notifs, senders);
    }
    return senders;
  }

  private subscribeNotifications(): void {
    this.notificationService.subscribeNotifications().subscribe(res => {
      if (res) {
        let notifs: NotificationPublic[] = this.notificationsAndSenders.getValue().notifications;
        let senders = this.notificationsAndSenders.getValue().senders;
        const configs = this.configurations.getValue();
        if (res.getState() === 1) {
          // check in the configuration if show = true
          if (configs?.has(res.getSender())
            && this.checkCategory(res)) {
            // ensure that newEvents notifications are not duplicated
            notifs = this.checkNoDuplication(res, notifs);
            // push - edit new notification
            notifs.push({
              id: res.id,
              actions: res.getActions(),
              icon: res.getIcon(),
              iconBg: res.getIconBg(),
              title: res.getTitle(),
              text: res.getText(),
              toastTitle: res.getToastTitle(),
              toastText: res.getToastText(),
              autoCancel: res.getAutoCancel(),
              sender: res.getSender(),
              customData: res.getCustomData(),
              overwrite: res.getOverwrite(),
              timestamp: res.getTimeStamp(),
              state: res.getState()
            });
            senders = this.setSenders(res, senders, notifs);
          }
          this.playSoundForLicenseAndRemoveToast(res, configs);
        } else {
          // delete notification
          const toDelete = notifs.findIndex(x => x.sender === res.getSender() && x.id === res.id);
          notifs.splice(toDelete, 1);
          if (senders.has(res.getSender())) {
            if (senders.get(res.getSender()).count > 1) {
              senders.get(res.getSender()).count -= 1;
            } else {
              senders.delete(res.getSender());
            }

            if (this.toastNotifications.has(res.getSender() + '#' + res.id)) {
              this.toastNotification.hideToastNotification(this.toastNotifications.get(res.getSender() + '#' + res.id));
              this.toastNotifications.delete(res.getSender() + '#' + res.id);
            }
          }
          const count = Array.from(notifs.values()).length;
          this.setNotificationCount(count);
        }
        const notifsSorted = notifs.sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime());
        this.notificationsAndSenders.next({ notifications: notifsSorted, senders });
      }
    });
  }

  private setSenderIfNotOverwrite(res: Notification, notifs: NotificationPublic[], senders: Map<string, GroupProperties>): Map<string, GroupProperties> {
    if (res.getOverwrite() === false) {
      const senderz: number = notifs.filter(x => x.sender.includes(res.getSender())).length;
      senders.set(res.getSender(), {
        sender: res.getSender(), grouped: senders.get(res.getSender()).grouped, count: senderz
      });
    } else {
      senders.set(res.getSender(), {
        sender: res.getSender(), grouped: senders.get(res.getSender()).grouped, count: 1
      });
    }
    return senders;
  }

  private removeLastToast(res: Notification, configs: Map<string, NotifConfiguration>): void {
    if (configs?.has(res.getSender()) && configs.get(res.getSender()).getToast()) {
      // remove last toast if the sender is the same
      const key = res.getSender() + '#' + res.id;
      if (this.toastNotifications.has(key) && this.toastNotification.activeToasts.length !== 0) {
        this.toastNotification.hideToastNotification(this.toastNotifications.get(key));
        this.toastNotifications.delete(key);
      }

      this.callToastNotification(res, configs);
    }
  }

  private callToastNotification(res: Notification, configs: Map<string, NotifConfiguration>): void {
    // toast notification
    const passwordSiNotif = { title: this.passwordReminderSubtitleLabel, action: (): void => this.passwordReminderClickAction() };
    if ((!this.multiMonitorService.runsInElectron || this.multiMonitorService.isMainManager())) {
      const categoryToast: string = this.checkCategoryToast(res);
      const toastType: string = configs.get(res.getSender()).getToast();
      if (res.getSender() === 'passwordReminder') {
        this.toastNotification.queueToastNotification('info', res.getToastTitle(), undefined, true,
          undefined, passwordSiNotif); // Should it be 5 seconds toast or auto-close disabled?
      }
      if ((res.getSender() !== 'passwordReminder') && (res.getSender() !== 'suppressedObjects' && res.getSender() !== 'license') ||
        (res.getSender() === 'suppressedObjects' && this.suppressedToast === true) || res.getSender() === 'license' && res.getTitle() !== this.licenseText) {
        if (categoryToast === '') {
          if (toastType === 'banner') {
            this.toastNotifications.set(res.getSender() + '#' + res.id,
              this.toastNotification.queueToastNotification('info', res.getToastTitle(), res.getToastText(), false, true));
          } else if (toastType === 'alert') {
            this.toastNotifications.set(res.getSender() + '#' + res.id,
              this.toastNotification.queueToastNotification('info', res.getToastTitle(), res.getToastText(), true, false));
          }
        } else {
          if (categoryToast === 'banner') {
            this.toastNotifications.set(res.getSender() + '#' + res.id,
              this.toastNotification.queueToastNotification('info', res.getToastTitle(), res.getToastText(), false, true));
          } else if (categoryToast === 'alert') {
            this.toastNotifications.set(res.getSender() + '#' + res.id,
              this.toastNotification.queueToastNotification('info', res.getToastTitle(), res.getToastText(), true, false));
          }
        }

        this.lastToastSender = res.getSender();
        if (res.getSender() === 'license') {
          this.licenseText = res.getTitle();
        }
      }

    }

  }

  private checkCategory(notif: Notification): boolean {
    const configs = this.configurations.getValue();
    const mainShow = configs.get(notif.getSender()).getShow();
    let show = true;
    if (configs && notif.getCustomData() && notif.getCustomData()[1]) {
      const customData: CustomData = configs.get(notif.getSender()).getCustomData().find(x => x.id === notif.getCustomData()[1]);
      const el: CustomSetting = customData.data[1];
      // found category in the configuration
      if (el !== undefined) {
        if (customData.override === true) {
          if (el.value === true) {
            show = true;
          } else {
            show = false;
          }
        } else {
          show = mainShow;
        }
      }
    } else {
      show = mainShow;
    }
    return show;
  }

  private checkCategorySound(notif: Notification): boolean {
    const configs = this.configurations.getValue();
    if (configs && notif.getCustomData() && notif.getCustomData()[1]) {
      const customData: CustomData = configs.get(notif.getSender()).getCustomData().find(x => x.id === notif.getCustomData()[1]);
      const el: CustomSetting = customData.data[2];
      // found category in the configuration
      if (el !== undefined) {
        if (customData.override === true) {
          if (el.value === true) {
            return true;
          } else {
            return false;
          }
        }
      }
    }
    return false;
  }

  private checkCategoryToast(notif: Notification): string {
    const configs = this.configurations.getValue();
    if (configs && notif.getCustomData() && notif.getCustomData()[1]) {
      const customData: CustomData = configs.get(notif.getSender()).getCustomData().find(x => x.id === notif.getCustomData()[1]);
      const el: CustomSetting = customData.data[0];
      // found category in the configuration
      if (el !== undefined) {
        if (customData.override === true) {
          if (el.value === 'none') {
            return 'none';
          } else if (el.value === 'banner') {
            return 'banner';
          } else if (el.value === 'alert') {
            return 'alert';
          }
        }
      }
    }
    return '';
  }

  private setNotificationCount(value: number): void {
    this.count.next(value);
  }

  private getTranslations(): void {
    this.translateService.get([
      'NOTIF.SUPPRESSED-NOTIFICATION-DESCRIPTION',
      'NOTIF.PASSWORD-REMINDER-SUBTITLE-LABEL',
      'NOTIF.PASSWORD-REMINDER-DESCRIPTION-LABEL',
      'CHANGE_PASSWORD',
      'GMS_SERVICES.PASSWORD_EXPIRED'
    ]).subscribe(res => {
      this.suppressedNotificationDescriptionLabel = res['NOTIF.SUPPRESSED-NOTIFICATION-DESCRIPTION'];
      this.passwordReminderSubtitleLabel = res['NOTIF.PASSWORD-REMINDER-SUBTITLE-LABEL'];
      this.passwordReminderDescriptionLabel = res['NOTIF.PASSWORD-REMINDER-DESCRIPTION-LABEL'];
      // eslint-disable-next-line @typescript-eslint/dot-notation
      this.passwordChangeTitleLabel = res['CHANGE_PASSWORD'];
      this.passwordExpiredSubTitleLabel = res['GMS_SERVICES.PASSWORD_EXPIRED'];

      this.suppressedObjectsService.subscribeSuppressedObjects();
      this.suppressedObjectsSubscription();
    });
  }

  private suppressedObjectsSubscription(): void {
    this.suppressedObjectsService.suppressedObjectsNotification().subscribe(
      values => this.onSuppressedObjectsNotifications(values),
      error => {
        this.suppressedObjectsSubscription();
      });
  }

  private onSuppressedObjectsNotifications(data: any): void {
    this.translateService.get([
      'NOTIF.SUPPRESSED-NOTIFICATION-SINGLE',
      'NOTIF.SUPPRESSED-NOTIFICATION-PLURAL'
    ], { count: data }).subscribe(res => {
      this.suppressedNotificationSingleLabel = res['NOTIF.SUPPRESSED-NOTIFICATION-SINGLE'];
      this.suppressedNotificationPluralLabel = res['NOTIF.SUPPRESSED-NOTIFICATION-PLURAL'];

      if (data > 0) {
        const userLang: string = this.translateService.getBrowserLang();
        const date: string = new Date().toLocaleString(userLang);
        let label = '';
        if (data > 1) {
          label = this.suppressedNotificationPluralLabel;
        } else {
          label = this.suppressedNotificationSingleLabel;
        }

        const notification: Notification = new Notification(0)
          .setIcon('element-alarm-off')
          .setAutoCancel(false)
          .setTitle(label)
          .setText(date)
          .setToastTitle(label)
          .setToastText(date)
          .setOverwrite(true);

        this.notificationService.notify('suppressedObjects', Object.create(notification));
      } else {
        this.notificationService.cancelAll('suppressedObjects');
      }
      this.suppressedToast = true;
    });
  }

  private startCookieTimer(): void {
    // ask, I move that out because it were started in translation subscribe, I don't know why
    setInterval(() => { this.readPasswordCookie(); }, 1000);
  }

  private readPasswordCookie(): void {
    if (this.cookieService.check('password_reminder_counter')) {
      const passwordExp = parseInt(this.cookieService.get('password_reminder_counter'), 10);
      if (passwordExp !== this.passwordReminderCounter) {
        this.passwordReminderCounter = passwordExp;

        this.translateService.get(['NOTIF.PASSWORD-REMINDER-TITLE-LABEL'], { count: this.passwordReminderCounter }).subscribe(res => {
          this.passwordReminderTitleLabel = res['NOTIF.PASSWORD-REMINDER-TITLE-LABEL'];

          const passwordReminderNotifAction: Action = new Action('default')
            .setCallback(this.passwordReminderClickAction
              .bind(this))
            .setDescription('default');

          if (this.passwordReminderCounter <= this.maxReminderCounter && this.passwordReminderCounter > 0) {
            const notification: Notification = new Notification(0)
              .setIcon('element-user')
              .setAutoCancel(false)
              .setText(this.passwordReminderSubtitleLabel)
              .setToastText(this.passwordReminderSubtitleLabel)
              .setOverwrite(true)
              .setActions([passwordReminderNotifAction]);

            if (this.passwordReminderCounter === 1) {
              notification
                .setTitle(this.passwordReminderTitleLabel)
                .setToastTitle(this.passwordReminderTitleLabel)
                .setIcon('element-user')
                .setAutoCancel(false)
                .setText(this.passwordExpiredSubTitleLabel) // Should we use passwordExpiredSubTitleLabel instead for the last day of password expiration?
                .setToastText(this.passwordReminderSubtitleLabel)
                .setOverwrite(true)
                .setActions([passwordReminderNotifAction]);

            } else {
              notification
                .setTitle(this.passwordReminderTitleLabel)
                .setToastTitle(this.passwordReminderTitleLabel)
                .setIcon('element-user')
                .setAutoCancel(false)
                .setText(this.passwordReminderSubtitleLabel)
                .setToastText(this.passwordReminderSubtitleLabel)
                .setOverwrite(true)
                .setActions([passwordReminderNotifAction]);
            }

            this.notificationService.notify('passwordReminder', Object.create(notification));

          } else {
            this.notificationService.cancelAll('passwordReminder');
          }
        });
      }
    }
  }

  private passwordReminderClickAction(): void {
    this.messageBroker.switchToNextFrame('account-frame-id').subscribe((frameChanged: boolean) => {
      // Switch to account frame and call the password edit modal
    });
    this.toastNotification.hideToastNotification();
    this.cookieService.set('password_toEdit', 'true');
  }

}
