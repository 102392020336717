import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SiIconComponent } from '@simpl/element-ng/icon';
import { Link, SiLinkDirective } from '@simpl/element-ng/link';
import { SiTranslateModule, TranslatableString } from '@simpl/element-translate-ng/translate';

/**
 * The component shall be use to indicate that an authenticated
 * user does not have the required permissions.
 */
@Component({
  selector: 'si-unauthorized-page',
  templateUrl: './si-unauthorized-page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrl: './si-unauthorized-page.component.scss',
  standalone: true,
  imports: [SiLinkDirective, SiIconComponent, SiTranslateModule]
})
export class SiUnauthorizedPageComponent {
  /**
   * The element warning icon.
   *
   * @defaultValue 'element-warning-filled'
   */
  @Input() icon = 'element-warning-filled';
  /** The main heading indicating the problem. */
  @Input() heading?: TranslatableString;
  /** A sub heading is a sentence summarizing the problem. */
  @Input() subHeading?: TranslatableString;
  /** May be a longer description explaining the problem. */
  @Input() description?: TranslatableString;
  /**
   * Use the link object if you have one option to follow. A link object
   * has a title to be displayed and can be configured with an external link,
   * a router link, or a custom action. If you want to provide multiple options,
   * add your own content into the component.
   */
  @Input() link?: Link;
}
