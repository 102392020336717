<ngx-datatable
  class="table-element si-layout-fixed-height mt-1"
  [rows]="tableProperties"
  [columns]="columns"
  [columnMode]="ColumnMode.force"
  [footerHeight]="0"
  [virtualization]="false"
  [scrollbarV]="true"
  [scrollbarH]="tableScrollbarH"
  [selectionType]="SelectionType.single"
  [rowClass]="getTableRowClass"
  [treeFromRelation]="'__internalState.itemParentId'"
  [treeToRelation]="'__internalState.itemId'"
  siDatatableInteraction
  [datatableInteractionAutoSelect]="false"
  (activate)="toggleTableRowGroup($event)"
  (siResizeObserver)="recalculate()"
/>

<ng-template #valueCellTempl let-row="row" ngx-datatable-cell-template>
  <ng-container
    *ngTemplateOutlet="
      propertyTempl;
      context: {
        property: row,
        index: !row.__internalState.parent ? row.__internalState.itemIndex : undefined,
        forceReadonly: row.__internalState.forceReadonly
      }
    "
  />
</ng-template>

<ng-template #toggleTempl let-cell="cellContext" />
