import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BACnetObjectIdentifierValue } from '@simpl/buildings-types';

import { SiNumberValidatorDirective } from '../../directives/number-validator.directive';
import { resetValueObjectid } from '../../helpers/property';
import { Property, StateChange, ValueState } from '../../interfaces/property';
import { SiPropertyPopoverComponent } from '../si-property-popover/si-property-popover.component';

@Component({
  selector: 'si-objectid-property',
  templateUrl: './si-objectid-property.component.html',
  styleUrl: './si-objectid-property.component.scss',
  standalone: true,
  imports: [FormsModule, SiNumberValidatorDirective, SiPropertyPopoverComponent]
})
export class SiObjectidPropertyComponent {
  @Input({ required: true }) property!: Property<BACnetObjectIdentifierValue>;
  /** @defaultValue 'none' */
  @Input() valueState: ValueState = 'none';
  /** @defaultValue 'Type' */
  @Input() typeLabel = 'Type';
  /** @defaultValue 'Instance' */
  @Input() instanceLabel = 'Instance';
  /** @defaultValue false */
  @Input() forceReadonly = false;

  @Output() readonly submitted = new EventEmitter<Property<BACnetObjectIdentifierValue>>();

  @ViewChild('popover', { static: true }) protected popover!: SiPropertyPopoverComponent;
  @ViewChild('typeSelect') protected typeSelect?: ElementRef;

  protected get displayValue(): string {
    let objectType = '';
    if (this.popover.modelValue?.objectType.value != null) {
      objectType =
        this.property.value.value?.objectType.options?.find(
          option => option.value === this.popover.modelValue?.objectType.value
        )?.text ?? String(this.popover.modelValue?.objectType.value);
    } else {
      objectType = this.property.value.value?.objectType.altText ?? '';
    }
    const instance = String(
      this.popover.modelValue?.instance.value ?? this.property.value.value?.instance.altText ?? ''
    );
    return objectType || instance ? `(${objectType}, ${instance})` : '';
  }

  protected get readonly(): true | null {
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    return this.forceReadonly || this.property.value.readonly || null;
  }

  protected stateChange(state: StateChange): void {
    switch (state) {
      case 'openKeyboard':
        setTimeout(() => this.typeSelect?.nativeElement.focus());
        break;
      case 'submit':
        this.submitted.emit(this.property);
        break;
      case 'release':
        resetValueObjectid(this.property);
        this.submitted.emit(this.property);
        break;
    }
  }
}
